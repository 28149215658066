import _reduce from 'lodash/reduce';
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';

import {
    OMS_RECEIVED_DEPENDENCIES,
    ASSETS_COMPOSITOR_CHANGED,
    COMPLETE_CONFIGURATION_CHANGED
 } from 'dictionary';
import { compositorOptionString } from 'utils';

function updateView({state, index, viewKey, views}) {
    const { available_views = {} } = state;
    index = index || available_views[viewKey].current.index
    views = views || state.views
    return {
        [viewKey]: {
            current: {
                url: views[available_views[viewKey].available[index]].url,
                view: available_views[viewKey].available[index],
                index
            },
            available: available_views[viewKey].available
        }
    }
}

function Compositor(state = {}, action, { app_state }){
  const { compositor_url, model, available_views = {} } = state;
  const { OMS = {} } = app_state;
  const { oms_params = {} } = OMS;

  switch (action.type) {
    case OMS_RECEIVED_DEPENDENCIES:
        return Object.assign({}, state, {"model": oms_params.model});

    case COMPLETE_CONFIGURATION_CHANGED:
      const views = _reduce(state.views, (result, viewData, view)=>
        Object.assign(result, {
          [view]: Object.assign({}, viewData, {
            url: (() => {
              let applyOptions = [];
              if (viewData.relevant_groups) {
                applyOptions = viewData.relevant_groups.reduce((result,group) =>
                  [].concat(
                    result,
                    _get(action.configurationByGroup.matchedCodes, group) ||[]
                  )
                , []);
              }
              else {
                applyOptions = action.options;
              }

              if (viewData.relevant_options) {
                _forEach(viewData.relevant_options, function(option) {
                  applyOptions.push(option);
                })
              }

              const { view, size } = viewData;
              const option_string = compositorOptionString(app_state, applyOptions);
              return `${compositor_url}&options=${option_string}&view=${view}&model=${model}&size=${size}`;
            })()
          })
        })
      , {})

      return Object.assign({}, state, {
        views,
        available_views: Object.keys(available_views).reduce((result,viewKey) => Object.assign(result, updateView({state, viewKey, views})), {})
      });

    case ASSETS_COMPOSITOR_CHANGED:
      return Object.assign({}, state, {available_views: action.layers.compositorData.availableViews});

    default:
      return state;
  }
}

export default Compositor;
