import React from 'react';
import { connect } from 'react-redux';
import { string } from 'prop-types';
import _get from 'lodash/get';
import { i18n, htmlToReact } from 'utils';
import { getModelCode } from 'selectors';

const InsuranceDisclaimer = ({ disclaimer }) => {
  if (!disclaimer) {
    return null;
  }
  return <div className="tds-text--caption">{htmlToReact(disclaimer)}</div>;
};

InsuranceDisclaimer.propTypes = {
  disclaimer: string,
};

InsuranceDisclaimer.defaultProps = {
  disclaimer: null,
};

function mapStateToProps(state) {
  const { OMS } = state;
  const modelOption = getModelCode(state);
  const model = _get(OMS, `lexicon.options.${modelOption}`, {});
  const modelName = _get(model, 'name', '').replace(' ', '&nbsp;');

  const disclaimer = i18n(
    'SummaryPanel.disclaimers.insuranceDisclaimer',
    { MODEL: modelName },
    null,
    { returnNullWhenEmpty: true }
  );

  return {
    disclaimer,
  };
}

export default connect(mapStateToProps)(InsuranceDisclaimer);
