/* eslint no-case-declarations: 0 */
import {
  SELECT_FINANCE_PANEL_TAB,
  TOGGLE_FINANCE_PANEL_SIDEBAR,
  INCLUDE_TESLA_INSURANCE,
  SET_FINANCE_MODAL_DRIVER_TYPE,
  SET_EV_CREDIT,
  TOGGLE_POTENTIAL_SAVINGS,
  SET_COE_AMOUNT,
  TOGGLE_TAXES_AND_FEES,
  SET_TAXES_AND_FEES,
  STATUS_ERROR,
  TOGGLE_TAXES_FEES_EXPERIENCE,
  STATUS_INVALID_ZIP,
  UPDATE_PREQUALIFICATION_DATA,
  RESET_PREQUALIFICATION_DATA,
  APPROVED,
} from 'dictionary';
import _isEmpty from 'lodash/isEmpty';

import { getBasePlusTrimPrice, getLeaseDownPayment, getTrimCode, getModelCode } from 'selectors';
import { getTaxesAndFeesData, memoize, getPreQualData } from 'utils';
import Analytics from 'analytics';

export const selectFinancePanelTab = (selectedForm, userClicked) => ({
  type: SELECT_FINANCE_PANEL_TAB,
  selectedForm,
  userClicked: userClicked !== 'undefined' && userClicked,
});

export const toggleFinancePanelSideBar = () => ({
  type: TOGGLE_FINANCE_PANEL_SIDEBAR,
});

export const selectTeslaInsurance = flag => ({
  type: INCLUDE_TESLA_INSURANCE,
  flag,
});

export const toggleFinancePanelDrivertype = driverType => ({
  type: SET_FINANCE_MODAL_DRIVER_TYPE,
  driverType,
});

export const setEVCredit = value => ({
  type: SET_EV_CREDIT,
  value,
});

export const togglePotentialSavings = value => ({
  type: TOGGLE_POTENTIAL_SAVINGS,
  value,
});

export const setCOEBidAmount = value => ({
  type: SET_COE_AMOUNT,
  value,
});

export const toggleTaxesAndFees = value => ({
  type: TOGGLE_TAXES_AND_FEES,
  value,
});

export const setTaxesAndFees = (value = {}) => ({
  type: SET_TAXES_AND_FEES,
  value,
});

export const toggleFeesAndTaxesExperience = flag => ({
  type: TOGGLE_TAXES_FEES_EXPERIENCE,
  flag,
});

export const resetTaxesAndFees = () => dispatch => {
  dispatch(toggleTaxesAndFees(false));
  dispatch(setTaxesAndFees());
};

export const fetchTaxesAndFees = () => async (dispatch, getState) => {
  const state = getState();
  const {
    App: { countryCode, routes: { taxesAndFeesApi: url } = {} } = {},
    FinancingOptions: { showTaxesAndFees } = {},
    ReviewDetails: {
      DeliveryDetails: { PostalCode, stateCode, county, city, countryCode: userInputCountry } = {},
    } = {},
    Pricing: { total } = {},
  } = state;

  if (!showTaxesAndFees) {
    return;
  }

  dispatch(setTaxesAndFees({ isLoading: true }));
  const payload = {
    country: countryCode,
    county,
    city,
    state: stateCode,
    postalCode: PostalCode,
    basePrice: getBasePlusTrimPrice(state),
    vehiclePrice: total,
    leaseDownPayment: getLeaseDownPayment(state),
    trimCode: getTrimCode(state),
    modelCode: getModelCode(state),
  };

  const invaliZipError = !PostalCode ? STATUS_INVALID_ZIP : null;
  const isInvalidCountry = userInputCountry && userInputCountry !== countryCode;
  const memoizedTaxesAndFees = memoize(getTaxesAndFeesData, payload);
  const response = isInvalidCountry ? {} : await memoizedTaxesAndFees(url, payload);
  const error = invaliZipError || (_isEmpty(response) ? STATUS_ERROR : null);
  const result = {
    ...response,
    error,
    isLoading: false,
  };
  if (error) {
    dispatch(toggleTaxesAndFees(false));
  }
  dispatch(setTaxesAndFees(result));
};

export const getPreQualificationReport = payload => async (dispatch, getState) => {
  const state = getState();
  const { App: { routes: { getPrequalResults: url } = {} } = {} } = state;
  dispatch({ type: UPDATE_PREQUALIFICATION_DATA, payload, isPending: true });
  const result = await getPreQualData(url, payload);
  const { decision, error } = result || {};
  if (decision) {
    const interaction = decision === APPROVED ? 'prequalify-qualified' : 'prequalify-denied';
    Analytics.fireInteractionEvent(interaction);
  }
  if (decision || error) {
    dispatch({ type: UPDATE_PREQUALIFICATION_DATA, error, isPending: false });
  }
};
export const setPreQualifyUserData = payload => dispatch => {
  dispatch({ type: UPDATE_PREQUALIFICATION_DATA, payload });
};

export const resetPreQualData = () => ({
  type: RESET_PREQUALIFICATION_DATA,
});
