import _get from 'lodash/get';
import {
  PAYMENT_TYPE_POS,
  PAYMENT_TYPE_WECHAT,
  PAYMENT_TYPE_ALIPAY,
  PAYMENT_TYPE_WT,
  SHOW_DISCLAIMER_OPT_IN_PAYX,
  DO_NOT_SHOW_DISCLAIMER_OPT_IN_PAYX,
} from 'dictionary';

const CharityInvoiceType = _get(window.tesla, 'payments.CharityInvoiceTypes', []);
const charityInvoiceList = [];
CharityInvoiceType.forEach(item => {
  charityInvoiceList.push({
    const: item,
    value: `Review.charityType.${item}`,
  });
});

export default ({ object }) => ({
  object: {
    ...object,
    serverError: null,
    showPaymentOverview: true,
  },
  deltas: {},
  deltasV2: [
    {
      selected_by: {
        isComboInventory: true,
        market: [
          'AT',
          'AU',
          'BE',
          'CA',
          'CH',
          'CZ',
          'DE',
          'DK',
          'ES',
          'FI',
          'FR',
          'GB',
          'IE',
          'IT',
          'IS',
          'JO',
          'JP',
          'KR',
          'LU',
          'MX',
          'NL',
          'NO',
          'NZ',
          'PT',
          'PR',
          'SE',
          'TW',
        ],
      },
      object: {
        registration: {
          deliveryPostalCodeRequired: true,
        },
      },
    },
    {
      selected_by: {
        model: 'ts',
      },
      object: {
        showInvoiceOption: false,
        registration: {
          showPrivateFields: false,
        },
        isSinglePage: true,
      },
    },
    {
      selected_by: {
        model: 'ts',
        market: 'GB',
      },
      object: {
        registration: {
          companyNumberRequired: false,
        },
      },
    },
    {
      selected_by: {
        market: 'CN',
        model: ['m3', 'ms', 'mx', 'my'],
      },
      object: {
        noWireTransferOnBusinessAccount: true,
        PaymentTypesBusiness: [
          PAYMENT_TYPE_ALIPAY,
          PAYMENT_TYPE_WECHAT,
          PAYMENT_TYPE_POS,
          PAYMENT_TYPE_WT,
        ],
        PaymentTypesPrivate: [PAYMENT_TYPE_ALIPAY, PAYMENT_TYPE_WECHAT, PAYMENT_TYPE_POS],
      },
    },
    {
      selected_by: {
        market: ['CN'],
        isInventory: true,
      },
      object: {
        noWireTransferOnBusinessAccount: true,
        PaymentTypesBusiness: [
          PAYMENT_TYPE_ALIPAY,
          PAYMENT_TYPE_WECHAT,
          PAYMENT_TYPE_POS,
        ],
        PaymentTypesPrivate: [
          PAYMENT_TYPE_ALIPAY,
          PAYMENT_TYPE_WECHAT,
          PAYMENT_TYPE_POS,
        ],
      },
    },

    {
      selected_by: {
        market: 'CN',
        isWeChatMiniEnv: true,
      },
      object: {
        noWireTransferOnBusinessAccount: true,
        PaymentDetail: {
          PaymentType: PAYMENT_TYPE_WECHAT,
        },
        PaymentTypes: [PAYMENT_TYPE_WECHAT, PAYMENT_TYPE_POS],
        PaymentTypesBusiness: [PAYMENT_TYPE_WECHAT, PAYMENT_TYPE_POS],
        PaymentTypesPrivate: [PAYMENT_TYPE_WECHAT, PAYMENT_TYPE_POS],
      },
    },
    {
      selected_by: {
        market: 'CN',
        isWeChatBrowserEnv: true,
      },
      object: {
        noWireTransferOnBusinessAccount: true,
        PaymentDetail: {
          PaymentType: PAYMENT_TYPE_WECHAT,
        },
        PaymentTypes: [PAYMENT_TYPE_WECHAT, PAYMENT_TYPE_POS],
        PaymentTypesBusiness: [PAYMENT_TYPE_WECHAT, PAYMENT_TYPE_POS],
        PaymentTypesPrivate: [PAYMENT_TYPE_WECHAT, PAYMENT_TYPE_POS],
      },
    },
    {
      selected_by: {
        market: 'CN',
      },
      object:{
        skipPaymentInProgressCheck: true
      }
    },
    {
      selected_by: {
        model: ['mp', 'ct'],
      },
      object: {
        showOptinDisclaimer: DO_NOT_SHOW_DISCLAIMER_OPT_IN_PAYX,
      },
    },
    {
      selected_by: {
        market: 'CN',
        isWeChatBrowserEnv: true,
        model: ['mt'],
      },
      object: {
        noWireTransferOnBusinessAccount: true,
        PaymentDetail: {
          PaymentType: PAYMENT_TYPE_WT,
        },
        PaymentTypes: [PAYMENT_TYPE_WT],
        PaymentTypesBusiness: [PAYMENT_TYPE_WT],
        PaymentTypesPrivate: [PAYMENT_TYPE_WT],
      },
    },
    {
      selected_by: {
        isInventory: true,
        isPostOrderSwap: false,
        isCoinReloaded: true,
      },
      object: {
        showPaymentOverview: false,
      },
    },
    {
      selected_by: {
        market: 'CN',
        isInventory: true,
        isCoinReloaded: true,
      },
      object: {
        showPaymentOverview: true,
      },
    },
  ],
});

/**
StoredProfile schema
{
    "IsProfileExists": true,
    "CardType": "Visa",
    "PayorName": "Pratim Nag",
    "LastFourDigits": "1112",
    "ExpYear": 2018,
    "ExpMonth": 1,
    "BillingAddress": {
        "Street": null,
        "Address2": null,
        "Address3": null,
        "City": null,
        "StateProvince": null,
        "County": null,
        "CountryCode": null,
        "ZipCode": null,
        "ZipCodeExtension": null
    }
}

Payment Schema
{
    "PayorName": "Pratim Nag",
    "PaymentType": "CC",
    "PaymentSource": "RESERVATION",
    "CurrencyCode": "USD",
    "CountryCode": "US",
    "CreditCardDetail": {
        "AccountNumber": "4112344112344113", //only new
        "ExpirationMonth": "11",
        "ExpirationYear": "2019",
        "VerificationNumber": "123", //only new
        "CardType": "Visa",
        "AgreedToSaveProfile": true //only new
    }
}


 */
