import { formatDate } from '@tesla/intl-date-time';

/**
 * Get Month Name and Year
 *
 * @param Date date
 * @param locale locale
 * @return string month year
 */
export const getMonthYear = (
  date,
  locale,
  options = {
    month: 'long',
    year: 'numeric',
    timeZone: 'UTC',
  }
) => {
  if (!date) {
    return null;
  }

  const newDate = typeof date === 'string' ? new Date(date) : date;
  const formattedLocale = locale.replace('_', '-');
  return newDate.toLocaleDateString(formattedLocale, options);
};

/**
 * Get Day Month Name and Year
 *
 * @param Date date
 * @param locale locale
 * @return string day month year
 */
export const getDayMonthYear = (date, locale) => {
  if (!date) {
    return null;
  }

  const newDate = typeof date === 'string' ? new Date(date) : date;
  const formattedDate = formatDate(newDate, locale, { dateStyle: 'medium' });
  return formattedDate;
};

/**
 * Calculate time difference
 *
 * @param Date date
 * @return string time difference in seconds
 */
export const getTimeDifferenceInSeconds = date => {
  if (!date) {
    return null;
  }
  const startTime = date.getTime();
  const now = new Date().getTime();
  const diff = now - startTime;
  const time = (diff / 1000) % 60;
  return `${time} s`;
};

/**
 * Get Year
 *
 * @param Date date
 * @return string year
 */
 export const getYear = (date) => {
  if (!date) {
    return null;
  }

  const newDate = typeof date === 'string' ? new Date(date) : date;
  return newDate.getFullYear();
};
