import { STD_FEATURES } from 'dictionary';

const InitialState = {
  object: {
    isCarouselOpen: false,
    groupName: STD_FEATURES,
  },
};

export default InitialState;
