import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _uniq from 'lodash/uniq';
import _isNil from 'lodash/isNil';
import _union from 'lodash/union';
import _head from 'lodash/head';
import _map from 'lodash/map';
import _orderBy from 'lodash/orderBy';
import _pull from 'lodash/pull';
import { getSuperRegion } from '@tesla/intl-display-names';
import {
  PAYMENT_DETAILS_CHANGED,
  PAYMENT_PROFILE_CHANGED,
  PAYMENT_DETAILS_VALID_FLAG,
  PAYMENT_FORM_TOGGLE,
  PAYMENT_CANCEL_EDIT,
  PAYMENT_FAILED,
  PAYMENT_FORM_VALID_FLAG,
  ADYEN_PAYMENTS,
  STRIPE_PAYMENTS,
  ACCOUNT_DETAILS_CHANGED,
  BILLING_INFO_CHANGED,
  REDIRECT_PAYMENT_DETAILS_CHANGED,
  REDIRECT_PAYMENT_DETAILS_CLEARED,
  BILLING_INFO_VALID_FLAG,
  BILLING_LOCATION_CHANGED,
  PROVINCE,
  CITY,
  DISTRICT,
  STATECODE,
  PAYMENT_TYPE_CHANGED,
  PAYMENT_TYPE_CC,
  PAYMENT_TYPE_WECHAT,
  PAYMENT_QRCODE_UPDATE,
  PAYMENT_QRCODE_TRANSACTION_STATUS_UPDATE,
  TRANSACTION_NUMBER_UPDATE,
  PAYMENT_INTERACTION_REQUIRED,
  CLEAR_SERVER_ERRORS,
  FIELD_REQUIRED,
  INVOICE_OPTIONS_VALID_FLAG,
  INVOICE_TYPE_CHANGED,
  PAYMENT_SOURCE_RESERVATION,
  REGISTRATION_TYPE_BUSINESS,
  UPDATE_PICKUP_LOCATIONS,
  UPDATE_PICKUP_LOCATIONS_BY_AVAILABILITY,
  PICKUP_LOCATION_PROVINCE_CHANGED,
  POST_PAYMENT_COMPLETE,
  POST_ORDER_FAIL,
  PRICE_CHANGED,
  UPDATE_ORDER_PAYMENT,
  FIELD_PICKUP_LOCATION,
  PAYMENT_FORM_INSTRUMENT_TYPE,
  UPDATE_PAYMENT_OVERVIEW_FLAG,
} from 'dictionary';
import { getOrderPayment } from 'selectors';
import { i18n } from 'utils';
import { defaultSelectedPickupLocationList } from '../../../common/locationsData';

const mapPayorName = ({ CCFirstName, CCLastName, PayorName }) => {
  const AccountName = `${CCFirstName} ${CCLastName}`.replace(/\s+/g, ' ').trim();
  if (AccountName !== PayorName) {
    return AccountName;
  }

  return PayorName;
};

/**
 * @param  {Object} state
 * @param  {Object} creditCardDetail
 * @return {Object}
 */
function getStoredProfileBlock(state, creditCardDetail) {
  // nothing really to do here
  return creditCardDetail || {};
}

/**
 * Returns full payment details object based needed by service
 * @param  {Object} app_state
 * @param  {Object} paymentDetails
 * @return {Object}
 */
function getPaymentDetails(app_state, paymentDetails, state) {
  const {
    PaymentAmount,
    PayorName,
    AccountNumber,
    ExpirationMonth,
    ExpirationYear,
    VerificationNumber,
    CardType,
    BillingZipCode,
    PaymentType,
    RedirectPaymentName,
    CreditCardToken,
    LastFourDigits,
    IsOffline,
  } = paymentDetails;

  const PayorNameMapped = state.formOpen
    ? PayorName || state.PaymentDetail.PayorName
    : _get(state, 'CreditCardDetail.PayorName', '');
  const CountryCode = state.CountryCode || _get(app_state, 'OMS.oms_params.market');
  const CurrencyCode = state.CurrencyCode || '';

  return {
    PaymentAmount: PaymentAmount || state.PaymentDetail.PaymentAmount,
    PayorName: PayorNameMapped,
    BillingZipCode,
    PaymentType: PaymentType || state.PaymentDetail.PaymentType,
    RedirectPaymentName,
    PaymentSource: PAYMENT_SOURCE_RESERVATION,
    CurrencyCode,
    CountryCode,
    CreditCardDetail: {
      ExpirationMonth,
      ExpirationYear,
      CardType,
      CreditCardToken,
      LastFourDigits,
      ...(!paymentDetails.ProcessWithProfile
        ? {
            AccountNumber,
            VerificationNumber,
            AgreedToSaveProfile: !!_isEmpty(_get(paymentDetails, 'AgreementSave')),
          }
        : {}),
    },
    IsOffline,
    PayorIDNumber: _get(paymentDetails, 'PayorIDNumber', null),
    VerificationPhone: _get(paymentDetails, 'VerificationPhone', null),
    VerificationSMSCode: _get(paymentDetails, 'VerificationSMSCode', null),
    AgreementSave: _get(paymentDetails, 'AgreementSave', null),
  };
}

function getPaymentEncryptor(paymentDetails, app_state, defaultEncryptor = ADYEN_PAYMENTS) {
  const type = paymentDetails.CardType || '';
  const countryCode = paymentDetails.CountryCode || _get(app_state, 'OMS.oms_params.market');
  const region = getSuperRegion(countryCode);
  let encryptor = defaultEncryptor;

  if (type.toLowerCase() === 'americanexpress' && region.code === 'REEU') {
    encryptor = STRIPE_PAYMENTS;
  }
  return encryptor;
}

export default function Reducer(state = {}, action, { app_state }) {
  switch (action.type) {
    case PAYMENT_DETAILS_VALID_FLAG:
      return { ...state, ccDetailsValid: action.flag };
    case PAYMENT_FORM_TOGGLE:
      return {
        ...state,
        formOpen: !_isNil(action, 'isOpen') ? action.isOpen : !state.formOpen,
        ccDetailsValid: false,
      };
    case PAYMENT_PROFILE_CHANGED:
      return {
        ...state,
        formOpen: !_get(action, 'creditCardDetail.IsProfileExists'),
        ccDetailsValid: _get(action, 'creditCardDetail.IsProfileExists'),
        CreditCardDetail: {
          ...state.CreditCardDetail,
          ...getStoredProfileBlock(action.creditCardDetail),
        },
      };
    case PAYMENT_DETAILS_CHANGED:
      return {
        ...state,
        serverErrors: {},
        PaymentDetail: {
          ...state.PaymentDetail,
          ...getPaymentDetails(app_state, action.paymentDetails, state),
        },
        encryptor: getPaymentEncryptor(action.paymentDetails, app_state, state.defaultEncryptor),
      };
    case PAYMENT_QRCODE_UPDATE:
      return {
        ...state,
        PaymentQRCode: action.qrCode,
      };
    case PAYMENT_QRCODE_TRANSACTION_STATUS_UPDATE:
      return {
        ...state,
        qrCodePaymentTransactionStatus: action.status,
      };
    case TRANSACTION_NUMBER_UPDATE:
      return {
        ...state,
        transactionNumber: action.transactionNumber,
      };
    case PAYMENT_INTERACTION_REQUIRED:
      return {
        ...state,
        isPaymentInteractionRequired: action.flag,
      };
    case PAYMENT_CANCEL_EDIT:
      return { ...state, formOpen: false, ccDetailsValid: true };

    case PAYMENT_FAILED:
      return {
        ...state,
        formOpen: true,
        serverErrors: {
          PAYMENT_FAILED,
        },
      };

    case FIELD_REQUIRED:
      return {
        ...state,
        validationErrors: {
          [`${action.field}`]: true,
        },
      };

    case CLEAR_SERVER_ERRORS:
      return { ...state, serverErrors: {} };

    case BILLING_INFO_CHANGED:
      return {
        ...state,
        PaymentDetail: {
          ...state.PaymentDetail,
          BillingInfoDetail: {
            ...state.PaymentDetail.BillingInfoDetail,
            ...action.billingInfo,
          },
        },
        validationErrors: {},
      };

    case REDIRECT_PAYMENT_DETAILS_CHANGED: {
      return {
        ...state,
        RedirectPaymentDetail: {
          token: action.token,
          returnUrl: action.returnUrl,
        },
      };
    }
    // PAYX-5816: Pass error to payx
    case POST_ORDER_FAIL: {
      return {
        ...state,
        RedirectPaymentDetail: {
          token: null,
          returnUrl: null,
        },
        serverError: action?.error.code || null,
      };
    }

    case REDIRECT_PAYMENT_DETAILS_CLEARED: {
      return {
        ...state,
        RedirectPaymentDetail: {
          token: null,
          returnUrl: null,
        },
      };
    }

    case BILLING_INFO_VALID_FLAG:
      return {
        ...state,
        billingInfoValid: action.flag,
      };

    case PAYMENT_FORM_VALID_FLAG:
      return {
        ...state,
        paymentFormValid: action.flag,
      };

    case PAYMENT_FORM_INSTRUMENT_TYPE:
      return {
        ...state,
        PaymentDetail: {
          ...state?.PaymentDetail,
          PaymentType: action.paymentType,
        },
      };

    case ACCOUNT_DETAILS_CHANGED: {
      const { CCFirstName = '', CCLastName = '', AccountType } = action.payload;
      const {
        noWireTransferOnBusinessAccount = false,
        PaymentTypes = [],
        PaymentTypesPrivate = [],
        PaymentTypesBusiness = [],
      } = state;
      const extraObjectModification = {};
      if (noWireTransferOnBusinessAccount) {
        const paymentTypesBusiness = PaymentTypesBusiness.length
          ? PaymentTypesBusiness
          : PaymentTypes;
        const paymentTypesPrivate = PaymentTypesPrivate.length ? PaymentTypesPrivate : PaymentTypes;
        extraObjectModification.PaymentTypes =
          AccountType === REGISTRATION_TYPE_BUSINESS ? paymentTypesBusiness : paymentTypesPrivate;
      }
      return {
        ...state,
        ...extraObjectModification,
        PaymentDetail: {
          ...state.PaymentDetail,
          PayorName: _get(state, 'isShowSMSField', false)
            ? _get(state.PaymentDetail, 'PayorName')
            : mapPayorName({
                CCFirstName,
                CCLastName,
                PayorName: _get(state.PaymentDetail, 'PayorName'),
              }),
        },
      };
    }

    case BILLING_LOCATION_CHANGED: {
      const type = action.changedType;
      const selectedValue = action.payload.value;
      const { regionCode } = action.payload;

      switch (type) {
        case PROVINCE:
          return {
            ...state,
            cityData: action.payload.children,
            districtData: action.payload.children[0].children,
            PaymentDetail: {
              ...state.PaymentDetail,
              BillingInfoDetail: {
                ...state.PaymentDetail.BillingInfoDetail,
                selectedProvince: selectedValue,
                selectedCity: action.payload.children[0].value,
                selectedDistrict: action.payload.children[0].children[0].value,
              },
            },
          };

        case STATECODE:
          return {
            ...state,
            PaymentDetail: {
              ...state.PaymentDetail,
              BillingInfoDetail: {
                ...state.PaymentDetail.BillingInfoDetail,
                StateProvince: regionCode,
              },
            },
          };

        case CITY:
          return {
            ...state,
            districtData: action.payload.children[0].children,
            PaymentDetail: {
              ...state.PaymentDetail,
              BillingInfoDetail: {
                ...state.PaymentDetail.BillingInfoDetail,
                selectedCity: selectedValue,
                selectedDistrict: action.payload.children[0].value,
              },
            },
          };
        case DISTRICT:
          return {
            ...state,
            PaymentDetail: {
              ...state.PaymentDetail,
              BillingInfoDetail: {
                ...state.PaymentDetail.BillingInfoDetail,
                selectedDistrict: selectedValue,
              },
            },
          };
        default:
          return null;
      }
    }

    case PAYMENT_TYPE_CHANGED: {
      const { IdentificationType } = action;
      const result =
        IdentificationType !== 'CITIZEN_ID_NO'
          ? state.PaymentTypes.filter(v => v !== PAYMENT_TYPE_CC)
          : state.includeCC;

      return {
        ...state,
        PaymentTypes: result
          ? _uniq(_union(state.PaymentTypes, [PAYMENT_TYPE_CC]))
          : state.PaymentTypes,
        isShowSMSField: IdentificationType === 'CITIZEN_ID_NO',
        PaymentDetail: {
          ...state.PaymentDetail,
          PaymentType:
            IdentificationType !== 'CITIZEN_ID_NO' &&
            state.PaymentDetail.PaymentType === PAYMENT_TYPE_CC
              ? state.PaymentTypes.filter(v => v !== PAYMENT_TYPE_CC)[0]
              : state.PaymentDetail.PaymentType,
        },
      };
    }

    case 'MOCK_WECHAT': {
      const email = action.payload.Email;
      return {
        ...state,
        PaymentTypes:
          email && email.match(/^teslaqacn\+([A-Za-z0-9_\-.])+@gmail\.com$/)
            ? _union(state.PaymentTypes, [PAYMENT_TYPE_WECHAT])
            : state.PaymentTypes.filter(paymentType => paymentType !== PAYMENT_TYPE_WECHAT),
      };
    }

    case INVOICE_TYPE_CHANGED: {
      const { invoiceType } = action;
      return {
        ...state,
        InvoiceType: invoiceType,
      };
    }

    case INVOICE_OPTIONS_VALID_FLAG: {
      return {
        ...state,
        InvoiceDetailsValid: action.flag,
      };
    }

    case UPDATE_PICKUP_LOCATIONS_BY_AVAILABILITY: {
      const pickupLocationsTrtIdFiltered = action.data || [];
      const currentPickupLocationsFiltered = state.PickupLocations || [];
      const currentPickupLocationsFormatted = state.PickupLocationsFormatted || [];
      const handledPickupLocationFiltered = currentPickupLocationsFiltered.filter(
        item => pickupLocationsTrtIdFiltered.indexOf(parseInt(item.trt_id)) !== -1
      );
      let handledPickupLocationFormatted = currentPickupLocationsFormatted.filter(
        item => pickupLocationsTrtIdFiltered.indexOf(parseInt(item.value)) !== -1
      );
      handledPickupLocationFormatted = [
        {
          key: 0,
          value: 0,
          label: '',
          province: '',
        },
        ...handledPickupLocationFormatted,
      ];
      return {
        ...state,
        PaymentDetail: {
          ...state.PaymentDetail,
          BillingInfoDetail: {
            ...state.PaymentDetail.BillingInfoDetail,
            PickupLocation: 0,
          },
        },
        validationErrors: {
          ...state.validationErrors,
          [FIELD_PICKUP_LOCATION]: i18n(
            'Inventory.inventoryOutOfStock.pickup_location_out_of_stock_message',
            null,
            null,
            {
              returnNullWhenEmpty: true,
            }
          ),
        },
        PickupLocations: handledPickupLocationFiltered,
        PickupLocationsFormatted: handledPickupLocationFormatted,
      };
    }

    case UPDATE_PICKUP_LOCATIONS: {
      let pickupLocationsFiltered = action.data || [];
      const disabledPickupLocation = _get(
        app_state,
        'App.configuration.disabled_pickup_location',
        []
      );
      const isInventory = !!_get(app_state, 'ReviewDetails.product.isInventory', null);
      if (!isInventory) {
        pickupLocationsFiltered = pickupLocationsFiltered?.filter(({ trt_id }) => {
          return disabledPickupLocation.indexOf(trt_id) === -1;
        });
      }
      const fleetAssignedLocationId = _get(
        app_state,
        'ReviewDetails.product.data.FleetAssignedLocationId',
        null
      );
      const isUsedInventory = !!_get(app_state, 'ReviewDetails.product.isUsedInventory', null);
      const isNewInventory = isInventory && !isUsedInventory;
      const titleSubStatus = _get(app_state, 'ReviewDetails.product.data.TitleSubStatus', null);
      const isBrandNewInventory = isInventory && titleSubStatus === 'Brand-New';
      const vrl = _get(app_state, 'ReviewDetails.product.data.Vrl', null);
      const availablePickupCenters = _get(
        app_state,
        'ReviewDetails.product.data.AvailablePickupCenters',
        []
      );
      const isComboHashInventory = _get(app_state, 'ReviewDetails.product.isComboInventory');
      const market = _get(app_state, 'OMS.lexicon.market');
      const enableInventoryNewVehicleCombo = _get(
        app_state,
        'ReviewDetails.enableInventoryNewVehicleCombo'
      );

      const fixedVrl = [
        3056, // Beijing-Yizhuang-Delivery Hub
        16509, // Beijing Xiedao Tesla Center
      ];
      const isFixedPickupLocation =
        !isComboHashInventory &&
        (_get(app_state, 'ReviewDetails.isFixedPickupLocation', false) ||
          fixedVrl.includes(vrl) ||
          isNewInventory);
      const fixedLocationId = fleetAssignedLocationId || vrl;
      if (!_isEmpty(pickupLocationsFiltered) && isFixedPickupLocation && fixedLocationId) {
        pickupLocationsFiltered = pickupLocationsFiltered.filter(
          location => location.trt_id === fixedLocationId
        );
      }

      if (_isEmpty(pickupLocationsFiltered) && isNewInventory) {
        pickupLocationsFiltered = action.data || [];
      }

      const isMatchOnePickupLocation = pickupLocationsFiltered.length === 1;
      let fleetSalesRegions = _get(app_state, 'ReviewDetails.product.data.FleetSalesRegions', null);
      const isFleetSalesRegionsEmpty =
        _isEmpty(fleetSalesRegions) ||
        fleetSalesRegions.filter(region => region !== 'CN').length === 0;

      const pickupLocationAvailable = _get(
        app_state,
        'ReviewDetails.DeliveryLocations.availablePickupLocation',
        []
      );

      if (Array.isArray(pickupLocationAvailable) && isComboHashInventory) {
        pickupLocationsFiltered = pickupLocationsFiltered.filter(
          ({ trt_id }) => pickupLocationAvailable.indexOf(trt_id) !== -1
        );
      } else if (isBrandNewInventory) {
        pickupLocationsFiltered = pickupLocationsFiltered.filter(
          ({ trt_id }) => availablePickupCenters.indexOf(trt_id) !== -1
        );
      }

      if (isNewInventory) {
        let vehicleCity = _get(app_state, 'ReviewDetails.DeliveryLocations.vehicleCity', '');
        if (!_isEmpty(vehicleCity) && isComboHashInventory && enableInventoryNewVehicleCombo) {
          vehicleCity = vehicleCity.replace(/[a-zA-Z0-9]/g, '').trim();
          pickupLocationsFiltered = pickupLocationsFiltered.filter(
            ({ city }) => city === vehicleCity
          );
        } else if (vrl === null || (vrl && !isMatchOnePickupLocation && isFleetSalesRegionsEmpty)) {
          pickupLocationsFiltered = [];
        }
      }
      // Filter pickup locations by fleetSalesRegion's city
      if (!isFleetSalesRegionsEmpty && !(isComboHashInventory && enableInventoryNewVehicleCombo)) {
        fleetSalesRegions = fleetSalesRegions.filter(region => region !== 'CN'); // Remove "CN" from the fleetSalesRegions array
        const len = fleetSalesRegions.length;
        if (isNewInventory && len > 1 && len < 56) {
          let formatFleetSalesRegions = _map(fleetSalesRegions, region =>
            region.replace(/[a-z]|[A-Z]|\s+/g, '')
          );
          pickupLocationsFiltered = pickupLocationsFiltered.filter(location => {
            return formatFleetSalesRegions.filter(region => region === location.city).length;
          });
        } else {
          const shouldFilterByCity = len === 1;
          if (shouldFilterByCity) {
            const fleetSalesCity = _head(fleetSalesRegions)?.replace(/[a-z]|[A-Z]|\s+/g, ''); // remove English chars
            pickupLocationsFiltered = pickupLocationsFiltered.filter(
              location => location.city === fleetSalesCity
            );
          }
        }
      }

      let pickupLocation = 0;
      if (pickupLocationsFiltered.length === 1) {
        pickupLocation = _get(_head(pickupLocationsFiltered), 'trt_id', 0);
      }

      const pickupLocationPlaceHolder =
        market === 'CN'
          ? [
              {
                key: 0,
                value: '',
                label: '',
                disabled: true,
              },
            ]
          : [
              {
                key: 0,
                value: 0,
                label: '--',
              },
            ];

      let pickupLocationsFormatted = [];
      if (pickupLocationsFiltered) {
        pickupLocationsFormatted =
          pickupLocationsFiltered.length === 1 ||
          (isComboHashInventory && enableInventoryNewVehicleCombo)
            ? pickupLocationPlaceHolder.concat(
                _map(pickupLocationsFiltered, pickUpLocation => ({
                  key: `${pickUpLocation.trt_id}`,
                  value: `${pickUpLocation.trt_id}`,
                  label: pickUpLocation.chinese_title,
                  province: pickUpLocation.province,
                }))
              )
            : pickupLocationPlaceHolder;
      }
      const pickupLocationProvincePlaceHolder =
        market === 'CN'
          ? [
              {
                key: 0,
                value: '',
                label: '',
                disabled: true,
              },
            ]
          : [
              {
                key: 0,
                value: 0,
                label: '--',
              },
            ];

      const pickupLocationProvinces = _map(
        _uniq(_map(pickupLocationsFiltered, 'province')),
        (province, idx) => ({
          key: idx + 1,
          value: province,
          label: province,
        })
      );
      let pickupLocationsProvinceFormatted = [];
      if (pickupLocationsFiltered) {
        pickupLocationsProvinceFormatted =
          pickupLocationsFiltered.length === 1
            ? pickupLocationProvinces
            : pickupLocationProvincePlaceHolder.concat(pickupLocationProvinces);
      }

      return {
        ...state,
        PaymentDetail: {
          ...state.PaymentDetail,
          BillingInfoDetail: {
            ...state.PaymentDetail.BillingInfoDetail,
            PickupLocation: pickupLocation,
          },
        },
        PickupLocations: pickupLocationsFiltered,
        PickupLocationsFormatted: pickupLocationsFormatted,
        PickupLocationsProvinceFormatted: pickupLocationsProvinceFormatted,
      };
    }

    case PICKUP_LOCATION_PROVINCE_CHANGED: {
      const selectedValue = action.payload;
      let pickupLocationId = 0; // clean up PickupLocation in BillingInfoDetail
      const pickupLocations = _get(state, 'PickupLocations');
      const market = _get(app_state, 'OMS.lexicon.market');
      const pickupLocationPlaceHolder =
        market === 'CN'
          ? [
              {
                key: 0,
                value: '',
                label: '',
                disabled: true,
              },
            ]
          : [
              {
                key: 0,
                value: 0,
                label: '--',
              },
            ];

      const pickupLocationsFiltered = selectedValue
        ? pickupLocations.filter(location => location.province === selectedValue)
        : [];
      let sortedPickupLocations = _map(
        _orderBy(pickupLocationsFiltered, ['title']),
        pickUpLocation => ({
          key: `${pickUpLocation.trt_id}`,
          value: pickUpLocation.trt_id,
          label: pickUpLocation.chinese_title,
          province: pickUpLocation.province,
        })
      );
      if (market === 'CN') {
        //get the fallback pickup location
        const fallbackPickupLocationList =
          sortedPickupLocations.filter(item => {
            return defaultSelectedPickupLocationList.indexOf(item.value) !== -1;
          }) ?? [];

        //sort the list of pickup location
        const pickupLocationLeft = _pull(sortedPickupLocations, _head(fallbackPickupLocationList));
        sortedPickupLocations = fallbackPickupLocationList.concat(pickupLocationLeft);

        //setup fallback pickup location for each province
        pickupLocationId =
          _head(fallbackPickupLocationList)?.value ??
          _get(_head(sortedPickupLocations), 'value', 0);
      }
      const pickupLocationsFormatted = pickupLocationsFiltered
        ? pickupLocationPlaceHolder.concat(sortedPickupLocations)
        : pickupLocationPlaceHolder;
      return {
        ...state,
        PaymentDetail: {
          ...state.PaymentDetail,
          BillingInfoDetail: {
            ...state.PaymentDetail.BillingInfoDetail,
            PickupLocation: pickupLocationId,
          },
        },
        PickupLocationsFormatted: pickupLocationsFormatted,
      };
    }

    case POST_PAYMENT_COMPLETE: {
      return {
        ...state,
        transactionNumber: action.transactionNumber,
      };
    }

    case PRICE_CHANGED: {
      const { CurrencyCode } = state || {};
      return {
        ...state,
        orderPayment: getOrderPayment(app_state, { CurrencyCode }),
      };
    }

    case UPDATE_ORDER_PAYMENT: {
      return {
        ...state,
        orderPayment: {
          amount: action?.amount || 0,
        },
      };
    }

    case UPDATE_PAYMENT_OVERVIEW_FLAG:
      return {
        ...state,
        showPaymentOverview: action?.flag,
      };

    default:
      return state;
  }
}
