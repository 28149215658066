import React from 'react';
import { connect } from 'react-redux';
import { string, bool, shape } from 'prop-types';
import _get from 'lodash/get';
import classnames from 'classnames';
import { i18n, isIOS } from 'utils';
import InfoBlock from '../InfoBlock';

const InventoryConditionBlock = ({
  dmsUrl,
  isLayoutMobile,
  showVehicleInspection,
  vehicleDetails,
  isUsedInventory,
  isWeChatMiniEnv,
  sibling,
}) => {
  const { inspectionDocumentGuid } = vehicleDetails;
  if (!isUsedInventory || !showVehicleInspection || !inspectionDocumentGuid) {
    return null;
  }
  let cardTitle = '';
  let cardTitleLink = '';
  if (showVehicleInspection && inspectionDocumentGuid) {
    cardTitle = i18n('Inventory.vehicleDetails.testReport');
    cardTitleLink = dmsUrl + inspectionDocumentGuid;
    if (isWeChatMiniEnv && !isIOS()) {
      cardTitleLink = `${sibling}/configurator/api/v3/wechat_mini_pdf_view?url=${encodeURIComponent(
        `${sibling}${cardTitleLink}`
      )}`;
    }
  }

  return (
    <div
      className={classnames('vehicleDetails-block-container', {
        'tds--vertical_padding--6x': isLayoutMobile,
      })}
    >
      <InfoBlock
        name="additionalDetailsBlock"
        titleSection={i18n('Inventory.vehicleDetails.conditionNotificationLabel')}
        cardTitle={cardTitle}
        cardTitleLink={cardTitleLink}
        additionalInfoAfterButton={[i18n('Inventory.vehicleDetails.deliveryStandard')]}
        additionalInfoAfterButtonClass="tds-text--caption"
      />
    </div>
  );
};

InventoryConditionBlock.propTypes = {
  dmsUrl: string,
  isLayoutMobile: bool,
  showVehicleInspection: bool,
  vehicleDetails: shape({}),
  isUsedInventory: bool.isRequired,
  isWeChatMiniEnv: bool.isRequired,
  sibling: string,
};

InventoryConditionBlock.defaultProps = {
  dmsUrl: '',
  FactoryGatedDate: '',
  isLayoutMobile: false,
  showVehicleInspection: false,
  vehicleDetails: {},
  isUsedInventory: false,
  isWeChatMiniEnv: false,
};

function mapStateToProps(state) {
  const product = _get(state, 'ReviewDetails.product', {});
  const { App } = state;
  const { isLayoutMobile, isWeChatMiniEnv, sibling } = App;
  const { data: inventoryData = {}, isUsedInventory = false } = product;
  const { InspectionDocumentGuid: inspectionDocumentGuid } = inventoryData || {};

  const showVehicleInspection = _get(state, 'ReviewDetails.showVehicleInspection', false);
  const dmsUrl = _get(state, 'App.routes.getDMSFiles', '');

  const vehicleDetails = {
    inspectionDocumentGuid,
  };

  return {
    dmsUrl,
    isLayoutMobile,
    showVehicleInspection,
    vehicleDetails,
    isUsedInventory,
    isWeChatMiniEnv,
    sibling,
  };
}

export default connect(mapStateToProps)(InventoryConditionBlock);
