import React, { useRef, useEffect } from 'react';
import { string, func } from 'prop-types';
import { connect } from 'react-redux';
import { i18n, htmlToReact, getInventoryLink } from 'utils';
import Analytics from 'analytics';
import { externalLinkClicked } from 'actions';
import _get from 'lodash/get';

const InventoryUpsellSection = ({ modelCode, locale, externalClicked, sibling }) => {
  const targetContainerRef = useRef();

  let observer;
  const options = {
    root: null,
    rootMargin: '0% 0% 0% 0%',
    threshold: 0.2,
  };

  const handleCallback = entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        observer.unobserve(targetContainerRef.current);
        observer.disconnect();
        Analytics.fireTagEvent({
          event: Analytics.event,
          interaction: 'view-inventory-link',
          'cfg-type': Analytics.cfgType,
        });
      }
    });
  };

  const triggerAnalytics = () => {
    Analytics.fireTagEvent({
      event: Analytics.event,
      interaction: 'click-inventory-link',
      'cfg-type': Analytics.cfgType,
    });
  };

  useEffect(() => {
    if (targetContainerRef.current) {
      observer = new IntersectionObserver(handleCallback, options);
      observer.observe(targetContainerRef.current);
    }
  }, []);

  return (
    <div
      className="view-available-inventory"
      ref={targetContainerRef}
      onClick={triggerAnalytics.bind(null, externalClicked)}
      onKeyPress={triggerAnalytics.bind(null, externalClicked)}
      role="presentation"
    >
      {htmlToReact(
        i18n(
          'common.viewAvailableInventory',
          {
            LINK: getInventoryLink({ sibling, modelCode, locale }),
            LINK_TARGET: '_blank',
          },
          null,
          { specificOverride: locale }
        )
      )}
    </div>
  );
};

InventoryUpsellSection.propTypes = {
  modelCode: string,
  locale: string,
  externalClicked: func,
  sibling: string.isRequired,
};

InventoryUpsellSection.defaultProps = {
  modelCode: '',
  locale: '',
  externalClicked: () => {},
};

function mapStateToProps(state) {
  const { App } = state;
  const { siblingBase: sibling, locale } = App;
  return {
    modelCode: _get(state, 'OMS.oms_params.model'),
    locale,
    sibling,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    externalClicked: props => {
      dispatch(externalLinkClicked(props));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryUpsellSection);
