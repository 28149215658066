import React from 'react';
import { connect } from 'react-redux';
import { bool, string, arrayOf, shape } from 'prop-types';
import { i18n, getTerritoryCodeList } from 'utils';
import { getDeliveryRegionDisclaimer, getIsFalconDeliverySelectionEnabled } from 'selectors';
import { setSummaryPanelRegion } from 'actions';
import RegistrationTypeSelector from '../../../components/RegistrationTypeSelector';
import StateDropdown from '../../../components/StateDropdown';
import Icon from '../../../components/GroupComponents/Icon';

const RegistrationTypeSection = ({
  showRegistrationAboveSummary,
  showTerritoryList,
  fleetSalesRegions,
  deliveryRegionDisclaimer,
  isFalconDeliverySelectionEnabled,
  showRegistrationTypeSelector,
  isNativePaymentEnabled,
  setRegion,
  readOnly,
}) => {
  if (!showRegistrationAboveSummary) {
    return null;
  }
  return (
    <div className="cf-selection__driverType tds--vertical_padding">
      <If condition={!readOnly && (showTerritoryList || fleetSalesRegions.length > 1) && !isFalconDeliverySelectionEnabled}>
        <div className="financial--form-cash-territory">
          <StateDropdown onChange={setRegion} id="payment-overview--state-selector" />
        </div>
      </If>
      <If condition={deliveryRegionDisclaimer && !isFalconDeliverySelectionEnabled}>
        <div className="tds-o-flex tds--vertical_padding tds-text--caption tds-text_color--black">
          <Icon id="tds-info" style={{ width: '30px', height: '35px' }} />
          <p>{deliveryRegionDisclaimer}</p>
        </div>
      </If>

      <If condition={!readOnly && showRegistrationTypeSelector && !isNativePaymentEnabled}>
        <div className="tds--vertical_padding">
          <RegistrationTypeSelector radioName="financing" />
        </div>
      </If>

      <span className="summary-page--disclaimer tds-text--caption">
        {i18n('Review.summary_page__disclaimer', null, null, {
        returnNullWhenEmpty: true,
        })}
      </span>
    </div>
  );
};

function mapStateToProps(state) {
  const { FinancingOptions, App, ReviewDetails } = state;
  const { countryCode, fleetSalesRegions = [], isNativePaymentEnabled } = App;
  const { showRegistrationTypeSelector = false } = ReviewDetails;
  const { showRegistrationAboveSummary = false, showTerritoryList } = FinancingOptions;

  return {
    showRegistrationAboveSummary,
    showTerritoryList: showTerritoryList
      ? getTerritoryCodeList(countryCode)
      : null,
    fleetSalesRegions,
    deliveryRegionDisclaimer: getDeliveryRegionDisclaimer(state),
    showRegistrationTypeSelector,
    isNativePaymentEnabled,
    isFalconDeliverySelectionEnabled: getIsFalconDeliverySelectionEnabled(state),
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setRegion: regionCode => {
      dispatch(setSummaryPanelRegion(regionCode));
    },
  };
};

RegistrationTypeSection.propTypes = {
  showRegistrationAboveSummary: bool,
  showTerritoryList: arrayOf(shape({})),
  fleetSalesRegions: arrayOf(shape({})),
  deliveryRegionDisclaimer: string,
  showRegistrationTypeSelector: bool,
  isNativePaymentEnabled: bool,
  readOnly: bool,
};

RegistrationTypeSection.defaultProps = {
  showRegistrationAboveSummary: false,
  showTerritoryList: [],
  fleetSalesRegions: [],
  deliveryRegionDisclaimer: '',
  showRegistrationTypeSelector: false,
  isNativePaymentEnabled: false,
  readOnly: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationTypeSection);
