import React from 'react';
import { connect } from 'react-redux';
import { string, bool } from 'prop-types';
import _get from 'lodash/get';
import { Icon, Button } from '@tesla/design-system-react';
import { iconStatusSuccess } from '@tesla/design-system-icons';
import { constructUrl, i18n, formatAddress } from 'utils';
import classNames from 'classnames';
import { MOBILE_APP_ACTION_NAV_PRODUCT_HOME_SCREEN, MOBILE_APP_ACTION_FETCH_PRODUCT_LIST, MODAL_LARGE } from 'dictionary';
import { getLocale } from 'selectors';

const PostOrderSwapConfirmation = ({ 
  line1,
  line2,
  locale,
  reservationNumber,
  sibling,
  isNewOrderProfileUrl,
  hidePostOrderSwapLocation,
}) => {
  const onContinue = () => {
    if (window?.ReactNativeWebView) {
      const fetchProductList = JSON.stringify({
        action: MOBILE_APP_ACTION_FETCH_PRODUCT_LIST,
      });
      window?.ReactNativeWebView?.postMessage(fetchProductList);

      const navToProductHomeScreen = JSON.stringify({
        action: MOBILE_APP_ACTION_NAV_PRODUCT_HOME_SCREEN,
      });
      window?.ReactNativeWebView?.postMessage(navToProductHomeScreen);
    } else {
      const dashboardUri = isNewOrderProfileUrl ?  `teslaaccount/order/${reservationNumber}` : `teslaaccount/profile?rn=${reservationNumber}&redirect=no`;
      const url = constructUrl(dashboardUri, sibling, locale);
      window.location = url;
    }
  };

  return (
    <div className="post-order-swap-confirmation-container tds-content-container tds--padding tds-flex tds-flex--center tds-flex--justify-center">
      <div>
        <Icon data={iconStatusSuccess} className="icon-confirmation--success" />
      </div>
      <div
        className={classNames('confirmation-title tds-text--h3', {
          'tds--vertical_padding-bottom': hidePostOrderSwapLocation,
        })}
      >
        {i18n('common.orderUpdated')}
      </div>
      <If condition={!hidePostOrderSwapLocation}>
        <div>{line1}</div>
        <div className="tds--vertical_padding-bottom">{line2}</div>
      </If>
      <Button
        className="continue-btn"
        height={MODAL_LARGE}
        type="button"
        variant="primary"
        onClick={onContinue}
      >
        {i18n('common.continue')}
      </Button>
    </div>
  );
};

const mapStateToProps = state => {
  const { App } = state;
  const { sibling, isNewOrderProfileUrl = false, hidePostOrderSwapLocation } = App;
  const {
    AddressLine1: address1,
    AddressLine2: address2,
    City: city,
    ProvinceState: provinceState,
    PostalCode: postalCode,
  } = _get(state, 'ReviewDetails.product.data.PickupLocation.VrlAddress', {});
  const line1 = formatAddress({ address1, address2 });
  const line2 = formatAddress({ city, provinceState, postalCode });
  const locale = getLocale(state);
  const reservationNumber = _get(state, 'ReviewDetails.product.data.ReservationNumber', '');

  return {
    line1,
    line2,
    locale,
    reservationNumber,
    sibling,
    isNewOrderProfileUrl,
    hidePostOrderSwapLocation,
  };
};

PostOrderSwapConfirmation.propTypes = {
  line1: string,
  line2: string,
  locale: string.isRequired,
  reservationNumber: string,
  sibling: string.isRequired,
  isNewOrderProfileUrl: bool.isRequired,
  hidePostOrderSwapLocation: bool,
};

PostOrderSwapConfirmation.defaultProps = {
  line1: '',
  line2: '',
  reservationNumber: '',
  hidePostOrderSwapLocation: false,
};

export default connect(mapStateToProps)(PostOrderSwapConfirmation);