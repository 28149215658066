import {
	MODAL_OPEN,
	MODAL_CLOSE,
    MODAL_CLOSE_ALL
} from 'dictionary'

export function openModal(component_target, props) {
    return {
        type: MODAL_OPEN,
        component_target,
        props
    }
}

export function closeModal() {
    return {
        type: MODAL_CLOSE
    }
}

export function closeAllModals() {
    return {
        type: MODAL_CLOSE_ALL
    }
}

