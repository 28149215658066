import React from 'react';

const PositiveIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 15.4649L12.5407 19.0056L20.3564 11.1899"
        stroke="#12BB00"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PositiveIcon;
