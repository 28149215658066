import _get from 'lodash/get';
import _reduce from 'lodash/reduce';
import _map from 'lodash/map';
import {
    PRICE_CHANGED,
    OMS_RECEIVED_DEPENDENCIES,
    UPDATE_INCENTIVES,
    SHOW_COMBINED_SAVINGS,
} from 'dictionary';
import { formatList } from '@tesla/coin-common-components';

function massageData(response) {
  const result = _reduce(Object.assign({}, response), (result, model, type)=>{
    return Object.assign(result, ['total', 'comparisonTotal', 'savingsTotal'].includes(type) ? {[type]:model} : {
      [type]:_reduce(model, (result, model, key)=>{
        let massagedData = model.data;
        return Object.assign(result, {[key]:massagedData});
      }, {})
    });
  },{});

  return result;
}

function Financial(state = {}, action) {
    switch (action.type) {
      case OMS_RECEIVED_DEPENDENCIES:
        return Object.assign({}, state, {
          fms_incentives: action.data.incentives,
          fms_fees: action.data.fees,
          fms_lease: action.data.lease,
          fms_loan: action.data.loan,
          fms_tcc: action.data.tcc,
          fms_finplat: action.data.finplat,
        });
      case PRICE_CHANGED:
        const incentives = massageData(action.data.apiResults.incentives);

        // @todo why are we doing this regionList formatting on every PRICE_CHANGED?
        const regionList = (() => {
          const regionCodes = _map(
            _get(incentives, 'lookup.regional', []).filter(
              incentive => incentive.regionCode !== 'other'
            ),
            'regionCode'
          );
          return regionCodes.length > 1 ? formatList(regionCodes, { and: true }) : regionCodes[0];
        })();

        return Object.assign({}, state, {
          incentives,
          fees: massageData(action.data.apiResults.fees),
          tcc: massageData(action.data.apiResults.tcc),
          regionList,
        });
      case UPDATE_INCENTIVES:
        return {
          ...state,
          userSelectedIncentives: action?.incentives,
        };
      case SHOW_COMBINED_SAVINGS:
        return {
          ...state,
          showCombinedSavings: !state?.showCombinedSavings || action?.flag,
        };
      default:
        return state;
    }
}

export default Financial
