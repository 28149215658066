import { FINANCE_MODAL_OPEN, FINANCE_MODAL_CLOSE, FINANCE_MODAL_SELECT_VIEW } from 'dictionary';

export const openFinanceModal = payload => {
  return dispatch => {
    dispatch({
      type: FINANCE_MODAL_OPEN,
      payload,
    });
  };
};

export const closeFinanceModal = () => {
  return dispatch => {
    dispatch({
      type: FINANCE_MODAL_CLOSE,
    });
  };
};

export const selectFinanceView = payload => {
  return dispatch => {
    dispatch({
      type: FINANCE_MODAL_SELECT_VIEW,
      payload,
    });
  };
};
