import React, { Suspense, useState } from 'react';
import { connect } from 'react-redux';
import { string, func, bool, shape, arrayOf, any } from 'prop-types';
import classnames from 'classnames';
import { getAccessoriesModalIsOpen } from 'selectors';
import { closeAccessoriesModal } from 'actions';
import getAccessoryPanels from './util/getAccessoryPanels';
import { lazy } from '../Lazy';
import Spinner from '../Loaders/Spinner';

const CarouselModal = lazy(() => import(/* webpackChunkName: "carousel-modal" */ './index'));

const AccessoriesContainer = ({ closeModal, isCarouselOpen, panels }) => {
  const [isFadeOut, setIsFadeOut] = useState(false);
  const onCloseCarousel = () => {
    setIsFadeOut(true);
    setTimeout(() => {
      closeModal();
      setIsFadeOut(false);
    }, 500);
  };

  return (
    <div
      className={classnames('carousel-container', {
        'is-open': isCarouselOpen,
        'is-close': isFadeOut,
      })}
    >
      <If condition={isCarouselOpen && panels?.length}>
        <Suspense key="Modal.Suspense.AccessoriesContainer" fallback={<Spinner active />}>
          <CarouselModal
            onCloseCarousel={onCloseCarousel}
            id="accessories-modal"
            panels={panels}
            label="Accessories Modal"
            //   initialView={selectedView}
          />
        </Suspense>
      </If>
    </div>
  );
};

AccessoriesContainer.propTypes = {
  closeModal: func,
  isCarouselOpen: bool,
  panels: arrayOf(
    shape({
      id: string,
      label: string,
      component: any,
    })
  ),
};

const mapStateToProps = state => {
  return {
    isCarouselOpen: getAccessoriesModalIsOpen(state),
    panels: getAccessoryPanels(state),
  };
};

const mapDispatchToProps = dispatch => ({ closeModal: () => dispatch(closeAccessoriesModal()) });

export default connect(mapStateToProps, mapDispatchToProps)(AccessoriesContainer);
