import React from 'react';
import { string } from 'prop-types';
import { i18n } from 'utils';
import { StatusMessage } from '@tesla/design-system-react';

const Unavailable = ({ productType }) => {
  if (!productType) {
    return null;
  }
  const i18nKey = `FinancingOptions.unavailable__${productType}`;
  return (
    <>
      <StatusMessage
        className="tds--fade-in"
        body={i18n(`${i18nKey}.body`, null, null, { returnNullWhenEmpty: true })}
        header={i18n(`${i18nKey}.header`, null, null, { returnNullWhenEmpty: true })}
        type="warning"
        enclosed={false}
      />
      <p className="tds-text--caption">
        {i18n(`${i18nKey}.disclaimer`, null, null, { returnNullWhenEmpty: true })}
      </p>
    </>
  );
};

Unavailable.defaultProps = {
  productType: string.isRequired,
};

export default Unavailable;
