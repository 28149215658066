import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { triggerGenericAction } from 'actions';

class ActionTrigger extends Component {
  componentDidMount() {
    const target = this.getTargetElem();
    if (!target.getAttribute('data-action-click')) {
      target.setAttribute('data-action-click', 1);
      target.addEventListener('click', this.eventHandler, false);
      target.addEventListener(
        'keydown',
        e => {
          if (e.key === 'Space' || e.key === 'Enter') {
            this.eventHandler();
          }
        },
        false
      );
    }
  }

  componentWillUnmount() {
    const target = this.getTargetElem();
    if (target.getAttribute('data-action-click')) {
      target.removeAttribute('data-action-click');
      target.removeEventListener('click', this.eventHandler);
      target.removeEventListener('keydown', this.eventHandler);
    }
  }

  eventHandler = e => {
    const { dispatchAction } = this.props;
    const action = get(e, 'target.dataset.actionTrigger');
    if (action) {
      dispatchAction(action);
    }
  };

  getTargetElem = () => {
    return document.body;
  };

  render() {
    const { children } = this.props;
    return <>{children}</>;
  }
}

ActionTrigger.propTypes = {
  children: PropTypes.shape({}),
  dispatchAction: PropTypes.func.isRequired,
};

ActionTrigger.defaultProps = {
  children: null,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchAction(action) {
      dispatch(triggerGenericAction(action));
    },
  };
}

export default connect(null, mapDispatchToProps)(ActionTrigger);
