/**
 * https://bugs.chromium.org/p/chromium/issues/detail?id=1097432
 */
function hasMissingICUBug() {
    var DisplayNames = Intl.DisplayNames;
    if (DisplayNames && !DisplayNames.polyfilled) {
        return (new DisplayNames(['en'], {
            type: 'region',
        }).of('CA') === 'CA');
    }
    return false;
}
/**
 * Custom shouldPolyfill check to avoid checking for:
 * https://bugs.chromium.org/p/chromium/issues/detail?id=1176979
 * which returns true in Chromium
 */
const shouldPolyfill = function _shouldPolyfillWithoutLocale() {
    return !Intl.DisplayNames || hasMissingICUBug();
}

async function polyfillIntlDisplayNames(locale) {
	const unsupportedLocale = shouldPolyfill();
	if (!unsupportedLocale) {
		return true;
	}
	await import('@formatjs/intl-displaynames/polyfill-force');
	switch (locale) {
		case 'en_CA':
			await import('@formatjs/intl-displaynames/locale-data/ca');
			break;
		case 'fr_CA':
			await import('@formatjs/intl-displaynames/locale-data/fr-CA');
			break;
		case 'es_MX':
			await import('@formatjs/intl-displaynames/locale-data/es-MX');
			break;
		case 'en_PR':
			await import('@formatjs/intl-displaynames/locale-data/en-PR');
			break;
		case 'es_PR':
			await import('@formatjs/intl-displaynames/locale-data/es-PR');
			break;
		case 'es_CL':
			await import('@formatjs/intl-displaynames/locale-data/es-CL');
			break;
		case 'de_AT':
			await import('@formatjs/intl-displaynames/locale-data/de-AT');
			break;
		case 'nl_BE':
			await import('@formatjs/intl-displaynames/locale-data/nl-BE');
			break;
		case 'fr_BE':
			await import('@formatjs/intl-displaynames/locale-data/fr-BE');
			break;
		case 'cs_CZ':
			await import('@formatjs/intl-displaynames/locale-data/cs');
			break;
		case 'da_DK':
			await import('@formatjs/intl-displaynames/locale-data/da');
			break;
		case 'fi_FI':
			await import('@formatjs/intl-displaynames/locale-data/fr');
			break;
		case 'fr_FR':
			await import('@formatjs/intl-displaynames/locale-data/fr');
			break;
		case 'de_DE':
			await import('@formatjs/intl-displaynames/locale-data/de');
			break;
		case 'en_GB':
			await import('@formatjs/intl-displaynames/locale-data/en-GB');
			break;
		case 'en_IE':
			await import('@formatjs/intl-displaynames/locale-data/en-IE');
			break;
		case 'it_IT':
			await import('@formatjs/intl-displaynames/locale-data/it');
			break;
		case 'de_LU':
			await import('@formatjs/intl-displaynames/locale-data/de-LU');
			break;
		case 'fr_LU':
			await import('@formatjs/intl-displaynames/locale-data/fr-LU');
			break;
		case 'nl_NL':
			await import('@formatjs/intl-displaynames/locale-data/nl');
			break;
		case 'no_NO':
			await import('@formatjs/intl-displaynames/locale-data/no');
			break;
		case 'pt_PT':
			await import('@formatjs/intl-displaynames/locale-data/pt');
			break;
		case 'es_ES':
			await import('@formatjs/intl-displaynames/locale-data/es');
			break;
		case 'de_CH':
			await import('@formatjs/intl-displaynames/locale-data/de-CH');
			break;
		case 'fr_CH':
			await import('@formatjs/intl-displaynames/locale-data/fr-CH');
			break;
		case 'it_CH':
			await import('@formatjs/intl-displaynames/locale-data/it-CH');
			break;
		case 'is_IS':
			await import('@formatjs/intl-displaynames/locale-data/is');
			break;
		case 'el_GR':
			await import('@formatjs/intl-displaynames/locale-data/el');
			break;
		case 'hr_HR':
			await import('@formatjs/intl-displaynames/locale-data/hr');
			break;
		case 'pl_PL':
			await import('@formatjs/intl-displaynames/locale-data/pl');
			break;
		case 'sl_SI':
			await import('@formatjs/intl-displaynames/locale-data/sl');
			break;
		case 'en_AE':
			await import('@formatjs/intl-displaynames/locale-data/en-AE');
			break;
		case 'ar_AE':
			await import('@formatjs/intl-displaynames/locale-data/ar-AE');
			break;
		case 'he_IL':
			await import('@formatjs/intl-displaynames/locale-data/he');
			break;
		case 'ar_QA':
			await import('@formatjs/intl-displaynames/locale-data/ar-QA');
			break;
		case 'zh_CN':
			await import('@formatjs/intl-displaynames/locale-data/zh');
			await import('@formatjs/intl-displaynames/locale-data/zh-Hans');
			await import('@formatjs/intl-displaynames/locale-data/zh-Hant');
			break;
		case 'en_AU':
			await import('@formatjs/intl-displaynames/locale-data/en-AU');
			break;
		case 'en_HK':
			await import('@formatjs/intl-displaynames/locale-data/en-HK');
			break;
		case 'zh_HK':
			await import('@formatjs/intl-displaynames/locale-data/zh');
			await import('@formatjs/intl-displaynames/locale-data/zh-Hans-HK');
			await import('@formatjs/intl-displaynames/locale-data/zh-Hant-HK');
			break;
		case 'ja_JP':
			await import('@formatjs/intl-displaynames/locale-data/ja');
			break;
		case 'en_MO':
			await import('@formatjs/intl-displaynames/locale-data/en-MO');
			break;
		case 'zh_MO':
			await import('@formatjs/intl-displaynames/locale-data/zh');
			await import('@formatjs/intl-displaynames/locale-data/zh-Hans-MO');
			await import('@formatjs/intl-displaynames/locale-data/zh-Hant-MO');
			break;
		case 'en_AU':
			await import('@formatjs/intl-displaynames/locale-data/en-AU');
			break;
		case 'en_NZ':
			await import('@formatjs/intl-displaynames/locale-data/en-NZ');
			break;
		case 'ko_KR':
			await import('@formatjs/intl-displaynames/locale-data/ko');
			break;
		case 'zh_TW':
			await import('@formatjs/intl-displaynames/locale-data/zh');
			await import('@formatjs/intl-displaynames/locale-data/zh-Hans');
			await import('@formatjs/intl-displaynames/locale-data/zh-Hant');
			break;
		case 'en_SG':
			await import('@formatjs/intl-displaynames/locale-data/en-SG');
			break;
		case 'hu_HU':
			await import('@formatjs/intl-displaynames/locale-data/hu');
			break;
		case 'en_MY':
			await import('@formatjs/intl-displaynames/locale-data/en-MY');
			break;
		case 'ro_RO':
			await import('@formatjs/intl-displaynames/locale-data/ro');
			break;
		case 'th_TH':
			await import('@formatjs/intl-displaynames/locale-data/th');
			break;
		case 'tr_TR':
			await import('@formatjs/intl-displaynames/locale-data/tr');
			break;
		default:
			await import('@formatjs/intl-displaynames/locale-data/en');
	}
	return true;
}

export {
	polyfillIntlDisplayNames,
};