import React from 'react';
import { connect } from 'react-redux';
import { string, shape, bool } from 'prop-types';
import { parseSelectedBy } from 'utils';
import { Card } from '@tesla/design-system-react';
import TextLoader from '../TextLoader';

/* StatusCard component */
const StatusCard = ({ group, isSelected, variant, field, classes, containerCss }) => {
  if (!group || !isSelected || !group?.[field]) {
    return null;
  }

  return (
    <div className={containerCss}>
      <Card variant={variant} className={classes}>
        <TextLoader data={group} field={field} />
      </Card>
    </div>
  );
};

StatusCard.propTypes = {
  group: shape({}).isRequired,
  field: string,
  variant: string,
  isSelected: bool,
  classes: string,
  containerCss: string,
};

StatusCard.defaultProps = {
  field: '',
  variant: 'dense',
  isSelected: true,
  classes: '',
  containerCss: '',
};

const mapStateToProps = (state, ownProps) => {
  const { option_codes = [] } = state?.Configuration || {};
  const { selected_by } = ownProps || {};

  return {
    isSelected: selected_by ? parseSelectedBy(selected_by, option_codes, [], state) : true,
  };
};

export default connect(mapStateToProps, null)(StatusCard);
