import React from 'react';
import { bool, shape, string, arrayOf, oneOfType, object } from 'prop-types';
import { connect } from 'react-redux';
import { htmlToReact, i18n } from 'utils';
import { formatCurrency } from '@tesla/coin-common-components';
import _get from 'lodash/get';
import _isString from 'lodash/isString';
import _isArray from 'lodash/isArray';
import _empty from 'lodash/isEmpty';
import cx from 'classnames';
import { Card, Icon } from '@tesla/design-system-react';
import { iconInfoFilled } from '@tesla/design-system-icons';
import { CLEAN_FUEL_REWARD, CHARGING_REBATE, UI_DATA_IDS } from 'dictionary';
import {
  getBannerContent,
  hasRegionalBannerCopy,
  isInventory as getIsInventory,
  getTaxCredit,
} from 'selectors';
import { hasSpecificUtmSource } from 'utils';
import TextLoader from '../TextLoader';
import DynamicBanner from './DynamicBanner';

const FederalTax = ({
  isContentAvailable,
  showRegionalBanner,
  showFederalTaxBanner,
  showFuelRewardBanner,
  bannerContent,
  fuelRewardContent,
  chargingRebateContent,
  incentives,
  showTaxValue,
  withoutPadding,
  locale,
  referralCode,
  dynamicBannerContentStringified,
  showDynamicBanner,
}) => {
  if (showFederalTaxBanner && showDynamicBanner) {
    return <DynamicBanner dynamicBannerContentStringified={dynamicBannerContentStringified} hasDynamicContent={showDynamicBanner} />;
  }

  if (
    !isContentAvailable ||
    (!showFederalTaxBanner && !showFuelRewardBanner && !showRegionalBanner)
  ) {
    return null;
  }
  const getContent = ({
    fuelRewardContent,
    chargingRebateContent,
    showTaxValueFlag,
    showFuelRewardBannerFlag,
    showRegionalBannerFlag,
    isProminent,
    bannerContent,
    isMultiBanner,
    referralCode,
  }) => {
    const bannerDescription = _get(bannerContent, 'description', '');
    const bannerTitle = _get(bannerContent, 'title', '');
    const bannersubTitle = _get(bannerContent, 'subtitle', '');
    let bannerLink = _get(bannerContent, 'link', '');
    if (!_empty(referralCode)) {
      const linkUrl = bannerLink?.content?.match(/href='([^']*)/)?.[1] ?? '';
      if (!_empty(linkUrl)) {
        const linkContentWithReferral = bannerLink?.content?.replace(
          /href='(\S*)/,
          `href='` + linkUrl + `?referral=${referralCode}'`
        );
        bannerLink = {
          ...bannerLink,
          content: linkContentWithReferral,
        };
      }
    }
    if (
      !bannerTitle &&
      !showTaxValueFlag &&
      !bannerDescription &&
      (!showFuelRewardBannerFlag || !fuelRewardContent) &&
      (!showRegionalBannerFlag || !chargingRebateContent)
    ) {
      return null;
    }
    return (
      <>
        <If condition={bannerTitle && (!_isString(bannerTitle) || bannerTitle.trim())}>
          <TextLoader
            data={bannerTitle}
            tag={{ component: 'strong' }}
            className={cx(
              'federal-tax--title',
              {
                'tds-text--center': !isMultiBanner,
              },
              {
                'tds-text--h3 coin-banner-title': isProminent,
              }
            )}
          />
        </If>
        <If condition={bannersubTitle && (!_isString(bannersubTitle) || bannersubTitle.trim())}>
          <TextLoader
            data={bannersubTitle}
            tag={{ component: 'strong' }}
            className={cx(
              'federal-tax--title',
              {
                'tds-text--center': !isMultiBanner,
              },
              {
                'tds-text--body coin-banner-title': isProminent,
              }
            )}
          />
        </If>
        <If condition={showTaxValueFlag}>
          <span className="federal-tax--value">
            {formatCurrency(incentives.taxCredit[0].amount)}
          </span>
        </If>
        <If condition={bannerDescription}>
          <div className="federal-tax--description">
            <span
              className={cx(
                'federal-tax--copy',
                {
                  'tds-text--center': !isMultiBanner,
                },
                {
                  'federal-tax--copy-with-link': bannerLink,
                  'tds-text--caption': isProminent,
                }
              )}
            >
              <Choose>
                <When condition={_isString(bannerDescription)}>
                  {htmlToReact(i18n(bannerDescription, { LOCALE: locale }))}
                </When>
                <Otherwise>
                  <TextLoader data={bannerDescription} transitionWrapper={false} />
                </Otherwise>
              </Choose>
            </span>
            <Choose>
              <When condition={bannerLink && _isString(bannerLink)}>
                <div className="tds-text--center">
                  {htmlToReact(i18n(bannerLink, { LOCALE: locale }))}
                </div>
              </When>
              <Otherwise>
                <If condition={bannerLink}>
                  <TextLoader
                    data={bannerLink}
                    className="federal-tax--link tds-text--caption"
                    transitionWrapper={false}
                  />
                </If>
              </Otherwise>
            </Choose>
          </div>
        </If>
        <If condition={showFuelRewardBannerFlag && fuelRewardContent}>
          <div className="federal-tax--disclaimer">
            <span className="federal-tax--icon icon-info" />
            <div className="federal-tax--description coin-banner-font--color">
              {htmlToReact(fuelRewardContent)}
            </div>
          </div>
        </If>
        <If condition={showRegionalBannerFlag && chargingRebateContent}>
          <div className="federal-tax--disclaimer">
            <span className="federal-tax--icon icon-info" />
            <div className="federal-tax--description coin-banner-font--color">
              {htmlToReact(chargingRebateContent)}
            </div>
          </div>
        </If>
      </>
    );
  };
  const bannerType = _get(bannerContent, 'type', '');
  const isInfo = bannerType === 'coin-general--info';
  const isMultiBanner = _isArray(_get(bannerContent, 'description', ''));
  const bannerContentBlock = getContent({
    fuelRewardContent,
    chargingRebateContent,
    showTaxValueFlag: showTaxValue,
    showFuelRewardBannerFlag: showFuelRewardBanner,
    showRegionalBannerFlag: showRegionalBanner,
    isProminent: !isInfo,
    bannerContent,
    isMultiBanner,
    referralCode,
  });
  if (!bannerContentBlock) {
    return null;
  }
  return (
    <div
      className={cx('tds-flex tds-flex--justify-center', {
        'tds--horizontal_padding': !withoutPadding,
      })}
    >
      <Card
        icon={
          isInfo ? (
            <Icon data={iconInfoFilled} style={{ color: 'var(--tds-color--primary)' }} />
          ) : null
        }
        highlighted={isInfo}
        variant={isInfo ? 'dense' : null}
        className={cx(
          { [bannerType]: bannerType },
          { 'coin-card--highlighted': isInfo },
          { 'tds-scrim--black tds-scrim--gradient': !isInfo },
          { 'coin-card--multibanner': isMultiBanner }
        )}
        data-id={UI_DATA_IDS?.overviewSection?.banner}
      >
        {bannerContentBlock}
      </Card>
    </div>
  );
};

FederalTax.propTypes = {
  incentives: shape({
    taxCredit: arrayOf(object),
  }),
  showTaxValue: bool,
  showFederalTaxBanner: bool,
  showFuelRewardBanner: bool,
  withoutPadding: bool,
  fuelRewardContent: string,
  chargingRebateContent: string,
  bannerContent: shape({
    title: string,
    description: string,
    link: oneOfType([shape({}), string]),
    type: string,
  }),
  isContentAvailable: bool,
  regionCode: string,
  showRegionalBanner: bool,
  locale: string.isRequired,
  referralCode: string,
  showDynamicBanner: bool,
  dynamicBannerContentStringified: string,
};

FederalTax.defaultProps = {
  incentives: {
    taxCredit: [{ amount: null }],
  },
  bannerContent: {},
  fuelRewardContent: '',
  chargingRebateContent: '',
  showTaxValue: false,
  showFederalTaxBanner: false,
  showFuelRewardBanner: false,
  content: null,
  withoutPadding: false,
  isContentAvailable: false,
  regionCode: '',
  showRegionalBanner: false,
  referralCode: '',
  showDynamicBanner: false,
  dynamicBannerContentStringified: '',
};

function mapStateToProps(state) {
  const { ReviewDetails, Financial, Banner } = state;
  const { showBanner, checkDynamicIncentive } = ReviewDetails;
  const { incentives: incentivesData = {} } = Financial;
  const { current: incentives } = incentivesData || {};
  const beforeDelivery = _get(incentives, 'beforeDelivery', []);
  const regional = _get(incentives, 'regional', []);
  const content = _get(state, 'CustomGroups.FEDERAL_TAX_CREDIT', null);
  const { fuelReward = null, chargingRebate = null } = content || {};
  const isContentAvailable = !!_get(content, 'extra_copy', []).length;
  const locale = _get(state, 'App.locale');
  const fuelRewardIncentive = beforeDelivery.some(x => x.key === CLEAN_FUEL_REWARD && x?.amount);
  const regionalIncentive = regional.some(x => x.key === CHARGING_REBATE);
  const regionCode = _get(state, 'SummaryPanel.region_code', null);
  const currentRegional = _get(state, 'Financial.incentives.current.regional[0]', {});
  const hasRegionalIncentiveConditions = regionCode
    ? _get(currentRegional, 'hasConditions', false)
    : null;
  const contentSource =
    hasRegionalIncentiveConditions && hasRegionalBannerCopy(state) ? 'regional' : '';
  const isInventory = getIsInventory(state);
  const isFederalCreditEligibleFalcon = _get(
    ReviewDetails,
    'product.data.IsFederalCreditEligible',
    false
  );
  const referralCode = _get(state, 'ApplicationFlow.referral.referralCode', '');
  const hideBannerForSpecificSource = hasSpecificUtmSource();
  const dynamicBannerContent = Banner?.bannerContentParsed?.filter(x => x?.description) || [];
  const showDynamicBanner = !!(Banner?.bannerContent?.length);

  return {
    incentives,
    showFederalTaxBanner:
      !hideBannerForSpecificSource &&
      showBanner &&
      (state.App.showFederalTaxBanner || showDynamicBanner),
    isContentAvailable: content && isContentAvailable,
    showFuelRewardBanner: !hideBannerForSpecificSource && fuelRewardIncentive && fuelReward,
    showRegionalBanner: !hideBannerForSpecificSource && regionalIncentive && chargingRebate,
    beforeDelivery,
    regional,
    locale,
    bannerContent: getBannerContent(state, { contentSource, regionCode }),
    fuelRewardContent: fuelReward,
    chargingRebateContent: chargingRebate,
    referralCode,
    dynamicBannerContentStringified: showDynamicBanner ? JSON.stringify(dynamicBannerContent) : '',
    showDynamicBanner,
  };
}

export default connect(mapStateToProps)(FederalTax);
