import React from 'react';
import { connect } from 'react-redux';
import { bool } from 'prop-types';
import { i18n, htmlToReact } from 'utils';
import _get from 'lodash/get';

const VisitTeslaFinancing = ({ showVisitTeslaFinancing }) => {
  if (!showVisitTeslaFinancing) {
    return null;
  }
  return (
    <p className="tds-text--caption tds--vertical_padding">
      {htmlToReact(i18n('SummaryPanel.disclaimers.visitTeslaFinancing'))}
    </p>
  );
};

VisitTeslaFinancing.propTypes = {
  showLink: bool,
};

VisitTeslaFinancing.defaultProps = {
  showLink: false,
};

function mapStateToProps(state) {
  const showVisitTeslaFinancing = _get(state, 'ReviewDetails.showVisitTeslaFinancing', false);

  return {
    showVisitTeslaFinancing,
  };
}

export default connect(mapStateToProps)(VisitTeslaFinancing);
