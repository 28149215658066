import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { Storage } from '@tesla/coin-common-components';
import { createBrowserHistory } from 'history';
import { LOCAL_STORAGE_KEY, LOCAL_STORAGE_KEY_REFERRAL } from 'dictionary';
import { getQueryParameters, arrayToQueryParams } from './routing';

const history = createBrowserHistory();

/**
 * Sets sub key to the local storage
 * @param {string}  subKey Storage key to set
 * @param {mixed}   dataValue Data to set
 * @param {object}  opts Options {expires, version}
 */
export function addSubKeyToStorage(subKey, dataValue, opts = {}) {
  Storage.set(`${LOCAL_STORAGE_KEY}.${subKey}`, dataValue, opts);
}

/**
 * Retrieve sub key from storage
 * @param {string}  subKey   Part of sub key of the main storage key of the app
 * @param {func}    cb       Callback function
 * @returns {object | null}
 */
export function retrieveSubKeyFromStorage(subKey) {
  return Storage.get(`${LOCAL_STORAGE_KEY}.${subKey}`);
}

/**
 * Remove sub key from storage
 * @param {string}  subKey   Part of sub key of the main storage key of the app
 */
export function removeSubKeyFromStorage(subKey) {
  Storage.remove(`${LOCAL_STORAGE_KEY}.${subKey}`);
}

export function retrieveReferralFromStorage(referral, rn, savedConfig) {
  let response = referral;
  if (_isEmpty(referral)) {
    if (rn && !savedConfig) {
      // Check local storage for referral, apply & remove
      const data = retrieveSubKeyFromStorage(LOCAL_STORAGE_KEY_REFERRAL);
      if (data) {
        // None expired data, set to state
        const referralCode = _get(data, 'referralCode', '');
        const isValid = _get(data, 'isValid', false);
        if (isValid && referralCode) {
          // Add referral to query param
          const query = getQueryParameters();
          const referralQuery = { ...query, referral: referralCode };
          const queryParams = arrayToQueryParams(referralQuery);
          const { hash } = window.location;
          history.replace(`?${queryParams}${hash}`, { params: referralQuery });
          response = data;
        }
        removeSubKeyFromStorage(LOCAL_STORAGE_KEY_REFERRAL);
      }
    } else {
      // Remove referral from local storage, if present
      removeSubKeyFromStorage(LOCAL_STORAGE_KEY_REFERRAL);
    }
  } else {
    // Remove referral from local storage, if present
    removeSubKeyFromStorage(LOCAL_STORAGE_KEY_REFERRAL);
  }
  return response;
}
