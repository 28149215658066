import { parseLocale } from '@tesla/parse-locale';
import { getDefaultLanguage, normalizeGrayMarket } from '@tesla/markets-vehicle';

/**
 * This method was in @tesla/markets before, but after the refactor this one is removed.
 * We rewrite it here to keep it DRY within this codebase.
 *
 * @param locale
 */
// eslint-disable-next-line import/prefer-default-export
export function normalizeGrayLocale(locale) {
  const parsedLocale = parseLocale(locale);
  const normalizedMarket = normalizeGrayMarket(parsedLocale.country);

  if (normalizedMarket !== parsedLocale.country) {
    return `${getDefaultLanguage(normalizedMarket)}_${normalizedMarket}`;
  }

  return locale;
}

/**
 * Handles T4B specific differences
 * @param locale
 * @returns {string|*}
 */
export function normalizeT4BLocale(locale) {
  return locale === 'en_IL' ? 'he_IL' : locale;
}
