import _get from 'lodash/get';
import { map, filter } from 'rxjs/operators';
import {
    COMPLETE_CONFIGURATION_CHANGED
} from 'dictionary'
import { setOption } from '../../Configuration'

export const updateSetRules = (action$, state$) =>
    action$.pipe(
        filter(action => {
            const state =state$.value;
            const { ReviewDetails } = state;
            const { vehicleDesign = {} } = ReviewDetails;
            const { isEarlyDesignSelected = false } = vehicleDesign || {};
            if (isEarlyDesignSelected) {
                return false;
            }
            switch (action.type) {
                case COMPLETE_CONFIGURATION_CHANGED:
                    return _get(state$.value, 'SetRules.set.length')
                default:
                    return false
            }
        }),
        map(action => {
            const state =state$.value;
            switch (action.type) {
                case COMPLETE_CONFIGURATION_CHANGED:
                    return setOption({ set: state.SetRules.set }, { dontKeepTrack: true })
            }
        })
    )
