import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import _isObject from 'lodash/isObject';
import _reduce from 'lodash/reduce';
import _has from 'lodash/has';
import _forOwn from 'lodash/forOwn';
import { formatCurrency } from '@tesla/coin-common-components';
import Cookie from 'js-cookie';
import Analytics from 'analytics';
import { CLID_MAP } from 'dictionary';
import { getQueryParameters } from 'utils';

/**
 * Adds new properties to cloned data object in form of ( formattedKey ) with currency formatting applied if value is numeric
 * If you pass an array, it will apply formatting to each object in array
 *
 *  USAGE:
 *
 *   utils.addFormattedCurrencyValues({
 *       amount1: 123,
 *       amount2: 1234,
 *   }, ['amount1', 'amount2'])
 *
 *  result:
 *   {
 *       amount1: 123,
 *       amount2: 1234,
 *       formattedAmount1: 123,
 *       formattedAmount2: 1234
 *   }
 *
 * @param {Object | Array} data - object containing values to be formatted
 * @param {Array} keys - Objct keys to format
 * @return {object}
 */
export function addFormattedCurrencyValues(data, keys) {

    if (!keys) {
        console.log(' addFormattedCurrencyValues -- requies keys array a 2nd param');
    }

    const arr = _isArray(data) ? data : [data]
    let results = []

    for (let i = 0, n = arr.length; i < n; i++) {
        let o = Object.assign({}, arr[i])
        for (let j = 0, m = keys.length; j < m; j++) {
            let key = keys[j];
            if (_has(o, key) && !Number.isNaN(parseFloat(o[key]))) {
                let formattedKey = `formatted${key.charAt(0).toUpperCase()}${key.slice(1)}`
                o[formattedKey] = formatCurrency(o[key])
            }
        }
        results.push(o)
    }

    return _isArray(data) ? results : results[0];
}

export function genUniqueKey(){
    return new Date().getTime().toString() + Math.random().toString(36).substr(2, 5);
}

export function objectToQueryParams(object) {
    let query = ''
    _forOwn(object, function(value, key){
        if (typeof value === 'object') {
            value.forEach(val => {
                query += `&${key}[]=${val}`
            })
        }
        else {
            query += `&${key}=${value}`
        }
    })
    if (query[0] === '&') {
        query = query.substring(1)
    }
    return query
}

export const updateUriWithQueryParams = (params, uri = '') => {
    const currentParams = getQueryParameters();
    const newParams = {...currentParams,...params};
    const urlPath = objectToQueryParams(newParams);
    const uriBase = uri || window.location.href.split('?')[0];
    return `${uriBase}?${urlPath}`;
}

/**
 * Update URL params (using history.pushState)
 * @param  {Object} currParams   current params
 * @param  {Object} historyState window history state object
 * @param  {String} name         page title
 * @param  {Object} params       set of params to apply to url query
 */
export function changeQueryParams({currentParams = {}, historyState = {}, name = '', params = {}}){
    if(!_isEmpty(params)){
        if(window.history && window.history.pushState){
            const pageTitle = name
            const stateObj  = (typeof historyState !== 'object') ? {} : historyState
            const oldParams = currentParams
            const newParams = Object.assign({}, oldParams, params)
            const urlPath   = objectToQueryParams(newParams)
            window.history.pushState(stateObj, pageTitle, '?' + urlPath)
            return true
        }
    }
    return false
}

/**
 * Get traffic source from url
 */
const getTrafficSourceFromUrl = (key = '') => {
    const params = getQueryParameters();
    if (params?.utm_source) {
        // check against utm data
        const utmSource = params?.utm_source;
        const campaignName = params?.utm_campaign;
        if (utmSource && campaignName) {
            return {
                campaignName,
                source: utmSource,
                ...(key && params?.hasOwnProperty(key) ? { id: params[key] } : {}),
            };
        }
    }
    return {};
}

/**
 * Get traffic source object
 */
const mapTrafficSource = ({ useUtm, source, storageType, storageKey, storageClickIdKey }) => {
    let id = '';
    let startDate = '';
    let campaignName = '';
    let mappedSource = source;
    switch (storageType) {
        case 'localstorage':
            try {
                const localeStorageData = localStorage.getItem(storageKey);
                const storageData = _isObject(localeStorageData) ? localeStorageData : JSON.parse(localeStorageData);
                const isValid = storageData && storageData?.[storageClickIdKey] && (!storageData?.expiryDate || (storageData?.expiryDate && new Date().getTime() < storageData.expiryDate));
                if (isValid) {
                    id = storageData[storageClickIdKey];
                    startDate = storageData?.startDate;
                }
            } catch(error){}
            break;
        case 'cookie':
            try {
                const cookieStorageData = Cookie.get(storageKey);
                const cookieData = _isObject(cookieStorageData) ? cookieStorageData : JSON.parse(cookieStorageData);
                const isValid = cookieData && cookieData?.utm_campaign;
                if (isValid) {
                    id = cookieData[storageClickIdKey] || '';
                    startDate = cookieData?.timestamp;
                    campaignName = cookieData?.utm_campaign || '';
                    const utmSource = cookieData?.utm_source || '';
                    if (useUtm && utmSource) {
                        mappedSource = utmSource;
                    }
                }
            } catch(error){}
            break;
        default:
    }
    if (id || campaignName) {
        return {
            id,
            source: mappedSource,
            startDate: startDate || ~~(+new Date() / 1000),
            campaignName,
        }
    }
    return {};
}

/**
 * Get traffic source object
 * @return Object
 */
export function getTrafficSource() {
    let recentValue = null;
    let fromUrl = false;
    for (let key in CLID_MAP) {
        const val = CLID_MAP[key];
        const urlVal = getTrafficSourceFromUrl(key);
        if (!_isEmpty(urlVal)) {
            recentValue = urlVal;
            fromUrl = true;
        } else {
            const item = mapTrafficSource(val);
            if (!_isEmpty(item) && (!recentValue || recentValue?.startDate < item?.startDate)) {
                recentValue = item;
            }
        }
        if (fromUrl && _has(recentValue, 'id')) {
            return recentValue;
        }
    }
    return recentValue || {};
}

/**
 * Check if url has specific utm source
 * @return bool
 */
export function hasSpecificUtmSource() {
    const map = ['28de1f1fcd31aeeeb435859c28212023'];
    const trafficSource = getTrafficSource();
    return !!(!_isEmpty(trafficSource) && map.includes(trafficSource?.source));
}

/**
 * Get all traffic sources into an array
 * @return Array
 */
export function getTrafficSourceHistory() {
    let sources = [];
    try {
        const data = Cookie.get('_trafficid_hist');
        if (_isEmpty(data)) {
            return sources;
        }
        const cookieData = _isObject(data) ? data : JSON.parse(data);
        sources = _reduce(cookieData, (res, val, k) => {
            const isValid = val && val?.utm_campaign;
            if (isValid) {
                const {
                    clickid: id = '',
                    timestamp = '',
                    utm_campaign: campaignName = '',
                    utm_source: source = '',
                    utm_term: term = '',
                    utm_content: content = '',
                } = val;
                res.push({
                    timestamp,
                    id,
                    source,
                    timestamp: timestamp || ~~(+new Date() / 1000),
                    campaignName,
                    term,
                    content,
                });
            }
            return res;
        }, []);
    } catch(error){}
    return sources;
}

/**
 * Get duration in units
 * @param durationInMonths number
 * @param countryCode string
 * @return object
 */
export const getDutationInUnits = (durationInMonths, countryCode) => {
    let durationInUnits = {
      value: durationInMonths,
      units: '',
    };
    if (countryCode === 'CN') {
      durationInUnits.value = durationInMonths * 30;
    } else if ((durationInMonths % 12) == 0) {
      durationInUnits.value = 1;
      durationInUnits.units = '_year';
    }
    return durationInUnits;
}

export const matchAgainstEveryKey = (selectedBy, params) => {
    return Object.keys(selectedBy).every(key => {
        if (_has(params, key)) {
            const deltaVal = selectedBy[key];
            const paramVal = params[key];

            if (_isArray(paramVal)) {
                if (_isArray(deltaVal)) {
                    return paramVal.some(item => deltaVal.includes(item));
                }
                return deltaVal === 'any' || paramVal.includes(deltaVal);;
            }
            else if (_isArray(deltaVal) && deltaVal.includes(paramVal)) {
                return true;
            }
            return deltaVal === 'any' || deltaVal === paramVal;
        }
        return false;
    })
}

/**
 * Get matching deltas
 *
 * @param  {Array} deltas [array of delta objects]
 * @param  {Object} params [oms_params]
 * @return {Array}         [array of matching deltas]
 */
export function getMatchingDeltas(deltas, params) {
    return deltas.filter((delta) => {
        return matchAgainstEveryKey(delta.selected_by, params);
        // enforce specifity by sorting where most highest-specifity items are shifted to the end of array
    }).sort((a, b) => Object.keys(a.selected_by).length - Object.keys(b.selected_by).length)
}

/**
 * Find top position of the element
 * @param obj Dom element
 * @return number
 */
export const findElemTop = (obj) => {
    if (!obj) {
        return 0;
    }
    let curtop = 0;
    if (obj?.offsetParent) {
        do {
            curtop += obj?.offsetTop;
        } while (obj === obj?.offsetParent);
        return curtop;
    }
    return curtop;
}

/**
 * Header-aware scroll where element ends up top of screen
 * @param {Object} element DOM element
 */
export const scrollToTopOfElement = (element, isMobileOrTablet = false) => {
    if (element) {
        const header = isMobileOrTablet
            ? document.getElementsByClassName('gallery')[0]
            : document.getElementById('tds-site-header');
        const { bottom: headerBottom = 0 } = header?.getBoundingClientRect() || {};
        const { top: targetTop = 0 } = element?.getBoundingClientRect() || {};
        const y = targetTop + window.scrollY - headerBottom - 5; // adding 5 px to account for box shadow on tablet and mobile, looks good on desktop
        window.scrollTo({ top: y, behavior: 'smooth' });
    }
}
