import React from 'react';
import { string } from 'prop-types';
import { i18n } from 'utils';
import { Icon, Tooltip, TooltipWrapper, useTooltipState } from '@tesla/design-system-react';
import { iconInfoSmall } from '@tesla/design-system-icons';

const InfoTooltip = ({ description }) => {
  const { open, handlers, wrapperRef } = useTooltipState();

  return (
    <TooltipWrapper
      {...handlers}
      className={'text-loader--subtitle'}
      wrapperRef={wrapperRef}
      inline
      onClick={e => e.preventDefault()}
    >
      <button>
        <Icon data={iconInfoSmall} aria-label={i18n('expand', 'common.ui')} />
      </button>
      <Tooltip open={open} className="cf-tooltip-content" orientation="up">
        <span>{description}</span>
      </Tooltip>
    </TooltipWrapper>
  );
};

InfoTooltip.propTypes = {
  description: string,
};

InfoTooltip.defaultProps = {
  description: '',
};
export default InfoTooltip;
