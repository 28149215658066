import _get from 'lodash/get'
import _difference from 'lodash/difference'
import _intersection from 'lodash/intersection'
import _reduce from 'lodash/reduce'
import _isObject from 'lodash/isObject'
import _has from 'lodash/has'
import _filter from 'lodash/filter'

import { COMPLETE_CONFIGURATION_CHANGED } from 'dictionary'
import { matchesTargetValue } from 'utils'

/**
 * @description Parses vehicle configuration
 *
 * @param {Array} options - Vehicle configuration
 * @param {Object} rules - Rules defined by InitialState/index.js
 * @param {Object} app_state - Entire application state
 *
 * @returns {Boolean} rules match configuration
 */
function parseVehicleConfig(options, rules, app_state) {
  const model = _get(app_state, 'Configuration.model_code', '');
  if (rules.model && !rules.model.includes(model)) {
    return false;
  }
  if (_intersection(rules.options, options).length) {
    return true;
  }
  return false;
}

function onTarget(selected_by, app_state, target) {
  const result = _filter(selected_by, (parameters, gate) => {
    switch (gate) {
      case 'not':
        return !_reduce(parameters, (result, parameter) => {
          if (_isObject(parameter)) {
            if (parameter.car_price) {
              return result || matchesTargetValue(target, parameter.car_price, app_state);
            }
            if (parameter.configuration) {
              return result || parseVehicleConfig(target, parameter.configuration, app_state);
            }
          }
          return result;
        }, false)

      case 'and':
        return _reduce(parameters, (result, parameter) => {
          if (_isObject(parameter)) {
            if (parameter.car_price) {
              return result && matchesTargetValue(target, parameter.car_price, app_state);
            }
            if (parameter.configuration) {
              return result && parseVehicleConfig(target, parameter.configuration, app_state);
            }
          }
          return result;
        }, true)

      case 'or':
        return _reduce(parameters, (result, parameter) => {
          if (_isObject(parameter)) {
            if (parameter.car_price) {
              return result || matchesTargetValue(target, parameter.car_price, app_state);
            }
            if (parameter.configuration) {
              return result || parseVehicleConfig(target, parameter.configuration, app_state);
            }
          }
          return result;
        }, false)
    }
  })
  return !!result.length;
}

function parseRules(target, selected_by, consequence, app_state, set_action = [], overrides = []) {
  if (!target) {
    return {};
  }
  const option_codes = _get(app_state, 'Configuration.option_codes', []);
  const user_selected_options = _get(app_state, 'Configuration.user_selected_options', []);
  const res = onTarget(selected_by, app_state, target);
  let options_array = [];

  if (res) {
    const options = _get(app_state, 'OMS.lexicon.options', {});
    options_array = _difference(consequence.set, option_codes).filter(option => _has(options, option));
  }

  if (options_array.length) {
    // check for overrides vs set_action
    if (overrides.length) {
      if (set_action.length) {
        if (_intersection(overrides, set_action).length) {
          options_array = [];
        }
      }
      if (user_selected_options.length) {
        if (_intersection(overrides, user_selected_options).length) {
          options_array = [];
        }
      }
    }
    if (_intersection(options_array, option_codes).length) {
      options_array = [];
    }
  }
  return Object.assign({}, consequence, {
    set: options_array
  })
}

function SetRules(state = {}, action, { app_state }) {
  switch (action.type) {
    case COMPLETE_CONFIGURATION_CHANGED:
      const setOfRules = _reduce(_get(state, 'rules'), (result, rule) => {
        let target = _get(app_state, rule.target, '');
        const selected_by = _get(rule, 'selected_by', {});
        const consequence = _get(rule, 'consequence', {});
        const once = _get(rule, 'once', false);
        const onInitialLoad = _get(rule, 'onInitialLoad', true);
        const onOptionOnly = _get(rule, 'onOptionOnly', false);
        const id = _get(rule, 'id', null);
        if (onOptionOnly) {
          target = _get(action, 'set', []);
        }
        let ids = [..._get(state, 'ids', []),..._get(result, 'ids', [])];
        const idsOnInitialLoad = _get(state, 'idsOnInitialLoad', []);
        if (!onInitialLoad && id && !ids.includes(id) && !idsOnInitialLoad.includes(id)) {
          result = {
            ...result,
            idsOnInitialLoad: [...new Set([...idsOnInitialLoad, ...[id]])],
          };
          return result;
        }
        if (once && id && ids.includes(id)) {
          return result;
        }
        const set_action = _get(action, 'setAction', []);
        const overrides = _get(rule, 'overrides', []);

        const setRule = parseRules(target, selected_by, consequence, app_state, set_action, overrides);
        if (id) {
          ids.push(id);
        }
        result = {
          ...result, 
          set: _get(result, 'set', []).concat(_get(setRule, 'set', [])),
          ids: [...new Set(ids)],
        };
        return result;
      }, {})

      return Object.assign({}, state, setOfRules);
    default:
      return state;
  }
}

export default SetRules;
