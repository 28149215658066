/* eslint no-shadow: 0 */
/* eslint no-case-declarations: 0 */
import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { string, func, shape, oneOf, oneOfType, number, arrayOf, bool } from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { triggerLoaderAction } from 'actions';
import { getCompositorViewDescription, getCompositorAltMap, i18n, arrayToQueryParams } from 'utils';
import Loader from './loader';
import Media from '../Media';
import { isInventory, getModelName } from 'selectors';

const addQuery = ({ path, query }) => {
  let nQuery = query.includes('?') ? query.replace('?', '') : query;
  nQuery = !nQuery.charAt(0).includes('&') && path.includes('&') ? `&${nQuery}` : nQuery;
  return path.includes('?') ? `${path}${nQuery}` : `${path}?${nQuery}`;
};

const getCompositorUrl = ({
  query,
  compositor,
  view,
  bkba = 0,
  crop = [0, 0, 0, 0],
  overlay = 0,
  size = undefined,
  isDarkMode,
}) => {
  const urlParams = query ? new URLSearchParams(query) : '';
  const paramsObj = urlParams ? Object.fromEntries(urlParams) : {};
  const sizeObj = size ? { size } : {};
  const queryStr = arrayToQueryParams({
    ...paramsObj,
    ...sizeObj,
    bkba_opt: isDarkMode ? 1 : bkba,
    crop: crop.toString(),
    overlay: overlay ? 1 : 0,
  });
  const baseUrl = query
    ? _get(compositor, 'compositor_url', '')
    : _get(compositor, `views.${view}.url`, '');
  return addQuery({
    path: baseUrl,
    query: queryStr,
  });
};

/**
 * AssetLoader is used to render media
 * current supported types:
 * - image
 * - image-sprite
 * - image-gallery
 */

const AssetLoader = ({
  asset,
  baseURL,
  videoBaseURL,
  videoBaseURLMobile,
  className,
  compositor,
  shape,
  selected,
  disabled = false,
  readOnly,
  isLayoutMobile,
  isDarkMode,
  title,
  label,
  videoUrl,
  playVideo,
  modelName,
  compositorAltDescription,
  compositorAltKey,
  selectedTrim,
  onClick = () => {},
  onAction = () => {},
  isCyberpunk,
  assetCaption,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const containerRef = useRef();
  const naturalDimensionsRef = useRef({ height: null, width: null });
  const [proportionalHeight, setProportionalHeight] = useState();
  const [bgSize, setBgSize] = useState('auto');
  const {
    type,
    url,
    urlDesktop,
    yOffset,
    xOffset,
    zoom = 1,
    overlay = 0,
    relative = false,
    absolute = false,
    src,
    width,
    height,
    ratio = 'meet',
    action,
    image_props,
    video_props,
    video_opts,
    video_optsDesktop,
    // badgeClass,
    loader,
    asset_opts = undefined,
    bgSizeType = 'contain',
    clipTopRight: clipTopRightOpt = !!isCyberpunk && !isLayoutMobile,
  } = asset;
  if (!['unavailable', 'image-compositor'].includes(type) && !url) {
    return null;
  }
  const clipTopRight = _isEmpty(asset) ? false : clipTopRightOpt;
  const path = relative || absolute ? url : `${baseURL}${url}`;
  let parsedUrl = path ? addQuery({ path, query: '' }) || '' : '';
  const parsedID = parsedUrl.replace(/[^a-zA-Z0-9\-_:.]/gi, '_');
  const altDescriptionNonCompositor = compositorAltDescription ? compositorAltDescription : label;
  const accessibilityAltDescription = compositorAltKey
    ? i18n(`Accessibility.views.${compositorAltKey}`, {
        TRIM_NAME: selectedTrim,
      })
    : altDescriptionNonCompositor;

  const uniqueTitleID =
    accessibilityAltDescription !== ''
      ? `${modelName.replace(' ', '_')}_${altDescriptionNonCompositor.replace(' ', '_')}`
      : accessibilityAltDescription;

  const calculateHeight = () => {
    if (containerRef.current) {
      const { height: naturalHeight, width: naturalWidth } = naturalDimensionsRef.current;
      const { current: containerEl } = containerRef;

      // Checks parent container to see if a timeout is passed added to element
      // adds timeout if passed in
      const parentContainer = containerRef?.current?.parentNode;
      const timeout = Number(parentContainer?.dataset?.timeout) || asset?.timeout || 0;

      const timeoutFunc = setTimeout(() => {
        const {
          width: containerWidth,
          height: containerHeight,
        } = containerEl.getBoundingClientRect();
        const newHeight = (containerWidth * naturalHeight) / naturalWidth;

        if (bgSizeType === 'cover') {
          setProportionalHeight(null); // we should not manually restrict height if we want image to cover fill background
        } 
        else if (containerEl.style.height !== newHeight) {
          setProportionalHeight(Math.round(newHeight));
        }
        if (containerWidth < naturalWidth || containerHeight < naturalHeight) {
          setBgSize(bgSizeType);
        } else {
          setBgSize('auto');
        }
      }, timeout ? timeout : 0);
      return () => {
        clearTimeout(timeoutFunc);
      };
    }
  };
  const defaultCursor = readOnly ? 'auto' : 'inherit';

  const style = {
    overflow: 'hidden',
    cursor: action?.includes(action => action.type === 'PLAY_VIDEO') ? 'pointer' : defaultCursor,
    ...(isLoading
      ? {
          opacity: 0,
          transform: `scale(${zoom})`,
        }
      : {
          opacity: !selected && disabled ? 0.5 : 1,
          transform: `scale(${zoom})`,
        }),
    ...(proportionalHeight && ratio !== 'slice'
      ? {
          height: `${proportionalHeight}px`,
        }
      : {}),
  };

  const handleClick = () => {
    onAction(asset.action, asset);
    onClick();
  };

  // Update Height when dependencies change
  useLayoutEffect(calculateHeight, [isLoading, containerRef, naturalDimensionsRef]);

  // Recalculate height on resize
  useEffect(() => {
    window.addEventListener('resize', calculateHeight, { passive: true });

    return () => window.removeEventListener('resize', calculateHeight);
  }, []);

  // Asset Load
  useEffect(() => {
    if (!['image-compositor', 'video-inline', 'video', 'unavailable'].includes(type)) {
      setIsLoading(true);
      const onLoad = ({ target: imageEl }) => {
        const { naturalWidth, naturalHeight } = imageEl;
        naturalDimensionsRef.current = {
          height: naturalHeight,
          width: naturalWidth,
        };
        const { current: containerEl } = containerRef;
        const {
          width: containerWidth,
          height: containerHeight,
        } = containerEl.getBoundingClientRect();
        if (containerWidth < naturalWidth || containerHeight < naturalHeight) {
          setBgSize('contain');
        }
        setIsLoading(false);
      };
      const img = new Image();

      img.addEventListener('load', onLoad, false);
      img.src = parsedUrl;

      return () => img.removeEventListener('load', onLoad);
    }
  }, [parsedUrl]);

  const assetOptsSet = typeof asset_opts !== 'undefined';
  const replaceOpts = assetOptsSet && asset_opts === '' ? 'f_auto,q_auto/' : 'f_auto,q_auto';
  parsedUrl = parsedUrl && assetOptsSet ? parsedUrl.replace(replaceOpts, asset_opts) : parsedUrl;

  switch (type) {
    case 'bg-image':
      return (
        <svg
          ref={containerRef}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          className={classnames('asset-loader--svg-container', 'asset-loader-2', {
            'asset-loader--play-video': action?.includes(action => action?.type === 'PLAY_VIDEO'),
            [className]: className,
            shadow: shape === 'circular' && !selected,
            selected,
          })}
          onClick={handleClick}
          style={{
            ...style,
            background: `url("${parsedUrl}")`,
            backgroundPosition: 'center',
            backgroundSize: `${bgSize}`,
            backgroundRepeat: 'no-repeat',
          }}
          {...(uniqueTitleID ? { id: uniqueTitleID } : {})}
        >
          <title {...(uniqueTitleID ? { id: uniqueTitleID } : {})}>
            {accessibilityAltDescription}
          </title>
          <defs></defs>
        </svg>
      );
    case 'image':
      const circleProps = {
        clipPath: `url(#clip_${parsedUrl})`,
        width: '40px',
        height: '40px',
        x: '7px',
        y: '7px',
      };
      return (
        <svg
          ref={containerRef}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          className={classnames('asset-loader--svg-container', 'asset-loader-2', {
            'asset-loader--play-video': action?.includes(action => action?.type === 'PLAY_VIDEO'),
            [className]: className,
            shadow: shape === 'circular' && !selected,
            selected,
          })}
          onClick={handleClick}
          style={style}
          {...(uniqueTitleID ? { id: uniqueTitleID } : {})}
        >
          <title {...(uniqueTitleID ? { id: uniqueTitleID } : {})}>
            {accessibilityAltDescription}
          </title>
          <defs>
            {shape === 'circular' && (
              <>
                <clipPath id={`clip_${parsedID}`}>
                  <circle
                    cx="50%"
                    cy="50%"
                    r={24}
                    fill="#FFFFFF"
                    stroke="#3E6AE1"
                    strokeWidth="0"
                  />
                </clipPath>
                <filter id={`filter_${parsedID}`} height="200%">
                  <feGaussianBlur in="SourceAlpha" stdDeviation="1" />
                  <feComponentTransfer>
                    <feFuncA type="linear" slope="0.3" />
                  </feComponentTransfer>

                  <feOffset dx={1.3} dy={1.5} result="offsetblur" />

                  <feMerge>
                    <feMergeNode />
                    <feMergeNode in="SourceGraphic" />
                  </feMerge>
                </filter>
              </>
            )}
          </defs>
          <image
            width="100%"
            height="100%"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xlinkHref={parsedUrl}
            preserveAspectRatio={ratio === 'meet' ? 'xMidYMid meet' : 'xMidYMid slice'}
            alt={accessibilityAltDescription}
            x={xOffset ? xOffset : undefined}
            y={yOffset ? yOffset : undefined}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...image_props}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(shape === 'circular' ? circleProps : {})}
          />

          {!!action &&
            action.map((action, index) => {
              switch (action.type) {
                case 'PLAY_VIDEO':
                  return (
                    <svg
                      width="25%"
                      height="25%"
                      x="38%"
                      y="38%"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      clipPath={`url(#clip_${parsedUrl})`}
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 50 50"
                      key={`${parsedUrl}_asset:${index}`}
                      alt={label}
                    >
                      <g id="Page-1_1_" fill="white">
                        <path
                          id="Fill-2"
                          d="M25,0C11.2,0,0,11.2,0,25s11.2,25,25,25s25-11.2,25-25S38.8,0,25,0 M25,2.5
                                      c12.4,0,22.5,10.1,22.5,22.5S37.4,47.5,25,47.5S2.5,37.4,2.5,25S12.6,2.5,25,2.5"
                        />
                        <polygon id="Path-2" points="20,15 35,25 20,35" />
                      </g>
                    </svg>
                  );
                case 'ZOOM_IMAGE':
                  return (
                    <text
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${parsedUrl}_icon:${index}`}
                      x="85%"
                      y="90%"
                      className="tsla-icon-zoom-img"
                      fontSize="200%"
                      stroke="black"
                      strokeWidth="0"
                      fill="white"
                      cursor="pointer"
                    >
                      &#xe90d;
                    </text>
                  );
                default:
                  return null;
              }
            })}
        </svg>
      );
    case 'video':
      return (
        <div
          className={classnames('asset-loader-2', {
            [className]: className,
          })}
          onClick={handleClick}
          style={style}
          role="presentation"
          ref={containerRef}
        >
          <iframe
            title="AssetLoader"
            src={src}
            width={width}
            height={height}
            frameBorder="0"
            allowFullScreen
          />
        </div>
      );
    case 'video-inline':
      const { loop, muted, autoPlay, controls, playsInline, showVideoTitle } = video_props || {};
      const mediaURL = isLayoutMobile ? url : (urlDesktop || url);
      let videoBaseMobile = videoBaseURLMobile;
      let videoBaseDesktop = videoBaseURL;
      if (video_opts && videoBaseURLMobile) {
        // update videoBase
        videoBaseMobile = videoBaseURLMobile.replace('f_auto:video,q_auto:best', video_opts);
      }
      if (video_optsDesktop && videoBaseURL) {
        videoBaseDesktop = videoBaseURL.replace('f_auto:video,q_auto:best', video_optsDesktop);
      }
      const videoBase = isLayoutMobile ? videoBaseMobile : videoBaseDesktop;
      const urlPath = relative ? mediaURL : `${videoBase}${mediaURL}`;
      const videoSrcURL = videoUrl || urlPath;
      const videoSrcDesktop = relative ? urlDesktop : `${videoBaseDesktop}${urlDesktop}`;
      const videoSrcBase = relative ? url : `${videoBase}${url}`;
      return (
        <div className="asset-loader-2-video-inline" role="presentation">
          <If condition={showVideoTitle}>
            <h1 className="asset-loader-2-video-title tds-text--h4" id={`video-title-${label || title}`}></h1>
          </If>
          <Media
            loop={loop}
            muted={muted}
            autoPlay={autoPlay}
            controls={controls}
            playsInline={playsInline}
            type="video"
            data-src={videoSrcBase}
            data-src-desktop={videoSrcDesktop}
            src={videoSrcURL}
            title={title}
            playVideo={playVideo}
            id={`video-id-${label || title}`}
            assetCaption={assetCaption}
          />
        </div>
      );
    case 'image-compositor':
      const compositorUrl = getCompositorUrl({ ...asset, compositor, isDarkMode });
      return (
        <Choose>
          <When condition={loader}>
            <div className={classnames({ 'asset-zoom-wrap': zoom }, className, 'asset-compositor')}>
              <Loader loading={isLoading} classes="assets-loader" />
              <img
                onClick={handleClick}
                className={classnames('asset-compositor--image', { isLoading })}
                key={`asset-compositor--image_${compositorUrl}`}
                src={compositorUrl}
                style={{ transform: `scale(${zoom})` }}
                onLoad={() => setIsLoading(false)}
                alt={label}
                aria-label={label}
                role="presentation"
              />
            </div>
          </When>
          <Otherwise>
            <ConnectedAssetLoader
              relative
              className={classnames(className, { 'tds-clip-top-right': clipTopRight })}
              asset={{ ...asset, type: 'image', url: compositorUrl, relative: true, zoom }}
            />
          </Otherwise>
        </Choose>
      );

    case 'unavailable':
      return <div className="asset-loader--unavailable"></div>;
    default:
      return <p className="No asset type found" />;
  }
};

AssetLoader.propTypes = {
  key: string,
  className: string,
  baseURL: string.isRequired,
  videoBaseURL: string.isRequired,
  videoBaseURLMobile: string.isRequired,
  asset: shape({
    type: oneOf(['image', 'image-compositor', 'video', 'video-inline', 'badge', 'unavailable']),
    url: string,
    yOffset: number,
    xOffset: number,
    view: string,
    ratio: string,
    relative: bool,
    action: string,
    urlDesktop: string,
    zoom: number,
    query: string,
    bkba: number,
    src: string,
    width: number,
    height: number,
    image_props: shape({}),
    video_props: shape({}),
    badgeClass: string,
    loader: bool,
    crop: arrayOf(number),
    overlay: bool,
  }),
  onClick: func,
  onLoad: func,
  sliderIndex: number,
  isLayoutMobile: bool,
  isDarkMode: bool,
  onAction: func,
  compositor: shape({}),
  shape: string,
  selected: bool,
  readOnly: bool,
  disabled: bool,
  title: string,
  label: string,
  videoUrl: string,
  style: string,
  playVideo: bool,
  modelName: string,
  compositorAltDescription: string,
  compositorAltKey: string,
  selectedTrim: oneOfType([string, shape({})]),
  isCyberpunk: bool,
  assetCaption: string,
};

AssetLoader.defaultProps = {
  className: '',
  asset: {
    type: 'image',
    url: '',
    assets: [],
    yOffset: 0,
    xOffset: 0,
    ratio: '',
    crop: [0, 0, 0, 0],
    overlay: 0,
  },
  onClick() {},
  onLoad: () => {},
  isLayoutMobile: false,
  isDarkMode: false,
  onAction() {},
  key: '',
  compositor: {},
  shape: '',
  selected: false,
  disabled: false,
  readOnly: false,
  title: '',
  label: '',
  videoUrl: '',
  style: '',
  playVideo: true, // video should play unless set to false by condition
  compositorAltDescription: '',
  compositorAltKey: '',
  selectedTrim: {},
  isCyberpunk: false,
  assetCaption: '',
};

const mapStateToProps = (state, ownProps) => ({
  isLayoutMobile: state.App.isLayoutMobile || state.App.isLayoutTablet,
  isDarkMode: state.App.isDm && !state.App.enableCyberpunk,
  isCyberpunk: state.App.enableCyberpunk,
  baseURL: state.Assets.baseURL,
  videoBaseURL: state.Assets?.videoBaseURL,
  videoBaseURLMobile: state.Assets?.videoBaseURLMobile,
  compositor: state.Compositor,
  modelName: getModelName(state),
  compositorAltDescription: getCompositorViewDescription(state, _get(ownProps, 'asset.view', '')),
  compositorAltKey: getCompositorAltMap(state, _get(ownProps, 'asset.view', '')),
  selectedTrim: isInventory(state)
    ? _get(state, 'ReviewDetails.product.data.TrimName', '')
    : _get(state, 'CustomGroups.TRIM.currentSelected[0].long_name', {}),
});

const mapDispatchToProps = dispatch => ({
  onAction: (actions, asset) => {
    if (actions) {
      dispatch(triggerLoaderAction(actions, asset));
    }
  },
});

const ConnectedAssetLoader = connect(mapStateToProps, mapDispatchToProps)(AssetLoader);
export default ConnectedAssetLoader;
