import {
  SHORT_URL_SUCCESS,
  SHORT_URL_FAIL,
  COPY_URL,
  SHARE_DESIGN_REQUEST,
  SHARE_DESIGN_SUCCESS,
  SHARE_DESIGN_FAIL,
  SHARE_DESIGN_RESET,
  SAVE_DESIGN_CLOSE,
  CLOSE_SHARE_FAIL_ALERT,
  MODAL_CLOSE,
  MODAL_OPEN,
  SAVE_DESIGN_MODAL,
  SAVE_DESIGN_GET_UPDATES,
} from 'dictionary';
import Analytics from 'analytics';

// Save Design Reducer
export default function Reducer(state = {}, action, { app_state }) {
  const { Configuration = {} } = app_state;
  const { model_code } = Configuration;
  switch (action.type) {
    case SHORT_URL_SUCCESS:
      return { ...state, shortUrl: action.shortUrl, options: action.options, linkCopied: false };
    case SHORT_URL_FAIL:
      return {
        ...state,
        shortUrl: null,
        error: {
          code: SHORT_URL_FAIL,
        },
        linkCopied: false,
      };
    case COPY_URL:
      return { ...state, linkCopied: true };
    case SHARE_DESIGN_REQUEST:
      return {
        ...state,
        method: action?.method,
        email: action?.email,
        phoneNumber: action?.phoneNumber,
      };
    case SHARE_DESIGN_RESET:
      return {
        ...state,
        showAlert: false,
        failedMethod: undefined,
      }
    case SHARE_DESIGN_SUCCESS:
      return {
        ...state,
        shareDesignRequestState: 'success',
        showAlert: false,
        failedMethod: undefined,
      };
    case SHARE_DESIGN_FAIL:
      return {
        ...state,
        shareDesignRequestState: 'failed',
        showAlert: true,
        failedMethod: action.method,
      };
    case CLOSE_SHARE_FAIL_ALERT:
      return { ...state, showAlert: false };
    case SAVE_DESIGN_GET_UPDATES:
      return { ...state, getUpdates: action?.flag || false };
    case MODAL_OPEN:
      if (action.component_target === SAVE_DESIGN_MODAL) {
        Analytics.fireTagEvent({
          event: Analytics.event,
          interaction: 'save-your-design',
          'cfg-type': model_code,
        });
      }
      return {
        ...state,
        isSaveModalOpen: action.component_target === SAVE_DESIGN_MODAL ? true : false,
      };
    case MODAL_CLOSE:
      const { isSaveModalOpen } = state;
      if (isSaveModalOpen) {
        Analytics.fireTagEvent({
          event: Analytics.event,
          interaction: 'save-design-exit',
          'cfg-type': model_code,
        });
      }
      return {
        ...state,
        isSaveModalOpen: false,
        shareDesignRequestState: null,
        shortUrl: null,
        options: [],
      };
    default:
      return state;
  }
}
