import React, { Suspense } from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { getUpgradeOptionPrice } from 'utils';
import { isInventory as getIsInventory } from 'selectors';
import { COMPARE_MODEL_AND_FEATURE } from '../../../common/dictionary';
import GroupComponent from '../../GroupComponents/GroupComponent';
import { lazyWithPreload } from '../../Lazy';

const GroupFeatureHighlight = lazyWithPreload(() =>
  import(
    /* webpackChunkName: "group-feature-highlight" */ '../FeatureHighlight/GroupFeatureHighlight'
  )
);
const FeatureGroup = lazyWithPreload(() => import(/* webpackChunkName: "feature-group" */ '../FeatureGroup'));

export default state => {
  const {
    CustomGroups,
    App: { isLayoutMobile },
    ReviewDetails: { vehicleUpgrades: upgradeOptions = [], hideActionField = [] },
    FeatureListModal: { groupName },
  } = state;
  const groupCodes = _get(CustomGroups, `${groupName}.groups`, []).filter(
    code => CustomGroups[code]
  );
  const isInventory = getIsInventory(state);

  // TODO: Optimize this into a reducer
  const panels = groupCodes
    .map(groupCode => {
      const option = CustomGroups[groupCode];
      const components = _get(option, 'components', []);
      const { options = [], current = [], hidden: { isHidden = false } = {} } = option;
      const { code: optCode = '' } = current[0] || option || {};
      const isNoUpgradeFeature = hideActionField.includes(optCode);
      const upgradePriceObj = upgradeOptions.length
        ? getUpgradeOptionPrice(upgradeOptions, optCode)
        : {};
      const upgradePrice = upgradePriceObj?.basePrice || 0;
      const upgradePriceFormatted = upgradePriceObj?.basePriceFormatted || 0;
      const extraPrice = upgradePriceObj?.extraPrice || null;
      if (
        (options.length && _isEmpty(current)) ||
        isHidden ||
        (!isNoUpgradeFeature && !_isEmpty(upgradePriceObj) && upgradeOptions.length && !upgradePrice)
      ) {
        return null;
      }

      if (!_isEmpty(option.child_groups)) {
        return {
          id: option.code,
          label: option.name,
          component: (
            <Suspense fallback={<></>}>
              <FeatureGroup
                groups={option}
                CustomGroups={CustomGroups}
                isLayoutMobile={isLayoutMobile}
              />
            </Suspense>
          ),
        };
      }
      const id = option.code;
      const modalName = id.substring(0, id.indexOf('_CARD'));
      if (components.length) {
        return {
          id: option.code,
          label: option.name,
          component: (
            <div className="carousel-modal--panel-content" data-id={`${modalName}-modal`}>
              <GroupComponent
                group={option}
                components={components}
                classes="groupComponent--container"
              />
            </div>
          ),
        };
      }
      const optData = extraPrice
        ? { ...option, extraPrice, price: upgradePrice, formattedPrice: upgradePriceFormatted }
        : option;
      return {
        id: option.code,
        label: option.name,
        component: (
          <Suspense fallback={<></>}>
            <GroupFeatureHighlight
              option={optData}
              upgradePrice={upgradePriceFormatted}
              upgradePriceExtra={extraPrice}
              hideActionField={isNoUpgradeFeature}
            />
          </Suspense>
        ),
      };
    })
    .filter(panel => {
      if (!panel?.id) {
        return false;
      }
      if (isInventory && panel.id === COMPARE_MODEL_AND_FEATURE) {
        return false;
      }
      if (panel) {
        return true;
      }
    });

  return panels;
};
