import React, { useEffect, useState } from 'react';
import { bool, string } from 'prop-types';
import { connect } from 'react-redux';
import { NAVIGATION_VIEW_PAYMENT } from 'dictionary';
import throttle from 'lodash/throttle';
import classnames from 'classnames';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { isPreOrder, getLoadedRN } from 'utils';
import { getEstimateDeliveryDate, getDeliveryTime } from 'selectors';
import Fade from '../Transitions/Fade';
import { UI_DATA_IDS } from '../../common/dictionary';

/* Group Delivery Date component */
const DeliveryDate = ({ id, hideDelivery, value, classes, description, isCentered }) => {
  if (hideDelivery || !value) {
    return null;
  }

  return (
    <Fade transitionKey={value}>
      <p
        className={classnames(`estimated-delivery-date text-loader--${id} tds-text--contrast-low`, {
          [classes]: classes,
          "tds-text--center": isCentered,
        })}
        data-id={
          id === 'PAYMENT'
            ? UI_DATA_IDS?.overviewSection?.bottomEstimatedDeliveryDate
            : UI_DATA_IDS?.overviewSection?.estimatedDeliveryDate
        }
        key={`group-delivery-date-${id}`}
      >
        {value}
      </p>
      {description && <p className="tds-text--caption tds-text--center">{description}</p>}
    </Fade>
  );
};

export const DeliveryDateHeader = ({ value, hideDelivery, section, isCoinReloaded, enableCyberpunk }) => {
  const [show, setShow] = useState(false);
  const hide = hideDelivery || !value || section === NAVIGATION_VIEW_PAYMENT || isCoinReloaded || enableCyberpunk;

  const onScroll = throttle(() => {
    const otherDeliveryDate = document.querySelector('.estimated-delivery-date');
    const rect = otherDeliveryDate?.getBoundingClientRect();

    if (rect?.y < 0) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, 100);

  useEffect(() => {
    window.addEventListener('scroll', onScroll, { passive: true, capture: true });

    return () => window.removeEventListener('scroll', onScroll, { passive: true, capture: true });
  }, []);

  if (hide) {
    return null;
  }

  return (
    <div
      className={classnames('delivery-date-header', {
        'delivery-date-header__show': show,
      })}
    >
      <Fade in={show} transitionKey={value}>
        <p
          className={'sticky-estimated-delivery-date'}
          data-id={UI_DATA_IDS?.overviewSection?.stickyEstimatedDeliveryDate}
        >
          {value}
        </p>
      </Fade>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const { group, hideEstimatedDeliveryOrderPage = false, distance, useUnselectedOptions = false } =
    ownProps || {};
  const eddData = _get(window.tesla, 'eddData', null);
  const deliveryTimingMessage = _isEmpty(eddData)
    ? getDeliveryTime(state, { distance })
    : getEstimateDeliveryDate(state, { optionCodes: useUnselectedOptions ? group?.set || [] : [] });

  const {
    App,
    Navigation: { section },
    ReviewDetails = {},
  } = state;
  const {
    isEditDesignPreserved = false,
    hideEstimatedDeliveryEditDesignPage = false,
    isCoinReloaded,
    enableCyberpunk = false,
  } = App;
  const { delivery_date_name = '', delivery_contact_disclaimer = '' } = deliveryTimingMessage;
  const { isEarlyDesignSelected = false } = ReviewDetails.vehicleDesign ?? {};

  return {
    value: delivery_date_name,
    hideDelivery: (
      isEditDesignPreserved ||
      isEarlyDesignSelected ||
      isPreOrder() ||
      hideEstimatedDeliveryOrderPage ||
      (getLoadedRN() && hideEstimatedDeliveryEditDesignPage)
    ),
    id: typeof group === 'string' ? group : _get(group, 'code', 'generic'),
    section,
    description: delivery_contact_disclaimer,
    isCoinReloaded,
    enableCyberpunk,
    ...ownProps
  };
}

DeliveryDate.propTypes = {
  id: string,
  hideDelivery: bool,
  value: string,
  classes: string,
  isCentered: bool,
};

DeliveryDate.defaultProps = {
  id: 'generic',
  hideDelivery: false,
  classes: '',
  value: '',
  isCentered: true,
};

DeliveryDateHeader.propTypes = {
  hideDelivery: bool,
  value: string,
  section: string,
  isCoinReloaded: bool,
  enableCyberpunk: bool,
};

const ConnectedDeliveryDate = connect(mapStateToProps)(DeliveryDate);
export const ConnectedDeliveryDateHeader = connect(mapStateToProps)(DeliveryDateHeader);

export default ConnectedDeliveryDate;
