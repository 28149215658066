import React from 'react';
import { string } from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { i18n } from 'utils';
import classnames from 'classnames';

const InfoMessage = ({ message, referralCode }) => {
  if (!message) {
    return null;
  }

  return (
    <div className={classnames('tds-text--center tds-text--contrast-low', {
      'tds--vertical_padding--small': !referralCode,
      'info-message-with-referral': referralCode,
    })}>
      {message}
    </div>
  );
};

const mapStateToProps = state => {
  const source = _get(state, 'ReviewDetails.showInfoMessage.source', '');
  const referralCode = _get(state, 'ApplicationFlow.referral.referralCode', '');
  const message = source ? i18n(source, null, null, {
    returnNullWhenEmpty: true,
  }) : '';
  
  return {
    message,
    referralCode,
  };
};

InfoMessage.propTypes = {
  message: string,
  referralCode: string,
};

InfoMessage.defaultProps = {
  message: '',
  referralCode: '',
};

export default connect(mapStateToProps)(InfoMessage);