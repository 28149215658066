import {
  APPLE_PAY_SESSION_REQUEST,
  APPLE_PAY_SESSION_SUCCESS,
  APPLE_PAY_SESSION_FAILURE,
  APPLE_PAY_PAYMENT,
  APPLE_PAY_VALIDITY,
} from 'dictionary';
import { placeOrderFlow } from '../../ApplicationFlow/Actions';

export const applePaySessionRequest = () => ({
  type: APPLE_PAY_SESSION_REQUEST,
});

export const applePaySessionSuccess = () => ({
  type: APPLE_PAY_SESSION_SUCCESS,
});

export const applePaySessionFailure = errorMsg => ({
  type: APPLE_PAY_SESSION_FAILURE,
  errorMsg,
});

export const applePayment = payment => ({
  type: APPLE_PAY_PAYMENT,
  payment,
});

export const userHasApplePay = canMakePayments => ({
  type: APPLE_PAY_VALIDITY,
  userHasApplePay: canMakePayments,
});

const getApplePaySession = url =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', '/configurator/api/get_apple_pay_session');
    xhr.onload = () => {
      if (this.status >= 200 && this.status < 300) {
        resolve(JSON.parse(xhr.response));
      } else {
        reject(new Error(`Status: ${this.status} | Error Message: ${xhr.statusText}`));
      }
    };
    xhr.onerror = () => {
      reject(new Error(`Status: ${this.status} | Error Message: ${xhr.statusText}`));
    };
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify({ url }));
  });

export const createPaymentRequest = () => dispatch => {
  dispatch(applePaySessionRequest());

  const paymentRequest = {
    countryCode: 'US', // TODO: should not be hard coded
    currencyCode: 'USD', // TODO: should not be hard coded
    lineItems: [],
    total: {
      label: 'Tesla, Inc.', // TODO: confirm this is correct label to display
      amount: '100.00', // TODO: should not be hard coded
    },
    supportedNetworks: ['amex', 'discover', 'masterCard', 'visa'], // TODO: confirm these are correct cards we should support / get them from somewhere else
    merchantCapabilities: ['supports3DS'],
    requiredBillingContactFields: ['postalAddress', 'name'],
    requiredShippingContactFields: ['email', 'phone'],
  };

  const session = new ApplePaySession(1, paymentRequest);

  /**
   * Merchant Validation
   * We call our merchant session endpoint, passing the URL to use
   */
  session.onvalidatemerchant = event => {
    const { validationURL } = event;
    getApplePaySession(validationURL)
      .then(res => {
        dispatch(applePaySessionSuccess());
        session.completeMerchantValidation(res);
      })
      .catch(() => {
        dispatch(applePaySessionFailure('TODO: some friendly localized error message'));
      });
  };

  /**
   * Payment Authorization
   * Here you receive the encrypted payment data. You would then send it
   * on to your payment provider for processing, and return an appropriate
   * status in session.completePayment()
   */
  session.onpaymentauthorized = event => {
    const { payment } = event;
    dispatch(applePayment(payment));

    // TODO: find a better way to pass ApplePaySession instance (window?)
    dispatch(placeOrderFlow(session));
  };

  // All our handlers are setup - start the Apple Pay payment
  session.begin();
};
