import React from 'react';
import PropTypes from 'prop-types';

const Loader = ({ loading = false, classes }) => {
  return (
    <If condition={loading}>
      <div className={`tds-spinner tds-spinner--fade_in ${classes}`}></div>
    </If>
  );
};

Loader.propTypes = {
  loading: PropTypes.bool.isRequired,
  classes: PropTypes.string,
};

Loader.defaultProps = {
  classes: '',
};

export default Loader;
