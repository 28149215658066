/* eslint no-shadow: 0 */
import React, { useRef, useState } from 'react';
import classnames from 'classnames';
import { array, shape, func, bool, string, arrayOf } from 'prop-types';
import _debounce from 'lodash/debounce';
import _isEqual from 'lodash/isEqual';
import { Form, Input, Select, RadioGroup } from '@tesla/informed-tds';
import {
  ELECTRIC_INVOICE,
  CHARITY_INVOICE,
  TRIPLE_INVOICE,
  INVOICE_NUMBER,
  COMPANY_NAME,
} from 'dictionary';
import { i18n } from 'utils';
import { useFormState } from 'informed';

const InvoiceTypes = ({ charityInvoiceList }) => {
  const formState = useFormState();
  return (
    <>
      <If condition={formState?.values?.invoiceType === ELECTRIC_INVOICE}>
        <p>{i18n('InvoiceType.ElectricInvoice__description')}</p>
      </If>
      <If condition={formState?.values?.invoiceType === CHARITY_INVOICE}>
        <Select
          name="charityInvoice"
          id={CHARITY_INVOICE}
          label={i18n('InvoiceType.CharityInvoice')}
          options={charityInvoiceList}
        />
      </If>
      <If condition={formState?.values?.invoiceType === TRIPLE_INVOICE}>
        <Input
          name="invoiceNumber"
          id={INVOICE_NUMBER}
          label={i18n('InvoiceType.Invoice_number')}
          required
        />
        <Input
          name="companyName"
          id={COMPANY_NAME}
          label={i18n('InvoiceType.Company_Name')}
          required
        />
      </If>
    </>
  );
};

const InvoiceOptionsRepresentation = props => {
  const { invoiceType, isMobile, updateInvoiceType, setValidFlag, charityInvoiceTypes } = props;
  const formApiRef = useRef();
  const charityInvoiceList = charityInvoiceTypes
    ? charityInvoiceTypes.map(item => {
        return {
          value: item,
          label: i18n(`Review.charityType.${item}`),
        };
      })
    : [];
  const [invoiceData, setInvoiceData] = useState(null);

  const updateInvoiceForm = fieldValues => {
    const { errors, values } = fieldValues;
    const { invoiceType, charityInvoice, companyName, invoiceNumber } = values;
    const charityInvoiceFallback =
      charityInvoice || (charityInvoiceTypes ? charityInvoiceTypes[0] : '');
    const invoiceDataObj = {
      invoiceType,
      charityInvoice: charityInvoiceFallback,
      companyName,
      invoiceNumber,
    };
    if (invoiceData && _isEqual(invoiceData, invoiceDataObj)) {
      return;
    }
    setInvoiceData(invoiceDataObj);
    if (Object.keys(errors).length) {
      setValidFlag(false);
    } else {
      setValidFlag(true);
      switch (invoiceType) {
        case ELECTRIC_INVOICE:
          updateInvoiceType({ type: invoiceType });
          break;
        case CHARITY_INVOICE:
          updateInvoiceType({
            charityType: charityInvoiceFallback,
            type: invoiceType,
          });
          break;
        case TRIPLE_INVOICE:
          updateInvoiceType({
            COMPANY_NAME: companyName,
            INVOICE_NUMBER: invoiceNumber,
            type: invoiceType,
          });
          break;
        default:
          break;
      }
    }
  };

  const onChangeCallback = _debounce(updateInvoiceForm, 500);

  return (
    <div className={classnames('invoice-type--container', { 'invoice-options--block': isMobile })}>
      <Form
        formApiRef={formApiRef}
        errorMessage={{
          required: i18n('common.errors__required'),
        }}
        onChange={onChangeCallback}
        initialValues={{
          invoiceType: invoiceType?.type,
        }}
      >
        <RadioGroup
          name="invoiceType"
          options={[
            {
              value: ELECTRIC_INVOICE,
              label: i18n(`InvoiceType.${ELECTRIC_INVOICE}`),
            },
            {
              value: CHARITY_INVOICE,
              label: i18n(`InvoiceType.${CHARITY_INVOICE}`),
            },
            {
              value: TRIPLE_INVOICE,
              label: i18n(`InvoiceType.${TRIPLE_INVOICE}`),
            },
          ]}
        />
        <InvoiceTypes charityInvoiceList={charityInvoiceList} />
      </Form>
    </div>
  );
};

InvoiceOptionsRepresentation.propTypes = {
  invoiceType: shape({
    type: string,
  }).isRequired,
  updateInvoiceType: func.isRequired,
  charityInvoiceTypes: arrayOf(string),
  setValidFlag: func.isRequired,
  isMobile: bool,
};

InvoiceOptionsRepresentation.defaultProps = {
  isMobile: false,
  charityInvoiceTypes: [],
};

InvoiceTypes.propTypes = {
  charityInvoiceList: array,
};

InvoiceTypes.defaultProps = {
  charityInvoiceList: [],
};

export default InvoiceOptionsRepresentation;
