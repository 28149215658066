import React from 'react';
import { connect } from 'react-redux';
import { number } from 'prop-types';
import { i18n, buttonStringExtractor } from 'utils';

import { BUTTON_START, BUTTON_END, MODAL_SMALL, USER_LOCATION_MODAL } from 'dictionary';
import { getTransportationFeeAmount } from 'selectors';
import ModalTrigger from '../ModalTrigger';

import { formatCurrency } from '@tesla/coin-common-components';

const DeliveryLocationLink = ({ transportFeeAmount }) => {
  const orderPaymentTransportFee = transportFeeAmount
    ? 'Inventory.common.orderDepositTransportFeeRedesign'
    : 'Inventory.common.orderDepositNoTransportFeeRedesign';

  const orderPaymentTransportFeeCopy = i18n(orderPaymentTransportFee, {
    TRANSPORT_FEE: formatCurrency(transportFeeAmount),
    BUTTON_START,
    BUTTON_END,
  });

  const deliveryLocationCopy = buttonStringExtractor(i18n(orderPaymentTransportFeeCopy));

  return (
    <>
      <p className="tds--vertical_padding-bottom">
        <span>
          <span>{deliveryLocationCopy.firstPart}</span>
          <ModalTrigger
            options={{
              props: {
                componentName: USER_LOCATION_MODAL,
                props: {
                  genericWrapper: true,
                  size: MODAL_SMALL,
                  classes: 'delivery-location-modal-trigger',
                  containerCss: 'tds-display--inline',
                },
              },
            }}
          >
            {deliveryLocationCopy.buttonPart}
          </ModalTrigger>
          <span>{deliveryLocationCopy.lastPart}</span>
        </span>
      </p>
    </>
  );
};

DeliveryLocationLink.propTypes = {
  transportFeeAmount: number,
};

DeliveryLocationLink.defaultProps = {
  transportFeeAmount: 0,
};

function mapStateToProps(state) {
  return {
    transportFeeAmount: getTransportationFeeAmount(state),
  };
}

export default connect(mapStateToProps)(DeliveryLocationLink);
