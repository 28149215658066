import React from 'react';

const NegativeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <path
        d="M19.0151 10.7183L10.7183 19.015"
        stroke="#8E8E8E"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M19.015 19.015L10.7183 10.7183"
        stroke="#8E8E8E"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default NegativeIcon;
