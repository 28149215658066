import {
    LOADER_START,
    LOADER_FINISH
} from 'dictionary';

function Loader(state = {}, action) {
  switch (action.type) {
    case LOADER_START:
      return Object.assign({}, state, {
        active: true,
      });
    case LOADER_FINISH:
      return Object.assign({}, state, {
          active: false,
      });
    default:
      return state;
  }
}

export default Loader;
