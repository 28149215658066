import { FINANCE_MODAL_CLOSE, FINANCE_MODAL_OPEN, FINANCE_MODAL_SELECT_VIEW } from 'dictionary';

export default function Reducer(state = {}, action) {
  switch (action.type) {
    case FINANCE_MODAL_CLOSE:
      return {
        ...state,
        isCarouselOpen: false,
      };
    case FINANCE_MODAL_OPEN:
      return {
        ...state,
        isCarouselOpen: true,
        selectedView: action.payload.selectedView || '',
        triggerId: action.payload.triggerId,
        selectedToggle: action.payload.selectedToggle || '',
      };
    case FINANCE_MODAL_SELECT_VIEW:
      return {
        ...state,
        isCarouselOpen: true,
        selectedView: action.payload || '',
      };
    default:
      return state;
  }
}
