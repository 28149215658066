import React from 'react';
import { connect } from 'react-redux';
import { bool } from 'prop-types';
import { i18n, htmlToReact } from 'utils';

const ContactUsLink = ({ showContactUs, useUsedInventoryDisclaimerCopy }) => {
  if (!showContactUs) {
    return null;
  }
  const contactusSource = `SummaryPanel.disclaimers.contactus${
    useUsedInventoryDisclaimerCopy ? '_used' : ''
  }`;
  return (
    <div className="modifier--tds-link-primary tds-text--caption tds--vertical_padding tds-text--center">
      {htmlToReact(i18n(contactusSource))}
    </div>
  );
};

ContactUsLink.propTypes = {
  showContactUs: bool,
  useUsedInventoryDisclaimerCopy: bool,
};

ContactUsLink.defaultProps = {
  showContactUs: false,
  useUsedInventoryDisclaimerCopy: false,
};

function mapStateToProps(state) {
  const { ReviewDetails } = state;
  const { showContactUs, useUsedInventoryDisclaimerCopy } = ReviewDetails;

  return {
    showContactUs,
    useUsedInventoryDisclaimerCopy,
  };
}

export default connect(mapStateToProps)(ContactUsLink);
