/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import _get from 'lodash/get';

import { Relevant, useFieldApi } from 'informed';
import { RadioGroup } from '@tesla/informed-tds';

import { toggleFinancePanelDrivertype, selectTab } from 'actions';
import {
  getSelectedFinanceProduct,
} from 'selectors';
import { i18n } from 'utils';
import {
  UI_DATA_IDS,
  REGISTRATION_TYPE_PRIVATE,
  REGISTRATION_TYPE_BUSINESS,
  REGISTRATION_TYPE_PERSONAL,
  FINPLAT_INPUTS_CHANGED,
} from 'dictionary';

const AccountFormRegistrationType = ({
  showAccountTypeSwitch,
  isInline,
  market,
  registrationType,
  setDrivertype
}) => {
  const registrationTypeFieldApi = useFieldApi('registrationType');
  const { reset, getValue } = registrationTypeFieldApi;

  useEffect(() => {
    if (registrationType !== getValue()) {
      reset();
    }
  }, [registrationType]);

  const handleRegistrationTypeChange = ({ value }) => {
    const driverType =
      value === REGISTRATION_TYPE_BUSINESS
        ? REGISTRATION_TYPE_BUSINESS
        : REGISTRATION_TYPE_PERSONAL;
    // Update registration type in order summary section
    setDrivertype(driverType);
  };

  return (
    <Relevant when={() => showAccountTypeSwitch}>
      <div className={`tds-o-form-input-group adangothadei tds-o-form-input-group__${market}`}>
        <RadioGroup
          name="registrationType"
          className={isInline ? 'tds-form-item--inline' : ''}
          options={[
            {
              value: REGISTRATION_TYPE_PRIVATE,
              label: i18n(`Review.account_in_${REGISTRATION_TYPE_PRIVATE}`),
              'data-id': `${UI_DATA_IDS?.accountSection?.privateRegistrationRadio}`,
            },
            {
              value: REGISTRATION_TYPE_BUSINESS,
              label: i18n(`Review.account_in_${REGISTRATION_TYPE_BUSINESS}`),
              'data-id': `${UI_DATA_IDS?.accountSection?.businessRegistrationRadio}`,
            },
          ]}
          onChange={handleRegistrationTypeChange}
          keepState
          validateOn="change"
          initialValue={registrationType || REGISTRATION_TYPE_PRIVATE}
        />
      </div>
    </Relevant>
  );
};

function mapStateToProps(state) {
  return {
    showAccountTypeSwitch: state.ReviewDetails.showAccountTypeSwitch,
    isInline: state.App.enableCyberpunk,
    market: state.App.countryCode,
    registrationType: _get(state, 'ReviewDetails.AccountDetail.AccountType', ''),
    financeProduct: getSelectedFinanceProduct(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setDrivertype: driverType => {
      dispatch(toggleFinancePanelDrivertype(driverType));
      dispatch({
        type: FINPLAT_INPUTS_CHANGED,
        'orderType': driverType === 'personal' ? 'Private' : 'Business',
      });
    },
    selectTab: tab => {
      dispatch(selectTab(tab));
    },
  };
}

AccountFormRegistrationType.propTypes = {
  market: string.isRequired,
  showAccountTypeSwitch: bool,
  isInline: bool,
  registrationType: string,
  setDrivertype: func.isRequired,
  financeProductByType: arrayOf(shape({})),
  financeProduct: string,
  selectTab: func.isRequired,
};

AccountFormRegistrationType.defaultProps = {
  showAccountTypeSwitch: false,
  isInline: false,
  registrationType: '',
  financeProductByType: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountFormRegistrationType);
