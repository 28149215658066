import {
  FEATURE_LIST_MODAL_CLOSE,
  FEATURE_LIST_MODAL_OPEN,
  FEATURE_LIST_MODAL_OPEN_LINK,
} from 'dictionary';

export default function Reducer(state = {}, action) {
  switch (action.type) {
    case FEATURE_LIST_MODAL_CLOSE:
      return {
        ...state,
        isCarouselOpen: false,
      };
    case FEATURE_LIST_MODAL_OPEN:
    case FEATURE_LIST_MODAL_OPEN_LINK:
      return {
        ...state,
        isCarouselOpen: true,
        groupName: action.payload.groupName,
        selectedView: action.payload.selectedView || '',
        carouselTriggerText: action.payload.carouselTriggerText,
        modalClasses: action.payload?.modalClasses || '',
      };
    default:
      return state;
  }
}
