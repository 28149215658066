const { showTaxesAndFees, isPreQualifyEnabled = false } = window.tesla || {};
import {
  FINANCE_MODAL_TAB_OPTIONS,
  FINANCE_MODAL_TAB_GAS_SAVINGS,
  FINANCE_MODAL_TAB_INCENTIVES,
  FINANCE_MODAL_TAB_PREQUALIFY,
  FINANCE_MODAL_TAB_TRADEIN,
  FINANCE_MODAL_TAB_DELIVERY_TIMING,
} from 'dictionary';

export default ({ object }) => ({
  merge_method: 'assign',
  object,
  deltas: {
    market: {
      US: {
        menuItems: [
          {
            key: FINANCE_MODAL_TAB_OPTIONS,
            i18nLabel: 'FinancingOptions.tabLabels.finance_options',
            showDeliveryZipCode: !!showTaxesAndFees,
          },
          {
            key: FINANCE_MODAL_TAB_PREQUALIFY,
            i18nLabel: 'FinancingOptions.tabLabels.prequalify',
            hide: !isPreQualifyEnabled,
          },
          {
            key: FINANCE_MODAL_TAB_TRADEIN,
            content_path: 'App.isTradeInEnabled',
          },
          {
            key: FINANCE_MODAL_TAB_GAS_SAVINGS,
            i18nLabel: 'FinancingOptions.tabLabels.gas_savings',
          },
          { key: FINANCE_MODAL_TAB_INCENTIVES, i18nLabel: 'FinancingOptions.tabLabels.incentives' },
        ],
        gasSavingsCap: {
          fuelPrice: 8,
          distanceYear: 30000,
          fuelEfficiency: 100,
          kwhPrice: 1,
        },
      },
    },
  },
  deltasV2: [
    {
      selected_by: {
        model: 'ts',
      },
      object: {
        hideAfterSavingsPrice: true,
        hideTaxesAndFeesExcluded: true,
        showAmountIncludingFees: false,
        showTaxCalcDisclaimer: false,
        showSavingsAmount: [],
        reversePricingOrder: false,
        showChargerDisclaimer: false,
      },
    },
    {
      selected_by: {
        isEnterpriseOrder: false,
        market: 'US',
        model: ['ms', 'mx', 'm3', 'my'],
      },
      object: {
        activeTabs: [
          FINANCE_MODAL_TAB_OPTIONS,
          FINANCE_MODAL_TAB_GAS_SAVINGS,
          FINANCE_MODAL_TAB_INCENTIVES,
          FINANCE_MODAL_TAB_DELIVERY_TIMING,
          FINANCE_MODAL_TAB_PREQUALIFY,
          FINANCE_MODAL_TAB_TRADEIN,
        ],
      },
    },
    {
      selected_by: {
        market: ['US'],
        model: ['m3', 'ms', 'mx', 'my'],
        isEnterpriseOrder: false,
        isInventory: false,
      },
      object: {
        gasSavingsCap: {
          fuelPrice: 8,
          distanceYear: 30000,
          fuelEfficiency: 100,
          kwhPrice: 1,
        },
        showAccordion: true,
      },
    },
  ],
});
