export default ({ object, overrides = {} }) => ({
    object: {
        fetching: false,
        option_codes: [],
        user_selected_options: [],
        user_selected_options_by_group: [],
        user_selected_options_by_group_non_matched:[],
        option_string: "",
        options_by_category: {},
        options_by_group: {},
        options_by_group_non_matched: {},
        configurations_detail: [],
        disclaimers:[],
        rn: null,
        id: null,
        model_code: "",
        savedConfiguration: null,
        configurationSource: null,
        previousUserConfig: null,
        ...object,
        ...overrides,
    },
});
