/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { captureException } from '@sentry/react';
import { getVideoSource } from 'selectors';
import { prefetch } from 'remotion';
import { Loader } from '@tesla/design-system-react';

const VIDEO = 'video';
const AUDIO = 'audio';
let videoAsset = {};

// round media timer to hundreds
const round = x => Math.round(x * 100) / 100;
const getIsPlaying = ({ currentTime, paused, ended, readyState }) =>
  !!(currentTime > 0 && !paused && !ended && readyState > 2);

/**
 * Wrapper for HTML5 video/audio tags
 * Adds ability to:
 *    - loop specific number of times
 *    - pause video at a specific time
 *
 */

const Media = ({
  type,
  loop, // Boolean or Integer
  stopTime,
  title,
  id,
  playVideo,
  src,
  assetCaption,
  ...otherProps
}) => {
  const mediaRef = useRef({});
  const isLoopNumber = Number.isInteger(loop);
  const shouldLoop = loop === true || isLoopNumber || false;
  const [loopCount, setLoopCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const hasMoreLoops = isLoopNumber ? loopCount < loop : loop;

  // Loop Number of times
  const onEnd = () => {
    const { current: element } = mediaRef;
    // forcing loop programmatically here because
    // built in behavior only allows for loop=true/false
    if (shouldLoop && hasMoreLoops) {
      setLoopCount(loopCount + 1);
      var playPromise = element.play();

      if (playPromise !== undefined) {
        playPromise.catch(error => captureException(`MediaPlayException: ${error}`)); // Log failed video play with Sentry
      }
    }
  };

  // Pause on timeStop
  const onTimeUpdate = () => {
    const { current: element } = mediaRef;
    if (stopTime && !hasMoreLoops && element) {
      const shouldPause = round(element.currentTime) > stopTime;
      if (shouldPause) {
        element.pause();
      }
    }
  };

  const loadVideo = () => {
    const blobUrl = videoAsset?.[src];
    const video = document.getElementById(id);
    if (blobUrl && video) {
      video.src = blobUrl;
      video.play();
    } else {
      setIsLoading(true);
      const { waitUntilDone, free } = prefetch(src, {
        contentType: 'video/mp4',
      });
      waitUntilDone().then((url, err) => {
        setIsLoading(false);
        if (err) {
          delete videoAsset?.[src];
          free();
          return;
        }
        const oldUrl = video?.currentSrc;
        if (oldUrl && oldUrl.startsWith('blob:')) {
          video.src = '';
          URL.revokeObjectURL(oldUrl);
        }
        if (url && video) {
          videoAsset[src] = url;
          video.src = url;
          video.play();
        }
      });
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      loadVideo();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      // Play / Pause from Props
      const { current: element } = mediaRef;
      const isPlaying = getIsPlaying(element);

      if (element) {
        if (!isPlaying && playVideo) {
          var playPromise = element.play();

          if (playPromise !== undefined) {
            playPromise.catch(error => captureException(`MediaPlayException: ${error}`)); // Log failed video play with Sentry
          }
        }
        if (isPlaying && !playVideo) {
          element.pause();
        }
      }
    }
    return () => {
      isMounted = false;
    };
  }, [playVideo]);

  switch (type) {
    case VIDEO:
      return (
        <>
          <Loader show={isLoading} />
          <video
            ref={mediaRef}
            title={title}
            alt={title}
            id={id}
            loop={isLoopNumber ? false : loop}
            onEnded={isLoopNumber ? onEnd : undefined}
            onTimeUpdate={stopTime ? onTimeUpdate : undefined}
            src={getVideoSource(src)}
            {...otherProps}
          />
          <If condition={assetCaption}>
            <figcaption className="video-caption tds-text--h4">{assetCaption}</figcaption>
          </If>
        </>
      );
    case AUDIO:
      return (
        <audio
          ref={mediaRef}
          title={title}
          alt={title}
          id={id}
          loop={isLoopNumber ? false : loop}
          onEnded={isLoopNumber ? onEnd : undefined}
          onTimeUpdate={stopTime ? onTimeUpdate : undefined}
          {...otherProps}
        />
      );
    default:
      return null;
  }
};

Media.defaultProps = {
  type: VIDEO,
  loop: false,
  muted: true,
  autoPlay: false,
  stopTime: null,
  controls: false,
  title: '',
  id: '',
  playsInline: null,
  playVideo: undefined,
};

Media.propTypes = {
  src: PropTypes.string.isRequired,
  type: PropTypes.oneOf([VIDEO, AUDIO]),
  loop: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  muted: PropTypes.bool,
  autoPlay: PropTypes.bool,
  stopTime: PropTypes.number,
  showControls: PropTypes.bool,
  title: PropTypes.string,
  id: PropTypes.string,
  playsInline: PropTypes.bool,
  playVideo: PropTypes.bool,
};

export default Media;
