import { useState, useEffect, useRef } from 'react';

const useIntersectionObserver = (options, element) => {
  const ref = useRef(element || null);
  const [isIntersecting, setIsIntersecting] = useState();

  const onEvent = entries => {
    const [entry] = entries;

    if (isIntersecting !== entry.isIntersecting) {
      setIsIntersecting(entry.isIntersecting);
    }
  };

  useEffect(() => {
    const { current: element } = ref;
    const observer = new IntersectionObserver(onEvent, options);

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [options, ref]);

  return [isIntersecting, ref];
};

export default useIntersectionObserver;
