import React from 'react';
import { connect } from 'react-redux';
import AssetLoader from '../../AssetLoader';

const CyberGraffiti = () => {

  const props = {
    "asset": {
        "url": "CYBERTRUCK/ct_graffiti_logo.svg",
        "type": "image"
    },
    "className": "tds-o--vertical_margin--small tds-o--centered coin-reloaded--ct-badge"
  }

  return (
    <AssetLoader {...props} />
  );
};
  
export default connect(null)(CyberGraffiti);