import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-collapse';
import classnames from 'classnames';
import {
  formatCurrency,
  formatNumber,
  formatPercent,
  formatMonthlyPrice,
} from '@tesla/coin-common-components';
import { Icon, IconTrigger } from '@tesla/design-system-react';
import { iconStatusInfo, iconStatusSuccess } from '@tesla/design-system-icons';
import _compact from 'lodash/compact';
import _get from 'lodash/get';
import Analytics from 'analytics';
import {
  htmlToReact,
  i18n,
  isPreOrder,
  getDefaultAnimationSpringConfig,
  getDayMonthYear,
  isWeChatMini,
  isPriceAcceptance,
} from 'utils';
import {
  MODAL_SMALL,
  FinanceTypes,
  TRIGGER_LOCATION_MODAL,
  PAYMENT_FIN_TABS,
  PILL,
  FINANCE_CAROUSEL,
  FINANCE_MODAL_TAB_OPTIONS,
  PURCHASE_TAX_SAVING_THRESHOLD,
  PRICING_SAVINGS_CALCULATIONS,
} from 'dictionary';
import ModalTrigger from '../../components/ModalTrigger';
import ActionTrigger from '../../components/ActionTrigger';
import PricingDetails from './PricingDetails';
import DeliveryDate from '../../components/GroupComponents/DeliveryDate.js'
import CustomizePayment from './Sections/CustomizePayment';
import TaxesAndFeesDisclaimer from './Sections/TaxesAndFeesDisclaimer';
import VisitTeslaFinancing from './Sections/VisitTeslaFinancing';
import ExtraIncentives from './ExtraIncentives';
import VehiclePricePlusFees from './Sections/VehiclePricePlusFees';
import CurrencyDisclaimer from '../../components/Forms/FinancingOptions/CurrencyDisclaimer';
import PriceValidDisclaimer from './Sections/PriceValidDisclaimer';
import Unavailable from '../../components/Forms/FinancingOptions/Unavailable';
import { formatDate } from '@tesla/intl-date-time';
import LegalBanner from '../../components/LegalBanner';
import FinanceToggle from '../../components/GroupComponents/FinanceToggle';
import FinanceDisclaimer from '../../components/GroupComponents/FinanceDisclaimer.js';
import { SHOW_FINANCIAL_MODAL_STANDALONE } from '../../common/dictionary.js';
const { string, number, bool, shape, func, oneOfType, arrayOf, node } = PropTypes;
import GioStatistics from 'gioStatistics';
import { WEB_CONFIGURATOR_PAYMENT_DETAILS } from '../../common/gioStatistics';

const DivWrapper = ({ children }) => {
  return <div className="items price review-page--disclaimer tds-text--caption">{children}</div>;
};

DivWrapper.propTypes = {
  children: oneOfType([node, shape({})]),
};

export default class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentWillUnmount() {
    const { setPricingDetails, showPricesDetailIconTrigger } = this.props;
    if (showPricesDetailIconTrigger) {
      setPricingDetails(true);
    }
  }

  handleToggle = () => {
    const { setPricingDetails } = this.props;
    const { isOpen } = this.state;
    document.body.classList.remove('using-mouse');

    if (isOpen) {
      // reverse because state isn't updated yet and don't want to rely on setState's update cycle here
      Analytics.fireTagEvent({
        event: Analytics.event,
        interaction: 'payment-hide-details',
        'cfg-type': Analytics.cfgType,
      });
      GioStatistics.manuallyTrack(WEB_CONFIGURATOR_PAYMENT_DETAILS, {
        tab_detail_shown: 'collapse',
      });
    } else {
      Analytics.fireTagEvent({
        event: Analytics.event,
        interaction: 'payment-show-details',
        'cfg-type': Analytics.cfgType,
      });
      GioStatistics.manuallyTrack(WEB_CONFIGURATOR_PAYMENT_DETAILS, {
        tab_detail_shown: 'expand',
      });
    }

    /* eslint-disable no-shadow */
    this.setState(({ isOpen }) => ({
      isOpen: !isOpen,
    }));
    setPricingDetails(isOpen);
  };

  renderLeaseSummary() {
    const {
      downPayment,
      downPaymentPercentage,
      showLeaseSummaryFields,
      leaseTerm,
      leaseMileage,
      interestRate,
      ecoBonus,
      leaseData,
      showCustomizeLabel,
      leaseRate,
      showLeaseSummaryDisclaimer,
      governmentIncentivesTotal,
      financeProductId,
      modelCode,
      annualLeaseInterestRate,
      locale,
      disclaimerIntlDateFormat,
      market,
    } = this.props;
    const costOfCredit = _get(leaseData, 'totalLeaseAmount', 0);
    const residualAmount = _get(leaseData, 'residualAmount', 0);
    const residualPercentage = _get(leaseData, 'residualPercentage', 0);
    const residualAmountWithoutVat = _get(leaseData, 'residualAmountWithoutVat', 0);
    const financedAmount = _get(leaseData, 'financedAmount', 0);
    const financedAmountWithoutVat = _get(leaseData, 'financedAmountWithoutVat', 0);
    const grossPrice = _get(leaseData, 'grossPrice', 0);
    const effectiveRate = _get(leaseData, 'effectiveRate', 0);
    const totalDownPayment = _get(leaseData, 'totalDownPayment', 0);
    const monthlyPayment = _get(leaseData, 'monthlyPayment', 0);
    const totalPayable = _get(leaseData, 'totalPayable', 0);

    const today = new Date();
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    if (showLeaseSummaryDisclaimer) {
      return htmlToReact(
        i18n(
          'SummaryPanel.disclaimers.lease',
          {
            MONTHS: leaseTerm,
            DISTANCE: formatNumber(leaseMileage),
            RESIDUAL_AMOUNT: formatCurrency(residualAmount),
            DOWNPAYMENT: formatCurrency(downPayment),
            DOWNPAYMENT_MONTHS: downPayment,
            DOWNPAYMENT_MINUS_BONUS: formatCurrency(downPayment - ecoBonus),
            DOWNPAYMENT_MINUS_INCENTIVES: formatCurrency(
              downPayment - Math.abs(governmentIncentivesTotal)
            ),
            BONUS: formatCurrency(ecoBonus),
            COST_CREDIT: formatCurrency(costOfCredit),
            GROSS_PRICE: formatCurrency(grossPrice),
            INCENTIVES_INCLUDED_TOTAL: formatCurrency(Math.abs(governmentIncentivesTotal)),
            RATE: formatNumber(interestRate, { precision: 2 }),
            TOTALDOWNPAYMENT: formatCurrency(totalDownPayment),
            PAYMENT: formatCurrency(monthlyPayment),
            TOTAL_AMOUNT_PAYABLE: formatCurrency(totalPayable),
            FINANCED_AMOUNT: formatCurrency(financedAmount),
            CASH_PRICE: formatCurrency(grossPrice),
            EFFECTIVE_RATE: formatNumber(effectiveRate, { precision: 2 }),
            TERM: leaseTerm,
            TERM_MINUS_TWO: leaseTerm - 2,
            LAST_DAY_OF_MONTH: formatDate(lastDayOfMonth, locale, disclaimerIntlDateFormat),
            INITIAL_TERM: leaseData?.initialTerm ?? 0,
            FINAL_TERM: leaseData?.finalTerm ?? 0,
            FINAL_RATE: leaseData?.finalRate ?? 0,
            FINAL_MONTHLY_PAYMENT: formatCurrency(leaseData?.finalMonthlPayment),
          },
          null,
          {
            specificOverride: [financeProductId, modelCode],
            specificOverrideOperator: 'OR',
          }
        )
      );
    }

    const parts = showLeaseSummaryFields.map(fieldName => {
      switch (fieldName) {
        case 'downPayment':
          return i18n(
            showCustomizeLabel ? 'SummaryPanel.downPayment' : 'SummaryPanel.down',
            {
              DOWN: formatCurrency(downPayment),
              DOWNPAYMENT_PERCENTAGE: formatPercent(downPaymentPercentage),
              ALD_DOWN_PAYMENT_PERCENT: formatPercent(downPaymentPercentage * 100, 0),
            },
            null,
            { specificOverride: financeProductId }
          );
        case 'leaseTerm':
          if (market === 'CN') {
            return i18n('SummaryPanel.terms', { TERMS: leaseTerm });
          }
          return i18n('SummaryPanel.months', { MONTHS: leaseTerm });
        case 'leaseDistance':
          return i18n('FinancingOptions.lease__distanceOptionLabel', {
            DISTANCE: formatNumber(leaseMileage),
          });
        case 'residualAmount':
          return i18n(
            'FinancingOptions.residualAmount',
            {
              RESIDUAL_AMOUNT: formatCurrency(residualAmount),
              RV_PERCENT: formatPercent(residualPercentage),
            },
            null,
            { specificOverride: financeProductId }
          );
        case 'residualAmountYears':
          return i18n('SummaryPanel.summary.residualAmountAfterYears', {
            YEARS: leaseTerm / 12,
            RESIDUAL_AMOUNT: formatCurrency(residualAmount),
          });
        case 'residualAmountExVAT':
          return i18n('FinancingOptions.residualAmount', {
            RESIDUAL_AMOUNT: formatCurrency(residualAmountWithoutVat),
            RV_PERCENT: formatPercent(residualPercentage),
            EX_VAT: i18n('SummaryPanel.exVATpostfix'),
          });
        case 'residualAmountYearsExVAT':
          return i18n('SummaryPanel.summary.residualAmountAfterYears', {
            YEARS: leaseTerm / 12,
            RESIDUAL_AMOUNT: formatCurrency(residualAmountWithoutVat),
            EX_VAT: i18n('SummaryPanel.exVATpostfix'),
          });
        case 'interestRate':
          return i18n('FinancingOptions.interestRate', {
            INTEREST_RATE: formatPercent(interestRate, 2),
          });
        case 'effectiveRate':
          return i18n('FinancingOptions.effectiveRate', {
            EFFECTIVE_RATE: formatPercent(effectiveRate, 2),
          });
        case 'leaseRate':
          return i18n('SummaryPanel.leaseRate', {
            RATE: formatNumber(leaseRate, { precision: 2 }),
          });
        case 'costOfCredit':
          return i18n('SummaryPanel.summary.costOfCredit', {
            COST_CREDIT: formatCurrency(costOfCredit),
          });
        case 'downPaymentMinusBonus':
          return i18n(
            'SummaryPanel.summary.downPaymentMinusBonus',
            {
              DOWNPAYMENT_MINUS_BONUS: formatCurrency(downPayment - ecoBonus),
              BONUS: formatCurrency(ecoBonus),
            },
            null,
            { specificOverride: modelCode }
          );
        case 'financedAmount':
          return i18n(
            'SummaryPanel.summary.financedAmount',
            {
              FINANCED_AMOUNT: formatCurrency(financedAmount),
            },
            null,
            { specificOverride: financeProductId }
          );
        case 'financedAmountExVAT':
          return i18n('SummaryPanel.summary.financedAmount', {
            FINANCED_AMOUNT: formatCurrency(financedAmountWithoutVat),
          });
        case 'ecoBonusIncluded':
          return i18n(
            'Incentives.extraIncentives.ecologicalBonus',
            {
              INCENTIVES_INCLUDED_TOTAL: formatCurrency(Math.abs(governmentIncentivesTotal)),
            },
            null,
            { specificOverride: modelCode }
          );
        default:
          return null;
      }
    });

    let partsCombined = _compact(parts).join(', ');

    if (showLeaseSummaryFields.includes('disclaimerPrefix')) {
      partsCombined = `${i18n('SummaryPanel.summary.financeDisclaimerPrefix')}${partsCombined}`;
    }

    if (showLeaseSummaryFields.includes('vatIncluded')) {
      partsCombined += `. ${i18n('SummaryPanel.summary.vatIncluded', null, null, {
        specificOverride: financeProductId,
      })}.`;
    }

    if (showLeaseSummaryFields.includes('vatExcluded')) {
      partsCombined += `. ${i18n('SummaryPanel.summary.vatExcluded')}.`;
    }

    return partsCombined;
  }

  renderLoanSummary() {
    const {
      amountAtSigning,
      showLoanSummaryFields,
      interestRate,
      effectiveRate,
      residualAmount,
      loanTerm,
      loanData,
      loanRate,
      debitRate,
      startFee,
      balloonPayment,
      totalAmtPaid,
      governmentIncentivesTotal,
      downPaymentPercentage,
      showLoanSummaryDisclaimer,
      ecoBonus,
      grossPrice,
      modelCode,
      trimCode,
      coeBidAmount,
      isUsedInventory,
      market,
    } = this.props;

    const costOfCredit = _get(loanData, 'costOfCredit', 0);
    const financedAmount = _get(loanData, 'financedAmount', 0);
    const financedAmountInclFees = _get(loanData, 'financedAmountInclFees', 0);
    const loanDistance = _get(loanData, 'LoanAPI.parameters.loanDistance', 0);
    const downPaymentAmount = _get(loanData, 'downPayment.downPaymentAmount', 0);
    const monthlyPayment = _get(loanData, 'monthlyPayment', 0);
    const finalTerm = _get(loanData, 'LoanAPI.variables.finalTerm', 0);
    const is3plus7campaign = _get(
      loanData,
      'LoanAPI.variables.localeSpecific.campaign3plus7',
      null
    );
    const monthlyPaymentPrecision = _get(loanData, 'LoanAPI.variables.monthlyPaymentPrecision', 0);

    if (showLoanSummaryDisclaimer) {
      return htmlToReact(
        i18n(
          'SummaryPanel.disclaimers.loan',
          {
            CASH_PRICE: formatCurrency(grossPrice),
            CASH_PRICE_PLUS_BONUS: formatCurrency(grossPrice + ecoBonus),
            MONTHS: loanTerm,
            DISTANCE: formatNumber(loanDistance),
            RESIDUAL_AMOUNT: formatCurrency(residualAmount),
            DOWNPAYMENT: amountAtSigning,
            DOWNPAYMENT_MINUS_BONUS: formatCurrency(downPaymentAmount - ecoBonus),
            BONUS: formatCurrency(ecoBonus),
            COST_CREDIT: formatCurrency(costOfCredit),
            ECO_BONUS: formatCurrency(ecoBonus),
            TERM_MINUS_ONE: loanTerm - 1,
            PAYMENT: formatCurrency(monthlyPayment, { precision: monthlyPaymentPrecision }),
            EFFECTIVE_RATE: formatNumber(effectiveRate, { precision: 2 }),
            RATE: formatNumber(interestRate, { precision: 2 }),
            INCENTIVES_INCLUDED_TOTAL: formatCurrency(Math.abs(governmentIncentivesTotal)),
            FINANCED_AMOUNT: formatCurrency(financedAmount),
            RATE_VARIATION: i18n(
              'SummaryPanel.summary.rateVariation',
              {
                RATE: formatNumber(interestRate, { precision: 2 }),
                FINAL_TERM: finalTerm,
              },
              null,
              {
                specificOverride:
                  Array.isArray(is3plus7campaign) && !is3plus7campaign.includes(trimCode)
                    ? null
                    : modelCode,
              }
            ),
          },
          null,
          {
            specificOverride: modelCode,
          }
        )
      );
    }

    const parts = showLoanSummaryFields.map(fieldName => {
      switch (fieldName) {
        case 'downPayment':
          return i18n('SummaryPanel.down', {
            DOWN: amountAtSigning,
            DOWNPAYMENT_PERCENTAGE: formatPercent(downPaymentPercentage),
          });
        case 'loanTerm':
          if (market === 'CN') {
            return i18n('SummaryPanel.terms', { TERMS: loanTerm });
          }
          return i18n('SummaryPanel.months', { MONTHS: loanTerm });
        case 'interestRate':
          return i18n('SummaryPanel.rate', { RATE: formatNumber(interestRate, { precision: 2 }) });
        case 'loanRate':
          return `${i18n('SummaryPanel.loanRate', {
            RATE: formatNumber(loanRate, { precision: 2 }),
            DEBIT_RATE: formatNumber(debitRate, { precision: 2 }),
          })}${market === 'CN' && isUsedInventory ? i18n('FinancingOptions.start_from') : ''}`;
        case 'loanDistance':
          return i18n('SummaryPanel.summary.loanDistance', {
            DISTANCE: formatNumber(loanDistance, { precision: 0 }),
          });
        case 'effectiveRate':
          return i18n('FinancingOptions.effectiveRate', {
            EFFECTIVE_RATE: formatPercent(effectiveRate, 2),
          });
        case 'residualAmount':
          return i18n('FinancingOptions.residualAmount', {
            RESIDUAL_AMOUNT: formatCurrency(residualAmount),
          });
        case 'costOfCredit':
          return i18n('SummaryPanel.summary.costOfCredit', {
            COST_CREDIT: formatCurrency(costOfCredit),
          });
        case 'financedAmount':
          return i18n('SummaryPanel.summary.financedAmount', {
            FINANCED_AMOUNT: formatCurrency(financedAmount),
          });
        case 'financedAmountInclFees':
          return i18n('SummaryPanel.summary.financedAmountInclFees', {
            FINANCED_AMOUNT_INCL_FEES: formatCurrency(financedAmountInclFees),
          });
        case 'residualAmountYears':
          return i18n('SummaryPanel.summary.residualAmountAfterYears', {
            YEARS: loanTerm / 12,
            RESIDUAL_AMOUNT: formatCurrency(residualAmount),
          });
        case 'startFee':
          return i18n('SummaryPanel.summary.startFee', {
            START_FEE: formatCurrency(startFee),
          });
        case 'balloonPayment':
          return i18n('SummaryPanel.balloonPayment', {
            BALLON_PAYMENT: formatCurrency(balloonPayment),
          });
        case 'totalAmtPaid':
          return i18n('SummaryPanel.totalAmtPaid', {
            TOTAL_AMT_PAID: formatCurrency(totalAmtPaid),
          });
        case 'ecoBonusIncluded':
          return i18n('Incentives.extraIncentives.ecologicalBonus', {
            INCENTIVES_INCLUDED_TOTAL: formatCurrency(Math.abs(governmentIncentivesTotal)),
          });
        default:
          return null;
      }
    });

    let partsCombined = _compact(parts).join(', ');
    if (showLoanSummaryFields.includes('disclaimerPrefix')) {
      partsCombined = `${i18n('SummaryPanel.summary.financeDisclaimerPrefix')}${partsCombined}`;
    }

    if (showLoanSummaryFields.includes('vatIncluded')) {
      partsCombined += `. ${i18n('SummaryPanel.summary.vatIncluded')}.`;
    }

    if (showLoanSummaryFields.includes('vatExcluded')) {
      partsCombined += `. ${i18n('SummaryPanel.summary.vatExcluded')}.`;
    }

    if (showLoanSummaryFields.includes('coe') && coeBidAmount) {
      partsCombined += `. ${i18n('SummaryPanel.coe_bid', { COE: formatCurrency(coeBidAmount) })}.`;
    }

    return partsCombined;
  }

  renderCashTab() {
    const {
      destinationAndDocFee,
      showVAT,
      vatAmount,
      vatPercent,
      reversePricingOrder,
      hideAfterSavingsPrice,
      hideTaxesAndFeesExcluded,
      shortPrice,
      cashPrice,
      showAmountIncludingFees,
      registrationTax,
      useModelYContent,
      showDriveAwayPrice,
      OMVICFee,
      deliveryFee,
      frtFee,
      exlFrtPrice,
      showFeesDisclaimer,
      modelCode,
      showChargerDisclaimer,
      showTaxCalcDisclaimer,
      showSavingsAmount,
      savingsAmount,
      showSavingsDisclaimeronCashTab,
      hideAfterSavingsModal,
      tabIndex,
      model,
      fuelYears,
      isReservation,
      isUsedInventory,
      reservationAmt,
      VehicleDetail,
      ACFee,
      purchaseTax,
      purchaseTaxAmount,
      vehiclePriceWithoutDiscounts,
      showTaxAndFeeDisclaimerForUsed,
      isDomestic,
      isPreSale,
      reservationPriceLabel,
      showPaymentDisclaimers,
      showPurchasePrice,
      deliveryPostalCode,
      transportationFee,
      isTransportFeeEnabled,
      isTransportFeeCollectionEnabled,
      isDeliveryDetailsValid,
      userHasApplePay,
      isEstimatedPrice,
      incentiveTotalInPurchasePrice,
      feesTotal,
      incentivesTotalPurchasePrice,
      extraPricingContextAmounts,
      dutyAndSurcharge,
      showEVCredit,
      priceAfterEVCredit,
      fuelSavingsPerMonth,
      showFuelMonthlySavings,
      tradeInType,
      showVehiclePricePlusFees,
      evCredit,
      governmentIncentive,
      showEvSubsidyDisclaimer,
      depositAmount,
      secondPaymentAltCurrency,
      firstRegistrationDate,
      showFirstRegistrationDatePaymentPage,
      showVehicleSubtotal,
      feeLineItems,
      displayDoubleCurrency,
      vehicleCondition,
      showCoEBid,
      coeBidAmount,
      showPriceWithCOE,
      vehiclePrice,
      showVehicleConfigurationPrice,
      showVATBreakdown,
      sctTax,
      referralCredit,
      market,
      showVisitTeslaFinancing,
      taxCredit,
      priceAfterTaxCredit,
      veteranCredit,
    } = this.props;

    const getPurchasePriceLabel = ({ reservationPriceLabel, showDriveAwayPrice }) => {
      if (reservationPriceLabel) {
        return reservationPriceLabel;
      }

      if (showDriveAwayPrice) {
        return 'FinancingOptions.drive_away_price';
      }

      return 'Review.purchase_price';
    };
    const purchasePriceLabel = getPurchasePriceLabel({
      reservationPriceLabel,
      showDriveAwayPrice,
    });
    const FeesDisclaimerLabel = showFeesDisclaimer
      ? 'SummaryPanel.disclaimers.fees__Disclamier'
      : 'SummaryPanel.disclaimers.otherFees';
    let reservationDueAmt = _get(reservationAmt, 'second', 0);
    if (isReservation && VehicleDetail && VehicleDetail.qty > 1) {
      reservationDueAmt =
        VehicleDetail.qty *
          (_get(reservationAmt, 'initial', 0) + _get(reservationAmt, 'second', 0)) -
        _get(reservationAmt, 'initial', 0);
    }

    const pricingOrder = [
      <If condition={isReservation && VehicleDetail} key="reservationQty">
        <li className="tds-list-item tds-text_color--10">
          {i18n(
            `Reservation.${modelCode}.${
              VehicleDetail.qty > 1 ? `${VehicleDetail.series}_PLURAL` : VehicleDetail.series
            }`,
            {
              QTY: VehicleDetail.qty,
            }
          )}
        </li>
      </If>,
      <If condition={showPurchasePrice} key="beforeSavingsPriceCash">
        <If condition={showVehicleSubtotal}>
          <li className="tds-list-item">
            <span className="left tds-text--medium tds-text_color--10">
              {i18n('Review.subtotal_vehicle')}
            </span>
            <span className="tds-text--end tds-text--medium tds-text_color--10">
              {cashPrice.beforeSavingsPrice}
              <If condition={displayDoubleCurrency}>
                {extraPricingContextAmounts.map(extraPricingContextAmount => (
                  <span
                    className="tds-text--end double-currency"
                    key={`extraContextPriceBeforeSavings_${extraPricingContextAmount.currency}`}
                  >
                    {formatCurrency(extraPricingContextAmount.subTotal, {
                      currency: extraPricingContextAmount.currency,
                      useDynamicRounding: true,
                    })}
                  </span>
                ))}
              </If>
            </span>
          </li>
          {feeLineItems.map(fee => (
            <If condition={fee.show} key={`FeeCondition:${fee.label.replace(/\s/g, ' ')}`}>
              <li className="tds-list-item">
                <span className="left tds-text_color--10">{fee.label}</span>
                <span className="tds-text--end tds-text_color--10">
                  {fee.value}
                  <If condition={displayDoubleCurrency}>
                    {extraPricingContextAmounts.map(extraPricingContextAmount => (
                      <span
                        className="tds-text--end double-currency"
                        key={`FeeCondition:${fee.label.replace(/\s/g, ' ')}_${
                          extraPricingContextAmount.currency
                        }`}
                      >
                        {formatCurrency(_get(extraPricingContextAmount, fee.key, 0), {
                          currency: extraPricingContextAmount.currency,
                          useDynamicRounding: true,
                        })}
                      </span>
                    ))}
                  </If>
                </span>
              </li>
            </If>
          ))}
        </If>

        <Choose>
          <When condition={showCoEBid}>
            <li className={'tds-list-item'}>
              <span className="left tds-text--medium tds-text_color--10">
                {i18n(
                  `Review.${
                    coeBidAmount || showPriceWithCOE ? 'purchase_price_with_coe' : 'purchase_price'
                  }`
                )}
              </span>
              <span className="tds-text--end tds-text--medium tds-text_color--10">
                {shortPrice.beforeSavingsPrice}
              </span>
            </li>
          </When>
          <Otherwise>
            <If condition={showVehicleConfigurationPrice && !!incentiveTotalInPurchasePrice}>
              <li className="tds-list-item">
                <span className="left tds-text--medium tds-text_color--10">
                  {i18n('Review.vehicle_configuration_price')}
                </span>
                <span className="tds-text--end tds-text--medium tds-text_color--10">
                  {vehiclePrice}
                </span>
              </li>
              <li className="tds-list-item ev-subsidy">
                <span className="left tds-text--regular tds-text_color--10">
                  {i18n('SummaryPanel.disclaimers.governmentIncentivesTotal')}
                </span>
                <span className="tds-text--end tds-text--regular tds-text_color--10">
                  {formatCurrency(-1 * Math.abs(incentiveTotalInPurchasePrice))}
                </span>
              </li>
            </If>
            <li
              className={classnames('tds-list-item', {
                'purchase-price-with-fees': showVehicleSubtotal,
              })}
            >
              <span className="left tds-text--medium tds-text_color--10">
                {i18n(`${purchasePriceLabel}`, null, null, {
                  specificOverride: [vehicleCondition],
                  returnNullWhenEmpty: true,
                  specificOverrideOperator: 'OR',
                })}
                <If condition={veteranCredit}>
                  <br />
                  <span className='tds-o-flex_no-wrap'>
                    <Icon data={iconStatusSuccess} className="tds-icon--small tsla-icon" /> 
                    <span className="tds-text--contrast-low tds-text--regular tds-text--caption tds-text--body">
                      {i18n('Review.discount_included__military')}
                    </span>
                  </span>
                </If>
              </span>
              <span className="tds-text--end tds-text--medium tds-text_color--10">
                {shortPrice.beforeSavingsPrice}
              </span>
            </li>
            <If condition={taxCredit && priceAfterTaxCredit}>
              <li className="tds-list-item" data-id="tax-credit-line-item">
                <span className="left tds-text--caption">
                  {i18n('Incentives.federalIncentive.savingsAfterTaxCredit')}
                  <sup>*</sup>
                </span>
                <span className="tds-text--caption">{formatCurrency(priceAfterTaxCredit)}</span>
              </li>
            </If>
          </Otherwise>
        </Choose>

        <If condition={displayDoubleCurrency}>
          {extraPricingContextAmounts.map(extraPricingContextAmount => (
            <li
              className="tds-list-item tds-align--end double-currency"
              key={`extraContextPriceCash_${extraPricingContextAmount.currency}`}
            >
              <span className="tds-text--end">
                {formatCurrency(extraPricingContextAmount.grossPrice, {
                  currency: extraPricingContextAmount.currency,
                  useDynamicRounding: true,
                })}
              </span>
            </li>
          ))}
        </If>
      </If>,
      <If condition={hideAfterSavingsPrice === false} key="afterSavingsPriceCash">
        <li className="tds-list-item">
          <span className="left tds-text--regular tds-text_color--10">
            {i18n('Review.est_savings_total')}
          </span>
          <span className="tds-text--end tds-text--regular tds-text_color--10">
            {shortPrice.afterSavingsPrice}
          </span>
        </li>
      </If>,
      <If
        condition={showEVCredit && (evCredit || governmentIncentive)}
        key="afterSavingsPriceEVCredit"
      >
        <li className="tds-list-item">
          <span className="left tds-text--regular tds-text_color--10">
            {i18n('SummaryPanel.est_ev_credit')}
          </span>
          <span className="tds-text--end tds-text--regular tds-text_color--10">
            {formatCurrency(priceAfterEVCredit)}
          </span>
        </li>
      </If>,
      <If condition={showFuelMonthlySavings} key="fuelSavingsPerMonth">
        <li className="tds-list-item">
          <span className="left tds-text--regular">{i18n('common.savingsAmount')}</span>
          <span className="tds-text--end tds-text--regular tds-text_color--10">
            {fuelSavingsPerMonth}
          </span>
        </li>
      </If>,
      <If
        condition={
          !isTransportFeeCollectionEnabled &&
          isTransportFeeEnabled &&
          isDeliveryDetailsValid &&
          transportationFee
        }
        key="transportationFeeCash"
      >
        <li className="tds-list-item">
          <span className="left tds-text--regular">
            <If condition={userHasApplePay}>
              <ActionTrigger>
                {htmlToReact(
                  i18n('DeliveryLocation.estimate_transport_fee_label_short_with_action', {
                    TRIGGER: TRIGGER_LOCATION_MODAL,
                    POSTAL_CODE: deliveryPostalCode,
                  })
                )}
              </ActionTrigger>
            </If>
            <If condition={!userHasApplePay}>
              {i18n('DeliveryLocation.estimate_transport_fee_label_short', {
                POSTAL_CODE: deliveryPostalCode,
              })}
            </If>
          </span>
          <span className="tds-text--end tds-text--regular">{formatCurrency(transportationFee)}</span>
        </li>
      </If>,
      <If
        condition={isReservation && reservationDueAmt && showPaymentDisclaimers}
        key="totalDueAmount"
      >
        <li className="tds-list-item tds-text_color--10">
          <span className="left">{i18n('Reservation.payment_due')}</span>
          <span>{formatCurrency(reservationDueAmt)}</span>
        </li>
        <If condition={secondPaymentAltCurrency}>
          <li className="tds-list-item tds-text_color--10">
            <span className="left"></span>
            <span className="tds-text--caption">{secondPaymentAltCurrency}</span>
          </li>
        </If>
      </If>,
    ];

    if (reversePricingOrder) {
      pricingOrder.reverse();
    }
    // HK: FRT may be zero for inventory
    const hasNoOtherFeesToIncludeInPrice =
      showAmountIncludingFees &&
      !destinationAndDocFee &&
      !registrationTax &&
      !OMVICFee &&
      !deliveryFee &&
      !frtFee &&
      !dutyAndSurcharge;

    const showPurchaseTaxCopy = market === 'CN' && purchaseTax && !isPreSale;

    if (showVehiclePricePlusFees && showPurchasePrice) {
      return <VehiclePricePlusFees />;
    }

    return (
      <>
        <div className="tds--vertical_padding--large pricing-container">
          <div className="price-block">
            <ul className="tds-list">{pricingOrder}</ul>
            <If condition={tradeInType}>
              <span className="tds-text--caption">
                {i18n(`Inventory.vehicleDetails.${tradeInType}`)}
              </span>
            </If>
            <If condition={showFirstRegistrationDatePaymentPage && firstRegistrationDate}>
              <p className="tds-text--caption">
                {i18n('Inventory.vehicleDetails.firstRegisteredOn', {
                  DATE: getDayMonthYear(firstRegistrationDate),
                })}
              </p>
            </If>
            <If condition={hideTaxesAndFeesExcluded === false}>
              <TaxesAndFeesDisclaimer />
            </If>
            <If condition={showVisitTeslaFinancing}>
              <VisitTeslaFinancing />
            </If>
            <PriceValidDisclaimer />
            <CurrencyDisclaimer className="tds-text--caption" />

            <If condition={showChargerDisclaimer}>
              <p className="tds-text--caption">
                {i18n(`SummaryPanel.disclaimers.superchargerDisclaimer.${modelCode}`)}
              </p>
            </If>
            <If condition={showVAT}>
              <If condition={sctTax.total}>
                <p className="tds-text--caption tds--vertical_padding-bottom">
                  {i18n('SummaryPanel.disclaimers.sctTaxIncluded', {
                    SCT_TAX: formatCurrency(sctTax.total),
                  })}
                </p>
              </If>
              <p className="tds-text--caption tds--vertical_padding-bottom">
                <Choose>
                  <When
                    condition={
                      !useModelYContent &&
                      !(showEvSubsidyDisclaimer && !!incentiveTotalInPurchasePrice) &&
                      !isDomestic &&
                      !showTaxAndFeeDisclaimerForUsed
                    }
                  >
                    {i18n('SummaryPanel.disclaimers.vatAmount', {
                      VAT_AMOUNT: formatCurrency(vatAmount, { precision: 0 }),
                      VAT_PERCENT: formatPercent(vatPercent, 0),
                      ...extraPricingContextAmounts.reduce(
                        (extraVatAmounts, extraPricingContext) => {
                          return {
                            ...extraVatAmounts,
                            [`VAT_AMOUNT_${extraPricingContext.currency}`]: formatCurrency(
                              _get(extraPricingContext, 'vatAmount', 0),
                              {
                                currency: extraPricingContext.currency,
                              }
                            ),
                          };
                        },
                        {}
                      ),
                    })}
                  </When>
                  <When condition={isUsedInventory && showTaxAndFeeDisclaimerForUsed}>
                    {i18n('SummaryPanel.disclaimers.taxesAndFeesExcluded__used', null, null, {
                      returnNullWhenEmpty: true,
                    })}
                  </When>
                  <Otherwise>
                    {i18n('SummaryPanel.disclaimers.myVatAmount', null, null, {
                      returnNullWhenEmpty: true,
                    })}
                  </Otherwise>
                </Choose>
              </p>
            </If>
            <If condition={showEvSubsidyDisclaimer && !!incentiveTotalInPurchasePrice}>
              <p className="tds-text--caption tds--vertical_padding-bottom">
                {i18n(
                  'SummaryPanel.disclaimers.evSubsidyAmount',
                  {
                    INCENTIVES_INCLUDED_TOTAL: formatCurrency(
                      Math.abs(incentiveTotalInPurchasePrice)
                    ),
                  },
                  null,
                  {
                    returnNullWhenEmpty: true,
                  }
                )}
              </p>
            </If>
            <If condition={coeBidAmount}>
              <span className="tds-text--caption">
                {htmlToReact(
                  i18n(
                    'SummaryPanel.disclaimers.coeBidDisclaimer',
                    {
                      COE: formatCurrency(coeBidAmount),
                    },
                    null,
                    { returnNullWhenEmpty: true }
                  ) || ''
                )}
              </span>
            </If>

            <If
              condition={
                showAmountIncludingFees &&
                !hasNoOtherFeesToIncludeInPrice &&
                (!showVehicleSubtotal || showVATBreakdown)
              }
            >
              <div className="items price review-page--disclaimer tds-text--caption tds--vertical_padding-bottom">
                {htmlToReact(
                  i18n(
                    `${FeesDisclaimerLabel}`,
                    {
                      DOC_FEE: formatCurrency(destinationAndDocFee),
                      REGISTRATION_TAX: formatCurrency(registrationTax),
                      OMVIC_FEE: formatCurrency(OMVICFee),
                      DELIVERY_FEE: formatCurrency(deliveryFee),
                      FRT_FEE: frtFee, // pricing.firstRegistrationTax,
                      CASH_PRICE: exlFrtPrice, // pricing.vehiclePrice
                      VEHICLE_PRICE_WITHOUT_DISCOUNTS: formatCurrency(vehiclePriceWithoutDiscounts),
                      AC_FEE: formatCurrency(ACFee),
                      PURCHASE_TAX: purchaseTax,
                      FEES_TOTAL: formatCurrency(feesTotal),
                      INCENTIVES_TOTAL: formatCurrency(-1 * Math.abs(incentivesTotalPurchasePrice)),
                      DUTY_AND_SURCHARGE: formatPercent(dutyAndSurcharge),
                      ORDER_FEE: formatCurrency(depositAmount),
                      GST: formatCurrency(vatAmount),
                    },
                    null,
                    { returnNullWhenEmpty: true }
                  ) || ''
                )}
              </div>
            </If>

            <If condition={showPurchaseTaxCopy}>
              <div className="items price purchase-tax review-page--disclaimer tds-text--caption tds--vertical_padding-bottom">
                <p>
                  {`${i18n('SummaryPanel.disclaimers.otherFees', {
                    PURCHASE_TAX:
                      purchaseTaxAmount <= PURCHASE_TAX_SAVING_THRESHOLD
                        ? purchaseTax
                        : formatCurrency(PURCHASE_TAX_SAVING_THRESHOLD),
                  })}`}
                </p>
                <ModalTrigger
                  type={FINANCE_CAROUSEL}
                  selectedView={FINANCE_MODAL_TAB_OPTIONS}
                  options={{
                    props: {
                      props: {
                        genericWrapper: true,
                        size: 'MODAL_SMALL',
                        containerCss: 'tds-display--inline tds-link',
                      },
                    },
                  }}
                >
                  {i18n('common.seeDetails')}
                </ModalTrigger>
              </div>
            </If>

            <If condition={isEstimatedPrice}>
              <p className="tds-text--caption">{i18n('SummaryPanel.disclaimers.myTaxesAndFees')}</p>
            </If>
            <ExtraIncentives
              wrapper={DivWrapper}
              keyPrefix="ExtraIncentivesCash"
              hideZeroRegistrationTax
            />
            <If condition={showTaxCalcDisclaimer}>
              <div className="items price review-page--disclaimer tds-text--caption">
                {i18n('SummaryPanel.disclaimers.taxCalculation')}
              </div>
            </If>
            <If condition={showSavingsAmount}>
              <ul className="tds-list">
                <li className="tds-list-item" key="savingsAmount">
                  <span className="left tds-text--regular">{i18n('common.potentialSavings')}</span>
                  <span className="tds-text--end tds-text--regular">{savingsAmount}</span>
                </li>
              </ul>
            </If>
            {showSavingsDisclaimeronCashTab && (
              <>
                <span className="tds-text--caption">
                  {i18n(
                    'SummaryPanel.disclaimers.savingsAfterPurchase',
                    {
                      NUM_FUEL_YEAR: fuelYears,
                    },
                    null,
                    {
                      specificOverride: modelCode,
                    }
                  )}
                </span>
                <If condition={!hideAfterSavingsModal}>
                  <ModalTrigger
                    tabIndex={tabIndex}
                    options={{
                      props: {
                        componentName: 'GasSaving',
                        props: {
                          genericWrapper: true,
                          size: MODAL_SMALL,
                          modelName: model.name,
                          modelCode,
                        },
                      },
                    }}
                    analyticsInteraction={PRICING_SAVINGS_CALCULATIONS}
                  >
                    <span className="tds-link tds-text--caption">
                      {i18n('Review.savings_link_label')}
                    </span>
                  </ModalTrigger>
                </If>
              </>
            )}
            <If condition={referralCredit?.credit && !referralCredit?.includedInPayments}>
              <span className="tds-text--caption">{referralCredit?.creditExcludedCopy}</span>
            </If>
          </div>
        </div>
      </>
    );
  }

  renderPricingDetails() {
    const {
      model,
      destinationAndDocFee,
      incentives,
      incentivesTotal,
      incentiveTotalInPurchasePrice,
      hidePurchasePriceIncentive,
      tcc,
      fuelYears,
      modelCode,
      hideAfterSavingsPrice,
      cashPrice,
      cashPriceExVAT,
      showPrpLink,
      showdestinationAndDocFee,
      hideDestinationDocFee,
      hideAfterSavingsModal,
      registrationTax,
      showRegistrationTax,
      governmentIncentivesPeriod,
      showRegionalTaxTypes,
      showDistinctTaxTotal,
      vehiclePriceInclFees,
      regionalTaxValues,
      showDriveAwayPrice,
      showSavingsDisclaimer,
      deliveryFee,
      OMVICFee,
      gstSalePrice,
      onRoadfeesTotal,
      showRegionalTax,
      summaryRegionalFees,
      showIncentivesSectionTitle,
      showOtherFeesinSummary,
      showOrderPageSummaryOtherFees,
      showTaxCalcDisclaimer,
      cashTabIncentives,
      isReservation,
      showDOCandOMVICfees,
      showVATBreakdown,
      vatAmount,
      ACFee,
      showOtherLoanTerms,
      showGrossPriceTotal,
      grossPrice,
      netPrice,
      loanType,
      showVatDisclaimer,
      showGovernmentSubsidy,
      totalSavings,
      fuelReward,
      showProvincialIncentives,
      registrationType,
      evCredit,
      hideIncentivesOrderPage,
      regionalTax,
      governmentGrant,
      governmentIncentivesTotal,
      recyclingFee,
      tireFee,
      winterTireFee,
      specialTax,
      showVehiclePricePlusFees,
      regionalFeeRebate,
      showVehicleSubtotal,
      feeLineItems,
      extraPricingContextAmounts,
      displayDoubleCurrency,
      priceExcludingCoE,
      vehiclePrice,
      showVehicleConfigurationPrice,
      locale,
      showVehiclePriceWithoutSavings,
      vehiclePriceWithoutDiscounts,
      regionalDisclaimer,
      hasRegionalBannerModalCopy,
      sctTax,
      referralCredit,
      showIRABusinessEligibility,
      trimCode,
      discount,
      isInventoryPriceAdjustmentsEnabled,
      isReservationToOrderFlow,
      showPricesDetailIconTrigger,
      showVisitTeslaFinancing,
      tradeInYear,
      tradeInMake,
      tradeInModel,
      enableCyberpunk,
      discounts,
      baseConfiguration,
      options,
      showPricesDetail,
      configuration,
      hideBaseField,
      showRegistrationAboveSummary,
      showRegistrationTypeSelector,
      depositAmount,
      showOrderFee,
      interstateTransitFee,
      showFeesSummary,
    } = this.props;
    const { isOpen } = this.state;
    const tabIndex = isOpen ? 0 : -1;

    const pricingDetailsProps = {
      incentives,
      tcc,
      model,
      incentivesTotal,
      incentiveTotalInPurchasePrice,
      hidePurchasePriceIncentive,
      fuelYears,
      destinationAndDocFee,
      modelCode,
      hideAfterSavingsPrice,
      handleToggle: this.handleToggle,
      summaryRegionalFees,
      showIncentivesSectionTitle,
      showPrpLink,
      showdestinationAndDocFee,
      cashPrice,
      cashPriceExVAT,
      hideDestinationDocFee,
      hideAfterSavingsModal,
      registrationTax,
      showRegistrationTax,
      governmentIncentivesPeriod,
      showRegionalTaxTypes,
      vehiclePriceInclFees,
      showDistinctTaxTotal,
      regionalTaxValues,
      showDriveAwayPrice,
      showSavingsDisclaimer,
      OMVICFee,
      deliveryFee,
      gstSalePrice,
      onRoadfeesTotal,
      showRegionalTax,
      showOtherFeesinSummary,
      tabIndex,
      showOrderPageSummaryOtherFees,
      showTaxCalcDisclaimer,
      cashTabIncentives,
      isReservation,
      showDOCandOMVICfees,
      showVATBreakdown,
      vatAmount,
      ACFee,
      showOtherLoanTerms,
      showGrossPriceTotal,
      grossPrice,
      netPrice,
      loanType,
      showVatDisclaimer,
      showGovernmentSubsidy,
      totalSavings,
      fuelReward,
      showProvincialIncentives,
      registrationType,
      evCredit,
      hideIncentivesOrderPage,
      regionalTax,
      governmentGrant,
      governmentIncentivesTotal,
      showVehiclePricePlusFees,
      regionalFeeRebate,
      recyclingFee,
      tireFee,
      winterTireFee,
      specialTax,
      feeLineItems,
      showVehicleSubtotal,
      extraPricingContextAmounts,
      displayDoubleCurrency,
      priceExcludingCoE,
      vehiclePrice,
      showVehicleConfigurationPrice,
      locale,
      showVehiclePriceWithoutSavings,
      vehiclePriceWithoutDiscounts,
      regionalDisclaimer,
      hasRegionalBannerModalCopy,
      sctTax,
      referralCredit,
      showIRABusinessEligibility,
      trimCode,
      discount,
      isInventoryPriceAdjustmentsEnabled,
      isReservationToOrderFlow,
      showVisitTeslaFinancing,
      enableCyberpunk,
      discounts,
      isReservation,
      baseConfiguration,
      options,
      showPricesDetail,
      configuration,
      hideBaseField,
      showRegistrationAboveSummary,
      showRegistrationTypeSelector,
      depositAmount,
      showOrderFee,
      interstateTransitFee,
      showFeesSummary,
    };
    if (isReservation || isPreOrder()) {
      return null;
    }
    const tradeInVehicleInfo =
      tradeInYear && tradeInMake && tradeInModel
        ? `${tradeInYear} ${tradeInMake} ${tradeInModel}`.toLocaleLowerCase()
        : null;

    return (
      <section className="review-page--pricing tds-o-text_color--20">
        <div className="pricing-container">
          <div className="price-block">
            <Collapse
              isOpened={isOpen || isPriceAcceptance() || !showPricesDetailIconTrigger}
              springConfig={getDefaultAnimationSpringConfig()}
            >
              <div
                className={classnames('collapse-container', {
                  'border-top': locale === 'zh_CN',
                })}
              >
                <section className="review-page--content review-page--summary vertical_margin-top--12">
                  <div className="review-page--body">
                    <div className="review-page--blocks">
                      <PricingDetails {...pricingDetailsProps} />
                    </div>
                  </div>
                </section>
              </div>
            </Collapse>
            <If condition={showPricesDetailIconTrigger}>
              {!isPreOrder() && !isPriceAcceptance() && (
                <div className='collapse-container'>
                  <IconTrigger
                    id="pricing-read-more"
                    label={i18n(isOpen ? 'common.hideDetails' : 'common.showDetails')}
                    onClick={this.handleToggle}
                    pressed={isOpen}
                    small
                  />
                </div>
              )}
            </If>
          </div>
        </div>
      </section>
    );
  }

  renderUpdatedPricingDisclaimer() {
    const { modifyOrder, model, isEditDesignPreserved, enableCyberpunk } = this.props;

    if (
      !modifyOrder ||
      isEditDesignPreserved ||
      isPreOrder() ||
      isPriceAcceptance() ||
      enableCyberpunk
    ) {
      return null;
    }

    return (
      <div className="review-page--update-pricing">
        <div className="tds-label-title">
          {i18n('SummaryPanel.disclaimers.updatedPricingTitle')}
        </div>
        <div className="tds-label-descriptor">
          {i18n('SummaryPanel.disclaimers.updatedPricingDisclaimer', {
            MODEL: _get(model, 'name', 'Tesla'),
          })}
        </div>
      </div>
    );
  }

  renderCashTabAsSingle() {
    return (
      <If condition={!isPreOrder()}>
        {this.renderPricingDetails()}
        <div className="tds-tabs tds-tabs--toggle tds-tabs--animated">{this.renderCashTab()}</div>
      </If>
    );
  }

  render() {
    const {
      loanAfterSaving,
      activeFinanceTypes,
      reversePricingOrder,
      hideAfterSavingsPrice,
      showSavingsAmount,
      savingsAmount,
      shortPrice,
      showBeforeSavingsPriceFinancingLabel,
      showFormattedMonthlyPayment,
      includeTeslaInsurance,
      insuranceAmount,
      showLoanFieldsOrderPage,
      interestRate,
      fuelYears,
      showOtherLoanTerms,
      financeTabs,
      monthlyLeasePayment,
      financeProductId,
      showMonthlyPaymentQuote,
      loanType,
      selectedTab,
      financeMiniDisclaimer,
      showFuelMonthlySavings,
      fuelSavingsPerMonth,
      monthlyPaymentDisclaimer,
      showAvailableInState,
      tradeInType,
      modelCode,
      showElectronicPayment,
      showVehiclePricePlusFees,
      destinationAndDocFee,
      financeSelectionDislaimer,
      showLeaseDisclaimer,
      postOrderDeposit,
      trimCode,
      showFinancialTabsEnabled,
      showVehicleSubtotal,
      feesDisclaimer,
      showFinancingBanner,
      displayDoubleCurrency,
      leaseMonthlyPaymentInIntlCurrency,
      monthlyPaymentWithCredit,
      deliveryCredit,
      finplatProductName,
      referralCredit,
      isUsedInventory,
      discount,
      isInventoryPriceAdjustmentsEnabled,
      market,
      productType,
      hideFinanceProduct,
      monthlyPaymentAfterTaxCredit,
      remainingTradInCredit,
      tradeInDown,
      tradeinDisclaimers,
      hideTaxesAndFeesExcluded,
      veteranCredit,
    } = this.props;

    // If we only have Cash available or in wechat mini with LD(order-wechat-miniprogram-financial-tab-switch) false, dont render tabs.
    if (
      (isWeChatMini() && !showFinancialTabsEnabled) ||
      (activeFinanceTypes.length === 1 && activeFinanceTypes[0] === 'cash')
    ) {
      return this.renderCashTabAsSingle();
    }

    const loanPricingOrder = [
      <li className="tds-list-item" key="beforeSavingsPriceLoan">
        <span className="left tds-text--medium tds-text_color--10">
          {(showBeforeSavingsPriceFinancingLabel ||
            (!showBeforeSavingsPriceFinancingLabel && activeFinanceTypes.includes('loan'))) &&
            i18n('SummaryPanel.est_payment_label', null, null, {
              specificOverride: financeProductId,
            })}
          <If condition={veteranCredit}>
            <br />
            <span className='tds-o-flex_no-wrap'>
              <Icon data={iconStatusSuccess} className="tds-icon--small tsla-icon" /> 
              <span className="tds-text--contrast-low tds-text--regular tds-text--caption tds-text--body">
                {i18n('Review.discount_included__military')}
              </span>
            </span>
          </If>
        </span>
        <span className="tds-text--end tds-text--medium tds-text_color--10">
          <span>
            {shortPrice.beforeSavingsPrice}
            {market === 'CN' && isUsedInventory ? i18n('FinancingOptions.start_from') : ''}
          </span>
          <If
            condition={
              (showFormattedMonthlyPayment && !showOtherLoanTerms) || showMonthlyPaymentQuote
            }
          >
            <span>
              <sup>*</sup>
            </span>
          </If>
        </span>
      </li>,
      <If
        condition={
          hideAfterSavingsPrice === false &&
          !showFormattedMonthlyPayment &&
          !showVehiclePricePlusFees
        }
        key="afterSavingsPriceLoan"
      >
        <li className="tds-list-item">
          <span className="left tds-text--regular tds-text_color--10">
            {i18n('Review.loan_savings_label')}
          </span>
          <span className="tds-text--end tds-text--regular tds-text_color--10">
            {loanAfterSaving}
            {market === 'CN' && isUsedInventory ? i18n('FinancingOptions.start_from') : ''}
          </span>
        </li>
      </If>,
    ];

    const leasePricingOrder = [
      <li className="tds-list-item" key="beforeSavingsPriceLease">
        <span className="left tds-text--medium tds-text_color--10">
          {(showBeforeSavingsPriceFinancingLabel ||
            (!showBeforeSavingsPriceFinancingLabel && activeFinanceTypes.includes('lease'))) &&
            i18n('SummaryPanel.est_lease_payment_label', null, null, {
              specificOverride: financeProductId,
            })}
          <If condition={veteranCredit}>
            <br />
            <span className='tds-o-flex_no-wrap'>
              <Icon data={iconStatusSuccess} className="tds-icon--small tsla-icon" /> 
              <span className="tds-text--contrast-low tds-text--regular tds-text--caption tds-text--body">
                {i18n('Review.discount_included__military')}
              </span>
            </span>
          </If>
        </span>
        <span className="tds-text--end tds-text--medium tds-text_color--10">
          {shortPrice.beforeSavingsPrice}
          <If condition={displayDoubleCurrency}>
            <span className="double-currency">{leaseMonthlyPaymentInIntlCurrency}</span>
          </If>
          {market === 'CN' ? i18n('FinancingOptions.start_from') : ''}
        </span>
      </li>,
      <If
        condition={hideAfterSavingsPrice === false && !showVehiclePricePlusFees}
        key="afterSavingsPriceLease"
      >
        <li className="tds-list-item">
          <span className="left tds-text--regular tds-text_color--10">
            {i18n('Review.lease_savings_label', null, null, {
              specificOverride: financeProductId,
            })}
          </span>
          <span className="tds-text--end tds-text--regular tds-text_color--10">
            {shortPrice.afterSavingsPrice}
            {market === 'CN' ? i18n('FinancingOptions.start_from') : ''}
          </span>
        </li>
      </If>,
    ];
    if (reversePricingOrder) {
      loanPricingOrder.reverse();
      leasePricingOrder.reverse();
    }
    const tabContent = [];
    const isDisplayMonthlyLeasePayment = market === 'CN' ? monthlyLeasePayment >= 0 : monthlyLeasePayment;

    financeTabs.map(financeTab => {
      const { financeType, label, id, financeProductId: financeProductIdTab } = financeTab;
      const aprLabel = !loanType
        ? i18n(`FinancingOptions.aprLabel`)
        : i18n(`FinancingOptions.aprLabel__${loanType}`);

      switch (financeType) {
        case FinanceTypes.CASH: {
          tabContent.push(
            <section
              id="cash"
              role="tabpanel"
              aria-labelledby="cash"
              className="review-page--pricing"
              key="PricingSection__cash"
              label={label}
            >
              {this.renderCashTab()}
            </section>
          );
          break;
        }

        case FinanceTypes.FINPLAT: {
          const paymentLabel = i18n('SummaryPanel.est_payment_label__finplat', null, null, {
            specificOverride: financeProductIdTab,
          });
          const estimatedSavingsLabel = i18n('Review.finplat_est_savings_label', { INDICATOR: finplatProductName }, null, {
            specificOverride: [financeProductId, modelCode],
            specificOverrideOperator: 'OR',
            returnNullWhenEmpty: true,
          });
          const additionalInfoDisclaimer = i18n('FinancingOptions.additionalInfo', null, null, {
            returnNullWhenEmpty: true
          });
          const finplatPricingOrder = [
            <li
              className="tds-list-item tds-o-margin_bottom-half"
              key="finplatPricingOrder:beforeSavingsPriceFinplat"
            >
              <span className="left tds-text--medium tds-text_color--10">{paymentLabel}
              <If condition={veteranCredit}>
                  <br />
                  <span className='tds-o-flex_no-wrap'>
                    <Icon data={iconStatusSuccess} className="tds-icon--small tsla-icon" /> 
                    <span className="tds-text--contrast-low tds-text--regular tds-text--caption tds-text--body">
                      {i18n('Review.discount_included__military')}
                    </span>
                  </span>
              </If>
              </span>
              <span className="tds-text--end tds-text--medium tds-text_color--10">
                {shortPrice.beforeSavingsPrice}
              </span>
            </li>,
            <If condition={showLoanFieldsOrderPage.includes('interestRate')}>
             <ul className="tds-list">
               <li className="tds-list-item" key="showLoanFieldsInterestRate">
                 <span className="left">{aprLabel}</span>
                 <span className="right">
                   {i18n('FinancingOptions.apr', { APR: formatNumber(interestRate * 100, { style: 'decimal', maxPrecision: 2 }) })}
                 </span>
               </li>
             </ul>
           </If>,
            <If condition={tradeInDown > 0}>
              <li>
                <p className="items price review-page--disclaimer tds-text--caption">
                  {tradeinDisclaimers.downPayment}&nbsp;{tradeinDisclaimers.remainingCredit}
                </p>
              </li>
            </If>,

            <If condition={monthlyPaymentAfterTaxCredit}>
              <li className="tds-list-item" key="finplatPricingOrder:savingsAfterTaxCredit">
                <span className="left tds-text--caption">
                  {i18n('Incentives.federalIncentive.savingsAfterTaxCredit')}
                  <sup>*</sup>
                </span>
                <span className="tds-text--caption">
                  {formatMonthlyPrice(monthlyPaymentAfterTaxCredit)}
                </span>
              </li>
            </If>,
            <If condition={referralCredit?.credit && referralCredit?.includedInPayments}>
              <li className="tds-text--caption tds--vertical_padding--small">
                {referralCredit?.financeDisclaimer}
              </li>
            </If>,
            <If condition={deliveryCredit} key="finplatPricingOrder:deliveryCredit">
              <div className="pricing--separater-after">
                <li className="tds-list-item tds-text_color--10 tds-o-vertical_padding-top">
                  <span className="left">{i18n('SummaryPanel.deliveryCredit')}</span>
                  <span>{formatCurrency(deliveryCredit)}</span>
                </li>
              </div>
              <li
                className="tds-list-item"
                key="finplatPricingOrder:beforeSavingsPriceWithCreditFinplat"
              >
                <span className="left tds-text--medium tds-text_color--10">{paymentLabel}</span>
                <span className="tds-text--end tds-text--medium tds-text_color--10">
                  {monthlyPaymentWithCredit}
                </span>
              </li>
              <p
                className="tds-text--caption tds-o-padding_bottom-16"
                key="finplatPricingOrder:deliveryCreditDisclaimer"
              >
                {i18n(`SummaryPanel.disclaimers.deliveryCreditDisclaimer`)}
              </p>
            </If>,
            <If
              condition={hideAfterSavingsPrice === false && !showVehiclePricePlusFees}
              key="afterSavingsPriceFinplat"
            >
              <li className="tds-list-item">
                <span className="left tds-text--regular tds-text_color--10">
                  <Choose>
                      <When condition={estimatedSavingsLabel}>
                        {estimatedSavingsLabel}
                      </When>
                    <Otherwise>
                       {i18n('Review.finplat_savings_label', { INDICATOR: finplatProductName }, null, {
                         specificOverride: [financeProductId, modelCode],
                         specificOverrideOperator: 'OR',
                       })}
                    </Otherwise>
                  </Choose>
                </span>
                <span className="tds-text--end tds-text--regular tds-text_color--10">
                  {shortPrice.afterSavingsPrice}
                </span>
              </li>
            </If>,
          ];
          if (reversePricingOrder) {
            finplatPricingOrder.reverse();
          }
          if (hideFinanceProduct) {
            tabContent.push(
              <section id={id}>
                <Unavailable productType={productType} />
              </section>
            );
          } else {
            tabContent.push(
              <section
                id={id}
                key={`PricingSection__${id}`}
                role="tabpanel"
                aria-labelledby={id}
                className="review-page--pricing"
              >
                <div className="tds--vertical_padding--large pricing-container">
                  <LegalBanner financeId={`finplat-${financeProductId}`} />
                  <div className="price-block">
                    <ul className="tds-list">
                      {finplatPricingOrder}
                      <If
                        condition={showSavingsAmount}
                        key="showGasSavingsAmountFinplat"
                      >
                        <li className="tds-list-item">
                          <span className="left tds-text--regular tds-text_color--10">
                            {i18n('common.savingsAmount', { NUM_FUEL_YEAR: fuelYears })}
                          </span>
                          <span className="tds-text--end tds-text--regular tds-text_color--10">
                            {savingsAmount}
                          </span>
                        </li>
                      </If>
                    </ul>

                    {financeMiniDisclaimer && (
                      <p className="items price review-page--disclaimer tds-text--caption">
                        {financeMiniDisclaimer}
                      </p>
                    )}

                    <If condition={isInventoryPriceAdjustmentsEnabled && discount}>
                      <p className="items price review-page--disclaimer tds-text--caption">
                        {i18n('Review.priceAdjustment', { DISCOUNT: formatCurrency(-discount) })}
                      </p>
                    </If>

                    {showVehiclePricePlusFees ? (
                      <>
                        <p className="items price review-page--disclaimer tds-text--caption">
                          {htmlToReact(feesDisclaimer)}
                        </p>
                        {!hideTaxesAndFeesExcluded && <TaxesAndFeesDisclaimer />}
                      </>
                    ) : null}
                    <If condition={destinationAndDocFee && showVehicleSubtotal}>
                      <p className="items price review-page--disclaimer tds-text--caption">
                        {i18n(
                          'SummaryPanel.disclaimers.otherFees',
                          {
                            DOC_FEE: formatCurrency(destinationAndDocFee),
                          },
                          null,
                          { specificOverride: financeProductId }
                        )}
                      </p>
                    </If>
                    <ExtraIncentives
                      wrapper={DivWrapper}
                      keyPrefix="ExtraIncentivesFinplat"
                      hideZeroRegistrationTax
                    />
                    <CurrencyDisclaimer className="tds-text--caption" />
                    <CustomizePayment
                      showAvailableInState={showAvailableInState}
                      showElectronicPayment={showElectronicPayment}
                      financeProductId={financeProductId}
                    />
                    <If condition={additionalInfoDisclaimer}>
                      <div className="tds-o-vertical_padding-top tds-text--medium finance_info">
                        {i18n('FinancingOptions.additionalInfo')}
                      </div>
                    </If>
                  </div>
                </div>
              </section>
            );
          }
          break;
        }

        case FinanceTypes.LEASE: {
          tabContent.push(
            <section
              id={id}
              role="tabpanel"
              aria-labelledby={id}
              className="review-page--pricing"
              key={`PricingSection__${id}`}
              label={label}
            >
              <div className="tds--vertical_padding--large pricing-container">
                <LegalBanner financeId={`lease-${financeProductIdTab}`} />
                <If condition={showFinancingBanner}>
                  <div className="financing-banner">
                    <Icon data={iconStatusInfo} />
                    <div className="financing-banner-caption">
                      {i18n(`FinancingOptions.banner`, null, null, {
                        specificOverride: financeProductId,
                      })}
                    </div>
                  </div>
                </If>
                <Choose>
                  <When condition={isDisplayMonthlyLeasePayment}>
                    <div className="price-block">
                      <ul className="tds-list">
                        {leasePricingOrder}
                        <If condition={tradeInType} key="tradeInType_lease">
                          <span className="tds-text--caption">
                            {i18n(`Inventory.vehicleDetails.${tradeInType}`)}
                          </span>
                        </If>
                        <If condition={showSavingsAmount} key="showSavingsAmountLease">
                          <li className="tds-list-item">
                            <span className="left tds-text--regular tds-text_color--10">
                              {i18n('common.savingsAmount', { NUM_FUEL_YEAR: fuelYears })}
                            </span>
                            <span className="tds-text--end tds-text--regular tds-text_color--10">
                              {savingsAmount}
                            </span>
                          </li>
                        </If>
                      </ul>
                      <p className="items price review-page--disclaimer tds-text--caption">
                        {this.renderLeaseSummary()}
                      </p>
                      <If condition={isInventoryPriceAdjustmentsEnabled && discount}>
                        <p className="items price review-page--disclaimer tds-text--caption">
                          {i18n('Review.priceAdjustment', { DISCOUNT: formatCurrency(-discount) })}
                        </p>
                      </If>
                      <If condition={destinationAndDocFee && showVehicleSubtotal}>
                        <p className="items price review-page--disclaimer tds-text--caption">
                          {i18n(
                            'SummaryPanel.disclaimers.otherFees',
                            {
                              DOC_FEE: formatCurrency(destinationAndDocFee),
                            },
                            null,
                            { specificOverride: financeProductId }
                          )}
                        </p>
                      </If>
                      <If condition={showVehicleSubtotal}>
                        <ExtraIncentives
                          wrapper={DivWrapper}
                          keyPrefix="ExtraIncentivesLease"
                          hideZeroRegistrationTax
                        />
                      </If>
                      <If condition={showVehiclePricePlusFees}>
                        <p className="items price review-page--disclaimer tds-text--caption">
                          {feesDisclaimer}
                        </p>
                      </If>
                    </div>
                  </When>
                  <Otherwise>
                    <div className="price-undefined">
                      {htmlToReact(
                        i18n('FinancingOptions.lease__undefined', null, null, {
                          specificOverride: financeProductId,
                        })
                      )}
                    </div>
                  </Otherwise>
                </Choose>
                <If condition={referralCredit?.credit && !referralCredit?.includedInPayments}>
                  <span className="tds-text--caption">{referralCredit?.creditExcludedCopy}</span>
                </If>
                <CustomizePayment
                  showAvailableInState={showAvailableInState}
                  showElectronicPayment={showElectronicPayment}
                />

                <If condition={showLeaseDisclaimer}>
                  <div className="items price review-page--disclaimer tds-text--caption tds--vertical_padding-top">
                    {htmlToReact(i18n('FinancingOptions.lease__disclaimer'))}
                  </div>
                </If>
              </div>
            </section>
          );
          break;
        }

        case FinanceTypes.LOAN: {
          tabContent.push(
            <section
              id={id}
              role="tabpanel"
              aria-labelledby={id}
              className="review-page--pricing"
              key={`PricingSection__${id}`}
              label={label}
            >
              <div className="tds--vertical_padding--large pricing-container">
                <If condition={showFinancingBanner}>
                  <div className="financing-banner">
                    <Icon data={iconStatusInfo} />
                    <div className="financing-banner-caption">
                      {i18n(`FinancingOptions.banner`, null, null, {
                        specificOverride: financeProductId,
                      })}
                    </div>
                  </div>
                </If>
                <div className="price-block">
                  <ul className="tds-list">{loanPricingOrder}</ul>
                  <If condition={tradeInType}>
                    <span className="tds-text--caption" key="tradeInType_loan">
                      {i18n(`Inventory.vehicleDetails.${tradeInType}`)}
                    </span>
                  </If>
                  <If condition={includeTeslaInsurance}>
                    <span className="items price review-page--disclaimer tds-text--caption">
                      {i18n('TeslaInsurance.price_disclaimer', {
                        INSURANCE_AMT: formatCurrency(insuranceAmount),
                      })}
                    </span>
                  </If>
                  <If condition={showLoanFieldsOrderPage.includes('interestRate')}>
                    <ul className="tds-list">
                      <li className="tds-list-item" key="showLoanFieldsInterestRate">
                        <span className="left">{aprLabel}</span>
                        <span className="right">
                          {i18n('FinancingOptions.apr', { APR: interestRate })}
                        </span>
                      </li>
                    </ul>
                  </If>

                  <If condition={showSavingsAmount}>
                    <ul className="tds-list">
                      <li className="tds-list-item line_item__savings" key="showSavingsAmountLoan">
                        <span className="left">
                          {i18n('common.savingsAmount', { NUM_FUEL_YEAR: fuelYears })}
                        </span>
                        <span>{savingsAmount}</span>
                      </li>
                    </ul>
                  </If>

                  <If condition={showFuelMonthlySavings}>
                    <ul className="tds-list">
                      <li className="tds-list-item" key="fuelSavingsPerMonthLoan">
                        <span className="left">{i18n('common.savingsAmount')}</span>
                        <span>{fuelSavingsPerMonth}</span>
                      </li>
                    </ul>
                  </If>

                  <div className="items price review-page--disclaimer tds-text--caption">
                    {this.renderLoanSummary()}
                  </div>

                  <If condition={isInventoryPriceAdjustmentsEnabled && discount}>
                    <p className="items price review-page--disclaimer tds-text--caption">
                      {i18n('Review.priceAdjustment', { DISCOUNT: formatCurrency(-discount) })}
                    </p>
                  </If>

                  <If condition={destinationAndDocFee && showVehicleSubtotal}>
                    <p className="items price review-page--disclaimer tds-text--caption">
                      {i18n('SummaryPanel.disclaimers.otherFees', {
                        DOC_FEE: formatCurrency(destinationAndDocFee),
                      })}
                    </p>
                  </If>
                  <If condition={showVehicleSubtotal}>
                    <ExtraIncentives
                      wrapper={DivWrapper}
                      keyPrefix="ExtraIncentivesLoan"
                      hideZeroRegistrationTax
                    />
                  </If>
                  <If condition={destinationAndDocFee && showVehiclePricePlusFees}>
                    <p className="items price review-page--disclaimer tds-text--caption">
                      {feesDisclaimer}
                    </p>
                  </If>

                  <If condition={monthlyPaymentDisclaimer}>
                    <div className="items price review-page--disclaimer tds-text--caption">
                      {monthlyPaymentDisclaimer}
                    </div>
                  </If>

                  <CurrencyDisclaimer className="tds-text--caption" />
                  <If condition={referralCredit?.credit && !referralCredit?.includedInPayments}>
                    <span className="tds-text--caption">{referralCredit?.creditExcludedCopy}</span>
                  </If>
                  <CustomizePayment showElectronicPayment={showElectronicPayment} />
                  <If condition={showLoanFieldsOrderPage.includes('additionalInfo')}>
                    <div className="tds-o-vertical_padding-top tds-text--medium finance_info">
                      {i18n('FinancingOptions.additionalInfo')}
                    </div>
                  </If>
                  <If condition={showLeaseDisclaimer}>
                    <Choose>
                      <When condition={isUsedInventory && market === 'CN'}>
                        <div className="items price review-page--disclaimer tds-text--caption tds--vertical_padding-top">
                          {htmlToReact(i18n('FinancingOptions.loan__disclaimer_for_used'))}
                        </div>
                      </When>
                      <Otherwise>
                        <div className="items price review-page--disclaimer tds-text--caption tds--vertical_padding-top">
                          {htmlToReact(i18n('FinancingOptions.loan__disclaimer'))}
                        </div>
                      </Otherwise>
                    </Choose>
                  </If>
                </div>
              </div>
            </section>
          );
          break;
        }
        default:
          break;
      }

      return null;
    });

    return (
      <>
        {this.renderPricingDetails()}
        <If condition={financeSelectionDislaimer}>
          <div className="tds-text--caption">{i18n(financeSelectionDislaimer)}</div>
        </If>
        {this.renderUpdatedPricingDisclaimer()}

        <If condition={!isPreOrder() && !isPriceAcceptance()}>
          <div className="tds--is_hidden">
            {financeTabs.map(tab => (
              <LegalBanner key={`FinanceTabs:${tab.id}`} financeId={tab.id} />
            ))}
          </div>
          <div className="tds-tabs tds-tabs--toggle tds-tabs--animated">
            <FinanceToggle
              tabsSource={PAYMENT_FIN_TABS}
              label={i18n('common.Select_Finance_Solutions')}
            />
            <div className="tab-content">
              {tabContent.map(tab => {
                if (
                  tab.props.id === selectedTab ||
                  tab.props.id === FinanceTypes.CASH && selectedTab === FinanceTypes.CASH_BUSINESS
                ) {
                  return tab.props.children
                }
                return null
              })}
            </div>
            <If condition={postOrderDeposit.length}>
              {postOrderDeposit.map((item, index) => {
                const { title, label, value } = item || {};
                return (
                  <div key={`PostOrderDeposit.${trimCode}_${index}`}>
                    <p className="tds-o-padding_bottom-1x">{title}</p>
                    <ol className="tds-list cf-summary-list">
                      <li className="tds-list-item tds-flex">
                        <span className="label--option tds-flex-item">{label}</span>
                        <span className="value price-indicator tds-flex--col_1of3">{value}</span>
                      </li>
                    </ol>
                  </div>
                );
              })}
            </If>
          </div>
        </If>
      </>
    );
  }
}

Pricing.propTypes = {
  annualLeaseInterestRate: number,
  annualLoanInterestRate: number,
  modelCode: string,
  trimCode: string,
  cashPrice: shape({
    afterSavingsPrice: string,
    beforeSavingsPrice: string,
  }),
  cashPriceExVAT: shape({
    afterSavingsPrice: string,
    beforeSavingsPrice: string,
  }),
  shortPrice: shape({
    afterSavingsPrice: string,
    beforeSavingsPrice: string,
  }),
  monthlyPayment: string,
  amountAtSigning: string,
  loanTerm: number,
  interestRate: number,
  loanAfterSaving: string,
  destinationAndDocFee: number,
  incentives: shape({}),
  tcc: shape({}),
  leaseMileage: number,
  hideAfterSavingsPrice: bool,
  showSavingsAmount: bool.isRequired,
  showAmountIncludingFees: bool,
  hideAfterSavingsModal: bool,
  showPrpLink: bool,
  showBeforeSavingsPriceLeaseLabel: bool,
  showLeaseSummaryDisclaimer: bool,
  showSecondDepositDisclaimer: bool,
  configuration: arrayOf(string),
  showSavingsDisclaimer: bool,
  showLoanFieldsOrderPage: arrayOf(string),
  showDOCandOMVICfees: bool,
  setPricingDetails: func,
  showLoanSummaryDisclaimer: bool,
  vehiclePriceWithoutDiscounts: number,
  showPaymentDisclaimers: bool,
  downPayment: number,
  downPaymentPercentage: number,
  showLeaseSummaryFields: arrayOf(string),
  leaseTerm: number,
  ecoBonus: number,
  leaseData: shape({}),
  showCustomizeLabel: bool,
  leaseRate: number,
  governmentIncentivesTotal: number,
  showLoanSummaryFields: arrayOf(string),
  effectiveRate: number,
  residualAmount: number,
  loanData: shape({}),
  loanRate: number,
  debitRate: number,
  startFee: number,
  totalAmtPaid: number,
  balloonPayment: number,
  grossPrice: oneOfType([bool, number]),
  showVAT: bool,
  vatAmount: number,
  vatPercent: number,
  reversePricingOrder: bool,
  hideTaxesAndFeesExcluded: bool,
  registrationTax: number,
  showDriveAwayPrice: bool,
  showdestinationAndDocFee: bool,
  incentivesTotal: oneOfType([number, string]),
  fuelYears: number,
  model: shape({
    name: string,
  }),
  market: string,
  hideDestinationDocFee: bool,
  showRegistrationTax: bool,
  governmentIncentivesPeriod: string,
  showRegionalTaxTypes: arrayOf(string),
  showDistinctTaxTotal: bool,
  vehiclePriceInclFees: number,
  regionalTaxValues: shape(),
  deliveryFee: number,
  OMVICFee: number,
  gstSalePrice: number,
  onRoadfeesTotal: number,
  showRegionalTax: bool,
  summaryRegionalFees: bool,
  showIncentivesSectionTitle: bool,
  showOtherFeesinSummary: bool,
  showOrderPageSummaryOtherFees: bool,
  showTaxCalcDisclaimer: bool,
  isReservation: bool,
  isUsedInventory: bool,
  discount: number,
  isInventoryPriceAdjustmentsEnabled: bool,
  cashTabIncentives: shape({}),
  showVATBreakdown: bool,
  ACFee: number,
  showOtherLoanTerms: bool,
  showGrossPriceTotal: bool,
  netPrice: oneOfType([number, bool]),
  activeFinanceTypes: arrayOf(string),
  savingsAmount: string,
  showFormattedMonthlyPayment: bool,
  includeTeslaInsurance: bool,
  insuranceAmount: number,
  registrationType: string,
  useModelYContent: bool,
  frtFee: string,
  exlFrtPrice: string,
  showFeesDisclaimer: bool,
  showChargerDisclaimer: bool,
  showSavingsDisclaimeronCashTab: bool,
  tabIndex: number,
  reservationAmt: oneOfType([number, shape({})]),
  VehicleDetail: shape({
    series: string,
    qty: number,
  }),
  purchaseTax: oneOfType([number, string]),
  showTaxAndFeeDisclaimerForUsed: bool,
  isDomestic: bool,
  isPreSale: oneOfType([bool, string]),
  reservationPriceLabel: string,
  financeProductId: string,
  modifyOrder: bool,
  showPurchasePrice: bool,
  deliveryPostalCode: string,
  transportationFee: number,
  isTransportFeeEnabled: bool,
  isDeliveryDetailsValid: bool,
  userHasApplePay: bool,
  isEstimatedPrice: bool,
  incentiveTotalInPurchasePrice: number,
  showBeforeSavingsPriceFinancingLabel: bool,
  financeTabs: arrayOf(shape({})).isRequired,
  monthlyLeasePayment: number,
  isTransportFeeCollectionEnabled: bool,
  showMonthlyPaymentQuote: bool,
  loanType: string,
  showVatDisclaimer: bool,
  showGovernmentSubsidy: bool,
  selectedTab: oneOfType([number, string]).isRequired,
  selectedFinanceProduct: string.isRequired,
  totalSavings: number,
  fuelReward: shape({
    amount: number,
    formattedAmt: string,
    title: string,
    regionName: string,
  }),
  feesTotal: number,
  incentivesTotalPurchasePrice: oneOfType([number, string]),
  financeMiniDisclaimer: oneOfType([shape({}), string]),
  showProvincialIncentives: bool,
  extraPricingContextAmounts: arrayOf(
    shape({
      currency: string,
      grossPrice: number,
      vatAmount: number,
    })
  ),
  dutyAndSurcharge: number,
  evCredit: number,
  hideIncentivesOrderPage: bool,
  showEVCredit: bool,
  priceAfterEVCredit: number,
  fuelSavingsPerMonth: string,
  showFuelMonthlySavings: bool,
  monthlyPaymentDisclaimer: oneOfType([string, bool]),
  showAvailableInState: bool,
  tradeInType: string,
  showElectronicPayment: bool,
  showVehiclePricePlusFees: bool,
  showEvSubsidyDisclaimer: bool,
  secondPaymentAltCurrency: oneOfType([string, number]),
  governmentIncentive: number,
  depositAmount: number,
  financeSelectionDislaimer: string,
  regionalTax: number,
  governmentGrant: number,
  recyclingFee: oneOfType([string, bool]),
  tireFee: oneOfType([string, bool]),
  winterTireFee: oneOfType([string, bool]),
  specialTax: number,
  regionalFeeRebate: number,
  isEditDesignPreserved: bool,
  showLeaseDisclaimer: bool,
  firstRegistrationDate: string,
  showFirstRegistrationDatePaymentPage: bool,
  postOrderDeposit: arrayOf(
    shape({
      title: string,
      label: string,
      value: string,
    })
  ),
  showFeesSummary: bool,
  hidePurchasePriceIncentive: bool,
  showFinancialTabsEnabled: bool,
  showVehicleSubtotal: bool,
  feeLineItems: arrayOf(shape({})),
  showFinancingBanner: bool,
  displayDoubleCurrency: bool,
  leaseMonthlyPaymentInIntlCurrency: string,
  vehicleCondition: string,
  coeBidAmount: number,
  showCoEBid: bool,
  priceExcludingCoE: string,
  showPriceWithCOE: bool,
  monthlyPaymentWithCredit: string,
  deliveryCredit: number,
  vehiclePrice: string,
  showVehicleConfigurationPrice: bool,
  locale: string,
  finplatProductName: string,
  regionalDisclaimer: string,
  hasRegionalBannerModalCopy: bool,
  showVehiclePriceWithoutSavings: bool,
  feesDisclaimer: string,
  sctTax: shape({}),
  referralCredit: shape({}),
  showVisitTeslaFinancing: bool,
  tradeinDisclaimers: shape({
    downPayment: string,
    remainingCredit: string,
  }),
  veteranCredit: number,
};

Pricing.contextTypes = {
  i18n: func,
};

Pricing.defaultProps = {
  annualLeaseInterestRate: 0.0,
  annualLoanInterestRate: 0.0,
  cashPrice: {
    afterSavingsPrice: '',
    beforeSavingsPrice: '',
  },
  cashPriceExVAT: {
    afterSavingsPrice: '',
    beforeSavingsPrice: '',
  },
  shortPrice: {
    afterSavingsPrice: '',
    beforeSavingsPrice: '',
  },
  showAmountIncludingFees: false,
  monthlyPayment: '',
  amountAtSigning: '',
  loanTerm: null,
  interestRate: null,
  leaseMileage: null,
  loanAfterSaving: '',
  destinationAndDocFee: null,
  incentives: {},
  tcc: {},
  hideAfterSavingsPrice: false,
  showPrpLink: false,
  hideAfterSavingsModal: false,
  showBeforeSavingsPriceLeaseLabel: true,
  showLeaseSummaryDisclaimer: false,
  showSecondDepositDisclaimer: true,
  configuration: [],
  showSavingsDisclaimer: true,
  setPricingDetails: () => {},
  showLoanFieldsOrderPage: [],
  showDOCandOMVICfees: true,
  showLoanSummaryDisclaimer: false,
  vehiclePriceWithoutDiscounts: 0,
  showPaymentDisclaimers: true,
  downPayment: 0,
  downPaymentPercentage: 0,
  showLeaseSummaryFields: [],
  leaseTerm: 0,
  ecoBonus: 0,
  leaseData: {},
  showCustomizeLabel: false,
  leaseRate: 0,
  governmentIncentivesTotal: 0,
  showLoanSummaryFields: [],
  effectiveRate: 0,
  residualAmount: 0,
  loanData: {},
  loanRate: 0,
  debitRate: 0,
  startFee: 0,
  totalAmtPaid: 0,
  balloonPayment: 0,
  grossPrice: 0,
  showVAT: false,
  vatAmount: 0,
  vatPercent: 0,
  reversePricingOrder: false,
  hideTaxesAndFeesExcluded: false,
  registrationTax: 0,
  showDriveAwayPrice: false,
  showdestinationAndDocFee: false,
  incentivesTotal: 0,
  fuelYears: 0,
  modelCode: '',
  trimCode: '',
  model: {
    name: '',
  },
  market: '',
  showRegistrationTax: false,
  governmentIncentivesPeriod: '',
  hideDestinationDocFee: false,
  showRegionalTaxTypes: '',
  showDistinctTaxTotal: false,
  vehiclePriceInclFees: 0,
  regionalTaxValues: [],
  deliveryFee: 0,
  OMVICFee: 0,
  gstSalePrice: 0,
  onRoadfeesTotal: 0,
  showRegionalTax: false,
  summaryRegionalFees: false,
  showIncentivesSectionTitle: false,
  showOtherFeesinSummary: false,
  showOrderPageSummaryOtherFees: false,
  showTaxCalcDisclaimer: false,
  isReservation: false,
  isUsedInventory: false,
  discount: 0,
  isInventoryPriceAdjustmentsEnabled: false,
  cashTabIncentives: {},
  showVATBreakdown: false,
  ACFee: false,
  showOtherLoanTerms: false,
  showGrossPriceTotal: false,
  netPrice: 0,
  activeFinanceTypes: [],
  savingsAmount: '',
  showFormattedMonthlyPayment: false,
  includeTeslaInsurance: false,
  insuranceAmount: 0,
  registrationType: '',
  useModelYContent: false,
  frtFee: 0,
  exlFrtPrice: '',
  showFeesDisclaimer: false,
  showChargerDisclaimer: false,
  showSavingsDisclaimeronCashTab: false,
  tabIndex: -1,
  reservationAmt: 0,
  VehicleDetail: {},
  purchaseTax: 0,
  showTaxAndFeeDisclaimerForUsed: false,
  isDomestic: false,
  isPreSale: false,
  reservationPriceLabel: '',
  financeProductId: '',
  modifyOrder: false,
  showPurchasePrice: false,
  deliveryPostalCode: '',
  transportationFee: 0,
  isTransportFeeEnabled: false,
  isDeliveryDetailsValid: false,
  userHasApplePay: false,
  isEstimatedPrice: false,
  incentiveTotalInPurchasePrice: 0,
  showBeforeSavingsPriceFinancingLabel: false,
  monthlyLeasePayment: 0,
  isTransportFeeCollectionEnabled: false,
  showMonthlyPaymentQuote: false,
  loanType: '',
  showVatDisclaimer: false,
  showGovernmentSubsidy: false,
  totalSavings: 0,
  fuelReward: {},
  feesTotal: 0,
  incentivesTotalPurchasePrice: 0,
  financeMiniDisclaimer: '',
  showProvincialIncentives: false,
  extraPricingContextAmounts: [],
  dutyAndSurcharge: 0,
  evCredit: 0,
  hideIncentivesOrderPage: false,
  showEVCredit: false,
  priceAfterEVCredit: 0,
  fuelSavingsPerMonth: '',
  showFuelMonthlySavings: false,
  monthlyPaymentDisclaimer: '',
  showAvailableInState: false,
  tradeInType: '',
  showElectronicPayment: false,
  showVehiclePricePlusFees: false,
  showEvSubsidyDisclaimer: false,
  secondPaymentAltCurrency: '',
  governmentIncentive: 0,
  depositAmount: 0,
  financeSelectionDislaimer: '',
  regionalTax: 0,
  governmentGrant: 0,
  recyclingFee: 0,
  tireFee: 0,
  winterTireFee: 0,
  specialTax: 0,
  regionalFeeRebate: 0,
  isEditDesignPreserved: false,
  showLeaseDisclaimer: false,
  firstRegistrationDate: '',
  showFirstRegistrationDatePaymentPage: false,
  postOrderDeposit: [],
  showFeesSummary: false,
  hidePurchasePriceIncentive: false,
  showVehicleSubtotal: false,
  feeLineItems: [],
  showFinancingBanner: false,
  displayDoubleCurrency: false,
  leaseMonthlyPaymentInIntlCurrency: '',
  vehicleCondition: '',
  coeBidAmount: 0,
  showCoEBid: false,
  priceExcludingCoE: null,
  showPriceWithCOE: false,
  monthlyPaymentWithCredit: '',
  deliveryCredit: 0,
  vehiclePrice: '',
  showVehicleConfigurationPrice: false,
  locale: '',
  finplatProductName: '',
  regionalDisclaimer: '',
  hasRegionalBannerModalCopy: false,
  showVehiclePriceWithoutSavings: false,
  feesDisclaimer: '',
  sctTax: {},
  referralCredit: {},
  showVisitTeslaFinancing: false,
  tradeinDisclaimers: {
    downPayment: '',
    remainingCredit: '',
  },
  veteranCredit: 0,
};
