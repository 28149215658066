/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Input, RadioGroup, Select, Hidden, Checkbox } from '@tesla/informed-tds';
import { FormFieldset, FormLegend } from '@tesla/design-system-react';
import { getDisplayName } from '@tesla/intl-display-names';
import { request } from 'utils/requestAgent';
import {
  i18n,
  companyNameValidator,
  postalCodeValidator,
  maxLengthValidator,
  getTerritoryNameList,
} from 'utils';
import _get from 'lodash/get';
import _debounce from 'lodash/debounce';
import classnames from 'classnames';
import {
  FIELD_COMPANY_NAME,
  CHAMBER_OF_COMMERCE,
  FIELD_VAT_ID,
  FIELD_COMPANY_ADDRESS_1,
  FIELD_COMPANY_ADDRESS_2,
  FIELD_COMPANY_CITY,
  FIELD_COMPANY_POSTAL_CODE,
  OFFICE_TYPE_HEAD,
  OFFICE_TYPE_BRANCH,
  UI_DATA_IDS,
  FIELD_BRANCH_NAME,
  FIELD_BRANCH_ID,
  FIELD_COMPANY_COUNTRY,
  FIELD_COMPANY_COUNTY,
  FIELD_COMPANY_STATE,
  COMPANY,
  BRANCH,
  FIELD_COMPANY_DISTRICT,
} from 'dictionary';
import { useFormApi, useFieldState, Relevant } from 'informed';
import useStateWithGetter from '../../hooks/useStateWithGetter';
import { getProvincesList, getDistrictsList, getPostalCodesList } from 'selectors';
import { updateProvinceAddress, updateDistrictAddress } from 'actions';
import Loader from '../common/loader';
import CrossBorderRegistration from './CrossBorderRegistration';

function SearchIcon() {
  return (
    <svg
      className="tds-new-icon tds-new-icon-search"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m20.267 19.207-4.818-4.818A6.967 6.967 0 0 0 17 10a7 7 0 1 0-7 7 6.967 6.967 0 0 0 4.389-1.55l4.818 4.817a.748.748 0 0 0 1.06 0 .75.75 0 0 0 0-1.06zM4.5 10c0-3.033 2.467-5.5 5.5-5.5s5.5 2.467 5.5 5.5-2.467 5.5-5.5 5.5-5.5-2.467-5.5-5.5z"
        fill="currentColor"
      />
    </svg>
  );
}

function CrossIcon() {
  return (
    <svg
      className="tds-new-icon tds-new-icon-cross"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.243 11.182a.75.75 0 1 1-1.06 1.06L8 9.062l-3.182 3.182a.748.748 0 0 1-1.06 0 .75.75 0 0 1 0-1.06L6.938 8 3.757 4.818a.75.75 0 1 1 1.06-1.06L8 6.938l3.182-3.182a.75.75 0 1 1 1.06 1.06L9.062 8l3.182 3.182z"
        fill="currentColor"
      />
    </svg>
  );
}

function CompanySearch({
  countryCode,
  countryName,
  language,
  chamberOfCommerceValidator,
  companyNumberPlaceholder,
  companyNumberFormatter,
  companyNumberMask,
  businessVatFormat,
  vatIdValidator,
  disableCompanySearch = false,
  showCompanyFields,
  optionalCompanyFields,
  companyProvinces,
  updateProvince,
  updateDistrict,
  companyDistricts,
  companyPostalCodes,
  allowCrossBorderRegistration,
  businessSearchApi,
  taxOfficesList,
  usesOfInvoiceList,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [showFields, setShowFields, getShowFields] = useStateWithGetter(disableCompanySearch);
  const [companies, setCompanies] = useState([]);
  const [position, setPosition] = useState({ left: 0, top: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const onChangeHandler = useRef();
  const req = useRef();
  const detailsReq = useRef();
  const parentRef = useRef();
  const requestRef = useRef();
  const inputRef = useRef();
  const containerRef = useRef();
  const formApi = useFormApi();
  const { value: companyDetails } = useFieldState('companyDetails');
  const { officeType, companyProvince, companyDistrict, companyPostalCode } = companyDetails || {};
  const isBranchOffice = officeType === OFFICE_TYPE_BRANCH;
  const companyType = isBranchOffice ? BRANCH : COMPANY;
  useEffect(() => {
    const updatePosition = () => {
      if (parentRef.current) {
        const bounds = parentRef.current.getBoundingClientRect();
        requestRef.current = requestAnimationFrame(() => {
          setPosition({
            left: bounds.left,
            top: bounds.top,
            width: bounds.width,
          });
        });
      }
    };

    onChangeHandler.current = _debounce(({ value }) => {
      if (value && value.length >= 3) {
        const formattedRequest = {
          companyName: value,
          countryCode,
        };

        if (req.current) {
          req.current.abort();
        }

        req.current = request.post(businessSearchApi);

        setIsLoading(true);

        req.current
          .send(formattedRequest)
          .set('X-Requested-With', 'XMLHttpRequest')
          .set('Accept', 'application/json')
          .end((err, response) => {
            if (!err) {
              const companies = _get(response, 'body', []);
              setCompanies(companies);
            } else {
              setCompanies([]);
            }

            setIsLoading(false);
            setIsOpen(true);

            formApi.validate();
          });
      } else {
        setIsOpen(false);
      }

      updatePosition();

      if (containerRef.current) {
        containerRef.current.scrollTop = 0;
      }
    }, 400);

    window.addEventListener('resize', updatePosition);
    window.addEventListener('scroll', updatePosition, { passive: true, capture: true });

    return () => {
      window.removeEventListener('resize', updatePosition);
      window.removeEventListener('scroll', updatePosition, { passive: true, capture: true });

      cancelAnimationFrame(requestRef.current);
    };
  }, []);

  const toggleToManualEntry = () => {
    if (formApi.getValue('companyDetails.companyId')) {
      formApi.setValue('companyDetails', {});
    }
    setIsOpen(false);
    setTimeout(() => {
      setShowFields(true);
      inputRef.current.blur();
      document
        .getElementById('company-information')
        .scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 0);
  };

  const getCompanyDetailsById = companyId => {
    setIsLoadingDetails(true);
    const formattedRequest = {
      companyId,
      countryCode,
    };
    if (detailsReq.current) {
      detailsReq.current.abort();
    }
    detailsReq.current = request.post(businessSearchApi);
    detailsReq.current
      .send(formattedRequest)
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('Accept', 'application/json')
      .end((err, response) => {
        if (!err) {
          setIsLoadingDetails(false);
          const comp = _get(response, 'body', {});
          formApi.setValue('companyDetails', {
            companyId: comp.companyId,
            companyAddress1: comp.street1,
            companyAddress2: comp.street2,
            companyCity: comp.city,
            companyState: comp.stateProvince,
            companyPostalCode: comp.postalCode,
            companyCountryCode: comp.countryCode,
            _companyName: comp.companyName,
            _companyNumber: comp.companyRegistrationNumber,
            _vatId: comp.taxId,
          });
        } else {
          toggleToManualEntry();
        }
      });
  };

  return (
    <>
      <h4 className="review-page--header">
        <span id="company-information" style={{ position: 'relative', top: -50 }}></span>
        {i18n('CompanySearch.title')}
      </h4>
      <If condition={allowCrossBorderRegistration}>
        <CrossBorderRegistration />
      </If>
      <Relevant when={({ formState }) => !formState.values.nonResidentCompany}>
        <FormFieldset>
          <FormLegend text={i18n('CompanySearch.title')} className="tds--is_visually_hidden" />
          <div className={classnames('company-search', { 'tds--is_visually_hidden': disableCompanySearch })} ref={parentRef}>
            <If condition={!disableCompanySearch}>
              <Input
                name="companySearch"
                placeholder={i18n('CompanySearch.placeholder')}
                onChange={onChangeHandler.current}
                aria-haspopup="listbox"
                onBlur={() => setIsOpen(false)}
                onFocus={() => {
                  if (companies.length > 0) {
                    setIsOpen(true);
                  }
                }}
                autocomplete="off"
                inputRef={inputRef}
                validate={(value, { companyId }) => {
                  if (!getShowFields() && companyId == null) {
                    return i18n('CompanySearch.error_message');
                  }
                }}
                validateOn="change"
                leading={<SearchIcon />}
                trailing={isLoading && <Loader />}
              />
            </If>

            <Hidden name="companyId" />
            <div className="company-search--suffix">
              <Choose>
                <When condition={companies.length > 0}>
                  <button
                    type="button"
                    onClick={() => {
                      setCompanies([]);
                      setIsOpen(false);
                      formApi.setValue('companySearch', '');

                      setTimeout(() => {
                        inputRef.current.focus();
                      }, 0);
                    }}
                  >
                    <CrossIcon />
                  </button>
                </When>
              </Choose>
            </div>
            <ul
              tabIndex={-1}
              role="listbox"
              aria-expanded={isOpen}
              className={classnames('company-search-list', { 'is-open': isOpen })}
              style={position}
              ref={containerRef}
              onMouseDown={e => e.preventDefault()}
            >
              {companies.map(comp => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <li
                  role="option"
                  aria-selected={false}
                  key={comp.companyId}
                  className="tds--vertical_padding tds--horizontal_padding tds--padding"
                  onClick={() => {
                    formApi.setValue('companyDetails', {
                      companyId: comp.companyId,
                      companyAddress1: comp.street1,
                      companyAddress2: comp.street2,
                      companyCity: comp.city,
                      companyPostalCode: comp.postalCode,
                      companyCountryCode: comp.countryCode,
                      _companyName: comp.companyName, // Fields starting with _ won't be use in updateAccountForm(). companyName, companyNumber and vatId will be fetched in BFF
                      _companyNumber: comp.companyRegistrationNumber,
                      _vatId: comp.taxId,
                    });
                    if (comp.getDetailsById) {
                      getCompanyDetailsById(comp.companyId);
                    }
                    formApi.setValue('vatId', '');
                    formApi.setValue('companyId', comp.companyId);
                    setIsOpen(false);
                    setShowFields(false);
                    inputRef.current.blur();
                  }}
                >
                  <strong>{comp.companyName}</strong>
                  <br />
                  {(comp.street1 || comp.city) && (
                    <>
                      {[comp.street1, comp.city].filter(Boolean).join(', ')}
                      <br />
                    </>
                  )}
                  {[comp.companyRegistrationNumber, comp.taxId].filter(Boolean).join(' / ')}
                </li>
              ))}
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
              <li
                role="option"
                aria-selected={false}
                className="tds--vertical_padding tds--horizontal_padding tds--padding"
                onClick={toggleToManualEntry}
                onMouseDown={e => e.preventDefault()}
              >
                <p>{i18n('CompanySearch.not_listed')}</p>
                <span className="tds-link">{i18n('CompanySearch.enter_info')}</span>
              </li>
            </ul>
          </div>
          {companyDetails && companyDetails.companyId && (
            <>
              <div className="company-search-details">
                <h6>{i18n('CompanySearch.company')}</h6>
                {companyDetails._companyName}
                <br />
                {(companyDetails.companyAddress1 || companyDetails.companyPostalCode) && (
                  <>
                    {[companyDetails.companyAddress1, companyDetails.companyPostalCode]
                      .filter(Boolean)
                      .join(', ')}
                    <br />
                  </>
                )}
                {companyDetails.companyCity && (
                  <>
                    {[companyDetails.companyCity, countryName].join(', ')}
                    <br />
                  </>
                )}
                {[companyDetails._companyNumber, companyDetails._vatId].filter(Boolean).join(' / ')}
              </div>
              <Choose>
                <When condition={isLoadingDetails}>
                  <Loader />
                </When>
                <Otherwise>
                  {companyDetails._vatId == null && showCompanyFields.includes('vatId') && (
                    <Input
                      name="vatId"
                      id={FIELD_VAT_ID}
                      label={i18n('Review.BillingInfo.Form.vat_id')}
                      placeholder={businessVatFormat}
                      validate={vatIdValidator}
                      validateOn="change"
                      required
                      keep={{ value: true, touched: true }}
                    />
                  )}
                  {companyDetails._companyNumber == null && showCompanyFields.includes('companyNumber') && (
                    <Input
                      name="companyDetails.companyNumber"
                      id={CHAMBER_OF_COMMERCE}
                      label={i18n('Review.account_chamber_of_commerce_label')}
                      validate={chamberOfCommerceValidator}
                      validateOn="change"
                      required
                      keep={{ value: true, touched: true }}
                      placeholder={companyNumberPlaceholder}
                      formatter={companyNumberFormatter}
                      mask={companyNumberMask}
                    />
                  )}
                </Otherwise>
              </Choose>
            </>
          )}
          <Hidden name="companyDetails.companyCountryCode" value={countryCode} />
          <If condition={showFields}>
            <If condition={!disableCompanySearch}>
              <p className="tds-text--caption tds--vertical_padding">
                {i18n('CompanySearch.description')}
              </p>
            </If>
            {showCompanyFields.map(field => {
              const fieldName = typeof field === 'object' ? field?.fieldname : field;
              switch (fieldName) {
                case 'companyName':
                  return (
                    <Input
                      name="companyDetails.companyName"
                      id={FIELD_COMPANY_NAME}
                      label={i18n('Review.account_company_name_label')}
                      required
                      keep={{ value: true, touched: true }}
                      validate={companyNameValidator}
                    />
                  );
                case 'companyAddress1':
                  return (
                    <Input
                      name="companyDetails.companyAddress1"
                      id={FIELD_COMPANY_ADDRESS_1}
                      label={i18n(`Review.account_${companyType}_address_1_label`)}
                      required
                      keep={{ value: true, touched: true }}
                      validate={value => maxLengthValidator(value, 255)}
                    />
                  );
                case 'companyAddress2':
                  return (
                    <Input
                      name="companyDetails.companyAddress2"
                      id={FIELD_COMPANY_ADDRESS_2}
                      label={i18n(`Review.account_${companyType}_address_2_label`)}
                      keep={{ value: true, touched: true }}
                      validate={value => maxLengthValidator(value, 255)}
                    />
                  );
                case 'companyState':
                  const { showStatesList = false, useLabelForValue = false } = field || {};
                  const companyStates = showStatesList ? getTerritoryNameList(countryCode, language, { useLabelForValue }) : []
                  if (companyStates?.length) {
                    return (
                      <Select
                        name="companyDetails.companyState"
                        id={FIELD_COMPANY_STATE}
                        label={i18n('Review.account_company_state_label')}
                        options={companyStates}
                        initialValue={companyStates?.[0]?.value}
                        required
                        keep={{ value: true, touched: true }}
                      />
                    );
                  }
                  return (
                    <Input
                      name="companyDetails.companyState"
                      id={FIELD_COMPANY_STATE}
                      label={i18n('Review.account_company_state_label')}
                      required
                      keep={{ value: true, touched: true }}
                      validate={value => maxLengthValidator(value, 50)}
                    />
                  );
                case 'companyProvince':
                  if (companyProvinces?.length) {
                    return (
                      <Select
                        name="companyDetails.companyProvince"
                        label={i18n('Review.account_company_province_label')}
                        options={companyProvinces}
                        required
                        keep={{ value: true, touched: true }}
                        onChange={({ value }) => {
                          if (companyDistrict) {
                            formApi?.setValue('companyDetails.companyDistrict', '');
                          }
                          if (companyPostalCode) {
                            formApi?.setValue('companyDetails.companyPostalCode', '');
                          }
                          updateProvince(value);
                        }}
                      />
                    );
                  }
                  return;
                case 'companyDistrict':
                  if (companyProvinces?.length) {
                    return (
                      <Select
                        name="companyDetails.companyDistrict"
                        id={FIELD_COMPANY_DISTRICT}
                        label={i18n('Review.account_company_district_label')}
                        options={companyDistricts}
                        required
                        keep={{ value: true, touched: true }}
                        disabled={!companyProvince}
                        onChange={({ value }) => {
                          if (companyPostalCode) {
                            formApi?.setValue('companyDetails.companyPostalCode', '');
                          }
                          updateDistrict(value);
                        }}
                      />
                    );
                  }
                  return;
                case 'companyCity':
                  return (
                    <Input
                      name="companyDetails.companyCity"
                      id={FIELD_COMPANY_CITY}
                      label={i18n('Review.account_company_city_label')}
                      required
                      keep={{ value: true, touched: true }}
                      showErrorIfDirty
                      validate={value => maxLengthValidator(value, 50)}
                    />
                  );
                case 'companyCounty':
                  return (
                    <Input
                      name="companyDetails.companyCounty"
                      id={FIELD_COMPANY_COUNTY}
                      label={i18n('Review.account_company_county_label')}
                      required
                      keep={{ value: true, touched: true }}
                      showErrorIfDirty
                      validate={value => maxLengthValidator(value, 50)}
                    />
                  );
                case 'companyPostalCode':
                  if (companyProvinces?.length) {
                    return (
                      <Select
                        name="companyDetails.companyPostalCode"
                        id={FIELD_COMPANY_POSTAL_CODE}
                        label={i18n('Review.account_company_postal_code_label')}
                        options={companyPostalCodes}
                        required
                        keep={{ value: true, touched: true }}
                        disabled={!companyDistrict}
                      />
                    );
                  }
                  return (
                    <Input
                      name="companyDetails.companyPostalCode"
                      id={FIELD_COMPANY_POSTAL_CODE}
                      label={i18n('Review.account_company_postal_code_label')}
                      validate={value => postalCodeValidator(value, countryCode)}
                      required={!optionalCompanyFields.includes('companyPostalCode')}
                      keep={{ value: true, touched: true }}
                      showErrorIfDirty
                    />
                  );
                case 'companyCountry':
                  return (
                    <Input
                      name="companyDetails.companyCountry"
                      id={FIELD_COMPANY_COUNTRY}
                      label={i18n('Review.account_company_country_label')}
                      initialValue={countryCode}
                      disabled
                    />
                  );
                case 'vatId':
                  return (
                    <Input
                      name="vatId"
                      id={FIELD_VAT_ID}
                      label={i18n('Review.BillingInfo.Form.vat_id')}
                      placeholder={businessVatFormat}
                      validate={vatIdValidator}
                      validateOn="change"
                      required={!optionalCompanyFields.includes('vatId')}
                      keep={{ value: true, touched: true }}
                    />
                  );

                case 'companyNumber':
                  const { regex } = field || {};
                  return (
                    <Input
                      name="companyDetails.companyNumber"
                      id={CHAMBER_OF_COMMERCE}
                      label={i18n('Review.account_chamber_of_commerce_label')}
                      validate={(value, values) =>
                        chamberOfCommerceValidator(value, values, { regex })
                      }
                      validateOn="change"
                      required
                      keep={{ value: true, touched: true }}
                      placeholder={companyNumberPlaceholder}
                      formatter={companyNumberFormatter}
                      mask={companyNumberMask}
                    />
                  );
                case 'officeType':
                  return (
                    <div className="tds-o-fieldset-form">
                      <RadioGroup
                        name="companyDetails.officeType"
                        options={[
                          {
                            value: OFFICE_TYPE_HEAD,
                            label: i18n('Review.head_office_label'),
                            'data-id': `${UI_DATA_IDS?.accountSection?.headOffice}`,
                          },
                          {
                            value: OFFICE_TYPE_BRANCH,
                            label: i18n('Review.branch_office_label'),
                            'data-id': `${UI_DATA_IDS?.accountSection?.branchOffice}`,
                          },
                        ]}
                        keep={{ value: true, touched: true }}
                        initialValue={OFFICE_TYPE_HEAD}
                      />
                    </div>
                  );
                case 'branchName':
                  if (isBranchOffice) {
                    return (
                      <Input
                        name="companyDetails.branchName"
                        id={FIELD_BRANCH_NAME}
                        label={i18n('Review.branch_name_label')}
                        required
                        keep={{ value: true, touched: true }}
                        showErrorIfDirty
                      />
                    );
                  }
                  return;
                case 'branchId':
                  if (isBranchOffice) {
                    return (
                      <Input
                        name="companyDetails.branchId"
                        id={FIELD_BRANCH_ID}
                        label={i18n('Review.branch_id_label')}
                        required
                        keep={{ value: true, touched: true }}
                      />
                    );
                  }
                  return;
                case 'taxOfficeName':
                  if (taxOfficesList.length) {
                    return (
                        <Select
                            id={`companyDetails.${fieldName}`}
                            name={`companyDetails.${fieldName}`}
                            label={i18n('Review.tax_office_label')}
                            options={
                              [
                                { label: i18n(`common.select`), value: '', disabled: true },
                                ...taxOfficesList.map((id) => {
                                  const label = i18n(`Review.taxOffices.${id}`, null, null, {
                                    returnNullWhenEmpty: true
                                  });
                                  return { label: label ? label : id, value: id }}
                                ),
                              ]
                            }
                            required
                        />
                    )
                  }
                  return (
                    <Input
                      id={`companyDetails.${fieldName}`}
                      name={`companyDetails.${fieldName}`}
                      label={i18n('Review.tax_office_label')}
                      required
                      keep={{ value: true, touched: true }}
                      validate={companyNameValidator}
                    />
                  );
                case 'taxableInvoice':
                  return (
                      <Checkbox
                          label={i18n('Review.taxable_invoice')}
                          id={`companyDetails.${fieldName}`}
                          name={`companyDetails.${fieldName}`}
                          defaultValue={false}
                      />
                  );
                case 'useOfInvoice':
                  if (usesOfInvoiceList.length) {
                    return (
                        <Relevant
                            when={({ formState }) => formState.values.companyDetails?.taxableInvoice}
                        >
                          <Select
                              id={`companyDetails.${fieldName}`}
                              name={`companyDetails.${fieldName}`}
                              label={i18n('Review.use_of_invoice')}
                              options={
                                [
                                  { label: i18n(`common.select`), value: '', disabled: true },
                                  ...usesOfInvoiceList.map((id) => {
                                    const label = i18n(`Review.usesOfInvoice.${id}`, null, null, {
                                      returnNullWhenEmpty: true
                                    });
                                    return { label: label ? label : id, value: id }}
                                  ),
                                ]
                              }
                              required
                          />
                        </Relevant>
                    )
                  }
                  return null;
                default:
                  return null;
              }
            })}
          </If>
        </FormFieldset>
      </Relevant>
    </>
  );
}

function mapStateToProps(state) {
  const { App, ReviewDetails } = state;
  const { routes, language } = App;
  const { showCompanyFields = [], optionalCompanyFields = [] } = ReviewDetails;
  const countryCode = _get(state, 'App.countryCode', '');
  const countryName = getDisplayName(countryCode) || '';
  const { staticAddressApi, businessSearch: businessSearchApi = '/configurator/api/v3/business-search' } = routes;

  return {
    countryCode,
    countryName,
    language,
    showCompanyFields,
    optionalCompanyFields,
    companyProvinces: getProvincesList(state),
    staticAddressApi,
    companyDistricts: getDistrictsList(state),
    companyPostalCodes: getPostalCodesList(state),
    businessSearchApi,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateProvince: province => {
      dispatch(updateProvinceAddress(province));
    },
    updateDistrict: district => {
      dispatch(updateDistrictAddress(district));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanySearch);
