import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import { string, shape, arrayOf, bool } from 'prop-types';
import { formatNumber } from '@tesla/coin-common-components';
import { TRIM } from 'dictionary';
import TextLoader from '../TextLoader';

/* DynamicGrid component */
const DynamicGrid = ({ group, gridContent, isLayoutMobile, isEnterpriseOrder }) => {
  const { code } = group;
  if (!group || !gridContent) {
    return null;
  }
  const hasDynamicWidth = isEnterpriseOrder && code === TRIM && gridContent.length > 1;
  return (
    <div className="tds-flex tds-flex-gutters">
      {gridContent.map((item, index) => {
        const dynamicStyle =
          hasDynamicWidth && index < 3 ? { width: '10%', whiteSpace: 'nowrap' } : {};
        return (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`DynamicGrid.${code}_${index}`}
            className={classnames(
              `tds-flex-item tds--no_vertical_padding ${item.classes}`,
              {
                'tds-flex--col_1of2': gridContent.length > 3,
              },
              {
                'tds-flex--auto': index !== 3,
              }
            )}
          >
            <ol
              className={classnames('tds-list', {
                'tds--vertical_padding': !isLayoutMobile,
                'tds--padding_top-16': isLayoutMobile,
              })}
            >
              <li className="tds-list-item">
                <TextLoader
                  data={item.title}
                  tag={{ component: 'span' }}
                  className="tds-list-item_title tds-text_color--black tds-text--medium"
                />
              </li>
            </ol>
            {item.contents.map((i, k) => {
              return (
                <If condition={i.label && i.value}>
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={`DynamicGrid.${code}_${k}`}
                    className="tds-flex tds-flex-gutters feature-group--line-item"
                  >
                    <table className="tds-table tds-table--thead_border tds-table--padded_cells feature-table--section">
                      <tbody>
                        <tr className="line-item">
                          <td className="tds-flex-item line-item--label tds-text--caption tds-o-grid-label">
                            {i.label}
                          </td>
                          <td
                            style={dynamicStyle}
                            className="tds-flex-item line-item--value tds-text--caption tds-text_color--10 tds-o-grid-value"
                          >
                            {i.value}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </If>
              );
            })}
            <If condition={item.disclaimer}>
              <TextLoader
                data={item.disclaimer}
                tag={{ component: 'span' }}
                className="tds-text_color--30 tds-text--caption"
              />
            </If>
          </div>
        );
      })}
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const { App, CustomGroups = {} } = state;
  const { isLayoutMobile, showMinMaxRangeValues, isEnterpriseOrder } = App;
  const { content, group } = ownProps;
  const targetGroup = CustomGroups[group] || {};
  const { current = [] } = targetGroup;
  const gridContent = content.map(element => {
    const {
      title_path: titlePath = '',
      content_path: elemContent = {},
      disclaimer_path: disclaimerPath = '',
    } = element;
    const title = _get(state, titlePath, '');
    const disclaimer = _get(state, disclaimerPath, '');
    let contents = [];
    // Backwards compatibility with the old structure
    if (_isArray(elemContent)) {
      contents = elemContent.map(elm => {
        const labelSource = isEnterpriseOrder && elm.label === 'name' ? 'long_name' : elm.label;
        const label = _get(opt, labelSource, '');
        return {
          label,
          value: _get(state, elm.value, ''),
        };
      });
    } else {
      contents = current.map(opt => {
        let contentValue = _get(opt, `${elemContent.value}`, '');
        const hasMinMaxRangeValue = opt?.formattedSpecs?.minMaxFormatted;

        if (
          elemContent.value === 'formattedSpecs.accelerationFormatted' &&
          contentValue.match(/^\d/)
        ) {
          const acceleration = _get(opt, `formattedSpecs.acceleration`, '');
          const decimalsStr = acceleration.toString().split('.')[1];
          const decimalPoint = (decimalsStr && decimalsStr.length) || 1;
          contentValue = contentValue.replace(
            acceleration,
            formatNumber(acceleration, { precision: decimalPoint })
          );
        }

        if (elemContent.value === 'formattedSpecs.rangeFormatted' && showMinMaxRangeValues) {
          const rangeFormatted = _get(opt, `formattedSpecs.rangeFormatted`, '');
          const rangesValue = _get(opt, `formattedSpecs.minMaxFormatted`, '');
          contentValue = hasMinMaxRangeValue
            ? contentValue.replace(rangeFormatted, rangesValue)
            : contentValue;
        }

        // elemContent.postfix (grid line item control), rawData.postfix (trim control)
        if (elemContent.postfix && opt.lexicon_specs?.rawData?.postfix) {
          contentValue += opt.lexicon_specs.rawData.postfix;
        }
        const labelSource =
          isEnterpriseOrder && elemContent.label === 'name' ? 'long_name' : elemContent.label;
        const label = _get(opt, labelSource, '');
        return {
          label,
          value: contentValue,
        };
      });
    }
    return {
      contents,
      title,
      disclaimer,
      classes: _get(element, 'classes', ''),
    };
  });
  return {
    gridContent,
    group: targetGroup,
    isLayoutMobile,
    isEnterpriseOrder,
  };
}

DynamicGrid.propTypes = {
  group: shape({
    code: string,
  }).isRequired,
  gridContent: arrayOf(shape({})),
  isLayoutMobile: bool,
  isEnterpriseOrder: bool,
};

DynamicGrid.defaultProps = {
  gridContent: [],
  isLayoutMobile: false,
  isEnterpriseOrder: false,
};

export default connect(mapStateToProps, null)(DynamicGrid);
