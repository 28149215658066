import _get from 'lodash/get';

import {
  OMS_REQUEST_DEPENDENCIES,
  OMS_RECEIVED_DEPENDENCIES,
  OMS_REQUEST_PRICEBOOK,
  OMS_RECEIVED_PRICEBOOK,
  UPDATE_BATTERY_GROUP_SPECS,
} from 'dictionary';

import { fetchConfiguration } from '../../Configuration';

import { DSServices } from '@web/tesla-rest-ds-services';
const Services = new DSServices({});

function requestOMSDependencies(oms_params) {
  return {
    type: OMS_REQUEST_DEPENDENCIES,
    oms_params,
  };
}

function receivedOMSData(data) {
  return dispatch => {
    dispatch({
      type: OMS_RECEIVED_DEPENDENCIES,
      data: {
        groups: data.Groups,
        base: data.BaseOptions || {},
        incentives: data.Incentives,
        deprecatedOptions: data.DeprecatedOptions || {},
        lexicon: data.Lexicon,
        fees: data.Fees,
        loan: data.Loan,
        lease: data.Lease,
        tcc: data.TCC,
        finplat: data.Finplat || [],
      },
      raw: data,
    });
    if (!_get(data, 'Lexicon')) {
      return;
    }
    dispatch({ type: UPDATE_BATTERY_GROUP_SPECS });
    dispatch(fetchConfiguration());
  };
}

export function fetchOMS() {
  return (dispatch, getState) => {
    const { oms_params } = getState().OMS;
    dispatch(requestOMSDependencies(oms_params));
    const response = Services.get(() => {}, oms_params);
    dispatch(receivedOMSData(response));
  };
}
