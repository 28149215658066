import React from 'react';
import { htmlToReact, i18n } from 'utils';

const LegalDataTable = () => (
  <div className="review-page--block">
    <h3 className="tds-text--body_headline">{i18n('Review.legalDataTable.title')}</h3>
    <p className="tds--vertical_padding legal-data-table">
      {htmlToReact(
        i18n('Review.legalDataTable.content', {
          TABLE: '<table>',
          TABLECLOSE: '</table>',
          TBODY: '<tbody>',
          TBODYCLOSE: '</tbody>',
          TD: '<td>',
          TDCLOSE: '</td>',
          TR: '<tr>',
          TRCLOSE: '<tr>',
        })
      )}
    </p>
  </div>
);

LegalDataTable.propTypes = {};

LegalDataTable.defaultProps = {};

export default LegalDataTable;
