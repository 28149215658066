import { UPDATE_SUBSCRIPTION } from 'dictionary';
import _isEmpty from 'lodash/isEmpty';

export default function Reducer(state = {}, action) {
  const { payload = {}, groupName } = action || {};
  switch (action.type) {
    case UPDATE_SUBSCRIPTION: {
      const { [groupName]: selected, ...rest } = state || {};
      return {
        ...rest,
        ...(_isEmpty(payload) ? {} : { [groupName]: payload }),
      };
    }
    default:
      return state;
  }
}