import React from 'react';
import { connect } from 'react-redux';
import { Observable, fromEvent } from 'rxjs';
import { interval } from 'rxjs/observable/interval';
import { take, merge, takeUntil } from 'rxjs/operators';
import cx from 'classnames';
import { bool } from 'prop-types';
import { Icon } from '@tesla/design-system-react';
import { iconChevronFilled180 } from '@tesla/design-system-icons';

import '../../style/scroll-indicator.css';

class ScrollIndicator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      fade: false,
    };
    const intervalTimer = interval(5000);
    const scrollObserver = fromEvent(window, 'scroll')
      .pipe(merge(fromEvent(window, 'wheel')), take(1));

    this.observable = intervalTimer.pipe(takeUntil(scrollObserver));
    this.disposable = null;
  }

  componentDidMount() {
    this.disposable = this.observable.subscribe({
      next: () => {
        this.setState(prevState => {
          if (!prevState.isVisible) {
            return {
              ...prevState,
              isVisible: true,
            };
          }
          return null;
        });
      },
      complete: () => {
        this.setState({
          fade: true,
        });
        setTimeout(() => {
          this.setState({
            isVisible: false,
          });
        }, 500);
      },
    });
  }

  componentWillUnmount() {
    this.disposable.unsubscribe();
  }

  render() {
    const { disableScrollIndicator } = this.props;
    const { isVisible, fade } = this.state;
    return (
      <>
        <div
          className={cx('fade-feature', {
            'is-open': !disableScrollIndicator && isVisible,
            'is-close': fade,
          })}
          style={{
            pointerEvents: 'none',
          }}
        >
          <If condition={!disableScrollIndicator && isVisible}>
            <div className="scroll-indicator-container fade">
              <Icon data={iconChevronFilled180} className="scroll-indicator bounce" />
              <title>Arrow</title>
            </div>
          </If>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { FeatureListModal, FinanceModal, Modal, App } = state;

  return {
    disableScrollIndicator:
      !!(FeatureListModal.isCarouselOpen || FinanceModal.isCarouselOpen || Modal.open || App.isCoinReloaded),
  };
};

ScrollIndicator.propTypes = {
  disableScrollIndicator: bool.isRequired,
};

export default connect(mapStateToProps)(ScrollIndicator);
