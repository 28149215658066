/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import _debounce from 'lodash/debounce';
import { string, bool, func, number, shape, arrayOf, oneOfType } from 'prop-types';
import classnames from 'classnames';
import { Form, Input, Select } from '@tesla/informed-tds';
import { Button, FormLabel } from '@tesla/design-system-react';
import _get from 'lodash/get';
import _upperFirst from 'lodash/upperFirst';
import { validatePostalCode } from '@tesla/intl-address';
import { formatCurrency } from '@tesla/coin-common-components';
import { getStates } from '@tesla/intl-display-names';
import { getModel } from 'selectors';
import { i18n, setPreferredLocationCookie } from 'utils';
import Analytics from 'analytics';
import {
  setDeliveryDetailsValidFlag,
  updateDeliveryDetails,
  getGeocodeByLocation,
  setSummaryPanelRegion,
  fetchAlternateVehicleDesigns,
} from 'actions';
import {
  FIELD_DELIVERY_POSTALCODE,
  FIELD_DELIVERY_LATITUDE,
  FIELD_DELIVERY_LONGITUDE,
  FIELD_DELIVERY_STATE_PROVINCE,
  FIELD_DELIVERY_CITY,
  NAVIGATION_VIEW_EARLYDELIVERY,
} from 'dictionary';

const assignments = {
  [FIELD_DELIVERY_POSTALCODE]: {
    maxLength: 20,
    minLength: 3,
  },
  [FIELD_DELIVERY_CITY]: {
    maxLength: 50,
    minLength: 1,
  },
  [FIELD_DELIVERY_STATE_PROVINCE]: {
    maxLength: 50,
    minLength: 1,
  },
};

const PostalCode = ({
  formApiRef,
  deliveryPostalCode,
  labelCopy,
  placeholder,
  onValidationChange,
  deliveryDistance,
  updateFormDetails,
  isDeliveryDetailsRequiredOnOrderFlow,
  modelCode,
  isConfigFlow,
  isDeliveryFormValid,
  setValidFlag,
  regionCode,
  setRegion,
  market,
  getAlternateDesigns,
  isSwap,
  showSubmitButton,
  isLayoutMobile,
  isPreferredAddressEnabled,
}) => {
  const zipCodeValidator = value => {
    if (!value || (value && !validatePostalCode(value, { countryCode: market }))) {
      return i18n('common.errors__invalidZipCode');
    }
  };

  const errorMessage = field => {
    const { maxLength, minLength } = assignments[field];
    return {
      maxLength: i18n('common.errors__maxLength', { MAXLENGTH: maxLength }),
      minLength: i18n('common.errors__minLength', { MAXLENGTH: minLength }),
    };
  };

  const getGeocodingData = value => {
    getGeocodeByLocation({ postal_code: value, country: market }, (err, res) => {
      if (res) {
        const { latitude = '', longitude = '', stateCode = '', stateProvince = '' } = res;
        const payload = {
          ...res,
          [FIELD_DELIVERY_LATITUDE]: latitude,
          [FIELD_DELIVERY_LONGITUDE]: longitude,
          stateCode,
          stateProvince,
          PostalCode: value,
        };
        if (isPreferredAddressEnabled) {
          setPreferredLocationCookie(payload);
        }
        updateFormDetails(payload);
        if (stateCode && regionCode !== stateCode) {
          setRegion(stateCode);
        }
      }
      return null;
    });
  };

  const updateDeliveryInfo = () => {
    const fieldValues = formApiRef?.current?.getFormState();
    const { values = {}, errors = {}, valid } = fieldValues;
    const {
      [FIELD_DELIVERY_POSTALCODE]: PostalCode = null,
      [FIELD_DELIVERY_CITY]: City = null,
      [FIELD_DELIVERY_STATE_PROVINCE]: StateProvince = null,
    } = values || {};
    const error = Object.keys(errors).length;
    const payload = {
      PostalCode,
      City,
      StateProvince,
    };
    if (!error && PostalCode && (PostalCode !== deliveryPostalCode || !deliveryDistance)) {
      if (isSwap) {
        return getAlternateDesigns(PostalCode);
      }
      updateFormDetails(payload);
      if (!isDeliveryDetailsRequiredOnOrderFlow) {
        // Get geolocation for the postal code
        getGeocodingData(PostalCode);
      }
      Analytics.fireTagEvent({
        event: Analytics.event,
        'cfg-type': isConfigFlow ? modelCode : `inventory-${modelCode}`,
        interaction: 'delivery-location-user-change',
      });
    }

    if (isDeliveryFormValid !== valid) {
      setValidFlag(valid);
      if (typeof onValidationChange === 'function') {
        onValidationChange(valid);
      }
    }
  };
  const debouncedUpdateDeliveryInfo = _debounce(updateDeliveryInfo, 400);

  const enterHandler = e => {
    if (e.key === 'Enter' || e.key === 13) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (formApiRef.current && !deliveryPostalCode) {
      formApiRef.current?.reset();
    }
  }, [deliveryPostalCode]);

  return (
    <>
      <FormLabel className="tds-o-padding_bottom-1x">
        {labelCopy ? i18n('DeliveryLocation.delivery_zipcode') : ''}
      </FormLabel>
      <div className="tds-flex tds-flex-gutters">
        <div className="tds-flex-item">
          <Input
            name={FIELD_DELIVERY_POSTALCODE}
            id={FIELD_DELIVERY_POSTALCODE}
            maxLength={assignments[FIELD_DELIVERY_POSTALCODE].maxLength}
            validate={zipCodeValidator}
            onChange={!showSubmitButton ? debouncedUpdateDeliveryInfo : null}
            required={!!onValidationChange}
            errorMessage={() => errorMessage(FIELD_DELIVERY_POSTALCODE)}
            showErrorIfDirty
            validateOn="change"
            placeholder={placeholder}
            data-id="delivery-postal-code-textbox"
            onKeyUp={enterHandler}
            onKeyPress={enterHandler}
            onKeyDown={enterHandler}
          />
        </div>
        <If condition={showSubmitButton}>
          <div
            className={classnames('tds-flex-item', { 'tds--vertical_padding-top': isLayoutMobile })}
          >
            <Button type="submit" className="tds-btn">
              {i18n('common.submit')}
            </Button>
          </div>
        </If>
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const market = _get(state, 'OMS.oms_params.market', _get(window, 'tesla.omsParams.market'));
  const language = _get(state, 'App.language');
  const states = getStates(market, language);
  let dropDownStates = [];
  if (states && states.length > 0) {
    dropDownStates = states.map(opt => ({
      value: opt.code,
      label: opt.label,
    }));
  }
  const ownPostalCode = ownProps?.postalCodeSource || '';
  const onValidationChange = ownProps?.onValidationChange || null;
  const isConfigFlow = ownProps?.isConfigFlow || false;
  const { section } = state?.Navigation || {};
  const { isInventorySwapEnabled = false, isLayoutMobile, isPreferredAddressEnabled = false } = state?.App || {};

  return {
    isDeliveryFormValid: _get(state, 'ReviewDetails.isDeliveryDetailsValid'),
    market,
    isRequired: _get(state, 'Payment.registration.deliveryPostalCodeRequired', false),
    deliveryPostalCode:
      _get(state, 'ReviewDetails.DeliveryDetails.PostalCode', ownPostalCode) || ownPostalCode,
    deliveryCity: _get(state, 'ReviewDetails.DeliveryDetails.City', ''),
    deliveryStateProvince: _get(state, 'ReviewDetails.DeliveryDetails.StateProvince', ''),
    deliveryDistance: _get(state, 'ReviewDetails.DeliveryDetails.Distance', ''),
    formErrors: _get(state, 'ReviewDetails.formErrors'),
    transportationFee: _get(state, 'ReviewDetails.DeliveryDetails.TransportationFee', 0),
    isTransportFeeEnabled: _get(state, 'ReviewDetails.isTransportFeeEnabled', false),
    regionCode: _get(state, 'SummaryPanel.region_code', ''),
    isDeliveryDetailsRequiredOnOrderFlow: _get(
      state,
      'ReviewDetails.isDeliveryDetailsRequiredOnOrderFlow',
      false
    ),
    language,
    modelCode: getModel(state),
    isPickupOnlyEnabled: _get(state, 'App.isPickupOnlyEnabled', false),
    dropDownStates,
    postalFromURL: _get(state, 'App.query_params.postal', ''),
    isModalOpen: _get(state, 'Modal.open', false),
    onValidationChange,
    isConfigFlow,
    isSwap: isInventorySwapEnabled && section === NAVIGATION_VIEW_EARLYDELIVERY,
    isLayoutMobile,
    isPreferredAddressEnabled,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setValidFlag: flag => {
      dispatch(setDeliveryDetailsValidFlag(flag));
    },
    updateFormDetails: payload => {
      dispatch(updateDeliveryDetails(payload));
    },
    setRegion: regionCode => {
      dispatch(setSummaryPanelRegion(regionCode));
    },
    getAlternateDesigns: zipCode => {
      dispatch(fetchAlternateVehicleDesigns(zipCode));
    },
  };
};

PostalCode.propTypes = {
  formApiRef: shape({}).isRequired,
  updateFormDetails: func.isRequired,
  setRegion: func.isRequired,
  market: string.isRequired,
  setValidFlag: func.isRequired,
  modelCode: string,
  regionCode: string,
  deliveryDistance: string,
  labelCopy: oneOfType([bool, string]),
  onValidationChange: func,
  isConfigFlow: bool,
  placeholder: string,
  deliveryPostalCode: string,
  isDeliveryDetailsRequiredOnOrderFlow: bool,
  isDeliveryFormValid: bool,
  getAlternateDesigns: func,
  isSwap: bool,
  isPreferredAddressEnabled: bool,
};

PostalCode.defaultProps = {
  modelCode: '',
  regionCode: '',
  deliveryDistance: '',
  labelCopy: false,
  onValidationChange: null,
  isConfigFlow: false,
  placeholder: '',
  deliveryPostalCode: '',
  isDeliveryDetailsRequiredOnOrderFlow: false,
  isDeliveryFormValid: false,
  getAlternateDesigns: () => {},
  isSwap: false,
  isPreferredAddressEnabled: false,
};

const ConnectedPostalCode = connect(mapStateToProps, mapDispatchToProps)(PostalCode);

const DeliveryDetailsForm = ({
  isRequired,
  isPostalCodeEnable,
  classNames,
  isInline,
  transportationFee,
  deliveryPostalCode,
  deliveryCity,
  deliveryStateProvince,
  isDeliveryFormValid,
  isTransportFeeEnabled,
  isDeliveryDetailsRequiredOnOrderFlow,
  isPickupOnlyEnabled,
  dropDownStates,
  labelCopy,
  placeholder,
  onValidationChange,
  showSubmitButton,
  onSubmit,
  isPreferredAddressEnabled,
}) => {
  if (!isRequired && !isPostalCodeEnable) {
    return null;
  }
  const formApiRef = useRef();

  const initialValues = {
    [FIELD_DELIVERY_POSTALCODE]: deliveryPostalCode,
    [FIELD_DELIVERY_STATE_PROVINCE]: deliveryStateProvince,
    [FIELD_DELIVERY_CITY]: deliveryCity,
  };

  return (
    <div
      className={classnames('delivery-location--container', {
        [classNames]: classNames,
      })}
    >
      <Form
        formApiRef={formApiRef}
        keepState
        showErrorIfDirty
        initialValues={initialValues}
        errorMessage={{
          required: _upperFirst(i18n('common.errors__required')),
        }}
        onSubmit={onSubmit}
      >
        <If condition={isInline}>
          <div
            className={classnames('field-container delivery_details', {
              'tds--is_hidden': isPickupOnlyEnabled,
            })}
          >
            <p className="tds-text--h5">{i18n('DeliveryLocation.title')}</p>
            <p className="tds-text--caption">
              {i18n(
                isTransportFeeEnabled
                  ? 'DeliveryLocation.description_transport_fee'
                  : 'Review.delivery_postal_code_disclaimer'
              )}
            </p>
            <div className="tds-flex tds--vertical_padding">
              <Choose>
                <When condition={isDeliveryDetailsRequiredOnOrderFlow}>
                  <Select
                    name={FIELD_DELIVERY_STATE_PROVINCE}
                    id={FIELD_DELIVERY_STATE_PROVINCE}
                    label={i18n('DeliveryLocation.delivery_state_province')}
                    options={dropDownStates}
                  />
                  <Input
                    name={FIELD_DELIVERY_CITY}
                    id={FIELD_DELIVERY_CITY}
                    label={i18n('DeliveryLocation.delivery_city')}
                    maxLength={assignments[FIELD_DELIVERY_CITY].maxLength}
                    required
                    validateOnMountNoTouch
                  />
                </When>
                <Otherwise>
                  <ConnectedPostalCode
                    formApiRef={formApiRef}
                    labelCopy={labelCopy}
                    placeholder={placeholder}
                    {...(onValidationChange ? { onValidationChange: onValidationChange } : {})}
                    showSubmitButton={showSubmitButton}
                  />
                  <If condition={isTransportFeeEnabled && isDeliveryFormValid}>
                    <p className="tds-text--caption">
                      {transportationFee
                        ? i18n('DeliveryLocation.estimate_transport_fee_label', {
                            FEE: formatCurrency(transportationFee),
                            POSTAL_CODE: deliveryPostalCode,
                          })
                        : i18n('DeliveryLocation.estimate_transport_nofee_label', {
                            POSTAL_CODE: deliveryPostalCode,
                          })}
                    </p>
                  </If>
                </Otherwise>
              </Choose>
            </div>
          </div>
        </If>
        <If condition={!isInline}>
          <ConnectedPostalCode
            formApiRef={formApiRef}
            labelCopy={labelCopy}
            placeholder={placeholder}
            {...(onValidationChange ? { onValidationChange: onValidationChange } : {})}
            showSubmitButton={showSubmitButton}
          />
        </If>
      </Form>
    </div>
  );
};

DeliveryDetailsForm.contextTypes = {
  i18n: func,
};

DeliveryDetailsForm.propTypes = {
  isDeliveryFormValid: bool,
  isRequired: bool.isRequired,
  deliveryPostalCode: string,
  transportationFee: number,
  classNames: string,
  isInline: bool,
  isTransportFeeEnabled: bool,
  isDeliveryDetailsRequiredOnOrderFlow: bool,
  deliveryStateProvince: string,
  deliveryCity: string,
  isPickupOnlyEnabled: bool,
  dropDownStates: arrayOf(shape({})),
  isPostalCodeEnable: bool,
  labelCopy: oneOfType([bool, string]),
  placeholder: string,
  onValidationChange: func,
  isPreferredAddressEnabled: bool,
};

DeliveryDetailsForm.defaultProps = {
  isDeliveryFormValid: false,
  deliveryPostalCode: '',
  deliveryStateProvince: '',
  deliveryCity: '',
  transportationFee: 0,
  classNames: '',
  isInline: true,
  isTransportFeeEnabled: false,
  isDeliveryDetailsRequiredOnOrderFlow: false,
  isPickupOnlyEnabled: false,
  dropDownStates: [],
  isPostalCodeEnable: false,
  labelCopy: false,
  placeholder: '',
  onValidationChange: null,
  isPreferredAddressEnabled: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryDetailsForm);
