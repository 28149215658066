import _get from 'lodash/get';
import _reduce from 'lodash/reduce';
import _find from 'lodash/find';
import { getStates } from '@tesla/intl-display-names';
import { map, filter } from 'rxjs/operators';
import {
    SUMMARY_PANEL_DISTANCE_CHANGE,
    SUMMARY_PANEL_REGION_CHANGE,
    SUMMARY_PANEL_TAB_CHANGE,
    SUMMARY_PANEL_FUEL_INCENTIVE_CHANGE,
    SUMMARY_PANEL_INCENTIVE_TOGGLE,
    SUMMARY_PANEL_DOWNPAYMENT_CHANGE,
    SUMMARY_PANEL_INCLUDE_FEES,
    SUMMARY_PANEL_LEASE_TERM_CHANGE,
    SUMMARY_PANEL_FINANCE_TERM_CHANGE,
    SUMMARY_PANEL_FINANCE_APR_CHANGE,
    SUMMARY_PANEL_FINANCE_BALLOON_PAYMENT_CHANGE,
    SUMMARY_PANEL_FINANCE_DISTANCE_CHANGE,
    SUMMARY_PANEL_INCLUDE_SERVICE_FEE_CHANGE,
    SUMMARY_PANEL_RESIDUAL_AMOUNT_CHANGE,
    SUMMARY_PANEL_FUEL_PANEL_OPEN,
    SUMMARY_PANEL_FUEL_PANEL_CLOSE,
    SUMMARY_PANEL_CONFIGURATION_CHANGED,
    SUMMARY_PANEL_EXPAND,
    SUMMARY_PANEL_COLLAPSE,
    SUMMARY_PANEL_LEASE_APR_CHANGE,
    COMPLETE_CONFIGURATION_CHANGED,
    LOCATION_CHANGED,
    MODAL_OPEN,
    TOGGLE_LOAN_TYPE,
    SUMMARY_PANEL_DELIVERY_INCENTIVE_CHANGE,
    SUMMARY_PANEL_INTEREST_RATE_TYPE_CHANGE,
    SUMMARY_PANEL_USER_LEASE_AMOUNT_CHANGE,
    TOGGLE_PROBABLE_SAVINGS,
    SWITCH_TO_DEFAULT_TRIM,
} from 'dictionary'

import { includesCodes } from 'utils'
import { showDefaultTrimForFinanceProduct } from 'selectors';
import { setOption } from 'actions';
import { getDefaultFinancePlanId } from 'selectors';

export const updateSummaryPanelDeltas = (action$, state$) =>
    action$.pipe(
        filter(action => [
                COMPLETE_CONFIGURATION_CHANGED
            ].includes(action.type)
            &&state$.value.SummaryPanel.optionOverrides
        ),
        map(action => {
            const { options } = action
            const state =state$.value
            let props = _reduce(state.optionOverrides, function(result, delta) {

                if(includesCodes(delta.options, options)){
                    result[delta.propName] = delta.deltaValue
                }
                else{
                    result[delta.propName] = delta.defaultValue
                }

                return result

            }, {})
            return {
                type: SUMMARY_PANEL_CONFIGURATION_CHANGED,
                props
            }
        })
    )

/**
 * When user chooses a distance they drive (mileage or km) -- present when calculating leasing
 * @param {Number} distance
 */
export function setSummaryPanelLeaseDistance(leaseDistance) {
    return {
        type: SUMMARY_PANEL_DISTANCE_CHANGE,
        leaseDistance
    }
}

/**
 * When user chooses a distance they drive (mileage or km) -- present when calculating loan
 * @param {Number} distance
 */
export function setSummaryPanelLoanDistance(loanDistance) {
    return {
        type: SUMMARY_PANEL_FINANCE_DISTANCE_CHANGE,
        loanDistance
    }
}

/**
 * When user chooses a distance they drive (mileage or km) -- present when calculating leasing
 * @param {Number} distance
 */
export function setSummaryPanelLeaseTerm(leaseTerm, financePlanId = null) {
    return {
        type: SUMMARY_PANEL_LEASE_TERM_CHANGE,
        leaseTerm,
        ...(financePlanId && { userInput: {financePlanId} })
    }
}

export function setSummaryPanelUserLeaseAmount(userLeaseAmount) {
    return {
        type: SUMMARY_PANEL_USER_LEASE_AMOUNT_CHANGE,
        userLeaseAmount
    }
}

export function setSummaryPanelFinanceTerm(financeTerm, financePlanId = null) {
    return {
        type: SUMMARY_PANEL_FINANCE_TERM_CHANGE,
        financeTerm,
        ...(financePlanId && { userInput: {financePlanId} })
    }
}

export function setSummaryPanelIncludeServiceFee(includeServiceFee) {
    return {
        type: SUMMARY_PANEL_INCLUDE_SERVICE_FEE_CHANGE,
        includeServiceFee
    }
}

export function setSummaryPanelInterestRateType(interestRateType) {
    return {
        type: SUMMARY_PANEL_INTEREST_RATE_TYPE_CHANGE,
        interestRateType
    }
}

export function setSummaryPanelResidualAmount(residualAmount) {
    return {
        type: SUMMARY_PANEL_RESIDUAL_AMOUNT_CHANGE,
        residualAmount
    }
}


/**
 * Called from Cash panel to set includeFees flag
 *
 *   in Cash.NetPrice:
 *       savingsPrice -- vehiclePrice + incentivesTotal (used when includeFees === false)
 *       netPrice  -- grossPrice + incentivesTotal (used when includeFees === true)
 *   in Cash.Price
 *       vehiclePrice (used when includeFees === false)
 *       grossPrice (used when includeFees === true)
 *
 * @param {Object} params [description]
 */
export function setSummaryPanelIncludeFees(){

    return {
       type: SUMMARY_PANEL_INCLUDE_FEES
    }
}

/**
 * Called from fuel incentives panel to update fuel calculation
 * @param {Object} params [description]
 */
export function setSummaryPanelFuelParams(params){

    return {
       type: SUMMARY_PANEL_FUEL_INCENTIVE_CHANGE,
       params: params
    }
}

export function setSummaryPanelRegion (regionCode){
    return {
        type: SUMMARY_PANEL_REGION_CHANGE,
        regionCode
    }
}

export function setSpecialRegionalIncentives(params){

    return {
       type: SUMMARY_PANEL_DELIVERY_INCENTIVE_CHANGE,
       params: params
    }
}


export const updateSummaryPanelRegion = (action$, state$)=>
    action$.pipe(
        filter(action => {
            const state = state$.value;
            const { ReviewDetails: { DeliveryDetails } = {}, SummaryPanel: { region_code } = {} } = state;
            const geoIpRegion = _get(action, 'location.region.regionCode', null);
            const updateRegion = region_code !== geoIpRegion;
            const { PostalCode } = DeliveryDetails || {};
            return (!PostalCode || updateRegion) && [
                LOCATION_CHANGED
            ].includes(action.type)
        }),
        map(action => {
            const state =state$.value;
            const { isInventory } = state?.ReviewDetails?.product || {};
            const { region } = state?.App?.query_params || {};
            const geoIpRegion = _get(action, 'location.region.regionCode', null);
            const regionFromParams = isInventory ? region : null;
            const regionCode = regionFromParams || geoIpRegion;
            const statesForCountry = getStates(state?.App.countryCode);
            const foundRegion = _find(statesForCountry, { 'code': regionCode });
            return typeof foundRegion !== 'undefined' ? setSummaryPanelRegion(regionCode) : ({ type: 'NOOP' });
        })
    )



/**
 * Sets the downPayment percent from SummaryPanel DownPayment component
 * @param {Number} downPaymentPercent [description]
 */
export function setSummaryPanelDownPayment(downPayment, grossPrice = null){
    return {
       type: SUMMARY_PANEL_DOWNPAYMENT_CHANGE,
       downPayment,
       grossPrice
    }
}


/**
 * Sets the lease interest rate
 * @param {Number} leaseApr
 */
export function setSummaryPanelLeaseAPR(leaseApr){
    return {
       type: SUMMARY_PANEL_LEASE_APR_CHANGE,
       leaseApr
    }
}

/**
 * Sets the lease interest rate
 * @param {Number} leaseApr
 */
export function setSummaryPanelFinanceAPR(loanApr){
    return {
       type: SUMMARY_PANEL_FINANCE_APR_CHANGE,
       loanApr
    }
}

/**
 * Sets the loan balloon payment
 * @param {Number} balloonPayment
 */
export function setSummaryPanelBalloonPayment(balloonPayment){
    return {
       type: SUMMARY_PANEL_FINANCE_BALLOON_PAYMENT_CHANGE,
       balloonPayment
    }
}

/**
 * Toggles an incentive on/off (used in IncentiveToggle component)
 * @param {Number} downPaymentPercent [description]
 */
export function toggleSummaryPanelIncentive(incentiveParams){
    return {
       type: SUMMARY_PANEL_INCENTIVE_TOGGLE,
       incentiveParams
    }
}

export function openFuelPanel(){
    return {
        type: SUMMARY_PANEL_FUEL_PANEL_OPEN
    }
}

export function closeFuelPanel(){
    return {
        type: SUMMARY_PANEL_FUEL_PANEL_CLOSE
    }
}


export function selectTab(tabID, source){
    return (dispatch, getState)=> {
        const state = getState() || {};
        const isModalOpen = state?.Modal?.open;
        const financePlanId = getDefaultFinancePlanId({state, selectedFinanceType: tabID?.split('.')?.[0]});
        dispatch({
            type: SUMMARY_PANEL_TAB_CHANGE,
            tabID,
            source,
            isModalOpen,
            ...(financePlanId && { userInput: {financePlanId} }),
        });
    }
}

export function expandSummaryPanel(){
    return {
        type: SUMMARY_PANEL_EXPAND
    }
}

export function collapseSummaryPanel(){
    return {
        type: SUMMARY_PANEL_COLLAPSE
    }
}


export function learnMore(){
    return (dispatch, getState)=>{
        const state = getState()
        const disclaimerModalConfig = {
            message: state.UI.cashPanelDisclaimer,
            injectedComponent: 'incentives',
            button: '',
            onModalClose: function(){
            }
        }

        dispatch({
            type: MODAL_OPEN,
            component_target: 'Disclaimer',
            props: disclaimerModalConfig
        })
    }
}

export const toggleLoanType = loanType => ({
  type: TOGGLE_LOAN_TYPE,
  loanType,
});

export const toggleProbableSavings = toggle => ({
  type: TOGGLE_PROBABLE_SAVINGS,
  toggle,
});

export const setDefaultTrim = (action$, state$) =>
  action$.pipe(
    filter(action => [SUMMARY_PANEL_TAB_CHANGE].includes(action.type)),
    map(({ tabID }) => dispatch => {
      const state = state$.value;
      const flag = showDefaultTrimForFinanceProduct(state);
      if (flag) {
        const { default_options = [], currentSelected = [], current = [] } =
          state?.CustomGroups?.TRIM || {};
        const currentOption = currentSelected?.[0]?.code || '';
        const option = default_options?.includes(currentOption)
          ? current?.find(x => !default_options?.includes(x?.code))?.set
          : default_options;
        dispatch({
          type: SWITCH_TO_DEFAULT_TRIM,
          payload: {
            tabID,
          },
        });
        dispatch(setOption({ set: option }));
      }
    })
  );
