const { bannerContent = [] } = window?.tesla || {};

const InitialState = {
  object: {
    bannerContent,
    bannerContentParsed: [],
  },
};

export default InitialState;
