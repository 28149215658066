import _reduce from 'lodash/reduce';

/**
 *  Check if all passed codes are found in the user configuration
 *
 * @param {Object | Array} codes - array of codes to check against
 * @param {Object | Array} configuration_codes - array of user configuration codes
 * @return {Boolean} TRUE | FALSE
 */
export function includesCodes(codes, configuration_codes) {
	if (!Array.isArray(codes) || !codes.length) {
		return false
	}
    const codesInside = codes.filter((code) => configuration_codes.indexOf(code) !== -1)
    return codesInside.length === codes.length
}

/**
 *  Check if at least one passed code is found in the user configuration
 *
 * @param {Object | Array} codes - array of codes to check against
 * @param {Object | Array} configuration_codes - array of user configuration codes
 * @return {Boolean} TRUE | FALSE
 */
export function includesAnyCode(codes, configuration_codes) {
	if (!Array.isArray(codes) || !codes.length) {
		return false
	}
    const codesInside = codes.filter((code) => configuration_codes.indexOf(code) !== -1)
    return codesInside.length > 0
}

export function inSubmarket(markets, region) {
    //{regionCode:["NV"], countryCode:["CA"]}
    if (!region) {
        // no region data available yet, return false
        return false
    }
    return _reduce(markets, (result, value, geoType) => {
        const geoValue = region[geoType]
        if (typeof value.filter === 'function') {
            const insideMarket = value.filter((market) => geoValue === market)
            return insideMarket.length > 0
        }
        else {
            return false
        }
    }, false)
}
