import _get from 'lodash/get';
import _set from 'lodash/set';
import {
  MODAL_OPEN,
  SHOW_MODAL_FORM,
  FINANCE_CONTAINER,
  SELECT_FINANCE_PANEL_TAB,
  TOGGLE_FINANCE_PANEL_SIDEBAR,
  INCLUDE_TESLA_INSURANCE,
  SET_FINANCE_MODAL_DRIVER_TYPE,
  FINANCE_MODAL_TAB_OPTIONS,
  SET_EV_CREDIT,
  TOGGLE_POTENTIAL_SAVINGS,
  SET_COE_AMOUNT,
  TOGGLE_TAXES_AND_FEES,
  SET_TAXES_AND_FEES,
  TOGGLE_TAXES_FEES_EXPERIENCE,
  UPDATE_PREQUALIFICATION_DATA,
  RESET_PREQUALIFICATION_DATA,
  PRICE_CHANGED,
} from 'dictionary';
import { getActiveFinanceTypes, getEnabledTabs, getDynamicBannerContent } from 'selectors';

export default function Reducer(state = {}, action, { app_state }) {
  switch (action.type) {
    case MODAL_OPEN: {
      if (action.component_target === FINANCE_CONTAINER) {
        const selectedForm = _get(action, 'props.selectedForm', null);
        const analyticsPrefix = _get(action, 'props.analyticsPrefix', null);
        const newState = {
          ...state,
          analyticsPrefix,
          menuItems: getEnabledTabs(state, app_state),
        };

        if (selectedForm) {
          newState.selectedForm = selectedForm;
        }

        return newState;
      }

      break;
    }

    case SHOW_MODAL_FORM: {
      if (action.selectedForm === FINANCE_MODAL_TAB_OPTIONS) {
        return {
          ...state,
          menuItems: getEnabledTabs(state, app_state),
          activeFinanceTypes: getActiveFinanceTypes(app_state),
          selectedForm: action.selectedForm,
        };
      }
      return {
        ...state,
        selectedForm: action.selectedForm,
      };
    }
    case SELECT_FINANCE_PANEL_TAB:
      return {
        ...state,
        selectedForm: action.selectedForm,
        sidebarOpen: false,
      };

    case TOGGLE_FINANCE_PANEL_SIDEBAR:
      return {
        ...state,
        sidebarOpen: !state.sidebarOpen,
      };

    case INCLUDE_TESLA_INSURANCE:
      return {
        ...state,
        includeTeslaInsurance: action.flag,
      };

    case SET_FINANCE_MODAL_DRIVER_TYPE:
      return {
        ...state,
        driverType: action.driverType,
      };

    case SET_EV_CREDIT:
      return {
        ...state,
        evCredit: action.value,
      };

    case SET_COE_AMOUNT:
      return {
        ...state,
        coeBidAmount: action.value,
      };

    case TOGGLE_TAXES_AND_FEES:
      return {
        ...state,
        includeTaxesAndFees: action.value,
      };
    case TOGGLE_TAXES_FEES_EXPERIENCE:
      let menuItems = [...(state?.menuItems || [])];
      const actionFlag = action?.flag || false;
      const financeOptsIndex = menuItems.findIndex(obj => obj.key === FINANCE_MODAL_TAB_OPTIONS);
      if (financeOptsIndex !== -1) {
        menuItems.splice(financeOptsIndex, 1, {
          ...menuItems[financeOptsIndex],
          showDeliveryZipCode: actionFlag,
        });
      }
      return {
        ...state,
        showTaxesAndFees: actionFlag,
        menuItems,
      };

    case TOGGLE_POTENTIAL_SAVINGS:
      return {
        ...state,
        hideAfterSavingsPrice: action.value,
        showPotentialSavings: action.value,
        showSavingsAmount: action.value,
        showGasSavingsCash: action.value,
        showSavingAmtWithoutMinusInFooter: action.value,
      };

    case SET_TAXES_AND_FEES:
      return {
        ...state,
        taxesAndFees: action.value || {},
      };

    case UPDATE_PREQUALIFICATION_DATA:
      return {
        ...state,
        preQualify: {
          ...state.preQualify,
          userDetails: {
            ...state.preQualify.userDetails,
            ...(action?.payload || {}),
          },
          isDecisionPending: action?.isPending || false,
          error: action?.error || null,
        },
      };
    case RESET_PREQUALIFICATION_DATA:
      return {
        ...state,
        preQualify: {
          userDetails: {},
          isDecisionPending: false,
          error: null,
        },
      };
    case PRICE_CHANGED:
      return {
        ...state,
        bannerContentParsed: getDynamicBannerContent({ ...app_state, FinancingOptions: state }),
      };
    default:
      return state;
  }

  return state;
}
