import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bool, func } from 'prop-types';
import { Form, Checkbox } from '@tesla/informed-tds';
import { i18n } from 'utils';
import {
  isAccessoriesBiddingFlowEnabled,
  isAccessoriesBiddingEligible,
  getAccessoriesInstallationEligibility,
} from 'selectors';
import { toggleAccessoryBiddableFlowOptIn, getItemEligibility } from 'actions';

const AccessoriesInstallation = ({
  isOptInForBiddingFlow,
  toggleBiddableFlowOptIn,
  getInstallationEligibility,
  shouldQueryEligibility,
  isBiddingEligible,
}) => {
  useEffect(() => {
    if (shouldQueryEligibility) {
      getInstallationEligibility();
    }
    if (isOptInForBiddingFlow && !shouldQueryEligibility) {
      toggleBiddableFlowOptIn();
    }
  }, [shouldQueryEligibility]);

  if (!shouldQueryEligibility || !isBiddingEligible) {
    return null;
  }

  return (
    <Form>
      <Checkbox
        key="accessories-installation-opt-in"
        name="accessoriesBiddingFlowOptIn"
        label={i18n('Accessories.install_quotes')}
        value={isOptInForBiddingFlow}
        onChange={toggleBiddableFlowOptIn}
      />
    </Form>
  );
};

AccessoriesInstallation.propTypes = {
  shouldQueryEligibility: bool,
  isOptInForBiddingFlow: bool,
  isBiddingEligible: bool,
  toggleBiddableFlowOptIn: func.isRequired,
  getInstallationEligibility: func.isRequired,
};

AccessoriesInstallation.defaultProps = {
  shouldQueryEligibility: false,
  isOptInForBiddingFlow: false,
  isBiddingEligible: false,
};

function mapStateToProps(state) {
  const { isOptInForBiddingFlow } = state.Accessories || {};
  const isBiddingFlowEnabled = isAccessoriesBiddingFlowEnabled(state);
  const isBiddingEligible = isAccessoriesBiddingEligible(state);
  return {
    isOptInForBiddingFlow,
    isBiddingFlowEnabled,
    isBiddingEligible,
    shouldQueryEligibility: getAccessoriesInstallationEligibility(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleBiddableFlowOptIn: () => {
      dispatch(toggleAccessoryBiddableFlowOptIn());
    },
    getInstallationEligibility: () => {
      dispatch(getItemEligibility());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessoriesInstallation);
