import React, { Component } from 'react';
import { connect } from 'react-redux';
import { shape, arrayOf, string, func, bool } from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import VideoContentLoader from '../VideoContentLoader';
import AssetLoader from '../AssetLoader';

class VideoAssetLoader extends Component {
  isMounted = false;

  constructor(props) {
    super(props);
    this.listeners = {};
    const { asset } = this.props;
    const video_content = _get(asset, 'video_content', []);
    this.onTimeUpdate = this.onTimeUpdate.bind(this);
    this.onClickUpdate = this.onClickUpdate.bind(this);
    this.state = {
      maxVideo: video_content.length || 0,
      currentVideo: 0,
      videoTextGroup: _get(video_content, '[0].content_path', ''),
      videoURL: _get(video_content, '[0].url', ''),
    };
  }

  componentDidMount() {
    this.isMounted = true;
    if (this.isMounted) {
      this.listeners = this.handleListeners();
    }
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  onClickUpdate(index) {
    const { maxVideo } = this.state;
    const { asset } = this.props;
    const { video_content } = asset;
    const pBar = document.getElementsByClassName('media-progress-bar');
    [...pBar].map((x, i) => (i < index ? { ...x, value: 100 } : { ...x, value: 0 }));
    if (index < maxVideo) {
      this.setState({
        currentVideo: index,
        videoTextGroup: video_content[index].content_path,
        videoURL: video_content[index].url,
      });
    }
  }

  onTimeUpdate() {
    const { asset } = this.props;
    const { video_content } = asset;
    const { maxVideo, currentVideo } = this.state;
    const media = document.querySelector('[id^="video-id"]');
    const pBar = document.getElementsByClassName('media-progress-bar');
    if (media && pBar) {
      const percent = Math.floor((100 / media.duration) * media.currentTime);
      if (currentVideo < maxVideo) {
        [...pBar].map((x, i) => (i < currentVideo ? { ...x, value: 100 } : { ...x, value: 0 }));
        if (pBar[currentVideo]) {
          pBar[currentVideo].value = !Number.isNaN(percent) ? percent : 0;
        }
        if (media.currentTime >= media.duration * 0.5) {
          media.className = 'active';
        } else {
          media.className = '';
        }
        let nextVideo = currentVideo + 1;
        if (nextVideo === maxVideo) {
          nextVideo = 0;
        }
        if (media.currentTime === media.duration) {
          this.setState({
            currentVideo: nextVideo,
            videoTextGroup: video_content[nextVideo].content_path,
            videoURL: video_content[nextVideo].url,
          });
          if (!nextVideo) {
            [...pBar].map(x => ({ ...x, value: 0 }));
          }
        }
      }
    }
  }

  handleListeners = () => {
    const modalContainer = document.getElementsByClassName('modal-container')[0];
    const media = document.querySelector('[id^="video-id"]');
    const pBar_Container = document.getElementsByClassName('progress_bar__story');
    const { closeModal } = this.props;
    let xDown = null;
    let yDown = null;
    let xUp;
    let yUp;
    let xDiff;
    let yDiff;

    const repositionTarget = ({ type, target }) => event => {
      switch (type) {
        case 'start':
          xDown = _get(event, target.x);
          yDown = _get(event, target.y);
          break;
        case 'end':
          xUp = _get(event, target.x);
          yUp = _get(event, target.y);
          xDiff = xDown - xUp;
          yDiff = yDown - yUp;

          if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0) {
              this.clickedNext(event);
            } else {
              this.clickedPrev(event);
            }
          } else if (yDiff) {
            closeModal();
          }
          xDown = null;
          yDown = null;
          break;
        default:
      }
    };
    if (!_isEmpty(media)) {
      media.addEventListener('timeupdate', this.onTimeUpdate, false);
    }
    if (!_isEmpty(pBar_Container)) {
      [...pBar_Container].forEach((x, i) => {
        x.addEventListener('click', () => this.onClickUpdate(i), false);
        x.addEventListener('keydown', () => this.onClickUpdate(i), false);
      });
    }
    if (!_isEmpty(modalContainer)) {
      modalContainer.addEventListener(
        'touchstart',
        repositionTarget({
          type: 'start',
          target: { x: 'changedTouches[0].clientX', y: 'changedTouches[0].clientY' },
        }),
        false
      );
      modalContainer.addEventListener(
        'touchend',
        repositionTarget({
          type: 'end',
          target: { x: 'changedTouches[0].clientX', y: 'changedTouches[0].clientY' },
        }),
        false
      );
    }
  };

  clickedNext(event) {
    event.stopPropagation();
    const { currentVideo, maxVideo } = this.state;
    let next = currentVideo + 1;
    if (next === maxVideo) {
      next = 0;
    }
    this.onClickUpdate(next);
  }

  clickedPrev(event) {
    event.stopPropagation();
    const { currentVideo, maxVideo } = this.state;
    let prev = currentVideo - 1;
    if (prev < 0) {
      prev = maxVideo - 1;
    }
    this.onClickUpdate(prev);
  }

  render() {
    const { group, model, asset, baseURL, baseURLMobile, isCarouselOpen, isLayoutMobile } = this.props;
    const { videoTextGroup, currentVideo, videoURL } = this.state;
    const relative = _get(asset, `video_content.${currentVideo}.relative`, false);
    const options_path = _get(asset, `video_content.${currentVideo}.options_path`, '');
    const assetURL = relative ? videoURL : `${isLayoutMobile ? baseURLMobile : baseURL}${videoURL}`;

    return (
      <>
        {group && videoURL && (
          <AssetLoader
            className={`group--main-video-content group--main-content--asset group--main-content--asset__${group.code ||
              ''} ${model}`}
            asset={group.asset}
            key={`${group.code}__asset`}
            title={group.code}
            videoUrl={assetURL}
            playVideo={!isCarouselOpen}
          />
        )}
        <div className="group--main-content--details">
          <If condition={videoTextGroup}>
            <VideoContentLoader
              video_key={`ap_video_${currentVideo}`}
              data={videoTextGroup}
              options={options_path}
              group={group}
            />
          </If>
        </div>
      </>
    );
  }
}

VideoAssetLoader.propTypes = {
  asset: shape({
    video_content: arrayOf(
      shape({
        content_path: string,
        url: string,
      })
    ),
  }),
  model: string,
  group: shape({
    code: string,
    asset: shape({}),
  }),
  closeModal: func,
  baseURL: string,
  baseURLMobile: string,
  isCarouselOpen: bool,
  isLayoutMobile: bool,
};

VideoAssetLoader.defaultProps = {
  asset: {},
  model: '',
  group: {},
  closeModal: () => {},
  baseURL: '',
  baseURLMobile: '',
  isCarouselOpen: false,
  isLayoutMobile: false,
};

function mapStateToProps(state) {
  return {
    model: _get(state, 'OMS.oms_params.model', ''),
    baseURL: _get(state, 'Assets.videoBaseURL', ''),
    baseURLMobile: _get(state, 'Assets.videoBaseURLMobile', ''),
    isCarouselOpen: _get(state, 'FeatureListModal.isCarouselOpen', false),
    isLayoutMobile: state.App.isLayoutMobile || state.App.isLayoutTablet,
  };
}

export default connect(mapStateToProps)(VideoAssetLoader);
