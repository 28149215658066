import React from 'react';
import { i18n } from 'utils';
import { string, bool } from 'prop-types';
import { FIN_MODAL_CUSTOMIZE, FINANCE_MODAL_TAB_OPTIONS, FINANCE_CAROUSEL } from 'dictionary';
import { htmlToReact } from 'utils';
import ModalTrigger from '../../../components/ModalTrigger';
import { WEB_CONFIGURATOR_PAYMENT_FINANCING_CALCULATOR } from '../../../common/gioStatistics';

const CustomizePayment = ({ showAvailableInState, financeProductId, showElectronicPayment }) => {
  const electronicPaymentDisclaimer = i18n('FinancingOptions.electronicPayment');
  return (
    <>
      <If condition={showAvailableInState}>
        <p className="tds-text--caption">
          {i18n('FinancingOptions.availableInStates', null, null, {
            specificOverride: financeProductId,
          })}
        </p>
      </If>
      <If condition={showElectronicPayment && electronicPaymentDisclaimer}>
        <p className="tds-text--caption">{htmlToReact(electronicPaymentDisclaimer)}</p>
      </If>
      <ModalTrigger
        type={FINANCE_CAROUSEL}
        selectedView={FINANCE_MODAL_TAB_OPTIONS}
        options={{
          props: {
            componentName: 'FinanceContainer',
            props: {
              analyticsPrefix: FIN_MODAL_CUSTOMIZE,
              classes: 'tds-link',
            },
          },
        }}
        analyticsInteraction={FIN_MODAL_CUSTOMIZE}
        data-gio-eventname={WEB_CONFIGURATOR_PAYMENT_FINANCING_CALCULATOR}
      >
        <p className="tds-text--caption">{i18n('FinancingOptions.customizePayment')}</p>
      </ModalTrigger>

    </>
  );
};

CustomizePayment.propTypes = {
  showAvailableInState: bool,
  showElectronicPayment: bool,
  financeProductId: string,
};

CustomizePayment.defaultProps = {
  showAvailableInState: false,
  showElectronicPayment: false,
  financeProductId: '',
};
export default CustomizePayment;
