export default ({ object, overrides }) => ({
  object: {
        utility_specs:[{
            type: 'selected_by',
            dependencies: ['Location', 'Configuration'],
            actions: ['COMPLETE_CONFIGURATION_CHANGED'],
            updates: ['selected', 'formattedSelected']
        },
        {
            type: 'group_selected_by',
            dependencies: ['Location', 'Configuration'],
            actions: ['COMPLETE_CONFIGURATION_CHANGED', 'LOCATION_CHANGED'],
            updates: ['selected']
        },
        {
            type: 'lexicon_price',
            dependencies: ['Pricing'],
            actions: ['COMPLETE_CONFIGURATION_CHANGED', 'PRICE_CHANGED'],
            updates: ['price', 'formattedPrice']
        },
        {
            type: 'custom_car_price',
            dependencies: ['Pricing'],
            actions: ['OMS_RECEIVED_DEPENDENCIES',
                        'SUMMARY_PANEL_TAB_CHANGE',
                        'SUMMARY_PANEL_FINANCE_TERM_CHANGE',
                        'SUMMARY_PANEL_FINANCE_APR_CHANGE',
                        'SUMMARY_PANEL_FINANCE_BALLOON_PAYMENT_CHANGE',
                        'SUMMARY_PANEL_FINANCE_DISTANCE_CHANGE',
                        'SUMMARY_PANEL_DOWNPAYMENT_CHANGE',
                        'FINANCE_PLAN_CHANGE',
                        'SUMMARY_PANEL_DISTANCE_CHANGE',
                        'SUMMARY_PANEL_LEASE_TERM_CHANGE',
                        'SUMMARY_PANEL_REGION_CHANGE',
                        'SUMMARY_PANEL_FUEL_INCENTIVE_CHANGE',
                        'FINANCIAL_FORM_STATE_CHANGE',
                        'PRICE_ADD_DISCOUNT',
                        'SUMMARY_PANEL_USER_LEASE_AMOUNT_CHANGE',
                        'SUMMARY_PANEL_INTEREST_RATE_TYPE_CHANGE'],
            updates: ['price']
        },
        {
            type: 'lexicon_groups',
            dependencies: ['Lexicon'],
            actions: ['OMS_RECEIVED_DEPENDENCIES'],
            updates: ['root']
        },{
            type: 'lexicon_reference',
            dependencies: ['Lexicon'],
            actions: ['OMS_RECEIVED_DEPENDENCIES'],
            updates: ['group_root']
        },{
            type: 'lexicon_group_source',
            dependencies: ['Lexicon'],
            actions: ['OMS_RECEIVED_DEPENDENCIES'],
            updates: ['group_root']
        },{
            type: 'lexicon_options',
            dependencies: ['Lexicon'],
            actions: ['OMS_RECEIVED_DEPENDENCIES'],
            updates: ['root']
        },{
            type: 'extra_content',
            dependencies: ['Lexicon'],
            actions: ['COMPLETE_CONFIGURATION_CHANGED', 'SCREEN_LAYOUT_CHANGE'],
            updates: ['actions', 'asset']
        },{
            type: 'lexicon_specs',
            dependencies: ['Lexicon'],
            actions: ['COMPLETE_CONFIGURATION_CHANGED'],
            updates: ['formattedSpecs']
        },{
            type: 'set_toggle_pair',
            dependencies: ['Configuration'],
            actions: ['COMPLETE_CONFIGURATION_CHANGED'],
            updates: ['set']
        },{
            type: 'available',
            dependencies: ['Location', 'Configuration'],
            actions: ['COMPLETE_CONFIGURATION_CHANGED', 'LOCATION_CHANGED', 'PRICE_CHANGED'],
            updates: ['current', 'current_index']
        }],
        maps: {},
        ...object,
        ...overrides,
    },
});
