import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { formatCurrency } from '@tesla/coin-common-components';
import DeliveryDate from '../GroupComponents/DeliveryDate';
import { i18n, htmlToReact } from 'utils';
import { array, bool, number, shape, string } from 'prop-types';
import { Icon } from '@tesla/design-system-react';
import { iconCalendar, iconPin, iconWarningSmall, iconSchedule } from '@tesla/design-system-icons';

const VehicleDetails = ({
  configList = [],
  deliveryDetails,
  isAlternateDesign,
  IsAtLocation,
  VrlCustomName,
  showPickupLocation,
  showTaxCreditNotEligible,
  zipCode,
  Discount,
  TotalPrice,
  Price,
  hideFinanceProduct,
  showProbableSavings,
  PriceAfterProbableSavings,
  TotalPriceAfterProbableSavings,
  earlyVehicleDesigns,
  finplat,
  displayFinanceToggleOnSwap,
}) => {
  const { status = '', availablility } = deliveryDetails || {};
  let price = (Price ? formatCurrency(Price) : 0); 
  let totalPrice = TotalPrice;
  if (earlyVehicleDesigns?.length && displayFinanceToggleOnSwap) {
    price = finplat?.monthlyPayment || price;
    if (showProbableSavings) {
      price = finplat?.monthlyPaymentAfterSavings || (PriceAfterProbableSavings ? formatCurrency(PriceAfterProbableSavings) : 0);
      totalPrice = TotalPriceAfterProbableSavings;
    }
  }

  return (
    <div className="tds--vertical_padding">
      <If condition={isAlternateDesign}>
        <div className="vehicle-details--label tds-text--body tds-text--contrast-medium tds--vertical_padding-bottom--1x tds-text--regular">
          {i18n('EarlyDelivery.alternate_design_label')}
        </div>
      </If>
      <div className="vehicle-details--value">
        <If condition={price}>
          <div className="tds-text--medium tds-text--contrast-medium">
            {price}
          </div>
        </If>
        <If condition={Discount && totalPrice}>
          <div className="tds-o-text-line_through tds-text--contrast-low tds-text--regular">
            {formatCurrency(totalPrice)}
          </div>
        </If>
      </div>
      <If condition={configList?.length}>
        <div className="tds-o-padding_bottom-4">
          {configList.map(option => {
            const { code, long_name = '', name = '' } = option || {};
            return (
              <h6
                className="tds--no_padding tds-text--medium tds-text--contrast-medium tds-o-padding_bottom-4"
                key={`${code}:${name}`}
              >
                {long_name || name}
              </h6>
            );
          })}
        </div>
      </If>
      <If condition={isAlternateDesign && Discount}>
        <div className="tds-o-flex_no-wrap tds-o-padding_bottom-4">
          <div className="tds-o-padding_right-4">
            <Icon data={iconSchedule} className="tds-icon--small" />
          </div>
          <div className="tds-flex-item">
            <div className="tds-text--body tds-text--contrast-medium tds-text--regular tds-o-padding_bottom-4">
              {i18n('EarlyDelivery.discount')}
            </div>
            <If condition={hideFinanceProduct}>
              <div className="tds-text--body tds-text--contrast-medium tds-text--regular">
                {i18n('EarlyDelivery.lease_unavailable')}
              </div>
            </If>
          </div>
        </div>
      </If>
      <Choose>
        <When condition={Object.keys(deliveryDetails)?.length}>
          <div className="tds-o-flex_no-wrap tds-o-padding_bottom-4 tds-text--contrast-medium tds-text--regular tds-text--body">
            <div className="tds-o-padding_right-4">
              <Icon data={iconPin} className="tds-icon--small" />
            </div>
            <div className="tds-flex-item">
              <div className="tds-o-padding_bottom-4">{htmlToReact(availablility)}</div>
              <div>{status}</div>
            </div>
          </div>
        </When>
        <When condition={IsAtLocation}>
          <div className="tds-o-flex_no-wrap tds-o-padding_bottom-4 tds-text--contrast-medium tds-text--body">
            <div className="tds-o-padding_right-4">
              <Icon data={iconPin} className="tds-icon--small" />
            </div>
            <div className="tds-flex-item">
              <div className="tds-o-padding_bottom-4">{i18n('EarlyDelivery.available_now')}</div>
              <div className="tds-o-padding_bottom-4">{VrlCustomName}</div>
              <If condition={Discount}>
                <div className="tds-text--regular tds-o-margin-top">
                  {i18n('EarlyDelivery.discount')}
                </div>
                <If condition={hideFinanceProduct}>
                  <div className="tds-text--regular">{i18n('EarlyDelivery.lease_unavailable')}</div>
                </If>
              </If>
            </div>
          </div>
        </When>
        <Otherwise>
          <div className="tds-o-flex_no-wrap tds-o-padding_bottom-4 tds-text--contrast-medium tds-text--regular tds-text--body">
            <div className="tds-o-padding_right-4">
              <Icon data={iconCalendar} className="tds-o-icon-calendar" />
            </div>
            <div className="tds-flex-item tds-flex--align-center">
              <div className={cx({ 'tds-o-padding_bottom-4': showPickupLocation })}>
                <DeliveryDate classes="tds-text--body tds-text--start tds-text--contrast-medium tds-text--regular" hideDelivery={false} />
              </div>
              <If condition={showPickupLocation}>
                <div>
                  {i18n('EarlyDelivery.future_pickup_location', {
                    ZIP_CODE: zipCode,
                  })}
                </div>
              </If>
            </div>
          </div>
        </Otherwise>
      </Choose>
      <If condition={!showPickupLocation && showTaxCreditNotEligible && false}>
        <div className="tds-o-flex_no-wrap tds-o-padding_bottom-4">
          <div className="tds-o-padding_right-4">
            <Icon data={iconWarningSmall} />
          </div>
          <div className="tds-flex-item tds-flex--align-center">
            <div className="tds-text--body tds-text--contrast-medium tds-text--regular">
              {i18n('Incentives.ineligible_for_taxcredit')}
            </div>
          </div>
        </div>
      </If>
    </div>
  );
};
const mapStateToProps = state => {
  const { ReviewDetails } = state;
  const { showAfterSavingsPriceForTrims: showProbableSavings, displayFinanceToggleOnSwap } = ReviewDetails || {}; 
  const { hideFinanceProductOnDiscount = [] } = state?.FinancingOptions || {};
  const { earlyVehicleDesigns = [] } = ReviewDetails?.vehicleDesign || {};

  return {
    zipCode: ReviewDetails?.DeliveryDetails?.PostalCode || '',
    hideFinanceProduct: !!hideFinanceProductOnDiscount?.length,
    showProbableSavings,
    earlyVehicleDesigns,
    displayFinanceToggleOnSwap,
  };
};

VehicleDetails.defaultProps = {
  configList: [],
  deliveryDetails: {},
  isAlternateDesign: false,
  IsAtLocation: false,
  VrlCustomName: '',
  showPickupLocation: false,
  taxCredit: 0,
  zipCode: '',
  Discount: 0,
  TotalPrice: 0,
  Price: 0,
  showTaxCreditNotEligible: false,
  hideFinanceProduct: false,
  showProbableSavings: false,
  PriceAfterProbableSavings: 0,
  TotalPriceAfterProbableSavings: 0,
  displayFinanceToggleOnSwap: false,
};

VehicleDetails.propTypes = {
  configList: array,
  deliveryDetails: shape({}),
  isAlternateDesign: bool,
  IsAtLocation: bool,
  VrlCustomName: string,
  showPickupLocation: bool,
  taxCredit: number,
  zipCode: string,
  Discount: number,
  TotalPrice: number,
  Price: number,
  showTaxCreditNotEligible: bool,
  hideFinanceProduct: bool,
  showProbableSavings: bool,
  PriceAfterProbableSavings: number,
  TotalPriceAfterProbableSavings: number,
  displayFinanceToggleOnSwap: bool,
};

export default connect(mapStateToProps, null)(VehicleDetails);