import { any, bool, func, number, string } from 'prop-types';
import React from 'react';
import { Transition, SwitchTransition } from 'react-transition-group';

const defaultStyle = {
  opacity: 1,
  filter: 'blur(0)',
};

const transitionStyles = {
  entering: { opacity: 0, filter: 'blur(2px)' },
  entered: { opacity: 1, filter: 'blur(0px)' },
  exiting: { opacity: 0, filter: 'blur(2px)' },
  exited: { opacity: 0, filter: 'blur(2px)' },
};

// FADE
// in: an optional boolean prop to show
// transitionKey: a string key to represent the uniquness of the child component
// children: the component to transition
const Fade = ({ in: inProp = true, transitionKey, duration = 150, onEnter, children }) => {
  if (!transitionKey) return null;

  return (
    <SwitchTransition>
      <Transition in={inProp} key={transitionKey} timeout={duration} onEnter={onEnter} appear>
        {state => (
          <div
            style={{
              ...defaultStyle,
              transition: `all ${duration}ms var(--bezier)`,
              ...transitionStyles[state],
            }}
          >
            {children}
          </div>
        )}
      </Transition>
    </SwitchTransition>
  );
};

Fade.propTypes = {
  in: bool,
  transitionKey: string,
  children: any,
  duration: number,
  onEnter: func,
};

export default Fade;
