import React, { useState, useEffect } from 'react';
import { func, number, array } from 'prop-types';
import cx from 'classnames';

// Window refers to the dots surrounding the active dot that we can see
// - - - - - o O O O o - - -  @dean

const Dots = ({ index = 0, list = [], maxCount = 10, onSelect = () => {} }) => {
  const [[startIndex, endIndex], setWindow] = useState([0, maxCount - 1]);

  useEffect(() => {
    // If the first item is selected, and the window is at the end of list
    if (index === 0 && endIndex === list.length - 1) {
      return setWindow([0, maxCount - 1]);
    }

    // If the last item is selected, and the window is at the start of list
    if (index === list.length - 1 && startIndex === 0) {
      return setWindow([list.length - 1 - maxCount, list.length - 1]);
    }

    // If we need to move the window forward
    if (index === endIndex) {
      if (endIndex !== list.length - 1) {
        return setWindow([startIndex + 1, endIndex + 1]);
      }
    }

    // If we need to move the window backwards
    if (index === startIndex) {
      if (startIndex !== 0) {
        return setWindow([startIndex - 1, endIndex - 1]);
      }
    }
  }, [index]);

  return (
    <span className="gallery--dots-container">
      {list.map((item, dotIndex) => (
        <button
          key={dotIndex}
          className={cx('gallery--dots-dot', {
            'gallery--dots-dot__active': dotIndex === index,
            'gallery--dots-dot__in-window': startIndex <= dotIndex && dotIndex <= endIndex,
            'gallery--dots-dot__window-end-point': startIndex === dotIndex || endIndex === dotIndex,
          })}
          onClick={() => onSelect(dotIndex)}
        >
          <span className="tds--is_visually_hidden">{`select item ${index + 1}`}</span>
        </button>
      ))}
    </span>
  );
};

Dots.propTypes = {
  index: number,
  list: array,
  maxCount: number,
  onSelect: func,
};

Dots.defaultProps = {
  index: 0,
  list: [],
  maxCount: 0,
  onSelect: () => {},
};

export default Dots;
