import React, { useRef } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { bool, shape, string, number, func } from 'prop-types';
import { i18n, isEMEAMarket, isNAMarket } from 'utils';
import { showRepairDisclosure } from 'selectors';
import { saveUserViewedDisclosures } from 'actions';
import { ADDITIONAL_DETAILS_MODAL, CLEAN, ACCIDENT, INSPECTION_MODAL, CPO_MARKETS } from 'dictionary';
import InfoBlock from '../InfoBlock';
import Analytics from 'analytics';
import { constructUrl } from '../../common/utils';

const AdditionalDetailsBlock = ({
  isLayoutMobile,
  thirdPartyHistoryUrl,
  serviceRepairHost,
  showRecall,
  ddFile,
  showCleanHistory,
  repairedHistory,
  RegistrationDetails,
  showThirdPartyHistoryUrl,
  saveUserViewedFiles,
  DamageDisclosureLatest,
  showDamageDisclosure,
  isRegionNA,
  isInventory,
  showMechDisclosure,
  showRefurbStandards,
  market,
  isEMEA,
  isUsedInventory,
  isDemo,
  vin,
  getAutoCheckReport,
  isAutoCheckEnabled,
  hideDeliveryStandardBlock,
  cpoDisclaimer,
  cpoLink,
  enableCyberpunk,
  isCoinReloaded,
}) => {
  const formApiRef = useRef();

  if (!isInventory || hideDeliveryStandardBlock) {
    return null;
  }

  const triggerAnalytics = interaction => {
    if (!interaction) {
      return;
    }

    Analytics.fireTagEvent({
      event: Analytics.event,
      'cfg-type': Analytics.cfgType,
      interaction,
    });
  };

  const contents = [];
  let buttonLabel = '';
  let componentName = null;
  let buttonClass = null;

  const { csrf_key = '', csrf_token = '' } = window.teslaConfig || {};

  // Clean vehicle history with no accidents
  if (showCleanHistory) {
    contents.push({
      title: i18n('Inventory.vehicleHistory.clean.modalTitle'),
      text: i18n('Inventory.vehicleHistory.clean.modalLink'),
      description: i18n('Inventory.vehicleHistory.clean.content'),
      componentName: ADDITIONAL_DETAILS_MODAL,
      analytics: 'clean-history-disclosure',
    });
  }
  if (showDamageDisclosure) {
    const { filename = '', filetype = '', guid = '' } = DamageDisclosureLatest || {};
    contents.push({
      href: ddFile,
      id: filename,
      text: i18n('Inventory.vehicleHistory.damage.label'),
      analytics: 'previously-repaired-damage-disclosure',
      onClick: () => {
        saveUserViewedFiles({
          [guid]: {
            Guid: guid,
            FileType: filetype,
            FileName: filename,
            Viewed: true,
          },
        });
      },
    });
  }
  // Repaired vehicle history with or no damage disclosures
  else if (repairedHistory) {
    contents.push({
      title: i18n('Inventory.vehicleHistory.damage.label'),
      text: i18n('Inventory.vehicleHistory.damage.modalLink'),
      componentName: ADDITIONAL_DETAILS_MODAL,
      description: i18n(
        `Inventory.vehicleHistory.damage.${isRegionNA ? 'content' : 'content_alt'}`
      ),
      analytics: 'previously-repaired-disclosure',
    });
  }
  // TODO: determine which countries to show registration information for
  const showRegistration = false;
  if (showRegistration && RegistrationDetails) {
    const { firstRegistered, registrationCount } = RegistrationDetails;
    if (firstRegistered || registrationCount) {
      contents.push(i18n('Inventory.registration.previouslyRegistered'));
    }
  }
  // cosmetic and mechanical disclosure
  if (showMechDisclosure) {
    if (showRefurbStandards) {
      componentName = INSPECTION_MODAL;
      buttonLabel = i18n('common.learn_more');
      buttonClass = 'tds-btn--tertiary tds-btn--medium';
      if (isEMEA && isUsedInventory) {
        buttonLabel = '';
        componentName = null;
        buttonClass = null;
        i18n('InspectionStandards.emeaDisclaimerList', {CPO_LINK: cpoLink})?.map(item => contents.push(item));
      } else if (isDemo) {
        contents.push(i18n('InspectionStandards.mechanicalAndCosmeticDisclaimer'));
      } else {
        cpoDisclaimer && contents.push(cpoDisclaimer);
        contents.push(i18n('InspectionStandards.mechanicalDisclaimer'));
        contents.push(i18n('InspectionStandards.cosmeticDisclaimer'));
      }
    } else {
      cpoDisclaimer && contents.push(cpoDisclaimer);
      contents.push({
        title: i18n('Inspection.title'),
        text:
          market === 'CN'
            ? i18n('Inspection.modalLink', null, null, { specificOverride: 'zh_CN' })
            : i18n('Inspection.modalLink'),
        componentName: INSPECTION_MODAL,
        analytics: 'mechanical-cosmetic-standards-disclosure',
        code: 'Inspection-title'
      });
    }
  }
  if (showThirdPartyHistoryUrl && thirdPartyHistoryUrl) {
    contents.push(
      i18n('Inventory.thirdPartyHistoryUrl', {
        LINK: thirdPartyHistoryUrl,
      })
    );
  }
  if (showRecall) {
    contents.push({
      link: i18n('Inventory.recall.disclaimer', {
        LINK: `${serviceRepairHost}/vin-recall-search`,
      }),
      analytics: 'open-recall',
      code: 'open-recall',
    });
  }

  if (isAutoCheckEnabled) {
    contents.push({
      text: (
        <form
          ref={formApiRef}
          action={getAutoCheckReport}
          method="POST"
          rel="noreferrer"
          target="_blank"
        >
          <input type="hidden" name="vin" value={vin} />
          <input type="hidden" name={csrf_key} value={csrf_token} />
          <input type="hidden" name="csrf_name" value={csrf_key} />
          <input type="hidden" name="csrf_value" value={csrf_token} />
          <button
            type="button"
            className="tds-link"
            onClick={() => {
              formApiRef?.current?.submit();
              triggerAnalytics('autocheck-history');
            }}
          >
            {i18n('Inventory.autocheck.label')}
          </button>
        </form>
      ),
    });
    triggerAnalytics('show-autocheck-history');
  }

  if (!contents.length) {
    return null;
  }
  const titleSection =
    market === 'CN'
      ? i18n('Inventory.vehicleDetails.deliveryStandardLabel')
      : i18n('Inventory.common.condition');

  return (
    <div
      className={classnames(`additional-details-container`, {
        'tds--vertical_padding--6x': isLayoutMobile && !isCoinReloaded,
      })}
    >
      <InfoBlock
        name="additionalDetailsBlock"
        titleSection={titleSection}
        contents={contents}
        unordered
        buttonLabel={buttonLabel}
        componentName={componentName}
        buttonClass={buttonClass}
        contentsClass={classnames({ 'tds--vertical_padding--small' : enableCyberpunk })}
      />
    </div>
  );
};

AdditionalDetailsBlock.propTypes = {
  RegistrationDetails: shape({
    firstRegistered: string,
    registrationCount: number,
  }),
  isUsedInventory: bool.isRequired,
  isLayoutMobile: bool,
  showMechDisclosure: bool,
  isRegionNA: bool,
  thirdPartyHistoryUrl: string,
  showThirdPartyHistoryUrl: bool,
  serviceRepairHost: string,
  showRecall: bool,
  ddFile: string,
  filePath: string,
  DamageDisclosureLatest: shape({
    guid: string,
    filetype: string,
    filename: string,
  }),
  showCleanHistory: bool,
  repairedHistory: bool,
  saveUserViewedFiles: func,
  showDamageDisclosure: bool,
  isInventory: bool.isRequired,
  showRefurbStandards: bool,
  market: string,
  isEMEA: bool,
  isDemo: bool,
  vin: string,
  getAutoCheckReport: string,
  isAutoCheckEnabled: bool,
  hideDeliveryStandardBlock: bool,
  cpoDisclaimer: string,
  cpoLink: string,
};

AdditionalDetailsBlock.defaultProps = {
  RegistrationDetails: {},
  isLayoutMobile: false,
  showMechDisclosure: false,
  isRegionNA: false,
  thirdPartyHistoryUrl: '',
  showThirdPartyHistoryUrl: false,
  serviceRepairHost: '',
  showRecall: true,
  ddFile: '',
  filePath: '',
  DamageDisclosureLatest: {},
  showCleanHistory: false,
  repairedHistory: false,
  saveUserViewedFiles: () => {},
  showDamageDisclosure: false,
  showRefurbStandards: false,
  market: '',
  isEMEA: false,
  isDemo: false,
  vin: '',
  getAutoCheckReport: '',
  isAutoCheckEnabled: false,
  hideDeliveryStandardBlock: false,
  cpoDisclaimer: false,
  cpoLink: false,
};

function mapStateToProps(state) {
  const { OMS, ReviewDetails, App } = state;
  const { isLayoutMobile = false, env, routes, isAutoCheckEnabled, locale, sibling, enableCyberpunk, isCoinReloaded } = App;
  const { getDMSFiles: filePath = '', getAutoCheckReport } = routes || {};
  const { oms_params } = OMS;
  const { market } = oms_params || {};
  const { service_repair_host: serviceRepairHost = '' } = env || {};
  const {
    showRecall: recallInfo = true,
    showThirdPartyHistoryUrl = false,
    product,
    showRefurbStandards,
    hideDeliveryStandardBlock,
  } = ReviewDetails;
  const { data: inventoryData = {}, isUsedInventory = false, isInventory = false } = product || {};
  const {
    IsDemo = false,
    ThirdPartyHistoryUrl = '',
    VehicleHistory = '',
    RegistrationDetails,
    DamageDisclosureLatest,
    VIN: vin = '',
  } = inventoryData || {};
  const { guid = '' } = DamageDisclosureLatest || {};
  const isEMEA = isEMEAMarket(market);
  const isRegionNA = isNAMarket(market);
  const cleanHistory = !!(VehicleHistory && VehicleHistory.toUpperCase() === CLEAN);

  const cpoLink = constructUrl('support/certified-pre-owned', sibling, locale)
  const cpoDisclaimer = isUsedInventory && CPO_MARKETS.includes(market) ? i18n('InspectionStandards.cpoLink', {CPO_LINK: cpoLink}) : '';

  return {
    inventoryData,
    isUsedInventory,
    isLayoutMobile,
    isDemo: IsDemo,
    market,
    isEMEA,
    showThirdPartyHistoryUrl,
    thirdPartyHistoryUrl: ThirdPartyHistoryUrl,
    serviceRepairHost,
    showRecall: isUsedInventory && recallInfo,
    showDamageDisclosure: showRepairDisclosure(state),
    ddFile: guid ? filePath + guid : '',
    DamageDisclosureLatest,
    showCleanHistory: cleanHistory && !isEMEA && isUsedInventory,
    repairedHistory: !!(VehicleHistory && VehicleHistory.toUpperCase() === ACCIDENT),
    RegistrationDetails,
    isRegionNA,
    isInventory,
    showMechDisclosure: isUsedInventory || IsDemo,
    showRefurbStandards,
    vin,
    getAutoCheckReport,
    isAutoCheckEnabled,
    hideDeliveryStandardBlock,
    cpoDisclaimer,
    cpoLink,
    enableCyberpunk,
    isCoinReloaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveUserViewedFiles: payload => {
      dispatch(saveUserViewedDisclosures(payload));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalDetailsBlock);
