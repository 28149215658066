import React from 'react';
import { string, func } from 'prop-types';
import { isPriceAcceptance } from 'utils';
import cx from 'classnames';
import { Breadcrumbs, Breadcrumb } from '@tesla/design-system-react';

/* Navigation component */
const Navigation = ({ label, onClick }) => {

  if (isPriceAcceptance()) {
    return null;
  }

  const onKeyDown = e => {
    if (e.keyCode === 13 || e.keyCode === 32) onClick();
  };

  return (
      <Breadcrumbs>
        <Breadcrumb onClick={onClick} isShallow>{label}</Breadcrumb>
      </Breadcrumbs>
  );
};

Navigation.propTypes = {
  label: string,
  onClick: func.isRequired,
};

Navigation.defaultProps = {
  label: 'Edit design',
};

export default Navigation;
