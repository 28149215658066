/* eslint global-require: 0 */

// eslint-disable-next-line import/no-extraneous-dependencies
import { DSServices } from '@web/tesla-rest-ds-services';
import { Storage } from '@tesla/coin-common-components';
import _get from 'lodash/get';
import _has from 'lodash/has'
import _merge from 'lodash/merge'
import _mergeWith from 'lodash/mergeWith'
import _isArray from 'lodash/isArray'
import _empty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _reduce from 'lodash/reduce';
import _filter from 'lodash/filter';
import _cloneDeep from 'lodash/cloneDeep'
import deepFreeze from 'deep-freeze'
import { merge } from 'rxjs/observable/merge';

import {
  LOCAL_STORAGE_KEY,
  INVENTORY_DATE_FIELDS,
  Models,
} from 'dictionary';

import {
  isPriceAcceptance,
  getMatchingDeltas,
} from 'utils';

import * as App from './App';
import * as ApplicationFlow from './ApplicationFlow';
import * as Assets from './Assets';
import * as Compositor from './Compositor';
import * as CustomGroups from './CustomGroups';
import * as Configuration from './Configuration';
import * as FindMyTesla from './FindMyTesla';
import * as Navigation from './Navigation';
import * as Financial from './Financial';
import * as FinancingOptions from './FinancingOptions';
import * as Forms from './Forms';
import * as ReviewDetails from './ReviewDetails';
import * as DeliveryTiming from './DeliveryTiming';
import * as Payment from './Payment';
import * as Alert from './Alert';
import * as ApplePay from './ApplePay';
import * as Address from './Address';
import * as FeatureListModal from './FeatureListModal';
import * as FinanceModal from './FinanceModal';
import * as SaveDesign from './SaveDesign';
import * as Enterprise from './Enterprise';
import * as Accessories from './Accessories';
import * as SetRules from './SetRules';
import * as SummaryPanel from './SummaryPanel';
import * as Loader from './Loader';
import * as Locale from './Locale';
import * as Location from './Location';
import * as Pricing from './Pricing';
import * as Modal from './Modal';
import * as OMS from './OMS';
import * as ALD from './ALD';
import * as Banner from './Banner';
import * as TradeIn from './TradeIn';
import * as Subscription from './Subscription';

// export actions
export * from './Configuration/Actions';
export * from './FindMyTesla/Actions';
export * from './Navigation/Actions';
export * from './Forms/Actions';
export * from './ReviewDetails/Actions';
export * from './App/Actions';
export * from './ApplicationFlow/Actions';
export * from './DeliveryTiming/Actions';
export * from './Payment/Actions';
export * from './Alert/Actions';
export * from './ApplePay/Actions';
export * from './Financial/Actions';
export * from './FinancingOptions/Actions';
export * from './FeatureListModal/Actions';
export * from './FinanceModal/Actions';
export * from './SaveDesign/Actions';
export * from './Enterprise/Actions';
export * from './Accessories/Actions';
export * from './SetRules/Actions';
export * from './SummaryPanel/Actions';
export * from './Loader/Actions';
export * from './Location/Actions';
export * from './Modal/Actions';
export * from './OMS/Actions';
export * from './ALD/Actions';
export * from './TradeIn/Actions';
export * from './Subscription/Actions';

const tslaObjInjection = _get(window, 'tesla', {});
const tslaObj = _cloneDeep(tslaObjInjection);
const {
  env,
  savedConfiguration,
  DSServices: DSServicesData,
  product = {},
  use_location_svc = false,
  location_svc_url = '',
  omsParams = {},
  Order = {},
  rn = null,
} = tslaObj;

const { ROADSTER, SEMI, MODEL_S, MODEL_X } = Models;

const { model, variant, market } = omsParams;

const { Lexicon, InitialConfig = {} } = new DSServices({}).get(
  () => {},
  { model, variant },
  omsParams
);

const available_configurations = DSServicesData.KeyManager.query.available_configurations
  .split(',')
  .map(configuration => {
    const configurationDetail = configuration.split(':');
    return {
      model: configurationDetail[0],
      variant: configurationDetail[1],
    };
  });

// If inventory vehicle checks if inventory filter should be applied
// to values returned from DS services
if (DSServicesData && product) {
  _forEach(DSServicesData, service => {
    _forEach(service, (data, serviceKey) => {
      if (Array.isArray(data)) {
        // eslint-disable-next-line no-param-reassign
        service[serviceKey] = _filter(data, value => {
          if (value.inventoryFilters && product.data) {
            return _reduce(
              value.inventoryFilters,
              (assert, currentVal, filterKey) => {
                let inventoryValue = _get(product, `data.${filterKey}`, null);
                return (
                  assert &&
                  _reduce(
                    currentVal,
                    (acc, comparedValue, operator) => {
                      if (INVENTORY_DATE_FIELDS.includes(filterKey)) {
                        // Calculates number of days from today to date provided
                        const inventoryDate = new Date(inventoryValue).getTime();
                        const now = new Date().getTime();

                        const msPerDay = 1000 * 60 * 60 * 24;
                        inventoryValue = (now - inventoryDate) / msPerDay;
                      }

                      switch (operator) {
                        case '<':
                          return acc && inventoryValue < comparedValue;
                        case '<=':
                          return acc && inventoryValue <= comparedValue;
                        case '=':
                        case '==':
                        case '===':
                          return acc && inventoryValue === comparedValue;
                        case '>':
                          return acc && inventoryValue > comparedValue;
                        case '>=':
                          return acc && inventoryValue >= comparedValue;
                        case '!=':
                        case '!==':
                          return acc && inventoryValue !== comparedValue;
                        default:
                          return acc && true;
                      }
                    },
                    true
                  )
                );
              },
              true
            );
          }
          return true;
        });
      }
    });
  });
}
// Get compositor URL normalized and for mt case.
const isReservation = _get(product, 'data.isReservation', false);
const asset_url = isReservation ? _get(product, 'data.imgURL', '') : '';
const compositor_url = _get(env, 'designStudio.compositorUrl', '');
const compositorUrlNormalized = compositor_url.includes('?')
  ? compositor_url
  : `${compositor_url}?`;

const compositorUrl =
  (model === ROADSTER || model === SEMI) && asset_url ? asset_url : compositorUrlNormalized;

const StateOverride = (() => {
  if (tslaObj.savedConfiguration) {
    const enableOrderModification = _get(Order, 'enableOrderModification', false);
    const isInvited = _get(Order, 'orderDetails.invitationDate', null);
    const isReservationToOrderFlow = !!rn && !enableOrderModification && !isPriceAcceptance();
    if (
      (market === 'CN' &&
      isInvited &&
      !enableOrderModification &&
      (model === MODEL_S || model === MODEL_X)) ||
      isReservationToOrderFlow
    ) {
      return {
        Configuration: {
          option_codes: [],
          option_codes_saved: [],
        },
      };
    }
    return { ...FindMyTesla.orderSchemaToState(tslaObj.savedConfiguration) };
  }

  // Is there a Inventory VIN-based injection?
  if (tslaObj.product) {
    const mktOptionCodes = _get(tslaObj, 'product.data.OptionCodeList', '')
      .split(',')
      .map(code => {
        return { code };
      });
    if (mktOptionCodes.length) {
      return {
        ...FindMyTesla.orderSchemaToState({
          config: { mktOptionCodes },
          isLegacy: true, // set for inventory
        }),
      };
    }
  }

  const cache = Storage.get(LOCAL_STORAGE_KEY, data => {
    const effective_date = _get(data, `value.StoredConfiguration.${model}.effective_date`);
    const lexicon_name = _get(data, `value.StoredConfiguration.${model}.lexicon_name`);
    if (
      effective_date !== _get(Lexicon, 'effective_date') ||
      lexicon_name !== _get(Lexicon, 'name')
    ) {
      return false;
    }
    return _get(data, 'version') === _get(tslaObj, 'version', '');
  });

  // if user has previously configured this model (omsParams.model),
  // then grab the options they selected.
  let user_selected_options = _get(cache, `StoredConfiguration.${model}.user_selected_options`);
  const exclude_local_storage_options = _get(tslaObj, 'exclude_local_storage_options', []);

  if (!_empty(exclude_local_storage_options) && !_empty(user_selected_options)) {
    user_selected_options = user_selected_options.filter(
      option => !exclude_local_storage_options.includes(option)
    );
  }
  const optionCodes = savedConfiguration
    ? savedConfiguration.config &&
      savedConfiguration.config.mktOptionCodes.map(option => option.code)
    : user_selected_options;
  return {
    ...cache,
    Configuration: {
      option_codes: optionCodes,
      option_codes_saved: optionCodes,
      savedConfiguration,
    },
  };
})();
const { teslaGeoip = {} } = env;
const { url: teslaGeoipUrl } = teslaGeoip;
const envObj = {
  ...env,
  teslaGeoip: {
    ...teslaGeoip,
    url: use_location_svc && location_svc_url ? location_svc_url : teslaGeoipUrl,
  },
};

const localeData = _get(tslaObj, 'App.localeSelector', {});
let localeSelector = {};
try {
  localeSelector = JSON.parse(localeData);
} catch {
  // noop
}

const isCryptoEnabled = _get(tslaObj, 'cr_enabled', false);
const isCryptoExcluded = ['$MTS09', '$MTX09'];
const configOptions = StateOverride.Configuration.option_codes || [];
const assetUrl = _get(envObj, 'designStudio.assetUrl', '') || '';
const videoAssetUrl = assetUrl
  ? assetUrl
      .replace('image', 'video')
      .replace('f_auto', 'f_auto:video')
      .replace('q_auto', 'q_auto:best')
  : '';

// Note: StateOverrides are not actually consumed in config modules init() func
// TODO: investigate if needed
const availableReducers = {
  App: {
    ...App,
    InitialState: App.InitialState({
      object: InitialConfig.App,
      overrides: {
        debug: tslaObj.App.debug || false,
        localStorageKey: LOCAL_STORAGE_KEY,
        version: _get(tslaObj, 'version', ''),
        env: envObj,
        localeSelector,
      },
    }),
  },
  Alert,
  // Most reducers depend on OMS...
  OMS: {
    ...OMS,
    InitialState: OMS.InitialState({
      object: InitialConfig.OMS,
      overrides: {
        oms_params: omsParams,
        available_configurations,
      },
    }),
  },
  Assets: {
    ...Assets,
    InitialState: Assets.InitialState({
      object: InitialConfig.Assets,
      overrides: {
        baseURL: assetUrl,
        videoBaseURL: assetUrl ? videoAssetUrl : '',
        // COIN-7689: Keep as separate for now to have flesibility to revert
        videoBaseURLMobile: assetUrl ? videoAssetUrl : '',
      },
    }),
  },
  Configuration: {
    ...Configuration,
    InitialState: Configuration.InitialState({
      object: InitialConfig.Configuration,
      overrides: StateOverride.Configuration,
    }),
  },
  Locale: {
    ...Locale,
    InitialState: Locale.InitialState({
      object: InitialConfig.Locale,
      overrides: {
        i18n: tslaObj.i18n,
      },
    }),
  },
  Location,
  SummaryPanel: {
    ...SummaryPanel,
    InitialState: SummaryPanel.InitialState({
      object: InitialConfig.SummaryPanel,
    }),
  },
  Financial,
  Pricing: {
    ...Pricing,
    InitialState: Pricing.InitialState({}),
  },
  Compositor: {
    ...Compositor,
    InitialState: Compositor.InitialState({
      object: InitialConfig.Compositor,
      overrides: {
        compositor_url: compositorUrl,
      },
    }),
  },
  Modal,
  Loader,
  SetRules: {
    ...SetRules,
    InitialState: SetRules.InitialState({
      object: InitialConfig.SetRules,
    }),
  },
  // Depends on Financial & Location reducers to be setup
  CustomGroups: {
    ...CustomGroups,
    InitialState: CustomGroups.InitialState({
      object: InitialConfig.CustomGroups,
    }),
  },
  FindMyTesla: {
    ...FindMyTesla,
    InitialState: FindMyTesla.InitialState({
      object: InitialConfig.FindMyTesla,
    }),
  },
  // Depends on App reducer to be setup
  Navigation,
  Address,
  // Depends on Configurator & SummaryPanel reducer to be setup
  Forms: {
    ...Forms,
    InitialState: Forms.InitialState({
      object: InitialConfig.Forms,
    }),
  },
  // Depends on Configurator reducer to be setup
  ReviewDetails: {
    ...ReviewDetails,
    InitialState: ReviewDetails.InitialState({
      object: InitialConfig.ReviewDetails,
    }),
  },
  ApplicationFlow: {
    ...ApplicationFlow,
    InitialState: ApplicationFlow.InitialState({
      object: InitialConfig.ApplicationFlow,
      overrides: {
        isCryptoExcluded,
        isCryptoAllowed: isCryptoEnabled
          ? !isCryptoExcluded.some(val => configOptions.indexOf(val) !== -1)
          : false,
      },
    }),
  },
  // Depends on ReviewDetails reducer
  Payment: {
    ...Payment,
    InitialState: Payment.InitialState({
      object: InitialConfig.Payment,
    }),
  },
  DeliveryTiming,
  ApplePay,
  FinancingOptions: {
    ...FinancingOptions,
    InitialState: FinancingOptions.InitialState({
      object: InitialConfig.FinancingOptions || {},
    }),
  },
  FeatureListModal,
  FinanceModal,
  SaveDesign,
  Enterprise,
  Accessories: {
    ...Accessories,
    InitialState: Accessories.InitialState({
      ...InitialConfig.Accessories,
    }),
  },
  ALD: {
    ...ALD,
    InitialState: ALD.InitialState({ InitialConfig }),
  },
  PrintPdf: require('./PrintPdf').default({
    object: InitialConfig.PrintPdf,
  }),
  Banner,
  TradeIn,
  Subscription,
};

/**
 * Updated deltas method that allows you to define an override using n* params
 *
 * Expects a 'deltasV2' key with the following structure to be added to InitialState:
 *
 *  deltasV2: [{
 *     selected_by: {
 *         market: 'CA',
 *         model: 'm3',
 *         language: 'fr'
 *     },
 *     object: {
 *         acceptedCardTypes: ['DISNEY'],
 *     }
 * }]
 *
 * @param  {Object} InitialState [InitialState object]
 * @param  {Object} oms_params
 * @param  {String} method       [merge, assign, etc]
 * @return {Object}              [returns InitialState updated with overrides]
 */
export function handleV2Deltas(InitialState, oms_params, method = 'merge') {
    // Instead of default merging arrays; override old array with new array -- this seems a more useful expectation for delta overrride logic
    const customMerge = (objValue, srcValue) => _isArray(objValue) ? srcValue : undefined
    const deltaObject = getMatchingDeltas(InitialState.deltasV2, oms_params).reduce((result, delta) => {
        return method === 'assign' ? { ...result, ...delta.object } : _mergeWith(result, delta.object, customMerge)
    }, { ...InitialState.object })
    return method === 'assign' ? { ...InitialState.object, ...deltaObject } : _mergeWith({ ...InitialState.object }, deltaObject, customMerge)
}


/**
 * handleDeltas adds the logic in order to handle the logic of deltas
 * @param  {[Object]} InitialState [Initial state of reducer with deltas (if available)]
 * @param  {[Object]} oms_params   [Parameters from OMS]
 * @return {[Object]}              [Initial state with deltas applied]
 */
export function handleDeltas(InitialState, oms_params, method = 'merge') {
    let deltaObject = {};
    switch (method) {
        case "assign":
            if (InitialState.deltas) {
                deltaObject = _reduce(Object.keys(InitialState.deltas), (result, delta_type) => {
                    var delta = _get(oms_params, delta_type)
                    if (delta) {
                        var dataToBeReplaced = _get(InitialState.deltas, delta_type + '.' + delta)
                        if (dataToBeReplaced) {
                            return Object.assign(result, dataToBeReplaced);
                        }
                    }
                    return result;
                }, {})
                return Object.assign(InitialState.object, deltaObject);
            }
            return InitialState.object;
        case "merge":
        default:
            if (InitialState.deltas) {
                deltaObject = _reduce(Object.keys(InitialState.deltas), (result, delta_type) => {
                    var delta = _get(oms_params, delta_type)
                    if (delta) {
                        var dataToBeReplaced = _get(InitialState.deltas, delta_type + '.' + delta)
                        if (dataToBeReplaced) {
                            return _merge(result, dataToBeReplaced);
                        }
                    }
                    return result;
                }, {})
                return _merge(InitialState.object, deltaObject);
            }
            return InitialState.object;
    }
}

const getReducers = () => _reduce(availableReducers, (result, data, key) => {
    return Object.assign(result, {
        [key]: data.Reducer
    })
}, {})

export const actions = _reduce(availableReducers, (result, data, key) => {
    return Object.assign(result, {
        [key]: data.Actions
    })
}, {})

export const rootEpic = () => {
    const epics = Object.keys(availableReducers).reduce((result, reducer) =>
        result = [].concat(result, Object.keys(availableReducers[reducer].Epics || {})
            .reduce((result, epic) =>
                result = [].concat(typeof availableReducers[reducer].Epics[epic] === 'function' ? availableReducers[reducer].Epics[epic] : [], result)
                , [])
        )
        , [])
    return (action$, state$) => merge(...epics.map(epic => epic(action$, state$)))
}

export function rootReducer() {
  return function (state = {}, action) {
    deepFreeze(state)
    deepFreeze(action)
    if (action.type === 'REPLACE_STATE') {
      state.App.debug ? console.warn('Replacing the complete state', action.state) : null
      return action.state
    }
    const reducers = getReducers()
    return Object.keys(reducers).reduce((nextState, key) => {
      // Call every reducer with the part of the state it manages
      try {
        nextState[key] = reducers[key](state[key], action, { app_state: nextState })
      }
      catch (e) {
        /**
         * If something breaks while reducing data tell the user in which reducer trying to do which action
         */
        console.error('ERROR on reducer:', key)
        console.error('error object:', e)
        console.error('while consuming the action:', action)
      }
      return nextState
    }, {})
  }
}

export function getInitialState(oms_params) {
    return _reduce(availableReducers, (response, data, key) => {
      let clonedState = _cloneDeep(data.InitialState);
      let { merge_method } = clonedState;
      let deltaState = Object.assign(response, { [key]: handleDeltas(clonedState, oms_params, merge_method) });
      if (data.InitialState.deltasV2) {
          deltaState = Object.assign(deltaState, { [key]: handleV2Deltas(clonedState, oms_params, merge_method) });
      }
      return deltaState;
    }, {});
}
