import React, { Suspense, Component } from 'react';
import { connect } from 'react-redux';
import { shape, bool, string, arrayOf } from 'prop-types';
import _has from 'lodash/has';
import cx from 'classnames';
import { lazyWithPreload } from '../Lazy';

const availableComponents = {
  PaymentOverview: lazyWithPreload(() => {
    import(/* webpackChunkName: "payment-overview" */ '../../containers/PaymentOverview');
  }),
  AssetLoader: lazyWithPreload(() =>
    import(/* webpackChunkName: "asset-loader" */ '../AssetLoader')
  ),
  EditDesignLink: lazyWithPreload(() =>
    import(
      /* webpackChunkName: "edit-design-link" */ '../../containers/PaymentOverview/Sections/EditDesignLink'
    )
  ),
  LexiconGroup: lazyWithPreload(() =>
    import(/* webpackChunkName: "lexicon-group" */ './LexiconGroup')
  ),
};

class CustomGroup extends Component {
  shouldComponentUpdate = nextProps => {
    const { components } = this.props;
    const { components: newData } = nextProps;
    return components.length !== newData.length;
  };

  render() {
    const { components, isMain, withMain, isStarting, classes } = this.props;
    if (!components.length) {
      return null;
    }
    return (
      <>
        <If condition={isMain || withMain}>
          <div className="cf-asset-wrapper">
            <div className="group--main-content group--main-content--big group-main-content--full-asset">
              {components.map(item => {
                const { component: type, props = {}, key } = item;
                if (!_has(availableComponents, type)) {
                  return null;
                }
                const ComponentName = availableComponents[type];
                if (isStarting) {
                  return <ComponentName key={`GroupComponent_${type}_${key}`} {...props} />;
                }
                return (
                  <Suspense
                    key={`Suspense.Main.GroupComponent_${type}_${key}`}
                    fallback={
                      <div
                        className="tds-spinner tds-spinner--fade_in"
                        style={{ position: 'absolute', height: '450px' }}
                      ></div>
                    }
                  >
                    <ComponentName key={`GroupComponent_${type}_${key}`} {...props} />
                  </Suspense>
                );
              })}
            </div>
          </div>
        </If>

        <If condition={!isMain}>
          <div
            className={cx(
              {
                'tds--vertical_padding--6x': !withMain,
              },
              {
                'tds--padding tds--align_center': withMain,
              },
              'option-widget--container',
              { [classes]: classes }
            )}
          >
            {components.map(item => {
              const { component: type, props = {}, key } = item;
              if (!_has(availableComponents, type)) {
                return null;
              }
              const ComponentName = availableComponents[type];
              if (isStarting) {
                return <ComponentName key={`GroupComponent_${type}_${key}`} {...props} />;
              }
              return (
                <Suspense
                  key={`Suspense.Aside.GroupComponent_${type}_${key}`}
                  fallback={
                    <div
                      className="tds-spinner tds-spinner--fade_in"
                      style={{ position: 'absolute', height: '450px' }}
                    ></div>
                  }
                >
                  <ComponentName
                    key={`GroupComponent_${type}_${key}`}
                    {...props}
                    classes={`${type}--section`}
                  />
                </Suspense>
              );
            })}
          </div>
        </If>
      </>
    );
  }
}

CustomGroup.propTypes = {
  isStarting: bool,
  isMain: bool,
  withMain: bool,
  isDesktop: bool,
  classes: string,
  currentNavigationKey: string,
  components: arrayOf(shape({})).isRequired,
};

CustomGroup.defaultProps = {
  isDesktop: false,
  isStarting: false,
  withMain: false,
  isMain: false,
  classes: '',
  currentNavigationKey: '',
};

function mapStateToProps(state, ownProps) {
  const { isMain, withMain, components } = ownProps;
  const { main = [], aside = [] } = components;
  const { Navigation } = state;
  const { section: currentNavigationKey } = Navigation;
  const asideData = withMain ? main.concat(aside) : aside;
  const data = isMain ? main : asideData;
  return {
    components: data.filter(item => (item.sections || []).includes(currentNavigationKey)),
  };
}

export default connect(mapStateToProps, null)(CustomGroup);
