import React from 'react';
import _has from 'lodash/has';
import { string, shape, bool } from 'prop-types';
import { connect } from 'react-redux';
import { WHEELS, PAINT, BATTERY_AND_DRIVE } from 'dictionary';
import TextLoader from '../TextLoader';

/* Group title component */
const Title = ({ group, field, component, classes, hideTitle }) => {
  if (!group || !_has(group, field) || hideTitle) {
    return null;
  }
  const { code } = group;
  switch (code) {
    case PAINT:
    case WHEELS:
      return (
        <legend className='tds-o--fullwidth'>
          <TextLoader
            data={group}
            tag={{ component }}
            field={field}
            className={classes}
          />
        </legend>
      );
    default:
      return (
        <TextLoader
          data={group}
          tag={{ component }}
          field={field}
          className={classes}
        />
      );
  }
};

function mapStateToProps(state, ownProps) {
  const { FeatureListModal: { groupName } = {}, App: { isCoinReloaded = false } = {} } = state;
  const { hideSelectedGroups = [], classes = 'tds-text--center text-loader--subtitle', group = '' } = ownProps;
  return {
    hideTitle: hideSelectedGroups.includes(groupName),
    classes: isCoinReloaded && group?.code !== BATTERY_AND_DRIVE ? classes.concat(' tds-text--h4') : classes,
  };
}

Title.propTypes = {
  group: shape({}).isRequired,
  field: string,
  component: string,
  classes: string,
  hideTitle: bool,
};

Title.defaultProps = {
  field: 'name',
  component: 'h1',
  classes: '',
  hideTitle: false,
};

export default connect(mapStateToProps, null)(Title);
