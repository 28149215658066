const InitialState = {
  object: {
    options: [],
    linkCopied: false,
    shareDesignRequestState: null,
    showGetUpdates: false,
    isSaveModalOpen: false,
    getUpdates: false,
    useSms: false,
    showCheckBox: true,
    shortUrl: null,
    isCN: false,
    isDynamicDisclaimer: false,
  },
  deltasV2: [
    {
      selected_by: {
        market: ['US'],
      },
      object: {
        showGetUpdates: true,
        showCheckBox: false,
        getUpdates: true,
      },
    },
    {
      selected_by: {
        market: ['JP'],
      },
      object: {
        getUpdates: true,
      },
    },
    {
      selected_by: {
        market: ['CA', 'MX', 'PR'],
      },
      object: {
        showGetUpdates: true,
      },
    },
    {
      selected_by: {
        market: ['CN'],
      },
      object: {
        isCN: true,
        useSms: true,
        showCheckBox: false,
      },
    },
    {
      selected_by: {
        market: [
          'AT',
          'BE',
          'CH',
          'CZ',
          'DE',
          'DK',
          'EE',
          'ES',
          'FI',
          'FR',
          'GB',
          'GR',
          'HR',
          'HU',
          'IE',
          'IS',
          'IT',
          'LT',
          'LU',
          'LV',
          'NL',
          'NO',
          'PL',
          'PT',
          'RO',
          'SE',
          'SI',
          'SK',
          'TR',
          'AE',
          'IL',
          'JO',
          'QA',
        ],
      },
      object: {
        showGetUpdates: true,
        isDynamicDisclaimer: true,
      },
    },
  ],
};

export default InitialState;
