import { connect } from 'react-redux';
import AssetGallery from './index';
import { shape, string, bool, number, arrayOf, oneOfType } from 'prop-types';
import _get from 'lodash/get';
import '../../style/asset-gallery.css';
import { getGroupAssets, getAdaptedAssetsFromSource, getGroup } from '../../common/selectors';
import { openModal, toggleFeatureState } from 'actions';
import { ASSET_GALLERY_MODAL, MODAL_FULLSCREEN } from '../../common/dictionary';

const getGalleryDetails = gallery => ({
  activeGalleryName: gallery?.group || 'EXTERIOR_GALLERY',
  activeGallerySectionIndex: gallery?.section || 0,
});

export const mapStateToProps = (state, { gallery, source, groupName, activeGroupName }) => {
  const isPullingFromSource = Boolean(source);
  const sourceAssets = getAdaptedAssetsFromSource(state, source);
  const groupAssets = getGroupAssets(state, groupName);
  const activeGroup = getGroup(state, activeGroupName);
  const { activeGalleryName = '', activeGallerySectionIndex } = gallery
    ? getGalleryDetails(gallery)
    : getGalleryDetails(activeGroup.gallery);
  const isActive = isPullingFromSource || activeGalleryName === groupName;

  // Todo: Maybe clean this up into a switch statement depending on the asset source/gallery
  return {
    isActive,
    currentIndex: isPullingFromSource ? null : activeGallerySectionIndex,
    assets: isPullingFromSource ? sourceAssets : groupAssets,
    isVideoPlaybackEnabeled: _get(state, 'FeatureListModal.isCarouselOpen', false),
    labels: {
      next: _get(state.Locale['common.ui'], 'strings.galleryControlNext', ''),
      previous: _get(state.Locale['common.ui'], 'strings.galleryControlPrevious', ''),
    },
    isCyberpunk: state?.App?.enableCyberpunk,
    isMobile: state?.App?.isLayoutMobile,
    currentFeatures: state?.App?.currentFeatures || {},
  };
};

AssetGallery.propTypes = {
  id: string.isRequired,
  activeGalleryName: string,
  gallery: shape({}),
  currentIndex: number.isRequired,
  isActive: oneOfType([bool, arrayOf(shape({}))]).isRequired,
  assets: arrayOf(shape({})).isRequired,
  isVideoPlaybackEnabeled: bool.isRequired,
  labels: shape({
    next: string,
    previous: string,
  }).isRequired,
  isCyberpunk: bool.isRequired,
  isMobile: bool.isRequired,
  currentFeatures: shape({}).isRequired,
};

AssetGallery.defaultProps = {
  activeGalleryName: '',
  gallery: null,
};

const mapDispatchToProps = dispatch => {
  return {
    openGalleryModal: props => {
      dispatch(
        openModal(ASSET_GALLERY_MODAL, {
          genericWrapper: false,
          size: MODAL_FULLSCREEN,
          ...props,
        })
      );
    },
    onFeatureToggle: props => {
      dispatch(toggleFeatureState(props));
    },
  };
};

const ConfiguratorGallery = connect(mapStateToProps, mapDispatchToProps)(AssetGallery);

export default ConfiguratorGallery;
