const locationsData = [
  {
    service_id: 'beijingyizhuangdeliveryhubnew',
    title: '北京亦庄交付中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 3056,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D北京市大兴区亦庄经济开发区中和街9号特斯拉交付中心',
    city: '北京',
    province: '北京',
    country_code: 'CN',
    map_address: '北京市大兴区亦庄经济开发区中和街9号特斯拉交付中心',
    address_line_1: '北京市大兴区亦庄经济开发区中和街9号特斯拉交付中心',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '北京亦庄交付中心',
    metro_area: '',
  },
  {
    service_id: 'bejingxiedaodelivery',
    title: '北京蟹岛特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 16509,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D北京市朝阳区金盏乡蟹岛路一号4号展厅',
    city: '北京',
    province: '北京',
    country_code: 'CN',
    map_address: '北京市朝阳区金盏乡蟹岛路一号4号展厅',
    address_line_1: '北京市朝阳区金盏乡蟹岛路一号4号展厅',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '北京蟹岛特斯拉中心',
    metro_area: '',
  },
];

const defaultSelectedPickupLocationList = [
  14441, //	上海	Greater CN-CN-Shanghai Senlan Delivery Center
  25759, //	昆明	Greater CN-CN-Kunming Jingkai Tesla Center
  26475, //	呼和浩特	Greater CN-CN-Hohhot Haixi Road Tesla Center
  16509, //	北京	Greater CN-CN-Beijing Xiedao Tesla Center
  14628, //	长春	Greater CN-CN-Changchun Jingyue Tesla Center
  14962, //	成都	Greater CN-CN-Chengdu Longquan Delivery Center
  3516, //	天津	APAC-CN-Tianjin-Wanggang Service
  16896, //	银川	Greater CN-CN-Yinchuan Jinfeng Tesla Center
  8861, //	合肥	Greater CN-CN-Hefei Feidong Delivery Center
  28787, //	济南	Greater CN-CN-Jinan Fengqi Road Tesla Center
  14750, //	太原	Greater CN-CN-Taiyuan Tesla Center
  8914, //	广州	APAC-CN-Guangzhou-Kexuecheng Tesla Center
  16510, //	南宁	Greater CN-CN-Nanning Wuxiang Delivery Center
  20162, //	乌鲁木齐	Greater CN-CN-Urumqi Tesla Center
  16512, //	南京	Greater CN-CN-Nanjing Lukou Delivery Center
  8898, //	南昌	Greater CN-CN-Nanchang Hengwang Tesla Center
  416505, //	石家庄	Greater CN-CN-Shijiazhuang ChangAn Tesla Center
  18523, //	郑州	Greater CN-CN-Zhengzhou Qiancheng Delivery Center
  12167, //	杭州	Greater CN-CN-Hangzhou North Delivery Center
  27783, //	海口	Greater CN-CN-Haikou Longhua Tesla Center
  16996, //	武汉	Greater CN-CN-Wuhan Zhongfa Xincheng Delivery Center
  16995, //	长沙	Greater CN-CN-Changsha Qingzhuhu Delivery Center
  16895, //	兰州	Greater CN-CN-Lanzhou Anning Tesla Center
  12923, //	福州	Greater CN-CN-Fuzhou Cangshan Tesla Center
  28771, //	贵阳	Greater CN-CN-Guiyang Mengguan Tesla Center
  418302, //	沈阳	APAC-CN-Shenyang-Shenbei Rd Tesla Center
  16555, //	重庆	Greater CN-CN-Chongqing Lijia Delivery Center
  16552, //	西安	Greater CN-CN-Xi'an Delivery Center
  409314, //	西宁	Greater CN-CN-Xining Kunlun East Road Tesla Center
  14629, //	哈尔滨	Greater CN-CN-Harbin Tesla Center
];

export { locationsData, defaultSelectedPickupLocationList };
