import React, { useEffect } from 'react';
import { string, shape, bool } from 'prop-types';
import { connect } from 'react-redux';
import Analytics from 'analytics';
import Disclaimer from './Disclaimer';

const InventoryUpsellLink = props => {
  const { hasSubsetAvailable } = props;

  useEffect(() => {
    if (hasSubsetAvailable) {
      Analytics.fireTagEvent({
        event: Analytics.event,
        'cfg-type': 'm3',
        interaction: 'show-see-limited-inventory-for-trim',
      });
    }
  }, [hasSubsetAvailable]);

  return <Disclaimer {...props} />;
};

InventoryUpsellLink.propTypes = {
  group: shape({}),
  field: string,
  hasSubsetAvailable: bool,
};

InventoryUpsellLink.defaultProps = {
  group: {},
  field: '',
  hasSubsetAvailable: false,
};

function mapStateToProps(state, ownProps) {
  const { field, field_inventory, group = {}, componentName, classes = '' } = ownProps;
  const { ReviewDetails } = state;
  const { hasSubsetAvailable = false } = ReviewDetails;
  const fieldSource = hasSubsetAvailable ? field_inventory : field;

  return {
    ...ownProps,
    group,
    field: fieldSource,
    classes: classes
      ? `coin-component--${componentName} ${classes}`
      : `coin-component--${componentName}`,
    hasSubsetAvailable,
  };
}

export default connect(mapStateToProps)(InventoryUpsellLink);
