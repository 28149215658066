export default ({ object, overrides }) => ({
    merge_method: 'assign',
    object: {
      ...object,
      ...overrides,
      assetGroups: {
        CHARGING_ACCESSORIES: {
          gallery: {
            group: 'EXTERIOR_GALLERY',
            section: 2,
          },
        },
        RECOMMENDED_ACCESSORIES: {
          gallery: {
            group: 'EXTERIOR_GALLERY',
            section: 2,
          },
        },
        ORDER: {
          gallery: {
            group: 'EXTERIOR_GALLERY',
            section: 2,
          },
        },
      }
    },
});
