import React from 'react';
import { captureException } from '@sentry/react';

const retry = (fn, retriesLeft = 3, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            captureException(`Lazy Retry: ${error}`);
            return reject(error);
          }

          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

export const lazy = importStatement => {
  const Component = React.lazy(() => retry(() => importStatement()));

  return Component;
};

export const lazyWithPreload = importStatement => {
  const Component = React.lazy(() => retry(() => importStatement()));

  Component.preload = importStatement;
  return Component;
};

export default {
  lazy,
  lazyWithPreload,
};
