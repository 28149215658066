import { useState, useRef, useEffect } from 'react';

function useStateWithGetter(initial) {
  const mounted = useRef(true);
  const [state, setState] = useState(initial);
  const ref = useRef(state);

  ref.current = state;

  const set = value => {
    ref.current = value;

    if (mounted.current) setState(value);
  };

  const get = () => {
    return ref.current;
  };

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  return [state, set, get];
}

export default useStateWithGetter;
