import { LOCATION_CHANGED } from 'dictionary';

export default ({ object, overrides }) => ({
  object: {
    ...object,
    ...overrides,
    isInvited: false,
    isWaiting: false,
    surveyCompleted: false,
    isSubmitting: false,
    survey: {
      inviteDateEnabled: false,
      isSubmitting: false,
      validation: {
        isValid: false,
        errors: {},
      },
      results: {},
    },
    referralConfig: [],
    optionsWithExpandedFeatures: [],
    groupsWithExpandedFeatures: [],
    connect: null,
    postConnectUrl: null,
    isInitialized: false,
    initializeOn: [LOCATION_CHANGED],
    isSessionCheckStarted: false,
    isPaymentInProgress: false,
    numberOfTimesPaymentFailed: 0,
  },
});
