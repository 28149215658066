import React from 'react';
import { connect } from 'react-redux';
import { bool, func, string } from 'prop-types';
import { AlertModal } from '@tesla/coin-common-components';
import { resetEnterpriseOrder, startLoader } from 'actions';
import { i18n, htmlToReact } from 'utils';
import { INVALID_CONFIG, SESSION_EXPIRED } from 'dictionary';
import { getFulfillmentUri } from '../../common/selectors';
import { Icon } from '@tesla/design-system-react';
import { iconSuccess } from '@tesla/design-system-icons';
import { clearAccessorySelected } from '../../common/utils';
import Analytics from 'analytics';

const Checkmark = () => <Icon data={iconSuccess} color='#12BB00' className="tds-width-full" />;

const PostOrderModalContainer = ({
  isOpen,
  isError,
  isSuccess,
  bodyContent,
  btnLabel,
  titleLabel,
  isRefreshRequired,
  reset,
  fulfillmentUri,
  showLoader,
}) => {
  const onContinue = () => {
    Analytics.fireTagEvent({
      event: Analytics.event,
      'cfg-type': Analytics.cfgType,
      interaction: 'complete-pre-delivery-tasks',
    });
    if (!isError) {
      clearAccessorySelected();
      showLoader();
      window.location.href = fulfillmentUri;
    }
    if (isRefreshRequired) {
      window.location.reload();
    }
    return reset();
  };

  const getIcon = () => {
    if (isError) {
      return null;
    }
    return <Checkmark />;
  };

  if (!isError && !isSuccess) {
    return null;
  }

  return (
    <div>
      <AlertModal
        open={isOpen}
        icon={getIcon()}
        title={titleLabel}
        body={bodyContent ? htmlToReact(bodyContent) : ''}
        buttonLabel={btnLabel}
        onContinue={onContinue}
      />
    </div>
  );
};

PostOrderModalContainer.propTypes = {
  isOpen: bool,
  isError: bool,
  isSuccess: bool,
  bodyContent: string,
  reset: func.isRequired,
  fulfillmentUri: string.isRequired,
  btnLabel: string,
  titleLabel: string,
  isRefreshRequired: bool,
  showLoader: func.isRequired,
};

PostOrderModalContainer.defaultProps = {
  isOpen: false,
  isError: false,
  isSuccess: false,
  bodyContent: '',
  btnLabel: '',
  titleLabel: '',
  isRefreshRequired: false,
};

const mapStateToProps = state => {
  const { Enterprise, Configuration } = state;
  const { option_codes_saved: optionsSaved = [] } = Configuration;
  const { data, error = {} } = Enterprise;
  const { msg } = data || {};
  const isOpen = Boolean(Enterprise.data || Enterprise.error);
  const { errorMsg = 'Something went wrong', errorCode, errorTitle } = error || {};
  const isRefreshRequired = [INVALID_CONFIG, SESSION_EXPIRED].includes(errorCode);
  const errorBtnLabel = isRefreshRequired ? 'common.reload' : 'common.go_back';
  const isError = !!error && !data;
  const isSuccess = !error && !!data;
  const bodyContent = isError ? errorMsg : msg;
  const btnLabel = isError ? i18n(errorBtnLabel) : i18n('Enterprise.order_next_steps');
  const successTitleLabel = optionsSaved.length
    ? 'Enterprise.order_saved'
    : 'Enterprise.order_placed';
  const titleLabel = isError ? errorTitle || i18n('common.error') : i18n(successTitleLabel);

  return {
    isError,
    isSuccess,
    isOpen,
    bodyContent,
    isRefreshRequired,
    btnLabel,
    titleLabel,
    fulfillmentUri: getFulfillmentUri(state),
  };
};

const mapDispatchToProps = dispatch => ({
  reset: resetEnterpriseOrder(dispatch),
  showLoader: () => dispatch(startLoader()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostOrderModalContainer);
