import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { bool } from 'prop-types';
import { i18n } from 'utils';
import { MODAL_SMALL } from 'dictionary';
import ModalTrigger from '../../../components/ModalTrigger';

const DisclaimerLink = ({ showLink, displayInline }) => {
  if (!showLink) {
    return null;
  }
  return (
    <div
      className={cx('tds-text--caption', {
        'tds-display--inline': displayInline,
        'legal-disclaimer': !displayInline,
      })}
    >
      <ModalTrigger
        options={{
          props: {
            componentName: 'Disclaimer',
            props: {
              genericWrapper: true,
              size: MODAL_SMALL,
              title: i18n('SummaryPanel.disclaimers.viewDisclaimer'),
              classes: 'tds-link',
              containerCss: 'tds-display--inline',
            },
          },
        }}
      >
        {i18n('SummaryPanel.disclaimers.viewDisclaimer')}
      </ModalTrigger>
    </div>
  );
};

DisclaimerLink.propTypes = {
  showLink: bool,
  displayInline: bool,
};

DisclaimerLink.defaultProps = {
  showLink: false,
  displayInline: true,
};

function mapStateToProps(state) {
  const { ReviewDetails } = state;
  const { showDisclaimerLink } = ReviewDetails;

  return {
    showLink: showDisclaimerLink,
  };
}

export default connect(mapStateToProps)(DisclaimerLink);
