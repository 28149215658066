import React from 'react';
import { connect } from 'react-redux';
import { arrayOf, string, bool, shape, oneOfType } from 'prop-types';
import _get from 'lodash/get';
import classnames from 'classnames';
import { i18n, getDayMonthYear, getVehicleType, getShowVehicleDetailsBlock } from 'utils';
// import { VEHICLE_DETAILS_MODAL } from 'dictionary'; // not used yet but code in place to have modal

import InfoBlock from '../InfoBlock';
import TextLoader from '../TextLoader';

const VehicleDetailsBlock = ({
  dmsUrl,
  isLayoutMobile,
  showVehicleDetailsBlock,
  showVehicleInspection,
  vehicleDetails,
}) => {
  if (!showVehicleDetailsBlock || !showVehicleDetailsBlock?.length) {
    return null;
  }

  let buttonLabel = null;
  let buttonHandler = null;
  const { inspectionDocumentGuid } = vehicleDetails;

  if (showVehicleInspection && inspectionDocumentGuid) {
    buttonLabel = i18n('Inventory.vehicleDetails.viewDetailsBtnLabel');
    buttonHandler = () => {
      window.open(dmsUrl + inspectionDocumentGuid, '_blank').focus();
    };
  }

  return (
    <If condition={showVehicleDetailsBlock && showVehicleDetailsBlock.length}>
      <div
        className={classnames('vehicleDetails-block-container', {
          'tds--vertical_padding--6x': isLayoutMobile,
        })}
      >
        <InfoBlock
          name="vehicleDetails"
          titleSection={i18n('Inventory.vehicleDetails.label')}
          buttonLabel={buttonLabel}
          buttonClass="tds-btn--tertiary vehicleDetails-inspection--btn"
          centered
          buttonHandler={buttonHandler}
        >
          {showVehicleDetailsBlock?.map((detail, idx) => {
            const data = vehicleDetails[detail]
              ? {
                  title: i18n(`Inventory.vehicleDetails.${detail}`),
                  description: vehicleDetails[detail],
                }
              : null;

            if (data) {
              if (idx === 0) {
                return (
                  <TextLoader key={detail} data={data} className="text-loader--no-padding-top" />
                );
              }
              return <TextLoader key={detail} data={data} />;
            }
          })}
        </InfoBlock>
      </div>
    </If>
  );
};

VehicleDetailsBlock.propTypes = {
  dmsUrl: string,
  FactoryGatedDate: string,
  isLayoutMobile: bool,
  showVehicleDetailsBlock: oneOfType([bool, arrayOf(string)]),
  showVehicleInspection: bool,
  vehicleDetails: shape({}),
};

VehicleDetailsBlock.defaultProps = {
  dmsUrl: '',
  FactoryGatedDate: '',
  isLayoutMobile: false,
  showVehicleDetailsBlock: false,
  showVehicleInspection: false,
  vehicleDetails: {},
};

function mapStateToProps(state) {
  const product = _get(state, 'ReviewDetails.product.data', {});
  const { App } = state;
  const { countryCode, locale, isLayoutMobile } = App;
  const {
    FactoryGatedDate,
    InspectionDocumentGuid: inspectionDocumentGuid,
    Model,
    RegistrationDetails,
    TradeInType = '',
  } = product;
  const regDetails = RegistrationDetails || {};

  const {
    Country: registrationCountry,
    CurrentRegistrationDate: currentRegistrationDate,
    Expiration: expiration,
    firstRegistered,
    LicensePlateNumber: licensePlateNumber,
    RegistrationCount: registrationCount,
    RegistrationStatus: registrationStatus,
    RegistrationValue: registrationValue,
    State: registrationState,
  } = regDetails;

  const displayFields = _get(state, 'ReviewDetails.showVehicleDetailsBlock', null);
  const showVehicleDetailsBlock = getShowVehicleDetailsBlock(
    countryCode,
    displayFields,
    regDetails
  );

  const showVehicleInspection = _get(state, 'ReviewDetails.showVehicleInspection', false);
  const dmsUrl = _get(state, 'App.routes.getDMSFiles', '');

  const vehicleDetails = {
    currentRegistrationDate: getDayMonthYear(currentRegistrationDate, locale),
    expiration: getDayMonthYear(expiration, locale),
    firstRegistered: getDayMonthYear(firstRegistered, locale),
    inspectionDocumentGuid,
    licensePlateNumber,
    manufactureDate: getDayMonthYear(FactoryGatedDate, locale),
    registrationCount,
    registrationCountry,
    registrationState,
    registrationStatus,
    registrationValue,
    tradeInType: TradeInType ? TradeInType.toLowerCase() : '',
    vehicleType: i18n(`Inventory.vehicleDetails.${getVehicleType(Model)}`),
  };

  return {
    dmsUrl,
    isLayoutMobile,
    showVehicleDetailsBlock,
    showVehicleInspection,
    vehicleDetails,
  };
}

export default connect(mapStateToProps)(VehicleDetailsBlock);
