import React from 'react';
import { bool, string, oneOfType } from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import cx from 'classnames';
import { StatusMessage } from '@tesla/design-system-react';
import { htmlToReact } from 'utils';

const SummaryPageDisclaimer = ({ disclaimer, withoutPadding }) => {
  if (!disclaimer) {
    return null;
  }

  return (
    <div
      className={cx('tds-banner tds-scrim--white tds-text--start fsdDisclaimer--margin-bottom', {
        'tds--no_padding': withoutPadding,
      })}
    >
      <StatusMessage
        className="tds--fade-in"
        body={disclaimer ? htmlToReact(disclaimer) : ''}
        type="info"
        bodyTag="div"
        isEnclosed={false}
      />
    </div>
  );
};

SummaryPageDisclaimer.propTypes = {
  disclaimer: oneOfType([bool, string]),
  withoutPadding: bool,
};

SummaryPageDisclaimer.defaultProps = {
  disclaimer: false,
  withoutPadding: false,
};

function mapStateToProps(state) {
  const disclaimer = _get(state, 'CustomGroups.AUTOPILOT_PACKAGE.summary_page_disclaimer', false);
  return {
    disclaimer,
  };
}

export default connect(mapStateToProps)(SummaryPageDisclaimer);
