import React, { Component, Suspense } from 'react';
import { shape, string, oneOfType, func } from 'prop-types';
import { lazy } from '../Lazy';

class DynamicComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      SectionComponent: null,
    };
  }

  componentDidMount() {
    const { component } = this.props;
    this.setState({
      SectionComponent: lazy(component),
    });
  }

  render() {
    const { componentKey, placeholder, component, ...rest } = this.props;
    const { SectionComponent } = this.state;

    return (
      <>
        <Suspense fallback={placeholder}>
          {SectionComponent && <SectionComponent key={componentKey} {...rest} />}
        </Suspense>
      </>
    );
  }
}

DynamicComponent.propTypes = {
  component: oneOfType([shape(), func]).isRequired,
  placeholder: shape().isRequired,
  componentKey: string.isRequired,
};

DynamicComponent.defaultProps = {};

export default DynamicComponent;
