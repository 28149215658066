import React from 'react';
import _get from 'lodash/get';
import cx from 'classnames';
import { bool, string } from 'prop-types';
import { connect } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import {
  getModelCode,
  isAccessoriesSelected,
  isInventory as getIsInventory,
  getPurchasePriceTotal,
  getIfHideLexiconGroup,
} from 'selectors';
import { postPriceAcceptance } from 'actions';
import { EXPERIMENT_DELIVERY_LOCATION_OFF } from 'dictionary';
import PaymentSummaryRepresentation from './representation.js';

const PaymentSummary = props => {
  const { ref, inView, entry } = useInView({
    triggerOnce: true,
    rootMargin: '0px 0px 250px 0px',
  });

  const { hideLexiconGroupAfterOrderGenerated } = props;
  return (
    <div
      className={cx('cf-summary_container', {
        'cf-summary_container--loading': !inView,
        'tds-o-margin-top--8x': hideLexiconGroupAfterOrderGenerated,
      })}
      ref={ref}
    >
      <If condition={inView}>
        <PaymentSummaryRepresentation {...props} />
      </If>
    </div>
  );
};

function mapStateToProps(state) {
  const {
    locale,
    isDm,
    enableCyberpunk,
    isLayoutMobileAdjusted: isMobile,
    isDeliverySelectionEnabled,
    ggConf,
    isSaveDesignLiteEnabled,
    isEarlyAvailabilityEnabled,
    isShowFinanceToggle,
    isShowDeliveryClaim,
  } = state?.App || {};
  const { optimizelyExpIds = [], canModifyOrder } = state?.ApplicationFlow || {};
  const { showPaymentOverview } = state?.Payment || {};

  const isInventory = getIsInventory(state);
  const hideLexiconGroupAfterOrderGenerated = getIfHideLexiconGroup(state);

  const modelCode = getModelCode(state);
  const model = _get(state, `OMS.lexicon.options.${modelCode}`, {});
  const modelName = _get(model, 'common_name', '') || _get(model, 'long_name', '');
  const {
    ReviewDetails: { reviewRefactor = false },
  } = state;

  const showFinanceToggle = isShowFinanceToggle && isInventory;
  return {
    locale,
    isDm,
    enableCyberpunk,
    isMobile,
    isDeliverySelectionEnabled,
    showPaymentOverview,
    modelName,
    ggConf,
    loadGoogleScript: !!(isAccessoriesSelected(state) && Object.values(ggConf)?.length),
    showFinanceToggle,
    isSaveDesignLiteEnabled,
    isDownloadPDFEnabled: window?.tesla?.download_pdf_feature_enabled,
    canModifyOrder,
    isEarlyAvailabilityEnabled:
      isEarlyAvailabilityEnabled && !optimizelyExpIds?.includes(EXPERIMENT_DELIVERY_LOCATION_OFF),
    reviewRefactor,
    estPurchasePrice: getPurchasePriceTotal(state),
    expandAfterSubmit: hideLexiconGroupAfterOrderGenerated,
    rn: _get(state, 'Configuration.rn', ''),
    hideLexiconGroupAfterOrderGenerated,
    isShowDeliveryClaim,
  };
}

PaymentSummary.propTypes = {
  isDm: bool,
  enableCyberpunk: bool,
  isMobile: bool,
  isDeliverySelectionEnabled: bool,
  modelName: string,
  showPaymentOverview: bool,
  showFinanceToggle: bool,
  reviewRefactor: bool,
  expandAfterSubmit: bool,
  rn: string,
  hideLexiconGroupAfterOrderGenerated: bool,
  isShowDeliveryClaim: bool,
};

PaymentSummary.defaultProps = {
  isDm: false,
  enableCyberpunk: false,
  isMobile: false,
  isDeliverySelectionEnabled: false,
  modelName: '',
  showPaymentOverview: true,
  showFinanceToggle: false,
  reviewRefactor: false,
  expandAfterSubmit: false,
  rn: '',
  hideLexiconGroupAfterOrderGenerated: false,
  isShowDeliveryClaim: false,
};

export default connect(mapStateToProps, { postPriceAcceptance })(PaymentSummary);
