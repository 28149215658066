import _get from 'lodash/get';
import { request } from 'utils/requestAgent';
import { map, filter } from 'rxjs/operators';
import Analytics from 'analytics';
import {
  LOCATION_CHANGED,
  GEOIP_LOCATION_CHANGED,
  COOKIE_LOCATION,
  LOCATION_SOURCE_CHANGE,
  LOCATION_COMPONENT_CHANGE,
} from 'dictionary';
import { validateUserZip } from 'utils';

export const updateLocation = (action$) =>
  action$.pipe(
    filter(action => [
        GEOIP_LOCATION_CHANGED,
        COOKIE_LOCATION
      ].includes(action.type)
    ),
    map(action => {
      const { location } = action;
      return {
        type: LOCATION_CHANGED,
        location,
      }
    })
  )

export function updateLocationSource(sourceName, sourceData) {
  return {
    type: LOCATION_SOURCE_CHANGE,
    sourceName,
    sourceData,
  };
}

export function updateLocationComponent(componentName, componentData) {
  return {
    type: LOCATION_COMPONENT_CHANGE,
    componentName,
    componentData,
  };
}

export function getUserLocation() {
  function parseCookie() {
    var oldCookie = document.cookie.split(';'),
      data = null;
    for (var i = 0; i < oldCookie.length; i++) {
      var cookie = oldCookie[i];
      if (cookie.indexOf('ip_info') !== -1) {
        var cookieVal = cookie.split('=')[1];
        if (cookieVal) {
          try {
            data = JSON.parse(decodeURIComponent(cookieVal));
          } catch (e) {
            console.warn('parseCookie:JSON.parse:err', e);
          }
        }
        break;
      }
    }
    return data;
  }

  function mapLocationFromCookie(cookieObj = null, countryCode) {
    let location = cookieObj || parseCookie();
    const cookieCountryCode = _get(location, 'countryCode', _get(location, 'country_code', ''));
    location = cookieCountryCode === countryCode ? location : null;

    const mapped_location = {
      ip: _get(location, 'ip', null),
      location: {
        latitude: _get(location, 'location.latitude', _get(location, 'location.lat', '')),
        longitude: _get(location, 'location.longitude', _get(location, 'location.lng', '')),
      },
      region: {
        longName: _get(location, 'region.longName', _get(location, 'state', _get(location, 'region', null))),
        regionCode: _get(location, 'region.regionCode', _get(location, 'state_code', _get(location, 'regionCode', null))),
      },
      city: _get(location, 'city', ''),
      country: _get(location, 'country', ''),
      countryCode: _get(location, 'countryCode', _get(location, 'country_code', '')),
      postalCode:  _get(location, 'postalCode', _get(location, 'postal', '')),
    };

    validateUserZip(mapped_location);

    return {
      type: COOKIE_LOCATION,
      location: mapped_location,
    };
  }

  return async (dispatch, getState) => {
    const state = getState();
    const callLocationsApi = _get(state, 'Location.callLocationsApi', true);
    const countryCode = _get(state, 'App.countryCode');

    if (!callLocationsApi) {
      return;
    }
    // Check if preferred address should be used
    if (state?.App?.isPreferredAddressEnabled) {
      return;
    }
    // Check if cookie exists
    const ipInfoCookie = parseCookie();
    if (_get(ipInfoCookie, 'ip', null) && countryCode === ipInfoCookie?.countryCode) {
      return dispatch(mapLocationFromCookie(ipInfoCookie, countryCode));
    }

    request
      .get(_get(state, 'App.env.teslaGeoip.url'))
      .timeout(3000)
      .end(function(err, res) {
        if (!err) {
          try {
            const parsedData = JSON.parse(res.text);
            const parsedDataCountryCode = _get(parsedData, 'country.iso_code', '');
            const geoData = parsedDataCountryCode === countryCode ? parsedData : null;
            const subdivision = _get(geoData, 'subdivisions[0]');
            const region = subdivision
              ? {
                  longName: subdivision.names.en,
                  regionCode: subdivision.iso_code,
                }
              : null;

            const location = {
              ip: geoData?.ip,
              location: {
                latitude: _get(geoData, 'location.latitude', ''),
                longitude: _get(geoData, 'location.longitude', ''),
              },
              region: region,
              city: _get(geoData, 'city.names.en', ''),
              country: _get(geoData, 'country.names.en', ''),
              countryCode: _get(geoData, 'country.iso_code', ''),
              postalCode: _get(geoData, 'postal.code', ''),
            };

            validateUserZip(location);

            return dispatch({
              type: GEOIP_LOCATION_CHANGED,
              location,
            });
          } catch (e) {
            console.warn('fetchUserLocation:JSON.parse:err ', e);
            return dispatch(mapLocationFromCookie(null, countryCode));
          }
        } else {
          console.warn('fetchUserLocation:JSON.parse:err ', err);
          return dispatch(mapLocationFromCookie(null, countryCode));
        }
      });
  };
}
