import _get from 'lodash/get';
import { filter, map } from 'rxjs/operators';
import { getCurrentModel } from 'utils';
import { setSummaryPanelDownPayment } from 'actions';
import { SET_ALD_DOWN_PAYMENT_PERCENT, SUMMARY_PANEL_TAB_CHANGE } from 'dictionary';

export const setAldDownPaymentPercent = downPaymentPercent => ({
  type: SET_ALD_DOWN_PAYMENT_PERCENT,
  downPaymentPercent,
});

export const updateAldProduct = action$ =>
  action$.pipe(
    filter(action => [SUMMARY_PANEL_TAB_CHANGE].includes(action.type)),
    map(({ tabID }) => dispatch => {
      if (/operational_lease/.test(tabID)) {
        const currentModel = getCurrentModel();
        const leaseProducts = _get(
          window.tesla,
          `DSServices["Lease.${currentModel}.${currentModel}"].lease`,
          []
        );
        const aldProduct = leaseProducts.find(({ id }) => `lease.${id}` === tabID);
        const downPaymentPercent = _get(aldProduct, 'variables.downPaymentPercent');
        const downPaymentValue = _get(aldProduct, 'variables.downPayment');
        const downPaymentMatrix = _get(aldProduct, 'variables.downPaymentMatrix', []);
        const defaultDownPayment = downPaymentMatrix.includes(downPaymentValue)
          ? downPaymentValue
          : Math.max(...downPaymentMatrix);

        dispatch(setAldDownPaymentPercent(downPaymentPercent));
        if (downPaymentMatrix.length > 0) {
          dispatch(setSummaryPanelDownPayment(defaultDownPayment));
        }
      }
    }));
