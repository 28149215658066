import React from 'react';
import { connect } from 'react-redux';
import { string, shape, bool } from 'prop-types';
import _get from 'lodash/get';
import cx from 'classnames';

import AssetLoader from '../AssetLoader';
import { getTrimOption, getModelName } from 'selectors';

const ConfigAssetLoader = ({ asset, view, modelName, trim, classes }) => {
  let newAsset = asset;

  if (view) {
    newAsset = { ...asset, view };
  }

  return (
    <div className={cx({ [classes]: classes })}>
      <div className="tds-flex-item">
        <h2 className="tds--vertical_padding--small">{modelName}</h2>
        <div className='tds--vertical_padding-bottom'>{trim}</div>
      </div>
      <div className="tds-flex-item">
        <AssetLoader asset={newAsset} className="group--main-content--asset" />
      </div>
    </div>
  );
};

ConfigAssetLoader.defaultProps = {
  view: false,
  asset: {},
  modelName: '',
  trim: '',
  classes: '',
};

ConfigAssetLoader.propTypes = {
  view: bool,
  asset: shape({}),
  modelName: string,
  trim: string,
  classes: string,
};

function mapStateToProps(state, { asset = {}, path, assetPath }) {
  const trimOption = getTrimOption(state);
  const modelName = getModelName(state);
  return {
    view: _get(state, path, false),
    asset: { ...asset, ..._get(state, assetPath, {}) },
    modelName,
    trim: _get(trimOption, 'long_name', '').replace(modelName, ''),
  };
}

export default connect(mapStateToProps)(ConfigAssetLoader);
