const InitialState = {
    object: {
        sources: {
            // data set by geo-ip service
            userSelected: {
                region: null
            },

            // geo ip set or user selected
            geoIp: {
                ip: null,
                location: {
                    latitude:  null,
                    longitude: null,
                },
                region: null,
                city: null,
                country: null,
                countryCode: null,
                postalCode: null
            },

            // Pricebook market
            pricebook: {
                market: null,
            },

            reservation: {
                postalCode: null
            }
        },

        components: {

            inventory: {
                regionCode: null,
                countryCode: null,
                city: null,
                postalCode: null
            },
            summaryPanel: {
                regionCode: null,
                regionName: null,
                countryCode: null
            }
        },
        callLocationsApi: true,
        isLocationChecked: false,
    }
}

export default InitialState