import { NON_TESLA, TESLA } from 'dictionary';
import { isUserLoggedIn } from 'utils';

const InitialState = {
    object: {
        vehicleType: isUserLoggedIn() ? TESLA: NON_TESLA,
        vin: '',
        vehicleDetails: {},
        contactDetails: {},
        step: '',
        vehiclesByYear: {},
        vehicleInfoByVin: {},
        vehiclesByUserId: [],
        tradeInAmount: undefined,
        finplatInputs: {},
    },
  };
  
  export default InitialState;
  