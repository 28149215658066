import React from 'react';
import { string, shape } from 'prop-types';
import classnames from 'classnames';
import TextLoader from '../TextLoader';

/* Group disclaimer component */
const Disclaimer = ({ group, field, component, classes }) => {
  if (!group && group.field) {
    return null;
  }
  const { code } = group;
  return (
    <TextLoader
      data={group}
      tag={{ component }}
      field={field}
      key={`group-disclaimer-${code}-${field}`}
      className={classnames(`tds-text--caption tds-o--maxinline--none text-loader--${field}`, {
        [classes]: classes,
      })}
    />
  );
};

Disclaimer.propTypes = {
  group: shape({}).isRequired,
  field: string,
  component: string,
  classes: string,
};

Disclaimer.defaultProps = {
  field: 'disclaimer',
  component: 'p',
  classes: '',
};

export default Disclaimer;
