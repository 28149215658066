import { useState, useEffect } from 'react';
const libs = [];

export default function useScript(url, name, type = 'script', cb = null, timeout = 0) {
  const [lib, setLib] = useState({});

  useEffect(() => {
    if (libs[`${name}:${type}`]) {
      return;
    }
    let script = '';
    switch (type) {
      case 'style':
        script = document.createElement('link');
        script.rel = 'stylesheet';
        script.type = 'text/css';
        script.href = url;
        script.media = 'all';
        break;
      case 'script':
      default:
        script = document.createElement('script');
        script.src = url;
        script.async = true;
        break;
    }
    setTimeout(() => {
      script.onload = () => {
        if (typeof cb === 'function') {
          cb();
        }
        libs[`${name}:${type}`] = url;
        setLib({ [name]: window[name] });
      };
  
      document.body.appendChild(script);
    }, timeout)

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);

  return lib;
}
