import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { string, shape, bool, arrayOf, func } from 'prop-types';
import { getCustomPricingIndicatorV2 } from 'utils';
import { PRICE_INDICATOR_HIDDEN } from 'dictionary';
import TextLoader from '../TextLoader';
import ExVatDisclaimer from './ExVatDisclaimer';
import { formatCurrency } from '@tesla/coin-common-components';
import { getPricePostfix } from 'selectors';

/* FormattedPrice price component */
const FormattedPrice = ({
  group,
  configuration,
  priceData,
  includeFeesinBatteryCalculation,
  CustomGroups,
  classes,
  tag,
  isCentered,
  hideIndicatorOnZeroPrice,
  pricePostfix,
  calcParams,
  isCoinReloaded,
}) => {
  if (!group) {
    return null;
  }

  const getIndicatorForOption = useMemo(() => {
    return getCustomPricingIndicatorV2(
      [group],
      configuration,
      priceData,
      includeFeesinBatteryCalculation,
      false,
      CustomGroups,
      false,
      hideIndicatorOnZeroPrice,
      pricePostfix
    );
  }, [configuration, group.code, calcParams]);

  if (hideIndicatorOnZeroPrice && getIndicatorForOption === PRICE_INDICATOR_HIDDEN) {
    return null;
  }

  if (getIndicatorForOption) {
    return (
      <p
        className={cx('text-loader--content tds-text--medium tds-text_color--black', {
          'tds-text--center': isCentered,
          [` ${classes}`]: classes,
        })}
      >
        {getIndicatorForOption}
      </p>
    );
  }

  return (
    <>
      <TextLoader
        data={{
          ...group,
          formattedPrice:
            group.price > 0 ? `${group.formattedPrice}${pricePostfix}` : group.formattedPrice,
        }}
        field="formattedPrice"
        tag={tag}
        className={cx('tds-text--medium tds-text_color--black formatted-price', {
          'tds-text--center': isCentered,
          [` ${classes}`]: classes,
        })}
      />
      <If condition={group.extraPrice}>
        <TextLoader
          data={{
            ...group,
            formattedPrice: `${formatCurrency(
              group.extraPrice.amount,
              { currency: group.extraPrice.currency, useDynamicRounding: true })}${pricePostfix}`,
          }}
          field="formattedPrice"
          tag={tag}
          className={cx('formatted-price', {
            'tds-text--center': isCentered,
            [` ${classes}`]: classes,
          })}
        />
      </If>
      {!isCoinReloaded && <ExVatDisclaimer />}
    </>
  );
};

function mapStateToProps(state, ownProps) {
  const { CustomGroups, Pricing, Configuration, FinancingOptions, App } = state;
  const { postfix = '', group } = ownProps || {};
  const { isLayoutMobile, pricePostfixGroups = [], isCoinReloaded = false } = App;
  const { option_codes } = Configuration;
  const { includeFeesinBatteryCalculation = false } = FinancingOptions;
  const { calculatorParameters: calcParams, calculatorResult } = Pricing || {};
  const { apiResultsPerTrim, apiResults } = calculatorResult?.data || {};

  return {
    CustomGroups,
    priceData: {
      ...apiResults,
      apiResultsPerTrim,
    },
    configuration: option_codes,
    includeFeesinBatteryCalculation,
    isLayoutMobile,
    pricePostfix: !pricePostfixGroups.length || pricePostfixGroups.includes(group) ? getPricePostfix(state) : '',
    calcParams,
    isCoinReloaded,
  };
}

FormattedPrice.propTypes = {
  group: shape({
    selected: bool,
    code: string,
    name: string,
    formattedPrice: string,
  }).isRequired,
  configuration: arrayOf(string).isRequired,
  priceData: shape({}),
  includeFeesinBatteryCalculation: bool,
  CustomGroups: shape({}).isRequired,
  recalculatePrice: func,
  tag: shape({ component: string }),
  classes: string,
  isCentered: bool,
  hideIndicatorOnZeroPrice: bool,
  pricePostfix: string,
  isCoinReloaded: bool.isRequired,
};

FormattedPrice.defaultProps = {
  includeFeesinBatteryCalculation: false,
  priceData: {},
  recalculatePrice: () => {},
  classes: '',
  tag: { component: 'p' },
  isCentered: true,
  hideIndicatorOnZeroPrice: false,
  pricePostfix: '',
};

export default connect(mapStateToProps)(FormattedPrice);
