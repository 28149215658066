import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bool, string, func, shape } from 'prop-types';
import { i18n, firstLastNameValidator, emailValidator, phoneValidator } from 'utils';
import { Form, Input, PhoneInput, RadioGroup } from '@tesla/informed-tds';
import _upperFirst from 'lodash/upperFirst';
import _debounce from 'lodash/debounce';
import { FormLayout } from '@tesla/design-system-react';
import { UI_DATA_IDS } from 'dictionary';
import { updateDeliveryContact } from 'actions';

const DeliveryContact = ({
  isIntlPhoneInputEnabled,
  market,
  locale,
  setDeliveryContact,
  initialValues,
  isContactDetailsValid,
}) => {
  const formApiRef = useRef();

  const nameValidator = value => {
    if (value && firstLastNameValidator(value, market)) {
      return;
    } else {
      return i18n('common.errors__firstLastNameNotValid');
    }
  };

  const onChange = formState => {
    const { values, valid, dirty } = formState;
    if (dirty && valid) {
      setDeliveryContact({ ...values, isDeliveryContactValid: valid });
    } else if (!valid && isContactDetailsValid) {
      setDeliveryContact({ isDeliveryContactValid: false });
    }
  };

  const debouncedOnChange = _debounce(onChange, 500);

  return (
    <div className="delivery-contact--section" id="delivery-contact">
      <h4>{i18n('DeliveryLocation.delivery_contact')}</h4>
      <Form
        formApiRef={formApiRef}
        className="tds--vertical_padding"
        validateOnMount
        id="deliveryContact"
        errorMessage={{
          required: _upperFirst(i18n('common.errors__required')),
        }}
        onChange={debouncedOnChange}
        initialValues={initialValues}
      >
        <FormLayout>
          <RadioGroup
            name="isDriver"
            id="isDriver"
            label={i18n('Review.isDriver_label')}
            defaultValue={true}
            options={[
              { value: true, label: i18n('common.yes') },
              { value: false, label: i18n('common.no') },
            ]}
            layout="horizontal"
          />
          <Input
            name="firstName"
            id="firstName"
            label={i18n('Review.account_firstname_label')}
            required
            maxLength={30}
            validate={nameValidator}
            validateOn="change"
            showErrorIfDirty
          />
          <Input
            name="lastName"
            id="lastName"
            label={i18n('Review.account_lastname_label')}
            required
            maxLength={30}
            validate={nameValidator}
            validateOn="change"
            showErrorIfDirty
          />
          <Input
            name="email"
            id="email"
            label={i18n('Review.account_email_label')}
            required
            validate={value => emailValidator(value, market)}
            validateOn="change"
            showErrorIfDirty
          />
          <Choose>
            <When condition={isIntlPhoneInputEnabled}>
              <PhoneInput
                name="phoneNumber"
                id="phoneNumber"
                label={i18n('Review.account_phonenumber_label')}
                locale={locale}
                required
                data-id={UI_DATA_IDS?.accountSection?.phoneNumberInput}
                defaultValue={{ country: market }}
                className="tds-o-form-phone-input-wrap"
                autocomplete="phone"
                showErrorIfDirty
                validateOn="change"
                validate={({ number }) => {
                  if (!number) {
                    return i18n('common.errors__phonenumberNotValid');
                  }
                }}
              />
            </When>
            <Otherwise>
              <Input
                name="phoneNumber"
                id="phoneNumber"
                label={i18n('Review.account_phonenumber_label')}
                validate={value => phoneValidator(value, market)}
                maxLength={50}
                validateOn="change"
                showErrorIfDirty
                required
                data-id={UI_DATA_IDS?.accountSection?.phoneNumberInput}
                className="tds-o-form-input-wrap"
              />
            </Otherwise>
          </Choose>
        </FormLayout>
      </Form>
    </div>
  );
};

DeliveryContact.propTypes = {
  isIntlPhoneInputEnabled: bool,
  market: string,
  locale: string,
  setDeliveryContact: func.isRequired,
  initialValues: shape({}),
};

DeliveryContact.defaultProps = {
  isIntlPhoneInputEnabled: false,
  market: '',
  locale: '',
  initialValues: {},
};

const mapStateToProps = state => {
  const { DeliveryContact = {}, isIntlPhoneInputEnabled = false } = state?.ReviewDetails;

  const { oms_params: { market } = {} } = state.OMS;
  const { locale } = state.App;
  const { isDeliveryContactValid } = DeliveryContact || {};

  return {
    isIntlPhoneInputEnabled,
    market,
    locale,
    initialValues: DeliveryContact || {},
    isContactDetailsValid: isDeliveryContactValid,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setDeliveryContact: data => {
      dispatch(updateDeliveryContact(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryContact);
