import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { bool, string } from 'prop-types';
import { i18n, htmlToReact, constructUrl } from 'utils';

const RefundPolicyLink = ({
  isEnabled,
  showRefundPolicyi18nKey,
  sibling,
  locale,
  displayInline,
}) => {
  if (!isEnabled || !showRefundPolicyi18nKey) {
    return null;
  }

  return (
    <span
      className={cx('modifier--tds-link', {
        'tds-display--inline tds--horizontal_margin-5': displayInline,
      })}
    >
      {htmlToReact(
        i18n(showRefundPolicyi18nKey, {
          LINK_TARGET: '_blank',
          RETURN_POLICY_URL: constructUrl(
            'support/tesla-return-policy?redirect=no',
            sibling,
            locale
          ),
        })
      )}
    </span>
  );
};

RefundPolicyLink.propTypes = {
  isEnabled: bool,
  showRefundPolicyi18nKey: string,
  locale: string.isRequired,
  sibling: string.isRequired,
  displayInline: bool,
};

RefundPolicyLink.defaultProps = {
  isEnabled: false,
  showRefundPolicyi18nKey: null,
  displayInline: true,
};

function mapStateToProps(state) {
  const { App, ReviewDetails, OMS } = state;
  const { showRefundPolicyDisclaimer, showRefundPolicy, sibling, locale } = App;
  const { product = {}, refundPolicySource = {} } = ReviewDetails;
  const { oms_params = {} } = OMS;
  const { model: modelCode } = oms_params;
  const { isUsedInventory = false } = product || {};

  const isInventory = !!product;
  const { source: refundPolicySrc } = refundPolicySource;
  const refundPolicyStr = refundPolicySrc
    ? `orderRefundPolicy.${refundPolicySrc}.${modelCode}`
    : `orderRefundPolicy.${modelCode}`;
  const isRefundPolicyEnabled =
    (isInventory && refundPolicySrc) || isUsedInventory ? false : showRefundPolicy;

  const showRefundPolicyi18nKey = isInventory
    ? 'SummaryPanel.disclaimers.inventoryorderRefundPolicy'
    : `SummaryPanel.disclaimers.${refundPolicyStr}`;

  return {
    isEnabled: !showRefundPolicyDisclaimer && !isRefundPolicyEnabled,
    sibling,
    locale,
    showRefundPolicyi18nKey,
  };
}

export default connect(mapStateToProps)(RefundPolicyLink);
