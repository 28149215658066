import smoothscroll from 'smoothscroll-polyfill';
import {
  ERROR_DESIGN_UNAVAILABLE,
  ERROR_EARLY_DELIVERY_DESIGN_UNAVAILABLE,
  ERROR_EARLY_PICKUP_UNAVAILABLE,
  ERROR_ONLY_SIMILAR_DESIGN_AVAILABLE,
  ERROR_DELIVERY_LOCATION_CHANGED,
  PICKUP_LOCATION_OUT_OF_STOCK,
  PAYMENT_FAILED,
} from 'dictionary';
import { i18n, findElemTop } from 'utils';

// eslint-disable-next-line import/prefer-default-export
export function smoothScrollToError(errorCode, isDesktop = true) {
  smoothscroll.polyfill();
  let errorEl = document.getElementsByClassName('alerts-container')[0];
  if (errorCode === PAYMENT_FAILED) {
    errorEl = document.getElementsByClassName('payment-website')[0] || document.getElementsByClassName('error-scroll-container')[0];
  } else if (errorCode === PICKUP_LOCATION_OUT_OF_STOCK) {
    errorEl =
      document.getElementsByClassName('pickup_location')[0] ??
      document.getElementsByClassName('alerts-container')[0];
  }
  if (!errorEl) {
    return;
  }
  let targetPos = findElemTop(errorEl);
  if (!isDesktop) {
    const fixedAsset = document.getElementsByClassName('cf-asset-wrapper--fixed')[0];
    if (fixedAsset) {
      const { height = 0 } = fixedAsset.getBoundingClientRect();
      targetPos -= height;
      // Browser sniffing is bad, but Safari behaves so different we need a different approach
      const isSafari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);
      if (isSafari) {
        // This is needed to compensate for the fixed header in Safari on iPhone/iPad
        // In Chrome the old approach (scrollIntoView) worked, but in Safari this kept
        // the alerts container behind the fixed header. Weird browser inconsistency :(
        targetPos -= 60;
      }
    }
    window.scroll({ top: targetPos, behavior: 'smooth' });
  } else if (errorEl) {
    errorEl.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }
}

export const getDesignAlerts = state => {
  const { error, swapConfig: { VrlCustomName: vrl = '' } = {} } =
    state?.ReviewDetails?.vehicleDesign || {};
  const alerts = [];
  switch (error) {
    case ERROR_DESIGN_UNAVAILABLE:
      alerts.push({
        header: i18n('EarlyDelivery.error_design_unavailable'),
        message: i18n('EarlyDelivery.select_another_design'),
        type: 'warning',
      });
      break;
    case ERROR_EARLY_DELIVERY_DESIGN_UNAVAILABLE:
      alerts.push({
        header: i18n('EarlyDelivery.error_early_delivery_unavailable'),
        message: i18n('EarlyDelivery.continue_with_custom_design'),
        type: 'warning',
      });
      break;
    case ERROR_EARLY_PICKUP_UNAVAILABLE:
      alerts.push({
        header: i18n('EarlyDelivery.error_early_pickup_unavailable'),
        message: i18n('EarlyDelivery.early_pickup_unavailable'),
        type: 'warning',
      });
      break;
    case ERROR_ONLY_SIMILAR_DESIGN_AVAILABLE:
      alerts.push({
        message: i18n('EarlyDelivery.only_similar_design_available'),
        type: 'warning',
      });
      break;
    case ERROR_DELIVERY_LOCATION_CHANGED:
      alerts.push({
        header: i18n('EarlyDelivery.location_changed_header'),
        message: i18n('EarlyDelivery.location_changed_message', {
          LOCATION: vrl,
        }),
        type: 'warning',
      });
      break;
    default:
      break;
  }
  return alerts;
};
