import React, { Suspense } from 'react';
import _reduce from 'lodash/reduce';
import {
  getAccessoriesFromKeys,
  getAccessoriesModalKeys,
  getAccessoriesAssetsBase,
} from '../../../common/selectors';
import { htmlToReact } from 'utils';
import { ACCESSORIES_OPTION_MAP } from 'dictionary';
import AccessoryToggleButton from '../FeatureHighlight/AccessoryToggleButton';
import { lazyWithPreload } from '../../Lazy';

const FeatureHighlight = lazyWithPreload(() =>
  import(/* webpackChunkName: "feature-highlight" */ '../FeatureHighlight/')
);

const htmlDecode = x => {
  var node = document.createElement('div');
  node.innerHTML = x;
  return node.childNodes.length === 0 ? '' : node.childNodes[0].nodeValue;
};

export default state => {
  const modalAccessoriesKeys = getAccessoriesModalKeys(state);
  const accessories = modalAccessoriesKeys.map(getAccessoriesFromKeys(state));
  const assetsBase = getAccessoriesAssetsBase(state);
  const configOptions = state?.Configuration?.option_codes || [];

  const panels = _reduce(accessories, (res, accessory) => {
    const { name, productMetaTag, description, options } = accessory;

    const applicableOpts = options?.find(opt => {
      const optionCode = ACCESSORIES_OPTION_MAP?.[opt?.sizeOption?.code] || '';
      return !optionCode || configOptions?.includes(optionCode);
    });
    if (typeof applicableOpts !== 'undefined') {
      res.push({
        id: name,
        label: name,
        component: (
          <Suspense fallback={<></>}>
            <FeatureHighlight
              title={name}
              label={name}
              asset={{
                url: `${assetsBase}${productMetaTag.image}`,
                type: 'image',
                absolute: true,
                ratio: 'slice',
              }}
              actionComponent={<AccessoryToggleButton accessory={accessory} />}
            >
              {htmlToReact(htmlDecode(description))}
            </FeatureHighlight>
          </Suspense>
        ),
      });
    }
    return res;
  }, []);

  return panels;
};
