import React from 'react';
import { string, array, object } from 'prop-types';
import cx from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import TextLoader from '../TextLoader';
import AssetLoader from '../AssetLoader';

const CompareFeatures = ({ classes, items, group }) => {
  const renderAssets = value => {
    const assets = Array.isArray(value) ? value : [value];
    return (
      <div className="compare-features--asset tds-o-flex-direction--row">
        {assets.map((asset, index) => {
          const { classes = '' } = asset || {};
          return <AssetLoader key={index} asset={asset} className={classes} />;
        })}
      </div>
    );
  };

  return (
    <div className={cx({ [classes]: classes })}>
      {items.map((i, index) => {
        const { field, items: element = [], classes } = i || {};
        const copy = group?.[field] || {};
        const isLastIndex = !!(items?.length - 1 === index);
        let subtitle = copy.title || copy;

        return (
          <div
            className={cx({
              'tds-o-border-bottom-grey60 tds-o-padding_bottom-24': !isLastIndex,
              [classes]: classes,
            })}
          >
            <If condition={subtitle?.length}>
              <TextLoader data={subtitle} className="tds-text--h5 tds--vertical_padding" />
            </If>
            <div className="compare-features--item">
              {element.map((item, index) => {
                const { asset, field } = item || {};
                const fieldValue = group?.[field];
                return (
                  <div className="tds-layout-item">
                    <If condition={fieldValue?.title}>
                      <TextLoader
                        data={fieldValue}
                        field="title"
                        className="tds-text--h5 tds--vertical_padding"
                      />
                    </If>
                    <If condition={!_isEmpty(asset)}>{renderAssets(asset)}</If>
                    <div
                      className={cx('tds-text--contrast-low', {
                        'tds--padding_top-8': !_isEmpty(asset),
                      })}
                    >
                      <Choose>
                        <When condition={typeof fieldValue === 'object' && fieldValue?.description}>
                          <TextLoader
                            key={`description:${index}`}
                            data={fieldValue}
                            field="description"
                          />
                        </When>
                        <When condition={Array.isArray(fieldValue)}>
                          <TextLoader key={`description:${index}`} data={fieldValue} />
                        </When>
                        <Otherwise>
                          <TextLoader key={`description:${index}`} data={fieldValue} />
                        </Otherwise>
                      </Choose>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

CompareFeatures.propTypes = {
  classes: string,
  items: array,
  group: object,
};

CompareFeatures.defaultProps = {
  classes: '',
  items: [],
  group: {},
};

export default CompareFeatures;
