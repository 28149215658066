import React from 'react';
import { connect } from 'react-redux';
import {
  openModal,
  closeModal,
  openFeatureModal,
  closeFeatureModal,
  openFinanceModal,
  closeFinanceModal,
  selectTab,
} from 'actions';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import ModalTriggerTemplate from './template';

const ModalTrigger = props => {
  return <ModalTriggerTemplate {...props} />;
};

const mapStateToProps = (state, ownProps) => {
  const { analyticsInteraction } = ownProps;
  const { FeatureListModal, Modal, FinanceModal } = state;

  const selected = _get(state, 'Modal.selected', {});
  const modelCode = _get(state, 'OMS.oms_params.model');

  const selected_props = _get(selected, 'props', {});
  const own_prop = _get(ownProps, 'options.props.props', {});
  const res = _isEqual(selected_props, own_prop);
  const { classes = '', containerCss } = own_prop;
  const isInventory = _get(state, 'ReviewDetails.product.isInventory');
  const tabIndex = _get(ownProps, 'options.props.tabIndex');
  const gioEventName = _get(ownProps, 'data-gio-eventname', undefined);

  return {
    modelCode,
    analyticsInteraction,
    isModalOpen: !!(
      Modal.open &&
      selected &&
      selected.component === _get(ownProps, 'options.props.componentName', false) &&
      res
    ),
    isComponentOpen:
      selected && selected.component === _get(ownProps, 'options.props.componentName', false),
    areSameProps: res,
    isCarouselOpen: FeatureListModal.isCarouselOpen,
    isFinanceModalOpen: FinanceModal.isCarouselOpen,
    currentTriggerId: FinanceModal.triggerId,
    classes,
    containerCss,
    isInventory,
    isFeatureListModalOpen:
      FeatureListModal.isCarouselOpen && FeatureListModal.groupName === ownProps.groupName,
    own_prop,
    tabIndex,
    gioEventName,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openModal: ({ componentName, props }) => dispatch(openModal(componentName, props)),
    closeModal: () => dispatch(closeModal()),
    openFeatureModal: payload => dispatch(openFeatureModal(payload)),
    closeFeatureModal: () => dispatch(closeFeatureModal()),
    openFinanceModal: payload => dispatch(openFinanceModal(payload)),
    closeFinanceModal: () => dispatch(closeFinanceModal()),
    setTab: tabId => dispatch(selectTab(tabId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalTrigger);
