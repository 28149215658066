import React from 'react';
import { connect } from 'react-redux';
import { bool } from 'prop-types';
import { i18n, htmlToReact } from 'utils';

const VatDisclaimer = ({ showLink }) => {
  if (!showLink) {
    return null;
  }
  return <span>{htmlToReact(i18n('SummaryPanel.disclaimers.vatDisclaimer'))}</span>;
};

VatDisclaimer.propTypes = {
  showLink: bool,
};

VatDisclaimer.defaultProps = {
  showLink: false,
};

function mapStateToProps(state) {
  const { ReviewDetails } = state;
  const { showVatDisclaimer } = ReviewDetails;

  return {
    showLink: showVatDisclaimer,
  };
}

export default connect(mapStateToProps)(VatDisclaimer);
