import React, { useEffect, useState } from 'react';
import { arrayOf, array, string, bool, func, number, shape } from 'prop-types';
import { connect } from 'react-redux';
import _isUndefined from 'lodash/isUndefined';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _uniqBy from 'lodash/uniqBy';
import _includes from 'lodash/includes';
import { getModel, getMarket, getModelName } from 'selectors';
import { i18n, getTerritoryNameList } from 'utils';
import { getRegionByState } from '@tesla/intl-display-names';
import {
  setSummaryPanelRegion,
  deliverystateupdate,
  updateDeliveryDetails,
  getGeoLocation,
} from 'actions';
import RegionalSelectorTemplate from './template';

const RegionalIncentive = props => {
  const { showRegionDropDown } = props;
  const [regionSelectorVisible, setRegionSelectorVisible] = useState(showRegionDropDown);
  useEffect(() => {
    if (showRegionDropDown) {
      setRegionSelectorVisible(showRegionDropDown);
    }
  }, [showRegionDropDown]);

  const toggleRegionSelector = () => {
    setRegionSelectorVisible(!regionSelectorVisible);
  };

  const onRegionChange = region => {
    const regionCode = region.value;
    const {
      updateDeliveryState,
      language,
      countryCode,
      setRegion,
      stateList,
      deliveryZip,
      showDeliveryZipCode,
      updateDeliveryZipDetails,
    } = props;
    setRegion(regionCode);
    stateList.map(data =>
      data.value === regionCode
        ? updateDeliveryState({
            deliveryLocationState: data.label,
            deliveryRegion: _get(getRegionByState(countryCode, regionCode, language), 'region', ''),
            deliveryZipCode: '',
            deliveryStateCode: regionCode,
          })
        : regionCode
    );
    if (showDeliveryZipCode && deliveryZip) {
      updateDeliveryZipDetails({
        PostalCode: null,
        Latitude: null,
        Longitude: null,
      });
    }
  };

  const onSubmit = ({ values }) => {
    const { DeliveryPostalCode } = values || {};
    const { includeTaxesAndFees, setLocation } = props || {};
    setLocation(DeliveryPostalCode, { callTaxesAndFees: includeTaxesAndFees, setPreferredAddress: true });
    setTimeout(() => {
      toggleRegionSelector();
    }, 500);
  };

  const {
    regions,
    label,
    regionName,
    showLabel,
    isLayoutMobile,
    isLayoutTablet,
    showRegionalIncentiveDropDown,
    modelCode,
    showDisclaimer,
    stateList,
    modelName,
    locale,
    sibling,
    showDeliveryZipCode,
    deliveryZip,
    showDeliveryState,
    showRegionAndZipCode,
    className,
    showSubmitButton,
    enableCyberpunk,
    regionCode,
  } = props;

  const propValues = {
    onRegionChange,
    regionCode,
    toggleRegionSelector,
    regionName,
    regions: regions.map(region => {
      const label =
        region.regionCode === 'other' ? i18n('Incentives.otherRegionOptionLabel') : region.longName;
      return Object.assign({}, region, { label, value: region.regionCode });
    }),
    label,
    showLabel,
    regionSelectorVisible,
    isLayoutMobile,
    isLayoutTablet,
    showRegionalIncentiveDropDown,
    modelCode,
    stateList,
    showDisclaimer,
    modelName,
    locale,
    sibling,
    showDeliveryZipCode,
    deliveryZip,
    showDeliveryState,
    showRegionAndZipCode,
    className,
    showSubmitButton,
    onSubmit,
    enableCyberpunk,
  };
  return <RegionalSelectorTemplate {...propValues} />;
};

RegionalIncentive.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  regions: array,
  regionCode: string,
  regionName: string,
  showChangeRegionLink: bool,
  label: string,
  regionSelectorVisible: bool,
  showDisclaimer: bool,
  locale: string.isRequired,
  sibling: string.isRequired,
  governmentIncentivesTotal: number.isRequired,
  showRegionDropDown: bool,
  onChange: func,
  showLabel: bool,
  isLayoutMobile: bool,
  isLayoutTablet: bool,
  showRegionalIncentiveDropDown: bool.isRequired,
  modelCode: string,
  stateList: arrayOf(shape({})),
  modelName: string,
  updateDeliveryState: func.isRequired,
  language: string.isRequired,
  countryCode: string.isRequired,
  setRegion: func.isRequired,
  updateDeliveryZipDetails: func.isRequired,
  deliveryZip: string,
  showDeliveryZipCode: bool,
  showRegionAndZipCode: bool,
  className: string,
  showSubmitButton: bool,
  includeTaxesAndFees: bool,
  enableCyberpunk: bool,
};

RegionalIncentive.defaultProps = {
  regionCode: '',
  showDisclaimer: false,
  regionSelectorVisible: false,
  label: '',
  showChangeRegionLink: false,
  regionName: '',
  regions: [],
  showRegionDropDown: false,
  modelCode: '',
  modelName: '',
  stateList: [],
  onChange: () => {},
  isLayoutMobile: false,
  isLayoutTablet: false,
  deliveryZip: null,
  showDeliveryZipCode: false,
  showRegionAndZipCode: false,
  className: '',
  showSubmitButton: false,
  includeTaxesAndFees: false,
  enableCyberpunk: false,
};

function mapStateToProps(state) {
  const { App, FinancingOptions, ReviewDetails } = state;
  const { showDeliveryZipCode, includeTaxesAndFees } = FinancingOptions || {};
  const { DeliveryDetails } = ReviewDetails || {};
  const { countryCode, language, enableCyberpunk } = App || {};
  const modelName = getModelName(state);
  const financeType = _get(state, 'SummaryPanel.selected_tab');
  const { region_code } = _get(state, 'SummaryPanel', {});
  let { regionCode } = _get(state, 'Location.sources.geoIp.region', {}) || {};
  const stateList = getTerritoryNameList(countryCode, language);
  const locale = _get(state, 'App.locale');
  const sibling = _get(state, 'App.sibling', '');
  let regions = _get(state, 'Financial.incentives.available.regional', []).reduce(
    (result, region) => {
      const longName =
        i18n(`states.${region.regionCode}`, null, 'common.geo', { returnNullWhenEmpty: true }) ||
        region.longName;
      const fmtRegion = Object.assign({}, region, { longName });

      if (_includes(region.financeType, financeType)) {
        result.push(fmtRegion);
      }
      return result;
    },
    []
  );

  regions = stateList.reduce((result, state) => {
    const longName = state.label;
    const regionCode = state.value;
    const fmtRegion = Object.assign({}, { long_name: longName, longName, regionCode });

    if (!_includes(regions, state)) {
      result.push(fmtRegion);
    }
    return result;
  }, []);

  if (_isUndefined(region_code)) {
    regionCode = 'other';
  }
  // we should be able to remove this when LOCATION_CHANGE PR for updating
  // Location region is merged, right now, only SummaryPanel region_code is updated
  else if (!_isUndefined(region_code) && regionCode !== region_code) {
    regionCode = region_code;
  }

  const sortedRegions = _uniqBy(regions, item => item.regionCode).sort(
    (a, b) => a.regionCode > b.regionCode
  );

  const selectedRegion = _find(regions, { regionCode }) || _find(regions, { regionCode: 'other' });

  return {
    modelName,
    model: getModel(state),
    market: getMarket(state),
    regions: sortedRegions,
    regionCode: selectedRegion ? selectedRegion.regionCode : null,
    regionName: selectedRegion ? selectedRegion.longName : null,
    stateList,
    language,
    countryCode,
    modelCode: _get(state, 'Configuration.model_code', null),
    showRegionalIncentiveDropDown: _get(state, 'Forms.showRegionalIncentiveDropDown', false),
    locale,
    sibling,
    governmentIncentivesTotal: _get(
      state,
      'Pricing.calculatorResult.data.apiResults.incentives.current.government.total',
      0
    ),
    showDeliveryZipCode,
    deliveryZip: DeliveryDetails?.PostalCode || null,
    showDeliveryState: _get(state, 'Forms.showDeliveryState', false),
    includeTaxesAndFees,
    enableCyberpunk,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setRegion: regionCode => dispatch(setSummaryPanelRegion(regionCode)),
    updateDeliveryState: address => {
      dispatch(deliverystateupdate(address));
    },
    updateDeliveryZipDetails: payload => {
      dispatch(updateDeliveryDetails(payload));
    },
    setLocation: (zipCode, props) => {
      dispatch(getGeoLocation(zipCode, props));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegionalIncentive);
