import _omit from 'lodash/omit';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { request } from 'utils/requestAgent';
import { setConfigurationRN, deselectAccessories } from 'actions';
import { i18n } from 'utils';
import { Storage } from '@tesla/coin-common-components';
import {
  LOADER_START,
  LOADER_FINISH,
  INVALID_CONFIG,
  INVALID_ACCOUNT_ID,
  GENERATE_RN_FAILURE,
  SESSION_EXPIRED,
  UNKNOWN_ERROR,
  ENTERPRISE_ORDER_PROCESS_START,
  ENTERPRISE_ORDER_PROCESS_END,
  ENTERPRISE_ORDER_PROCESS_RESET,
  ENTERPRISE_ORDER_PROCESS_ERROR,
  ENTERPRISE_ORDER_PROCESS_SUCCESS,
  SET_BACK_TO_PROFILE,
  LOCAL_STORAGE_KEY,
  ACCESSORIES_ORDER_CREATE_ERROR,
  ACCESSORIES_VALIDATION_ERROR,
} from 'dictionary';
import {
  getEnterpriseOrderSchema,
  getEnterpriseOrderUri,
  getEstimateDeliveryDate,
  getModelCode,
  isAccessoriesSelected,
} from '../../../common/selectors';

export const processEnterpriseOrder = () => {
  return (dispatch, getState) => {
    dispatch({ type: ENTERPRISE_ORDER_PROCESS_START });
    dispatch({ type: LOADER_START });
    const state = getState();

    const eddData = _get(state, 'ReviewDetails.initialEDD', null);
    let initialEDD = null;
    if (!_isEmpty(eddData)) {
      const computedDeliveryWindow = getEstimateDeliveryDate(state);
      initialEDD = {
        deliveryWindowDisplay: computedDeliveryWindow?.deliveryWindowDisplay,
        deliveryWindowStart: computedDeliveryWindow?.deliveryWindowStart,
        deliveryWindowEnd: computedDeliveryWindow?.deliveryWindowEnd,
      };
    }
    const requestObj = {
      ...state,
      ...(!_isEmpty(eddData) && { initialEDD }),
    };
    const enterpriseOrderUri = getEnterpriseOrderUri(state);
    const orderSchema = _omit(getEnterpriseOrderSchema({ state: requestObj }), [
      'order',
      'comments',
      'impersonatedUserName',
      'modifiedApplication',
    ]);
    request
      .post(enterpriseOrderUri)
      .send(orderSchema)
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('Accept', 'application/json')
      .end((error, response) => {
        dispatch({ type: LOADER_FINISH });
        if (error || !response) {
          let errorCode = UNKNOWN_ERROR;
          if (error.status !== 200) {
            errorCode = _get(response, 'body.error.ErrorCode');
          }
          let errorMsg = '';
          let errorTitle = i18n('common.error');
          switch (errorCode) {
            case INVALID_CONFIG:
              errorMsg = i18n('common.errors__invalidConfig');
              break;
            case INVALID_ACCOUNT_ID:
              errorMsg = i18n('common.errors__invalidAccountId');
              break;
            case GENERATE_RN_FAILURE:
              errorMsg = i18n('common.errors__generateRnFailure');
              break;
            case SESSION_EXPIRED:
              errorMsg = i18n('common.errors__sessionExpired');
              break;
            case ACCESSORIES_ORDER_CREATE_ERROR:
            case ACCESSORIES_VALIDATION_ERROR:
              errorMsg = i18n('Accessories.alert.description');
              errorTitle = i18n('Accessories.alert.title');
              dispatch(deselectAccessories());
              break;
            default:
              errorMsg = i18n('common.errors__genericOrderProcessing');
          }

          // Dispatch the order fail
          dispatch({
            type: LOADER_FINISH,
          });

          return dispatch({
            type: ENTERPRISE_ORDER_PROCESS_ERROR,
            payload: { errorCode, errorMsg, errorTitle },
          });
        }
        const { body = {} } = response;
        const { referenceNumber = '' } = body;
        dispatch(setConfigurationRN(referenceNumber));

        const modelOption = getModelCode(state);
        const model = _get(state, `OMS.lexicon.options.${modelOption}`, {});
        const modelName = _get(model, 'name', '');
        const hasAccessories = isAccessoriesSelected(state);
        const msg = i18n(`Enterprise.${hasAccessories ? 'success_msg_generic' : 'success_msg'}`, {
          RESERVATION_NUMBER: referenceNumber,
          MODEL: modelName,
        });
        Storage.remove(LOCAL_STORAGE_KEY);
        return dispatch({
          type: ENTERPRISE_ORDER_PROCESS_SUCCESS,
          payload: { referenceNumber, msg },
          data: orderSchema,
          response,
        });
      });
    dispatch({ type: ENTERPRISE_ORDER_PROCESS_END });
    return null;
  };
};

export const resetEnterpriseOrder = dispatch => () =>
  dispatch({ type: ENTERPRISE_ORDER_PROCESS_RESET });

export const setBackToProfileFlag = (dispatch, flag) => () =>
  dispatch({ type: SET_BACK_TO_PROFILE, goBackToProfile: flag });
