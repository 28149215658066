import _get from 'lodash/get';
import { getCurrentModel } from 'utils';

export default ({ InitialConfig }) => {
  const currentModel = getCurrentModel();
  const leaseProducts = _get(
    window.tesla,
    `DSServices["Lease.${currentModel}.${currentModel}"].lease`,
    []
  );
  const selectedTab = _get(InitialConfig, 'SummaryPanel.selected_tab', '');
  const defaultAldProduct = /operational_lease/.test(selectedTab) ? selectedTab : null;
  const aldProduct = leaseProducts.find(({ id }) =>
    defaultAldProduct ? `lease.${id}` === defaultAldProduct : /operational_lease/.test(id)
  );

  return {
    merge_method: 'assign',
    object: {
      downPaymentPercent: _get(aldProduct, 'variables.downPaymentPercent'),
    },
  };
};
