import {
  ENTERPRISE_ORDER_PROCESS_START,
  ENTERPRISE_ORDER_PROCESS_SUCCESS,
  ENTERPRISE_ORDER_PROCESS_ERROR,
  ENTERPRISE_ORDER_PROCESS_RESET,
  ENTERPRISE_ORDER_UPDATED,
  SET_BACK_TO_PROFILE,
} from 'dictionary';

export default (state, { type, payload, goBackToProfile }) => {
  switch (type) {
    case ENTERPRISE_ORDER_PROCESS_START:
      return { ...state, isLoading: true, updated: false };
    case ENTERPRISE_ORDER_PROCESS_SUCCESS:
      return { ...state, isLoading: false, error: null, data: payload, updated: false };
    case ENTERPRISE_ORDER_PROCESS_ERROR:
      return { ...state, isLoading: false, data: null, error: payload, updated: false };
    case ENTERPRISE_ORDER_PROCESS_RESET:
      return { ...state, error: null, data: null, isLoading: false, updated: false };
    case ENTERPRISE_ORDER_UPDATED:
      return { ...state, error: null, data: null, isLoading: false, updated: true };
    case SET_BACK_TO_PROFILE:
      return { ...state, goBackToProfile };
    default:
      return state;
  }
};
