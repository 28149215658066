import React from 'react';
import { connect } from 'react-redux';
import { func, string } from 'prop-types';
import { navigationSelectKey } from 'actions';
import { i18n } from 'utils';
import { NAVIGATION_VIEW_OVERVIEW } from 'dictionary';

const EditDeliveryLocation = ({ goToOverview, id }) => {
  return (
    <a className="tds-link" onClick={() => goToOverview(id)}>
      {i18n('Review.edit')}
    </a>
  );
};

EditDeliveryLocation.propTypes = {
  goToOverview: func,
  id: string,
};

EditDeliveryLocation.defaultProps = {
  goToOverview: () => {},
  id: 'delivery-selection',
};

const mapDispatchToProps = dispatch => ({
  goToOverview: id =>
    dispatch(
      navigationSelectKey({
        key: NAVIGATION_VIEW_OVERVIEW,
        isEditDesign: true,
        linkTo: id,
      })
    ),
});

export default connect(null, mapDispatchToProps)(EditDeliveryLocation);
