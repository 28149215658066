/* eslint no-case-declarations: 0 */
/* eslint no-unused-vars: 0 */

import _get from 'lodash/get';
import _has from 'lodash/has';
import _find from 'lodash/find';
import _reduce from 'lodash/reduce';
import _omit from 'lodash/omit';
import _isEmpty from 'lodash/isEmpty';
import _flatMap from 'lodash/flatMap';
import {
  objectToQueryParams,
  compositorOptionString,
  getQueryParameters,
} from 'utils';
import { isInventory, getGAAutoType } from 'selectors';
import { map, filter } from 'rxjs/operators';

import {
  NAVIGATION_EXTERNAL_LINK_CLICKED,
  MODAL_FULLSCREEN,
  OMS_RECEIVED_DEPENDENCIES,
  NAVIGATION_SELECT_KEY,
  MODAL_OPEN,
  MODAL_CLOSE,
  PAGE_LOAD_EVENT,
  SUMMARY_PANEL_TAB_CHANGE,
  PAYMENT_FORM_TOGGLE,
  SUMMARY_PANEL_REGION_CHANGE,
  SHOW_MODAL_FORM,
  SUMMARY_PANEL_DOWNPAYMENT_CHANGE,
  SUMMARY_PANEL_FINANCE_TERM_CHANGE,
  SUMMARY_PANEL_FINANCE_APR_CHANGE,
  SUMMARY_PANEL_FINANCE_BALLOON_PAYMENT_CHANGE,
  VIDEO_MODAL,
  PLAY_VIDEO,
  ZOOM_IMAGE,
  ZOOM_IMAGE_MODAL,
  VIEW_STANDARD_FEATURES,
  STANDARD_FEATURES_MODAL,
  APPLE_PAY_VALIDITY,
  APPLE_PAY_SESSION_REQUEST,
  SAVE_CONFIG_FLOW,
  CANCEL_UPDATE_ORDER,
  GEOIP_LOCATION_CHANGED,
  COPY_URL,
  SHARE_DESIGN_SUCCESS,
  UPDATE_ANALYTICS,
  SELECT_FINANCE_PANEL_TAB,
  LOCAL_STORAGE_KEY_LOCATION,
  FINANCE_MODAL_OPEN,
  FINANCE_MODAL_SELECT_VIEW,
  ANALYTICS_IRA_CLICK_MODAL_DETAILS,
  NAVIGATION_VIEW_PAYMENT,
  UPDATE_ACTIVE_GROUP,
  NAVIGATION_VIEW_OVERVIEW,
  PAYMENT_FORM_INSTRUMENT_TYPE,
} from 'dictionary';
import { openModal } from 'actions';

import { normalizeGrayLocale } from 'utils';

import Analytics from '../../../common/analytics';

export const navigationSelectKey = ({ key, force, isEditDesign, linkTo, interaction, noSwitch = false }) => (dispatch, getState) => {
  const state = getState();
  const { Navigation } = state;
  const { allowedSections = [], section } = Navigation;
  if (allowedSections.includes(key)) {
    if (
      force ||
      allowedSections.length === 1 ||
      section !== key ||
      window.location.hash.substring(1) !== key
    ) {
      window.location.hash = key;
      if (!noSwitch) {
        dispatch({ type: NAVIGATION_SELECT_KEY, key, isEditDesign, linkTo, interaction });
      }
    }
  }
};
export const navigationSelectNextKey = () => (dispatch, getState) => {
  window.location.hash = getState().Navigation.next_section;
  return null;
};

export function triggerAction(action = 'link', params = {}) {
  return (dispatch, getState) => {
    const state = getState();
    const isLayoutMobile = _get(state, 'App.isLayoutMobile', false);
    const referral = _get(state, 'App.query_params.referral', false);

    switch (action) {
      case 'link':
        const url = _get(params, 'url', '');
        const query = _get(params, 'query', {});
        const type = _get(params, 'type', '_blank');
        const query_params = objectToQueryParams(query);

        if (url) {
          window.open(`${url}?${query_params}`, type);
        }
        break;

      case 'go_to_custom':
        const { pkg, group } = params;
        let option_string = '';

        const target = _get(params, 'url', '');
        const query_p = _get(params, 'query_params', {});
        const retain_params = _get(params, 'retain_params', false);
        const target_type = _get(params, 'type', '_self');
        let target_url = isLayoutMobile ? _get(target, 'mobile', '') : _get(target, 'default', '');
        const target_params = isLayoutMobile
          ? _get(query_p, 'mobile', {})
          : _get(query_p, 'default', {});

        if (!pkg || !group) {
          const options = _get(state, 'Configuration.option_codes', []);
          option_string = compositorOptionString(state, options);
        } else {
          // fire different anaylitics

          const packageObj = _find(
            _get(state, 'CustomGroups.packages.current', []),
            pkgItem => pkgItem.key === pkg
          );
          if (packageObj) {
            const base_options = _get(packageObj, 'options', []);
            const custom_options = _reduce(
              _get(packageObj, 'custom_upgrades_by_group', {}),
              (result, group_val, custom_group) => {
                let res = result;
                if (group.includes(custom_group)) {
                  res = res.concat(group_val);
                }
                return res;
              },
              []
            );
            option_string = compositorOptionString(state, base_options.concat(custom_options));
          }
        }

        const extra_params = {
          cfg: option_string,
        };
        if (referral && retain_params) {
          if (isLayoutMobile) {
            extra_params.referral = referral;
          } else if (target_url) {
            target_url = `${target_url.replace(/\/$/, '')}/referral/${referral}`;
          }
        }
        const q_params = objectToQueryParams({ ...target_params, ...extra_params });

        if (target_url) {
          window.open(`${target_url}?${q_params}`, target_type);
        }
        break;
      default:
        return null;
    }
    return null;
  };
}

export function externalLinkClicked({ analytics, href, target }) {
  return dispatch => {
    dispatch({
      type: NAVIGATION_EXTERNAL_LINK_CLICKED,
      analytics,
    });

    window.open(href, target);
  };
}

export const NavigationFlow = (action$, state$) =>
  action$.pipe(
    filter(action => [PLAY_VIDEO, ZOOM_IMAGE, VIEW_STANDARD_FEATURES].includes(action.type)),
    map(action => {
      switch (action.type) {
        case PLAY_VIDEO:
          return openModal(VIDEO_MODAL, {
            className: 'asset-video-container',
            asset: action.content,
          });
        case ZOOM_IMAGE:
          return openModal(ZOOM_IMAGE_MODAL, {
            className: 'asset-zoom-container',
            asset: { ...action.asset, method: 'background-image' },
            genericWrapper: true,
            size: MODAL_FULLSCREEN,
          });
        case VIEW_STANDARD_FEATURES:
          return openModal(STANDARD_FEATURES_MODAL, {
            className: 'standard-features--container',
            genericWrapper: true,
            size: MODAL_FULLSCREEN,
          });
        default:
          return null;
      }
    })
  );

export const AnalyticsFlow = (action$, state$) =>
  action$.pipe(
    filter(action => {
      const state = state$.value;
      const {
        Configuration: { model_code },
        FinancingOptions: { analyticsPrefix },
        SummaryPanel: { selected_tab },
      } = state;
      switch (action.type) {
        case OMS_RECEIVED_DEPENDENCIES:
          Analytics.fireOnce(PAGE_LOAD_EVENT, state);
          break;
        case MODAL_OPEN:
          switch (action.component_target) {
            case 'Video':
            case 'AssetLoader':
            case 'GasSaving':
            case 'DetailsMatrix':
            case 'SuccessModal':
              Analytics.fireOptionInteractionEvent(action, analyticsPrefix);
              break;
            case 'BannerModal':
              if (action?.props?.bannerType === 'ira_modal') {
                Analytics.fireTagEvent({
                  event: Analytics.event,
                  interaction: ANALYTICS_IRA_CLICK_MODAL_DETAILS + action?.props?.source,
                  'cfg-type': Analytics.cfgType,
                });
              }
              break;
            default:
              return null;
          }
          break;
        case SELECT_FINANCE_PANEL_TAB:
        case FINANCE_MODAL_OPEN:
        case FINANCE_MODAL_SELECT_VIEW:
          Analytics.fireOptionInteractionEvent(action, analyticsPrefix);
          break;
        case COPY_URL:
          Analytics.fireInteractionTag({ action, state });
          break;

        case SHARE_DESIGN_SUCCESS:
          Analytics.fireInteractionTag({ action, state });
          break;

        case SUMMARY_PANEL_TAB_CHANGE:
          Analytics.fireTabChangeEvent(action);
          break;

        case PAYMENT_FORM_TOGGLE:
          Analytics.fireOptionInteractionEvent(action);
          break;

        case SHOW_MODAL_FORM:
          if (action.selectedForm) {
            Analytics.fireOptionInteractionEvent(action, analyticsPrefix);
          }
          break;

        case SUMMARY_PANEL_REGION_CHANGE:
          if (!action.regionCode) {
            Analytics.fireOptionInteractionEvent(action);
          }
          break;

        case SUMMARY_PANEL_FINANCE_TERM_CHANGE:
        case SUMMARY_PANEL_DOWNPAYMENT_CHANGE:
        case SUMMARY_PANEL_FINANCE_APR_CHANGE:
        case SUMMARY_PANEL_FINANCE_BALLOON_PAYMENT_CHANGE:
          Analytics.fireOptionInteractionEvent(action, selected_tab);
          break;

        case NAVIGATION_SELECT_KEY:
          const { allowedSections, section } = state.Navigation;
          const { key: actionKey, isEditDesign, interaction } = action;
          if (allowedSections.includes(actionKey)) {
            if (
              allowedSections.length === 1 ||
              section !== actionKey ||
              window.location.hash.substring(1) !== actionKey
            ) {
              window.location.hash = actionKey;
            }
          }
          switch (actionKey) {
            case 'payment':
              // We don't want this tag to be fired for ent edit-design
              if (
                _get(state, 'App.isEnterpriseOrder') &&
                !!_get(window, 'tesla.savedConfiguration', false)
              ) {
                return;
              }
              if (_get(state, 'App.isEnterpriseOrder')) {
                document.body.classList.remove('has-top-banner');
                Analytics.fireTagEvent({
                  event: Analytics.event,
                  interaction: 'add-to-order',
                  'cfg-type': Analytics.cfgType,
                });
                return;
              }
              Analytics.addToCart(state);
              Analytics.fireTagEvent({
                event: Analytics.event,
                interaction: interaction || 'continue-to-payment',
                'cfg-type': Analytics.cfgType,
                titleStatus: getGAAutoType(state),
              });

              break;
            default: {
              if (isEditDesign) {
                Analytics.fireTagEvent({
                  event: Analytics.event,
                  interaction: 'edit-design',
                  'cfg-type': Analytics.cfgType,
                });
              }
              break;
            }
          }
          break;

        case NAVIGATION_EXTERNAL_LINK_CLICKED:
          Analytics.fireNavigationTag(action.analytics);
          break;

        case APPLE_PAY_VALIDITY:
          if (action.userHasApplePay) {
            Analytics.fireApplePayAnalyticsEvent({ action });
          }
          break;

        case APPLE_PAY_SESSION_REQUEST:
          Analytics.fireApplePayAnalyticsEvent({ action });
          break;

        case SAVE_CONFIG_FLOW:
          Analytics.fireModifyOrderAnalyticsEvent({ action, state });
          break;

        case CANCEL_UPDATE_ORDER:
          Analytics.fireModifyOrderAnalyticsEvent({ action, state });
          break;

        case PAYMENT_FORM_INSTRUMENT_TYPE:
          if (state?.App?.isCoinReloaded) { // otherwise fires twice on non-redesign flows
            Analytics.addToCart(state);
          }
          break;

        case GEOIP_LOCATION_CHANGED:
          // Do not redirect to locale for inventory order flow
          if (_get(state, 'ReviewDetails.product.isInventory')) {
            return null;
          }
          // This is taken from url (e.g. /en_CA/model3....)
          const uiLocale = normalizeGrayLocale(_get(state, 'App.uiLocale'));
          const uiLocaleNormalized = uiLocale.replace('_', '-');
          const availableLocales = _get(window, 'tesla.App.availableLocales', []);
          const allowedLocales = _flatMap(availableLocales).map(availableLocale =>
            normalizeGrayLocale(availableLocale)
          );
          const addRedirect = false;
          const localStorageObj = window.localStorage;
          const desiredLocale = localStorageObj
            ? localStorageObj.getItem(LOCAL_STORAGE_KEY_LOCATION) || ''
            : '';
          const desiredLocalesArray = desiredLocale ? desiredLocale.split(',') : [];
          // Check if URL has ?redirect=no
          const queryParams = getQueryParameters();
          if (queryParams.redirect === 'no') {
            // Update locale storage variable to preserve preference.
            let desiredLocaleString = uiLocaleNormalized;
            if (!desiredLocalesArray.includes(uiLocaleNormalized)) {
              desiredLocalesArray.push(uiLocaleNormalized);
              desiredLocaleString = desiredLocalesArray.join(',');
              if (localStorageObj) {
                localStorageObj.setItem(LOCAL_STORAGE_KEY_LOCATION, desiredLocaleString);
              }
            }
            return true;
          }
          if (desiredLocalesArray.length) {
            if (desiredLocalesArray.includes(uiLocaleNormalized)) {
              return null;
            }
            /*
            // TBD if we need this. Check against allowedLocales
            if (!desiredLocalesArray.includes(uiLocale)) {
              // Redirect to the last locale in desiredLocalesArray?
              return redirectoToLocale(desiredLocalesArray.slice(-1), addRedirect, model_code);
            }
            */
          }
          break;
        default:
      }
      return null;
    }),
    map(action => ({
      type: UPDATE_ANALYTICS,
    }))
  )

export const navigateToPayment = (props) => dispatch => {
  dispatch(navigationSelectKey({ key: NAVIGATION_VIEW_PAYMENT, ...props }));
};

export const navigateToOverview = (props) => dispatch => {
  dispatch(navigationSelectKey({ key: NAVIGATION_VIEW_OVERVIEW, ...props }));
};

export const setActiveGroupId = activeId => ({
  type: UPDATE_ACTIVE_GROUP,
  activeId,
});

export const updateSection = (action$, state$) =>
  action$.pipe(
    filter(action => [UPDATE_ACTIVE_GROUP].includes(action.type)),
    map(action => dispatch => {
      if (!state$.value?.App?.showSinglePage) {
        return;
      }

      if (action?.activeId === 'ORDER') {
        return dispatch(navigateToPayment({ noSwitch: true }));
      }

      if (window.location.hash.substring(1) === NAVIGATION_VIEW_PAYMENT) {
        return dispatch(navigateToOverview({ noSwitch: true }));
      }
    })
  );