import React from 'react';
import { connect } from 'react-redux';
import { string } from 'prop-types';
import _get from 'lodash/get';
import { getVehicleLocationString, isEMEAMarket } from 'utils';
import { getVehicleMappedForLocation } from 'selectors';

const VehicleLocationDisclaimer = ({ vehicleLocationString }) => {
  if (!vehicleLocationString) {
    return null;
  }
  return (
    <div className="tds--vertical_padding-bottom tds-text--center">{vehicleLocationString}</div>
  );
};

VehicleLocationDisclaimer.propTypes = {
  vehicleLocationString: string,
};

VehicleLocationDisclaimer.defaultProps = {
  vehicleLocationString: '',
};

function mapStateToProps(state) {
  const showVehicleLocation = _get(state, 'ReviewDetails.showVehicleLocation');
  const isPickupOnlyEnabled = _get(state, 'App.isPickupOnlyEnabled', false);
  const isEMEA = isEMEAMarket(_get(state, 'OMS.oms_params.market'));
  const vehicleMapped = getVehicleMappedForLocation(state);
  const showInTransitStatusMetro = _get(state, 'ReviewDetails.showInTransitStatusMetro', false);

  return {
    vehicleLocationString: showVehicleLocation
      ? getVehicleLocationString({
          vehicle: vehicleMapped,
          showLocationPrefix: _get(state, 'ReviewDetails.showLocationPrefix', false),
          showVehicleLocationDisclaimer: _get(
            state,
            'ReviewDetails.showVehicleLocationDisclaimer',
            false
          ),
          isPickupOnlyEnabled,
          isEMEA,
          showInTransitStatusMetro,
        })
      : '',
  };
}

export default connect(mapStateToProps)(VehicleLocationDisclaimer);
