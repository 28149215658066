import React from 'react';
import { connect } from 'react-redux';
import { string, bool } from 'prop-types';
import _get from 'lodash/get';
import { i18n, htmlToReact } from 'utils';
import { MODEL_FULL_NAME } from 'dictionary';
import classnames from 'classnames';

const PaymentFinishComponent = ({
  rn,
  modelCode,
  isPaymentInteractionRequired,
  isConfigBurst,
  enableCyberpunk,
  referralCode,
  baseUrl,
  isReservedPreOrder,
  isBookedPreOrder,
  isNewOrderProfileUrl,
}) => {
  const modelUrl = isConfigBurst ? modelCode : MODEL_FULL_NAME[modelCode];
  const dashboardUrl = isNewOrderProfileUrl ? `${baseUrl}/teslaaccount/order/${rn}` : `${baseUrl}/teslaaccount/profile?rn=${rn}&redirect=no`;
  let redirectUrl = referralCode
    ? `/${modelUrl}/confirmation/${rn}?referral=${referralCode}`
    : `/${modelUrl}/confirmation/${rn}`;
  if (isReservedPreOrder || isBookedPreOrder) {
    redirectUrl = dashboardUrl;
  }

  return (
    <If condition={!isReservedPreOrder && !isBookedPreOrder && isPaymentInteractionRequired}>
      <button
        type="button"
        className={classnames(
          'tds-btn tds-btn--secondary tds-btn--width-full payment-qrcode-btn-paid',
          { 'tds-scrim--black': enableCyberpunk }
        )}
        onClick={() => {
          window.location = redirectUrl;
        }}
      >
        {htmlToReact(i18n('SummaryPanel.wechat_payment_complete'))}
      </button>
    </If>
  );
};

PaymentFinishComponent.propTypes = {
  rn: string,
  modelCode: string,
  isPaymentInteractionRequired: bool,
  isConfigBurst: bool,
  enableCyberpunk: bool,
  referralCode: string,
  baseUrl: string,
  isReservedPreOrder: bool,
  isBookedPreOrder: bool,
  isNewOrderProfileUrl: bool.isRequired,
};

PaymentFinishComponent.defaultProps = {
  rn: '',
  modelCode: '',
  isPaymentInteractionRequired: false,
  isConfigBurst: false,
  enableCyberpunk: false,
  referralCode: '',
  baseUrl: '',
  isReservedPreOrder: false,
  isBookedPreOrder: false,
};

const mapStateToProps = state => {
  const { App, Payment, Configuration, ApplicationFlow } = state;
  const {
    isNewOrderProfileUrl = false,
    isConfigBurst = false,
    enableCyberpunk = false,
    base_url: baseUrl = '',
  } = App;
  const { isReservedPreOrder = false, isBookedPreOrder = false, referral = {} } = ApplicationFlow;
  const { referralCode = '' } = referral || {};
  const { isPaymentInteractionRequired = false, PaymentQRCode = '' } = Payment;
  const { rn = null, model_code: modelCode = '' } = Configuration;
  return {
    qrcode: PaymentQRCode,
    rn,
    modelCode,
    isPaymentInteractionRequired,
    isConfigBurst,
    enableCyberpunk,
    referralCode,
    baseUrl,
    isReservedPreOrder,
    isBookedPreOrder,
    isNewOrderProfileUrl,
  }
};

export default connect(mapStateToProps)(PaymentFinishComponent);
