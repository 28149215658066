import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Elon, Relevant, useFormApi } from 'informed';
import { Checkbox, Input, Select } from '@tesla/informed-tds';
import {
  NON_RESIDENT_PERSON,
  NON_RESIDENT_COMPANY,
  EU_CBR_MARKETS,
  REGISTRATION_TYPE_PRIVATE,
  REGISTRATION_TYPE_BUSINESS,
  FIELD_PRIVATE_VAT_ID,
  FIELD_COMPANY_NAME,
  FIELD_VAT_ID,
  FIELD_COMPANY_ADDRESS_1,
  FIELD_COMPANY_ADDRESS_2,
  FIELD_COMPANY_CITY,
  FIELD_COMPANY_POSTAL_CODE,
  CHAMBER_OF_COMMERCE,
  UI_DATA_IDS,
} from 'dictionary';
import {
  i18n,
  companyNameValidator,
  postalCodeValidator,
  htmlToReact,
  isValidSiretNumber,
} from 'utils';
import InfoTooltip from '../InfoTooltip';
import { getVatNumberFormat, isvalidVatNumber } from '@tesla/intl-id';
import { Icon } from '@tesla/design-system-react';
import { iconStatusWarning } from '@tesla/design-system-icons';
import { deselectAccessories } from 'actions';
import { arrayOf, bool, func, shape, string } from 'prop-types';

function RegistrationCountrySelector({ market, type, defaultValue, onCountryChange }) {
  const countryNames = new Intl.DisplayNames(['en'], { type: 'region' });
  const countryList = EU_CBR_MARKETS.reduce((countries, country) => {
    if (market !== country) {
      countries.push({ code: country, name: countryNames.of(country) });
    }
    return countries;
  }, []).sort((a, b) => (a.name > b.name ? 1 : -1));

  const fieldName = `${type}RegistrationCountry`;
  const toolTip = `tooltip__${type}`;

  return (
    <Select
      name={fieldName}
      id={fieldName}
      onChange={({ value }) => onCountryChange(value)}
      required
      label={i18n('CrossBorderOrder.registrationCountry')}
      tooltip={<InfoTooltip description={i18n(`CrossBorderOrder.${toolTip}`)} />}
      keep={{ value: true, touched: true }}
      defaultValue={defaultValue}
    >
      <option value="" disabled>
        {i18n('CrossBorderOrder.selectCountry')}
      </option>
      {countryList.map(country => {
        return (
          <option value={country.code} key={country.code}>
            {country.name}
          </option>
        );
      })}
    </Select>
  );
}

const WarningMessage = ({ locale }) => {
  return (
    <div className="tds-status_msg cross-border-order--warning">
      <div>
        <Icon data={iconStatusWarning} style={{ marginInlineEnd: 'var(--tds-size)' }} />
      </div>
      <div>
        <p className="tds-text--caption">{i18n('CrossBorderOrder.warning_title')}</p>
        <ul className="tds-list tds-list--unordered tds-text--caption">
          {htmlToReact(i18n('CrossBorderOrder.warning_list', { LOCALE: locale.toLowerCase() }))}
        </ul>
      </div>
    </div>
  );
};

const isNonResident = values => {
  const { nonResidentPerson, nonResidentCompany, registrationType } = values;
  return (
    (!!nonResidentPerson && registrationType === REGISTRATION_TYPE_PRIVATE) ||
    (!!nonResidentCompany && registrationType === REGISTRATION_TYPE_BUSINESS)
  );
};

const isRegistrationCountrySelected = values => {
  const { privateRegistrationCountry, businessRegistrationCountry, registrationType } = values;
  return (
    (privateRegistrationCountry && registrationType === REGISTRATION_TYPE_PRIVATE) ||
    (businessRegistrationCountry && registrationType === REGISTRATION_TYPE_BUSINESS)
  );
};

function CrossBorderRegistration({
  market,
  locale,
  registrationFields,
  removeAccessories,
  isNonResidentPerson,
  isNonResidentCompany,
  selectedRegistrationType,
  selectedRegistrationCountry,
}) {
  const [registrationCountry, setRegistrationCountry] = useState(selectedRegistrationCountry);
  const [extraFields, setExtraFields] = useState([]);
  const [vatFormat, setVatFormat] = useState('');
  const [companyNumberPlaceholder, setCompanyNumberPlaceholder] = useState('');
  const formApi = useFormApi();

  const setFields = () => {
    let selectedCountry = registrationCountry;
    if (!selectedCountry) {
      selectedCountry =
        selectedRegistrationType === REGISTRATION_TYPE_PRIVATE
          ? formApi.getValue('privateRegistrationCountry')
          : formApi.getValue('businessRegistrationCountry');
    }
    if (selectedCountry && selectedRegistrationType) {
      setExtraFields(
        registrationFields.find(field => field?.market?.includes(selectedCountry))?.fields || []
      );
      removeAccessories();
      const vatNumberFomat = getVatNumberFormat(selectedCountry, selectedCountry === 'IS' ? REGISTRATION_TYPE_PRIVATE : selectedRegistrationType);
      setVatFormat(vatNumberFomat);
      setCompanyNumberPlaceholder(selectedCountry !== 'FR' ? vatNumberFomat : '');
    }
  };

  const validateVat = Elon.inspect([
    (value, values) => {
      // Validates FIELD_PRIVATE_VAT_ID, FIELD_VAT_ID
      const { privateRegistrationCountry, businessRegistrationCountry, registrationType } = values;
      const selectedCountry =
        registrationType === REGISTRATION_TYPE_PRIVATE
          ? privateRegistrationCountry
          : businessRegistrationCountry;
      if (value && !isvalidVatNumber(selectedCountry, value, selectedRegistrationType)) {
        return i18n('CrossBorderOrder.generic_validation_message', {
          FORMAT: getVatNumberFormat(selectedCountry, selectedRegistrationType),
        });
      }
    },
  ]);

  const validateCompanyNumber = Elon.inspect([
    (value, values) => {
      // Validates CHAMBER_OF_COMMERCE
      const { privateRegistrationCountry, businessRegistrationCountry, registrationType } = values;
      const selectedCountry =
        registrationType === REGISTRATION_TYPE_PRIVATE
          ? privateRegistrationCountry
          : businessRegistrationCountry;

      if (selectedCountry === 'FR') {
        return !isValidSiretNumber(value)
          ? i18n('common.errors__pattern_chamber_of_commerce')
          : null;
      } else {
        if (value && !isvalidVatNumber(selectedCountry, value, selectedCountry === 'IS' ? REGISTRATION_TYPE_PRIVATE : selectedRegistrationType)) {
          return i18n('CrossBorderOrder.generic_validation_message', {
            FORMAT: getVatNumberFormat(selectedCountry, selectedCountry === 'IS' ? REGISTRATION_TYPE_PRIVATE : selectedRegistrationType),
          });
        }
      }
    },
  ]);

  const cacheValue = (key, value) => {
    sessionStorage.setItem(
      `${registrationCountry}_${selectedRegistrationType}_${key}`,
      value ?? ''
    );
  };

  const validateCompanyPostalCode = Elon.inspect([
    (value, values) => {
      return postalCodeValidator(value, values.businessRegistrationCountry);
    },
  ]);

  const clearFields = () => {
    // Clears fields which are not available for the selected market
    const mapping = {
      privateVatId: FIELD_PRIVATE_VAT_ID,
      vatId: FIELD_VAT_ID,
      companyName: FIELD_COMPANY_NAME,
      companyNumber: CHAMBER_OF_COMMERCE,
    };
    for (const [key, value] of Object.entries(mapping)) {
      if (!extraFields.includes(value) && formApi.getValue(key)) {
        formApi.setValue(key, null);
      } else if (extraFields.includes(value)) {
        const cachedValue = sessionStorage.getItem(
          `${registrationCountry}_${selectedRegistrationType}_${key}`
        );
        if (cachedValue && !formApi.getValue(key)) {
          formApi.setValue(key, cachedValue);
        }
      }
    }
  };

  useEffect(() => {
    setFields();
  }, []);

  useEffect(() => {
    setFields();
  }, [registrationCountry, isNonResidentPerson, isNonResidentCompany]);

  useEffect(() => {
    clearFields();
  }, [extraFields]);

  return (
    <>
      <Relevant
        when={({ formState }) => formState.values.registrationType === REGISTRATION_TYPE_PRIVATE}
      >
        <Checkbox
          name="nonResidentPerson"
          id={NON_RESIDENT_PERSON}
          data-id={UI_DATA_IDS?.accountSection?.nonResidentPerson}
          label={i18n('CrossBorderOrder.nonResidentPerson')}
          defaultValue={isNonResidentPerson}
          keep={{ value: true, touched: true }}
        />
      </Relevant>
      <Relevant
        when={({ formState }) => formState.values.registrationType === REGISTRATION_TYPE_BUSINESS}
      >
        <Checkbox
          name="nonResidentCompany"
          id={NON_RESIDENT_COMPANY}
          data-id={UI_DATA_IDS?.accountSection?.nonResidentCompany}
          label={i18n('CrossBorderOrder.nonResidentCompany')}
          defaultValue={isNonResidentCompany}
          keep={{ value: true, touched: true }}
        />
      </Relevant>
      <Relevant when={({ formState }) => isNonResident(formState.values)}>
        <RegistrationCountrySelector
          market={market}
          type={selectedRegistrationType}
          onCountryChange={setRegistrationCountry}
          defaultValue={registrationCountry}
        />
        <Relevant when={({ formState }) => isRegistrationCountrySelected(formState.values)}>
          <WarningMessage locale={locale} />
        </Relevant>
      </Relevant>
      <Relevant when={({ formState }) => !!formState.values.nonResidentPerson}>
        <If condition={extraFields.includes(FIELD_PRIVATE_VAT_ID)}>
          <Input
            name="privateVatId"
            id={FIELD_PRIVATE_VAT_ID}
            data-id={UI_DATA_IDS?.accountSection?.vatIDInput}
            label={i18n('Review.BillingInfo.Form.private_vat_id')}
            placeholder={vatFormat}
            validate={validateVat}
            validateOn="change"
            required
            showErrorIfDirty
            className="tds-o-form-input-wrap"
            keep={{ value: true, touched: true }}
            validateWhen={['privateRegistrationCountry']}
            onChange={({ value }) => cacheValue('privateVatId', value)}
          />
        </If>
      </Relevant>
      <Relevant when={({ formState }) => !!formState.values.nonResidentCompany}>
        <If condition={extraFields.includes(FIELD_COMPANY_NAME)}>
          <Input
            name="companyName"
            id={FIELD_COMPANY_NAME}
            data-id={UI_DATA_IDS?.accountSection?.companyNameInput}
            label={i18n('Review.account_company_name_label')}
            required
            validateOn="change"
            validate={companyNameValidator}
            showErrorIfDirty
            validateOnMount
            className="tds-o-form-input-wrap"
            keep={{ value: true, touched: true }}
            validateWhen={['businessRegistrationCountry']}
            onChange={({ value }) => cacheValue('companyName', value)}
          />
          <Relevant when={({ formState }) => isRegistrationCountrySelected(formState.values)}>
            <Input
              name="companyDetails.companyAddress1"
              id={FIELD_COMPANY_ADDRESS_1}
              label={i18n(`Review.account_company_address_1_label`)}
              required
              keep={{ value: true, touched: true }}
            />
            <Input
              name="companyDetails.companyAddress2"
              id={FIELD_COMPANY_ADDRESS_2}
              label={i18n(`Review.account_company_address_2_label`)}
              keep={{ value: true, touched: true }}
            />
            <Input
              name="companyDetails.companyCity"
              id={FIELD_COMPANY_CITY}
              label={i18n('Review.account_company_city_label')}
              required
              keep={{ value: true, touched: true }}
              showErrorIfDirty
            />
            <Input
              name="companyDetails.companyPostalCode"
              id={FIELD_COMPANY_POSTAL_CODE}
              label={i18n('Review.account_company_postal_code_label')}
              validate={validateCompanyPostalCode}
              required
              keep={{ value: true, touched: true }}
              showErrorIfDirty
              validateWhen={['businessRegistrationCountry']}
            />
          </Relevant>
        </If>
        <If condition={extraFields.includes(FIELD_VAT_ID)}>
          <Input
            name="vatId"
            id={FIELD_VAT_ID}
            label={i18n('Review.BillingInfo.Form.vat_id')}
            placeholder={vatFormat}
            validate={validateVat}
            validateOn="change"
            required
            showErrorIfDirty
            keep={{ value: true, touched: true }}
            validateWhen={['businessRegistrationCountry']}
            onChange={({ value }) => cacheValue('vatId', value)}
          />
        </If>
      </Relevant>
      <Relevant
        when={({ formState }) =>
          !!formState.values.nonResidentPerson || !!formState.values.nonResidentCompany
        }
      >
        <If condition={extraFields.includes(CHAMBER_OF_COMMERCE)}>
          <Input
            name="companyNumber"
            id={CHAMBER_OF_COMMERCE}
            data-id={UI_DATA_IDS?.accountSection?.companyNumberInput}
            label={i18n('Review.account_chamber_of_commerce_label', null, null, {
              specificOverride: registrationCountry,
            })}
            placeholder={companyNumberPlaceholder}
            validate={validateCompanyNumber}
            validateOn="change"
            required
            showErrorIfDirty
            className="tds-o-form-input-wrap"
            keep={{ value: true, touched: true }}
            validateWhen={['privateRegistrationCountry', 'businessRegistrationCountry']}
            onChange={({ value }) => cacheValue('companyNumber', value)}
          />
        </If>
      </Relevant>
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    removeAccessories: () => dispatch(deselectAccessories()),
  };
}

function mapStateToProps(state) {
  const accountDetails = state?.ReviewDetails?.AccountDetail ?? {};
  const selectedRegistrationType = accountDetails.AccountType ?? '';
  return {
    selectedRegistrationType,
    market: state?.App?.countryCode ?? '',
    locale: state?.App?.locale ?? '',
    isNonResidentPerson: !!accountDetails.NonResidentPerson ?? false,
    isNonResidentCompany: !!accountDetails.NonResidentCompany ?? false,
    selectedRegistrationCountry:
      selectedRegistrationType === REGISTRATION_TYPE_PRIVATE
        ? accountDetails.PrivateRegistrationCountry
        : accountDetails.BusinessRegistrationCountry,
    registrationFields:
      state?.ReviewDetails?.crossBorderRegistrationFields[`${selectedRegistrationType}`] ?? [],
  };
}

RegistrationCountrySelector.propTypes = {
  market: string.isRequired,
  type: string.isRequired,
  defaultValue: string.isRequired,
  onCountryChange: func.isRequired,
};

WarningMessage.propTypes = {
  locale: string.isRequired,
};

CrossBorderRegistration.propTypes = {
  market: string.isRequired,
  locale: string.isRequired,
  registrationFields: arrayOf(shape({})).isRequired,
  removeAccessories: func.isRequired,
  isNonResidentPerson: bool.isRequired,
  isNonResidentCompany: bool.isRequired,
  selectedRegistrationType: string.isRequired,
  selectedRegistrationCountry: string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CrossBorderRegistration);
