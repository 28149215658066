import React from 'react';
import { connect } from 'react-redux';
import { number, shape, bool, string } from 'prop-types';
import { i18n, convertMultipleCurrency } from 'utils';

const CurrencyDisclaimer = ({ conversionRate, className }) => {
  const { show, primaryCurrency, secondaryCurrency, factor } = conversionRate;

  if (show === false) return null;

  return (
    <p className={className}>
      {i18n(
        `FinancingOptions.currency_disclaimer`,
        {
          PRIMARY_CURRENCY: primaryCurrency,
          SECONDARY_CURRENCY: secondaryCurrency,
          FACTOR: factor,
        },
        null,
        { returnNullWhenEmpty: true }
      )}
    </p>
  );
};

CurrencyDisclaimer.propTypes = {
  conversionRate: shape({
    show: bool,
    primaryCurrency: number,
    secondaryCurrency: number,
    factor: number,
  }),
  className: string,
};

const mapStateToProps = state => {
  return {
    conversionRate: convertMultipleCurrency(state),
  };
};

export default connect(mapStateToProps)(CurrencyDisclaimer);
