import React from 'react';
import { shape, string, bool } from 'prop-types';
import { connect } from 'react-redux';
import { i18n, htmlToReact } from 'utils';
import { getTrimTotal } from 'selectors';
import _isEmpty from 'lodash/isEmpty';
import _isObject from 'lodash/isObject';
import AssetLoader from '../AssetLoader';
import { Icon, Tooltip, TooltipWrapper, useTooltipState } from '@tesla/design-system-react';
import { iconInfoSmall } from '@tesla/design-system-icons';
import TextLoader from '../../components/TextLoader';

const AssetTooltip = ({ classes, data: option, group, showAsset = true, optionPrice }) => {
  const { open, handlers, wrapperRef } = useTooltipState();

  if (!option || !group) {
    return null;
  }
  const { code = '', name = '', feature_description = '', long_name = '', formattedSpecs, lexicon_specs } = option;
  const {
    range_units,
    accelerationFormatted,
    topspeedFormatted,
    range,
    topspeed,
    rawData = {},
  } = formattedSpecs || {};
  const {
    towingCapacity,
    towingCapacityFormatted,
  } = lexicon_specs || {};
  const { asset } = group || {};
  const {
    range_units_override,
    zero_to_road_limit_units,
    range_units_short,
    top_speed_units,
    towing_capacity_units: towingLabel,
  } = rawData;

  let rangeUnitLabel = range_units || '';

  if (!_isEmpty(range_units_override)) {
    rangeUnitLabel = rawData[range_units_override] || rangeUnitLabel;
  }

  return (
    <TooltipWrapper
      {...handlers}
      className={classes || 'tds-text--center text-loader--subtitle'}
      wrapperRef={wrapperRef}
      inline
      onClick={e => e.preventDefault()}
    >
      <button>
        <Icon data={iconInfoSmall} aria-label={i18n('expand', 'common.ui')} />
      </button>

      <Tooltip open={open} className="cf-tooltip-content" orientation="down">
        <TextLoader
          data={option}
          field={long_name ? 'long_name' : 'name'}
          className="tds-text--caption tds-text--medium tds-text_color--10"
        />
        <span className="tds-text--caption">{optionPrice}</span>
        <If condition={formattedSpecs}>
          <div className="enterprise-specs-block">
            <ol className="tds-list tds-list--horizontal">
              <li>
                {!_isObject(rangeUnitLabel) && rangeUnitLabel && (
                  <p className="specs-item tds-text--medium tds-text--caption">
                    {htmlToReact(rangeUnitLabel)}
                  </p>
                )}
                {_isObject(rangeUnitLabel) && rangeUnitLabel.label && (
                  <p className="specs-item cf-range-est--container tds-text--medium tds-text--caption">
                    {`${rangeUnitLabel.label} ${rangeUnitLabel.content || ''}`}
                  </p>
                )}
                <span className="tds-text_color--black tds-text--caption">
                  {range}&nbsp;{range_units_short}
                </span>
              </li>
              <If condition={topspeed}>
                <li>
                  <p className="specs-item tds-text--medium tds-text--caption">{top_speed_units}</p>
                  <span className="tds-text_color--black tds-text--caption">{topspeedFormatted}</span>
                </li>
              </If>
              <If condition={towingCapacity}>
                <li>
                  <p className="specs-item tds-text--medium tds-text--caption">{towingLabel}</p>
                  <span className="tds-text_color--black tds-text--caption">{towingCapacityFormatted}</span>
                </li>
              </If>
              <li>
                <p className="specs-item tds-text--medium tds-text--caption">
                  {i18n('Enterprise.acceleration')}
                </p>
                <span className="tds-text_color--black tds-text--caption">
                  {accelerationFormatted}&nbsp;
                  {zero_to_road_limit_units}
                </span>
              </li>
            </ol>
          </div>
        </If>
        <If condition={feature_description}>
          <p className="tds-text--caption tds--vertical_padding">{feature_description}</p>
        </If>
        <If condition={showAsset && asset}>
          <div className="tds-tooltip-img">
            <AssetLoader asset={asset} key={`${code}__asset`} title={code} />
          </div>
        </If>
      </Tooltip>
    </TooltipWrapper>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { data: { code = '', formattedPrice = '' } = {} } = ownProps;
  const baseConfiguration = getTrimTotal(state);
  const selectedTrim = baseConfiguration.find(x => x.code === code);
  const optionPrice = selectedTrim ? selectedTrim.value : formattedPrice;
  return {
    optionPrice,
  };
};

AssetTooltip.propTypes = {
  classes: string,
  data: shape({}),
  group: shape({}),
  showAsset: bool,
  optionPrice: string,
};

AssetTooltip.defaultProps = {
  classes: '',
  data: {},
  group: {},
  showAsset: true,
  optionPrice: '',
};
export default connect(mapStateToProps)(AssetTooltip);
