import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { shape, bool, func, arrayOf } from 'prop-types';
import { i18n, htmlToReact } from 'utils';
import _isEmpty from 'lodash/isEmpty';
import cx from 'classnames';
import { REFERRAL_TYPE_B, CREDITS_MODAL, VERIFY_PROVIDER_UBER, VERIFY_PROVIDER_IDME } from 'dictionary';
import { iconStatusSuccess, iconStatusError } from '@tesla/design-system-icons';
import { Icon } from '@tesla/design-system-react';
import Analytics from 'analytics';
import { openModal } from 'actions';

const getProviderDisclaimer = ({ verificationStatus }) => {
  useEffect(() => {
    let prefix = '';
    switch(provider) {
      case VERIFY_PROVIDER_UBER:
        prefix = 'Uber';
        break;
      case VERIFY_PROVIDER_IDME:
      default:
        prefix = 'IDme';
    }
    Analytics.fireInteractionEvent(`${prefix}-discount-${status ? 'eligible' : 'ineligible'}`);
  }, []);

  const { status = false, statusGroup = '', provider = '' } = verificationStatus;
  const discountsDisclaimer = status ? i18n('Review.discount_applied', {}, null,
    {
      specificOverride: statusGroup,
      returnNullWhenEmpty: true,
    }
  ) : i18n(`Review.${statusGroup}_credit_ineligible`);
  return {
    status,
    disclaimer: discountsDisclaimer,
  }

}

const VerificationBanner = ({
  verificationStatus,
  isCyberpunk,
  referralData,
  showCredits,
  validConfigForReferral,
  showReferralZeroCreditDisclaimer,
  openCreditsModal,
}) => {
  if (_isEmpty(verificationStatus)) {
    return null;
  }
  const validVerifyPrograms = verificationStatus.filter(i => i?.status);
  const { status = false, statusGroup = '', provider = '' } = verificationStatus[0] || {};
  const disclaimers = [];
  const { isValid: isValidReferral, type: referralType } = referralData || {};
  if (validVerifyPrograms.length > 1 || (!!validVerifyPrograms.length && isValidReferral)) {
    disclaimers.push({
      status: true,
      disclaimer: i18n('Review.discount_applied__benefits'),
    });
  }
  let hasGenericDisclaimer = !!disclaimers.length;
  if (hasGenericDisclaimer || validVerifyPrograms.legth !== verificationStatus.length) {
    if (!hasGenericDisclaimer && isValidReferral) {
      disclaimers.push({
        status: true,
        disclaimer: i18n('Review.discount_applied__referral'),
      });
    }
    verificationStatus.forEach((v) => {
      if (!hasGenericDisclaimer || !v?.status) {
        disclaimers.push(getProviderDisclaimer({ verificationStatus: v }));
      }
    });
  }

  return (
    <div
      className={cx('tds-o-flex_no-wrap tds-o-flex-direction--column', {
        'tds-flex--justify-center': status,
      }, {
        'tds--vertical_padding--small': !isCyberpunk,
      }, {
        'tds-o-vertical_padding-top--3x': isCyberpunk,
      })}
    >
      {disclaimers.map(({ status, disclaimer }) => {
        return (
          <div className="tds-o-flex_no-wrap tds-flex--justify-center">
            <Icon data={status ? iconStatusSuccess : iconStatusError} />
            <span className="tds--horizontal_padding--small">
              {htmlToReact(disclaimer)}
              <If condition={status}>
                <a className="tds-link tds--horizontal_margin-5" onClick={openCreditsModal}>
                  {i18n('common.learn_more')}
                </a>
              </If>
            </span>
          </div>
        );
      })}
    </div>
  );
};

VerificationBanner.propTypes = {
  verificationStatus: arrayOf(shape({
    status: bool,
  })),
  showCredits: bool,
  showReferralZeroCreditDisclaimer: bool,
  openReferralModal: func,
  isCyberpunk: bool,
};

VerificationBanner.defaultProps = {
  verificationStatus: [],
  isCyberpunk: false,
  showCredits: false,
  showReferralZeroCreditDisclaimer: false,
  openReferralModal: () => {},
};

function mapStateToProps(state) {
  return {
    isCyberpunk: state?.App?.enableCyberpunk || false,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openCreditsModal: () => {
      dispatch(
        openModal(CREDITS_MODAL, {
          genericWrapper: false,
        })
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VerificationBanner);
