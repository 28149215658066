/* eslint-disable no-console */
import _get from 'lodash/get';
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { createEpicMiddleware, combineEpics } from 'redux-observable';
import { rootReducer, rootEpic } from '../reducers';

export default function configureStore(initialState, composeWithDevTools) {
  const reduxLoggerEnabled = _get(window, 'tesla.App.reduxLogger');

  function storeEnhancer() {
    return next => action => {
      let returnValue;
      try {
        returnValue = next(action);
      } catch (e) {
        /**
         * If something breaks while trying to finish an action
         */
        if (reduxLoggerEnabled) {
          console.error('ERROR on action:', action);
          console.error('error object:', e);
        }
      }

      return returnValue;
    };
  }

  const epicMiddleware = createEpicMiddleware();

  const composeEnhancers = composeWithDevTools || compose;
  const middleware = [thunkMiddleware, epicMiddleware];

  if (reduxLoggerEnabled) {
    middleware.push(createLogger());
  }

  const store = createStore(
    rootReducer(),
    initialState,
    composeEnhancers(applyMiddleware(...middleware, storeEnhancer))
  );

  epicMiddleware.run(combineEpics(rootEpic()));

  configureStore.store = store;
  return store;
}
