import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { string, bool, func, array } from 'prop-types';
import { Select, Form } from '@tesla/informed-tds';
import { i18n, getTerritoryCodeList, getCleanRegionList } from 'utils';
import { getIsFalconDeliverySelectionEnabled } from 'selectors';

// Note: There might be overlap here with this and RegionalSelector
const StateDropdown = ({
  id,
  onChange,
  selectedState,
  isDisabled,
  showFleetSalesRegionList,
  states,
}) => {
  const formApiRef = useRef();
  useEffect(() => {
    const fieldValue = formApiRef?.current?.getValue(id);
    if (fieldValue !== selectedState) {
      formApiRef?.current?.setValue(id, selectedState);
    }
  }, [selectedState]);

  return (
    <Form formApiRef={formApiRef}>
      <Select
        id={id}
        name={id}
        label={
          showFleetSalesRegionList
            ? i18n('FinancingOptions.region')
            : i18n('FinancingOptions.territory')
        }
        options={states}
        initialValue={selectedState}
        onChange={({ value }) => {
          if (value && onChange) {
            onChange(value);
          }
        }}
        disabled={isDisabled}
      />
    </Form>
  );
}

StateDropdown.propTypes = {
  id: string,
  onChange: func.isRequired,
  showFleetSalesRegionList: bool,
  isDisabled: bool.isRequired,
  selectedState: string,
  states: array,
};

StateDropdown.defaultProps = {
  countryCode: '',
  showFleetSalesRegionList: false,
  selectedState: null,
  id: undefined,
  states: [],
};

const mapStateToProps = state => {
  const countryCode = state?.Payment?.CountryCode || '';
  const isFalconDeliverySelectionEnabled = getIsFalconDeliverySelectionEnabled(state);
  const regionsData =
    window.tesla?.regionsData?.map(({ RegionCode, ...rest }) => {
      const regionCodeNoSpaces = RegionCode.replace(' ', '');
      return {
        ...rest,
        RegionCode: regionCodeNoSpaces.charAt(0).toLowerCase() + regionCodeNoSpaces.slice(1),
      };
    }) || [];

  return {
    selectedState: state?.SummaryPanel?.region_code || null,
    isDisabled: state?.ApplicationFlow?.canModifyOrder,
    showFleetSalesRegionList: state?.FinancingOptions?.showFleetSalesRegionList || false,
    states: regionsData?.length
      ? getCleanRegionList({ regionsData })
      : (!isFalconDeliverySelectionEnabled && state?.App?.fleetSalesRegions) || getTerritoryCodeList(countryCode) || [],
  };
};

export default connect(mapStateToProps)(StateDropdown);
