import React from 'react';
import _has from 'lodash/has';
import _get from 'lodash/get';
import { string, shape, bool } from 'prop-types';
import TextLoader from '../TextLoader';

/* Copy component */
const Copy = ({ group, field, component, classes, isEnabledForEditDesign, inheritClasses }) => {
  if (!group || !_has(group, field)) {
    return null;
  }
  const rn = _get(window.tesla, 'rn', null);
  const isEditDesign = !!rn;

  if (isEditDesign && !isEnabledForEditDesign) {
    return null;
  }

  return (
    <TextLoader
      data={group}
      field={field}
      tag={{ component }}
      inheritClasses={inheritClasses}
      className={`tds-text_color--30${classes ? ` ${classes}` : ''}`}
    />
  );
};

Copy.propTypes = {
  group: shape({
    selected: bool,
    code: string,
    name: string,
    formattedPrice: string,
  }).isRequired,
  field: string.isRequired,
  component: string,
  classes: string,
  isEnabledForEditDesign: bool,
  inheritClasses: bool,
};

Copy.defaultProps = {
  component: 'div',
  classes: '',
  isEnabledForEditDesign: true,
  inheritClasses: true,
};

export default Copy;
