import React from 'react';
import cx from 'classnames';
import { string, shape, number } from 'prop-types';

/* Icon component */
const Icon = ({ id, className, title, description, style, rotate, viewbox }) => {
  if (!id) {
    return null;
  }
  const { width = '16px', height = '16px' } = style;

  return (
    <svg
      className={cx('tds-icon', className)}
      style={{ ...{ width, height }, ...style }}
      viewBox={viewbox || '0 0 30 30'}
    >
      <title>{title || id}</title>
      <description>{description || id}</description>

      {id === 'tds-arrow' && (
        <path
          stroke="var(--tds-color--grey20)"
          strokeWidth="1.5"
          d="M10.5 17.5l4.5-4 4.5 4"
          fill="none"
          strokeLinecap="round"
          className="arrow"
          transform={`rotate(${rotate} 15 15)`}
        />
      )}

      {id === 'tds-plus' && (
        <line
          x1="15"
          y1="10"
          x2="15"
          y2="20"
          stroke="var(--tds-color--grey20)"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      )}

      {(id === 'tds-plus' || id === 'tds-minus') && (
        <line
          x1="10"
          y1="15"
          x2="20"
          y2="15"
          stroke="var(--tds-color--grey20)"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      )}

      {id === 'tds-info' && (
        <g fill="none" fillRule="evenodd">
          <circle
            stroke="var(--tds-color--grey40)"
            strokeWidth="1.5"
            cx="8"
            cy="8"
            r="7.25"
          ></circle>
          <g transform="translate(7.25 4)" fill="var(--tds-color--grey40)">
            <rect y="2.5" width="1.5" height="5.5" rx=".75"></rect>
            <rect width="1.5" height="1.5" rx=".75"></rect>
          </g>
        </g>
      )}

      {id === 'tds-status-success' && (
        <>
          <circle fill="#ffffff" cx="12" cy="12" r="6"></circle>
          <path
            d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm4.78 8.284-5.491 5.492a.75.75 0 0 1-1.061 0L7.47 13.018a.75.75 0 1 1 1.06-1.061l2.228 2.229 4.962-4.962a.75.75 0 1 1 1.06 1.06z"
            fill="#12BB00"
          />
        </>
      )}

      {id === 'tds-success-outline' && (
        <>
          <circle cx="12" cy="12" fill="var(--tds-dialog--background-color)" r="6" />
          <path
            d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18.5c-4.687 0-8.5-3.813-8.5-8.5S7.313 3.5 12 3.5c4.687 0 8.5 3.813 8.5 8.5s-3.813 8.5-8.5 8.5zm4.78-11.276a.75.75 0 0 1 0 1.06l-5.49 5.492a.75.75 0 0 1-1.061 0l-2.76-2.758a.75.75 0 1 1 1.061-1.061l2.229 2.229 4.96-4.962a.75.75 0 0 1 1.061 0z"
            fill="#12BB00"
          />
        </>
      )}

      {id === 'tds-error-outline' && (
        <>
          <circle cx="12" cy="12" fill="#fff" r="6" />
          <path
            d="M256,0C114.508,0,0,114.497,0,256c0,141.493,114.497,256,256,256c141.492,0,256-114.497,256-256    C512,114.507,397.503,0,256,0z M256,472c-119.384,0-216-96.607-216-216c0-119.385,96.607-216,216-216    c119.384,0,216,96.607,216,216C472,375.385,375.393,472,256,472z M343.586,315.302L284.284,256l59.302-59.302c7.81-7.81,7.811-20.473,0.001-28.284c-7.812-7.811-20.475-7.81-28.284,0    L256,227.716l-59.303-59.302c-7.809-7.811-20.474-7.811-28.284,0c-7.81,7.811-7.81,20.474,0.001,28.284L227.716,256    l-59.302,59.302c-7.811,7.811-7.812,20.474-0.001,28.284c7.813,7.812,20.476,7.809,28.284,0L256,284.284l59.303,59.302    c7.808,7.81,20.473,7.811,28.284,0C351.398,335.775,351.397,323.112,343.586,315.302z"
            fill="#df1f1f"
            data-original="#000000"
          />
        </>
      )}

      {id === 'tds-currency' && (
        <>
          <path
            d="M12.2336 8.39179C12.7712 8.53846 13.3088 8.80923 13.8464 9.15897L14.6752 7.72615C13.9808 7.22974 13.2192 6.90256 12.2896 6.76718V6H11.0016V6.74462C9.3776 6.90256 8.3248 7.82769 8.3248 9.24923V9.27179C8.3248 10.7836 9.2208 11.4831 11.0576 11.9795V14.1118C10.2848 13.9651 9.6464 13.5928 8.952 13.0626L8 14.4615C8.8288 15.1159 9.8928 15.5672 11.0016 15.7026V17H12.2896V15.7251C13.9136 15.5672 15 14.6195 15 13.2092V13.1867C15 11.7538 14.1488 10.9867 12.2224 10.4564V8.40308L12.2336 8.39179ZM11.0688 10.1292C10.296 9.86974 10.128 9.58769 10.128 9.14769V9.12513C10.128 8.68513 10.3968 8.35795 11.0688 8.26769V10.1179V10.1292ZM12.2336 12.2728C12.984 12.5323 13.2192 12.8144 13.2192 13.2769V13.2995C13.2192 13.7959 12.8832 14.0892 12.2336 14.1795V12.2728Z"
            fill="#5C5E62"
          />
        </>
      )}
    </svg>
  );
};

Icon.propTypes = {
  id: string.isRequired,
  className: string,
  description: string,
  title: string,
  style: shape({}),
  rotate: number,
};

Icon.defaultProps = {
  className: '',
  description: '',
  title: '',
  style: {},
  rotate: 0,
};

export default Icon;
