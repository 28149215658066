import { Storage } from '@tesla/coin-common-components';

import { LOCAL_STORAGE_KEY_ACCESSORIES } from 'dictionary';

import { getStates } from '@tesla/intl-display-names';

import { EMIRATE_VALUES } from 'dictionary';

const KEY = `${LOCAL_STORAGE_KEY_ACCESSORIES}.${window?.location?.pathname}`;
export const getSelectedAccessoriesFromStorage = () => Storage.get(KEY) || {};
export const saveAccessorySelected = selectedAccessories => Storage.set(KEY, selectedAccessories);
export const clearAccessorySelected = () => Storage.remove(KEY);

export const getSavedAccessoriesListFromWindow = () =>
  window?.tesla?.savedConfiguration?.accessories || [];

export const getValidSelectedAccessories = (selectedAccessories = {}, accessories = []) => {
  const savedAccessories = getSavedAccessoriesListFromWindow();
  const savedAccessoriesArray = savedAccessories.reduce((r, i) => {
    const { partNumber = null } = i;
    if (partNumber) {
      r.push(partNumber);
    }
    return r;
  }, []);
  return accessories.reduce((validSelected, accessory) => {
    const partNumbers = accessory.options.map(option => option.partNumber);
    const key = partNumbers.find(
      partNumber =>
        savedAccessoriesArray.includes(partNumber) || Boolean(selectedAccessories[partNumber])
    );
    return {
      ...validSelected,
      ...(key ? { [key]: accessory } : {}),
    };
  }, {});
};

export const getOptionByPartNumber = (partNumber, accessory) =>
  accessory?.options?.find(({ partNumber: value }) => value === partNumber);
export const getAccessoryByPartNumber = (partNumber, accessories) =>
  accessories.find(accessory => getOptionByPartNumber(partNumber, accessory));

export const getSelectedAccessoriesPriceTotal = selectedItems =>
  Object.entries(selectedItems).reduce((total, entry) => {
    const [key, value] = entry;
    const option = getOptionByPartNumber(key, value);
    const amount = option?.purchasePrice?.amount || 0;

    return total + amount;
  }, 0);

export const getAccessoriesLocationStates = state => {
  const { App } = state;
  const { countryCode, language } = App;
  const { regions = [] } = state?.Accessories?.geographies ?? {};
  const states = regions.length ? regions : getStates(countryCode, language);
  const locationStates = states?.map(state => ({
    value: EMIRATE_VALUES?.[state?.code] || state?.code || '',
    label: state?.label || state?.displayName || '',
  }));
  return locationStates || [];
};
