/* eslint-disable jsx-control-statements/jsx-jcs-no-undef */
import _isObject from 'lodash/isObject';

import { ADYEN_PAYMENTS, STRIPE_PAYMENTS } from 'dictionary';

/**
 * CreditCardEncryptor client-side payment encryption wrapper
 * Wrapper class to handle client-side encryption
 */
export default class CreditCardEncryptor {
  static providers = [ADYEN_PAYMENTS, STRIPE_PAYMENTS];

  /**
   * Encrypts payload with provider's key
   * @param {object} card ~ object with keys {number, name, cvc, month, year}
   * @returns {string|boolean} ~ false if validation failed, string with encrypted data
   */
  static async encrypt(provider = ADYEN_PAYMENTS, card, key, generationtime) {
    if (!CreditCardEncryptor.providers.includes(provider)) {
      return false;
    }
    if (provider === STRIPE_PAYMENTS) {
      // Stripe js lib needs to be included and visible
      if (typeof window.Stripe === 'undefined' || !_isObject(window.Stripe)) {
        return false;
      }

      Stripe.setPublishableKey(key);
      return new Promise(resolve => {
        Stripe.card.createToken(
          {
            name: card.name || '',
            number: card.number || '',
            cvc: card.cvc,
            exp_month: card.month || 0,
            exp_year: card.year || 0,
          },
          (status, response) => {
            if (response.error) {
              return resolve(false);
            }
            return resolve(response.id);
          }
        );
      });
    }

    if (typeof window.adyen === 'undefined' || !_isObject(window.adyen)) {
      return false;
    }

    const cseInstance = adyen.encrypt.createEncryption(key, { numberIgnoreNonNumeric: false });
    const obj = {
      number: `${card.number}` || '',
      cvc: `${card.cvc}` || '0',
      holderName: `${card.name}` || '',
      expiryMonth: `${card.month}` || '0',
      expiryYear: `${card.year}` || '0',
      generationtime,
    };

    return cseInstance.encrypt(obj);
  }
}
