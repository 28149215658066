export default ({ object, overrides = {} }) => ({
    object: {
        fetching: false,
        oms_params: {
            model: "",
            context: "",
            market: "",
            language: "",
            variant: ""
        },
        available_configurations: [],
        pricebook: null,
        pricebook_deltas: {},
        saved_config_delta: {},
        oms_pricebook: {meta_data:{},options:{},categories:{}},
        oms_groups: [],
        oms_baseOptions: {},
        lexicon: null,
        ...object,
        ...overrides,
    }
});