import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { i18n, htmlToReact } from 'utils';
import { setOptionExtended } from 'actions';
import classnames from 'classnames';

import TextLoader from '../TextLoader';
import { formatCurrency } from '@tesla/coin-common-components';

function VideoContentLoader(props) {
  const { content, isLayoutMobile, options, setOption, group, video_key } = props;
  const videoText =
    options && options.selected ? group.autopilot_fsd_selected : group.autopilot_fsd_unselected;
  const btnText = options && options.selected ? 'common.remove' : 'common.add';

  const showAddBtn = _get(options, 'price', 0) || false;
  if (!content || (!options || !showAddBtn)) {
    return null;
  }
  return (
    <div className="videoContent--container">
      <TextLoader
        data={content}
        field="title"
        className={classnames(
          'text-loader--subtitle tds-text--medium tds-text--h6 tds-o-letter-spacing',
          { 'tds-text_color--10': !isLayoutMobile },
          { 'tds-text_color--30': isLayoutMobile }
        )}
      />
      <TextLoader data={content} field="description" className="text-loader--description" />
      {!isLayoutMobile && <TextLoader data={content} field="label" className="videoLabel--badge" />}
      <div className="videoContent--label">
        <If condition={isLayoutMobile && options}>
          {showAddBtn && (
            <React.Fragment key={video_key}>
              <p className="videoContent--text" key={`${options.name}_label`}>
                {htmlToReact(
                  i18n(`${videoText}`, {
                    OPTION_NAME: options.name,
                    OPTION_PRICE: formatCurrency(options.price),
                  })
                )}
              </p>
              <button
                className={classnames(
                  'tds-btn  tds-btn--medium',
                  { 'videoContent--btn tds-btn--blue': !options.selected },
                  { 'videoContent--btn-white tds-btn--white': options.selected }
                )}
                onClick={() => setOption(options.set)}
                type="button"
              >
                {i18n(btnText)}
              </button>
            </React.Fragment>
          )}
        </If>
      </div>
    </div>
  );
}

VideoContentLoader.propTypes = {
  content: PropTypes.shape({}),
  options: PropTypes.shape({
    selected: PropTypes.bool,
    name: PropTypes.string,
    price: PropTypes.number || PropTypes.string,
    set: PropTypes.arrayOf(PropTypes.string),
  }),
  isLayoutMobile: PropTypes.bool,
  setOption: PropTypes.func,
  video_key: PropTypes.string,
  group: PropTypes.shape({
    autopilot_fsd_selected: PropTypes.string,
    autopilot_fsd_unselected: PropTypes.string,
    autopilot_included: PropTypes.string,
  }),
};

VideoContentLoader.defaultProps = {
  content: {},
  options: {},
  group: {},
  isLayoutMobile: false,
  video_key: '',
  setOption() {},
};

function mapStateToProps(state, ownProps) {
  const content_path = _get(ownProps, 'data', '');
  const optionsData = _get(ownProps, 'options', '');
  const content = _get(state, `${content_path}`, {});
  const options = _get(state, `${optionsData}`, {});
  return {
    content,
    isLayoutMobile: _get(state, 'App.isLayoutMobile', false),
    options,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setOption: option => {
      dispatch(setOptionExtended({ set: option }));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoContentLoader);
