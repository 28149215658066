const InitialState = {
  object: {
    applePaySessionRequested: false,
    applePaySession: false,
    applePaySessionError: false,
    applePaySessionErrorMsg: '',
    userHasApplePay: false,
    payment: null,
    enabled: false,
  },
};

export default InitialState;
