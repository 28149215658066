import { ALERT_ADD, ALERT_REMOVE, ALERT_CLEAR_AND_ADD } from 'dictionary';

export function addAlert({ message, withTrigger = false }) {
  return {
    type: ALERT_ADD,
    message,
    withTrigger,
  };
}

export function removeAlert({ message }) {
  return {
    type: ALERT_REMOVE,
    message,
  };
}

export function clearAndAddAlert({ message, withTrigger = false }) {
  return {
    type: ALERT_CLEAR_AND_ADD,
    message,
    withTrigger,
  };
}
