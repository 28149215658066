import React from 'react';
import ModalTrigger from '../../ModalTrigger';
import { string } from 'prop-types';
import { BANNER_MODAL, MODAL_SMALL } from 'dictionary';

/* BannerModalTrigger component */
const BannerModalTrigger = ({ contentSource, label }) => {
  return (
    <ModalTrigger
      options={{
        props: {
          componentName: BANNER_MODAL,
          props: {
            genericWrapper: true,
            size: MODAL_SMALL,
            containerCss: 'tds-display--inline tds-text--caption',
            bannerType: 'ira_modal',
            baseOnly: true,
            contentSource,
            classes: 'tds-o-text-align--left',
          },
        },
      }}
    >
      <span className='tds-link'>{label}</span>
    </ModalTrigger>
  );
};

BannerModalTrigger.propTypes = {
  contentSource: string,
  label: string,
};

BannerModalTrigger.defaultProps = {
  contentSource: '',
  label: '',
};

export default BannerModalTrigger;
