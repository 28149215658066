import {
  ACCESSORIES_TOGGLE_ITEM,
  ACCESSORIES_OPEN_MODAL,
  ACCESSORIES_CLOSE_MODAL,
  ACCESSORY_UPDATE_SHIP_TO_ADDRESS,
  ACCESSORY_UPDATE_TAX,
  ACCESSORY_ADDRESS_LINE_2,
  ACCESSORY_ADDRESS_TEXT,
  DESELECT_ACCESSORIES,
  GOOGLE_ADDRESS_NOT_FOUND,
  UPDATE_CUSTOM_ADDRESS_VALIDATION_COMPLETION,
  ACCESSORY_BIDDABLE_FLOW_OPT_IN,
  ACCESSORY_BIDDING_ELIGIBILITY,
  ACCESSORY_SET_CUSTOM_ADDRESS_VALIDITY,
  ACCESSORY_SET_ADDRESSES_AFTER_VALIDATION,
  ACCESSORY_SET_CUSTOM_ADDRESS,
  ACCESSORY_SET_SUGGESTED_ADDRESS,
  SET_PARTIAL_GOOGLE_ADDRESS,
  UPDATE_ACCESSORIES_LIST,
} from 'dictionary';
import Analytics from 'analytics';
import {
  getOptionByPartNumber,
  getAccessoryByPartNumber,
  getShipFromAddress,
  getSelectedAccessoriesFromStorage,
  getValidSelectedAccessories,
  getSelectedAccessoriesPriceTotal,
} from 'utils';

export default (state, { type, item, items, payload, error, isFetching }, { app_state }) => {
  const { useShipFromAddressPerProduct } = app_state?.App || {};
  switch (type) {
    case ACCESSORIES_TOGGLE_ITEM: {
      const { partNumber: key } = item;
      const {
        accessories: { list: accessoriesList },
        selectedItems: selectedAccessories,
      } = state;
      const { [key]: selectedItem, ...otherSelectedItems } = selectedAccessories || {};
      const accessory = getAccessoryByPartNumber(key, accessoriesList);
      const option = getOptionByPartNumber(key, accessory);
      const isSelected = Boolean(selectedItem);
      const entry = isSelected ? {} : { [key]: accessory };
      if (isSelected) {
        Analytics.fireTagEvent({
          event: Analytics.event,
          interaction: `remove-${selectedItem.name.toLowerCase().replaceAll(' ', '-')}`,
          'cfg-type': Analytics.cfgType,
        });
      } else {
        Analytics.fireTagEvent({
          event: Analytics.event,
          interaction: `add-${accessory.name.toLowerCase().replaceAll(' ', '-')}`,
          'cfg-type': Analytics.cfgType,
        });
      }
      const updatedSelectedItems = {
        ...otherSelectedItems,
        ...entry,
      };

      const priceDifference = (isSelected ? -1 : 1) * (option?.purchasePrice?.amount || 0);
      const grossTotal = state.grossTotal + priceDifference;

      return {
        ...state,
        selectedItems: updatedSelectedItems,
        grossTotal,
        errorState: null,
        ...(useShipFromAddressPerProduct
          ? {
              shipFromAddress: getShipFromAddress(updatedSelectedItems),
            }
          : {}),
      };
    }
    case ACCESSORY_ADDRESS_TEXT: {
      return {
        ...state,
        shippingAddressText: payload,
      };
    }

    case ACCESSORIES_OPEN_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          isOpen: true,
          accessories: items,
        },
      };

    case ACCESSORIES_CLOSE_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          isOpen: false,
        },
      };

    case ACCESSORY_UPDATE_SHIP_TO_ADDRESS: {
      const { shipToAddress, ...rest } = state;
      const { street2 } = shipToAddress;
      return {
        ...rest,
        shipToAddress: { ...payload, street2 },
      };
    }
    case ACCESSORY_UPDATE_TAX:
      return {
        ...state,
        taxData: payload,
      };
    case ACCESSORY_ADDRESS_LINE_2: {
      const { shipToAddress, ...rest } = state;
      return {
        ...rest,
        shipToAddress: { ...shipToAddress, street2: payload },
      };
    }
    case DESELECT_ACCESSORIES:
      return {
        ...state,
        selectedItems: {},
        grossTotal: 0,
        taxData: {},
        errorState: error,
        isOptInForBiddingFlow: false,
      };

    case ACCESSORY_BIDDABLE_FLOW_OPT_IN:
      return {
        ...state,
        isOptInForBiddingFlow: !state.isOptInForBiddingFlow,
      };
    case GOOGLE_ADDRESS_NOT_FOUND:
      return {
        ...state,
        googleAddressNotFound: true,
      };
    case UPDATE_CUSTOM_ADDRESS_VALIDATION_COMPLETION:
      return {
        ...state,
        customAddressValidationComplete: payload,
      };
    case SET_PARTIAL_GOOGLE_ADDRESS:
      return {
        ...state,
        partialGoogleAddress: payload,
      };
    case ACCESSORY_BIDDING_ELIGIBILITY:
      return {
        ...state,
        selectedItems: payload,
      };
    case ACCESSORY_SET_CUSTOM_ADDRESS_VALIDITY:
      return {
        ...state,
        isCustomAddressValid: payload,
      };
    case ACCESSORY_SET_ADDRESSES_AFTER_VALIDATION: {
      const { customAddress, suggestedAddress } = payload;
      return {
        ...state,
        customAddress,
        suggestedAddress,
      };
    }
    case ACCESSORY_SET_SUGGESTED_ADDRESS:
      return {
        ...state,
        isSuggestedAddressSelected: payload,
      };
    case ACCESSORY_SET_CUSTOM_ADDRESS:
      return {
        ...state,
        isCustomAddressSelected: payload,
      };
    case UPDATE_ACCESSORIES_LIST:
      const accessoriesFromStorage = getSelectedAccessoriesFromStorage();
      const { accessoriesList, geographies } = payload || {};
      const { list } = accessoriesList || {};
      const selectedItems = getValidSelectedAccessories(accessoriesFromStorage, list);
      return {
        ...state,
        accessories: accessoriesList,
        shipFromAddress: useShipFromAddressPerProduct
          ? getShipFromAddress(selectedItems)
          : list?.[0]?.shipFromAddress || {},
        selectedItems,
        grossTotal: getSelectedAccessoriesPriceTotal(selectedItems),
        isFetching,
        geographies,
      };
    default:
      return state;
  }
};
