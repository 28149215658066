/* @prettier */
import PropTypes from 'prop-types';

// Review page prop validation

const { shape, string, number, bool, func, oneOfType, arrayOf } = PropTypes;

export const common = {
  model: shape({}),
  modelCode: string,
  locale: string,
  market: string,
  stateProvince: string,
};

export const env = {
  connect: string,
  postConnectUrl: string,
  sibling: string,
};

export const device = {
  isMobile: bool,
  isTablet: bool,
};

export const configuration = {
  baseConfiguration: arrayOf(string),
  configuration: shape({}),
  options: arrayOf(string),
};

export const flags = {
  accountDetailsValid: bool,
  ccDetailsValid: bool,
  hideDestinationDocFee: bool,
  hideAfterSavingsPrice: bool,
  isEmployee: bool,
  isProfileExists: bool,
  isSaveDesignEnabled: bool,
  isPaymentDetailsValid: func,
  modifyOrder: bool,
  orderPlaced: bool,
  reversePricingOrder: bool,
  rnLoaded: bool,
  showRefundPolicy: bool,
  showRightOrder: bool,
  showContactUs: bool,
  showDisclaimerLink: bool,
  submitTried: bool,
  userHasApplePay: bool,
  validateCCDetails: bool,
  validConfigForReferral: bool,
  showSecondDepositDisclaimer: bool,
  legalConsentValid: bool,
};

export const data = {
  afterSavingsPrice: string,
  asset: shape({}),
  assetBaseUrl: string,
  beforeSavingsPrice: string,
  discounts: arrayOf(shape({})),
  financeTabs: arrayOf(shape({})),
  financeTypeLabel: string,
  selectedFinanceType: string,
  referralData: shape({}),
  cashPrice: shape({}),
  paymentTypes: arrayOf(string),
  depositAmount: number,
  financeModalProps: shape({}),
};

export const funcs = {
  cancelUpdateOrder: func,
  editDesign: func,
  externalLinkClicked: func,
  handleApplePayBtnClick: func,
  onTypeChange: func,
  saveMarketingConfig: func,
  triggerPostConnectRedirect: func,
  validateAndPlaceOrder: func,
  validateAndPlacePublicOrder: func,
  setPricingDetails: func,
};

export const ApplePayProps = {
  dispatchApplePaySessionRequest: func,
  dispatchApplePaySessionSuccess: func,
  dispatchApplePaySessionFailure: func,
};

export const incentiveTypeProps = oneOfType([
  shape({
    amount: number,
    longName: string,
  }),
  arrayOf(shape()),
]);

export default {
  ...common,
  ...env,
  ...device,
  ...configuration,
  ...flags,
  ...data,
  ...funcs,
};
