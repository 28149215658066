import React, { Suspense, useEffect, useState } from 'react';
import { arrayOf, shape, string, bool, func, number } from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import _throttle from 'lodash/throttle';
import _debounce from 'lodash/debounce';
import classnames from 'classnames';
import { Storage } from '@tesla/coin-common-components';
import { startIndicatingFocus, stopIndicatingFocus } from '@tesla/design-system';
import { validatePostalCode } from '@tesla/intl-address';
import DOMPurify from 'dompurify';
import {
  fetchOMS,
  userDevice,
  getUserLocation,
  updateRegistration,
  updateCreditCardDetails,
  updateScreenLayout,
  navigationSelectKey,
  openModal,
  openFeatureModal,
  addAlert,
  selectTab,
  getPickupLocations,
  getRequestCallBackLocations,
  getGeoInfoByPostalCode,
  setOption,
  setRegistrationType,
  toggleFinancePanelDrivertype,
  toggleTrimSavingsPrice,
  togglePotentialSavings,
  checkSession,
  getInventoryAvailability,
  setHasAvailableInventory,
  setOptionExtended,
  updateDeliveryDetails,
  getAccessories,
  startLoader,
  removeLoader,
  logActivitySession,
  triggerAnalyticsEvent,
} from 'actions';
import {
  i18n,
  getLayoutTypes,
  getQueryParameters,
  filterSalesRegion,
  isHashBlockedFromReLoading,
  isProdEnv,
  getLocationCookieShape,
  smoothScrollToError,
  changeQueryParams,
} from 'utils';
import { getProductTypeMapping } from 'selectors';
import GioStatistics from 'gioStatistics';
import Analytics from 'analytics';
import {
  SCREEN_LAYOUT_MOBILE,
  SCREEN_LAYOUT_TABLET,
  SCREEN_LAYOUT_DESKTOP,
  REFERRAL_MODAL,
  MODAL_SMALL,
  MODAL_REQUIRED,
  USER_LOCATION_MODAL,
  GENERIC_ERROR,
  REGISTRATION_TYPE_PRIVATE,
  DRIVER_TYPE_PERSONAL,
  NAVIGATION_VIEW_OVERVIEW,
  NAVIGATION_VIEW_PAYMENT,
  NAVIGATION_VIEW_CONFIRMATION,
  MOBILE_APP_ACTION_SHOW_HEADER,
  MOBILE_APP_ACTION_FETCH_PRODUCT_LIST,
  GEOIP_LOCATION_CHANGED,
  FIELD_DELIVERY_STATE_PROVINCE,
  FINANCE_MODAL_TAB_OPTIONS,
  FINANCE_MODAL_TAB_FINANCE,
  PAYMENT_FAILED,
  SHOW_RANGE_MODAL,
  STD_FEATURES,
  FSD_FEATURES,
  FINANCE_MODAL_OPEN,
  SHOW_SAVINGS_MODAL,
  OPTIMIZELY_EXP,
  EXPERIMENT_DELIVERY_LOCATION_OFF,
  PAGE_LOAD_EVENT,
  SERVER_PAYMENT_ERROR_EVENT,
} from 'dictionary';
import Debugger from './Debugger';
import SideScroll from './SideScroll';
import Spinner from '../components/Loaders/Spinner';
import CarouselModalContainer from '../components/CarouselModal/groupContainer';
import AccessoriesModalContainer from '../components/CarouselModal/accessoriesContainer';
import FinanceCarouselModalContainer from '../components/CarouselModal/financeContainer';
import EnterprisePostOrderModal from './Enterprise/PostOrderModal';
import Footer from '../components/Footer';
import useGoogleOptimize from '../hooks/useGoogleOptimize';
import useScript from '../hooks/useScript';
import HideSavingsToggle from '../context/hideSavingsToggle';
import { lazyWithPreload } from '../components/Lazy';
import { openFinanceModalStandalone } from '../reducers';

const CarouselModalStyles = lazyWithPreload(() =>
  import(/* webpackChunkName: "carousel-theme" */ '../components/CarouselModal/styles')
);

const CarouselModalStylesWithFallback = lazyWithPreload(() =>
  import(/* webpackChunkName: "carousel-theme-fb" */ '../components/CarouselModal/styles.fb')
);

const CyberpunkTheme = lazyWithPreload(() =>
  import(/* webpackChunkName: "cyber-theme" */ './cyberpunk')
);

const CyberpunkReloadedTheme = lazyWithPreload(() =>
  import(/* webpackChunkName: "cyber-reloaded-theme" */ './cyberpunk-reloaded')
);

const DarkTheme = lazyWithPreload(() => import(/* webpackChunkName: "dark-theme" */ './dark'));

const WebChatMobileStyles = lazyWithPreload(() =>
  import(/* webpackChunkName: "webchat-styles-mobile" */ './webchat-mobile')
);

const EnterpriseTheme = lazyWithPreload(() =>
  import(/* webpackChunkName: "enterprise-theme" */ './enterprise')
);

const Header = lazyWithPreload(() =>
  import(/* webpackChunkName: "header" */ '../components/header')
);
const FederalTax = lazyWithPreload(() =>
  import(/* webpackChunkName: "federal-tax" */ '../components/FederalTax')
);
const ModalComponent = lazyWithPreload(() =>
  import(/* webpackChunkName: "modal" */ '../components/Modal')
);

const PageHeader = lazyWithPreload(() =>
  import(/* webpackChunkName: "t4b-header" */ '@tesla/t4b-header')
);

let addressEventNotTriggered = true;

const getCarouselModalStyles = () => {
  const supportsDeviceViewportUnits =
    typeof CSS?.supports === 'function' ? CSS.supports('height:100dvh') : false;
  if (supportsDeviceViewportUnits) {
    return <CarouselModalStyles />;
  } else {
    return <CarouselModalStylesWithFallback />;
  }
};

const Main = ({
  abTestExperimentIds,
  activeTabs,
  assetsBaseURL,
  availableCountrySelection,
  baseUrl,
  canModifyOrder,
  checkAvailableInventoryForMarket,
  containerBG,
  countryCode,
  CreditCardDetail,
  dispatch,
  doSessionStartOnMount,
  dsFunctionsEnabled,
  enableCyberpunk,
  experimentId,
  extra_content,
  geoIp,
  headerState,
  hideSectionsOnFooter,
  inventoryZipCode,
  isAccessoriesEnabled,
  isAvayaChatEnabled,
  isAvayaChatLiteEnabled,
  isAvayaChatMobileEnabled,
  isAvayaChatTeslaAssistEnabled,
  isCoinReloaded,
  isDeliveryDetailsRequired,
  isDeliverySelectionEnabled,
  isDm,
  isEarlyAvailabilityEnabled,
  isEnterpriseCustomOrderEdit,
  isEnterpriseOrder,
  isGetUserLocationEnabled,
  isLayoutMobile,
  isLayoutTablet,
  isLocationSelectorEnabled,
  isNativePaymentEnabled,
  isPickupLocationsEnabled,
  isPostOrderSwap,
  isPreferredAddressEnabled,
  preferredAddressScriptUrl,
  isPreOrder,
  isRequestCallbackLocationsEnabled,
  isSessionSet,
  isWebChatEnabled,
  isWeChatMiniEnv,
  logoutURL,
  modelCode,
  optimizelyExpIds,
  option_codes_saved,
  PaymentServerErrors,
  postalCode,
  product,
  productType,
  referral,
  section,
  showEmirateSelection,
  showSinglePage,
  showSummaryPanelFooter,
  subsidiaryAccounts,
  t4bAccountId,
  t4bNewMarketExpEnabled,
  t4bNotifications,
  t4bServiceTabEnabled,
  t4bShopTabEnabled,
  uiLocale,
  uiThemeReplicant,
  useTopBanner,
  webChatHost,
  isCookieConsentLightNeeded,
}) => {
  const analytics = window?.tesla?.env?.analytics || window?.teslaConfig?.analytics;
  const queryParameters = getQueryParameters();

  // Update CreditCard details
  useEffect(() => {
    if (CreditCardDetail?.IsProfileExists) {
      dispatch(updateCreditCardDetails(CreditCardDetail));
    }
  }, [CreditCardDetail?.IsProfileExists]);

  // Payment API Errors
  useEffect(() => {
    if (PaymentServerErrors?.[GENERIC_ERROR]) {
      dispatch(addAlert({ message: i18n('common.errors__genericOrderProcessing') }));
      dispatch(triggerAnalyticsEvent({ type: SERVER_PAYMENT_ERROR_EVENT }));
    }
  }, [PaymentServerErrors]);

  useEffect(() => {
    if (isPostOrderSwap && window?.ReactNativeWebView) {
      switch (section) {
        case NAVIGATION_VIEW_OVERVIEW:
          const overviewHeader = JSON.stringify({
            action: 'setHeaderText',
            payload: i18n('common.details'),
          });
          window?.ReactNativeWebView?.postMessage(overviewHeader);
          break;
        case NAVIGATION_VIEW_PAYMENT:
          if (countryCode !== 'CN') {
            const message = JSON.stringify({
              action: 'setHeaderText',
              payload: i18n('Review.summary_label'),
            });
            window?.ReactNativeWebView?.postMessage(message);
          }
          break;
        case NAVIGATION_VIEW_CONFIRMATION:
          const hideHeader = JSON.stringify({
            action: MOBILE_APP_ACTION_SHOW_HEADER,
            payload: false,
          });
          window?.ReactNativeWebView?.postMessage(hideHeader);

          const fetchProductList = JSON.stringify({
            action: MOBILE_APP_ACTION_FETCH_PRODUCT_LIST,
          });
          window?.ReactNativeWebView?.postMessage(fetchProductList);
          break;
        default:
          break;
      }
    }
  }, [section]);

  const [hideSavingsToggle, setHideSavingsToggle] = useState(false);

  useGoogleOptimize(abTestExperimentIds?.potential_savings, variant => {
    if (variant === '1') {
      dispatch(toggleTrimSavingsPrice(false));
      dispatch(togglePotentialSavings(true));
      setHideSavingsToggle(true);
    }
  });

  const containerPrefix = isLayoutMobile ? 'mobile' : isLayoutTablet ? 'tablet' : 'desktop';
  const displayContainerBG = containerBG && !isLayoutMobile && !isLayoutTablet;
  const isDesktop = !isLayoutMobile && !isLayoutTablet;
  const showHeader = !isEnterpriseOrder && !isDm; // no header for enterprise or in-app
  const onKeydown = () => startIndicatingFocus(document.body);
  const onMousemove = _throttle(() => stopIndicatingFocus(document.body), 300, { leading: true });

  const onHashChange = () => {
    setTimeout(() => {
      window.scroll(0, 0);
    }, 200);
    if ((!isDeliverySelectionEnabled || isDm) && !isHashBlockedFromReLoading()) {
      dispatch(navigationSelectKey({ key: window.location.hash.substring(1) }));
    }
  };
  const detectScreenSize = _debounce(() => {
    const { isLayoutMobile, isLayoutTablet, isLayoutMobileAdjusted } = getLayoutTypes();

    if (isLayoutMobile) {
      return dispatch(updateScreenLayout(SCREEN_LAYOUT_MOBILE, !!isLayoutMobileAdjusted));
    } else if (isLayoutTablet) {
      return dispatch(updateScreenLayout(SCREEN_LAYOUT_TABLET, !!isLayoutMobileAdjusted));
    } else {
      return dispatch(updateScreenLayout(SCREEN_LAYOUT_DESKTOP));
    }
  }, 300);

  const openModalFromQueryParams = () => {
    let { modal = '', tab = '', id = '', financeModalTab = '', financeProduct = '' } =
      queryParameters || {};

    // COIN-15690: allow for backward compatibility
    if (financeModalTab) {
      modal = FINANCE_MODAL_TAB_FINANCE;
      id = financeProduct;

      const tabMapping = isCoinReloaded
        ? {
            prequalify: 'prequal',
            gas_savings: 'savings',
          }[financeModalTab] || financeModalTab
        : financeModalTab;

      tab = financeModalTab === FINANCE_MODAL_TAB_OPTIONS && isCoinReloaded ? '' : tabMapping;
    } else if (modal === 'finance' && !tab) {
      tab = FINANCE_MODAL_TAB_OPTIONS;
    }

    if (!modal) return null;

    const modalAction = {
      range: () => dispatch({ type: SHOW_RANGE_MODAL }),
      finance: () => {
        if (isCoinReloaded) {
          dispatch(
            openFinanceModalStandalone(
              {
                selectedView: `${tab}`.toUpperCase() ?? 'FINANCE',
              },
              countryCode === 'CN'
            )
          );

          if (id) {
            dispatch(selectTab(id));
          }

          return;
        }

        const isFinanceTabValid = activeTabs.includes(tab);

        if (!isFinanceTabValid) return null;

        if (id) {
          dispatch(selectTab(id));
        }

        dispatch({
          type: FINANCE_MODAL_OPEN,
          payload: {
            selectedView: tab,
          },
        });
      },
      features: () => {
        dispatch(
          openFeatureModal({
            groupName: id || STD_FEATURES,
            modalClasses: id === FSD_FEATURES && isCoinReloaded ? 'tds-o-mega-modal' : '',
          })
        );
      },
    }[modal];

    if (modalAction) {
      modalAction();
    }
  };
  if (isGetUserLocationEnabled && isPreferredAddressEnabled && preferredAddressScriptUrl) {
    const locationScript = `${webChatHost}/web-chat.js`;
    const locationInit = () => {
      if (typeof window.runLocationDetection === 'function') {
        const isEarlyAvailabilityOn = isEarlyAvailabilityEnabled && !optimizelyExpIds?.includes(EXPERIMENT_DELIVERY_LOCATION_OFF);
        window.runLocationDetection({
          progressHandler: () => {
            if (!isEarlyAvailabilityOn) {
              dispatch(startLoader());
            }
          },
          fallback: true,
          isGpsEnabled: isEarlyAvailabilityOn || false,
        });
        setTimeout(() => {
          document.dispatchEvent(new Event('addressDataFbCheck'));
        }, 300);
      } else {
        document.dispatchEvent(new Event('addressDataFbCheck'));
      }
    };

    useScript(preferredAddressScriptUrl, 'LocationScript', 'script', locationInit);
  }

  // ComponentDidMount
  useEffect(() => {
    const isInventory = product?.isInventory;
    const vehicleId = _get(product, 'data.VIN', '');
    const inventoryUpgrades = isInventory ? Storage.get(`${vehicleId}-selected-upgrades`) : null;
    const inventoryDeliveryDetailsStorage = isInventory
      ? Storage.get(`${vehicleId}-delivery-details`)
      : null;
    dispatch(fetchOMS());
    const location = getLocationCookieShape();
    setTimeout(() => {
      dispatch(userDevice());
      // If inventory and location in storage use storage location information
      if (
        !isPreferredAddressEnabled &&
        isInventory &&
        _isEmpty(inventoryDeliveryDetailsStorage) &&
        queryParameters?.token &&
        !queryParameters?.postal
      ) {
        return dispatch({
          type: GEOIP_LOCATION_CHANGED,
          location,
        });
      }
      if (isGetUserLocationEnabled && !isPreferredAddressEnabled) {
        dispatch(getUserLocation());
      }
      // Swap or T4B lookup by postal code
      dispatch(getGeoInfoByPostalCode());
      dispatch(updateRegistration());
    }, 200);
    dispatch(navigationSelectKey({ key: section, force: true }));
    if (isPickupLocationsEnabled) {
      dispatch(getPickupLocations());
    }
    if (isRequestCallbackLocationsEnabled) {
      dispatch(getRequestCallBackLocations(uiLocale));
    }

    const { showLocationSelection } = filterSalesRegion();
    // serverErrors are from redirect payment injection
    if (!_isEmpty(referral) && _get(referral, 'isValid') && _isEmpty(PaymentServerErrors)) {
      dispatch(
        openModal(REFERRAL_MODAL, {
          className: 'session-time-out--container',
          genericWrapper: false,
        })
      );
    } else if (
      isLocationSelectorEnabled &&
      !isDeliveryDetailsRequired &&
      !canModifyOrder &&
      !isEnterpriseCustomOrderEdit &&
      (!product || (product && showLocationSelection))
    ) {
      dispatch(
        openModal(USER_LOCATION_MODAL, {
          className: 'session-time-out--container',
          genericWrapper: true,
          size: MODAL_SMALL,
        })
      );
    }

    openModalFromQueryParams();

    if (showEmirateSelection && section === NAVIGATION_VIEW_OVERVIEW) {
      dispatch(updateDeliveryDetails({ [FIELD_DELIVERY_STATE_PROVINCE]: '' }));
      dispatch(
        openModal(USER_LOCATION_MODAL, {
          className: 'session-time-out--container',
          genericWrapper: true,
          size: MODAL_SMALL,
          type: MODAL_REQUIRED,
        })
      );
    }

    if (inventoryUpgrades?.selectedUpgrades?.length) {
      dispatch(
        setOptionExtended({
          set: inventoryUpgrades?.selectedUpgrades,
        })
      );
    }
  }, []);

  useGoogleOptimize(
    experimentId,
    variant => {
      const getVariant = _find(extra_content, { type: `variant:${variant}` });
      if (typeof getVariant !== 'undefined') {
        const { content = [] } = getVariant;
        if (content.length && !option_codes_saved.length) {
          dispatch(setOption({ set: content }, { dontKeepTrack: true }));
        }
      }
    },
    [experimentId]
  );

  if (countryCode === 'CN' && isWebChatEnabled) {
    const webchatJs = `${webChatHost}/web-chat.js`;
    const webchatCss = `${webChatHost}/web-chat.css`;
    const webChatInit = () => {
      window.Webchat.init();
    };

    useScript(webchatJs, 'Webchat', 'script', webChatInit);
    useScript(webchatCss, 'Webchat', 'style');
  }

  let avayaJs = '';
  let avayaCss = '';
  let avayaInit = null;
  if (!isWebChatEnabled && isAvayaChatEnabled) {
    const normalizedLocale = (uiLocale || '').toLowerCase().replace('_', '-');
    const chatObj = {
      locale: normalizedLocale,
      chat_lite: isAvayaChatLiteEnabled,
      mobile: isAvayaChatMobileEnabled,
      tesla_assist: isAvayaChatTeslaAssistEnabled,
      options: {
        apiEndpointsDomain: baseUrl,
      },
    };
    avayaInit = () => {
      window.avaya = {
        ...(window.avaya || {}),
        ...chatObj,
      };
      if (!window?.avaya?.is_initialized && typeof window?.avaya?.init_chat === 'function') {
        window.avaya.init_chat();
      }
    };
    avayaJs = `${baseUrl}/cua/assets/apps/oceana/chat-ui.js`;
    avayaCss = `${baseUrl}/cua/assets/apps/oceana/chat-ui.css`;
  }

  useScript(avayaJs, 'avaya', 'script', avayaInit, 2000);
  useScript(avayaCss, 'avaya', 'style', null, 2000);

  const onScroll = _throttle(() => {
    const galleryFixed = document.querySelector('.cf-asset-wrapper--fixed');
    const headerContainer = document.querySelector('.site-header-container');
    const body = document.querySelector('body.coin-reloaded');
    const chatEntrance = document.querySelector('.coin-reloaded .web-chat .web-chat-entrance');
    if (galleryFixed && headerContainer) {
      if (window.pageYOffset > 20) {
        body?.classList.add('coin-navup');
        if (isLayoutMobile && chatEntrance) {
          chatEntrance.classList.add('top-banner--hidden');
        }
      } else {
        body?.classList.remove('coin-navup');
        if (isLayoutMobile && chatEntrance) {
          chatEntrance.classList.remove('top-banner--hidden');
        }
      }
    }
    if (body?.classList.contains('has-top-banner')) {
      const topBanner = document.querySelector('.top-banner');
      if (topBanner && topBanner.clientHeight < window.scrollY) {
        body.classList.add('has-top-banner--hidden');
      } else {
        body.classList.remove('has-top-banner--hidden');
      }
    }
  }, 100);

  const onAddressData = event => {
    addressEventNotTriggered = false;

    const data = event?.detail;
    const emptyShape = getLocationCookieShape();
    if (!data) {
      dispatch({
        type: GEOIP_LOCATION_CHANGED,
        location: emptyShape,
      });
    } else {
      const { countryCode: countryCodeFromData } = data;
      const locationObj = countryCode === countryCodeFromData ? data : emptyShape;
      dispatch({
        type: GEOIP_LOCATION_CHANGED,
        location: locationObj,
      });
    }
    dispatch(removeLoader());
  };

  const onAddressDataFbCheck = event => {
    if (addressEventNotTriggered) {
      dispatch({
        type: GEOIP_LOCATION_CHANGED,
        location: getLocationCookieShape(),
      });
    }
  };

  const onCookieConsentLightGiven = event => {
    Analytics.enabled = true;
    dispatch(triggerAnalyticsEvent({ type: PAGE_LOAD_EVENT }));
  }

  const onOptimizelyExp = event => {
    dispatch({
      type: OPTIMIZELY_EXP,
      id: DOMPurify.sanitize(event?.detail?.expId),
    });
  };

  // Macro Level Event Listeners
  useEffect(() => {
    if (isPreferredAddressEnabled) {
      document.addEventListener('addressData', onAddressData);
      document.addEventListener('addressDataFbCheck', onAddressDataFbCheck);
    }
    if (isCookieConsentLightNeeded) {
      document.addEventListener('cookieConsentLightGiven', onCookieConsentLightGiven);
    }
    window.addEventListener('optimizelyExp', onOptimizelyExp, false);
    window.addEventListener('resize', detectScreenSize, false);
    if (!showSinglePage) {
      window.addEventListener('hashchange', onHashChange);
    }

    document.body.addEventListener('keydown', onKeydown);
    document.body.addEventListener('mousemove', onMousemove);
    if (countryCode === 'CN') {
      GioStatistics.start();
    }

    window.addEventListener('scroll', onScroll, { passive: true, capture: true });
    if (isCoinReloaded && window.location.hash.substring(1) === NAVIGATION_VIEW_PAYMENT) {
      setTimeout(() => {
        smoothScrollToError(PAYMENT_FAILED, isDesktop);
      }, 7000);
    }
    return () => {
      window.removeEventListener('resize', detectScreenSize, false);
      if (!showSinglePage) {
        window.removeEventListener('hashchange', onHashChange);
      }
      window.removeEventListener('optimizelyExp', onOptimizelyExp, false);

      document.body.removeEventListener('keydown', onKeydown);
      document.body.removeEventListener('mousemove', onMousemove);
      window.removeEventListener('scroll', onScroll, { passive: true, capture: true });
      if (isPreferredAddressEnabled) {
        document.removeEventListener('addressData', onAddressData);
        document.removeEventListener('addressDataFbCheck', onAddressDataFbCheck);
      }
      if (isCookieConsentLightNeeded) {
        document.removeEventListener('cookieConsentLightGiven', onCookieConsentLightGiven);
      }
      if (countryCode === 'CN') {
        GioStatistics.remove();
      }
    };
  }, []);

  // Set registration and driver type to private for mobile app
  useEffect(() => {
    if (isNativePaymentEnabled) {
      dispatch(setRegistrationType(REGISTRATION_TYPE_PRIVATE));
      dispatch(toggleFinancePanelDrivertype(DRIVER_TYPE_PERSONAL));
    }
  }, []);

  useEffect(() => {
    if (doSessionStartOnMount && !isSessionSet) {
      dispatch(checkSession());
    } else if (isSessionSet) {
      dispatch(logActivitySession());
    }
    if (isSessionSet && isAccessoriesEnabled) {
      dispatch(getAccessories());
    }
  }, [doSessionStartOnMount, isSessionSet]);

  const dependencies = checkAvailableInventoryForMarket
    ? [isSessionSet]
    : [isSessionSet, postalCode];

  useEffect(() => {
    const { location = {}, region = {} } = geoIp;
    const { regionCode } = region || {};
    const { latitude = '', longitude = '' } = location;
    const validCoordinates = !!(
      latitude &&
      longitude &&
      postalCode &&
      validatePostalCode(postalCode, { countryCode })
    );
    const enabledForFlow = !enableCyberpunk && !isEnterpriseOrder && !product && !isDm;
    if (
      enabledForFlow &&
      isSessionSet &&
      (checkAvailableInventoryForMarket || (inventoryZipCode && validCoordinates))
    ) {
      const geoData = checkAvailableInventoryForMarket ? {} : { lat: latitude, long: longitude };
      getInventoryAvailability(
        { ...{ market: countryCode, model: modelCode }, ...geoData },
        (err, res) => {
          if (res) {
            const { available = false, subset = false } = res;
            dispatch(
              setHasAvailableInventory({
                available,
                subset,
                latitude,
                longitude,
                postalCode,
                regionCode,
              })
            );
          }
        }
      );
    }
  }, dependencies);

  useEffect(() => {
    if (analytics && !isCookieConsentLightNeeded) {
      Analytics.enabled = !!analytics;
    }
  }, [analytics]);

  const getCybertruckTheme = () => {
    if (!enableCyberpunk) {
      return null;
    }
    if (isCoinReloaded) {
      return <CyberpunkReloadedTheme />;
    }
    return <CyberpunkTheme />;
  };

  const hasTopBanner = isCoinReloaded && useTopBanner;

  return (
    <HideSavingsToggle value={hideSavingsToggle}>
      <If condition={hasTopBanner && section === NAVIGATION_VIEW_OVERVIEW}>
        <Suspense fallback={<></>}>
          <FederalTax showTaxValue={false} />
        </Suspense>
      </If>
      <If condition={showHeader}>
        <Suspense fallback={<></>}>
          <Header
            dark={!!isDm || !!enableCyberpunk}
            locale={uiLocale}
            isWeChatMiniEnv={isWeChatMiniEnv}
            availableLocales={availableCountrySelection}
            country={countryCode}
          />
        </Suspense>
      </If>
      <If condition={isEnterpriseOrder && t4bAccountId}>
        <Suspense fallback={<></>}>
          <div className="site-header-container">
            <PageHeader
              accountId={t4bAccountId}
              accountsList={subsidiaryAccounts}
              headerState={{ ...headerState, locale: uiLocale }}
              logout={() => null}
              logoutURL={logoutURL}
              isCNEnv={false}
              isProdEnv={isProdEnv()}
              enableShopTab={t4bShopTabEnabled}
              enableNewMarketExp={t4bNewMarketExpEnabled}
              disableLocaleSelector
              notificationList={t4bNotifications?.data || []}
              enableServiceTab={t4bServiceTabEnabled}
            />
          </div>
        </Suspense>
      </If>

      {/* Main */}
      <div
        className={classnames(
          'tds-shell-content main-page',
          'cf-shell-content',
          `main-page--${containerPrefix}`,
          { 'cf-shell--desktop': isDesktop },
          { [uiThemeReplicant]: uiThemeReplicant },
          `${section}-page`,
          `finance-type-${productType}`,
          { 'cf-preorder': isPreOrder }
        )}
        style={
          displayContainerBG
            ? {
                backgroundImage: `url(${assetsBaseURL}${containerBG})`,
              }
            : null
        }
      >
        <Suspense fallback={<></>}>
          <ModalComponent />
        </Suspense>

        <If
          condition={
            !hasTopBanner &&
            (isLayoutMobile || isLayoutTablet) &&
            (countryCode !== 'CN' || !isCoinReloaded) &&
            section === NAVIGATION_VIEW_OVERVIEW
          }
        >
          <Suspense fallback={<></>}>
            <FederalTax showTaxValue={false} />
          </Suspense>
        </If>

        <Suspense key="Main.Suspense.SideScroll" fallback={<Spinner active />}>
          <SideScroll />
        </Suspense>
        <Suspense key="Main.Suspense.CyberpunkTheme" fallback={<></>}>
          {getCybertruckTheme()}
        </Suspense>
        <Suspense key="Main.Suspense.EnterpriseTheme" fallback={<></>}>
          {!!isEnterpriseOrder && <EnterpriseTheme />}
        </Suspense>
        <Suspense key="Main.Suspense.DarkTheme" fallback={<></>}>
          {!!isDm && <DarkTheme />}
        </Suspense>
        <Suspense key="Main.Suspense.WebChatStyles" fallback={<></>}>
          {!!isWebChatEnabled && <WebChatMobileStyles />}
        </Suspense>
        <Suspense key="Main.Suspense.CarouselModalStyles" fallback={<></>}>
          {getCarouselModalStyles()}
        </Suspense>
        <Spinner />
        <If condition={dsFunctionsEnabled}>
          <Debugger />
        </If>
        <CarouselModalContainer />
        <AccessoriesModalContainer />
        <FinanceCarouselModalContainer />
        <EnterprisePostOrderModal />
      </div>
      {/* Footer */}
      <Choose>
        <When
          condition={isCoinReloaded && (!isEnterpriseOrder || section === NAVIGATION_VIEW_OVERVIEW)}
        >
          <div className="aside-footer--container option-widget">
            <footer
              id="footer"
              data-id="footer"
              className={classnames('aside-footer', {
                [uiThemeReplicant]: uiThemeReplicant,
                'tds-theme--dk': isDm,
              })}
            >
              <div className={classnames('tds-flex')} style={{ bottom: 160 }}>
                <div
                  className={classnames(
                    { 'tds-layout-item': !isDesktop },
                    'tds-flex',
                    'tds-flex-item'
                  )}
                >
                  <Footer />
                </div>
              </div>
            </footer>
          </div>
        </When>
        <When condition={showSummaryPanelFooter && !hideSectionsOnFooter.includes(section)}>
          <footer
            id="footer"
            data-id="footer"
            className={classnames('tds-shell-footer', {
              [uiThemeReplicant]: uiThemeReplicant,
              'tds-theme--dk': isDm,
            })}
          >
            <div
              className={classnames('tds-flex', `cf-footer-layout cf-footer--${containerPrefix}`)}
              style={{ bottom: 160 }}
            >
              <div
                className={classnames(
                  { 'tds-layout-item': !isDesktop },
                  'tds-flex',
                  'tds-flex-item'
                )}
              >
                <Footer />
              </div>
            </div>
          </footer>
        </When>
      </Choose>
      <div
        id="enterprise-modal-root"
        className={classnames({ [uiThemeReplicant]: uiThemeReplicant })}
      />
    </HideSavingsToggle>
  );
};

Main.propTypes = {
  abTestExperimentIds: shape({}),
  activeTabs: arrayOf(string),
  assetsBaseURL: string,
  availableCountrySelection: arrayOf(string),
  baseUrl: string,
  canModifyOrder: bool,
  checkAvailableInventoryForMarket: bool,
  containerBG: string,
  countryCode: string,
  CreditCardDetail: shape({}),
  dispatch: func,
  doSessionStartOnMount: bool,
  dsFunctionsEnabled: bool,
  enableCyberpunk: bool,
  experimentId: string,
  extra_content: shape({}),
  headerState: shape({}),
  hideSectionsOnFooter: arrayOf(string),
  inventoryZipCode: bool,
  isAccessoriesEnabled: bool,
  isAvayaChatEnabled: bool,
  isAvayaChatLiteEnabled: bool,
  isAvayaChatMobileEnabled: bool,
  isAvayaChatTeslaAssistEnabled: bool,
  isCoinReloaded: bool,
  isDeliveryDetailsRequired: bool,
  isDeliverySelectionEnabled: bool,
  isDm: bool,
  isEarlyAvailabilityEnabled: bool,
  isEnterpriseCustomOrderEdit: bool,
  isEnterpriseOrder: bool,
  isGetUserLocationEnabled: bool,
  isLayoutMobile: bool,
  isLayoutTablet: bool,
  isLocationSelectorEnabled: bool,
  isNativePaymentEnabled: bool,
  isPickupLocationsEnabled: bool,
  isPostOrderSwap: bool,
  isPreferredAddressEnabled: bool,
  preferredAddressScriptUrl: string,
  isPreOrder: bool,
  isRequestCallbackLocationsEnabled: bool,
  isSessionSet: bool,
  isWebChatEnabled: bool,
  isWeChatMiniEnv: bool,
  logoutURL: string,
  modelCode: string,
  optimizelyExpIds: arrayOf(string),
  option_codes_saved: arrayOf(string),
  PaymentServerErrors: shape({}),
  product: shape({}),
  productType: string,
  referral: shape({}),
  section: string,
  showEmirateSelection: bool,
  showSinglePage: bool,
  showSummaryPanelFooter: bool,
  subsidiaryAccounts: arrayOf(string),
  t4bAccountId: string,
  t4bNewMarketExpEnabled: bool,
  t4bNotifications: arrayOf(string),
  t4bServiceTabEnabled: bool,
  t4bShopTabEnabled: bool,
  uiLocale: string,
  uiThemeReplicant: string,
  useTopBanner: bool,
  webChatHost: string,
  isCookieConsentLightNeeded: number,
};

Main.defaultProps = {
  activeTabs: [],
  assetsBaseURL: '',
  containerBG: null,
  experimentId: null,
  extra_content: {},
  geoIp: {},
  headerState: {},
  hideSectionsOnFooter: [],
  logoutURL: '',
  optimizelyExpIds: [],
  option_codes_saved: [],
  postalCode: '',
  showEmirateSelection: false,
  subsidiaryAccounts: [],
  t4bAccountId: '',
};

const mapStateToProps = state => {
  const {
    dispatch,
    Navigation: { containerBG, section },
    App: {
      isLayoutMobile,
      isLayoutTablet,
      dsFunctionsEnabled,
      enableCyberpunk,
      showSummaryPanelFooter,
      uiLocale,
      isEnterpriseOrder,
      isEnterpriseCustomOrderEdit,
      isPostOrderSwap,
      isDm,
      isNativePaymentEnabled,
      hideSectionsOnFooter,
      isLocationSelectorEnabled,
      isPickupLocationsEnabled,
      isGetUserLocationEnabled,
      isRequestCallbackLocationsEnabled,
      isWeChatMiniEnv,
      isAvayaChatEnabled,
      isAvayaChatLiteEnabled,
      isAvayaChatMobileEnabled,
      isAvayaChatTeslaAssistEnabled,
      availableCountrySelection,
      abTestExperimentIds,
      countryCode,
      isDeliverySelectionEnabled,
      base_url: baseUrl,
      webChatHost,
      isWebChatEnabled,
      uiThemeReplicant,
      isAccessoriesEnabled,
      isPreferredAddressEnabled,
      preferredAddressScriptUrl,
      isCoinReloaded,
      showSinglePage,
      isPreOrder,
      isEarlyAvailabilityEnabled,
      useTopBanner,
      isCookieConsentLightNeeded,
    },
    Assets: { baseURL: assetsBaseURL },
    ApplicationFlow: { canModifyOrder, referral, isSessionSet, optimizelyExpIds },
    FinancingOptions,
    ReviewDetails: {
      isDeliveryDetailsRequired,
      doSessionStartOnMount,
      inventoryZipCode,
      checkAvailableInventoryForMarket,
      product,
    },
    Payment: { CreditCardDetail, serverErrors: PaymentServerErrors },
    OMS: { lexicon = {} },
    Configuration: { option_codes_saved, model_code: modelCode },
    Location: { sources },
    SummaryPanel: { showEmirateSelection },
    Enterprise: {
      t4bAccountId,
      t4bAccountHeader: { headerState, logoutURL, subsidiaryAccounts },
      t4bNotifications,
      t4bServiceTabEnabled,
      t4bShopTabEnabled,
      t4bNewMarketExpEnabled,
    }
  } = state;
  const productType = getProductTypeMapping(state);
  const { groups_dictionary = {} } = lexicon || {};
  const { configurator: configuratorGroups = {} } = groups_dictionary;
  const optimize = _get(configuratorGroups, 'OPTIMIZE', {});
  const { name: experimentId, extra_content } = optimize;
  const { activeTabs } = FinancingOptions;
  const { geoIp, postalCode } = sources || {};

  return {
    abTestExperimentIds,
    activeTabs,
    assetsBaseURL,
    availableCountrySelection,
    baseUrl,
    canModifyOrder,
    checkAvailableInventoryForMarket,
    containerBG,
    countryCode,
    CreditCardDetail,
    dispatch,
    doSessionStartOnMount,
    dsFunctionsEnabled,
    enableCyberpunk,
    experimentId,
    extra_content,
    geoIp,
    headerState,
    hideSectionsOnFooter,
    inventoryZipCode,
    isAccessoriesEnabled,
    isAvayaChatEnabled,
    isAvayaChatLiteEnabled,
    isAvayaChatMobileEnabled,
    isAvayaChatTeslaAssistEnabled,
    isCoinReloaded,
    isDeliveryDetailsRequired,
    isDeliverySelectionEnabled,
    isDm,
    isEarlyAvailabilityEnabled,
    isEnterpriseCustomOrderEdit,
    isEnterpriseOrder,
    isGetUserLocationEnabled,
    isLayoutMobile,
    isLayoutTablet,
    isLocationSelectorEnabled,
    isNativePaymentEnabled,
    isPickupLocationsEnabled,
    isPostOrderSwap,
    isPreferredAddressEnabled,
    preferredAddressScriptUrl,
    isPreOrder,
    isRequestCallbackLocationsEnabled,
    isSessionSet,
    isWebChatEnabled,
    isWeChatMiniEnv,
    logoutURL,
    modelCode,
    optimizelyExpIds,
    option_codes_saved,
    PaymentServerErrors,
    postalCode,
    product,
    productType,
    referral,
    section,
    showEmirateSelection,
    showSinglePage,
    showSummaryPanelFooter,
    subsidiaryAccounts,
    t4bAccountId,
    t4bNewMarketExpEnabled,
    t4bNotifications,
    t4bServiceTabEnabled,
    t4bShopTabEnabled,
    uiLocale,
    uiThemeReplicant,
    useTopBanner,
    webChatHost,
    isCookieConsentLightNeeded,
  }
}

export default connect(mapStateToProps)(Main);
