/**
 * User-agent detection scripts
 */

/**
 * Check if we are on a mobile device
 * @param  {navigator.userAgent}  userAgent
 * @return {Boolean}
 */
export function isMobile(userAgent = window.navigator.userAgent) {
    const mobileRegex = /Mobi|iP(hone|od|ad)|Android|BlackBerry|IEMobile/    
    return !!userAgent.match(mobileRegex)
}

/**
 * Detect user Mobile OS
 * @param  {navigator.userAgent} userAgent
 * @return {String} - Android/iOs, etc
 */
export function getUserOS(userAgent = window.navigator.userAgent) {

    let userOS

    if(!!userAgent.match(/windows phone/)){
        userOS = "WindowsPhone"
    }

    if(!!userAgent.match(/Android/)){
        userOS = "Android"
    }

    if(!!userAgent.match(/BlackBerry/)){
        userOS = "BlackBerry"
    }

    if(!!userAgent.match(/IEMobile/)){
        userOS = "IEMobile"
    }

    if(!!userAgent.match(/iP(hone|od|ad)/)){
        userOS = "iOS";
    }

    return userOS
}

/**
 * Detect environment
 * @return {String} - localhost, prod, stage2, stage
 */
export const determineEnvironment = () => {
    if (window.location.search.indexOf('payxUI=localhost') !== -1) {
        return 'localhost';
    }
    if (window.tesla.environment === 'production') {
        return 'prod';
    }
    if (window.tesla.environment === 'stage2') {
        return 'stage2';
    }
    return 'stage';
};

/**
 * Is prod environment
 * @return {Boolean}
 */
export const isProdEnv = () => determineEnvironment() === 'prod';

