import React, { useRef } from 'react';
import _get from 'lodash/get';
import { i18n, htmlToReact } from 'utils';
import { connect } from 'react-redux';
import { Input, Form } from '@tesla/informed-tds';
import { string, bool, number, func, shape } from 'prop-types';
import { FOUNDERS } from 'dictionary';
import { updateVehicleDetail } from 'actions';
import { getReservationAmount } from 'selectors';

import { formatCurrency } from '@tesla/coin-common-components';

const SelectionForm = ({
  updateInfo,
  totalOrders,
  VehicleDetail,
  model,
  showQtyInput,
  paymentAmt,
  showFoundersDisclaimers,
  paymentAmtSecondary,
  secondaryCurrency,
}) => {
  const maxCount = 1000;
  const formApiRef = useRef();

  const validateQuantity = value => {
    const qty = parseInt(value);
    const { qty: vehicleQty, valid } = VehicleDetail || {};
    if (qty && qty <= maxCount) {
      if (vehicleQty !== qty || !valid) {
        const res = {
          ...VehicleDetail,
          qty: qty || 1,
          valid: true,
        };
        updateInfo(res);
      }
      return true;
    } else if (valid) {
      updateInfo({ ...VehicleDetail, valid: false });
    }
    return i18n('Reservation.error__maxQty', { MAX_QTY: maxCount });
  };

  const isSelected = VehicleDetail ? VehicleDetail.series : null;
  const description =
    showFoundersDisclaimers && isSelected === FOUNDERS ? 'description_founder' : 'description';
  const paymentIniitial = paymentAmtSecondary
    ? `${formatCurrency(paymentAmt.initial)} (${formatCurrency(paymentAmtSecondary.initial, {
        currency: secondaryCurrency,
      })})`
    : formatCurrency(paymentAmt.initial);
  const paymentSecond = paymentAmtSecondary
    ? `${formatCurrency(paymentAmt.second)} (${formatCurrency(paymentAmtSecondary.second, {
      currency: secondaryCurrency,
      })})`
    : formatCurrency(paymentAmt.second);

  const initialValues = {
    quantity: 1,
  };

  return (
    <Form
      formApiRef={formApiRef}
      className="order-form order-form--business"
      showErrorIfDirty
      initialValues={initialValues}
    >
      <div className="tds--vertical_padding-top form-container form-theme--rounded_white">
        <div className="two-fields-side-by-side selection-form">
          <div className="two-fields-side-by-side selection-form">
            <div className="field-container">
              <If condition={showQtyInput}>
                <Input
                  id={`Qty_${model}`}
                  name="quantity"
                  label={i18n('Reservation.Quantity')}
                  key={`Quantity_${model}`}
                  initialValue={totalOrders}
                  required
                  allowEmptyString={false}
                  validate={validateQuantity}
                  validateOn="change"
                  showErrorIfDirty
                />
              </If>
            </div>
          </div>
          <span className="selection-form--description">
            <p className="selection-text">
              {htmlToReact(
                i18n(`Reservation.${model}.${description}`, {
                  INITIAL_AMT: paymentIniitial,
                  WIRE_TRANSFER_AMT: paymentSecond,
                  ADDITIONAL_AMT: formatCurrency(paymentAmt.initial + paymentAmt.second),
                })
              )}
            </p>
          </span>
        </div>
      </div>
    </Form>
  );
};

function mapStateToProps(state) {
  const VehicleDetail = _get(state, 'ReviewDetails.VehicleDetail', {});
  const depositAmountPerCurrency = _get(state, 'Payment.depositAmountPerCurrency', {});
  const secondaryCurrency = _get(depositAmountPerCurrency, 'currency_secondary');
  const paymentAmtSecondary = secondaryCurrency
    ? _get(depositAmountPerCurrency, secondaryCurrency, {})
    : null;
  return {
    model: _get(state, 'OMS.oms_params.model', ''),
    showQtyInput: _get(state, 'ReviewDetails.showQtyInput', false),
    VehicleDetail,
    totalOrders: _get(VehicleDetail, 'qty', null),
    paymentAmt: getReservationAmount(state) || {},
    paymentAmtSecondary,
    secondaryCurrency,
    showFoundersDisclaimers: _get(state, 'ReviewDetails.showFoundersDisclaimers', false),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateInfo: input => {
      dispatch(updateVehicleDetail(input));
    },
  };
}

SelectionForm.propTypes = {
  model: string.isRequired,
  showQtyInput: bool,
  VehicleDetail: shape({
    series: string,
  }),
  paymentAmt: shape({
    initial: number,
    second: number,
  }),
  showFoundersDisclaimers: bool,
  totalOrders: number,
  updateInfo: func.isRequired,
  secondaryCurrency: string,
  paymentAmtSecondary: shape({}),
};

SelectionForm.defaultProps = {
  showFoundersDisclaimers: false,
  totalOrders: 1,
  VehicleDetail: {},
  showQtyInput: false,
  paymentAmt: {},
  secondaryCurrency: null,
  paymentAmtSecondary: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectionForm);
