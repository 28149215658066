import _get from 'lodash/get';

import {
  LOCATION_CHANGED,
  LOCATION_SOURCE_CHANGE,
  SUMMARY_PANEL_REGION_CHANGE,
  OMS_RECEIVED_DEPENDENCIES,
  LOCATION_COMPONENT_CHANGE,
} from 'dictionary';

function setInventoryLocation({action, state, app_state}) {
  const { components = {} } = state;
  const { inventory = {} } = components;
  const { regionCode = '', city = '', postalCode = '' } = inventory;

  switch(action.type){
    case OMS_RECEIVED_DEPENDENCIES:
      return {
        regionCode,
        countryCode:  _get(app_state, 'OMS.oms_params.market', null),
        city,
        postalCode,
      }

    case LOCATION_CHANGED:
      const { location } = action
      const pricebook_override = location.countryCode !== state.sources.pricebook.market
      return {
        regionCode:     pricebook_override ? null               : _get(location, 'region.regionCode', null),
        countryCode:    pricebook_override ? state.sources.pricebook.market   : _get(location, 'countryCode', null),
        city:           pricebook_override ? null               : _get(location, 'city', null),
        postalCode:     pricebook_override ? null               : _get(location, 'postalCode', null)
      }
  }
}

function setSummaryPanelLocation({action, state, app_state}) {
  const savedConfigRegionCode = _get(app_state, 'Configuration.savedConfiguration.regionCode');
  const { components = {}, sources = {} } = state;
  const { pricebook = {} } = sources;
  const { summaryPanel = {} } = components;

  if (savedConfigRegionCode) {
    return {
      regionCode: savedConfigRegionCode,
      countryCode: _get(app_state, 'OMS.oms_params.market', null)
    }
  }

  switch(action.type){
    case OMS_RECEIVED_DEPENDENCIES:
      return {
        regionCode: summaryPanel.regionCode,
        countryCode: _get(app_state, 'OMS.oms_params.market', null)
      }
    case LOCATION_CHANGED:
      const { location } = action
      if (location.countryCode === pricebook.market) {
        return {
          regionCode: _get(location, 'region.regionCode', null),
          regionName: _get(location, 'region.longName', null),
          countryCode: _get(location, 'countryCode', null)
        }
      } else {
        return {
          regionCode: summaryPanel.regionCode,
          countryCode: pricebook.market
        }
      }

    case SUMMARY_PANEL_REGION_CHANGE:
      const { regionCode } = action
      return {
        regionCode: regionCode,
        countryCode: summaryPanel.countryCode
      }
  }

  return {
    countryCode: pricebook.market,
    regionCode: summaryPanel.regionCode,
  }
}

/**
*   Reducer for updating Location information
*/
function Location(state = {}, action, {app_state}) {
  const { type } = action;
  const { sources = {}, components = {} } = state;
  const { userSelected = {}, geoIp = {} } = sources;
  const { inventory = {}, summaryPanel = {} } = components;

  switch (type) {
    case OMS_RECEIVED_DEPENDENCIES:
      return Object.assign({}, state, {
        sources: Object.assign({}, sources, {
          pricebook:{
            market: _get(app_state, 'OMS.oms_params.market', _get(action,'data.lexicon.market'))
          }
        }),
        components: Object.assign({}, components, {
          inventory: Object.assign({}, inventory, setInventoryLocation({app_state, state, action})),
          summaryPanel: Object.assign({}, summaryPanel, setSummaryPanelLocation({app_state, state, action}))
        })
      })

    case SUMMARY_PANEL_REGION_CHANGE:
      return Object.assign({}, state, {
        sources: Object.assign({}, sources, {
          userSelected: Object.assign({}, userSelected, {
            region: action.regionCode
          })
        }),
        components: Object.assign({}, components, {
          summaryPanel: Object.assign({}, summaryPanel, setSummaryPanelLocation({app_state, state, action}))
        })
      })

    case LOCATION_SOURCE_CHANGE:
      const sourceData = action.sourceData || sources[action.sourceName];
      return Object.assign({}, state, {
        sources: Object.assign({}, sources, {
          [action.sourceName]: sourceData
        })
      })

    case LOCATION_COMPONENT_CHANGE:
      return Object.assign({}, state, {
        components: Object.assign({}, components, {
          [action.componentName]: action.componentData
        })
      })

    case LOCATION_CHANGED:
      return  Object.assign({}, state, {
        isLocationChecked: true,
        sources: Object.assign({}, sources, {
          geoIp: Object.assign({}, geoIp, action.location || {})
        }),
        components: Object.assign({}, components, {
          inventory: Object.assign({}, inventory, setInventoryLocation({app_state, state, action})),
          summaryPanel: Object.assign({}, summaryPanel, setSummaryPanelLocation({app_state, state, action}))
        })
      })
    default:
        return state;
  }
}

export default Location