import { UPDATE_INCENTIVES } from 'dictionary';
import { toggleTrimSavingsPrice } from 'actions';

export const updateIncentives = (value, subType) => {
  return (dispatch, getState) => {
    const state = getState();
    const { showAfterSavingsPriceForTrims } = state?.ReviewDetails || {};
    const { userSelectedIncentives, incentives } = state?.Financial || {};
    const currentIncentives = incentives?.current ?? {};
    let includedInPriceIncentives = [];

    // When updating incentives, find out which ones are included in price and do not override them
    const filteredIncentives = Object.values(currentIncentives).reduce((acc, incentive = []) => {
      incentive?.map(x =>
        x?.period === 'includedInPurchasePrice'
          ? includedInPriceIncentives.push(x)
          : showAfterSavingsPriceForTrims && acc.push(x)
      );
      return acc;
    }, []);
    const selected = [
      ...(!userSelectedIncentives ? filteredIncentives : userSelectedIncentives),
      ...includedInPriceIncentives,
    ];
    let data = Array.isArray(value) ? value : [];
    // condition to uncheck input on UI
    if (selected?.find(x => x?.incentiveType === value && x?.incentiveSubType == subType)) {
      data = selected.filter(v => {
        if (subType) {
          return subType !== v?.incentiveSubType;
        }
        return !v?.incentiveSubType ? v?.incentiveType !== value : v;
      });
    } else { // condition to check/select input on UI
      data = [
        ...selected,
        incentives?.currentAvailable?.[value]?.find(x =>
          subType ? x?.incentiveSubType == subType : x
        ),
      ];
    }
    dispatch({
      type: UPDATE_INCENTIVES,
      incentives: data,
    });

    if (
      data?.length &&
      Object.keys(data)?.length === Object.keys(incentives?.currentAvailable || {})?.length &&
      !showAfterSavingsPriceForTrims
    ) {
      return dispatch(toggleTrimSavingsPrice(true));
    } else if (!data?.length && showAfterSavingsPriceForTrims) {
      return dispatch(toggleTrimSavingsPrice(false));
    }
  };
};
