import {
  APPLE_PAY_SESSION_REQUEST,
  APPLE_PAY_SESSION_SUCCESS,
  APPLE_PAY_SESSION_FAILURE,
  APPLE_PAY_PAYMENT,
  APPLE_PAY_VALIDITY,
} from 'dictionary';

export default function Reducer(state = {}, action) {
  switch (action.type) {
    case APPLE_PAY_SESSION_REQUEST:
      return { ...state, applePaySessionRequested: true };

    case APPLE_PAY_SESSION_SUCCESS:
      return { ...state, applePaySessionRequested: false, applePaySession: true };

    case APPLE_PAY_SESSION_FAILURE:
      return {
        ...state,
        applePaySessionRequested: false,
        applePaySession: false,
        applePaySessionError: true,
        applePaySessionErrorMsg: action.errorMsg,
      };

    case APPLE_PAY_PAYMENT:
      return { ...state, payment: action.payment };

    case APPLE_PAY_VALIDITY:
      return { ...state, userHasApplePay: action.userHasApplePay };

    default:
      return state;
  }
}
