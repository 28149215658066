import { 
	LOADER_START,
	LOADER_FINISH
} from 'dictionary'

export function startLoader() {
  return {
    type: LOADER_START
  }
}

export function removeLoader() {
  return {
  	type: LOADER_FINISH
  }
}