import React from 'react';
import { connect } from 'react-redux';
import { string, shape, bool } from 'prop-types';

import _has from 'lodash/has';
import _set from 'lodash/set';
import _get from 'lodash/get';
import classnames from 'classnames';

import { htmlToReact } from 'utils';

import NegativeIcon from '../common/negativeIcon';
import PositiveIcon from '../common/positiveIcon';
import { parseSelectedBy } from '../../common/utils';

const Table = ({ group, field, ...rest }) => {
  if (!group || !_has(group, field)) {
    return null;
  }
  const { extra_copy } = group;
  const { isCollapsedOnMobile, configurationString } = rest;
  let tableData = {};
  extra_copy
    .filter(item => item.type.includes(field))
    .reduce((acc, item) => {
      const { behavior } = item;
      if (behavior) {
        behavior.forEach(rule => {
          const match = parseSelectedBy(_get(rule, 'content.selected_by', {}), configurationString);
          if (match) {
            _set(acc, item.type, item.content);
          }
        });
      } else {
        _set(acc, item.type, item.content);
      }
      return acc;
    }, tableData);

  return (
    <>
      <table
        className={classnames('tds-table', {
          'equal-width-table': isCollapsedOnMobile,
        })}
      >
        <If condition={tableData?.table?.row[0]}>
          <thead className="tds-table-head">
            <tr className="tds-table-row">
              {tableData?.table.row[0]?.column.map((lineItem, index) => (
                <th
                  key={index}
                  className={classnames({ 'tds-text--center': index > 0 })}
                  role="columnheader"
                  scope="col"
                >
                  {htmlToReact(lineItem)}
                </th>
              ))}
            </tr>
          </thead>
        </If>
        <tbody className="tds-table-body">
          {tableData?.table.row.slice(1).map(({ column }, rowIndex) => (
            <tr
              key={rowIndex}
              className={classnames('tds-table-row', {
                'tds--no_vertical_padding': !isCollapsedOnMobile,
              })}
            >
              {column.map((cell, columnIndex) => {
                const cellType = typeof cell;
                switch (cellType) {
                  case 'object':
                    return (
                      <>
                        <If condition={cell.tickmark}>
                          <td
                            key={columnIndex}
                            className="tds-text--center tds--no_vertical_padding"
                          >
                            <PositiveIcon />
                          </td>
                        </If>
                        <If condition={cell.xmark}>
                          <td
                            key={columnIndex}
                            className="tds-text--center tds--no_vertical_padding"
                          >
                            <NegativeIcon />
                          </td>
                        </If>
                        <If condition={cell.title}>
                          <td
                            key={columnIndex}
                            className={classnames({
                              'tds--no_vertical_padding': !isCollapsedOnMobile,
                              'collapsible-table': isCollapsedOnMobile && columnIndex === 0,
                              'table-baseline': isCollapsedOnMobile,
                            })}
                          >
                            <span
                              className={classnames({
                                'tds-text--bold': isCollapsedOnMobile && columnIndex === 0,
                              })}
                            >
                              {cell.title}
                            </span>
                            <div className="tds-text--caption">{cell?.currentSelection}</div>
                          </td>
                        </If>
                      </>
                    );
                  default:
                    return (
                      <td
                        key={columnIndex}
                        className={classnames({
                          'tds--no_vertical_padding': !isCollapsedOnMobile,
                          'collapsible-table': isCollapsedOnMobile && columnIndex === 0,
                          'table-baseline': isCollapsedOnMobile,
                          'tds-text--bold': isCollapsedOnMobile && columnIndex === 0,
                        })}
                      >
                        {htmlToReact(cell)}
                      </td>
                    );
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

const mapStateToProps = state => {
  const { Configuration } = state;
  return {
    configurationString: Configuration.option_string,
  };
};

Table.propTypes = {
  group: shape({
    selected: bool,
    code: string,
    name: string,
    formattedPrice: string,
  }).isRequired,
  field: string.isRequired,
  component: string,
  classes: string,
};

Table.defaultProps = {
  component: 'div',
  classes: '',
};

export default connect(mapStateToProps)(Table);
