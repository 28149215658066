import React, { useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import _upperFirst from 'lodash/upperFirst';
import _get from 'lodash/get';
import { string, bool, arrayOf, shape, func, number } from 'prop-types';
import { Button, Loader, StatusMessage, Icon } from '@tesla/design-system-react';
import { useFormState } from 'informed';
import { Form, Input, OptionGroup } from '@tesla/informed-tds';
import { iconStatusInfoSmall } from '@tesla/design-system-icons';
import {
  i18n,
  htmlToReact,
  zipCodeValidator,
  formatAddress,
  getPostOrderSwapCustomAvailability,
  isEMEAMarket,
} from 'utils';
import {
  updateDeliveryLocations,
  getDeliveryLocations,
  getInventoryDeliveryLocationsByRegion,
  openModal,
  setDeliveryDetailsValidFlag,
  setInventoryDeliveryLocations,
  setPaymentOverviewFlag,
} from 'actions';
import {
  DELIVERY_LOCATION,
  FIELD_ZIP_CODE,
  DELIVERY_DETAILS_CHANGED,
  MODAL_SMALL,
  USER_LOCATION_MODAL,
  PROVINCE,
} from 'dictionary';
import Analytics from 'analytics';

import InventoryDeliveryAddress from './InventoryDeliveryAddress';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import DeliveryContact from './DeliveryContact';

const SubmitButton = () => {
  const { invalid } = useFormState();
  return (
    <div className="tds--vertical_padding--small">
      <Button
        className={cx('tds-btn tds-btn--primary tds-btn--medium tds--align_center', {
          'tds--disabled': invalid,
        })}
        disabled={invalid}
        type="submit"
        data-id="delivery-postal-code-submit-button"
      >
        {i18n('common.submit')}
      </Button>
    </div>
  );
};

/* InventoryDeliverySelection component */
const InventoryDeliverySelection = ({
  isDeliverySelectionEnabled,
  isDeliverySelectionEnabledByRegion,
  locations,
  defaultLocation,
  updateLocationDetails,
  userZipCode,
  fetchLocationDetails,
  fetchLocationDetailsByRegion,
  openLocationModal,
  isLoading,
  countryCode,
  statusMsgProps,
  registrationError,
  error,
  showSoldOutFlow,
  setValidFlag,
  isDeliveryDetailsValid,
  flagToSetDefaultDeliveryLocation,
  isDesktop,
  geoIpPostalCode,
  postalCodeFromQueryParams,
  vehicleVrl,
  isPostOrderSwap,
  postOrderSwapCustomAvailability,
  addressLine1,
  addressLine2,
  vrlName,
  deliveryDisclaimer,
  ignoreZip,
  ignoreRegion,
  deliveryLocationsApiResults,
  setDeliveryLocations,
  updateDeliveryDetails,
  showDeliveryRestrictionDisclaimer,
  isEnterpriseOrderPickup,
  region_code,
  setShowPaymentFlag,
  isCoinReloaded,
}) => {
  if (!isDeliverySelectionEnabled) return null;
  const formApiRef = useRef();
  const [showInput, setShowInput] = useState(!userZipCode);

  const [isVisible, ref] = useIntersectionObserver({
    threshold: 0,
  });

  const initialValues = useMemo(() => ({
    [DELIVERY_LOCATION]: defaultLocation?.id,
    [FIELD_ZIP_CODE]: userZipCode,
  }), [defaultLocation?.id, userZipCode]);

  useEffect(() => {
    formApiRef?.current?.reset();
  }, [initialValues]);

  useEffect(() => {
    if (ignoreZip && flagToSetDefaultDeliveryLocation) {
      setDeliveryLocations(deliveryLocationsApiResults, true);
    }
  }, []);

  useEffect(() => {
    if (showSoldOutFlow) {
      Analytics.fireTagEvent({
        event: Analytics.event,
        interaction: 'delivery-not-available-for-registration-zipcode',
        'cfg-type': Analytics.cfgType,
      });
    }
  }, [showSoldOutFlow]);

  useEffect(() => {
    if (!isPostOrderSwap && !ignoreZip) {
      if (!userZipCode && !geoIpPostalCode) {
        setValidFlag(false);
        setShowInput(true);
        return;
      }
      if (
        flagToSetDefaultDeliveryLocation &&
        isVisible &&
        (userZipCode || postalCodeFromQueryParams || geoIpPostalCode)
      ) {
        const zipCode = postalCodeFromQueryParams || userZipCode || geoIpPostalCode;
        fetchLocationDetails(zipCode);
      }
    }

  }, [geoIpPostalCode, region_code, isVisible]);

  useEffect(() => {
    if (error || isLoading) {
      setValidFlag(false);
    } else if (!isDeliveryDetailsValid || isPostOrderSwap) {
      setValidFlag(true);
      setShowInput(false);
    }
  }, [error, isLoading]);

  useEffect(() => {
    if (!ignoreRegion && ignoreZip && !isPostOrderSwap) {
      setValidFlag(false);
      fetchLocationDetailsByRegion()
      setShowInput(false)
    }
  }, [region_code])

  const onLocationChange = ({ value }) => {
    const selected = locations.find(x => x?.id === value);
    const distanceSrc =
      (selected?.distance_from_car || selected?.distance) < 100 ? 'vehicle' : 'customer';
    updateLocationDetails({ selected });
    if (ignoreZip) {
      updateDeliveryDetails({ StateProvince: selected?.city || '' });
    }
    Analytics.fireTagEvent({
      event: Analytics.event,
      interaction: `closest-${distanceSrc}-delivery-location`,
      'cfg-type': Analytics.cfgType,
    });
  };

  const onSubmit = ({ values, valid }) => {
    const { zipCode } = values || {};
    Analytics.fireInteractionEvent('submit-registration-zipcode');
    if (valid && zipCode) {
      if (zipCode !== userZipCode) {
        fetchLocationDetails(zipCode, { setPreferredAddress: true });
      } else {
        setValidFlag(true);
      }
      setShowInput(false);
    }
  };

  const onChangeZipCode = () => {
    setShowInput(true);
    setValidFlag(false);
    if (isCoinReloaded) {
      setShowPaymentFlag(false);
    }
    Analytics.fireTagEvent({
      event: Analytics.event,
      interaction: 'change-registration-zipcode',
      'cfg-type': Analytics.cfgType,
    });
  };

  const title = postOrderSwapCustomAvailability
    ? i18n('PostOrderSwap.PICKUP_LOCATION')
    : i18n('DeliveryLocation.title');

  return (
    <div
      ref={ref}
      id="delivery-selection"
      className={cx('delivery-selection--container', {
        'tds-o-horizontal_padding-24': isPostOrderSwap && !isDesktop,
      })}
    >
      <h2 className="tds-text--center tds--vertical_padding coin-group--title">{title}</h2>
      <Choose>
        <When condition={isPostOrderSwap}>
          <div className="text-loader--content tds--vertical_padding-bottom">
            <div className="tds-text--medium tds-o-vertical_padding--1x">{vrlName}</div>
            <div>{addressLine1}</div>
            <div>{addressLine2}</div>
          </div>
        </When>
        <Otherwise>
          <Form
            formApiRef={formApiRef}
            initialValues={initialValues}
            errorMessage={{
              required: _upperFirst(i18n('common.errors__required')),
            }}
            onSubmit={onSubmit}
          >
            <Choose>
              <When condition={!ignoreZip && (showInput || isLoading)}>
                <p className="tds-text--center">{i18n('DeliveryLocation.check_availability')}</p>
                <div
                  className={cx('tds-o-vertical_padding-top', {
                    'observer-placeholder': isLoading,
                  })}
                >
                  <Input
                    name={FIELD_ZIP_CODE}
                    id={FIELD_ZIP_CODE}
                    data-id="delivery-postal-code-textbox"
                    label={htmlToReact(
                      i18n('DeliveryLocation.registration_zipcode_label', {
                        CLASS: 'tds-text--caption tds-text--regular',
                      })
                    )}
                    validate={value => zipCodeValidator(value, countryCode)}
                    required
                    validateOn="change"
                    validateOnMount
                    showErrorIfError
                    onFocus={({ event }) => event?.target?.select()}
                    keepState
                  />
                  <SubmitButton />
                  <Loader show={isLoading} className="inline-loader" />
                </div>
              </When>
              <When condition={(!ignoreRegion && ignoreZip) && (showInput || isLoading)}>
                <p className="tds-text--center">{i18n('DeliveryLocation.check_availability')}</p>
                <div style={{height: '100px'}}
                  className={cx('tds-o-vertical_padding-top', {
                    'observer-placeholder': isLoading,
                  })}
                >
                  <Loader show={isLoading} className="inline-loader" />
                </div>
              </When>
              <When condition={showSoldOutFlow || (!ignoreRegion && !locations?.length)}>
                <div className="tds-text--center">
                  <button onClick={ ignoreZip ? openLocationModal : onChangeZipCode } data-id="delivery-postal-code-edit-link">
                    {htmlToReact(
                      i18n('DeliveryLocation.delivery_options', {
                        ZIP_CODE: ignoreZip ? region_code: userZipCode,
                      })
                    )}
                  </button>
                </div>
                <div className="tds--vertical_padding">
                  <StatusMessage
                    {...statusMsgProps}
                    header={
                      <div
                        className="tds-text--regular tds-text--contrast-high"
                        data-id="delivery-search-error"
                      >
                        <span>{i18n(ignoreZip ? 'DeliveryLocation.errors__region_restricted_error' : 'DeliveryLocation.errors__range_restricted_error')}</span>
                      </div>
                    }
                  />
                </div>
              </When>
              <When condition={locations.length}>
                <If condition={!ignoreZip || isDeliverySelectionEnabledByRegion}>
                  <div className="tds-text--center">
                    <button onClick={ ignoreZip ? openLocationModal : onChangeZipCode } data-id="delivery-postal-code-edit-link">
                      {htmlToReact(
                        i18n('DeliveryLocation.delivery_options', {
                          ZIP_CODE: ignoreZip ? region_code : userZipCode,
                        })
                      )}
                    </button>
                  </div>
                </If>
                <If condition={registrationError}>
                  <StatusMessage {...statusMsgProps} body={registrationError} />
                </If>
                <Choose>
                  <When condition={locations.length > 1}>
                    <div className="tds--vertical_padding--large">
                      <OptionGroup
                        name={DELIVERY_LOCATION}
                        id={DELIVERY_LOCATION}
                        onChange={onLocationChange}
                        options={locations.map(location => {
                          const { id, trt_id } = location || {};
                          return {
                            'data-id': `${trt_id}-location-option`,
                            value: id,
                            label: (
                              <InventoryDeliveryAddress
                                {...location}
                                key={`delivery_address_${id}`}
                                classes="tds--vertical_padding--large"
                                vehicleVrl={vehicleVrl}
                              />
                            ),
                          };
                        })}
                      />
                    </div>
                  </When>
                  <Otherwise>
                    <div data-id={`${defaultLocation?.trt_id}-location-read-only`}>
                      <InventoryDeliveryAddress
                        {...defaultLocation}
                        singleLocation={true}
                        classes={'tds--vertical_padding'}
                      />
                    </div>
                  </Otherwise>
                </Choose>
              </When>
              <Otherwise>
                <>
                  <StatusMessage
                    {...statusMsgProps}
                    header={
                      <div
                        className="tds-text--caption tds-text--medium tds-text--contrast-high"
                        data-id="delivery-search-error"
                      >
                        <span>
                          {i18n('DeliveryLocation.errors__deliverylocation_invalid', {
                            ZIP_CODE: userZipCode,
                          })}
                        </span>
                        <If condition={!ignoreZip}>
                          <button
                            className="tds--horizontal_margin-5 tds-link tds-link--primary"
                            data-id="delivery-search-error-edit-link"
                            onClick={onChangeZipCode}
                          >
                            {i18n('Review.edit')}
                          </button>
                        </If>
                      </div>
                    }
                    body={
                      <p className="tds-text--caption tds--vertical_padding--small">
                        {i18n('DeliveryLocation.location_disclaimer')}
                      </p>
                    }
                  />
                </>
              </Otherwise>
            </Choose>
          </Form>
        </Otherwise>
      </Choose>
      <If condition={deliveryDisclaimer}>
        <p className="tds-text--caption">
          <Icon data={iconStatusInfoSmall} align="text-middle" inline />
          {deliveryDisclaimer}
        </p>
      </If>
      <If condition={showDeliveryRestrictionDisclaimer}>
        <p
          className={cx('tds-text--caption', {
            'tds--vertical_padding--small': deliveryDisclaimer,
          })}
        >
          {i18n('DeliveryLocation.delivery_disclaimer')}
        </p>
      </If>
      <If condition={isEnterpriseOrderPickup && !showSoldOutFlow}>
        <DeliveryContact />
      </If>
    </div>
  );
};

const mapStateToProps = state => {
  const { ReviewDetails, App, Location, SummaryPanel } = state;
  const {
    isDeliverySelectionEnabled,
    isLayoutTablet,
    isLayoutMobile,
    countryCode,
    query_params,
    isPostOrderSwap,
    isEnterpriseOrder,
    isEnterpriseOrderPickup,
    isCoinReloaded,
  } = App;
  const {
    DeliveryLocations,
    DeliveryDetails,
    isDeliveryDetailsValid,
    ignoreFieldsForDeliveryLocation: ignoreField = [],
    product,
    isDeliverySelectionEnabledByRegion,
    isSoldOutFlowEnabled = false,
  } = ReviewDetails;
  const { region_code } = SummaryPanel;
  const {
    filtered: locations = [],
    selected: defaultLocation = {},
    isLoading,
    registrationError,
    registrationZipCode,
    error,
    errorSalesMetro,
    available: deliveryLocationsApiResults,
    compoundLocations = [],
    deliveryDisclaimer,
  } = DeliveryLocations || {};
  const isDesktop = !isLayoutMobile && !isLayoutTablet;

  const userZipCode = registrationZipCode || DeliveryDetails?.PostalCode;
  const { sources } = Location || {};
  const { geoIp } = sources || {};
  const { postalCode: geoIpPostalCode, countryCode: geoIpCountryCode } = geoIp || {};
  const { postal: postalCodeFromQueryParams = '' } = query_params || {};
  const { CHOSEN_VEHICLE: chosenVehicle } = deliveryLocationsApiResults || {};
  const { vrl: vehicleVrl } = chosenVehicle || {};
  const { isUsedInventory: isUsed = false } = product || {};

  let addressLine1 = '';
  let addressLine2 = '';
  let vrlName = '';
  let postOrderSwapCustomAvailability = '';
  if (isPostOrderSwap) {
    const {
      AddressLine1: address1,
      AddressLine2: address2,
      City: city,
      ProvinceState: provinceState,
      PostalCode: postalCode,
    } = _get(ReviewDetails, 'product.data.PickupLocation.VrlAddress', {});
    addressLine1 = formatAddress({ address1, address2 });
    addressLine2 = formatAddress({ city, provinceState, postalCode });
    vrlName = _get(ReviewDetails, 'product.data.PickupLocation.VrlName', '');
    postOrderSwapCustomAvailability = getPostOrderSwapCustomAvailability(state);
  }

  const showSoldOutFlow = errorSalesMetro && isSoldOutFlowEnabled;

  return {
    isDeliverySelectionEnabled,
    isDeliverySelectionEnabledByRegion,
    locations: compoundLocations?.length ? compoundLocations : locations,
    defaultLocation,
    userZipCode,
    isLoading,
    countryCode,
    registrationError,
    statusMsgProps: {
      type: 'warning',
      className: 'tds--fade-in tds-o-no-margin-bottom',
      enclosed: false,
    },
    error,
    showSoldOutFlow,
    isDeliveryDetailsValid,
    flagToSetDefaultDeliveryLocation: !!(
      isDeliverySelectionEnabled &&
      deliveryLocationsApiResults &&
      !Object.keys(defaultLocation).length
    ),
    isLayoutMobile,
    geoIpPostalCode: countryCode === geoIpCountryCode ? geoIpPostalCode : '',
    postalCodeFromQueryParams,
    vehicleVrl,
    isPostOrderSwap,
    postOrderSwapCustomAvailability,
    addressLine1,
    addressLine2,
    vrlName,
    ignoreField,
    deliveryDisclaimer,
    ignoreZip: ignoreField?.includes(FIELD_ZIP_CODE),
    ignoreRegion: ignoreField?.includes(PROVINCE),
    deliveryLocationsApiResults,
    showDeliveryRestrictionDisclaimer:
      !isUsed && isEMEAMarket(countryCode) && !isEnterpriseOrderPickup,
    isDesktop,
    isEnterpriseOrder,
    isEnterpriseOrderPickup,
    region_code,
    isCoinReloaded,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateLocationDetails: payload => {
      dispatch(updateDeliveryLocations(payload));
    },
    fetchLocationDetails: (zipCode, props) => {
      dispatch(getDeliveryLocations(zipCode, props));
    },
    fetchLocationDetailsByRegion: region => {
      dispatch(getInventoryDeliveryLocationsByRegion(region))
    },
    openLocationModal: () => {
      dispatch(
        openModal(USER_LOCATION_MODAL, {
          className: 'session-time-out--container',
          genericWrapper: true,
          size: MODAL_SMALL,
        })
      );
    },
    setValidFlag: flag => {
      dispatch(setDeliveryDetailsValidFlag(flag));
    },
    setDeliveryLocations: (payload, ignoreZip) => {
      dispatch(setInventoryDeliveryLocations(payload, ignoreZip));
    },
    updateDeliveryDetails: payload => {
      dispatch({
        type: DELIVERY_DETAILS_CHANGED,
        payload,
      });
    },
    setShowPaymentFlag: flag => {
      dispatch(setPaymentOverviewFlag(flag));
    }
  };
};

InventoryDeliverySelection.propTypes = {
  userZipCode: string,
  isDeliverySelectionEnabled: bool,
  isDeliverySelectionEnabledByRegion: bool,
  locations: arrayOf(shape({})),
  defaultLocation: shape({}),
  updateLocationDetails: func.isRequired,
  fetchLocationDetails: func.isRequired,
  fetchLocationDetailsByRegion: func.isRequired,
  openLocationModal: func.isRequired,
  deliveryLocation: shape({}),
  url: string,
  postalFromURL: string,
  isLoading: bool,
  countryCode: string.isRequired,
  registrationError: string,
  statusMsgProps: shape({}),
  error: string,
  setValidFlag: func.isRequired,
  isDeliveryDetailsValid: bool,
  flagToSetDefaultDeliveryLocation: bool,
  isLayoutMobile: bool,
  geoIpPostalCode: string,
  postalCodeFromQueryParams: string,
  vehicleVrl: number,
  isPostOrderSwap: bool.isRequired,
  addressLine1: string,
  addressLine2: string,
  vrlName: string,
  ignoreField: arrayOf(string),
  deliveryDisclaimer: string,
  ignoreZip: bool,
  ignoreRegion: bool,
  setDeliveryLocations: func.isRequired,
  updateDeliveryDetails: func.isRequired,
  showSoldOutFlow: bool,
  showDeliveryRestrictionDisclaimer: bool,
  isDesktop: bool.isRequired,
  region_code: string,
  isCoinReloaded: bool.isRequired,
};

InventoryDeliverySelection.defaultProps = {
  userZipCode: '',
  isDeliverySelectionEnabled: false,
  isDeliverySelectionEnabledByRegion: false,
  locations: [],
  defaultLocation: {},
  deliveryLocation: {},
  url: '',
  postalFromURL: '',
  isLoading: false,
  registrationError: '',
  statusMsgProps: {},
  error: '',
  showSoldOutFlow: false,
  isDeliveryDetailsValid: false,
  flagToSetDefaultDeliveryLocation: false,
  isLayoutMobile: false,
  geoIpPostalCode: '',
  postalCodeFromQueryParams: '',
  vehicleVrl: 0,
  addressLine1: '',
  addressLine2: '',
  vrlName: '',
  ignoreField: [],
  deliveryDisclaimer: '',
  ignoreZip: false,
  ignoreRegion: true,
  showDeliveryRestrictionDisclaimer: false,
  region_code: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryDeliverySelection);
