import React from 'react';
import {
  iconBolt,
  iconDog,
  iconCamp,
  iconDisplay,
  iconDownload,
  iconCameraLens,
  iconMicrophone,
  iconPerson,
  iconPhoneKey,
  iconRouteOverview,
  iconAc,
  iconClock,
} from '@tesla/design-system-icons';
import { Icon } from '@tesla/design-system-react';
import { string, array, object } from 'prop-types';
import { formatNumber } from '@tesla/coin-common-components';

// iconCollision is currently not available in @tesla/design-system-react
const iconCollision = {
  children: [
    {
      name: 'path',
      type: 'element',
      attributes: {
        fill: 'currentColor',
        d:
          'm18.378 9.498-.104-.356-.709-2.422a1.5 1.5 0 0 0-1.29-.999l-1.036-.086a38.998 38.998 0 0 0-6.478 0l-1.035.086a1.5 1.5 0 0 0-1.299 1.02l-.003.012-.004.01-.933 2.66-.114.325-.243.242-.155.154-.066.066-.074.057-.716.555c-.38.295-.619.77-.619 1.294V17h17v-4.884c0-.523-.239-1-.619-1.294l-.8-.62-.084-.065-.075-.077-.286-.296-.258-.266Zm.618-3.23.664 2.269H20l.078-.667a.4.4 0 0 1 .454-.35l.468.067.108.027c.256.064.49.197.678.384a.73.73 0 0 1 .214.517v.132a.33.33 0 0 1-.228.314l-.17.055a4 4 0 0 1-1.236.196h-.114l.548.424a3.135 3.135 0 0 1 1.2 2.48v5.398c0 .68-.057 1.358-.171 2.025-.046.268-.247.461-.48.461h-2.115c-.14 0-.263-.105-.306-.26a6.953 6.953 0 0 1-.225-1.24H5.297c-.037.42-.113.836-.225 1.24-.043.155-.167.26-.306.26H2.652c-.234 0-.435-.193-.48-.461A12.033 12.033 0 0 1 2 17.514v-5.398c0-.976.445-1.894 1.2-2.48l.548-.424h-.114a4 4 0 0 1-1.237-.196l-.169-.055A.33.33 0 0 1 2 8.647v-.132a.73.73 0 0 1 .214-.517 1.46 1.46 0 0 1 .679-.384L3 7.587l.469-.067a.4.4 0 0 1 .453.35L4 8.537h.208l.796-2.27a3 3 0 0 1 2.597-2.04l1.036-.087a40.5 40.5 0 0 1 6.726 0l1.036.086a3 3 0 0 1 2.597 2.041Z',
      },
    },
    {
      name: 'path',
      type: 'element',
      attributes: {
        fill: 'currentColor',
        d:
          'M16.167 9.126a2.292 2.292 0 0 1-3.211 2.1l-3.699 3.698a.833.833 0 0 1-1.178-1.179l3.697-3.697a2.292 2.292 0 0 1 2.778-3.111c.14.042.17.215.068.318L13.62 8.258c-.286.286-.253.781.072 1.107.325.325.82.357 1.107.071l.973-.973c.105-.105.281-.069.32.075.05.187.076.384.076.588Z',
      },
    },
  ],
  id: 'icon-collision',
  viewBox: '0 0 24 24',
};


const icons = {
  iconBolt,
  iconDog,
  iconCamp,
  iconDisplay,
  iconDownload,
  iconCameraLens,
  iconMicrophone,
  iconPerson,
  iconPhoneKey,
  iconRouteOverview,
  iconAc,
  iconClock,
  iconCollision,
};

const ImproveYourDrive = ({ classes, items, group }) => {
  return (
    <ul className={`${classes} tds-flex`}>
      {items.map(item => {
        const icon = icons[item.icon] ?? null;
        let label = group[item.label] ?? '';
        if (item.count) {
          label = label.replace('{COUNT}', formatNumber(item.count));
        }
        if (icon && label) {
          return (
            <li className={'tds-flex-item tds-flex--col_1of2'} key={item.label}>
              <Icon data={icon} />
              {label}
            </li>
          );
        }
      })}
    </ul>
  );
};

ImproveYourDrive.propTypes = {
  classes: string,
  items: array,
  group: object,
};

ImproveYourDrive.defaultProps = {
  classes: '',
  items: [],
  group: {},
};

export default ImproveYourDrive;
