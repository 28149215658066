import { PO_BOX_ADDRESS_REGEX } from 'dictionary';
import Analytics from 'analytics';
import { validatePostalCode } from '@tesla/intl-address';
import { getStates } from '@tesla/intl-display-names';

/**
 * Get geo distance between two geolocation points.
 *
 * @param number lat1   Latitude point
 * @param number lon1   Longitude point
 * @param number lat2   Latitude target point
 * @param number lon2   Longitude target point
 * @param string unit   Unit of measure (M, K, N): miles, kilometers, or nautical miles.
 *
 * @return number dist Distance in requested units.
 */
export function calculateGeoDistance(lat1, lon1, lat2, lon2, unit = 'M') {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  }
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit === 'K') {
    dist *= 1.609344;
  }
  if (unit === 'N') {
    dist *= 0.8684;
  }
  return dist;
}

/**
 * Do basic latitude / longitude validation
 *
 * @param number lat   Latitude point
 * @param number lng   Longitude point
 *
 * @return boolean Is lat / lng valid.
 */
export function validateLatLng(lat, lng) {
  if (!lat || !lng) {
    return false;
  }
  if (lat < -90 || lat > 90) {
    return false;
  }
  if (lng < -180 || lng > 180) {
    return false;
  }
  return true;
}

/**
 * COIN-10521: Exclude PO Box addresses
 * Helper to check whether supplied address is a PO Box.
 *
 * @param {string} address
 * @returns {boolean}
 */
export const containsPOBoxAddress = address => {
  return PO_BOX_ADDRESS_REGEX.test(address);
};

export const validateUserZip = location => {
  const { isInventorySwapEnabled, omsParams: { market } = {} } = window.tesla || {};
  if (isInventorySwapEnabled) {
    const { location: { latitude, longitude } = {}, postalCode } = location || {};
    const isValidZip = validatePostalCode(postalCode, { countryCode: market });
    const interaction =
      isValidZip && postalCode && latitude && longitude
        ? 'user-zipcode-found'
        : 'user-zipcode-not-found';
    Analytics.fireInteractionEvent(interaction);
  }
  return;
};

export const getLocationCookieShape = () => {
  return {
    ip: '',
    location: {
      latitude: '',
      longitude: '',
    },
    region: '',
    city: '',
    country: '',
    countryCode: '',
    postalCode: '',
  };
}

/**
 * Checks whether given region code is within given market 
 *
 * @param {string} market the market to validate against
 * @param {string} region the region code being validated
 * @returns {boolean} true if region code is within country, false otherwise
 */
export const validateRegionCode = (market, region) => {
  if (!market || !region) {
    return false
  }
  const regions = getStates(market);
  return !!regions?.find(reg => reg.code === region);
};