import React from 'react';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { string, number, bool } from 'prop-types';
import { i18n, buttonStringExtractor } from 'utils';

import { BUTTON_START, BUTTON_END, MODAL_SMALL, USER_LOCATION_MODAL } from 'dictionary';
import ModalTrigger from '../ModalTrigger';
import { formatCurrency } from '@tesla/coin-common-components';

const ZipCodeLink = ({
  isTransportFeeCollectionEnabled,
  transportationFee,
  deliveryPostalCode,
}) => {
  const transportFeeLabelWithAction = isTransportFeeCollectionEnabled
    ? 'transport_fee_label_with_action_redesign'
    : 'estimate_transport_fee_label_with_action_redesign';

  const transportFeeLabelShort = i18n(
    `DeliveryLocation.${
      transportationFee
        ? transportFeeLabelWithAction
        : 'estimate_transport_nofee_label_with_action_redesign'
    }`,
    {
      FEE: formatCurrency(transportationFee),
      POSTAL_CODE: deliveryPostalCode,
      BUTTON_START,
      BUTTON_END,
    }
  );

  const transportFeeLabelCopy = buttonStringExtractor(i18n(transportFeeLabelShort));

  return (
    <>
      <span>
        <span>{transportFeeLabelCopy.firstPart}</span>
        <ModalTrigger
          options={{
            props: {
              componentName: USER_LOCATION_MODAL,
              props: {
                genericWrapper: true,
                size: MODAL_SMALL,
                classes: 'delivery-location-modal-trigger',
                containerCss: 'tds-display--inline',
              },
            },
          }}
        >
          {transportFeeLabelCopy.buttonPart}
        </ModalTrigger>
        <span>{transportFeeLabelCopy.lastPart}</span>
      </span>
    </>
  );
};

ZipCodeLink.propTypes = {
  isTransportFeeCollectionEnabled: bool,
  transportationFee: number,
  deliveryPostalCode: string,
};

ZipCodeLink.defaultProps = {
  isTransportFeeCollectionEnabled: false,
  transportationFee: 0,
  deliveryPostalCode: '',
};

function mapStateToProps(state) {
  return {
    isTransportFeeCollectionEnabled: _get(state, 'App.isTransportFeeCollectionEnabled'),
    transportationFee: _get(state, 'ReviewDetails.DeliveryDetails.TransportationFee', 0),
    deliveryPostalCode: _get(state, 'ReviewDetails.DeliveryDetails.PostalCode', ''),
  };
}

export default connect(mapStateToProps)(ZipCodeLink);
