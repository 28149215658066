/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useEffect, useRef } from 'react';
import { func, string, bool, arrayOf, shape } from 'prop-types';
import { Form, Select } from '@tesla/informed-tds';
import { htmlToReact, constructUrl, i18n } from 'utils';
import _upperFirst from 'lodash/upperFirst';
import cx from 'classnames';
import DeliveryDetailsForm from '../../../AccountForm/DeliveryDetails';

/**
 * RegionalIncentive template
 */
const RegionalSelectorTemplate = ({
  regions,
  regionCode,
  onRegionChange,
  regionSelectorVisible,
  isLayoutMobile,
  isLayoutTablet,
  regionName,
  showRegionalIncentiveDropDown,
  modelCode,
  showDisclaimer,
  toggleRegionSelector,
  modelName,
  locale,
  sibling,
  showDeliveryZipCode,
  showDeliveryState,
  showRegionAndZipCode,
  deliveryZip,
  className,
  showSubmitButton,
  onSubmit,
  enableCyberpunk,
}) => {
  const formApiRef = useRef();

  useEffect(() => {
    if (formApiRef?.current && regionCode) {
      formApiRef.current?.reset();
    }
  }, [regionCode]);

  return (
    <div className={cx('regional-incentive', className)}>
      <If condition={showRegionalIncentiveDropDown}>
        <If condition={!regionSelectorVisible}>
          <div
            className={cx('tds-flex tds-flex-gutters', {
              'tds-flex-item': isLayoutMobile || isLayoutTablet,
              'dd-wrapper tds-flex--col_1of2':
                !showDeliveryZipCode && !(isLayoutMobile || isLayoutTablet),
            })}
          >
            <If condition={showDeliveryZipCode}>
              <div
                className={cx('tds-flex-item', {
                  'tds-flex--col_1of2': !showSubmitButton,
                })}
              >
                <DeliveryDetailsForm
                  isInline={false}
                  isPostalCodeEnable={true}
                  labelCopy={'DeliveryLocation.delivery_zipcode'}
                  placeholder={i18n('DeliveryTiming.enter_delivery_zipcode')}
                  showSubmitButton={showSubmitButton}
                  onSubmit={showSubmitButton ? onSubmit : {}}
                />
              </div>
            </If>
            <If condition={showDeliveryState}>
              <div className="tds-flex-item">
                <Form formApiRef={formApiRef}>
                  <Select
                    name="region"
                    id="region"
                    label={i18n('Incentives.deliveryState')}
                    options={regions}
                    initialValue={regionCode}
                    value={regionCode}
                    onChange={onRegionChange}
                    tabIndex={0}
                  />
                </Form>
              </div>
            </If>
          </div>
        </If>
        <If condition={regionSelectorVisible}>
          <button
            type="button"
            className={cx('tds-link', { 'tds-link--primary': !showRegionAndZipCode && !enableCyberpunk })}
            onClick={toggleRegionSelector}
          >
            <Choose>
              <When condition={showRegionAndZipCode}>
                {regionName && deliveryZip
                  ? i18n('Incentives.regionAndZipCode', {
                      REGION: regionName,
                      ZIP_CODE: deliveryZip,
                    })
                  : regionName || deliveryZip}
              </When>
              <When condition={showDeliveryState}>{i18n('Incentives.changeRegion')}</When>
              <Otherwise>{i18n('Incentives.changeDeliveryZip')}</Otherwise>
            </Choose>
          </button>
        </If>
        <If condition={showDisclaimer && i18n(`Incentives.description`).hasOwnProperty(modelCode)}>
          <div
            className="regional-incentive-disclaimer tds-text--caption tds--vertical_padding"
            tabIndex={-1}
          >
            {htmlToReact(
              i18n(
                `Incentives.description.${modelCode}`,
                {
                  REGIONNAME: regionName,
                  MODEL_NAME: modelName,
                  LINK: constructUrl('support/incentives?redirect=no', sibling, locale),
                },
                null,
                { specificOverride: locale }
              )
            )}
          </div>
        </If>
      </If>
    </div>
  );
};

RegionalSelectorTemplate.propTypes = {
  regions: arrayOf(shape({})),
  regionCode: string,
  onRegionChange: func,
  regionSelectorVisible: bool,
  isLayoutMobile: bool,
  isLayoutTablet: bool,
  regionName: string,
  showRegionalIncentiveDropDown: bool,
  modelCode: string,
  showDisclaimer: bool,
  toggleRegionSelector: func,
  modelName: string,
  locale: string,
  sibling: string,
  showDeliveryZipCode: bool,
  showDeliveryState: bool,
};

RegionalSelectorTemplate.defaultProps = {
  regions: [],
  regionCode: '',
  onRegionChange: () => {},
  regionSelectorVisible: false,
  isLayoutMobile: false,
  isLayoutTablet: false,
  regionName: '',
  showRegionalIncentiveDropDown: false,
  modelCode: '',
  showDisclaimer: false,
  toggleRegionSelector: () => {},
  modelName: '',
  locale: '',
  sibling: '',
  showDeliveryZipCode: false,
  showDeliveryState: false,
};

export default RegionalSelectorTemplate;
