import React from 'react';
import { i18n, htmlToReact } from 'utils';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const DeliveryEstDisclaimerSection = ({ rn, market }) => {
  return (
    <div className="delivery-estimate-disclaimer">
      <Choose>
        <When condition={!!rn && market === 'CN'}>
          {htmlToReact(i18n('DeliveryTiming.editDesignDisclaimer'))}
        </When>
        <Otherwise>{htmlToReact(i18n('DeliveryTiming.disclaimer'))}</Otherwise>
      </Choose>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    rn: state?.Configuration?.rn || '',
  };
}

DeliveryEstDisclaimerSection.propTypes = {
  rn: PropTypes.string.isRequired,
  market: PropTypes.string.isRequired,
};

DeliveryEstDisclaimerSection.defaultProps = {
  rn: '',
  market: '',
};

export default connect(mapStateToProps)(DeliveryEstDisclaimerSection);
