import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from 'utils';
import { formatCurrency, formatPercent } from '@tesla/coin-common-components';
import { FinanceTypes } from '../../../common/dictionary';
import _get from 'lodash/get';

const BriefCalculatedResult = ({ downPayment, interest, terms }) => {
  return (
    <p className="tds-text--caption tds-o-padding_bottom-8">
      {i18n('SummaryPanel.disclaimers.loan_lease__brief_calculate', {
        DOWNPAYMENT: formatCurrency(downPayment),
        RATE: interest,
        MONTHS: terms,
      })}
    </p>
  );
};

BriefCalculatedResult.propTypes = {
  downPayment: PropTypes.number,
  interest: PropTypes.number,
  terms: PropTypes.number,
  financeType: PropTypes.string,
};

BriefCalculatedResult.defaultProps = {
  financeType: FinanceTypes.CASH,
  downPayment: 0,
  interest: 0,
  terms: 0,
};

const mapStateToProps = (state, ownProps) => {
  const { leaseData, loanData } = state.Forms;
  if (ownProps?.financeType === FinanceTypes.LEASE) {
    const params = leaseData?.params ?? {};
    const term = _get(state, 'Pricing.calculatorParameters.leaseTerm', 0);
    const aprMatrix = _get(state, 'Forms.leaseData.aprMatrix', []);
    const { downPayment } = params;
    return {
      downPayment,
      interest: formatPercent(aprMatrix?.value, 2),
      terms: term,
    };
  }

  if (ownProps?.financeType === FinanceTypes.LOAN) {
    const params = loanData?.params ?? {};
    const term = _get(state, 'Pricing.calculatorParameters.loanTerm', 0);
    const aprMatrix = _get(state, 'Forms.loanData.aprMatrix', []);
    const { downPayment } = params;
    return {
      downPayment,
      interest: formatPercent(aprMatrix?.value, 2),
      terms: term,
    };
  }

  return {};
};

export default connect(mapStateToProps)(BriefCalculatedResult);
