export const InitialState = {
  allowedSections: ['payment'],
  section: 'payment',
  main: [
    {
      component: 'InventoryAssetLoader',
      key: 'PAYMENT.asset.image',
      sections: ['payment'],
      props: {
        asset: {
          type: 'image',
        },
        className: 'group-main-content--full-asset',
        assetPath: 'ReviewDetails.product.data.staticAsset',
        isAsset: true,
      },
      selected_by: {
        or: [
          {
            on: ['tablet', 'mobile'],
          },
        ],
      },
    },
    {
      component: 'PaymentOverview',
      key: 'PAYMENT',
      sections: ['payment'],
    },
  ],
  gallery: [
    {
      component: 'MainGallery',
      key: 'MainGallery:gallery.EXTERIOR',
      group: 'EXTERIOR_GALLERY',
      source: {
        path: 'ReviewDetails.product.data.staticAsset',
        defaultParams: {
          type: 'image',
        },
      },
      sections: ['payment'],
    },
  ],
};
export default InitialState;
