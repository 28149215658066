import { FEATURE_LIST_MODAL_OPEN, FEATURE_LIST_MODAL_CLOSE } from 'dictionary';

export const openFeatureModal = payload => {
  return dispatch => {
    dispatch({
      type: FEATURE_LIST_MODAL_OPEN,
      payload,
    });
  };
};

export const closeFeatureModal = () => {
  return dispatch => {
    dispatch({
      type: FEATURE_LIST_MODAL_CLOSE,
    });
  };
};
