import {
  ACCESSORIES_TOGGLE_ITEM,
  ACCESSORY_ADDRESS_TEXT,
  ACCESSORIES_OPEN_MODAL,
  ACCESSORIES_CLOSE_MODAL,
  ACCESSORY_UPDATE_SHIP_TO_ADDRESS,
  ACCESSORY_UPDATE_TAX,
  ACCESSORY_ADDRESS_LINE_2,
  DESELECT_ACCESSORIES,
  ACCESSORIES_TAX_CALL_ERROR,
  GOOGLE_ADDRESS_NOT_FOUND,
  UPDATE_CUSTOM_ADDRESS_VALIDATION_COMPLETION,
  ACCESSORY_BIDDABLE_FLOW_OPT_IN,
  ACCESSORY_BIDDING_ELIGIBILITY,
  ACCESSORY_SET_CUSTOM_ADDRESS_VALIDITY,
  ACCESSORY_SET_ADDRESSES_AFTER_VALIDATION,
  ACCESSORY_SET_SUGGESTED_ADDRESS,
  ACCESSORY_SET_CUSTOM_ADDRESS,
  SET_PARTIAL_GOOGLE_ADDRESS,
  UPDATE_ACCESSORIES_LIST,
} from 'dictionary';
import {
  getTaxData,
  smoothScrollToError,
  saveAccessorySelected,
  clearAccessorySelected,
} from 'utils';
import {
  getSelectedAccessories,
  getItemEligibilityEndpoint,
  getItemEligibilityRequestPayload,
  isEnterprisePurchaseOrder,
  isChargingConnectorIncluded,
  isInventory,
} from 'selectors';
import { request } from 'utils/requestAgent';
import _debounce from 'lodash/debounce';
import Analytics from 'analytics';

export const toggleAccessoryItem = item => (dispatch, getState) => {
  dispatch({
    type: ACCESSORIES_TOGGLE_ITEM,
    item,
  });

  saveAccessorySelected(getSelectedAccessories(getState()));
};

export const deselectAccessories = (error = null) => {
  return (dispatch, getState) => {
    dispatch({
      type: DESELECT_ACCESSORIES,
      error,
    });
    if (error) {
      const state = getState();
      const { App } = state;
      const { isLayoutMobile, isLayoutTablet } = App;
      const isDesktop = !isLayoutMobile && !isLayoutTablet;
      smoothScrollToError('', isDesktop);
    }
    clearAccessorySelected();
  };
};

export const updateAccessoriesTax = payload => {
  const { error = false, signature } = payload || {};
  return dispatch => {
    dispatch({
      type: ACCESSORY_UPDATE_TAX,
      payload,
    });
    if (error && !signature) {
      dispatch(deselectAccessories(ACCESSORIES_TAX_CALL_ERROR));
    }
  };
};

export const updateAccessoryAddressText = payload => ({
  type: ACCESSORY_ADDRESS_TEXT,
  payload,
});

export const openAccessoriesModal = ({ items }) => ({
  type: ACCESSORIES_OPEN_MODAL,
  items,
});

export const closeAccessoriesModal = () => ({
  type: ACCESSORIES_CLOSE_MODAL,
});

export const toggleAccessoryItemWithShipping = payload => {
  return async (dispatch, getState) => {
    dispatch({
      type: ACCESSORIES_TOGGLE_ITEM,
      item: payload,
    });
    const state = getState();
    const isEnterprisePO = isEnterprisePurchaseOrder(state);
    if (!isEnterprisePO) {
      saveAccessorySelected(getSelectedAccessories(getState()));

      const { shippingAddressText, grossTotal } = state?.Accessories;

      if (!grossTotal) {
        return dispatch(deselectAccessories());
      }
      if (shippingAddressText) {
        const taxData = await getTaxData(state);
        dispatch(updateAccessoriesTax(taxData));
      }
    }
  };
};

export const updateAccessoryShipToAddress = payload => {
  return async (dispatch, getState) => {
    // COIN-6739: Remap PR for commerce
    const { country } = payload;
    let payloadRemapped = { ...payload };
    if (country === 'PR') {
      payloadRemapped = { ...payload, country: 'US', state: 'PR' };
    }
    dispatch({
      type: ACCESSORY_UPDATE_SHIP_TO_ADDRESS,
      payload: payloadRemapped,
    });
    const state = getState();
    const taxData = await getTaxData(state);
    dispatch(updateAccessoriesTax(taxData));
  };
};

export const updateAccessoryAddressLine2 = payload => ({
  type: ACCESSORY_ADDRESS_LINE_2,
  payload,
});

// payload not required, because once user clicks 'Cannot find address'
// we give them custom set of fields, and they cannot go back to google
// autocomplete unless they reload the page
export const setGoogleAddressNotFound = () => ({
  type: GOOGLE_ADDRESS_NOT_FOUND,
});

export const updateCustomAddressValidationComplete = payload => ({
  type: UPDATE_CUSTOM_ADDRESS_VALIDATION_COMPLETION,
  payload,
});

export const setPartialGoogleAddress = payload => ({
  type: SET_PARTIAL_GOOGLE_ADDRESS,
  payload,
});

export const toggleAccessoryBiddableFlowOptIn = () => ({
  type: ACCESSORY_BIDDABLE_FLOW_OPT_IN,
});

export const setIsCustomAddressValid = payload => ({
  type: ACCESSORY_SET_CUSTOM_ADDRESS_VALIDITY,
  payload,
});

export const setAddressesAfterValidation = payload => ({
  type: ACCESSORY_SET_ADDRESSES_AFTER_VALIDATION,
  payload,
});

export const setIsSuggestedAddressSelected = payload => ({
  type: ACCESSORY_SET_SUGGESTED_ADDRESS,
  payload,
});

export const setIsCustomAddressSelected = payload => ({
  type: ACCESSORY_SET_CUSTOM_ADDRESS,
  payload,
});

export const getItemEligibility = () => {
  return _debounce((dispatch, getState) => {
    const state = getState();
    const payload = getItemEligibilityRequestPayload(state);
    const endpoint = getItemEligibilityEndpoint(state);
    const accessories = getSelectedAccessories(state);
    request
      .post(endpoint)
      .set('Accept', 'application/json')
      .set('content-type', 'application/json')
      .send(payload)
      .end((error, res) => {
        const { eligibilityData = [] } = res?.body || {};
        let payload = {};
        if (!error) {
          Object.keys(accessories).forEach(acc => {
            const isBiddingEligible =
              eligibilityData.find(({ partNumber }) => partNumber == acc)?.eligible || false;
            payload = {
              ...payload,
              [acc]: {
                ...accessories[acc],
                isBiddingEligible,
              },
            };
          });
          dispatch({
            type: ACCESSORY_BIDDING_ELIGIBILITY,
            payload,
          });
        }
      });
  }, 300);
};

export const updateAccessoriesList = (payload, isFetching = false) => ({
  type: UPDATE_ACCESSORIES_LIST,
  payload,
  isFetching,
});

export const getAccessories = () => {
  return (dispatch, getState) => {
    const state = getState();
    const {
      App: {
        locale,
        routes: { getAccessoriesData: url = '' } = {},
        isEnterpriseOrder,
        isDm,
        mobileVersion,
        contextApp,
        showRecommendedAccessories,
      } = {},
      OMS: { oms_params: { model, market } = {} } = {},
      Accessories: { isFetching } = {},
    } = state || {};

    if (isFetching) {
      return;
    }

    let contextType = isEnterpriseOrder ? 'B2B' : 'B2C';
    const payload = {
      country: market,
      model,
      locale,
      showRecommendedAccessories,
      contextType: isDm && mobileVersion ? 'mobileApp' : contextType,
      isChargingConnectorIncluded: isChargingConnectorIncluded(state),
      mobileVersion,
      contextApp,
    };

    dispatch(updateAccessoriesList({}, true));

    try {
      request
        .post(url)
        .set('Accept', 'application/json')
        .set('content-type', 'application/json')
        .send(payload)
        .timeout(3000)
        .end((err, res) => {
          const { accessoriesList = {}, geographies = {}, error } = res?.body || {};
          if (!err) {
            if (Object.keys(accessoriesList)?.length) {
              const { list: types = {} } = accessoriesList || {};
              const items = Object.values(types) || [];
              const list = items?.flat() || [];
              if (list?.length) {
                if (types?.category?.length) {
                  Analytics.fireInteractionEvent('charging-accessories-shown');
                }
                if (types?.recommended?.length) {
                  Analytics.fireInteractionEvent('recommended-accessories-shown');
                }
              }
              dispatch(
                updateAccessoriesList({
                  accessoriesList: {
                    ...accessoriesList,
                    list,
                    types,
                  },
                  geographies,
                })
              );
            }
          } else {
            dispatch(updateAccessoriesList({ error }));
          }
        });
    } catch (err) {
      return dispatch(updateAccessoriesList({ error: err }));
    }
  };
};
