import React, { useState } from 'react';
import { Icon } from '@tesla/design-system-react';
import { iconChevron, iconChevron180 } from '@tesla/design-system-icons';
import classNames from 'classnames';
import { Collapse } from 'react-collapse';

import './accordion.css';

export default function Accordion({ className, defaultOpen = false, title, children }) {
    const [open, setOpen] = useState(defaultOpen);

    return (
        <div className={classNames("accordion", className)}>
            <div className="accordion__summary" onClick={() => setOpen(prevOpen => !prevOpen)}>
                <h4>{title}</h4>
                <span><Icon data={open ? iconChevron : iconChevron180} /></span>
            </div>
            <Collapse isOpened={open}>
                {children}
            </Collapse>
        </div>
    )
}