import React, { createContext, useContext } from 'react';

const HideSavingsToggleContext = createContext(false);

// eslint-disable-next-line react/prop-types
const HideSavingsToggle = ({ value = false, children }) => {
  return (
    <HideSavingsToggleContext.Provider value={value}>{children}</HideSavingsToggleContext.Provider>
  );
};

export const useHideSavingsToggle = () => {
  return useContext(HideSavingsToggleContext);
};

export default HideSavingsToggle;
