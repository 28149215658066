import React, { useEffect, useRef } from 'react';
import { func, bool, string } from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Card } from '@tesla/design-system-react';
import { UI_DATA_IDS, SHOW_BANNER_MODAL, EXPERIMENT_TOP_BANNER_OFF } from 'dictionary';
import { htmlToReact } from 'utils';

import TextLoader from '../TextLoader';
import ActionTrigger from '../ActionTrigger';
import _get from 'lodash/get';
import { Icon } from '@tesla/design-system-react';
import { iconChevron90 } from '@tesla/design-system-icons';
import GioStatistics from 'gioStatistics';
import { WEB_CONFIGURATOR_BANNER_LINK } from '../../common/gioStatistics';

const DynamicBanner = ({
  dynamicBannerContentStringified,
  openBannerModal,
  referralCode,
  countryCode,
  isCoinReloaded,
  isLayoutMobile,
  isLayoutTablet,
  triggerAction,
  hasDynamicContent,
  bannerDisabled,
  useTopBanner,
}) => {
  const dynamicBannerContent = dynamicBannerContentStringified
    ? JSON.parse(dynamicBannerContentStringified)
    : [];
  const topBannerRef = useRef(null);
  const topBannerSecondaryRef = useRef(null);
  const contentLength = dynamicBannerContent?.length;
  const isTopBanner = useTopBanner && !bannerDisabled && isCoinReloaded && contentLength;
  const headerContainer = document.querySelector(
    countryCode === 'CN' ? '.site-header-container > div' : '.site-header-container'
  );
  const t4bNotificationStickyBanner = document.querySelector('.site-header-container .tds-banner');
  const isDesktop = !(isLayoutMobile || isLayoutTablet);

  useEffect(() => {
    if (isTopBanner) {
      document.body.classList.add('has-top-banner');
    } else {
      document.body.classList.remove('has-top-banner');
    }
  }, [isTopBanner]);

  useEffect(() => {
    let bannerHeightTimer = setTimeout(() => {
      if (
        (topBannerRef?.current?.clientHeight || hasDynamicContent) &&
        !(!contentLength || (isCoinReloaded && !isTopBanner))
      ) {
        const bannerHeight = parseInt(topBannerRef?.current?.clientHeight) || 0;
        const headerHeight = headerContainer
          ? parseInt(getComputedStyle(headerContainer)?.height) || 0
          : 0;
        const t4bBannerHeight = t4bNotificationStickyBanner
          ? parseInt(getComputedStyle(t4bNotificationStickyBanner)?.height) || 0
          : 0;
        let totalHeight = bannerHeight + headerHeight + t4bBannerHeight;
        if (topBannerSecondaryRef?.current?.clientHeight) {
          totalHeight += topBannerSecondaryRef?.current?.clientHeight || 0;
        }
        const root = document.getElementById('root');
        if (root) {
          root.style.setProperty('--coin-top-banner-height', `${bannerHeight}px`);
          root.style.setProperty('--tds-shell-header-height', `${totalHeight}px`);
          document.documentElement.style.setProperty(
            '--tds-shell-header-height--override',
            `${totalHeight}px`
          );
        }
      }
    }, 200);
    return () => {
      clearTimeout(bannerHeightTimer);
    };
  }, [
    topBannerRef?.current?.clientHeight,
    isTopBanner,
    headerContainer,
    topBannerSecondaryRef?.current?.clientHeight,
  ]);

  if (!contentLength || (isCoinReloaded && !isTopBanner)) {
    return null;
  }

  const isMultiBanner = contentLength > 1;
  const isCentered = contentLength < 2 && dynamicBannerContent?.[0]?.isAlignLeft !== true;
  const gioTrackBanner = () => {
    countryCode === 'CN' && GioStatistics.manuallyTrack(WEB_CONFIGURATOR_BANNER_LINK);
  };
  const onClick = data => {
    gioTrackBanner();
    const { modalContent, modalTitle, modalSubtitle, modalBtnCopy, modalBtnBehavior } = data || {};
    const content =
      modalTitle && modalContent
        ? [
            {
              modalTitle,
              modalSubtitle,
              modalContent,
              modalBtnCopy,
              modalBtnBehavior,
            },
          ]
        : [];
    openBannerModal({ content });
  };

  const addReferralToLink = content => {
    if (countryCode !== 'CN') {
      return content;
    }
    const regEx = /["']https?:\/\/.*?["']/gi;
    let urls = content.match(regEx);
    if (Array.isArray(urls)) {
      urls = _.uniq(urls);
      urls.forEach(function(url) {
        if (!url || url.indexOf('referral') >= 0 || !referralCode) {
          return;
        }
        url = url.replaceAll("'", '').replaceAll('"', '');
        let targetUrl = new URL(url);
        targetUrl.searchParams.append('referral', referralCode);
        content = _.replace(content, url, targetUrl.toString());
      });
    }
    return content;
  };
  const getContent = bannerContent => {
    if (isTopBanner) {
      const { description, btnCopy, btnBehavior } = bannerContent[0];
      const replacedDescription = addReferralToLink(description);
      return (
        <>
          <If condition={description}>
            <TextLoader data={replacedDescription} transitionWrapper={false} />
          </If>
          &nbsp;
          <If condition={btnCopy && (btnBehavior?.length || bannerContent[0]?.modalContent)}>
            <div className="modal-trigger-container">
              <Choose>
                <When condition={btnBehavior?.length && isDesktop}>
                  <TextLoader
                    data={{
                      content: btnCopy,
                      behavior: btnBehavior,
                    }}
                  />
                </When>
                <When condition={btnCopy && isDesktop}>
                  <button
                    className="modal-trigger tds-link"
                    onClick={() => onClick(bannerContent[0])}
                  >
                    {htmlToReact(btnCopy)}
                  </button>
                </When>
                <When condition={!isDesktop}>
                  <Icon data={iconChevron90} />
                </When>
              </Choose>
            </div>
          </If>
        </>
      );
    }
    return (
      <>
        {bannerContent?.map((content, index) => {
          const { title, subtitle, description, btnCopy, btnBehavior, link } = content || {};
          const replacedDescription = addReferralToLink(description);
          return (
            <div key={`banner-id-${index}`} className={cx({ 'tds--padding_top-16': index })}>
              <If condition={title}>
                <TextLoader data={title} tag={{ component: 'strong' }} className="tds-text--h3" />
              </If>
              <If condition={subtitle}>
                <TextLoader
                  data={subtitle}
                  tag={{ component: 'strong' }}
                  className="tds-text--body"
                />
              </If>
              <If condition={description}>
                <TextLoader
                  data={replacedDescription}
                  transitionWrapper={false}
                  className="tds--padding_top-8 tds-text--caption"
                />
              </If>
              <If condition={btnCopy && (btnBehavior?.length || content?.modalContent)}>
                <div className="tds--padding_top-8 modal-trigger-container">
                  <Choose>
                    <When condition={btnBehavior?.length}>
                      <TextLoader
                        data={{
                          content: btnCopy,
                          behavior: btnBehavior,
                        }}
                      />
                    </When>
                    <When condition={btnCopy}>
                      <button className="modal-trigger tds-link" onClick={() => onClick(content)}>
                        {htmlToReact(btnCopy)}
                      </button>
                    </When>
                  </Choose>
                </div>
              </If>
              <If condition={link}>
                <div className="text-loader--content tds-text--center federal-tax--link tds-text--caption">
                  {htmlToReact(link)}
                </div>
              </If>
            </div>
          );
        })}
      </>
    );
  };
  let bannerContentBlock = getContent(dynamicBannerContent);
  if (!bannerContentBlock) {
    return null;
  }

  if (isTopBanner) {
    const onTouch = dynamicBannerContent => {
      if (!isDesktop) {
        if (dynamicBannerContent?.modalContent) {
          return onClick(dynamicBannerContent);
        } else if (dynamicBannerContent?.btnBehavior) {
          const actionType = dynamicBannerContent?.btnBehavior[0]?.content?.type;
          actionType && triggerAction({ actionType: actionType });
        }
      }
      gioTrackBanner();
    };
    return (
      <div className="top-banner--container">
        <div
          className="top-banner"
          ref={topBannerRef}
          onClick={() => onTouch(dynamicBannerContent[0])}
        >
          <ActionTrigger>{getContent([dynamicBannerContent[0]])}</ActionTrigger>
        </div>
        <If condition={countryCode === 'CN' && dynamicBannerContent[1]}>
          <div
            className="top-banner top-banner--secondary"
            ref={topBannerSecondaryRef}
            onClick={() => onTouch(dynamicBannerContent[1])}
          >
            <ActionTrigger>{getContent([dynamicBannerContent[1]])}</ActionTrigger>
          </div>
        </If>
      </div>
    );
  }

  return (
    <div className={'tds-flex tds-flex--justify-center'}>
      <Card
        className={cx('coin-prominent--currency tds-scrim--black tds-scrim--gradient', {
          'coin-card--multibanner': isMultiBanner,
          'tds-text--center': isCentered,
        })}
        data-id={UI_DATA_IDS?.overviewSection?.banner}
      >
        <ActionTrigger>{bannerContentBlock}</ActionTrigger>
      </Card>
    </div>
  );
};

DynamicBanner.propTypes = {
  dynamicBannerContentStringified: string,
  openBannerModal: func.isRequired,
  triggerAction: func.isRequired,
  hasDynamicContent: bool.isRequired,
  referralCode: string,
  countryCode: string.isRequired,
  isCoinReloaded: bool.isRequired,
  isLayoutMobile: bool.isRequired,
  isLayoutTablet: bool.isRequired,
  bannerDisabled: bool.isRequired,
  useTopBanner: bool.isRequired,
};

DynamicBanner.defaultProps = {
  dynamicBannerContentStringified: '',
  referralCode: '',
};

function mapDispatchToProps(dispatch) {
  return {
    openBannerModal: props => {
      dispatch({
        type: SHOW_BANNER_MODAL,
        props,
      });
    },
    triggerAction: props => {
      dispatch({
        type: props?.actionType,
      });
    },
  };
}

function mapStateToProps(state) {
  const { ApplicationFlow, App } = state;
  const { optimizelyExpIds = [], referral = {} } = ApplicationFlow || {};
  const { referralCode = '' } = referral || {};
  const { useTopBanner = false, isCoinReloaded = false } = App || {};
  const countryCode = _get(state, 'OMS.oms_params.market');
  return {
    referralCode,
    countryCode,
    useTopBanner: isCoinReloaded,
    isCoinReloaded: state?.App?.isCoinReloaded,
    isLayoutMobile: state?.App?.isLayoutMobile,
    isLayoutTablet: state?.App?.isLayoutTablet,
    bannerDisabled: optimizelyExpIds?.includes(EXPERIMENT_TOP_BANNER_OFF),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DynamicBanner);
