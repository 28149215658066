import * as Sentry from '@sentry/react';
import _get from 'lodash/get';
import { version } from '../package.json';

// ----------------- Sentry Integration Test -------------------------------
const { location } = window;
const countryCode = _get(window.tesla, 'omsParams.market', 'US');
const environment = window.tesla?.environment;
const isLocalHost = location?.hostname === 'localhost';
const sentryEnabled = countryCode === 'CN' ? _get(window.tesla, 'isSentryEnabled', false) : true;

const dsn =
  countryCode === 'CN'
    ? 'https://56bf4e5474d542bab714e5e5f5407f45@error-log.tesla.cn/115'
    : 'https://f61facb0d9589a9f7f0feb7f030aea24@error-log.tesla.com/25';

// enable sentry for global for both stage and prod environments
if (sentryEnabled) {
  Sentry.init({
    sampleRate: 0.25,
    integrations: [
      new Sentry.BrowserTracing({
        tracingOrigins: ['localhost', /^\//],
      }),
      new Sentry.Integrations.Breadcrumbs({ console: false }),
    ],
    environment,
    tracesSampleRate: 0.2,
    maxBreadcrumbs: 50,
    enabled: true,
    dsn,
    release: version,
    beforeSend: e => {
      // do not send any logs to sentry if localhost
      if (isLocalHost) return null;
      return e;
    },
    initialScope: {
      tags: { locale: window.tesla.App.locale },
    },
  });
}
