const InitialState = {
    object: {
    	lease: {},
    	finance: {},
    	fees: {
    		available: {},
    		current: {},
            total: 0
    	},
        incentives:{
            available:{},
            current: {},
            lookup: {},
            total: 0
        },
        tcc:{
            available:{},
            current: {},
            total: 0,
            comparisonTotal: 0,
            savingsTotal: 0
        },
        fms_incentives:{},
        fms_fees:{},
        fms_loan:{},
        regionList: [],
        userSelectedIncentives: undefined,
        showCombinedSavings: false,
    }
}

export default InitialState
