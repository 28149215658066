import React from 'react';
import { connect } from 'react-redux';
import { getEarlyVehicleDesigns, getRegionName, getCustomVehicleDesign } from 'selectors';
import { DELIVERY_ZIP_MODAL, MODAL_SMALL, LOADER_START } from 'dictionary';
import {
  checkVehicleAvailability,
  setOption,
  setVehicleDesign,
  fetchAlternateVehicleDesigns,
  openModal,
} from 'actions';
import Template from './template';

const InventorySwap = props => {
  return <Template {...props} />;
};

const mapStateToProps = state => {
  const { App, ReviewDetails, Configuration = {}, Pricing = {}, Navigation = {} } = state;
  const { isLayoutMobileAdjusted, isLayoutTablet, isInventorySwapEnabled } = App || {};
  const { vehicleDesign, DeliveryDetails = {}, displayFinanceToggleOnSwap } = ReviewDetails || {};
  const { clearInventorSwapPaymentError = false } = Navigation;
  const {
    initialDesign,
    selectedConfig = '',
    error,
    pickupLocations,
    earlyVehicleDesigns,
    isEarlyDesignSelected,
    interactionTypeForSwap,
    swapConfig,
  } = vehicleDesign || {};
  const { options } = initialDesign || {};
  const initialVehicleConfig = `customDesign:${options}`;
  const pickupLocation = (Object.keys(swapConfig)?.length ? pickupLocations?.VrlCustomName : null) || 'other';

  return {
    market: state?.App?.countryCode ?? '',
    isMobile: isLayoutMobileAdjusted,
    isTablet: isLayoutTablet,
    customDesign: getCustomVehicleDesign(state),
    isInventorySwapEnabled,
    earlierDesigns: getEarlyVehicleDesigns(state),
    initialValues: {
      vehicleConfig: selectedConfig || initialVehicleConfig,
      pickupLocation,
    },
    error,
    pickupLocations,
    zipCode: DeliveryDetails?.PostalCode,
    selectedConfig,
    showPickupLocation: !earlyVehicleDesigns?.length,
    earlyVehicleDesigns,
    regionName: getRegionName(state),
    pickupLocation,
    totalSwapConfig: earlyVehicleDesigns?.length,
    isEarlyOptionSelected: isEarlyDesignSelected,
    interactionTypeForSwap,
    initialDesign,
    savedConfig: {
      initialDesign: {
        options: Configuration.option_string,
        price: Pricing.total,
        grossPrice: Pricing.grossPrice,
      },
      initialConfigByGroup: Configuration.options_by_group,
    },
    clearInventorSwapPaymentError,
    displayFinanceToggleOnSwap,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    reviewYourOrder: () => {
      dispatch(checkVehicleAvailability());
    },
    setOption: (option, props = {}) => {
      dispatch(setOption({ set: option }, props));
    },
    updateVehicleDesign: payload => {
      dispatch(setVehicleDesign(payload));
    },
    openUserZipModal: (props = {}) => {
      dispatch(
        openModal(DELIVERY_ZIP_MODAL, { genericWrapper: true, size: MODAL_SMALL, ...props })
      );
    },
    getAlternateDesigns: zipCode => {
      dispatch(fetchAlternateVehicleDesigns(zipCode));
    },
    showLoader: () => {
      dispatch({
        type: LOADER_START,
      });
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(InventorySwap);
