import React from 'react';
import { connect } from 'react-redux';
import { bool, func, shape, string, arrayOf } from 'prop-types';
import cx from 'classnames';
import { i18n, getEditDesignNavigationKey, getDesignAlerts } from 'utils';
import { navigationSelectKey } from 'actions';

import Navigation from '../../../components/GroupComponents/Navigation';
import Alert from '../../../components/Alert';
import NotificationBanner from '../../../components/GroupComponents/NotificationBanner';
import { getIsPostOrderSwap, isInventory } from '../../../common/selectors';
import { isInAppEnv } from '../../../common/utils';

const EditDesignLink = ({
  isEnabled,
  setNavigation,
  classes,
  navigationLabel,
  navigationKey,
  alerts = [],
  showAsLink = false,
}) => {
  if (!isEnabled) {
    return null;
  }

  const onClick = key => {
    setNavigation(key);
  };

  if (showAsLink) {
    return (
      <a className="tds-link" onClick={() => onClick(navigationKey)}>
        {i18n(navigationLabel)}
      </a>
    );
  }

  return (
    <div className={cx({ [classes]: classes }, 'coin-nav-link')}>
      <Navigation
        label={i18n(navigationLabel)}
        onClick={() => onClick(navigationKey)}
        icon={{
          id: 'tds-arrow',
          title: i18n('Review.edit_design_label'),
          description: i18n('Review.edit_design_label'),
          className: 'cf-nav-icon',
        }}
        classes="tds-flex--justify-flex-start"
      />
      <If condition={alerts?.length}>
        <Alert alerts={alerts} />
      </If>
      <NotificationBanner />
    </div>
  );
};

EditDesignLink.propTypes = {
  isEnabled: bool,
  setNavigation: func.isRequired,
  classes: string,
  navigationLabel: string,
  navigationKey: string,
  alerts: arrayOf(shape({})),
  showSinglePage: bool,
};

EditDesignLink.defaultProps = {
  isEnabled: false,
  classes: '',
  navigationLabel: '',
  navigationKey: '',
  alerts: [],
  showSinglePage: false,
};

function mapStateToProps(state) {
  const { App, ReviewDetails, Payment } = state;
  const {
    product = null,
    vehicleDesign: { earlyVehicleDesigns = [], pickupLocations } = {},
  } = ReviewDetails;
  const isProductWithOverview = product ? product.isProductWithOverview : false;
  const { showEditDesignLink = false, enableCyberpunk = false, showSinglePage = false } = App;
  const { isPaymentInteractionRequired = false } = Payment;
  const isInventoryOrder = isInventory(state);
  let navigationLabel = 'Review.edit_design_label';

  if (isInventoryOrder) {
    navigationLabel = 'Inventory.common.go_back_label';
  }

  if (earlyVehicleDesigns?.length || pickupLocations || enableCyberpunk) {
    navigationLabel = 'common.back';
  }

  return {
    isEnabled:
      !showSinglePage &&
      showEditDesignLink &&
      (!product || isProductWithOverview) &&
      !isPaymentInteractionRequired &&
      (!getIsPostOrderSwap(state) || !isInAppEnv()),
    navigationLabel,
    navigationKey: getEditDesignNavigationKey(state),
    alerts: getDesignAlerts(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setNavigation: key => {
      dispatch(navigationSelectKey({ key, isEditDesign: true }));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDesignLink);
