import React from 'react';
import { connect } from 'react-redux';
import { string, shape, arrayOf, bool } from 'prop-types';
import _get from 'lodash/get';
import classnames from 'classnames';
import { INVENTORY_FEATURES_MODAL, FEATURE_DETAILS_CARD } from 'dictionary';
import { i18n } from 'utils';
import { getConfigurationOptions, getInventorySteeringWheelDisclaimer } from 'selectors';

import InfoBlock from '../InfoBlock';

const FeaturesList = ({ featureOptions, isLayoutMobile, additionalInfoAfterButton }) => {
  const groupName = '';
  const type = '';
  const componentName = INVENTORY_FEATURES_MODAL;
  const analyticsInteraction = FEATURE_DETAILS_CARD;

  return (
    <div
      className={classnames(`features-list-container`, {
        'tds--vertical_padding--6x': isLayoutMobile,
      })}
    >
      <InfoBlock
        name="features-list"
        titleSection={i18n('Inventory.common.features')}
        titleSectionClass="tds-text--h2 tds--vertical_padding"
        contents={featureOptions}
        buttonLabel={i18n('Inventory.common.featureDetails')}
        buttonClass="tds-btn--tertiary"
        unordered
        componentName={componentName}
        groupName={groupName}
        type={type}
        analyticsInteraction={analyticsInteraction}
        additionalInfoAfterButton={additionalInfoAfterButton}
      />
    </div>
  );
};

FeaturesList.propTypes = {
  featureOptions: arrayOf(shape({})).isRequired,
  isLayoutMobile: bool,
  additionalInfoAfterButton: arrayOf(string),
};

FeaturesList.defaultProps = {
  isLayoutMobile: false,
  additionalInfoAfterButton: [],
};

function mapStateToProps(state) {
  const isPostOrderSwap = _get(state, 'App.isPostOrderSwap', false);
  const autopilotOpts = _get(state, 'CustomGroups.AUTOPILOT_PACKAGE.options', []);
  const inventoryData = _get(state, 'ReviewDetails.product.data', {});
  const configOptions = _get(inventoryData, 'OptionCodeSpecs.C_OPTS.options', []);
  const showCCSFeature = _get(state, 'ReviewDetails.showCCSFeature', false);
  const selectedOptions = getConfigurationOptions(state);
  const { optCodes = [], copy: inventoryOptionsDisclaimer } = getInventorySteeringWheelDisclaimer(state);

  const filterArr = [];
  const resultArr = configOptions.reduce((acc, item) => {
    const { code: itemCode, name: itemName = '' } = item;
    const found = autopilotOpts.includes(itemCode);
    if (found) {
      filterArr.push(item);
    } else {
      const itemMod = inventoryOptionsDisclaimer && optCodes.includes(itemCode);
      acc.push({
        ...item,
        name: itemMod ? `${itemName}*` : itemName,
      });
    }
    return acc;
  }, []);
  const showPremiumConnectivity = _get(state, 'ReviewDetails.showPremiumConnectivity', true);
  const inventoryConnectivity = _get(
    state,
    'ReviewDetails.product.data.OptionCodeSpecs.C_CALLOUTS.options',
    []
  ).find(opt => opt.group === 'CONNECTIVITY');
  const premiumConnectivityTrial = {
    code: inventoryConnectivity?.code,
    name: inventoryConnectivity?.name,
    long_name: '',
    description: inventoryConnectivity?.description,
  };
  const featureOptions = showPremiumConnectivity
    ? resultArr.concat(filterArr).concat([premiumConnectivityTrial])
    : resultArr.concat(filterArr);

  if (showCCSFeature) {
    const ccsFeatureLabel = i18n('Inventory.ccsChargingOption');
    featureOptions.push({
      code: 'CCS',
      name: ccsFeatureLabel,
      long_name: '',
      description: ccsFeatureLabel,
    });
  }

  return {
    inventoryData,
    featureOptions: isPostOrderSwap ? selectedOptions : featureOptions,
    isLayoutMobile: _get(state, 'App.isLayoutMobile', false),
    additionalInfoAfterButton: inventoryOptionsDisclaimer ? [
    (
      <span className="tds-text--caption">
        <sup>*</sup>{inventoryOptionsDisclaimer}
      </span>
    )] : [],
  };
}
export default connect(mapStateToProps)(FeaturesList);
