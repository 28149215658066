import { PRICE_CHANGED } from 'dictionary';
import { getDynamicBannerContent } from 'selectors';

function Banner(state = {}, action, { app_state }) {
  switch (action.type) {
    case PRICE_CHANGED:
      return {
        ...state,
        bannerContentParsed: getDynamicBannerContent({ ...app_state, Banner: state }),
      };
    default:
      return state;
  }
}

export default Banner;
