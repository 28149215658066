import _get from 'lodash/get';
import _isString from 'lodash/isString';
import _uniq from 'lodash/uniq';
import _head from 'lodash/head';
import { map, filter } from 'rxjs/operators';
import { Storage } from '@tesla/coin-common-components';
import { Configurator, Price, DSServices } from '@web/tesla-rest-ds-services';
import { isEarlyDesignSelected, getDefaultFinancePlanId } from 'selectors';

import {
  CONFIGURATION_BASE_REQUEST,
  CONFIGURATION_CHANGE_REQUEST,
  COMPLETE_CONFIGURATION_CHANGED,
  BASE_CONFIGURATION_CHANGED,
  PRICE_CHANGED,
  SET_CONFIGURATION_RN,
  REGISTRATION_TYPE_CHANGED,
  LOCAL_STORAGE_KEY_REGISTRATION,
  LOCAL_STORAGE_KEY_SUMMARY,
  LOCAL_STORAGE_KEY_EMIRATE,
  SUMMARY_PANEL_REGION_CHANGE,
  INVENTORY_DELIVERY_LOCATIONS_CHANGED,
  INVENTORY_DELIVERY_LOCATION_INVALID,
  POST_ORDER_START,
  DELIVERY_DETAILS_VALID_FLAG,
  SET_CURRENT_CONFIGURATION_SOURCE,
  CURRENT_CONFIGURATION_SOURCE_URL_PARAM,
  STORE_USER_CONFIG,
  RESET_PREVIOUS_CONFIG,
  NAVIGATION_VIEW_OVERVIEW,
  DELIVERY_DETAILS_CHANGED,
} from 'dictionary';

import { changeQueryParams, getQueryParameters } from 'utils';
import _isEmpty from 'lodash/isEmpty';

function requestConfigurationChange(option) {
  return {
    type: CONFIGURATION_CHANGE_REQUEST,
    option,
  };
}

function completeConfigurationChange(restConfiguratorResponse, set, skuValidation = false) {
  return (dispatch, getState) => {
    const state = getState();
    const { options } = restConfiguratorResponse;
    const { callToggleOption } = restConfiguratorResponse;
    const configurationByGroup = Configurator.optionsByGroup(
      { Lexicon: state.OMS.lexicon },
      { options }
    );

    const userSelectedOptions = _uniq(
      []
        .concat(state.Configuration.user_selected_options || [], (() => set || [])() || [])
        .filter(option => options.includes(option))
    );
    const userSelectedOptionsByCategory = Configurator.optionsByGroup(
      { Lexicon: state.OMS.lexicon },
      { options: userSelectedOptions }
    );
    const userSelectedOptionsByGroup = Configurator.optionsByGroup(
      { Lexicon: state.OMS.lexicon },
      { options: userSelectedOptions }
    );
    const groupTrim = _head(userSelectedOptionsByGroup?.matchedCodes?.TRIM);
    const trimCode = set?.find(code => code === groupTrim);
    const financePlanId = getDefaultFinancePlanId({state, trimCode});
    dispatch({
      type: COMPLETE_CONFIGURATION_CHANGED,
      set,
      options,
      callToggleOption,
      configurationByCategory: state.OMS.pricebook
        ? Configurator.optionsByCategory({ Lexicon: state.OMS.lexicon }, { options })
        : {},
      configurationByGroup,
      userSelectedOptions,
      userSelectedOptionsByCategory,
      userSelectedOptionsByGroup,
      restConfiguratorResponse,
      skuValidation,
      receivedAt: Date.now(),
      ...(financePlanId && { userInput: {financePlanId} }),
    });
  };
}

export function setOption(option, configureSetOption = {}) {
  const { dontKeepTrack, excludeOptions = false, callValidConfig = false, ruleAction } =
    configureSetOption || {};

  return (dispatch, getState) => {
    if (!option.set.length) {
      return;
    }
    const state = getState();
    let response;
    dispatch(requestConfigurationChange(option));
    const configuration = Object.assign(
      {},
      {
        userSelectedOptions: [].concat(option.set, state.Configuration.user_selected_options),
        ignoreRules: [],
      },
      option
    );
    const options = excludeOptions ? [] : state.Configuration.option_codes;
    const { query_params: queryParams = {} } = state?.App || {};
    let { skuValidation = false } = queryParams || {};
    skuValidation = !!skuValidation;
    if (callValidConfig) {
      response = getCompleteConfig(state, option.set);
    } else {
      response = Configurator.setOption(
        { Lexicon: state.OMS.lexicon, Configuration: { options } },
        { ...configuration, skuValidation, ruleAction }
      );
    }
    dispatch(
      completeConfigurationChange(response, dontKeepTrack ? null : option.set, skuValidation)
    );
  };
}

function requestBaseConfiguration(options) {
  return {
    type: CONFIGURATION_BASE_REQUEST,
    options,
  };
}

function baseConfigurationChange(Configuration) {
  return (dispatch, getState) => {
    const state = getState();
    const priceRest = Price.total(
      { PricebookOptions: state.OMS.pricebook, Lexicon: state.OMS.lexicon, Configuration },
      {}
    );
    dispatch({
      type: BASE_CONFIGURATION_CHANGED,
      restAPIS: {
        Configuration,
        priceRest,
      },
      options: Configuration.options,
      price: priceRest.vehiclePrice,
    });
  };
}

function checkIfTrimIsValidAfterConfirmPrice(configuration, lexicon) {
  const { pricingChangeConfirmed, rn } = configuration;
  if (!pricingChangeConfirmed) {
    return false;
  }
  const [cookieRn, targetTrim] = pricingChangeConfirmed;
  const trimInfo = lexicon?.groups.filter(({ code }) => code === 'TRIM')[0];
  const trimOptions = trimInfo?.options || [];
  return rn === cookieRn && trimOptions.indexOf(targetTrim) !== -1 ? targetTrim : '';
}

export function getCompleteConfig(state, options) {
  const { oms_params: omsParams } = state.OMS || {};
  const { model, variant } = omsParams || {};
  const { Lexicon } = new DSServices({}).get(() => {}, { model, variant }, omsParams);
  return Configurator.getValidConfiguration(options, Lexicon);
}

export function getDefaultConfiguration(options = [], props = {}) {
  return (dispatch, getState) => {
    const state = getState();
    const { callValidConfig = false } = props || {};
    let set;
    dispatch(requestBaseConfiguration(state.OMS.oms_params));
    let callToggleOption = state.Configuration.call_toggle_option
      ? state.Configuration.call_toggle_option
      : false;
    const isLegacy = state.Configuration.isLegacy || false;
    let response = Configurator.defaultOMSConfig({ Lexicon: state.OMS.lexicon }, {});
    dispatch(baseConfigurationChange(response));
    const { query_params: queryParams = {} } = state?.App || {};
    let { skuValidation = false } = queryParams || {};
    skuValidation = !!skuValidation;
    if (options.length) {
      const offMenuItems = _get(state, 'CustomGroups.OFF_MENU_ITEMS.options', []);
      options = options.filter(option => !offMenuItems.includes(option));
      set = options.filter(option => option !== '$ACL1'); // TWS-48247 - quick hack; needs proper fixing in Configurator class or lexicon
      response = callValidConfig
        ? getCompleteConfig(state, options)
        : Configurator.setOption(
            {
              Lexicon: state.OMS.lexicon,
              Configuration: { options: response.options, skuValidation },
            },
            {
              set,
              ignoreRules: [],
              userSelectedOptions: set,
              callToggleOption,
              isLegacy,
            }
          );
      console.log('Full healed configuration', response);
    }
    dispatch(completeConfigurationChange(response, set, skuValidation));
    if (!isMigratingToNewTrimEditDesignFlow(state)) {
      let targetTrimAfterConfirm = checkIfTrimIsValidAfterConfirmPrice(
        state.Configuration,
        state.OMS.lexicon
      );
      if (targetTrimAfterConfirm) {
        dispatch(setOption({ set: [targetTrimAfterConfirm] }));
      }
    }
  };
}

export function fetchConfiguration() {
  return (dispatch, getState) => {
    const state = getState();
    const cfgOptions = _get(getQueryParameters(), 'cfg', '');
    const referral = _get(getQueryParameters(), 'referral', null);
    let optionCodes = cfgOptions || _get(state, 'Configuration.option_codes');
    if (optionCodes && !isMigratingToNewTrimEditDesignFlow(state)) {
      //validate options
      try {
        if (_isString(optionCodes)) {
          const currentParams = _get(state, 'App.query_params', {});
          const newQueryParams = {
            redirect: 'no',
            cfg: '',
            ...(_isEmpty(referral) ? {} : { referral }),
          };
          changeQueryParams({ currentParams, historyState: {}, name: '', params: newQueryParams });
        }
        optionCodes = _isString(optionCodes)
          ? optionCodes.replace(/(COL[1-9]-)/g, '').split(',')
          : optionCodes;
        state.App.reduxLogger ? console.log('Trying to re-configure with:', optionCodes) : null;
        !!cfgOptions && dispatch(setIsCurrentDesignFromUrlQuery());
        const isEditDesign = _get(state, 'ApplicationFlow.canModifyOrder', false);
        return dispatch(getDefaultConfiguration(optionCodes, { callValidConfig: !!cfgOptions || isEditDesign }));
      } catch (e) {
        console.warn('Provided options not valid', e);
      }
    }
    dispatch(getDefaultConfiguration());
  };
}

export function setIsCurrentDesignFromUrlQuery() {
  return {
    type: SET_CURRENT_CONFIGURATION_SOURCE,
    source: CURRENT_CONFIGURATION_SOURCE_URL_PARAM,
  };
}
export function isMigratingToNewTrimEditDesignFlow(state) {
  const market = _get(state, 'OMS.lexicon.market');
  if (market !== 'CN') {
    return false;
  }
  const {
    OMS: { lexicon },
    ApplicationFlow: { canModifyOrder },
    Configuration: { option_codes = [] },
  } = state;
  const trimInfo = lexicon?.groups.filter(({ code }) => code === 'TRIM')?.[0];
  const currentTrimOptions = trimInfo?.options || [];
  const noTrimSelected =
    option_codes?.filter(optionCode => currentTrimOptions.indexOf(optionCode) !== -1).length === 0;
  return canModifyOrder && noTrimSelected;
}

export function setConfigurationRN(rn) {
  return {
    type: SET_CONFIGURATION_RN,
    rn,
  };
}

export const storeUserConfig = payload => {
  return {
    type: STORE_USER_CONFIG,
    payload,
  };
};

export const localStorageFlow = (action$, store$) =>
  action$.pipe(
    filter(action => {
      const state = store$.value;
      const vehicleId = _get(state, 'ReviewDetails.product.data.VIN', '');
      const inventoryDeliveryLocationsKey = `${vehicleId}-delivery-locations`;
      const version = _get(window, 'tesla.version', '');
      const isInventory = _get(state, 'ReviewDetails.product.isInventory');

      switch (action.type) {
        case PRICE_CHANGED:
          const { model } = _get(state, 'OMS.oms_params', {});
          const effective_date = _get(state, 'OMS.lexicon.effective_date');
          const lexicon_name = _get(state, 'OMS.lexicon.name');
          const user_selected_options = _get(state, `Configuration.user_selected_options`);
          const option_codes = _get(state, `Configuration.option_codes`);

          if (isInventory || _get(state, 'Configuration.rn') || isEarlyDesignSelected(state)) {
            return;
          }

          const config = {
            StoredConfiguration: {
              [model]: {
                user_selected_options,
                option_codes,
                effective_date,
                lexicon_name,
              },
            },
            Pricing: {
              financeType: _get(state, 'SummaryPanel.selected_tab'),
            },
          };

          Storage.set(state.App.localStorageKey, config, {
            expires: 'month',
            version,
          });
          break;
        case SUMMARY_PANEL_REGION_CHANGE:
          const summaryData = {
            SummaryPanel: {
              selected_tab: _get(state, 'SummaryPanel.selected_tab'),
              region_code: _get(state, 'SummaryPanel.region_code', ''),
              country_code: _get(state, 'OMS.oms_params.market', ''),
            },
          };
          Storage.set(LOCAL_STORAGE_KEY_SUMMARY, summaryData, {});
          break;
        case REGISTRATION_TYPE_CHANGED:
          const registrationData = {
            ReviewDetails: {
              registrantType: _get(state, 'ReviewDetails.RegistrationDetail.RegistrantType'),
              countryCode: _get(state, 'OMS.oms_params.market', ''),
            },
          };
          Storage.set(LOCAL_STORAGE_KEY_REGISTRATION, registrationData, {});
          break;
        case DELIVERY_DETAILS_CHANGED:
          const stateProvince = _get(state, 'ReviewDetails.DeliveryDetails.StateProvince');
          if (_get(state, 'SummaryPanel.showEmirateSelection') && stateProvince) {
            Storage.set(LOCAL_STORAGE_KEY_EMIRATE, stateProvince);
          }
          break;
        // Disabling inventory storage flow will re-enable when issue gets fixed
        // case INVENTORY_DELIVERY_LOCATIONS_CHANGED:
        //   if (action?.payload?.registrationZipCode && action?.payload?.selected) {
        //     Storage.set(inventoryDeliveryLocationsKey, action.payload, {
        //       expires: 'day',
        //       version,
        //     });
        //   } else if (action?.payload?.selected) {
        //     const {
        //       available,
        //       filtered,
        //       params,
        //       registrationError,
        //       registrationZipCode,
        //     } = _get(state, 'ReviewDetails.DeliveryLocations', {});

        //     const deliveryLocationData = {
        //       available,
        //       filtered,
        //       params,
        //       registrationError,
        //       registrationZipCode,
        //       selected: action?.payload?.selected,
        //     }
        //     Storage.set(inventoryDeliveryLocationsKey, deliveryLocationData, {
        //       expires: 'day',
        //       version,
        //     });
        //   } else {
        //     Storage.remove(inventoryDeliveryLocationsKey);
        //   }
        // break;
        // case INVENTORY_DELIVERY_LOCATION_INVALID:
        //   const invalidLocationData = {
        //     available: null,
        //     error: null,
        //     errorSalesMetro: true,
        //     isLoading: false,
        //     registrationZipCode: action?.zipcode,
        //   }
        //   Storage.set(inventoryDeliveryLocationsKey, invalidLocationData, {
        //     expires: 'day',
        //     version,
        //   });
        // break;
        // case DELIVERY_DETAILS_VALID_FLAG:
        //   if (isInventory && action?.flag) {
        //     // store inventory delivery details when valid flag set to true
        //     const inventoryDeliveryDetailsKey =  `${vehicleId}-delivery-details`;
        //     const inventoryDeliveryDetails = _get(state, 'ReviewDetails.DeliveryDetails', {}) || {};
        //     const {PostalCode, Latitude, Longitude} = inventoryDeliveryDetails;
        //     if (PostalCode && Latitude && Longitude) {
        //       Storage.set(inventoryDeliveryDetailsKey, inventoryDeliveryDetails, {
        //         expires: 'day',
        //         version,
        //       });
        //     } else {
        //       Storage.remove(inventoryDeliveryDetailsKey);
        //     }
        //   }
        // case POST_ORDER_START:
        //   if (isInventory) {
        //     // store inventory upgrades so pre-selected when same VIN
        //     const vehicleUpgrades = _get(state, 'ReviewDetails.vehicleUpgrades', []);
        //     const inventoryUpgradesKey = `${vehicleId}-selected-upgrades`;
        //     const selectedUpgrades = vehicleUpgrades?.reduce((upgradeList, opt) => {
        //       if (opt?.selected) {
        //         upgradeList.push(opt?.optionCode);
        //       }
        //       return upgradeList;
        //     }, []);

        //     if (vehicleUpgrades?.length && selectedUpgrades?.length) {
        //       Storage.set(inventoryUpgradesKey, {selectedUpgrades}, {
        //         expires: 'day',
        //         version,
        //       });
        //     } else {
        //       Storage.remove(inventoryUpgradesKey);
        //     }
        //   }
        // break;
      }
    }),
    map(() => ({ type: '' }))
  );

export const restorePreviousConfig = () => {
  return (dispatch, getState) => {
    const state = getState();
    const { previousUserConfig } = state.Configuration || {};
    if (previousUserConfig) {
      dispatch(setOption({ set: previousUserConfig }, { callValidConfig: true }));
      dispatch({ type: RESET_PREVIOUS_CONFIG })
    }
  }
}