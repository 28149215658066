import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import { formatCurrency, formatMonthlyPrice } from '@tesla/coin-common-components';
import {
  i18n,
  getLoadedRN,
  getLoadedVin,
  getTerritoryCodeList,
  getDeliveryAndRegistrationDisclaimer,
  formatAddress,
  getOrderAgreementUrl,
} from 'utils';
import {
  placeOrderFlow,
  placePublicOrderFlow,
  saveConfigFlow,
  cancelUpdateOrder,
  navigationSelectKey,
  setCCDetailsValidFlag,
  userHasApplePay,
  updateAccountDetails,
  updatePaymentDetails,
  externalLinkClicked,
  redirectToDeliveryFlow,
  selectTab,
  setPricingDetails,
  addAlert,
  checkSession,
  toggleFinancePanelDrivertype,
  setSummaryPanelRegion,
  setAccountRegistrationType,
  postPriceAcceptance,
  setTimeOfClickPlaceOrderButton,
} from 'actions';

import {
  MODAL_LARGE,
  PAYMENT_TYPE_CC,
  PAYMENT_TYPE_WT,
  PAYMENT_TYPE_POS,
  PAYMENT_TYPE_ALIPAY,
  PAYMENT_TYPE_WECHAT,
  TRIPLE_INVOICE,
  NAVIGATION_VIEW_OVERVIEW,
  PAYMENT_FIN_TABS,
  FINANCE_MODAL_TAB_OPTIONS,
  FinanceTypes,
} from 'dictionary';

import {
  getDepositAmount,
  getIncentivesTotal,
  getFuelYears,
  getFuelReward,
  getActiveFinanceTypes,
  getDestinationAndDocFee,
  getInterestRate,
  getFinanceType,
  getSelectedFinanceProduct,
  getLoanTerm,
  getLeaseTerm,
  getModelCode,
  getPriceAfterSavings,
  getLeaseMileage,
  getDownPayment,
  getDownPaymentPercentage,
  getPrice,
  hideAfterSavingsPrice,
  getSavingsAmount,
  showSavingsAmount,
  showSavingsAmountInFooter,
  hideFinanceModal,
  useModelYContent,
  getFinancialIncentives,
  getReservationAmount,
  isOrderProcessingFeeEnabled,
  getVATAmount,
  getVATPercent,
  getFinanceTabs,
  getFinanceProductData,
  getLoanDistance,
  getFinanceProductId,
  getShowSummaryFields,
  getShowLeaseSummaryDisclaimer,
  getTransportationFeeAmount,
  getMiniDisclaimer,
  getExtraPricingContextAmounts,
  getPriceAfterEVCredit,
  getFuelSavingsPerMonth,
  getTradeInType,
  getRegionalFeeRebate,
  getDisclaimer,
  isAccessoriesSelected,
  getShippingAddressText,
  getDeliveryRegionDisclaimer,
  referralConfigValidation,
  getFeeLineItems,
  getFeesDisclaimer,
  getTrimCode,
  getFinanceProductType,
  getPriceExcludingCoE,
  getMonthyPaymentWithCredit,
  getDeliveryCredit,
  getTaxCredit,
  getRegionalDisclaimer,
  hasRegionalBannerModalCopy,
  getSCTTax,
  getReferralCredit,
  getOrderDisclaimerCopySource,
  getFinanceProductToHide,
  getMonthlyPaymentAfterTaxCredit,
  isNotAtEsignStatus,
  getFirstMonthDisclaimer,
  getTradeInDisclaimers,
  getIsFalconDeliverySelectionEnabled,
  getVeteranCredit,
} from 'selectors';

import PaymentOverviewRepresentation from './representation';
import { ESIGN_STATUS_INIT, ESIGN_STATUS_SENT, ESIGN_STATUS_SIGNED } from '../../common/dictionary';
import { selectFinanceView } from '../../reducers';
import PaymentWrapper from '../../components/PaymentSummary/Sections/PaymentWrapper';

class PaymentOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderPlaced: false,
      ccDetailsValid: props.ccDetailsValid,
    };

    this.payXUIRef = createRef();

    this.validateAndPlaceOrder = this.validateAndPlaceOrder.bind(this);
    this.validateAndPlacePublicOrder = this.validateAndPlacePublicOrder.bind(this);
    this.cancelUpdateOrder = this.cancelUpdateOrder.bind(this);
    this.saveMarketingConfig = this.saveMarketingConfig.bind(this);
    this.validateAndSaveDesign = this.validateAndSaveDesign.bind(this);
    this.isInvoiceDetailsValid = this.isInvoiceDetailsValid.bind(this);
    this.isAccountDetailsValid = this.isAccountDetailsValid.bind(this);
  }

  componentDidMount() {
    const {
      serverErrors,
      addAlert: triggerAlert,
      doSessionStartOnMount,
      isSessionSet,
      dispatchCheckSession,
      isSessionCheckStarted,
    } = this.props;
    if (doSessionStartOnMount && !isSessionSet && !isSessionCheckStarted) {
      dispatchCheckSession();
    }
    const paymentErr = _get(serverErrors, 'PAYMENT_ERROR', '');
    if (paymentErr) {
      triggerAlert({ message: i18n('common.errors__genericOrderProcessing') });
    }
  }

  static getDerivedStateFromProps(props) {
    if (_get(props, 'alerts.length') || props.paymentError || !_isEmpty(props.validationErrors)) {
      return {
        ccDetailsValid: props.ccDetailsValid,
        orderPlaced: false,
      };
    }
    return null;
  }

  /**
   * We have this helper function because in EMEA we have CreditCard or Wire Transfer,
   * this needs checking.
   * */
  isPaymentDetailsValid = () => {
    const {
      ccDetailsValid,
      billingInfoValid,
      collectAllBillingInfo,
      hideAllBillingInfo,
      paymentType,
      paymentFormValid,
    } = this.props;

    if (paymentFormValid) {
      return true;
    }
    switch (paymentType) {
      case PAYMENT_TYPE_CC:
        return collectAllBillingInfo ? billingInfoValid && ccDetailsValid : ccDetailsValid;
      case PAYMENT_TYPE_POS:
      case PAYMENT_TYPE_ALIPAY:
      case PAYMENT_TYPE_WT:
      case PAYMENT_TYPE_WECHAT:
        return hideAllBillingInfo || billingInfoValid;
      default:
        return ccDetailsValid;
    }
  };

  onTypeChange = tabId => {
    const { triggerSelectTab } = this.props;
    triggerSelectTab(tabId);
  };

  isInvoiceDetailsValid = () => {
    const { invoiceType, showInvoiceOption, invoiceDetailsValid } = this.props;
    if (showInvoiceOption) {
      return !(invoiceType.type === TRIPLE_INVOICE && !invoiceDetailsValid);
    }
    return true;
  };

  isAccountDetailsValid = () => {
    const { legalConsentValid, accountDetailsValid } = this.props;
    return legalConsentValid && accountDetailsValid;
  };

  validateAndPlacePublicOrder() {
    const { payXUIRef } = this;

    if (payXUIRef?.current != null) {
      payXUIRef.current.startPayment();
      return;
    }

    const {
      isDeliveryDetailsValid,
      onPlacePublicOrder,
      validateCCDetails,
      dispatchSetCCDetailsValidFlag,
    } = this.props;
    const { ccDetailsValid } = this.state;

    if (validateCCDetails) {
      dispatchSetCCDetailsValidFlag(true);
    }

    if (this.isPaymentDetailsValid() && this.isAccountDetailsValid() && isDeliveryDetailsValid) {
      onPlacePublicOrder();
      this.setState({
        orderPlaced: true,
      });
    } else {
      this.setState({
        ccDetailsValid,
      });
    }
  }

  validateAndPlaceOrder() {
    const { payXUIRef } = this;

    if (payXUIRef.current != null) {
      payXUIRef.current.startPayment();
      return;
    }

    const { onPlaceOrder } = this.props;

    if (this.isPaymentDetailsValid()) {
      onPlaceOrder();
      this.setState({
        orderPlaced: true,
      });
    } else {
      this.setState({
        ccDetailsValid: false,
      });
    }
  }

  saveMarketingConfig() {
    const { modifyOrder, onSaveConfig } = this.props;
    if (modifyOrder) {
      onSaveConfig();
    }
  }

  cancelUpdateOrder() {
    const { onCancelUpdateOrder } = this.props;

    onCancelUpdateOrder();
  }

  validateAndSaveDesign() {
    const { accountDetailsValid, isDeliveryDetailsValid, onPlacePublicOrder } = this.props;

    if (accountDetailsValid && isDeliveryDetailsValid) {
      onPlacePublicOrder();
    }
  }

  render() {
    const { showPaymentOverview, showOnlyPayment } = this.props || {};
    const { ccDetailsValid, orderPlaced } = this.state;
    const props = {
      ...this.props,
      orderPlaced,
      ccDetailsValid,
      onTypeChange: this.onTypeChange,
      validateAndPlaceOrder: this.validateAndPlaceOrder,
      validateAndPlacePublicOrder: this.validateAndPlacePublicOrder,
      saveMarketingConfig: this.saveMarketingConfig,
      cancelUpdateOrder: this.cancelUpdateOrder,
      rnLoaded: getLoadedRN(),
      vinLoaded: getLoadedVin(),
      validateAndSaveDesign: this.validateAndSaveDesign,
      isPaymentDetailsValid: this.isPaymentDetailsValid,
      isInvoiceDetailsValid: this.isInvoiceDetailsValid,
      isAccountDetailsValid: this.isAccountDetailsValid,
      payXUIRef: this.payXUIRef,
    };

    if (!showPaymentOverview) {
      return null;
    }

    if (showOnlyPayment) {
      return <PaymentWrapper {...props} />
    }

    return <PaymentOverviewRepresentation {...props} />;
  }
}

const mapStateToProps = state => {
  const {
    App,
    FinancingOptions,
    Alert,
    ReviewDetails,
    ApplicationFlow,
    CustomGroups,
    Pricing,
    TradeIn,
    Payment,
  } = state;
  const {
    showProvincialIncentives = false,
    showEVCreditInput = [],
    evCredit,
    showFuelMonthlySavings,
    financeSelectionDislaimer,
    financeDisclaimerKeyPayment = '',
    coeBidAmount = 0,
    showCoEBidInput = false,
    showVehiclePriceWithoutSavings = false,
    showIRABusinessEligibility = false,
  } = FinancingOptions;

  const {
    isUnbuildable = false,
    surveyCompleted = false,
    canModifyOrder: modifyOrder = false,
    isSessionCheckStarted = false,
    isReservationToOrderFlow = false,
    verificationStatus = {},
  } = ApplicationFlow;

  const { 
    tradeInAmount, 
    vehicleDetails,
    finplatInputs
  } = TradeIn || {};

  const { 
    year: tradeInYear, 
    make: tradeInMake, 
    model: tradeInModel 
  } = vehicleDetails || {};

  const productId = getFinanceProductId(state);
  const { 
    remainingTradInCredit,
    tradeInDown,
  } = finplatInputs[productId] || {};

  const {
    hideIncentivesOrderPage,
    VehicleDetail = {},
    showFinanceDisclaimerOrderPage = false,
    product = {},
    showPricesDetailIconTrigger,
    showAfterSavingsPriceForTrims = false,
  } = ReviewDetails;
  const { data: vehicle = {} } = product || {};
  const discount = _get(ReviewDetails, 'product.data.Discount', 0) || _get(ReviewDetails, 'vehicleDesign.Discount', 0);
  const {
    showRefundPolicyDisclaimer,
    fleetSalesRegions = [],
    isDeliverySelectionEnabled,
    isPostOrderSwap,
    hidePostOrderSwapLocation = false,
    showSavingsAfterTaxCredit,
    isTradeInEnabled,
    hideMobilePaymentSummary = false,
    isLayoutMobile = false,
    isLayoutTablet = false,
    isCoinReloaded = false,
  } = App;
  const { alerts } = Alert;
  const validConfigForReferral = referralConfigValidation(state);
  const financeModalProps = {
    componentName: 'FinanceContainer',
    props: {
      form: FINANCE_MODAL_TAB_OPTIONS,
      title: '',
      genericWrapper: true,
      header: {
        display: true,
        logo: true,
        close: true,
      },
      size: MODAL_LARGE,
    },
  };

  const market = _get(state, 'OMS.oms_params.market');
  const incentivesTotal = getIncentivesTotal(state, {
    includeAllCurrentIncentives: true,
    includeSavings: true,
  });
  const incentivesTotalPurchasePrice = getIncentivesTotal(state, {
    includeAllCurrentIncentives: true,
    includeSavings: true,
    incentivesInPurchasePrice: true,
    absoluteValue: true,
    doFormatCurrency: false,
  });
  const formattedPrices = _get(state, 'SummaryPanel.formattedPrices', {});
  const fuelYears = getFuelYears(state);
  const destinationAndDocFee = getDestinationAndDocFee(state);
  const downPayment = getDownPayment(state, {
    context: 'payment',
  });
  const downPaymentPercentage = getDownPaymentPercentage(state);
  const interestRate = getInterestRate(state);
  const leaseTerm = getLeaseTerm(state);
  const leaseMileage = getLeaseMileage(state);
  const loanTerm = getLoanTerm(state);
  const selectedFinanceType = getFinanceType(state);
  const activeFinanceTypes = getActiveFinanceTypes(state);
  const beforeSavingsPrice = getPrice(state, selectedFinanceType);
  const afterSavingsPrice = getPriceAfterSavings(state, selectedFinanceType);
  const savingsAmount = getSavingsAmount(state);
  const useModelYContentState = useModelYContent(state);
  const modelCode = _get(state, 'OMS.oms_params.model');
  const trimCode = getTrimCode(state);

  const shortPrice = {
    beforeSavingsPrice: getPrice(state, selectedFinanceType),
    afterSavingsPrice: getPriceAfterSavings(state, selectedFinanceType),
  };

  // we want to show totals in cash price only
  const showAmountIncludingFeesSummary = _get(
    state,
    'FinancingOptions.showAmountIncludingFeesSummary',
    false
  );
  const addOnlyVATwithSubtotal = _get(state, 'ReviewDetails.addOnlyVATwithSubtotal', false);
  const cashPrice = {
    beforeSavingsPrice: getPrice(state, 'cash', {
      showAmountIncludingFees: showAmountIncludingFeesSummary,
      formatCurrencyOpts: {
        precision: 0, // This will override the state.SummaryPanel.totalsRounding.cash setting.
      },
      addOnlyVATwithSubtotal,
    }),
    afterSavingsPrice: getPriceAfterSavings(state, selectedFinanceType, {
      showAmountIncludingFees: showAmountIncludingFeesSummary || (isCoinReloaded && showAfterSavingsPriceForTrims),
      showTotalPriceAfterSavings: true,
      formatCurrencyOpts: {
        precision: 0, // This will override the state.SummaryPanel.totalsRounding.cash setting.
      },
      addOnlyVATwithSubtotal,
    }),
  };
  const cashPriceExVAT = {
    beforeSavingsPrice: getPrice(state, 'cash', {
      showAmountIncludingFees: showAmountIncludingFeesSummary,
      subtractVAT: true,
    }),
    afterSavingsPrice: getPriceAfterSavings(state, selectedFinanceType, {
      showAmountIncludingFees: showAmountIncludingFeesSummary,
      subtractVAT: true,
      showTotalPriceAfterSavings: true,
    }),
  };

  const {
    grossPriceAfterTaxCredit = 0,
    vehiclePriceAfterTaxCredit = 0,
    vehiclePriceWithDeliveryFee = 0, 
    vehiclePriceWithoutDiscounts = 0 
  } = Pricing?.cash || {};

  const reversePricingOrder = _get(state, 'FinancingOptions.reversePricingOrder', false);
  const hideTaxesAndFeesExcluded = _get(state, 'FinancingOptions.hideTaxesAndFeesExcluded', false);
  const showAmountIncludingFees = _get(state, 'FinancingOptions.showAmountIncludingFees', false);
  const hideAfterSavingsModal = _get(state, 'FinancingOptions.hideAfterSavingsModal', false);
  const showChargerDisclaimer = _get(state, 'FinancingOptions.showChargerDisclaimer', false);

  const showdestinationAndDocFee = !!_get(
    state,
    'SummaryPanel.formattedPrices.destinationAndDocFee'
  );

  const financeTabs = getFinanceTabs(state);
  const modelOption = getModelCode(state);
  const financeProductData = getFinanceProductData(state);

  const leaseRateMatrix = _get(financeProductData, 'interestRateMatrix', []);
  const loanRateMatrix = _get(financeProductData, 'interestRateMatrix', []);
  const trimsWithBanner = _get(financeProductData, 'uiSettings.forms.trimsWithBanner', []);
  const finplatProductName = _get(financeProductData, 'parameters.name', '');

  const financeTypeLabel = i18n(selectedFinanceType, 'common.ui');
  const isInventory = !!_get(state, 'ReviewDetails.product');
  const isUsedInventory = _get(state, 'ReviewDetails.product.isUsedInventory');
  const isInventoryPriceAdjustmentsEnabled = _get(state, 'App.isInventoryPriceAdjustmentsEnabled', false);
  const firstRegistrationDate = _get(state, 'ReviewDetails.product.data.FirstRegistrationDate');
  const showFirstRegistrationDatePaymentPage = _get(
    state,
    'ReviewDetails.showFirstRegistrationDatePaymentPage'
  );
  const depositAmount = getDepositAmount(state);
  const transportFeeAmount = getTransportationFeeAmount(state);
  const showReturnPolicy = _get(state, 'ReviewDetails.showReturnPolicy', false);
  const navsection = _get(state, 'Navigation.section', null);
  const navigationSelected = !!(navsection === 'payment' && showReturnPolicy);
  const showRegionalTaxTypes = _get(state, 'FinancingOptions.showRegionalTaxTypes', null);
  const showDistinctTaxTotal = _get(state, 'FinancingOptions.showDistinctTaxTotal', false);
  const showOtherFeesinSummary = _get(state, 'FinancingOptions.showOtherFeesinSummary', false);
  const balloonPayment = _get(state, 'Pricing.calculatorResult.data.result.loan.balloonPayment');
  const totalAmtPaid = _get(
    state,
    'Pricing.calculatorResult.data.result.loan.regional.totalAmtPaid',
    null
  );
  const vehiclePriceInclFees = Math.abs(vehiclePriceWithDeliveryFee - coeBidAmount);
  const gstSalePrice = _get(
    state,
    'Pricing.calculatorResult.data.apiResults.fees.current.regional.data[0].variables.gst_on_sale',
    null
  );
  const onRoadfeesTotal = _get(
    state,
    'Pricing.calculatorResult.data.apiResults.fees.current.regional.total',
    null
  );
  const incentives = state.Financial.incentives.current;
  const tcc = state.Financial.tcc.current;
  const showOrderPageSummaryOtherFees = _get(
    state,
    'FinancingOptions.showOrderPageSummaryOtherFees',
    false
  );
  const cashTabIncentives = getFinancialIncentives(state);
  const isReservation = _get(state, 'ReviewDetails.product.isReservation', false);

  const showPurchasePrice = _get(state, 'ReviewDetails.showPurchasePrice', true);
  const { enableCyberpunk = false, isCollapsedFlow = false } = state.App;
  const totalSavings = _get(state, 'Pricing.cash.totalSavingsAmount', 0);

  const isPickupOnlyEnabled = _get(state, 'App.isPickupOnlyEnabled', false);
  const locale = _get(state, 'App.locale');
  const financeProductId = getFinanceProductId(state);
  const monthlyPaymentDisclaimer =
    locale.includes('HK') &&
    financeProductId === 'unsecured_loan' &&
    i18n('FinancingOptions.loan_unsecuredLoanMonthlyPaymentDisclaimer', null, null, {
      returnNullWhenEmpty: true,
    });
  const depositAmountPerCurrency = _get(state, 'Payment.depositAmountPerCurrency', {});
  const secondaryCurrency = _get(depositAmountPerCurrency, 'currency_secondary');
  const paymentAmtSecondary = secondaryCurrency
    ? _get(depositAmountPerCurrency, secondaryCurrency, {})
    : null;
  const initialPaymentAltCurrency = paymentAmtSecondary
    ? formatCurrency(paymentAmtSecondary.initial, { currency: secondaryCurrency })
    : 0;
  const secondPaymentAltCurrency = paymentAmtSecondary
    ? formatCurrency(paymentAmtSecondary.second, { currency: secondaryCurrency })
    : 0;

  let postOrderSwapLocation = '';
  if (isPostOrderSwap) {
    const {
      AddressLine1: address1,
      AddressLine2: address2,
      City: city,
      ProvinceState: provinceState,
    } = _get(ReviewDetails, 'product.data.PickupLocation.VrlAddress', {});
    postOrderSwapLocation = formatAddress({ address1, address2, city, provinceState });
  }
  const { apiResultsPerTrim, apiResults = {} } = Pricing?.calculatorResult?.data || {};
  const intlCurrency = _get(state, 'OMS.lexicon.metadata.pricing.context_mapping.configurator');
  const monthlyPaymentValue = _get(state, 'Pricing.lease.monthlyPayment');
  const defaultCurrency = _get(state, 'App.pricingContext');
  const conversionRate = _get(
    state,
    `OMS.lexicon.metadata.copy.conversion_rate.${intlCurrency}.${defaultCurrency}`,
    0
  );

  const getAnnualLeaseInterestRate = state => {
    return _get(
      _find(
        leaseRateMatrix,
        leaseItem => leaseItem.leaseTerm && leaseItem.leaseTerm === getLeaseTerm(state)
      ),
      'annualInterestRate',
      0
    );
  };

  const getAnnualLoanInterestRate = state => {
    return _get(
      _find(
        loanRateMatrix,
        loanItem => loanItem.loanTerm && loanItem.loanTerm === getLoanTerm(state)
      ),
      'annualInterestRate',
      0
    );
  };

  const hasAccessories = isAccessoriesSelected(state);

  const isUsedFalconTaxEligible =
    isUsedInventory && _get(ReviewDetails, 'product.data.FederalIncentives.IsTaxIncentiveEligible');
  const priceAfterTaxCredit = isUsedFalconTaxEligible
    ? vehiclePriceAfterTaxCredit + transportFeeAmount
    : grossPriceAfterTaxCredit;
  const { showPaymentOverview = true } = Payment || {};

  return {
    selectedTab: getSelectedFinanceProduct(state),
    market,
    totalSavings,
    isCollapsedFlow,
    enableCyberpunk,
    isPostOrderSwap,
    hidePostOrderSwapLocation,
    postOrderSwapLocation,
    showEditDesignLink: !isInventory && !enableCyberpunk,
    showOptionsPricing: !isInventory && !enableCyberpunk,
    isSaveDesignLiteEnabled: _get(state, 'App.isSaveDesignLiteEnabled'),
    isSaveDesignEnabled: _get(state, 'ReviewDetails.isSaveDesignEnabled', false),
    isDownloadPDFEnabled: _get(window.tesla, 'download_pdf_feature_enabled', false),
    alerts: state.Alert.alerts,
    paymentError: _get(state, 'Payment.serverErrors.PAYMENT_FAILED'),
    validationErrors: _get(state, 'Payment.validationErrors', {}),
    ccDetailsValid: state.Payment.ccDetailsValid,
    billingInfoValid: _get(state, 'Payment.billingInfoValid', false),
    accountDetailsValid: _get(state, 'ReviewDetails.accountDetailsValid', false),
    isDeliveryDetailsValid: isPickupOnlyEnabled
      ? true
      : _get(state, 'ReviewDetails.isDeliveryDetailsValid', false),
    isDeliveryDetailsRequired: isPickupOnlyEnabled
      ? false
      : _get(state, 'ReviewDetails.isDeliveryDetailsRequired', false),
    collectAllBillingInfo: state.Payment.collectAllBillingInfo,
    baseConfiguration: state.ReviewDetails.base_configuration_options,
    options: state.ReviewDetails.configuration_options,
    model: _get(state, `OMS.lexicon.options.${modelOption}`, {}),
    modelCode,
    depositAmount: depositAmount + transportFeeAmount,
    depositAmountBase: depositAmount,
    destinationAndDocFee,
    discounts: _get(state, 'Pricing.discounts', []),
    incentives,
    incentivesTotal,
    tcc,
    surveyCompleted,
    vehiclePrice: formattedPrices.vehiclePrice,
    monthlyPayment: formattedPrices.financeMonthlyPayment,
    leaseAmountDueAtSigning: formattedPrices.leaseAmountDueAtSigning,
    financedAmount: formattedPrices.financedAmountPayment,
    amountAtSigning: formattedPrices.financeAmountDueAtSigning,
    loanTerm,
    leaseTerm,
    interestRate,
    leaseMileage,
    isProfileExists: _get(state, 'Payment.CreditCardDetail.IsProfileExists', false),
    showInvoiceOption: _get(state, 'Payment.showInvoiceOption', false),
    invoiceType: _get(state, 'Payment.InvoiceType', {}),
    InvoiceDetailsValid: _get(state, 'Payment.InvoiceDetailsValid', false),
    isLayoutMobile: state.App.isLayoutMobileAdjusted,
    isEmployee: _get(state, 'App.isTeslaEmployee', false),
    sibling: _get(state, 'App.sibling'),
    userHasApplePay: state.ApplePay.userHasApplePay,
    ap_enabled: state.ApplePay.enabled,
    apMerchantId: state.ApplePay.apMerchantId,
    locale,
    formOpen: state.Payment.formOpen,
    modifyOrder,
    isMobile: _get(state, 'App.isLayoutMobileAdjusted'),
    isTablet: _get(state, 'App.isLayoutTablet'),
    showRefundPolicy: _get(state, 'App.showRefundPolicy'),
    validConfigForReferral,
    referralData: _get(state, 'ApplicationFlow.referral'),
    configuration: _get(state, 'Configuration.option_codes', []),
    fuelYears,
    currentNavigationTab: navsection,
    loanAfterSaving: formattedPrices.financeNetMonthlyPayment,
    leaseAfterSaving: formattedPrices.leaseNetMonthlyPayment,
    connect: state.ApplicationFlow.connect,
    postConnectUrl: state.ApplicationFlow.postConnectUrl,
    asset: _get(state, 'ReviewDetails.asset', {}),
    hidePaymentModalTrigger: hideFinanceModal(state),
    financeModalProps,
    selectedFinanceType,
    selectedFinanceProduct: getSelectedFinanceProduct(state),
    activeFinanceTypes,
    financeTabs,
    afterSavingsPrice,
    beforeSavingsPrice,
    shortPrice,
    cashPrice,
    cashPriceExVAT,
    showAmountIncludingFees,
    downPayment,
    downPaymentPercentage,
    showDownPaymentInFooter: _get(state, 'FinancingOptions.showDownPaymentInFooter', false),
    ecoBonus: Math.abs(
      _get(
        state,
        'Pricing.calculatorResult.data.apiResults.incentives.available.government.total',
        0
      )
    ),
    leaseData: _get(state, 'Pricing.lease', {}),
    loanData: _get(state, 'Pricing.finance', {}),
    showVAT: _get(state, 'ReviewDetails.showVAT', false),
    vatAmount: getVATAmount(state),
    vatPercent: getVATPercent(state),
    regionalTax: _get(state, 'Pricing.calculatorResult.data.variables.fees.regional.total', 0),
    showRegionalTax: _get(state, 'FinancingOptions.showRegionalTax', false),
    registrationTax: _get(
      state,
      'Pricing.calculatorResult.data.variables.fees.registration.total',
      0
    ),
    specialTax: _get(state, 'Pricing.calculatorResult.data.variables.fees.special_tax.total', 0),
    governmentGrant: _get(
      state,
      'Pricing.calculatorResult.data.variables.incentives.government_grant.total',
      0
    ),
    governmentIncentivesTotal: _get(
      state,
      'Pricing.calculatorResult.data.apiResults.incentives.current.government.total',
      0
    ),
    governmentIncentivesPeriod: _get(
      state,
      'Pricing.calculatorResult.data.apiResults.incentives.current.government.data[0].period',
      ''
    ),
    ecoTaxReduction: _get(
      state,
      'Pricing.calculatorResult.data.apiResults.incentives.current.ecoTaxReduction.total',
      0
    ),
    incentiveTotalInPurchasePrice: _get(
      state,
      'Pricing.calculatorResult.data.apiResults.incentives.total.includedInPurchasePrice',
      0
    ),
    hidePurchasePriceIncentive: _get(
      state,
      'Pricing.calculatorResult.data.apiResults.incentives.hidePurchasePriceIncentive',
      false
    ),
    reversePricingOrder,
    showVehiclePricePlusFees: state?.ReviewDetails?.showVehiclePricePlusFees || false,
    hideAfterSavingsModal,
    showPrpLink: _get(state, 'ReviewDetails.showPrpLink'),
    hideTaxesAndFeesExcluded,
    savingsAmount,
    showSavingsAmount: showSavingsAmount(state),
    showSavingsAmountInFooter: showSavingsAmountInFooter(state),
    paymentTypes: _get(state, 'Payment.PaymentTypes', []),
    paymentTabsType: _get(state, 'Payment.paymentTabsType', 'tabs'),
    paymentType: _get(state, 'Payment.PaymentDetail.PaymentType', ''),
    paymentFormValid: _get(state, 'Payment.paymentFormValid', false),
    invoiceDetailsValid: _get(state, 'Payment.InvoiceDetailsValid', false),
    showOtherLoanTerms: _get(state, 'Forms.showOtherLoanTerms', false),
    loanDistance: getLoanDistance(state),
    effectiveRate: _get(state, 'Pricing.finance.effectiveRate'),
    residualAmount: _get(state, 'Pricing.finance.residualAmount'),
    extraIncentives: _get(state, 'ReviewDetails.extraIncentives', []),
    showLeaseSummaryFields: getShowSummaryFields(state, FinanceTypes.LEASE),
    showLeaseSummaryDisclaimer: getShowLeaseSummaryDisclaimer(state),
    recyclingFee: _get(state, 'SummaryPanel.formattedPrices.recyclingFee', 0),
    tireFee: _get(state, 'SummaryPanel.formattedPrices.tireFee', 0),
    winterTireFee: _get(state, 'SummaryPanel.formattedPrices.winterTireFee', 0),
    showdestinationAndDocFee,
    validateCCDetails: _get(state, 'ReviewDetails.validateCCDetails', true),
    hideDestinationDocFee: _get(state, 'ReviewDetails.hideDestinationDocFee', false),
    hideAllBillingInfo: _get(state, 'Payment.hideAllBillingInfo', false),
    showLoanSummaryFields: getShowSummaryFields(state, FinanceTypes.LOAN),
    showLoanSummaryDisclaimer: _get(state, 'ReviewDetails.showLoanSummaryDisclaimer', false),
    showCustomizeLabel: _get(state, 'ReviewDetails.showCustomizeLabel', false),
    showRightOrder: _get(state, 'Forms.showRightOrder', false),
    showContactUs: _get(state, 'ReviewDetails.showContactUs', false),
    leaseRate: _get(
      _find(leaseRateMatrix, leaseItem => leaseItem.leaseTerm && leaseItem.leaseTerm === leaseTerm),
      'value',
      0
    ),
    loanRate: _get(
      _find(loanRateMatrix, loanItem => loanItem.loanTerm && loanItem.loanTerm === loanTerm),
      'value',
      0
    ),
    debitRate: _get(state, 'Pricing.finance.regional.debitRate', null),
    showDisclaimerLink: _get(state, 'ReviewDetails.showDisclaimerLink', false),
    hideApplyForFinancing: _get(state, 'ReviewDetails.hideApplyForFinancing', false),
    showReturnPolicy,
    showBeforeSavingsPriceFinancingLabel: _get(
      state,
      'ReviewDetails.showBeforeSavingsPriceFinancingLabel',
      true
    ),
    showPaymentModalTriggerBelowPrice: _get(
      state,
      'ReviewDetails.showPaymentModalTriggerBelowPrice',
      true
    ),
    showSavingsAmountTrigger: _get(state, 'ReviewDetails.showSavingsAmountTrigger', true),
    showIRACopy: !!getTaxCredit(state),

    // startFee is now Norway specific, ideally, we refactor all this hardcoded Norway fee types
    // into the more generic ones that we use for other countries.
    startFee: _get(state.Financial, 'fees.fees.dnb_loan_fee[0].amount', 0),
    financeTypeLabel,
    navigationSelected,
    isInventory,
    isUsedInventory,
    isInventoryPriceAdjustmentsEnabled,
    discount,
    isFalconDeliverySelectionEnabled: getIsFalconDeliverySelectionEnabled(state),
    firstRegistrationDate,
    showFirstRegistrationDatePaymentPage,
    isPreSale: _get(state, 'ReviewDetails.isPreSale.source', false),
    showSecondDepositDisclaimer: _get(
      state,
      'ReviewDetails.showSecondDepositDisclaimer.source',
      false
    ),
    useModelYContent: useModelYContentState,
    showRegistrationTax: _get(state, 'FinancingOptions.showRegistrationTax', true),
    hideBaseField: _get(state, 'ReviewDetails.hideBaseField', []),
    showRegionalTaxTypes,
    regionalTaxValues: showRegionalTaxTypes
      ? _get(state, 'Financial.fees.current.regional[0]', null)
      : null,
    showDriveAwayPrice: _get(state, 'ReviewDetails.showDriveAwayPrice', false),
    showFormattedMonthlyPayment: _get(state, 'FinancingOptions.showFormattedMonthlyPayment', false),
    loanApr: interestRate,
    showSavingsDisclaimer: _get(state, 'ReviewDetails.showSavingsDisclaimer', true),
    deliveryFee: _get(state, 'Pricing.calculatorResult.data.variables.fees.delivery_fee.total', 0),
    OMVICFee: _get(state, 'Pricing.calculatorResult.data.variables.fees.OMVIC_fee.total', 0),
    frtFee: _get(state, 'SummaryPanel.formattedPrices.firstRegistrationTax'),
    exlFrtPrice: _get(state, 'SummaryPanel.formattedPrices.payment'),
    grossPrice: _get(state, 'Pricing.grossPrice', false),
    netPrice: _get(state, 'Pricing.netPrice', false),
    balloonPayment,
    totalAmtPaid,
    showOrderPlacementDisclaimerCash: _get(
      state,
      'ReviewDetails.showOrderPlacementDisclaimerCash',
      false
    ),
    showDistinctTaxTotal,
    showOtherFeesinSummary,
    vehiclePriceInclFees,
    gstSalePrice,
    onRoadfeesTotal,
    refundPolicySource: _get(state, 'ReviewDetails.refundPolicySource', {}),
    showRefundPolicyDisclaimer,
    showRegistrationTypeSelector: _get(state, 'ReviewDetails.showRegistrationTypeSelector', false),
    showFeesDisclaimer: _get(state, 'ReviewDetails.showFeesDisclaimer', false),
    showPricesDetail: _get(state, 'ReviewDetails.showPricesDetail'),
    showChargerDisclaimer,
    loanType: _get(state, 'SummaryPanel.loanType', null),
    summaryRegionalFees: _get(state, 'ReviewDetails.summaryRegionalFees', false),
    hideEstimatedDeliveryOrderPage: _get(state, 'App.hideEstimatedDeliveryOrderPage', false),
    showIncentivesSectionTitle: _get(state, 'ReviewDetails.showIncentivesSectionTitle', false),
    includeTeslaInsurance: _get(state, 'FinancingOptions.includeTeslaInsurance', false),
    insuranceAmount: _get(state, 'Pricing.finance.insuranceAmt', 0),
    displayPaymentDisclaimer: _get(state, 'Payment.displayPaymentDisclaimer', false),
    showLoanFieldsOrderPage: _get(state, 'ReviewDetails.showLoanFieldsOrderPageLineItem', []),
    showModalonFinanceSelector: _get(state, 'FinancingOptions.showModalonFinanceSelector', false),
    showOrderPageSummaryOtherFees,
    showTaxCalcDisclaimer: _get(state, 'FinancingOptions.showTaxCalcDisclaimer', false),
    showOrderFee: _get(state, 'FinancingOptions.showOrderFee', false),
    showSavingsDisclaimeronCashTab: _get(
      state,
      'ReviewDetails.showSavingsDisclaimeronCashTab',
      false
    ),
    cashTabIncentives,
    isReservation,
    isReservedPreOrder: _get(state, 'ApplicationFlow.isReservedPreOrder', false),
    isBookedPreOrder: _get(state, 'ApplicationFlow.isBookedPreOrder', false),
    reservationAmt: isReservation ? getReservationAmount(state) : null,
    VehicleDetail,
    showInventoryOrderFirstPaymentPolicy: _get(
      state,
      'ReviewDetails.showInventoryOrderFirstPaymentPolicy',
      false
    ),
    errorState: _get(state, 'ReviewDetails.errorState'),
    serverErrors: _get(state, 'Payment.serverErrors'),
    showOrderFeeDisclaimer: isOrderProcessingFeeEnabled(state),
    showDOCandOMVICfees: _get(state, 'ReviewDetails.showDOCandOMVICfees', true),
    showVATBreakdown: _get(state, 'ReviewDetails.showVATBreakdown', false),
    ACFee: _get(state, 'Pricing.calculatorResult.data.variables.fees.acquisition_fee.total', 0),
    registrationType: _get(state, 'ReviewDetails.AccountDetail.AccountType', null),
    showNonRefundPolicy: _get(state, 'ReviewDetails.nonRefundPolicy', false),
    purchaseTax: _get(state, 'SummaryPanel.formattedPrices.purchaseTax'),
    purchaseTaxAmount: _get(state, 'Financial.fees.current.purchase_tax[0].purchaseTaxAmount', 0),
    showOrderRefundDisclaimer: _get(state, 'ReviewDetails.showOrderRefundDisclaimer', true),
    isFixedPickupLocation: _get(state, 'ReviewDetails.isFixedPickupLocation', false),
    isPaymentFormOpen: _get(state, 'Payment.isPaymentFormOpen', true),
    isPaymentInteractionRequired: _get(state, 'Payment.isPaymentInteractionRequired', false),
    isSessionSet: _get(state, 'ApplicationFlow.isSessionSet', false),
    doSessionStartOnMount: _get(state, 'ReviewDetails.doSessionStartOnMount', true),
    loanEffectiveRate: _get(state, 'Pricing.finance.effectiveRate'),
    vehiclePriceWithoutDiscounts,
    showTaxAndFeeDisclaimerForUsed: _get(
      state,
      'ReviewDetails.showTaxAndFeeDisclaimerForUsed',
      false
    ),
    isDomestic: _get(state, 'ReviewDetails.isDomestic.source', false),
    showGrossPriceTotal: _get(state, 'ReviewDetails.showGrossPriceTotal', false),
    isOldVersionOrderAgreement: _get(state, 'ReviewDetails.isOldVersionOrderAgreement', false),
    oldOrderAgreementVersion: _get(state, 'ReviewDetails.oldOrderAgreementVersion', ''),
    reservationPriceLabel: _get(state, 'ReviewDetails.purchasePriceLabel', ''),
    showNonRefundableDisclaimer: _get(state, 'ReviewDetails.nonRefundPolicyDisclaimer', false),
    showPaymentDisclaimers: _get(state, 'ReviewDetails.showPaymentDisclaimers', true),
    showPurchasePrice,
    hideAfterSavingsPrice: showPurchasePrice ? hideAfterSavingsPrice(state) : true,
    showTraderLicense: _get(state, 'ReviewDetails.showTraderLicense', false),
    interstateTransitFee: _get(state, 'Financial.fees.current.interstate_transit_fee[0].amount', 0),
    monthlyLeasePayment: _get(state, 'Pricing.lease.monthlyPayment'),
    financeProductId,
    USLegalDisclaimer: _get(state, 'ReviewDetails.USLegalDisclaimer'),
    deliveryPostalCode: _get(state, 'ReviewDetails.DeliveryDetails.PostalCode', ''),
    transportationFee: transportFeeAmount,
    isTransportFeeEnabled: _get(state, 'ReviewDetails.isTransportFeeEnabled'),
    isTransportFeeCollectionEnabled: _get(state, 'App.isTransportFeeCollectionEnabled', false),
    isEstimatedPrice: _get(state, 'FinancingOptions.isEstimatedPrice', false),
    orderPageDisclaimerCopySrc: getOrderDisclaimerCopySource(state),
    showTotalPaymentFooter: _get(state, 'FinancingOptions.showTotalPaymentFooter', false),
    showMonthlyPaymentQuote: _get(state, 'FinancingOptions.showMonthlyPaymentQuote', false),
    disclaimerIntlDateFormat: _get(state.Forms, 'disclaimerIntlDateFormat', { dateStyle: 'long' }),
    orderFeeDislaimer: _get(
      state,
      'CustomGroups.TRIM.order_disclaimer',
      _get(state, 'CustomGroups.TRIM.currentSelected[0].order_disclaimer')
    ),
    displayAsset: _get(state, 'ReviewDetails.displayAsset'),
    sellerDiscount: _get(state, 'Financial.incentives.current.sellerDiscount[0].preVATAmount', 0),
    stateContribution: _get(state, 'Financial.incentives.current.stateContribution[0].amount', 0),
    showVatDisclaimer: _get(state, 'ReviewDetails.showVatDisclaimer', false),
    showVehicleSubtotal: _get(state, 'ReviewDetails.showVehicleSubtotal', false),
    showGovernmentSubsidy: _get(state, 'ReviewDetails.showGovernmentSubsidy', false),
    fuelReward: getFuelReward(state),
    disclaimerSectionAboveOrderButton: _get(
      state,
      'FinancingOptions.disclaimerSectionAboveOrderButton',
      null
    ),
    showRegistrationAboveSummary: _get(
      state,
      'FinancingOptions.showRegistrationAboveSummary',
      false
    ),
    isNativePaymentEnabled: _get(state, 'App.isNativePaymentEnabled', false),
    isDm: _get(state, 'App.isDm', false),
    displayOtherEUDisclaimer: _get(state, 'Payment.displayOtherEUDisclaimer', false),
    showTerritoryList: _get(state, 'FinancingOptions.showTerritoryList')
      ? getTerritoryCodeList(_get(state, 'App.countryCode'), '')
      : null,
    orderAgreementUrl: getOrderAgreementUrl(state),
    showLegalConsentAfterPayX: _get(state, 'ReviewDetails.showLegalConsentAfterPayX', []),
    legalConsentValid: state.ReviewDetails.legalConsentValid,
    feesTotal: _get(state, 'Pricing.calculatorResult.data.apiResults.fees.total.once', 0),
    incentivesTotalPurchasePrice,
    firstMonthDisclaimer: getFirstMonthDisclaimer(state),
    financeMiniDisclaimer: getMiniDisclaimer(state),
    trimCode,
    showProvincialIncentives,
    extraPricingContextAmounts: getExtraPricingContextAmounts(state) || [],
    isPickupOnlyEnabled,
    hasAlerts: !!alerts.length,
    dutyAndSurcharge: _get(state, 'Financial.fees.current.duty_and_surcharge[0].percent', 0),
    monthlyPaymentDisclaimer,
    showEVCredit: showEVCreditInput.includes(selectedFinanceType),
    governmentIncentive: _get(
      state,
      'Pricing.calculatorResult.data.apiResults.incentives.current.government.total',
      0
    ),
    priceAfterEVCredit: getPriceAfterEVCredit(state),
    evCredit,
    fuelSavingsPerMonth: getFuelSavingsPerMonth(state),
    showFuelMonthlySavings,
    hideIncentivesOrderPage,
    showAvailableInState: _get(state, 'ReviewDetails.showAvailableInState', false),
    tradeInType: getTradeInType(state),
    showElectronicPayment: _get(state, 'ReviewDetails.showElectronicPayment', false),
    isUnbuildable,
    deliveryAndRegistrationDisclaimer: getDeliveryAndRegistrationDisclaimer({ state, vehicle }),
    deliveryRegionDisclaimer: getDeliveryRegionDisclaimer(state),
    fleetSalesRegions,
    financeSelectionDislaimer,
    regionalFeeRebate: getRegionalFeeRebate(state),
    showEvSubsidyDisclaimer: _get(state, 'ReviewDetails.showEvSubsidyDisclaimer', false),
    showVehicleConfigurationPrice: _get(
      state,
      'ReviewDetails.showVehicleConfigurationPrice',
      false
    ),
    showFeesSummary: _get(state, 'FinancingOptions.showFeesSummary', false),
    initialPaymentAltCurrency,
    secondPaymentAltCurrency,
    isSinglePage: _get(state, 'ReviewDetails.isSinglePage', false),
    isEditDesignPreserved: _get(state, 'App.isEditDesignPreserved', false),
    isQtyInfoValid: VehicleDetail?.valid,
    financeDisclaimer: getDisclaimer(state, { disclaimerKey: financeDisclaimerKeyPayment }),
    showFinanceDisclaimerOrderPage,
    showLeaseDisclaimer: _get(state, 'ReviewDetails.showLeaseDisclaimer', false),
    isAccessoriesValid:
      state.Accessories?.googleAddressNotFound || state.Accessories?.partialGoogleAddress
        ? state.Accessories?.customAddressValidationComplete
        : (hasAccessories && getShippingAddressText(state)) ||
          !hasAccessories,
    isDeliverySelectionEnabled,
    postOrderDeposit: CustomGroups?.TRIM?.post_order_deposit || [],
    priceData: {
      ...apiResults,
      apiResultsPerTrim,
    },
    showFinancialTabsEnabled: _get(state, 'App.showFinancialTabsEnabled', true),
    feeLineItems: getFeeLineItems(state),
    feesDisclaimer: getFeesDisclaimer(state),
    showFinancingBanner: trimsWithBanner.includes(trimCode),
    displayDoubleCurrency: _get(state, 'App.displayDoubleCurrency', false),
    leaseMonthlyPaymentInIntlCurrency: formatMonthlyPrice(monthlyPaymentValue / conversionRate, {
      currency: intlCurrency,
      useDynamicRounding: true,
    }),
    vehicleCondition: _get(state, 'ReviewDetails.product.condition'),
    productType: getFinanceProductType(state),
    showCoEBid: showCoEBidInput,
    coeBidAmount,
    showPriceWithCOE: _get(state, 'ReviewDetails.showPriceWithCOE', false),
    priceExcludingCoE: getPriceExcludingCoE(state),
    monthlyPaymentWithCredit: getMonthyPaymentWithCredit(state),
    deliveryCredit: getDeliveryCredit(state),
    finplatProductName,
    showVehiclePriceWithoutSavings,
    regionalDisclaimer: getRegionalDisclaimer(state),
    hasRegionalBannerModalCopy: hasRegionalBannerModalCopy(state),
    sctTax: getSCTTax(state),
    annualLeaseInterestRate: getAnnualLeaseInterestRate(state),
    annualLoanInterestRate: getAnnualLoanInterestRate(state),
    referralCredit: getReferralCredit(state),
    showIRABusinessEligibility,
    isSessionCheckStarted,
    showLegalConsentEditDesign: _get(state, 'ReviewDetails.showLegalConsentEditDesign', false),
    timeOfClickPlaceOrderButton: _get(state, 'ReviewDetails.timeOfClickPlaceOrderButton', ''),
    productType: financeProductId ? financeProductId.split(':')[0] : '',
    hideFinanceProduct: getFinanceProductToHide(state),
    sectionsToHide: false,
    isReservationToOrderFlow,
    showPricesDetailIconTrigger,
    monthlyPaymentAfterTaxCredit: getMonthlyPaymentAfterTaxCredit(state),
    showSavingsAfterTaxCredit,
    showVisitTeslaFinancing: _get(state, 'ReviewDetails.showVisitTeslaFinancing', false),
    shouldShowEsignProcess:
      (isNotAtEsignStatus(state, ESIGN_STATUS_INIT) &&
        isNotAtEsignStatus(state, ESIGN_STATUS_SIGNED)) ||
      _get(ApplicationFlow, 'esignPending', false),
    isTradeInEnabled,
    tradeInAmount,
    tradeInYear,
    tradeInMake,
    tradeInModel,
    remainingTradInCredit,
    tradeInDown,
    tradeinDisclaimers: getTradeInDisclaimers(state),
    hideMobilePaymentSummary: (isLayoutMobile || isLayoutTablet) && !isCoinReloaded ? hideMobilePaymentSummary : false,
    hasAccessories,
    ggConf: state?.App?.ggConf,
    isCoinReloaded,
    taxCredit: getTaxCredit(state),
    priceAfterTaxCredit,
    showPaymentOverview,
    verificationStatus,
    veteranCredit: getVeteranCredit(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onPlacePublicOrder: (session = false) => {
      dispatch(placePublicOrderFlow(session));
    },
    onPlaceOrder: (session = false) => {
      dispatch(placeOrderFlow(session));
    },
    onSaveConfig: (session = false) => {
      dispatch(saveConfigFlow(session));
    },
    onCancelUpdateOrder: (session = false) => {
      dispatch(cancelUpdateOrder(session));
    },
    editDesign: () => {
      dispatch(navigationSelectKey({ key: NAVIGATION_VIEW_OVERVIEW, isEditDesign: true }));
    },
    dispatchSetCCDetailsValidFlag: isValid => {
      dispatch(setCCDetailsValidFlag(isValid));
    },
    dispatchUserHasApplePay: canMakePayments => {
      dispatch(userHasApplePay(canMakePayments));
    },
    triggerUpdateAccountDetails: payload => {
      dispatch(updateAccountDetails(payload));
    },
    triggerUpdatePaymentDetails: payload => {
      dispatch(updatePaymentDetails(payload));
    },
    externalLinkClicked: props => {
      dispatch(externalLinkClicked(props));
    },
    triggerPostConnectRedirect: props => {
      dispatch(redirectToDeliveryFlow(props));
    },
    updatePickupLocation: payload => {
      // eslint-disable-next-line jsx-control-statements/jsx-jcs-no-undef
      dispatch(updatePickupLocation(payload));
    },
    triggerSelectTab: tabId => {
      dispatch(selectTab(tabId, PAYMENT_FIN_TABS));
    },
    setPricingDetails: flag => {
      dispatch(setPricingDetails(flag));
    },
    addAlert: message => {
      dispatch(addAlert(message));
    },
    dispatchCheckSession: func => {
      dispatch(checkSession(func));
    },
    setDrivertype: driverType => {
      dispatch(toggleFinancePanelDrivertype(driverType));
    },
    setRegion: regionCode => {
      dispatch(setSummaryPanelRegion(regionCode));
    },
    setAccountRegistrationType: regType => {
      dispatch(setAccountRegistrationType(regType));
    },
    postPriceAcceptance: type => dispatch(postPriceAcceptance(type)),
    setTimeOfClickPlaceOrderButton: timestamp => {
      dispatch(setTimeOfClickPlaceOrderButton(timestamp));
    },
    selectFinanceTab: tabName => dispatch(selectFinanceView(tabName)),
  };
};

const { string, number, bool, shape, func, objectOf, oneOfType, arrayOf } = PropTypes;

PaymentOverview.propTypes = {
  ccDetailsValid: bool,
  accountDetailsValid: bool,
  isDeliveryDetailsValid: bool.isRequired,
  isDeliveryDetailsRequired: bool.isRequired,
  hideBaseField: arrayOf(string).isRequired,
  validationErrors: objectOf(bool),
  dispatchUserHasApplePay: func.isRequired,
  compositorQuery: string,
  isFixedPickupLocation: bool.isRequired,
  doSessionStartOnMount: bool,
  isSessionSet: bool,
  dispatchCheckSession: func,
  onPlacePublicOrder: func.isRequired,
  validateCCDetails: bool.isRequired,
  serverErrors: shape({}),
  addAlert: func.isRequired,
  billingInfoValid: bool.isRequired,
  collectAllBillingInfo: bool,
  hideAllBillingInfo: bool,
  paymentType: string.isRequired,
  paymentTabsType: string.isRequired,
  paymentFormValid: bool.isRequired,
  dispatchSetCCDetailsValidFlag: func.isRequired,
  onPlaceOrder: func.isRequired,
  onSaveConfig: func.isRequired,
  triggerUpdateAccountDetails: func.isRequired,
  triggerUpdatePaymentDetails: func.isRequired,
  depositAmount: oneOfType([string.isRequired, number.isRequired]).isRequired,
  depositAmountBase: number.isRequired,
  invoiceType: shape({
    type: string,
  }),
  showInvoiceOption: bool,
  invoiceDetailsValid: bool.isRequired,
  paymentError: oneOfType([string, bool]),
  triggerSelectTab: func.isRequired,
  modifyOrder: oneOfType([func.isRequired, bool.isRequired]).isRequired,
  onCancelUpdateOrder: func.isRequired,
  showPaymentDisclaimers: bool,
  showTraderLicense: bool.isRequired,
  deliveryPostalCode: string,
  transportationFee: number,
  isTransportFeeEnabled: bool,
  isTransportFeeCollectionEnabled: bool,
  editDesign: func.isRequired,
  isSinglePage: bool,
  enableCyberpunk: bool.isRequired,
  isCollapsedFlow: bool.isRequired,
  isPostOrderSwap: bool.isRequired,
  hidePostOrderSwapLocation: bool,
  isFalconDeliverySelectionEnabled: bool.isRequired,
  postOrderSwapLocation: string,
  displayAsset: bool,
  legalConsentValid: bool,
  showLegalConsentAfterPayX: arrayOf(string),
  orderAgreementUrl: string,
  hideIncentivesOrderPage: bool,
  deliveryAndRegistrationDisclaimer: string,
  showVehiclePricePlusFees: bool,
  deliveryRegionDisclaimer: string,
  initialPaymentAltCurrency: oneOfType([string, number]),
  secondPaymentAltCurrency: oneOfType([string, number]),
  firstRegistrationDate: string,
  showFirstRegistrationDatePaymentPage: bool,
  isNativePaymentEnabled: bool,
  isDm: bool,
  discount: number,
  displayOtherEUDisclaimer: bool,
  showFinancialTabsEnabled: bool,
  showPriceWithCOE: bool,
  feesDisclaimer: string,
  showFinancingBanner: bool,
  isReservedPreOrder: bool,
  isBookedPreOrder: bool,
  showLegalConsentEditDesign: bool,
  showPricesDetailIconTrigger: bool,
  shouldShowEsignProcess: bool,
  isTradeInEnabled: bool,
  tradeInAmount: number,
  tradeInYear: number,
  tradeInMake: string,
  tradeInModel: string,
  selectFinanceTab: func.isRequired,
  remainingTradInCredit: number,
  tradeInDown: number,
  paymentType: string,
  hasAccessories: bool,
  ggConf: shape({gKey: string, gChannel: string}),
  isCoinReloaded: bool,
  taxCredit: number,
  priceAfterTaxCredit: number,
  showPaymentOverview: bool,
  showOnlyPayment: bool,
};

PaymentOverview.defaultProps = {
  ccDetailsValid: false,
  accountDetailsValid: false,
  validationErrors: {},
  compositorQuery: '',
  isSessionSet: false,
  serverErrors: {},
  doSessionStartOnMount: true,
  dispatchCheckSession: null,
  collectAllBillingInfo: false,
  hideAllBillingInfo: false,
  invoiceType: {},
  showInvoiceOption: false,
  paymentError: false,
  paymentTabsType: 'tabs',
  showPaymentDisclaimers: true,
  deliveryPostalCode: '',
  transportationFee: 0,
  isTransportFeeEnabled: false,
  isTransportFeeCollectionEnabled: false,
  isSinglePage: false,
  legalConsentValid: true,
  showLegalConsentAfterPayX: [],
  orderAgreementUrl: '',
  displayAsset: false,
  hideIncentivesOrderPage: false,
  deliveryAndRegistrationDisclaimer: '',
  showVehiclePricePlusFees: false,
  deliveryRegionDisclaimer: '',
  initialPaymentAltCurrency: '',
  secondPaymentAltCurrency: '',
  firstRegistrationDate: '',
  showFirstRegistrationDatePaymentPage: false,
  isNativePaymentEnabled: false,
  isDm: false,
  hidePostOrderSwapLocation: false,
  discount: 0,
  displayOtherEUDisclaimer: false,
  showPriceWithCOE: false,
  feesDisclaimer: '',
  showFinancingBanner: false,
  isReservedPreOrder: false,
  isBookedPreOrder: false,
  postOrderSwapLocation: '',
  showLegalConsentEditDesign: false,
  showPricesDetailIconTrigger: true,
  shouldShowEsignProcess: false,
  isTradeInEnabled: false,
  tradeInYear: 0,
  tradeInMake: '',
  tradeInModel: '',
  remainingTradInCredit: 0,
  tradeInDown: 0,
  paymentType: '',
  hasAccessories: false,
  ggConf: {},
  isCoinReloaded: false,
  taxCredit: 0,
  priceAfterTaxCredit: 0,
  showPaymentOverview: true,
  showOnlyPayment: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentOverview);
