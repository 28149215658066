import _get from 'lodash/get'
import _reduce from 'lodash/reduce'
import {
    CONFIGURATION_BASE_REQUEST,
    CONFIGURATION_CHANGE_REQUEST,
    OMS_RECEIVED_DEPENDENCIES,
    COMPLETE_CONFIGURATION_CHANGED,
    SET_CONFIGURATION_RN,
    SET_CURRENT_CONFIGURATION_SOURCE,
    STORE_USER_CONFIG,
    RESET_PREVIOUS_CONFIG,
 } from 'dictionary'
import { includesCodes } from 'utils'

/**
 * Function to get the current selected model object
 * @param {Object} state - application state
 * @return {Array} returns array of models with string name and selection status [{name=string,model=string,variant=string,selected=boolean}]
 */
export function configuartionsDetail(app_state, state) {
    const { OMS = {} } = app_state;
    const { available_configurations = [], oms_params = {}, pricebook }  = OMS;
    const newOrder = (_get(state, 'rn', null) === null) ? true : false
    const paintCode = _get(state, 'options_by_group.PAINT[0]')
    const COL   = _get(pricebook, `options.${paintCode}.rules.col`) || ''
    const wheel = (model)=>_get(state, `user_selected_per_model.${model}.WHEEL`)
    const savedModel = _get(state,'model_code',false)

    return available_configurations.filter(config => {
        if (!newOrder && savedModel) {
            return (savedModel === config.model)
        }
        else {
            return true
        }
    }).map(config => {
        switch(config.model){
            case 'ms':
                return Object.assign({}, config, {
                    name: "Model S",
                    selected: config.model === oms_params.model && config.variant === oms_params.variant,
                    asset: {
                        type: 'image-compositor',
                        query: `&options=MI01,${COL}-${paintCode},${wheel('ms')||'WTAS'}&view=STUD_SIDE&model=ms&size=375`,
                        bkba: 2
                    }
                })
            case 'mx':
                return Object.assign({}, config, {
                    name: "Model X",
                    selected: config.model === oms_params.model && config.variant === oms_params.variant,
                    asset: {
                        type: 'image-compositor',
                        query: `&options=${COL}-${paintCode},${wheel('mx')||'WT20'}&view=STUD_SIDE&model=mx&size=375`,
                        bkba: 2
                    }
                })
        }
    })
}

function Configuration(state = {}, action, {app_state}) {
    const { OMS = {} } = app_state;
    const { oms_params = {} } = OMS;
    switch (action.type) {
        case COMPLETE_CONFIGURATION_CHANGED:
            const options = action.options
            const user_selected_options_by_group = _reduce(action.userSelectedOptionsByGroup.matchedCodes, (result, option, key)=>{
                        const optionCode = option[option.length - 1]
                        return includesCodes([optionCode], options) ? Object.assign(result, {[key]: optionCode}) : result
                    },{})
            const stateDelta = Object.assign({}, state, {
                fetching: false,
                user_selected_options_by_group,
                user_selected_per_model: Object.assign({}, state.user_selected_per_model, {[oms_params.model]:Object.assign({}, user_selected_options_by_group, { non_matched: action.userSelectedOptionsByGroup.nonMatchedCodes })}),
                user_selected_options_by_group_non_matched: action.nonMatchedCodes,
                options_by_category: action.configurationByCategory.matchedCodes,
                options_by_group: action.configurationByGroup.matchedCodes,
                options_by_group_non_matched: action.configurationByGroup.nonMatchedCodes,
                option_codes:  options,
                option_string:  options.toString(),
                configuration:  action,
                user_selected_options: action.userSelectedOptions
            })

            return Object.assign({}, state, stateDelta, {
                configurations_detail: configuartionsDetail(app_state, stateDelta),
            })

        case CONFIGURATION_BASE_REQUEST:
        case CONFIGURATION_CHANGE_REQUEST:
            return Object.assign({}, state, {
                fetching: true
            })

        case OMS_RECEIVED_DEPENDENCIES:
            return Object.assign({}, state, {
                model_code: _get(app_state,'OMS.oms_params.model'),
            })

        case SET_CONFIGURATION_RN:
            return Object.assign({}, state, {
                rn: _get(action, 'rn', ''),
            })

        case SET_CURRENT_CONFIGURATION_SOURCE:
            return Object.assign({}, state, {
                configurationSource: _get(action, 'source', null)
            })
        case STORE_USER_CONFIG: 
            return {
                ...state,
                previousUserConfig: action?.payload || null,
            }
        case RESET_PREVIOUS_CONFIG: 
            return {
                ...state,
                previousUserConfig: null,
            }
        default:
            return state
    }
}

export default Configuration

