/* eslint no-shadow: 0 */
import React, { useMemo } from 'react';
import { string, shape, arrayOf, func, bool } from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import _has from 'lodash/has';
import _isArray from 'lodash/isArray';
import _filter from 'lodash/filter';
import { setOptionExtended } from 'actions';
import { findNextTabStop } from '../../common/accessiblity';
import ActionTarget from '../ActionTarget';
import Title from './Title';
import Disclaimer from './Disclaimer';
import FormattedPrice from './FormattedPrice';
import InventoryUpsellLink from './InventoryUpsellLink';
import Copy from './Copy';
import LocationObserver from '../LocationObserver';
import DynamicComponent from '../DynamicComponent';
import StatusCard from './StatusCard';
import { parseSelectedBy, getCustomDisabled } from 'utils';
import { OPT_TYPE_BLOCK, OPT_TYPE_CIRCULAR, PAINT, WHEELS, OPT_TYPE_SQUARE } from 'dictionary';
import { getPricePostfix, isInventory, getInventoryOptionsArray } from 'selectors';

const availableComponents = {
  ActionTarget,
  Copy,
  Disclaimer,
  FormattedPrice,
  Title,
  InventoryUpsellLink,
  StatusCard,
};
/* Options component */
const Options = ({
  group,
  priceData,
  extraPriceData,
  isLayoutMobile,
  configuration,
  setOption,
  setOptionByKeyDown,
  pricePostfix,
  showActualPriceInOption,
  customerType,
  showAfterSavingsPriceForTrims,
  includeFeesinBatteryCalculation,
  navigationTab,
  classes,
  customGroups,
  showAmountWithTaxCredit,
  isInventory,
  inventoryOptionsArray,
  vehicleUpgradesCategories,
  ...rest
}) => {
  if (!group || !group.current.length) {
    return null;
  }
  const subComponent = (option, groupCode) => {
    if (!option.components) {
      return null;
    }
    const { components, code } = option;
    return (
      components.length &&
      components.map(component => {
        const { type, props = {}, selected_by } = component;
        if (
          !_has(availableComponents, type) ||
          (selected_by && !parseSelectedBy(selected_by, configuration))
        ) {
          return null;
        }
        const componentProps = {
          parentGroupCode: groupCode,
          group: option,
          ...props,
        };
        const ComponentName = availableComponents[type];
        return (
          <ComponentName
            key={`OptionGroupComponent_${groupCode}_${type}_${code}_${JSON.stringify(props)}`}
            {...componentProps}
            componentName={type}
          />
        );
      })
    );
  };
  const { code, type } = group;
  let { current = [] } = group;
  const isReadOnly = isInventory && _isArray(inventoryOptionsArray) && (!vehicleUpgradesCategories || !vehicleUpgradesCategories.includes(code));
  if (isReadOnly) {
    current = useMemo(() => _filter(current, (o) => inventoryOptionsArray.includes(o?.code)), [inventoryOptionsArray, current]);
  }
  /* TDS5 has OptionGroup now - maybe something to implement when we can refactor */
  return (
    <div className="group--detail-container">
      <div
        className={cx(
          'child-group--option_details',
          { 'tds-form-input-group': type === OPT_TYPE_BLOCK },
          { 'child-group--paint': code === PAINT },
          { 'child-group--swatch': code === PAINT || code === WHEELS },
          { 'child-group--compact': (current.length > 5 && current.length < 7)},
          { 'child-group--honeycomb': (current.length > 6)},
          {
            [classes]: classes,
          }
        )}
      >
        {current.reduce((res, option) => {
          const hiddenOption = useMemo(() => getCustomDisabled(option, 'hidden'), [option]);
          if (hiddenOption) {
            return res;
          }
          return [...res, ...[
            <LocationObserver
              type="OptionWidget"
              classes={cx(
                'tds-option--type',
                { 'tds-option tds-o-option': type === OPT_TYPE_BLOCK },
                { 'tds-option--circular': type === OPT_TYPE_CIRCULAR },
                { 'tds-option--square': type === OPT_TYPE_SQUARE },
                { [option?.shape?.classes] : option?.shape?.classes },
              )}
              key={`LocationObserver:Option:${code}:${option.code}`}
            >
              <DynamicComponent
                component={() => import('./Option')}
                placeholder={<div></div>}
                componentKey={`OptionInGroup:${code}:${option.code}`}
                priceData={priceData}
                extraPriceData={extraPriceData}
                isLayoutMobile={isLayoutMobile}
                option={option}
                configuration={configuration}
                setOption={setOption}
                setOptionByKeyDown={setOptionByKeyDown}
                sectionID={rest.code}
                code={code}
                pricePostfix={pricePostfix}
                showActualPriceInOption={showActualPriceInOption}
                customerType={customerType}
                showAfterSavingsPriceForTrims={showAfterSavingsPriceForTrims}
                includeFeesinBatteryCalculation={includeFeesinBatteryCalculation}
                navigationTab={navigationTab}
                CustomGroups={customGroups}
                group={group}
                showAmountWithTaxCredit={showAmountWithTaxCredit}
                hiddenOption={hiddenOption}
                isReadOnly={isReadOnly}
              />
            </LocationObserver>,
            <If
              key={`SubComponent:Option:${code}:${option.code}`}
              condition={option?.components?.length}
            >
              {subComponent(option, code)}
            </If>,
          ]];
        }, [])}
      </div>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const {
    App,
    Configuration,
    FinancingOptions = {},
    Pricing,
    ReviewDetails,
    Navigation,
    CustomGroups,
  } = state;
  const { showAmountWithTaxCredit = false } = ownProps || {};
  const { isLayoutMobile, displayDoubleCurrency } = App;
  const { option_codes } = Configuration;
  const { section = '' } = Navigation;
  const { includeFeesinBatteryCalculation = false } = FinancingOptions;
  const {
    showActualPriceInOption = false,
    showAfterSavingsPriceForTrims = false,
    AccountDetail = {},
  } = ReviewDetails;
  const { AccountType = '' } = AccountDetail;
  const { calculatorParameters: calcParams, calculatorResult, financeType } = Pricing;
  const { apiResultsPerTrim, apiResults, extraPriceContexts = {} } = calculatorResult?.data || {};
  const { fees, incentives } = apiResults || {};

  let extraPriceData;

  if (displayDoubleCurrency && Object.keys(extraPriceContexts).length > 0) {
    const { apiResultsPerTrim: extraApiResultsPerTrim, apiResults: extraApiResults } =
      Object.values(extraPriceContexts)[0] || {};
    const { fees: extraFees, incentives: extraIncentives } = extraApiResults || {};

    extraPriceData = {
      fees: extraFees,
      incentives: extraIncentives,
      apiResultsPerTrim: extraApiResultsPerTrim,
      calcParams,
      currency: Object.keys(extraPriceContexts)[0],
    };
  }

  const hasFinanceToggle = CustomGroups?.BATTERY_AND_DRIVE?.components?.find((component) => component.type === 'FinanceToggle');
  return {
    configuration: option_codes,
    priceData: {
      fees,
      incentives,
      apiResultsPerTrim,
      calcParams,
      financeType,
      showOptionPriceByFinanceType: hasFinanceToggle,
    },
    extraPriceData,
    isLayoutMobile,
    showActualPriceInOption,
    showAfterSavingsPriceForTrims,
    includeFeesinBatteryCalculation,
    pricePostfix: getPricePostfix(state),
    navigationTab: section,
    customerType: AccountType,
    customGroups: CustomGroups,
    showAmountWithTaxCredit,
    isInventory: isInventory(state),
    inventoryOptionsArray: getInventoryOptionsArray(state),
    vehicleUpgradesCategories: state?.ReviewDetails?.vehicleUpgradesCategories || null,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setOption: (option, groupCode) => {
      document.body.classList.add('using-mouse');
      if (option) {
        dispatch(setOptionExtended({ set: option }, groupCode));
      }
    },
    setOptionByKeyDown: (option, e) => {
      let direction = 'next';
      if (e.shiftKey && e.keyCode === 9) {
        direction = 'previous';
      }
      if (e.keyCode === 9) {
        document.body.classList.remove('using-mouse');
      }

      // down and right
      if (e.keyCode === 39 || e.keyCode === 40) {
        document.body.classList.remove('using-mouse');
        const currentElement = e.target;
        const nextElem = findNextTabStop(currentElement);
        nextElem.focus();
      }

      // up and left
      if (e.keyCode === 37 || e.keyCode === 38) {
        document.body.classList.remove('using-mouse');
        const currentElement = e.target;
        const nextElem = findNextTabStop(currentElement, 'previous');
        nextElem.focus();
      }

      if (option && (e.keyCode === 13 || e.keyCode === 32)) {
        dispatch(setOptionExtended({ set: option }));
      }
    },
  };
}

Options.propTypes = {
  group: shape({
    current: arrayOf(shape({})),
  }).isRequired,
  setOption: func.isRequired,
  setOptionByKeyDown: func.isRequired,
  configuration: arrayOf(string).isRequired,
  priceData: shape({}),
  extraPriceData: shape({}),
  customGroups: shape({}),
  isLayoutMobile: bool,
  showActualPriceInOption: bool,
  showAfterSavingsPriceForTrims: bool,
  includeFeesinBatteryCalculation: bool,
  pricePostfix: string,
  customerType: string,
  navigationTab: string,
  classes: string,
  showAmountWithTaxCredit: bool,
  isInventory: bool.isRequired,
  inventoryOptionsArray: arrayOf(string),
  vehicleUpgradesCategories: arrayOf(string),
};

Options.defaultProps = {
  isLayoutMobile: false,
  showActualPriceInOption: false,
  showAfterSavingsPriceForTrims: false,
  includeFeesinBatteryCalculation: false,
  priceData: {},
  customGroups: {},
  pricePostfix: '',
  customerType: '',
  navigationTab: '',
  classes: '',
  showAmountWithTaxCredit: false,
  inventoryOptionsArray: null,
  vehicleUpgradesCategories: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Options);
