import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { i18n } from 'utils';
import classnames from 'classnames';
import { 
  INVENTORY_COMPARE_MODAL,
  COMPARE_DESIGN_CARD,
  SIZE,
  BKBA_OPT,
  CROP_COMPARE_ORDERED_DESIGN,
  CROP_COMPARE_NEW_DESIGN
} from 'dictionary';

import InfoBlock from '../InfoBlock';

const CompareDesign = ({ url, orderedUrl }) => {
  const componentName = INVENTORY_COMPARE_MODAL;
  const analyticsInteraction = COMPARE_DESIGN_CARD;



  return (
    <div
      className= "compare-design-container"
    >
      <InfoBlock
        name="compareDesign"
        titleSection={i18n('common.compareDesigns')}
        titleSectionClass="tds-text--h2 tds--vertical_padding"
        buttonLabel={i18n('common.compare')}
        buttonClass="tds-btn--tertiary tds-btn--medium"
        componentName={componentName}
        analyticsInteraction={analyticsInteraction}
      >        
      <div className={classnames(
        'group-block specs-block'
      )}>
        <ol className='tds-list tds-list--horizontal tds-o-list--box'>
          <li className='compare-img-item tds-o-list-item'>
            <img
            className='compare-img'
            src={orderedUrl}
            />
            <span>{i18n('PostOrderSwap.orderedDesign')}</span>
          </li>
          <li className='compare-img-item tds-o-list-item'>
            <img
              className='compare-img'
              src={url}
            />
            <span>{i18n('PostOrderSwap.newDesign')}</span>
          </li>
        </ol>
      </div>
      </InfoBlock>
    </div>
  );
};

CompareDesign.propTypes = {
};


function mapStateToProps(state) { 
  const newViews = _get(state, 'Compositor.views', {});
  const orderedViews = _get(state, 'ReviewDetails.product.orderDetails.OptionCodeList', {});
  const countryCode = _get(state, 'App.countryCode', {});
  const isCN = countryCode === 'CN';
  const { STUD_3QTR } = newViews;
  const { url } = STUD_3QTR;
  
  const urlArray=url.split('?')
  const baseNewUrl=urlArray?.[0]

  const queryParams={}
  const queryOptionCode = {}

  urlArray?.[1].split('&').forEach(item=>{
  const paramsArray=item.split('=')
  const key=paramsArray?.[0]
  const value=paramsArray?.[1]
    queryParams[key]=value
    queryOptionCode[key]=value
  })
  queryParams['size']= SIZE
  queryParams['crop']= CROP_COMPARE_NEW_DESIGN
  queryParams['bkba_opt']= BKBA_OPT

  queryOptionCode['options'] = orderedViews
  queryOptionCode['size']= SIZE
  queryOptionCode['bkba_opt']= BKBA_OPT
  queryOptionCode['crop']= CROP_COMPARE_ORDERED_DESIGN

  const newUrl=baseNewUrl+'?'+Object.keys(queryParams).map(key=>{
  return `${key}=${queryParams[key]}`
  }).join('&')

  const orderedUrl = baseNewUrl+'?'+Object.keys(queryOptionCode).map(key=>{
    return `${key}=${queryOptionCode[key]}`
    }).join('&')


  return {
    url: newUrl,
    orderedUrl,
  };
}
export default connect(mapStateToProps)(CompareDesign);
