import { useLayoutEffect } from 'react';

export default function useGoogleOptimize(experimentId, callback, deps = []) {
  useLayoutEffect(
    () => {
      function gtag() {
        // eslint-disable-next-line prefer-rest-params
        window.dataLayer.push(arguments);
      }

      if (experimentId) {
        gtag('event', 'optimize.callback', {
          name: experimentId,
          callback,
        });
      }

      return () => {
        // clean up the event on umount
        if (experimentId) {
          gtag('event', 'optimize.callback', {
            name: experimentId,
            callback,
            remove: true,
          });
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  );
}
