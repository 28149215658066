import React from 'react';
import { connect } from 'react-redux';
import { bool } from 'prop-types';
import { i18n, htmlToReact } from 'utils';

const PriceAcceptanceDisclaimer = ({showPreOrderPriceAcceptance}) => {
  if (!showPreOrderPriceAcceptance) {
    return null;
  }
  return (
    <div className="priceAcceptance-disclaimer tds--vertical_padding">
      {htmlToReact(
        i18n('PriceAcceptance.preorder_disclaimer', {
        BUSINESS_DAYS_COUNT: 5
      }))}
      {htmlToReact(i18n('PriceAcceptance.legal_disclaimer'))}
    </div>
  )
};

PriceAcceptanceDisclaimer.propTypes = {
  showPreOrderPriceAcceptance: bool,
};

PriceAcceptanceDisclaimer.defaultProps = {
  showPreOrderPriceAcceptance: false,
};

function mapStateToProps(state) {
  const { ReviewDetails } = state;
  const { showPreOrderPriceAcceptance } = ReviewDetails;

  return {
    showPreOrderPriceAcceptance,
  };
}

export default connect(mapStateToProps)(PriceAcceptanceDisclaimer);