import React from 'react';
import { connect } from 'react-redux';
import { getInvoiceType, setInvoiceDetailsValidFlag } from 'actions';
import InvoiceOptionsRepresentation from './representation';

const InvoiceOptions = props => {
  return <InvoiceOptionsRepresentation {...props} />;
};

function mapStateToProps(state) {
  const { Payment, App } = state;
  const {
    InvoiceType: invoiceType = null,
    configs: { CharityInvoiceTypes: charityInvoiceTypes = null } = {},
  } = Payment;
  const { isLayoutMobileAdjusted: isMobile } = App;
  return {
    invoiceType,
    charityInvoiceTypes,
    isMobile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateInvoiceType: key => {
      dispatch(getInvoiceType(key));
    },
    setValidFlag: flag => {
      dispatch(setInvoiceDetailsValidFlag(flag));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceOptions);
