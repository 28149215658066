import React from 'react';
import { bool } from 'prop-types';
import { Loader } from '@tesla/design-system-react';

const PartialSpinner = ({ active }) => (
  <If condition={active}>
    <Loader show={active} className="tds-loader--partial" />
  </If>
);

PartialSpinner.propTypes = {
  active: bool.isRequired,
};

PartialSpinner.defaultProps = {
  active: false,
};

export default PartialSpinner;
