import React from 'react';
import { connect } from 'react-redux';
import { bool, string } from 'prop-types';
import { i18n, htmlToReact, constructUrl, isPriceAcceptance } from 'utils';
import _get from "lodash/get";

const ApplyForFinancingLink = ({ showLink, applyForFinancing }) => {
  if (!showLink || !applyForFinancing || isPriceAcceptance()) {
    return null;
  }
  return <span>{htmlToReact(applyForFinancing)}</span>;
};

ApplyForFinancingLink.propTypes = {
  showLink: bool,
  applyForFinancing: string,
};

ApplyForFinancingLink.defaultProps = {
  showLink: false,
  applyForFinancing: '',
};

function mapStateToProps(state) {
  const { ReviewDetails, ApplicationFlow, App } = state;
  const { hideApplyForFinancing } = ReviewDetails;
  const { canModifyOrder } = ApplicationFlow;
  const { sibling, locale } = App;
  const modelCode = _get(state, 'Configuration.model_code', '');

  const applyForFinancing = i18n(
    'SummaryPanel.disclaimers.applyForFinancing',
    {
      FAQ_DELIVERY_QUESTIONS: constructUrl(
        'support/frequently-asked-delivery-questions?redirect=no#info-financial',
        sibling,
        locale
      ),
    },
    null,
    {
      returnNullWhenEmpty: true,
      specificOverride: modelCode,
    }
  );

  return {
    showLink: !hideApplyForFinancing && !canModifyOrder,
    applyForFinancing,
  };
}

export default connect(mapStateToProps)(ApplyForFinancingLink);
