import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { formatCurrency } from '@tesla/coin-common-components';
import { htmlToReact, i18n } from 'utils';
import {
  FINANCE_MODAL_TAB_INCENTIVES,
  FINANCE_CAROUSEL,
  BANNER_MODAL,
  FINANCE_CONTAINER,
  MODAL_XSMALL,
  SHOW_INCENTIVES_MODAL,
} from 'dictionary';
import { incentiveTypeProps } from './propTypes';
import RegionalFees from '../../components/Forms/RegionalFees';
import ModalTrigger from '../../components/ModalTrigger';
import IRAIncentiveCopy from '../../components/GroupComponents/IRAIncentiveCopy';
import ActionTrigger from '../../components/ActionTrigger';

const Incentives = ({
  incentives,
  incentivesTotal,
  tcc,
  fuelYears,
  showIncentivesSectionTitle,
  showOrderPageSummaryOtherFees,
  cashTabIncentives,
  governmentIncentivesTotal,
  showGovernmentSubsidy,
  totalSavings,
  fuelReward,
  evCredit,
  showVehiclePricePlusFees,
  showProvincialIncentives,
  locale,
  regionalDisclaimer,
  hasRegionalBannerModalCopy,
  showIRABusinessEligibility,
  modelCode,
}) => {
  const { afterDelivery = [], beforeDelivery = [], regional = [] } = incentives;
  const regionalIncentive = regional.length ? regional[0]?.amount : 0;
  const { longName: regionCode = '', regionCode: shortRegionCode } = regional.length
    ? regional[0]
    : {};
  const { taxCredit = 0, incentivesAfterDelivery = 0, incentiveInDown = 0 } =
    cashTabIncentives || {};

  const getRegionalIncentivesBlock = amount => {
    return (
      <li className="review-page--line-item tds-list-item">
        <span className="label left">
          {i18n(
            'Review.regional',
            {
              LABEL: regionCode,
            },
            null,
            {
              specificOverride: shortRegionCode,
              returnNullWhenEmpty: true,
              specificOverrideOperator: 'OR',
            }
          )}
          <If condition={cashTabIncentives?.hasAfterDeliveryDisclaimer && regionalDisclaimer}>
            <p className="tds-text--caption">{regionalDisclaimer}</p>
          </If>
          <If condition={cashTabIncentives?.hasConditionDisclaimer}>
            <ModalTrigger
              type={hasRegionalBannerModalCopy ? undefined : FINANCE_CAROUSEL}
              selectedView={hasRegionalBannerModalCopy ? undefined : FINANCE_MODAL_TAB_INCENTIVES}
              options={{
                props: {
                  componentName: hasRegionalBannerModalCopy ? BANNER_MODAL : FINANCE_CONTAINER,
                  props: hasRegionalBannerModalCopy
                    ? {
                        bannerType: 'ira_modal',
                        side: MODAL_XSMALL,
                        genericWrapper: true,
                        source: 'banner',
                        containerCss: 'coin--banner-modal',
                        contentSource: 'regional',
                      }
                    : {},
                },
              }}
            >
              <span className="tds-link tds-text--caption">
                {i18n('Review.regional_qualification_link')}
              </span>
            </ModalTrigger>
          </If>
        </span>
        <span className="value">{formatCurrency(amount)}</span>
      </li>
    );
  };

  const listItems = (
    <>
      <If condition={taxCredit}>
        <li className="review-page--line-item tds-list-item">
          <div className="label left">
            <p>{i18n('Review.taxCredit')}</p>
            <ModalTrigger
              options={{
                props: {
                  componentName: 'BannerModal',
                  props: {
                    genericWrapper: true,
                    size: 'MODAL_SMALL',
                    containerCss: 'tds-display--inline tds-link tds-text--caption',
                    bannerType: 'ira_modal',
                    baseOnly: true,
                    contentSource: 'taxCredit',
                  },
                },
              }}
            >
              {i18n('Incentives.taxCredit_eligiblity')}
            </ModalTrigger>
          </div>
          <span className="value">{formatCurrency(taxCredit)}</span>
        </li>
      </If>
      <If condition={afterDelivery.length || beforeDelivery.length}>
        {[...afterDelivery, ...beforeDelivery].map(splIncentive => {
          const { amount = 0, key = '', period = '', longName: region = '' } = splIncentive;

          return (
            <If condition={amount && period === 'once'} key={key}>
              <li className="review-page--line-item tds-list-item" key={`Incentives_total:${key}`}>
                <span className="label left">
                  {i18n(`Incentives.${key}.title`, { STATE: region })}
                </span>
                <span className="value">{formatCurrency(amount)}</span>
              </li>
            </If>
          );
        })}
      </If>
      <If
        condition={
          !showProvincialIncentives &&
          !(incentivesAfterDelivery || incentiveInDown) &&
          regionalIncentive
        }
      >
        {getRegionalIncentivesBlock(regionalIncentive)}
      </If>
      <If
        condition={incentives.environmental_bonus && incentives.environmental_bonus[0].amount !== 0}
      >
        <li className="review-page--line-item tds-list-item">
          <span className="label left">{incentives.environmental_bonus[0].longName}</span>
          <span className="value">{formatCurrency(incentives.environmental_bonus[0].amount)}</span>
        </li>
      </If>
      <If condition={incentives.ecoTaxReduction && incentives.ecoTaxReduction[0].amount !== 0}>
        <li className="review-page--line-item tds-list-item">
          <span className="label left">{i18n('SummaryPanel.incentives')}</span>
          <span className="value">{formatCurrency(incentives.ecoTaxReduction[0].amount)}</span>
        </li>
      </If>
      <Choose>
        <When condition={typeof tcc === 'object' && Object.keys(tcc).length > 0}>
          {Object.entries(tcc)
            .filter(([, [tccValue]]) => !(tccValue.amount === 0 && tccValue.comparisonAmount === 0))
            .map(([tccKey, [tccValue]]) => (
              <li
                className="review-page--line-item tds-list-item"
                key={`TCC_incentives_list:${tccKey}`}
              >
                <span className="label left">{htmlToReact(i18n(`Review.tcc.${tccKey}`))}</span>
                <span className="value">{formatCurrency(-1 * tccValue.savingsAmount)}</span>
              </li>
            ))}
        </When>
        <When condition={incentives.fuel && incentives.fuel[0].amount !== 0}>
          <li className="review-page--line-item tds-list-item">
            <span className="label left">
              {htmlToReact(
                i18n('Review.fuel', {
                  NUM_FUEL_YEAR: fuelYears,
                })
              )}
            </span>
            <span className="value">{formatCurrency(incentives.fuel[0].amount)}</span>
          </li>
          <If condition={showIRABusinessEligibility}>
            <div className="tds-text--caption tds--vertical_padding--small">
              <IRAIncentiveCopy
                showIRABusinessEligibility={showIRABusinessEligibility}
                hideIcon={true}
                className="tds-o-no-margin"
                linkText={i18n('common.learn_more')}
              />
            </div>
          </If>
        </When>
      </Choose>
      <If condition={showOrderPageSummaryOtherFees}>
        <If condition={incentivesAfterDelivery}>
          {getRegionalIncentivesBlock(incentivesAfterDelivery)}
        </If>
        <If condition={governmentIncentivesTotal}>
          <li className="review-page--line-item tds-list-item">
            <span className="line-item--label left">
              <ActionTrigger>
                {htmlToReact(
                  i18n(
                    'Incentives.purchasePriceIncentive',
                    { TRIGGER: SHOW_INCENTIVES_MODAL },
                    null,
                    {
                      specificOverride: modelCode,
                      returnNullWhenEmpty: true,
                      specificOverrideOperator: 'OR',
                    }
                  )
                )}
              </ActionTrigger>
            </span>
            <span className="value">{formatCurrency(governmentIncentivesTotal)}</span>
          </li>
        </If>
      </If>
      <If condition={showGovernmentSubsidy && (evCredit || governmentIncentivesTotal)}>
        <li
          className="review-page--line-item tds-list-item"
          key="FinancingOptions__government_subsidy"
        >
          <span className="line-item--label left">
            {i18n('Incentives.extraIncentives.governmentSubsidy')}
          </span>
          <span className="line-item--value value">
            {formatCurrency(evCredit || governmentIncentivesTotal)}
          </span>
        </li>
      </If>
      <If condition={showProvincialIncentives}>
        <RegionalFees isSummary />
      </If>
    </>
  );

  let hasListItems = false;
  React.Children.forEach(listItems.props.children, nestedChild => {
    if (nestedChild != null) {
      hasListItems = true;
    }
  });

  // hide this component if nothing to show
  if (
    !hasListItems &&
    !showIncentivesSectionTitle &&
    !(incentivesTotal && !showOrderPageSummaryOtherFees)
  ) {
    return null;
  }

  return (
    <div
      className={classnames('review-page--block', {
        'tds-o--vertical_padding_with_border': locale !== 'zh_CN',
      })}
    >
      <div className="review-page--line-items">
        <If condition={showIncentivesSectionTitle}>
          <p className="review-page--line-item">{i18n('Review.incentivesSectionTitle')}</p>
        </If>
        {hasListItems && <ul className="review-page--line-items-list tds-list">{listItems}</ul>}
        <If
          condition={incentivesTotal && !showOrderPageSummaryOtherFees && !showProvincialIncentives && showVehiclePricePlusFees === false}
        >
          <div className="review-page--total-section">
            <ul className="review-page--totals-list tds-list">
              <li className="review-page--line-item tds-list-item">
                <Choose>
                  <When condition={showGovernmentSubsidy || fuelReward?.amount}>
                    <span className="label left">
                      {i18n('Review.est_savings_label', { NUM_YEAR: fuelYears })}
                    </span>
                    <div className="value">{formatCurrency(totalSavings)}</div>
                  </When>
                  <Otherwise>
                    <span className="label left">
                      {i18n('Review.est_savings_label', { NUM_YEAR: fuelYears })}
                    </span>
                    <div className="value">{incentivesTotal}</div>
                  </Otherwise>
                </Choose>
              </li>
            </ul>
          </div>
        </If>
      </div>
    </div>
  );
};

const { shape, string, number, bool } = PropTypes;

Incentives.propTypes = {
  incentives: shape({
    taxCredit: incentiveTypeProps,
    regional: incentiveTypeProps,
    environmental_bonus: incentiveTypeProps,
    ecoTaxReduction: incentiveTypeProps,
    fuel: incentiveTypeProps,
  }).isRequired,
  incentivesTotal: string,
  tcc: shape({}).isRequired,
  fuelYears: number.isRequired,
  showIncentivesSectionTitle: bool,
  showOrderPageSummaryOtherFees: bool,
  cashTabIncentives: shape({
    incentivesAfterDelivery: number,
    driveAwayPricewithIncentives: number,
    futureSavings: number,
    potentialSavingsPrice: number,
  }).isRequired,
  governmentIncentivesTotal: number,
  showGovernmentSubsidy: bool,
  fuelReward: shape({
    amount: number,
    title: string,
    formattedAmtWithMinus: string,
  }),
  totalSavings: number,
  showProvincialIncentives: bool,
  evCredit: number,
  showVehiclePricePlusFees: bool,
  locale: string,
  regionalDisclaimer: string,
  hasRegionalBannerModalCopy: bool,
};

Incentives.defaultProps = {
  incentivesTotal: null,
  showIncentivesSectionTitle: false,
  showOrderPageSummaryOtherFees: false,
  showGovernmentSubsidy: false,
  fuelReward: null,
  governmentIncentivesTotal: 0,
  totalSavings: 0,
  showProvincialIncentives: false,
  evCredit: 0,
  showVehiclePricePlusFees: false,
  locale: '',
  regionalDisclaimer: '',
  hasRegionalBannerModalCopy: false,
};

export default Incentives;
