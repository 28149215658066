import React, { useState, useEffect, useRef } from 'react';
import { shape, string, bool, arrayOf, number, func } from 'prop-types';
import Template from './template';
import '../../style/asset-gallery.css';

const AssetGallery = ({
  id,
  assets,
  currentIndex: currentIndexProp,
  isActive,
  labels,
  isVideoPlaybackEnabeled,
  infinite = true, // Used for lower count galleries like compositor views
  dots = false,
  theme = 'default',
  openGalleryModal,
  closeGalleryModal,
  enableModalView = false,
  isCyberpunk,
  isMobile,
  currentFeatures,
  onFeatureToggle,
}) => {
  const [currentIndex, setCurrentIndex] = useState(currentIndexProp || 0);
  const previousIndex = useRef(); // The previous in history, not order
  const gallerySize = assets?.length;
  if (!gallerySize) {
    return null;
  }

  const onPrevious = () => {
    if (infinite) {
      const index = currentIndex < 1 ? gallerySize - 1 : currentIndex - 1;
      setCurrentIndex(index);
    } else {
      const index = currentIndex < 1 ? currentIndex : currentIndex - 1;
      setCurrentIndex(index);
    }
  };

  const onNext = () => {
    if (infinite) {
      const index = currentIndex === gallerySize - 1 ? 0 : currentIndex + 1;
      setCurrentIndex(index);
    } else {
      const index = currentIndex === gallerySize - 1 ? currentIndex : currentIndex + 1;
      setCurrentIndex(index);
    }
  };

  const onRequestDetailedView = enableModalView
    ? () =>
        openGalleryModal({
          id,
          assets,
          currentIndex,
          isActive,
          labels,
          isVideoPlaybackEnabeled,
          infinite,
          dots,
          theme,
        })
    : null;

  useEffect(() => {
    // If we want to override the index through props
    const shouldOverrideIndex = typeof currentIndexProp === 'number';
    if (shouldOverrideIndex) {
      if (isActive && currentIndex !== currentIndexProp) {
        previousIndex.current = currentIndex;
        setCurrentIndex(currentIndexProp);
      }
    } else {
      // Return to user selected index
      if (previousIndex?.current !== undefined) {
        setCurrentIndex(previousIndex.current);
      }
    }
  }, [isActive, currentIndexProp]);

  return (
    <Template
      onNext={infinite || currentIndex !== gallerySize - 1 ? onNext : null}
      onPrevious={infinite || currentIndex !== 0 ? onPrevious : null}
      onSelect={index => setCurrentIndex(index)}
      onRequestDetailedView={onRequestDetailedView}
      onCloseGallery={closeGalleryModal}
      currentIndex={gallerySize > 1 ? currentIndex : 0}
      id={id?.replace(':', '-') || 'asset-gallery'}
      isActive={isActive}
      assets={assets}
      isVideoPlaybackEnabeled={!isVideoPlaybackEnabeled}
      labels={labels}
      dots={dots}
      theme={theme}
      isCyberpunk={isCyberpunk}
      isMobile={isMobile}
      currentFeatures={currentFeatures}
      onFeatureToggle={onFeatureToggle}
    />
  );
};

AssetGallery.propTypes = {
  currentIndex: number,
  assets: arrayOf(shape({})).isRequired,
  id: string.isRequired,
  isActive: bool,
  isVideoPlaybackEnabeled: bool,
  infinite: bool,
  dots: bool,
  theme: string,
  openGalleryModal: func,
  closeGalleryModal: func,
  enableModalView: bool,
  labels: shape({
    next: string,
    previous: string,
  }).isRequired,
  isCyberpunk: bool.isRequired,
  currentFeatures: shape({}).isRequired,
  onFeatureToggle: func.isRequired,
  isMobile: bool.isRequired,
};

export default AssetGallery;
