import React from 'react';
import { shape, arrayOf, string, bool, oneOfType, func } from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import { getImgPropsForMobile } from 'utils';
import { toggleFeatureState } from 'actions';
import AssetLoader from '../AssetLoader';
import TextLoader from '../TextLoader';
import VideoAssetLoader from '../VideoAssetLoader';

const GroupDetails = props => {
  const {
    group,
    className,
    styleProps,
    detail_size = 'big',
    model,
    showGroupTitle = true,
    showGroupFeatureList = true,
    imgProps__Mobile,
    enableCyberpunk,
    onFeatureToggle,
  } = props;
  const { current, child_groups, options } = group;
  if (
    !group ||
    (_isEmpty(current) && _isEmpty(options) && _isEmpty(child_groups)) ||
    !group.asset
  ) {
    return null;
  }

  const isMediaStoryEnabled = _get(group, 'asset.video_content', false);
  const assetLoaderProps = !_isEmpty(imgProps__Mobile) ? imgProps__Mobile : group.asset;

  const style = { ...styleProps };
  const group_detail_size = _get(
    _find(_get(group, 'extra_content', []), content => {
      return _get(content, 'type') === 'detail_size';
    }) || {},
    'content',
    detail_size
  );
  return (
    <div
      style={style}
      className={classnames(
        'group--main-content',
        (() => {
          switch (group_detail_size) {
            case 'full-asset':
              return 'group--main-content--big group-main-content--full-asset';
            case 'big':
              return 'group--main-content--big group-main-content--big-content';
            default:
              return 'group--main-content--regular';
          }
        })(),
        { [className]: className }
      )}
    >
      {group_detail_size !== 'full-asset' &&
        (group.features ||
          (showGroupTitle && group.title) ||
          (showGroupFeatureList && group.feature_list)) &&
        !isMediaStoryEnabled && (
          <div className="group--main-content--details">
            <TextLoader data={group} field="features" />
            {showGroupTitle && <TextLoader data={group} field="title" />}
            {showGroupFeatureList && <TextLoader data={group} field="feature_list.default" />}
          </div>
        )}
      {group.asset && group_detail_size !== 'no_asset' && (
        <>
          {isMediaStoryEnabled && <VideoAssetLoader asset={group.asset} {...props} />}

          {(!group.asset.video_content || !_isEmpty(imgProps__Mobile)) && (
            <AssetLoader
              className={classnames(
                `group--main-content--asset group--main-content--asset__${group.code ||
                  ''} ${model}`,
                { 'tds-clip-top-right': _has(assetLoaderProps, 'clipTopRight') ? assetLoaderProps.clipTopRight : enableCyberpunk && (assetLoaderProps?.type !== 'bg-image') }
              )}
              asset={assetLoaderProps}
              key={`${group.code}__asset`}
              title={group.code}
            />
          )}
          {group?.asset?.toggle_feature && (
            <div className="group--main-content--feature-action">
              <button class="tds-btn--custom-feature" onClick={() => onFeatureToggle(group?.asset?.toggle_feature)}>{group?.asset?.toggle_label || 'Toggle'}</button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

GroupDetails.propTypes = {
  group: shape({
    asset: shape({
      video_content: arrayOf(shape({})),
    }),
    title: string,
    feature_list: oneOfType([arrayOf(string), shape({})]),
    code: string,
    features: string,
  }),
  className: string,
  showGroupTitle: bool,
  showGroupFeatureList: bool,
  detail_size: string,
  model: string,
  styleProps: shape({}),
  isLayoutMobile: bool,
  imgProps__Mobile: shape({}),
  showGroupVideo: bool,
  isMediaStoryEnabled: bool,
  onFeatureToggle: func.isRequired,
};

GroupDetails.defaultProps = {
  group: null,
  className: '',
  showGroupTitle: true,
  showGroupFeatureList: true,
  detail_size: 'big',
  model: null,
  styleProps: {},
  isLayoutMobile: false,
  imgProps__Mobile: {},
  showGroupVideo: false,
  isMediaStoryEnabled: false,
};
function mapStateToProps(state, ownProps) {
  const { enableCyberpunk } = state.App || {};
  const group = _get(state, `CustomGroups.${ownProps.group}`, {});
  const isLayoutMobile = _get(state, 'App.isLayoutMobile', false);
  const imgProps__Mobile = isLayoutMobile ? getImgPropsForMobile(group) : {};
  const { toggle_label } = group?.asset || {};

  return {
    showGroupFeatureList: ownProps.showGroupFeatureList,
    styleProps: ownProps.styleProps,
    className: ownProps.className,
    group: {
      ...group,
      asset: {
        ...(group?.asset || {}),
        ...(toggle_label ? {
          toggle_label: _get(state, toggle_label, ''),
        } : {}),
      }
    },
    CustomGroups: state.CustomGroups,
    model: state.OMS.oms_params.model,
    showGroupVideo: _get(ownProps, 'showGroupVideo', false),
    isLayoutMobile,
    imgProps__Mobile,
    enableCyberpunk,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onFeatureToggle: props => {
      dispatch(toggleFeatureState(props));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupDetails);
