import { request } from 'utils/requestAgent';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _uniq from 'lodash/uniq';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Calculator, Configurator } from '@web/tesla-rest-ds-services';
import {
  getUiOptionsNotInConfiguration,
  smoothScrollToError,
  getTrafficSource,
  getTrafficSourceHistory,
  getActivitySessionId,
  getInventorySwapPaymentErrorZip,
  setPreferredLocationCookie,
} from 'utils';
import {
  calculateTransportationFee,
  getFinanceType,
  getFuelDistance,
  getFullModelName,
  getLocaleInfo,
  getFilteredDeliveryLocations,
  getDeliveryLocationQueryParams,
  getGAAutoType,
} from 'selectors';
import Analytics from 'analytics';
import {
  ACCOUNT_DETAILS_VALID_FLAG,
  ACCOUNT_DETAILS_CHANGED,
  SAVE_DESIGN_FLAG,
  IDENTIFICATION_TYPE_DETAILS_CHANGED,
  HIDE_BASE_FIELD,
  SHOW_BASE_FIELD,
  REGISTRATION_TYPE_CHANGED,
  TOGGLE_TRIM_SAVINGS_PRICE,
  SHOW_PRICING_DETAILS,
  UPDATE_VEHICLE_DETAIL,
  DELIVERY_DETAILS_CHANGED,
  DELIVERY_DETAILS_VALID_FLAG,
  SET_FORM_ERRORS,
  CLEAR_FORM_ERRORS,
  DELIVERY_SOURCE_TRT,
  LEGAL_CONSENT_VALID_FLAG,
  FIELD_DELIVERY_LATITUDE,
  FIELD_DELIVERY_LONGITUDE,
  SET_ACCOUNT_REGISTRATION_TYPE,
  VIEW_DISCLOSURE,
  LOADER_START,
  STATUS_ERROR,
  STATUS_SUCCESS,
  UPDATE_CALLBACK_DETAIL,
  LOADER_FINISH,
  INVENTORY_DELIVERY_LOCATIONS_CHANGED,
  INVENTORY_DELIVERY_LOCATION_INVALID,
  UPDATE_REQ_CALLBACK_SELECTED_PROVINCE,
  UPDATE_REQ_CALLBACK_SELECTED_CITY,
  UPDATE_REQ_CALLBACK_PROVINCE_LIST,
  UPDATE_REQ_CALLBACK_CITY_LIST,
  HAS_AVAILABLE_INVENTORY_FLAG,
  UPDATE_PROVINCE_ADDRESS,
  UPDATE_DISTRICT_LIST,
  UPDATE_DISTRICT_ADDRESS,
  UPDATE_POSTAL_CODE_LIST,
  SET_VEHICLE_DESIGN,
  NAVIGATION_VIEW_EARLYDELIVERY,
  NAVIGATION_VIEW_PAYMENT,
  RESET_VEHICLE_DESIGN,
  ERROR_DESIGN_UNAVAILABLE,
  ERROR_EARLY_DELIVERY_DESIGN_UNAVAILABLE,
  ERROR_EARLY_PICKUP_UNAVAILABLE,
  ERROR_ONLY_SIMILAR_DESIGN_AVAILABLE,
  ERROR_DELIVERY_LOCATION_CHANGED,
  SET_CUSTOM_DESIGN,
  SET_VERIFIED_PHONE_NUMBER,
  SET_TIME_OF_CLICK_PLACE_ORDER_BUTTON,
  SET_TIME_OF_ORDER_PLACED_SUCCESS,
  UPDATE_DELIVERY_CONTACT,
  SUMMARY_PANEL_TAB_CHANGE,
  UPDATE_INCENTIVES,
  PRICE_CHANGED,
  STATUS_INVALID_ZIP,
} from 'dictionary';
import {
  getGeocodeByLocation,
  setSummaryPanelRegion,
  navigationSelectKey,
  setOption,
  navigateToPayment,
  fetchTaxesAndFees,
  setPaymentOverviewFlag,
} from 'actions';
import { map, filter } from 'rxjs/operators';

export const setAccountDetailsValidFlag = flag => ({
  type: ACCOUNT_DETAILS_VALID_FLAG,
  flag,
});

export const setLegalConsentValidFlag = flag => ({
  type: LEGAL_CONSENT_VALID_FLAG,
  flag,
});

export const setSaveDesignFlag = flag => ({
  type: SAVE_DESIGN_FLAG,
  flag,
});

export const setPricingDetails = flag => ({
  type: SHOW_PRICING_DETAILS,
  flag,
});

export const setAccountRegistrationType = regType => ({
  type: SET_ACCOUNT_REGISTRATION_TYPE,
  regType,
});

export const updateAccountDetails = payload => (dispatch, getState) => {
  const state = getState();
  const extraPayload = {};
  if (_isEmpty(payload.IdentificationType)) {
    const defaultIdentificationType = state?.ReviewDetails?.defaultIdentificationType ?? '';
    extraPayload.IdentificationType = _get(
      state,
      'ReviewDetails.AccountDetail.identificationType',
      defaultIdentificationType
    );
  }
  if (_isEmpty(payload?.AccountType)) {
    extraPayload.AccountType = _get(state,'ReviewDetails.AccountDetail.AccountType');
  }
  dispatch({
    type: ACCOUNT_DETAILS_CHANGED,
    payload: { ...payload, ...extraPayload },
  });
};

export const setDeliveryDetailsValidFlag = flag => ({
  type: DELIVERY_DETAILS_VALID_FLAG,
  flag,
});

export const setVerifiedPhoneNumber = ({ phoneNumber, verifyPhoneCode }) => ({
  type: SET_VERIFIED_PHONE_NUMBER,
  payload: { phoneNumber, verifyPhoneCode },
});

export const setHasAvailableInventory = ({
  available,
  subset,
  latitude,
  longitude,
  postalCode,
  regionCode,
}) => ({
  type: HAS_AVAILABLE_INVENTORY_FLAG,
  available,
  subset,
  latitude,
  longitude,
  postalCode,
  regionCode,
});

export const updateDeliveryDetails = payload => (dispatch, getState) => {
  const { Latitude = null, Longitude = null, transportFee = {} } = payload || {};
  const state = getState();
  const { App = {}, ReviewDetails = {} } = state;
  const { isTransportFeeEnabled = false } = ReviewDetails;
  const { isPickupOnlyEnabled } = App;

  const payloadObj = { ...payload };
  if (!isPickupOnlyEnabled && isTransportFeeEnabled) {
    let transportationFee = transportFee || {};
    if (_isEmpty(transportFee) && Latitude !== null && Longitude !== null) {
      transportationFee = calculateTransportationFee(state, { Latitude, Longitude });
    }
    const { fee = 0, sourceType, distance = 0, trt = {} } = transportationFee;
    const { id } = trt;
    payloadObj.TransportationFee = fee;
    payloadObj.SourceType = sourceType;
    payloadObj.Distance = distance;
    if (sourceType === DELIVERY_SOURCE_TRT) {
      payloadObj.PickupLocation = id;
    }
  }
  dispatch({
    type: DELIVERY_DETAILS_CHANGED,
    payload: payloadObj,
  });
};

export const getGeoLocation = (inputZip = '', props = {}) => (dispatch, getState) => {
  const state = getState();
  const { callTaxesAndFees = false, setPreferredAddress = false } = props || {};
  const { countryCode, isPreferredAddressEnabled = false } = state?.App || {};
  const { region_code } = state?.SummaryPanel || {};
  return getGeocodeByLocation(
    { postal_code: inputZip, country: countryCode },
    (error, response) => {
      if (!error) {
        const {
          latitude = '',
          longitude = '',
          stateCode = '',
          stateProvince = '',
          city = '',
          county = '',
        } = response || {};
        const payload = {
          ...response,
          [FIELD_DELIVERY_LATITUDE]: latitude,
          [FIELD_DELIVERY_LONGITUDE]: longitude,
          PostalCode: inputZip,
          stateCode,
          stateProvince,
          county,
          city,
        };
        dispatch(
          updateDeliveryDetails(payload)
        );
        dispatch(setSummaryPanelRegion(stateCode));
        if (isPreferredAddressEnabled && setPreferredAddress) {
          setPreferredLocationCookie(payload);
        }
        if (callTaxesAndFees) {
          dispatch(fetchTaxesAndFees());
        }
      } else {
        dispatch(
          updateDeliveryDetails({
            error: STATUS_INVALID_ZIP,
            PostalCode: inputZip,
          })
        );
      }
    }
  );
};

export const getGeoInfoByPostalCode = () => (dispatch, getState) => {
  const state = getState();
  const { App, ReviewDetails } = state;
  const { query_params, countryCode, isPostOrderSwap } = App;
  const { isTransportFeeEnabled = false, DeliveryDetails = {}, product = {} } = ReviewDetails;
  const postal = query_params?.postal || '';
  const { PostalCode } = DeliveryDetails || {};
  const postCodeRegister = isPostOrderSwap
    ? product?.orderDetails?.DeliveryAddress?.PostalCode || ''
    : '';

  const postOrderSwapStateProvince = isPostOrderSwap
    ? product?.orderDetails?.DeliveryAddress?.StateProvince || ''
    : '';

  if (postOrderSwapStateProvince) {
    return dispatch(setSummaryPanelRegion(postOrderSwapStateProvince));
  }

  if (
    (!isPostOrderSwap || !postCodeRegister) &&
    (!isTransportFeeEnabled || !postal || !countryCode || PostalCode === postal)
  ) {
    return null;
  }
  const postalCodeSource = isPostOrderSwap ? postCodeRegister : postal;
  return dispatch(getGeoLocation(postalCodeSource));
};

export const updateAccountIDTypeLabel = payload => dispatch => {
  dispatch({
    type: IDENTIFICATION_TYPE_DETAILS_CHANGED,
    IdentificationType: `Review.${payload.value}`,
  });
};

export const hideBaseField = field => ({
  type: HIDE_BASE_FIELD,
  field,
});

export const showBaseField = field => ({
  type: SHOW_BASE_FIELD,
  field,
});

export const setRegistrationType = regType => ({
  type: REGISTRATION_TYPE_CHANGED,
  regType,
});

export const toggleTrimSavingsPrice = flag => ({
  type: TOGGLE_TRIM_SAVINGS_PRICE,
  flag,
});

export const setFormErrors = ({ field, error = 'required' }) => ({
  type: SET_FORM_ERRORS,
  field,
  error,
});

export const clearFormErrors = () => ({
  type: CLEAR_FORM_ERRORS,
});

export const calcPriceForOptions = (options, vehiclePrice, passmatchedCodes = []) => (
  dispatch,
  getState
) => {
  const state = getState();
  const { Location = {}, ReviewDetails = {}, SummaryPanel = {}, Financial = {}, OMS = {} } =
    state ?? {};

  const { regionCode } = Location.components?.summaryPanel ?? {};
  const { RegistrantType = null } = ReviewDetails.RegistrationDetail ?? {};
  const { AccountType } = ReviewDetails.AccountDetail ?? {};

  return Calculator.total(
    {
      Incentives: Financial.fms_incentives,
      Fees: Financial.fms_fees,
      Loan: Financial.fms_loan,
      Lease: Financial.fms_lease,
      Lexicon: OMS.lexicon,
      Configuration: {
        options,
      },
      UnselectedOptions: getUiOptionsNotInConfiguration(state, options),
    },
    {
      ...OMS.oms_params,
      options,
      regionCode: regionCode || SummaryPanel.regionCode,
      vehiclePrice,
      passmatchedCodes,
      selectedIncentives: SummaryPanel.selectedIncentives ?? [],
      financeProductType: getFinanceType(state),
      fuelDist: getFuelDistance(state),
      fuelPrice: SummaryPanel.fuelPrice,
      userLeaseAmount: SummaryPanel.userLeaseAmount,
      customerType: RegistrantType || AccountType,
      licenseStatus: ReviewDetails.product?.data?.RegistrationDetails?.LicenseStatus ?? false,
    }
  );
};

export const calcPriceForNewlySelectedOption = (option, vehiclePrice = null) => (
  dispatch,
  getState
) => {
  const state = getState();

  const matchedCodes = _get(
    state,
    'Pricing.calculatorResult.data.apiResults.price.matchedCodes',
    []
  );
  // We can override doing this in state. For now,
  // Ireland has a dynamic incentive, but we dont want to show it.
  const includePurchasePriceIncentives = _get(
    state,
    'ReviewDetails.includePurchasePriceIncentivesInTrims',
    true
  );
  if (!includePurchasePriceIncentives) {
    return null;
  }
  const { query_params: queryParams = {} } = state?.App || {};
  let { skuValidation = false } = queryParams || {};
  skuValidation = !!skuValidation;
  const configuration = {
    userSelectedOptions: _uniq([].concat(option, state.Configuration.user_selected_options)),
    ignoreRules: ['RequiredUpgrades', 'ExistInWorld', 'Deprecated'],
    set: option,
    skuValidation,
  };

  const { options } = Configurator.setOption(
    {
      Lexicon: state.OMS.lexicon,
      Configuration: { options: state.Configuration.option_codes },
    },
    configuration
  );

  return calcPriceForOptions(options, vehiclePrice, matchedCodes)(dispatch, getState);
};

export const updateVehicleDetail = VehicleDetail => ({
  type: UPDATE_VEHICLE_DETAIL,
  VehicleDetail,
});

export const saveUserViewedDisclosures = payload => ({
  type: VIEW_DISCLOSURE,
  payload,
});

export const updateCallbackDetail = (payload = null, status = null) => ({
  type: UPDATE_CALLBACK_DETAIL,
  data: payload,
  status,
});

export const getCallbackRequest = values => (dispatch, getState) => {
  const state = getState();
  const { locale } = getLocaleInfo(state);
  const routes = _get(state, 'App.routes', {});
  const { inventoryCallback = '/configurator/api/v3/inventory-callback' } = routes || {};
  const modelCode = _get(state, 'OMS.oms_params.model', '');
  const countryCode = _get(state, 'OMS.oms_params.market', '');
  const vinNumber = _get(state, 'ReviewDetails.product.data.VIN', null);
  const modelName = getFullModelName(state);
  const normalizedLocale = (locale || '').replace('_', '-');
  const activitysessionid = getActivitySessionId();
  const isDm = _get(state, 'App.isDm', false);

  const payload = {
    ...values,
    locale: normalizedLocale,
    modelCode,
    countryCode,
    vinNumber,
    modelName,
    trafficSource: getTrafficSource(),
    trafficSourceHistory: getTrafficSourceHistory(),
    activitysessionid,
    isFromApp: isDm,
  };
  dispatch({ type: LOADER_START });
  Analytics.fireTagEvent({
    event: Analytics.event,
    'cfg-type': Analytics.cfgType,
    interaction: 'request-a-callback-submit',
    titleStatus: getGAAutoType(state),
    activitysession_id: activitysessionid,
    isFromApp: isDm,
  });
  request
    .post(inventoryCallback)
    .set('X-Requested-With', 'XMLHttpRequest')
    .set('Accept', 'application/json')
    .send(payload)
    .end(err => {
      const status = err ? STATUS_ERROR : STATUS_SUCCESS;
      dispatch({ type: LOADER_FINISH });
      return dispatch(updateCallbackDetail(payload, status));
    });
};

export const updateDeliveryLocations = payload => dispatch => {

  const transportFee = payload?.selected?.transportFee;
  if (transportFee != null) {
    dispatch(updateDeliveryDetails({ transportFee }));
  }
  return dispatch({
    type: INVENTORY_DELIVERY_LOCATIONS_CHANGED,
    payload,
  });
};

export const setInvalidDeliveryLocation = (zipcode, addressLookupError = false) => (
  dispatch,
  getState
) => {
  const state = getState();
  const { showSinglePage } = state?.App || {};
  const { showPaymentOverview } = state?.Payment || {};

  dispatch({
    type: INVENTORY_DELIVERY_LOCATION_INVALID,
    zipcode,
    addressLookupError,
  });

  if (showSinglePage && showPaymentOverview) {
    dispatch(setPaymentOverviewFlag(false));
  }
};

export const setInventoryDeliveryLocations = (payload, ignoreZip = false) => (
  dispatch,
  getState
) => {
  const state = getState();
  const result = getFilteredDeliveryLocations(state, payload) || {};
  const { city = '' } = result?.selected || {};
  if (result?.registrationError) {
    Analytics.fireTagEvent({
      event: Analytics.event,
      'cfg-type': Analytics.cfgType,
      interaction: 'pick-up-only-message',
    });
  }
  if (ignoreZip) {
    dispatch({
      type: DELIVERY_DETAILS_CHANGED,
      payload: { StateProvince: city },
    });
  }
  return dispatch(updateDeliveryLocations(result));
};

export const getInventoryDeliveryLocations = payload => (dispatch, getState) => {
  const state = getState();
  const { inventoryPickupLocations: url } = state?.App?.routes || {};
  const params = getDeliveryLocationQueryParams(state, payload, true);
  const { postalCode } = payload || {};
  if (params) {
    try {
      request
        .post(url)
        .send(params)
        .end((err, res) => {
          if (err) {
            return dispatch(setInvalidDeliveryLocation(postalCode));
          }
          return dispatch(setInventoryDeliveryLocations(res?.body));
        });
    } catch (err) {
      return dispatch(setInvalidDeliveryLocation(postalCode));
    }
  } else {
    return dispatch(setInvalidDeliveryLocation(postalCode));
  }
};

export const getInventoryDeliveryLocationsByRegion = payload => (dispatch, getState) => {
  const state = getState();
  const { inventoryPickupLocations: url } = state?.App?.routes || {};
  const { region_code } = state?.SummaryPanel || {} 
  const params = getDeliveryLocationQueryParams(state, {stateCode: region_code}, true);
  dispatch(updateDeliveryLocations({ isLoading: true }));
  const { postalCode } = payload || {};
  if (params) {
    try {
      request
        .post(url)
        .send(params)
        .end((err, res) => {
          if (err) {
            return dispatch(setInvalidDeliveryLocation(region_code));
          }
          return dispatch(setInventoryDeliveryLocations(res?.body));
        });
    } catch (err) {
      return dispatch(setInvalidDeliveryLocation(region_code));
    }
  } else {
    return dispatch(setInvalidDeliveryLocation(region_code));
  }
};


export const getDeliveryLocations = (postalCode, { setPreferredAddress = false } = {}) => async (dispatch, getState) => {
  if (!postalCode) {
    return dispatch(setInvalidDeliveryLocation(postalCode));
  }
  const state = getState();
  const { countryCode: country, isPreferredAddressEnabled = false } = state?.App || {};
  const { region_code } = state?.SummaryPanel || {};
  dispatch(updateDeliveryLocations({ isLoading: true }));
  return getGeocodeByLocation({ postal_code: postalCode, country }, (addressErr, address) => {
    const {
      latitude: Latitude,
      longitude: Longitude,
      countryCode: addressCountryCode,
      postalCode: addressPostalCode,
      city,
      stateCode,
    } = address || {};

    // Invalid user entered address or zip code
    const addressLookupError = addressErr ||
      (!Latitude && !Longitude) ||
      addressCountryCode !== country ||
      (!addressPostalCode && !city && !stateCode)

    if (addressLookupError) {
      return dispatch(setInvalidDeliveryLocation(postalCode, addressLookupError));
    }
    const payload = {
      ...address,
      Latitude,
      Longitude,
      PostalCode: postalCode,
    };
    if (isPreferredAddressEnabled && setPreferredAddress && _has(payload, 'stateCode') && _has(payload, 'postalCode')) {
      // Set preferred address cookie if data is valid
      setPreferredLocationCookie(payload);
    }
    // Update delivery address info
    dispatch(
      updateDeliveryDetails(payload)
    );
    if (!stateCode || region_code !== stateCode) {
      dispatch(setSummaryPanelRegion(stateCode));
    }
    // Get delivery locations
    return dispatch(getInventoryDeliveryLocations(address));
  });
};

export const updateInventoryAvailability = payload => ({
  type: payload,
});

export const updateReqCallbackSelectedProvince = payload => ({
  type: UPDATE_REQ_CALLBACK_SELECTED_PROVINCE,
  payload,
});

export const updateReqCallbackSelectedCity = payload => ({
  type: UPDATE_REQ_CALLBACK_SELECTED_CITY,
  payload,
});

export const updateReqCallbackProvinceList = payload => ({
  type: UPDATE_REQ_CALLBACK_PROVINCE_LIST,
  payload,
});

export const updateReqCallbackCityList = payload => ({
  type: UPDATE_REQ_CALLBACK_CITY_LIST,
  payload,
});

export function getRequestCallBackLocations(locale) {
  return (dispatch, getState) => {
    const state = getState();
    const { App = {} } = state;
    const { sibling } = App;
    request
      .get(`${sibling}/cua-api/drive/country-data`)
      .timeout({
        response: 5000,
        deadline: 30000,
      })
      .query({ locale, additional: true })
      .end((error, response) => {
        if (error) return;
        const content = response?.text ? response?.text : '{}';
        const { provinceList = {}, cityList = {} } = JSON.parse(content);
        if (_isEmpty(provinceList)) return;
        dispatch({
          type: UPDATE_REQ_CALLBACK_PROVINCE_LIST,
          payload: provinceList,
        });
        dispatch({
          type: UPDATE_REQ_CALLBACK_CITY_LIST,
          payload: cityList,
        });
      });
  };
}

export const updateProvinceAddress = province => ({
  type: UPDATE_PROVINCE_ADDRESS,
  province,
});

export const updateDistrictAddress = district => ({
  type: UPDATE_DISTRICT_ADDRESS,
  district,
});

export const updateDistrictList = payload => ({
  type: UPDATE_DISTRICT_LIST,
  payload,
});

export const updatePostalCodeList = payload => ({
  type: UPDATE_POSTAL_CODE_LIST,
  payload,
});

export const setVehicleDesign = payload => ({
  type: SET_VEHICLE_DESIGN,
  payload,
});

export const setCustomDesign = () => (dispatch, getState) => {
  const state = getState();
  const { vehicleDesign: { initialDesign = {}, isEarlyDesignSelected } = {} } =
    state.ReviewDetails || {};
  dispatch({
    type: SET_CUSTOM_DESIGN,
  });
  if (isEarlyDesignSelected) {
    const options = initialDesign?.options?.split(',') || [];
    dispatch(setOption({ set: options }, { callValidConfig: true }));
  }
};

export const resetVehicleDesign = (error = null) => dispatch => {
  dispatch(setCustomDesign());
  dispatch({
    type: RESET_VEHICLE_DESIGN,
    error,
  });
  if (error) {
    smoothScrollToError();
  }
};

export const getAlternateVehicleDesigns = (location = {}, props) => async (dispatch, getState) => {
  const state = getState();
  let result;
  let interaction;
  const { alternateMatchApi: url } = state.App?.routes || {};
  const { market, model, language } = state.OMS?.oms_params || {};
  const { section: currentNavKey, clearInventorSwapPaymentError = false } = state.Navigation || {};
  const { latitude, longitude, stateCode } = location || {};
  const {
    vehicleDesign: {
      initialDesign = {},
      earlyVehicleDesigns: alternateDesigns = [],
      swapConfig: { Trt } = {},
      pickupLocations: earlyPickupLocation,
    } = {},
  } = state.ReviewDetails || {};
  const { options, price, lat, lon, regionCode } = initialDesign || {};

  const payload = {
    lat: latitude || lat,
    lon: longitude || lon,
    model,
    language,
    country: market,
    options,
    price,
    regionCode: stateCode || regionCode,
  };

  try {
    const res = await request.post(url).send(payload);
    result = { data: res?.body };
  } catch (err) {
    result = { data: null, err };
  }

  if (result) {
    const { ExactMatch = null, ApproximateMatch = [] } = result?.data || {};
    const { Trt: trtId, OptionCodeList = '' } = ExactMatch || {};
    const hasSwap = !!ApproximateMatch?.length;
    const hasEarlyPickup = Object.keys(ExactMatch || {})?.length;
    let pickupLocations = trtId ? ExactMatch : null;
    let error = null;
    let navKey =
      hasSwap || pickupLocations ? NAVIGATION_VIEW_EARLYDELIVERY : NAVIGATION_VIEW_PAYMENT;
    if (currentNavKey === NAVIGATION_VIEW_EARLYDELIVERY) {
      navKey = currentNavKey;
      if (hasEarlyPickup && !pickupLocations) {
        pickupLocations = trtId ? ExactMatch : null;
        interaction = 'delivery-zip-change-show-earlier-delivery';
      }
      if (hasSwap && !alternateDesigns?.length) {
        error = ERROR_ONLY_SIMILAR_DESIGN_AVAILABLE;
        interaction = 'delivery-zip-change-show-swap';
      }
      if (!hasSwap && !pickupLocations) {
        navKey = NAVIGATION_VIEW_PAYMENT;
        error = ERROR_EARLY_DELIVERY_DESIGN_UNAVAILABLE;
        interaction = 'delivery-zip-change-no-available-inventory';
      }
      if (props?.isVehicleTaken) {
        interaction = 'configuration-not-available';
        if (hasSwap && alternateDesigns?.length) {
          error = ERROR_DESIGN_UNAVAILABLE;
        } else if (hasEarlyPickup && earlyPickupLocation) {
          const isLocationChanged = Trt !== pickupLocations?.Trt;
          error = isLocationChanged ? ERROR_DELIVERY_LOCATION_CHANGED : null;
          navKey = isLocationChanged ? currentNavKey : NAVIGATION_VIEW_PAYMENT;
          interaction = 'delivery-location-changed';
        } else {
          navKey = NAVIGATION_VIEW_PAYMENT;
          error = ERROR_EARLY_DELIVERY_DESIGN_UNAVAILABLE;
          interaction = 'no-available-inventory';
        }
      }
    }

    if (error) {
      Analytics.inventorySwapError(state, interaction);
    }

    if (getInventorySwapPaymentErrorZip()) {
      dispatch({
        type: LOADER_FINISH,
      });
      if (!clearInventorSwapPaymentError) {
        error = null;
      }
    }

    dispatch(
      setVehicleDesign({
        earlyVehicleDesigns: ApproximateMatch || [],
        initialDesign: { ...initialDesign, ...payload },
        error,
        selectedConfig: '',
        pickupLocations,
        swapConfig: pickupLocations || {},
        isEarlyDesignSelected: !!pickupLocations,
        interactionTypeForSwap: hasSwap
          ? 'inventory-swap'
          : pickupLocations
          ? 'earlier-delivery'
          : null,
        totalSwapConfig: ApproximateMatch?.length,
      })
    );

    if (pickupLocations && OptionCodeList) {
      const options = OptionCodeList.split(',') || [];
      dispatch(setOption({ set: options }, { callValidConfig: true }));
    }

    if (error) {
      smoothScrollToError();
    }

    return dispatch(navigationSelectKey({ key: navKey }));
  }
};

export const setInvalidPickupLocation = () => (dispatch, getState) => {
  const state = getState();
  const { section } = state.Navigation || {};
  if (section === 'overview') {
    return dispatch(navigateToPayment());
  }
  Analytics.inventorySwapError(state, 'configuration-not-available-payment-page');
  return dispatch(resetVehicleDesign(ERROR_EARLY_DELIVERY_DESIGN_UNAVAILABLE));
};

export const getAlternateDesigns = (PostalCode = null) => async (dispatch, getState) => {
  const state = getState();
  const { countryCode: country, isPreferredAddressEnabled = false } = state?.App || {};
  const { PostalCode: deliveryZip, Latitude: latitude, Longitude: longitude, stateCode: region } =
    state?.ReviewDetails?.DeliveryDetails || {};
  const { region_code } = state?.SummaryPanel || {};

  const stateCode = region || region_code;
  const location = { latitude, longitude, stateCode };
  const userZipCode = PostalCode || deliveryZip;

  if (!userZipCode) {
    return dispatch(navigateToPayment());
  }

  if (latitude && longitude && deliveryZip === userZipCode) {
    return dispatch(getAlternateVehicleDesigns(location));
  }
  if (!PostalCode) {
    return dispatch(navigateToPayment());
  }
  return getGeocodeByLocation({ postal_code: PostalCode, country }, (addressErr, address) => {
    const { latitude: Latitude, longitude: Longitude, stateCode } = address || {};
    const payloadObj = {
      ...address,
      Latitude,
      Longitude,
      PostalCode,
    };
    dispatch(
      updateDeliveryDetails(payloadObj)
    );
    if (isPreferredAddressEnabled) {
      // set preferred address cookie on swap
      setPreferredLocationCookie(payloadObj);
    }
    if (region_code !== stateCode && stateCode) {
      dispatch(setSummaryPanelRegion(stateCode));
    }
    // Invalid user entered address or zip code
    if (addressErr || !Latitude || !Longitude) {
      return dispatch(setInvalidPickupLocation());
    }
    // Get earlier vehice designs
    return dispatch(getAlternateVehicleDesigns(address));
  });
};

export const fetchAlternateVehicleDesigns = zipCode => dispatch => {
  dispatch(setCustomDesign());
  if (zipCode) {
    return dispatch(getAlternateDesigns(zipCode));
  }
  return dispatch(getAlternateVehicleDesigns(null, { isVehicleTaken: true }));
};

export const checkVehicleAvailability = () => async (dispatch, getState) => {
  const state = getState();
  const { countryCode: country, routes: { getVehicleAvailabilityApi: url = '' } = {} } =
    state?.App || {};
  const {
    vehicleDesign: {
      swapConfig: { Public_Hash: hash = '', Vrl: vrl = 0 } = {},
      isEarlyDesignSelected,
      error,
    } = {},
  } = state?.ReviewDetails || {};

  let result = !isEarlyDesignSelected ? { found: true } : null;
  if (isEarlyDesignSelected) {
    try {
      const res = await request.post(url).send({ hash, country, vrl });
      result = { found: res?.body?.match_found };
    } catch (err) {
      result = { found: false, err };
    }
  }

  if (result) {
    const { found } = result || {};
    if (found) {
      error ? dispatch(setVehicleDesign({ error: null })) : null;
      return dispatch(navigateToPayment());
    }
    return dispatch(fetchAlternateVehicleDesigns());
  }
};

export const setTimeOfClickPlaceOrderButton = timestamp => ({
  type: SET_TIME_OF_CLICK_PLACE_ORDER_BUTTON,
  timestamp,
});

export const setTimeOfOrderPlacedSuccess = timestamp => ({
  type: SET_TIME_OF_ORDER_PLACED_SUCCESS,
  timestamp,
});

export const updateDeliveryContact = payload => ({
  type: UPDATE_DELIVERY_CONTACT,
  payload,
});

export const updateSavingsToggleForTrims = (action$, state$) =>
  action$.pipe(
    filter(action => [PRICE_CHANGED].includes(action.type)),
    map(action => dispatch => {
      const state = state$.value;
      const { userSelectedIncentives, incentives: { current = {} } = {} } = state?.Financial || {};
      if (!userSelectedIncentives?.length) {
        return;
      }
      const { showAfterSavingsPriceForTrims } = state?.ReviewDetails || {};
      const selected = !_isEmpty(current) ? Object.keys(current)?.filter(x => !current?.[x]?.[0]?.isPermanent) : [];
      if (selected) {
        return dispatch(toggleTrimSavingsPrice(!!selected?.length));
      } else if (showAfterSavingsPriceForTrims) {
        return dispatch(toggleTrimSavingsPrice(false));
      }
    })
  );
