import React, { useRef, useEffect } from 'react';
import classnames from 'classnames';
import { i18n, htmlToReact, getInventorySwapPaymentErrorZip } from 'utils';
import { NAVIGATION_VIEW_EARLYDELIVERY } from 'dictionary';
import { Button } from '@tesla/design-system-react';
import { bool, func, shape, string, arrayOf, number, array } from 'prop-types';
import { Form, OptionGroup, Option } from '@tesla/informed-tds';
import { StatusMessage } from '@tesla/design-system-react';
import Analytics from 'analytics';
import VehicleDetails from './VehicleDetails';
import { validatePostalCode } from '@tesla/intl-address';
import IncentivesToggle from '../GroupComponents/IncentivesToggle';
import FinanceToggle from '../GroupComponents/FinanceToggle';
import FinanceDisclaimer from '../GroupComponents/FinanceDisclaimer';

const InventorySwapTemplate = ({
  classes = '',
  isMobile,
  customDesign = {},
  reviewYourOrder,
  isInventorySwapEnabled,
  earlierDesigns,
  initialValues,
  setOption,
  updateVehicleDesign,
  error,
  pickupLocations,
  zipCode,
  openUserZipModal,
  getAlternateDesigns,
  selectedConfig,
  showPickupLocation,
  earlyVehicleDesigns,
  regionName,
  pickupLocation,
  totalSwapConfig,
  isEarlyOptionSelected,
  interactionTypeForSwap,
  initialDesign,
  savedConfig,
  showLoader,
  clearInventorSwapPaymentError,
  market,
  isTablet,
  displayFinanceToggleOnSwap,
}) => {
  const formApiRef = useRef();
  const inventorySwapPaymentErrorZip = getInventorySwapPaymentErrorZip();

  const fireAnalytics = value => {
    Analytics.fireTagEvent({
      event: Analytics.event,
      'cfg-type': Analytics.cfgType,
      ...(totalSwapConfig ? { 'inventorySwap-configuration': totalSwapConfig } : {}),
      interaction: `${interactionTypeForSwap}:${value}`,
    });
  };

  useEffect(() => {
    if (selectedConfig || pickupLocation) {
      formApiRef?.current?.reset();
    }
  }, [selectedConfig, pickupLocation]);

  useEffect(() => {
    if (
      inventorySwapPaymentErrorZip &&
      !initialDesign?.options &&
      savedConfig?.initialDesign?.price
    ) {
      const isValidZip = validatePostalCode(inventorySwapPaymentErrorZip, { countryCode: market });
      if (isValidZip) {
        showLoader();
        updateVehicleDesign({ ...savedConfig });
        getAlternateDesigns(inventorySwapPaymentErrorZip);
      }
    }
  }, [initialDesign?.options, savedConfig?.initialDesign?.price]);

  useEffect(() => {
    const interaction = totalSwapConfig ? 'show-inventory-swap' : 'show-earlier-delivery';
    fireAnalytics(interaction);
  }, []);

  if (!isInventorySwapEnabled) {
    return null;
  }

  const onDesignChange = ({ value }) => {
    const options = value.split(':');
    const optionArr = options[1]?.split(',') || [];
    const isEarlyDesignSelected = !!(options?.[1] !== customDesign?.optionCodes);
    const interaction = isEarlyDesignSelected ? 'click-similar-configuration' : 'click-custom';
    fireAnalytics(interaction);
    updateVehicleDesign({
      selectedConfig: value,
      isEarlyDesignSelected,
      swapConfig: earlyVehicleDesigns?.find(x => x?.Public_Hash === options[0]) || {},
      error: null,
    });
    setOption(optionArr, { callValidConfig: true });
  };

  const onLocationUpdate = ({ value }) => {
    const optionList = pickupLocations?.OptionCodeList || initialDesign?.options || '';
    const optionsArr = optionList?.split(',') || [];
    const isEarlyDesignSelected = !!(value !== 'other');
    const interaction = isEarlyDesignSelected ? 'click-available-now-inventory' : 'click-custom';
    fireAnalytics(interaction);
    updateVehicleDesign({
      swapConfig: isEarlyDesignSelected ? pickupLocations : {},
      isEarlyDesignSelected,
    });
    setOption(optionsArr, { callValidConfig: true });
  };

  const onSubmit = () => {
    fireAnalytics(`review-your-order-${isEarlyOptionSelected ? 'inventory' : 'custom'}`);
    reviewYourOrder();
  };

  const deliveryZipDisclaimer = i18n('EarlyDelivery.delivery_zip_disclaimer', {
    LOCATION: regionName,
  });

  return (
    <>
      <div
        className={classnames(
          'tds--vertical_padding',
          {
            'option-widget--container': isMobile,
          },
          { [classes]: classes }
        )}
      >
        <If condition={inventorySwapPaymentErrorZip && !clearInventorSwapPaymentError}>
          <StatusMessage type="error" body={i18n('Review.errors__PAYMENT_FAILED')} />
        </If>
        <If condition={!error}>
          <h2
            className={classnames(
              'tds-text--center tds-o-padding_bottom-1x early-delivery--title',
              { 'cf-h2-title': !isMobile },
              { 'tds--padding_top-8': isMobile }
            )}
          >
            {pickupLocations ? i18n('EarlyDelivery.locations_title') : i18n('EarlyDelivery.title')}
          </h2>
        </If>
        <div className="tds-text--center">
          <button
            onClick={() => {
              fireAnalytics('change-delivery-location');
              openUserZipModal({
                title: i18n('DeliveryLocation.title'),
                onSubmit: value => {
                  fireAnalytics('confirm-delivery-location');
                  getAlternateDesigns(value);
                },
              });
            }}
            data-id="delivery-postal-code-edit-link"
            className="tds-text--medium tds-text--contrast-high"
          >
            {htmlToReact(
              i18n('EarlyDelivery.delivery_options', {
                ZIP_CODE: zipCode,
              })
            )}
          </button>
          {deliveryZipDisclaimer !== 'EarlyDelivery.delivery_zip_disclaimer' && (
            <p className="tds--vertical_padding--small">{deliveryZipDisclaimer}</p>
          )}
        </div>
        <If condition={displayFinanceToggleOnSwap && earlierDesigns?.length}>
          <FinanceToggle tabsSource={NAVIGATION_VIEW_EARLYDELIVERY} />
          <IncentivesToggle classes="tds--vertical_padding"/>
        </If>
        <Form
          formApiRef={formApiRef}
          initialValues={initialValues}
          onSubmit={onSubmit}
          className="early-delivery--section"
        >
          <Choose>
            <When condition={showPickupLocation}>
              <OptionGroup
                name="pickupLocation"
                id="pickupLocation"
                onChange={onLocationUpdate}
                className="tds-o-padding_bottom-24"
              >
                {pickupLocations && (
                  <Option
                    value={pickupLocations?.VrlCustomName}
                    label={
                      <VehicleDetails
                        {...{
                          showPickupLocation,
                          ...(pickupLocations || {}),
                          ...(!pickupLocations?.Discount
                            ? {
                                Price: 0,
                              }
                            : {}),
                        }}
                      />
                    }
                    className="tds--horizontal_padding"
                  ></Option>
                )}
                <p className="tds-text--h6 tds-text--contrast-low tds--vertical_padding-bottom--1x">
                  {i18n('EarlyDelivery.est_availability')}
                </p>
                <Option
                  value="other"
                  label={
                    <VehicleDetails
                      {...{
                        showPickupLocation,
                        Price: pickupLocations?.Discount ? pickupLocations?.TotalPrice : 0,
                      }}
                      className="tds--horizontal_padding"
                    />
                  }
                ></Option>
              </OptionGroup>
            </When>
            <Otherwise>
              <OptionGroup
                name="vehicleConfig"
                label={i18n(`customDesign.title`, { MODEL_NAME: customDesign?.trim })}
                className="tds-o-padding_bottom-24"
                onChange={onDesignChange}
              >
                <Option
                  value={`customDesign:${customDesign?.optionCodes}`}
                  label={<VehicleDetails {...customDesign} />}
                  className="tds--horizontal_padding"
                ></Option>
                {/* earlierDesign */}
                <If condition={earlierDesigns?.length}>
                  <p className="tds-text--h6 tds-text--contrast-low tds--vertical_padding-bottom--1x">
                    {i18n('EarlyDelivery.similar_design_title')}
                  </p>
                  {earlierDesigns.map(design => {
                    return (
                      <Option
                        value={`${design?.id}:${design?.optionCodes}`}
                        label={<VehicleDetails {...design} />}
                        key={`earlierDesign:${design?.optionCodes}`}
                        className="tds--horizontal_padding"
                      ></Option>
                    );
                  })}
                  <If condition={displayFinanceToggleOnSwap}>
                    <FinanceDisclaimer />
                  </If>
                </If>
              </OptionGroup>
            </Otherwise>
          </Choose>
          {/* customDesign */}
          <div
            className={classnames('tds--align_center', {
              'coin--fixed-footer': isMobile || isTablet,
            })}
          >
            <Button
              className="tds-btn tds-btn--primary tds-btn--large tds-btn--width-full"
              type="submit"
              data-id="review-your-order-button"
            >
              {i18n('Enterprise.review_your_order')}
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

InventorySwapTemplate.defaultProps = {
  classes: '',
  isMobile: false,
  customDesign: {},
  isInventorySwapEnabled: false,
  earlierDesigns: {},
  initialValues: {},
  error: '',
  pickupLocations: null,
  zipCode: '',
  selectedConfig: '',
  showPickupLocation: false,
  earlyVehicleDesigns: [],
  regionName: '',
  pickupLocation: 'other',
  totalSwapConfig: 0,
  isEarlyOptionSelected: false,
  savedConfig: {},
  clearInventorSwapPaymentError: false,
  updatePickupLocation: () => {},
  isTablet: false,
  displayFinanceToggleOnSwap: false,
};

InventorySwapTemplate.propTypes = {
  classes: string,
  isMobile: bool,
  customDesign: shape({}),
  reviewYourOrder: func.isRequired,
  isInventorySwapEnabled: bool,
  earlierDesigns: array,
  initialValues: shape({}),
  setOption: func.isRequired,
  updateVehicleDesign: func.isRequired,
  error: string,
  pickupLocations: arrayOf(shape({})),
  zipCode: string,
  openUserZipModal: func.isRequired,
  getAlternateDesigns: func.isRequired,
  selectedConfig: string,
  updatePickupLocation: func,
  showPickupLocation: bool,
  earlyVehicleDesigns: arrayOf(shape({})),
  regionName: string,
  pickupLocation: string,
  totalSwapConfig: number,
  isEarlyOptionSelected: bool,
  savedConfig: shape({}),
  clearInventorSwapPaymentError: bool,
  isTablet: bool,
  displayFinanceToggleOnSwap: bool,
};

export default InventorySwapTemplate;
