import { shape, string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { i18n } from 'utils';

const PriceValidDisclaimer = ({ region, product }) => {
  const { condition } = product || {};

  const priceDescription = i18n('Inventory.common.price_valid_disclaimer.disclaimer', null, null, {
    specificOverride: [region, condition],
    returnNullWhenEmpty: true,
    specificOverrideOperator: 'OR',
  });
  return priceDescription ? <span className="tds-text--caption">{priceDescription}</span> : null;
};

PriceValidDisclaimer.propTypes = {
  region: string,
  product: shape({}),
};

PriceValidDisclaimer.defaultProps = {
  region: '',
  product: {},
};

function mapStateToProps(state) {
  const { ReviewDetails } = state;
  const { DeliveryDetails, product } = ReviewDetails;
  return {
    region: DeliveryDetails?.stateCode,
    product,
  };
}

export default connect(mapStateToProps)(PriceValidDisclaimer);
