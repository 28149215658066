import {
  FINANCIAL_MODAL_STANDALONE,
  FINANCIAL_MODAL_CN,
  FIN_MODAL_CUSTOMIZE,
  MODAL_MEDIUM,
  FinanceTypes,
} from 'dictionary';
import { bool, number, shape, string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import {
  getFinanceTabs,
  getSelectedFinanceProduct,
  getPricingSummary,
  getVATAmount,
  hideFinanceModal,
  getExtraPricingContextAmounts,
  getTradeInType,
  getSCTTax,
} from 'selectors';
import { i18n } from 'utils';
import FinanceDisclaimer from '../../GroupComponents/FinanceDisclaimer';
import ModalTrigger from '../../ModalTrigger';
import PricingDisclaimers from './PricingDisclaimers';
import BannerModalTrigger from './BannerModalTrigger';
import { formatCurrency } from '@tesla/coin-common-components';
import { getFinanceType, getPrice } from '../../../common/selectors';
import BriefCalculatedResult from './BriefCalculatedResult';
import classNames from 'classnames';
import _get from 'lodash/get';
import {
  WEB_CONFIGURATOR_PAYMENT_FINANCING_CALCULATOR,
  GIO_TAG_ENABLE,
} from '../../../common/gioStatistics';
import { isGrayMarket } from '@tesla/markets-vehicle';

const FinanceTabs = ({
  incentives,
  paymentLabel,
  formattedPurchasePrice,
  formattedMonthlyPaymentPrice,
  formattedTotalPriceWithoutIncentives,
  financeProductId,
  vatDisclaimer,
  reviewRefactor,
  financeType,
  hidePaymentModalTrigger,
  tradeInTypeDisclaimer,
  isGrayMarket,
  sctTax,
}) => {
  const componentName = reviewRefactor ? FINANCIAL_MODAL_CN : FINANCIAL_MODAL_STANDALONE;
  const exchangeRateDisclaimer = i18n('Incentives.extraIncentives.exchangeRate', null, null, {
    returnNullWhenEmpty: true,
  });
  const taxExcludedDisclaimer = i18n('SummaryPanel.disclaimers.taxesAndFeesExcluded', null, null, {
    returnNullWhenEmpty: true,
  });
  return (
    <div className="tab-content">
      <div className="pricing-container tds-o-padding_bottom-24">
        <div
          className={classNames('price-block', {
            'tds-o-flex-direction--column tds-flex-gap--8': !reviewRefactor,
          })}
        >
          <Choose>
            <When condition={reviewRefactor}>
              <div className="finance-section--wrapper tds-o-padding_bottom-8">
                <ul className="tds-list">
                  <li
                    className={cx('tds-list-item', {
                      'tds-text--h4': financeType === FinanceTypes.CASH,
                    })}
                  >
                    <span className="left tds-text_color--10 tds-o-padding_right-8">
                      {i18n('common.purchasePrice')}
                    </span>
                    <span className="tds-text--end tds-text_color--10">
                      {formattedTotalPriceWithoutIncentives}
                    </span>
                  </li>
                  <If condition={financeType !== FinanceTypes.CASH}>
                    <li className="tds-list-item tds-text--h4">
                      <span className="left tds-text--medium tds-text_color--10 tds-o-padding_right-8">
                        {paymentLabel}
                      </span>
                      <span className="tds-text--end tds-text--medium tds-text_color--10">
                        {formattedMonthlyPaymentPrice}
                      </span>
                    </li>
                  </If>
                  <If condition={incentives?.key}>
                    <li className="tds-list-item" data-id="tax-credit-line-item">
                      <span className="left tds-text--caption tds-o-padding_right-8">
                        <BannerModalTrigger
                          contentSource={incentives?.key}
                          label={incentives?.label}
                        />
                      </span>
                      <span className="tds-text--caption">{incentives?.formattedPrice}</span>
                    </li>
                  </If>
                </ul>
                <PricingDisclaimers />
                <If condition={vatDisclaimer && !financeProductId}>
                  <p className="tds-text--caption">{vatDisclaimer}</p>
                </If>
              </div>
              <If condition={financeType !== FinanceTypes.CASH}>
                <BriefCalculatedResult financeType={financeType} />
              </If>
            </When>
            <Otherwise>
              <div className="finance-section--wrapper">
                <ul className="tds-list">
                  <li className="tds-list-item tds-text--h4">
                    <span className="left tds-text--medium tds-text_color--10 tds-o-padding_right-8">
                      {paymentLabel}
                    </span>
                    <span className="tds-text--end tds-text--medium tds-text_color--10">
                      {formattedPurchasePrice}
                    </span>
                  </li>
                  <If condition={incentives?.key && incentives?.label}>
                    <li
                      className="tds-list-item tds-o-margin_bottom"
                      data-id="tax-credit-line-item"
                    >
                      <span className="left tds-text--caption tds-o-padding_right-8">
                        <BannerModalTrigger
                          contentSource={incentives?.key}
                          label={incentives?.label}
                        />
                      </span>
                      <span className="tds-text--caption">{incentives?.formattedPrice}</span>
                    </li>
                  </If>
                </ul>
                <PricingDisclaimers />
                <If condition={!financeProductId}>
                  <If condition={vatDisclaimer}>
                    <p className="tds-text--caption">{vatDisclaimer}</p>
                  </If>
                  <If condition={sctTax?.total}>
                    <p className="tds-text--caption">
                      {i18n('SummaryPanel.disclaimers.sctTaxIncluded', {
                        SCT_TAX: formatCurrency(sctTax?.total),
                      })}
                    </p>
                  </If>
                  <If condition={tradeInTypeDisclaimer}>
                    <div className="tds-text--caption">{tradeInTypeDisclaimer}</div>
                  </If>
                  <If condition={isGrayMarket && taxExcludedDisclaimer}>
                    <div className="tds-text--caption">{taxExcludedDisclaimer}</div>
                  </If>
                </If>
              </div>
              <FinanceDisclaimer className="tds-text--caption finance-disclaimer--condensed" />
              <If condition={exchangeRateDisclaimer}>
                <p className="tds-text--caption">{exchangeRateDisclaimer}</p>
              </If>
            </Otherwise>
          </Choose>

          <If condition={!hidePaymentModalTrigger}>
            <ModalTrigger
              analyticsInteraction={FIN_MODAL_CUSTOMIZE}
              data-gio-eventname={WEB_CONFIGURATOR_PAYMENT_FINANCING_CALCULATOR}
              data-gio-track={GIO_TAG_ENABLE}
              options={{
                props: {
                  componentName: componentName,
                  props: {
                    genericWrapper: true,
                    size: MODAL_MEDIUM,
                    classes: 'tds-link',
                    isMega: true,
                  },
                },
              }}
            >
              <p className="tds-text--caption">{i18n('Review.explore_financing')}</p>
            </ModalTrigger>
          </If>
          <If condition={reviewRefactor}>
            <FinanceDisclaimer
              className="tds-text--caption finance-disclaimer--condensed"
              reviewRefactor={reviewRefactor}
              financeType={financeType}
            />
          </If>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const financeTabs = getFinanceTabs(state);
  const selectedTab = getSelectedFinanceProduct(state);
  const financeType = getFinanceType(state);
  const { financeProductId } = financeTabs.find(tab => tab?.id === selectedTab) || {};
  const {
    ReviewDetails: { showVAT = false, showMyVAT = false, reviewRefactor = false },
  } = state;
  const pricingSummaryObj = getPricingSummary(state);
  const { model_code: modelCode = '' } = state?.Configuration || {};

  let paymentLabel = pricingSummaryObj?.isMonthly
    ? i18n('SummaryPanel.est_payment_label__finplat', null, null, {
        specificOverride: financeProductId,
      })
    : i18n('Review.est_purchase_price');

  const extraPricing = getExtraPricingContextAmounts(state) || [];
  const extraVat = extraPricing[0]
    ? {
        [`VAT_AMOUNT_${extraPricing[0].currency}`]: formatCurrency(extraPricing[0].vatAmount ?? 0, {
          useDynamicRounding: true,
          currency: extraPricing[0].currency,
        }),
      }
    : {};
  let vatDisclaimer =
    showVAT &&
    i18n('SummaryPanel.disclaimers.vatAmount', {
      VAT_AMOUNT: formatCurrency(getVATAmount(state)),
      ...extraVat,
    });
  if (showMyVAT && state?.App?.isCoinReloaded) {
    if (modelCode === 'm3' || modelCode === 'my') {
      vatDisclaimer = i18n('SummaryPanel.disclaimers.myVatAmount', null, null, {
        returnNullWhenEmpty: true,
      });
    } else if (modelCode === 'ms' || modelCode === 'mx') {
      vatDisclaimer = i18n('SummaryPanel.disclaimers.myVatAmountSX', null, null, {
        returnNullWhenEmpty: true,
      });
    } else {
      vatDisclaimer = '';
    }
  }

  const formattedTotalPriceWithoutIncentives = formatCurrency(_get(state, 'Pricing.total', 0));
  let formattedMonthlyPaymentPrice;
  if (reviewRefactor) {
    if (financeType === FinanceTypes.LEASE) {
      paymentLabel = i18n('SummaryPanel.payment__lease');
      formattedMonthlyPaymentPrice = getPrice(state, 'lease');
    }
    if (financeType === FinanceTypes.LOAN) {
      paymentLabel = i18n('SummaryPanel.payment__loan');
      formattedMonthlyPaymentPrice = getPrice(state, 'loan');
    }
  }

  const tradeInType = getTradeInType(state);
  const sctTax = getSCTTax(state);

  return {
    paymentLabel,
    financeProductId,
    vatDisclaimer,
    financeType,
    reviewRefactor,
    formattedMonthlyPaymentPrice,
    formattedTotalPriceWithoutIncentives,
    hidePaymentModalTrigger: hideFinanceModal(state),
    tradeInTypeDisclaimer: i18n(`Inventory.vehicleDetails.${tradeInType}`, null, null, {
      returnNullWhenEmpty: true,
    }),
    isGrayMarket: isGrayMarket(state?.App?.countryCode),
    sctTax,
    ...pricingSummaryObj,
  };
}

FinanceTabs.propTypes = {
  paymentLabel: string,
  financeProductId: string,
  formattedPurchasePrice: string.isRequired,
  formattedMonthlyPaymentPrice: string,
  formattedTotalPriceWithoutIncentives: string,
  incentives: shape({
    price: number,
    key: string,
    label: string,
  }),
  vatDisclaimer: string,
  financeType: string,
  reviewRefactor: bool,
  hidePaymentModalTrigger: bool,
  isGrayMarket: bool,
  sctTax: shape({}),
};

FinanceTabs.defaultProps = {
  paymentLabel: '',
  financeProductId: null,
  monthlyPayment: {
    price: 0,
    key: '',
    label: '',
  },
  vatDisclaimer: '',
  financeType: FinanceTypes.CASH,
  reviewRefactor: false,
  hidePaymentModalTrigger: false,
  isGrayMarket: false,
  sctTax: {},
};

export default connect(mapStateToProps)(FinanceTabs);
