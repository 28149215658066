import _get from 'lodash/get';
import _difference from 'lodash/difference';
import { validatePostalCode } from '@tesla/intl-address';
import { request } from 'utils/requestAgent';
import {
  SHORT_URL_SUCCESS,
  COPY_URL,
  SHARE_DESIGN_REQUEST,
  SHARE_DESIGN_SUCCESS,
  SHARE_DESIGN_RESET,
  SHARE_DESIGN_FAIL,
  CLOSE_SHARE_FAIL_ALERT,
  SAVE_DESIGN_CLOSE,
  MODEL_FULL_NAME,
  LOADER_START,
  LOADER_FINISH,
  SAVE_DESIGN_GET_UPDATES,
  GROUP_CONNECTIVITY,
  SAVE_DESIGN_METHOD_SMS,
  SAVE_DESIGN_METHOD_EMAIL
} from 'dictionary';

import { copyToClipboard, getShareUrl, getTrafficSource, getTrafficSourceHistory, getActivitySessionId } from 'utils';

import { getDepositAmount, getSelectedOptions, getLocaleInfo, getModel } from 'selectors';
import { formatCurrency } from '@tesla/coin-common-components';

/**
 * Returns object to dispatch when short url service returns successfully
 * @param  {String} shortUrl [Short URL with encoded configuration information]
 * @param  {Array} options [Option codes that configuration had at time that shortUrl was created]
 * @return {Object}
 */
function shortUrlSuccess(shortUrl, options) {
  return {
    type: SHORT_URL_SUCCESS,
    options,
    shortUrl,
  };
}

export const getShortUrl = () => (dispatch, getState) => {
  const state = getState();
  const lastSavedOptions = _get(state, 'SaveDesign.options', []);
  const currentOptions = _get(state, 'Configuration.configuration.options', []);
  const shortUrl = _get(state, 'SaveDesign.shortUrl');
  const shareUrl = getShareUrl(state);
  const options = getSelectedOptions({ state }).optionCodes.join(',');
  const modelCode = _get(state, 'OMS.oms_params.model');
  const market = _get(state, 'OMS.oms_params.market');
  const language = _get(state, 'OMS.oms_params.language');
  const modelName = MODEL_FULL_NAME[modelCode];
  // if options have not changed
  if (shortUrl && _difference(currentOptions, lastSavedOptions).length === 0) {
    return dispatch(shortUrlSuccess(shortUrl, currentOptions));
  }
  const { routes = {} } = window?.tesla;
  const {
    getShortUrl = _get(state, 'App.env.findMyTesla.shortUrl', '/configurator/api/get_short_url'),
  } = routes;

  request
    .get(getShortUrl)
    .type('form')
    .query({ modelName, modelCode, options, market, language })
    .end((err, res) => {
      if (err) {
        dispatch(shortUrlSuccess(shareUrl, currentOptions));
        return;
      }
      dispatch(shortUrlSuccess(res.body.short_url, currentOptions));
    });
  return null;
};

/**
 * [saveDesign description]
 * @param  {String} options.email [description]
 * @return {Function}
 */
export const shareDesign = ({ method, payload = {} }) => (dispatch, getState) => {
  const state = getState();
  const { locale } = getLocaleInfo(state);
  const referralCode = _get(state, 'ApplicationFlow.referral.referralCode', null);
  const { optionCodes, optionCodeNames } = getSelectedOptions({
    state,
    exclude: [GROUP_CONNECTIVITY],
  });
  const assetView = _get(state, 'ReviewDetails.asset.view');
  const depositAmount = formatCurrency(getDepositAmount(state));
  const modelCode = _get(state, 'OMS.oms_params.model');
  const postalCode = _get(state, 'ReviewDetails.DeliveryDetails.PostalCode') || _get(state, 'Location.sources.geoIp.postalCode');
  const countryCode = _get(state, 'OMS.oms_params.market');
  const modelName = MODEL_FULL_NAME[modelCode];
  const shareUrl = _get(state, 'SaveDesign.shortUrl');

  let email, firstName, lastName, getUpdates, phoneNumber, verifyPhoneCode;
  if (method === SAVE_DESIGN_METHOD_EMAIL) {
    email = payload?.email;
    firstName = payload?.firstName;
    lastName = payload?.lastName;
    phoneNumber = payload?.phoneNumber; 
    getUpdates = payload?.getUpdates;
    dispatch({
      email,
      firstName,
      lastName,
      phoneNumber,
      method,
      type: SHARE_DESIGN_REQUEST,
    });
  }

  if (method === SAVE_DESIGN_METHOD_SMS) {
    phoneNumber = payload?.phoneNumber;
    verifyPhoneCode = payload?.verifyPhoneCode;
    dispatch({
      phoneNumber,
      method,
      type: SHARE_DESIGN_REQUEST,
    });
  }

  dispatch({ type: LOADER_START });

  // only share method at present (might implement social shares in future)
  if (method === SAVE_DESIGN_METHOD_EMAIL || method === SAVE_DESIGN_METHOD_SMS) {
    const { routes = {} } = window?.tesla;
    const {
      saveDesign = _get(
        state,
        'App.env.findMyTesla.saveDesignLite',
        '/configurator/api/save_design_lite'
      ),
    } = routes;

    let saveDesignPayload = {
      method: undefined,
      optionCodes: optionCodes.join(','),
      optionCodeNames: optionCodeNames.join(','),
      locale,
      assetView,
      depositAmount,
      modelCode,
      postalCode: postalCode && validatePostalCode(postalCode, { countryCode }) ? postalCode : '',
      countryCode,
      modelName,
      shareUrl,
      trafficSource: getTrafficSource(),
      trafficSourceHistory: getTrafficSourceHistory(),
      activitysessionid: getActivitySessionId(),
    };
    switch (method) {
      case SAVE_DESIGN_METHOD_EMAIL:
        saveDesignPayload = Object.assign(saveDesignPayload, {
          method: 'Email',
          email,
          firstName,
          lastName,
          phoneNumber,
          getUpdates,
        });
        break;
      case SAVE_DESIGN_METHOD_SMS:
        saveDesignPayload = Object.assign(saveDesignPayload, {
          method: 'Sms',
          phoneNumber,
          verifyPhoneCode,
          referral: referralCode
        });
        break;
      default:
    }

    request
      .post(saveDesign)
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('Accept', 'application/json')
      .retry(0, (err, res) => {
        console.warn('Error on save design: retry', err);
        return false;
      })
      .send(saveDesignPayload)
      .end(err => {
        dispatch({ type: LOADER_FINISH });
        if (err) {
          dispatch({
            type: SHARE_DESIGN_FAIL,
            method: method,
          });
          return null;
        }
        dispatch({
          type: SHARE_DESIGN_SUCCESS,
        });
        return null;
      });
  }
};

export const resetSaveDesign = () => ({
  type: SHARE_DESIGN_RESET
})

export const closeFailedShareAlert = () => ({
  type: CLOSE_SHARE_FAIL_ALERT,
});

export const copyUrl = (url, inputEl) => {
  const input = inputEl ? inputEl.current : null;
  copyToClipboard(url, input);
  return {
    copied: true,
    type: COPY_URL,
  };
};

export function toggleGetUpdates(flag) {
  return {
    type: SAVE_DESIGN_GET_UPDATES,
    flag,
  };
}
