import React from 'react';
import { connect } from 'react-redux';
import { bool, shape, number } from 'prop-types';
import { formatCurrency } from '@tesla/coin-common-components';
import { i18n } from 'utils';
import { getRegionalFeeRebate } from 'selectors';
import _get from 'lodash/get';
import { BANNER_MODAL, MODAL_XSMALL } from 'dictionary';
import ModalTrigger from '../../ModalTrigger';

const RegionalFees = ({ incentives, showProvincialIncentives, regionalFeeRebate, isSummary }) => {
  if (!showProvincialIncentives) {
    return null;
  }

  const { regional = [] } = incentives;
  const incentive = regional.length ? regional[0] : {};
  const incentiveAmount = incentive?.amount || 0;
  const { longName, hasConditions = false } = incentive || {};

  return (
    <If condition={showProvincialIncentives}>
      <If condition={incentiveAmount}>
        <div className="tds-flex regional-fees">
          <span className="label tds-flex-item">
            {i18n('Review.regional_rebate', {
              LABEL: longName,
            })}
            <If condition={hasConditions}>
              <Choose>
                <When condition={isSummary}>
                  <ModalTrigger
                    options={{
                      props: {
                        componentName: BANNER_MODAL,
                        props: {
                          bannerType: 'ira_modal',
                          side: MODAL_XSMALL,
                          genericWrapper: true,
                          source: 'banner',
                          containerCss: 'coin--banner-modal',
                          contentSource: 'regional'
                        },
                      },
                    }}
                  >
                    <span className="tds-link tds-text--caption">
                      {i18n('Review.regional_qualification_link')}
                    </span>
                  </ModalTrigger>
                </When>
                <Otherwise>
                  <sup>1</sup>
                </Otherwise>
              </Choose>
            </If>
          </span>
          <span className="value tds-flex-item">{formatCurrency(incentiveAmount)}</span>
        </div>
      </If>
      <If condition={regionalFeeRebate}>
        <div className="tds-flex regional-fees">
          <span className="label tds-flex-item">{i18n('FinancingOptions.stamp_duty_rebate')}</span>
          <span className="value tds-flex-item">{formatCurrency(regionalFeeRebate)}</span>
        </div>
      </If>
    </If>
  );
};

RegionalFees.propTypes = {
  incentives: shape({}),
  showProvincialIncentives: bool,
  regionalFeeRebate: number,
  isSummary: bool,
};

RegionalFees.defaultProps = {
  incentives: {},
  showProvincialIncentives: false,
  regionalFeeRebate: 0,
  isSummary: false,
};

function mapStateToProps(state, ownProps) {
  const { isSummary = false } = ownProps;
  return {
    showProvincialIncentives: _get(state, 'FinancingOptions.showProvincialIncentives', false),
    incentives: _get(state, 'Financial.incentives.current', false),
    regionalFeeRebate: getRegionalFeeRebate(state),
    isSummary,
  };
}

export default connect(mapStateToProps)(RegionalFees);
