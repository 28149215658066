import {
  CHANGE_HERO_VIEW_TO,
  CHANGE_HERO_TYPE_TO,
  COMPLETE_CONFIGURATION_CHANGED,
} from 'dictionary';

import _intersection from 'lodash/intersection';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { parseSelectedBy } from 'utils';

export default function Reducer(state = {}, action) {
  const { EPAunits = {}, hideEstLink = {} } = state;
  const { selectedBy: hideSelectedBy = {} } = hideEstLink;
  const { selectedBy = [] } = EPAunits;
  switch (action.type) {
    case CHANGE_HERO_VIEW_TO:
      return { ...state, selected_view: action.selected_view };
    case CHANGE_HERO_TYPE_TO:
      return { ...state, selected_type: action.selected_type };
    case COMPLETE_CONFIGURATION_CHANGED: {
      const { set = [] } = action;
      if (typeof set === 'undefined') {
        return state;
      }
      const matchedCodes = _get(action, 'userSelectedOptionsByCategory.matchedCodes', {});
      let selected_view = null;
      if (_intersection(set, matchedCodes.PAINT).length) {
        selected_view = 'exterior';
      } else if (_intersection(set, matchedCodes.INTERIOR_BUNDLE).length) {
        selected_view = 'interior';
      }

      return {
        ...state,
        selected_view: selected_view || state.selected_view,
        extraDelivery: !!_intersection(_get(action, 'options', []), ['$DV4W', '$IN3PW', '$PFP31'])
          .length,
        EPAunits: {
          ...state.EPAunits,
          display: _isEmpty(selectedBy)
            ? false
            : parseSelectedBy(selectedBy, _get(action, 'options', [])),
        },
        hideEstLink: {
          ...hideEstLink,
          hide: _isEmpty(hideSelectedBy)
            ? false
            : parseSelectedBy(hideSelectedBy, _get(action, 'options', [])),
        },
      };
    }
    default:
      return state;
  }
}
