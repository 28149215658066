/* eslint no-plusplus: 0 */

import _has from 'lodash/has';
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import _includes from 'lodash/includes';
import _capitalize from 'lodash/capitalize';
import _intersection from 'lodash/intersection';
import _isEmpty from 'lodash/isEmpty';

import { getModel, isInventory, getGAAutoType, getPaymentType, getStateProvince, getPostalCode, getProductTypeMapping } from 'selectors';
import {
  PAGE_LOAD_EVENT,
  VIRTUAL_PAGE_VIEW,
  APPLE_PAY_VALIDITY,
  APPLE_PAY_SESSION_REQUEST,
  SAVE_CONFIG_FLOW,
  CANCEL_UPDATE_ORDER,
  COPY_URL,
  SHARE_DESIGN_SUCCESS,
  FINANCE_MODAL_TAB_GAS_SAVINGS,
  FINANCE_MODAL_TAB_INCENTIVES_SAVINGS,
  FINANCE_MODAL_TAB_DELIVERY_TIMING,
  FINANCE_MODAL_TAB_INCENTIVES,
  FINANCE_MODAL_TAB_OPTIONS,
  SUMMARY_PANEL_DOWNPAYMENT_CHANGE,
  SUMMARY_PANEL_FINANCE_TERM_CHANGE,
  SUMMARY_PANEL_FINANCE_APR_CHANGE,
  SUMMARY_PANEL_REGION_CHANGE,
  PAYMENT_FORM_TOGGLE,
} from 'dictionary';

// codes mapped to analytics equivalents
const modelMap = {
  ms: 'Model S',
  mx: 'Model X',
  m3: 'Model 3',
  my: 'Model Y',
  mp: 'cybertruck',
  ct: 'cybertruck',
};

/**
 * Analytics
 * Wrapper class to handle GA tag events or other types of tracking
 */
export default class Analytics {
  // if set to false, then console logs are displayed
  static suppressConsole = true;

  // contains any attached listeners
  static events = {};

  static counts = {};

  static publicAccountId = this.getPublicAccountId();

  static event = this.getEvent();

  static initializeTime = Date.now();

  static cfgType = this.getCfgType();

  static getPublicAccountId() {
    const teslaData = _get(window, 'tesla', null);
    return _get(teslaData, 'accountIdPub', '');
  }

  static getEvent() {
    const teslaData = _get(window, 'tesla', {});
    const { savedConfiguration = null } = teslaData;
    const isEnterpriseOrder = _get(teslaData, 'isEnterpriseOrder', false);
    const isEnterpriseEditOrder = isEnterpriseOrder && !!savedConfiguration;
    const isPostOrderSwap = _get(teslaData, 'isPostOrderSwap', false);

    if (isEnterpriseEditOrder) {
      return 'fleet-management';
    }
    if (isPostOrderSwap) {
      return 'inventory-swap';
    }
    return 'configurator';
  }

  static getCfgType() {
    const teslaData = _get(window, 'tesla', null);
    const isReservation = _get(teslaData, 'product.data.isReservation', false);
    const isInventoryOrder = _has(teslaData, 'product.data') && !isReservation;
    const isEnterpriseOrder = _get(teslaData, 'isEnterpriseOrder', false);
    const modelCode = _get(teslaData, 'omsParams.model', false);
    const isPostOrderSwap = _get(teslaData, 'isPostOrderSwap', false);

    if (isEnterpriseOrder) {
      return `enterprise-${modelCode}`;
    }
    if (isPostOrderSwap) {
      return modelCode;
    }
    if (isInventoryOrder) {
      return `inventory-${modelCode}`;
    }
    return modelCode;
  }

  /**
   * Fire event that you only want fired once
   * @param  {String} eventName [description]
   * @param  {Object} state     [description]
   */
  static fireOnce(eventName, state) {
    if (!Analytics.counts[eventName]) {
      Analytics.fireEvent(eventName, state);
    }
  }

  /**
   * Fire appropriate event
   * @param  {String} eventName - name of event (from constants)
   * @param  {Object} state - redux state
   */
  static fireEvent(eventName, state) {
    if (!Analytics.enabled) {
      return;
    }

    const eventProps = {
      event: {
        name: eventName,
      },
    };

    switch (eventName) {
      case PAGE_LOAD_EVENT:
        Analytics.firePDPTag(state);
        break;

      case VIRTUAL_PAGE_VIEW:
        Analytics.fireVirtualPageTag(state);
        break;
      default:
        return;
    }

    // track of how many times we call each event
    if (!Analytics.counts[eventName]) {
      Analytics.counts[eventName] = 1;
    } else {
      Analytics.counts[eventName]++;
    }

    // if event bindings have been added for this event,
    // then iterate through callbacks and pass response.
    const events = Analytics.events[eventName];

    if (events) {
      for (let i = 0, n = events.length; i < n; i++) {
        events[i](eventProps);
      }
    }
  }

  // Fired on document.ready.
  static firePDPTag(state) {
    if (!Analytics.enabled) {
      return;
    }
    const modelName = modelMap[getModel(state)];
    const autoType = getGAAutoType(state);

    // eslint-disable-next-line no-restricted-globals
    const price = isNaN(_get(state, 'Pricing.total'))
      ? _get(state, 'ReviewDetails.product.data.PurchasePrice')
      : _get(state, 'Pricing.total');

    const products = isInventory(state)
      ? {
          id: _get(state, 'ReviewDetails.product.data.VIN') || '',
          name: modelName,
          brand: 'Tesla',
          category: 'auto',
          price,
          variant: _get(state, 'ReviewDetails.product.data.TrimName') || '',
        }
      : {
          id: modelName,
          name: modelName,
          brand: 'Tesla',
          category: 'auto',
        };

    Analytics.fireTagEvent({
      event: 'dataLayer-loaded',
      pageCategory: 'Product Details',
      autoType,
      referral: false, // TODO: hookup with referral
      ecommerce: {
        detail: {
          products: [products],
        },
      },
    });
  }

  /**
   * Virtual Page event
   * When user proceed on main navigation flows
   * @param  String virtualPageName
   */
  static fireVirtualPageTag(virtualPageName, virtualPageTitle = false, state) {
    if (!Analytics.enabled) {
      return;
    }
    const modelCode = getModel(state) || '';
    const virtualPageUrl = `/virtual/${modelCode}-configurator/${virtualPageName}`;
    let pageTitle = virtualPageName;

    if (virtualPageTitle) {
      pageTitle = virtualPageTitle;
    }

    if (Analytics.events.virtualPageUrl === virtualPageUrl) {
      // ensure we don't fire same virtual page view twice in a row
      return;
    }
    Analytics.fireTagEvent({
      virtualPageUrl,
      event: 'virtualPageview',
      virtualPageTitle: `${modelCode.toUpperCase()} CFG - ${pageTitle}`,
    });

    Analytics.events.virtualPageUrl = virtualPageUrl;
  }

  /**
   * Finance Tab change event
   * When user clicks on different financial options
   * @param  action
   */
  static fireTabChangeEvent(action) {
    if (!Analytics.enabled) {
      return;
    }
    Analytics.fireTagEvent({
      event: Analytics.event,
      'cfg-type': Analytics.cfgType,
      interaction: `${action.source}-${action.tabID}${action.isModalOpen? '-modal' : ''}`,
    });
  }

  /**
   * Configuration Change event
   * When user clicks on available options
   * @param action
   */
  static fireConfigurationChangeTag(action) {
    if (!Analytics.enabled) {
      return;
    }
    const selectedGroup = [];
    // map group names to GA cfg-type's
    const typeMap = {
      TRIM: 'battery',
      DRIVE_MODE: 'drivetrain',
      PAINT: 'color',
      WHEELS: 'wheels',
      AUTOPILOT: Analytics.getAutoPilotType(action.options),
      PREMIUM_PACKAGE: 'interior-type',
      INTERIOR: 'interior-color',
    };

    const groupsMapped = {
      WHEEL: 'WHEELS',
    };

    _forEach(action.set, selectedOption => {
      _forEach(action.userSelectedOptionsByGroup.matchedCodes, (value, key) => {
        const keyToUse = _has(groupsMapped, key) ? groupsMapped[key] : key;
        if (_has(typeMap, keyToUse) && _includes(value, selectedOption)) {
          selectedGroup.push({ keyToUse, selectedOption });
        }
      });
    });

    if (
      selectedGroup.length === 0 &&
      action.userSelectedOptionsByGroup.nonMatchedCodes.length === 0 &&
      action.set[0].includes('$APF2')
    ) {
      // Handle removing fsd
      Analytics.fireTagEvent({
        event: Analytics.event,
        'cfg-type': Analytics.cfgType,
        // Carousel remove fsd vs directly from config
        interaction: action.interaction ? action.interaction : 'remove-fsd',
      });
    }

    _forEach(selectedGroup, group => {
      const interactionPrefix = typeMap[group.keyToUse];
      if (interactionPrefix) {
        // Handle add fsd from carousel modal
        if (action.panelName && action.interaction) {
          Analytics.fireTagEvent({
            event: Analytics.event,
            'cfg-type': Analytics.cfgType,
            interaction: `${action.interaction}-${group.selectedOption}`,
          });
        } else {
          // Handle add fsd from configurator directly
          Analytics.fireTagEvent({
            event: Analytics.event,
            'cfg-type': Analytics.cfgType,
            interaction: `${interactionPrefix}-${group.selectedOption}`,
          });
        }
      }
    });
  }

  /**
   * Option Interaction event
   * When user clicks on interactions that is available with the options like images, videos etc.
   * @param action
   */
  static fireOptionInteractionEvent(action, analyticsPrefix = '') {
    if (!Analytics.enabled) {
      return;
    }
    let nameOfWidget = '';
    let interaction = '';
    let event = null;
    let formType = null;

    switch (action.component_target) {
      case 'AssetLoader':
        nameOfWidget = 'interior-image';
        interaction = 'expand';
        break;
      case 'Video':
        nameOfWidget = 'fsd-ap-video';
        interaction = 'play';
        break;
      case 'FinanceContainer':
        nameOfWidget = 'ribbon';
        interaction = 'savings';
        break;
      case 'SaveDesign':
        interaction = 'view-open';
        event = 'form-interaction';
        formType = 'save design';
        break;
      default:
        break;
    }

    switch (action.type) {
      case PAYMENT_FORM_TOGGLE:
        nameOfWidget = 'ribbon';
        interaction = 'savings';
        break;
      case SUMMARY_PANEL_REGION_CHANGE:
        nameOfWidget = 'change-state';
        interaction = 'toggle';
        break;
      case SUMMARY_PANEL_DOWNPAYMENT_CHANGE:
        interaction = `financeContainer-${analyticsPrefix}-update-downpayment`;
        break;
      case SUMMARY_PANEL_FINANCE_TERM_CHANGE:
        interaction = `financeContainer-${analyticsPrefix}-update-term`;
        break;
      case SUMMARY_PANEL_FINANCE_APR_CHANGE:
        interaction = `financeContainer-${analyticsPrefix}-update-interest-rate`;
        break;
      default:
        break;
    }
    const selectedView = action.selectedView || action.selectedForm;

    switch (selectedView) {
      case FINANCE_MODAL_TAB_INCENTIVES_SAVINGS:
      case FINANCE_MODAL_TAB_GAS_SAVINGS:
      case FINANCE_MODAL_TAB_DELIVERY_TIMING:
      case FINANCE_MODAL_TAB_INCENTIVES:
      case FINANCE_MODAL_TAB_OPTIONS:
        nameOfWidget = Analytics.cfgType;
        interaction = `${analyticsPrefix}-${action.selectedForm}`;
        break;
      case 'FinancingOptions':
        nameOfWidget = Analytics.cfgType;
        interaction = `${analyticsPrefix}-finance-options`;
        break;
      case 'loan':
        nameOfWidget = 'loan-after-savings';
        interaction = 'expand';
        break;
      default:
        break;
    }

    const AnalyticsObject = {
      event: event || Analytics.event,
      'cfg-type': nameOfWidget || Analytics.cfgType,
      formType,
      interaction,
    };

    // Remove null formType
    if (AnalyticsObject.formType == null) {
      delete AnalyticsObject.formType;
    }

    // Prevent GA tagging with empty cfg-type and interaction values
    if (!!AnalyticsObject['cfg-type'].length && !!AnalyticsObject.interaction.length) {
      Analytics.fireTagEvent(AnalyticsObject);
    }
  }

  /**
   * Form Interaction event
   * When user interacts with the form e.g financial modal interaction
   * @param  String nameOfWidget
   */
  static fireInteractionTag({
    action,
    userInteraction = false,
    nameOfWidget = false,
    eType = false,
    state,
  }) {
    if (!Analytics.enabled) {
      return;
    }
    const component_target = _get(action, 'component_target');
    const action_type = action.type;
    const modelLabel = modelMap[getModel(state)].toLowerCase().replace(' ');

    let formType = '';
    let interaction = 'view-open';
    let engagementType = 'User Requested';

    if (userInteraction) {
      interaction = userInteraction;
    }
    if (nameOfWidget) {
      formType = nameOfWidget;
    }
    if (eType) {
      engagementType = eType;
    }

    switch (component_target) {
      case 'SuccessModal':
        formType = `${modelLabel}-hold-my-place-in-line`;
        interaction = 'personal-info-complete';
        break;
      case 'SaveDesign':
        formType = 'save design';
        break;
      default:
      // default case
    }

    switch (action_type) {
      case COPY_URL:
        interaction = 'copy-link';
        formType = 'save design';
        break;
      case SHARE_DESIGN_SUCCESS:
        interaction = 'personal-info-complete';
        formType = 'save design';
        break;
      default:
      // default case
    }

    Analytics.fireTagEvent({
      event: 'form-interaction',
      interaction,
      formType,
      engagementType,
    });
  }

  /**
   * External link navigation
   * When user clicks for any url link
   * @param  Object analytics
   */
  static fireNavigationTag(analytics) {
    if (!Analytics.enabled) {
      return;
    }
    Analytics.fireTagEvent({
      event: Analytics.event,
      ...analytics,
    });
  }

  static fireApplePayAnalyticsEvent({ action }) {
    if (!Analytics.enabled) {
      return;
    }
    const action_type = action.type;
    let interaction = '';

    switch (action_type) {
      case APPLE_PAY_VALIDITY:
        interaction = 'impression';
        break;

      case APPLE_PAY_SESSION_REQUEST:
        interaction = 'click';
        break;
      default:
      // default case
    }

    Analytics.fireTagEvent({
      event: 'apple_pay',
      interaction,
    });
  }

  static fireModifyOrderAnalyticsEvent({ action, state }) {
    if (!Analytics.enabled) {
      return;
    }
    const action_type = action.type;
    let interaction = '';
    let label = '';
    const model = modelMap[getModel(state)].toLowerCase().replace(' ');

    switch (action_type) {
      case SAVE_CONFIG_FLOW:
        interaction = 'update-order';
        label = `${model} - ${state.Configuration.rn}`;
        break;

      case CANCEL_UPDATE_ORDER:
        interaction = 'cancel-keep-current-design';
        label = `${model} - ${state.Configuration.rn}`;
        break;
      default:
      // default case
    }

    Analytics.fireTagEvent({
      event: 'modify-order',
      interaction,
      label,
    });
  }

  /**
   * Wrapper function for GA tag event
   * Pushes GA tag event onto dataLayer queue
   * @param  {Object} eventProps - json data to send
   */
  static fireTagEvent(eventProps, cb = null, state = null) {
    if (!Analytics.enabled) {
      if (typeof cb === 'function') {
        cb(state);
      }
      return;
    }
    let eventObj = {
      ...eventProps
    };
    if (typeof cb === 'function' && window.google_tag_manager) {
      eventObj = {
        ...eventObj,
        eventCallback: function(containerId) {
          if (containerId.startsWith('GTM-') && typeof cb === 'function') {
            cb(state);
          }
        },
        eventTimeout: 2000,
      }
    }
    window.dataLayer.push(eventObj);
    if (typeof cb === 'function' && !window.google_tag_manager) {
      setTimeout(() => {
        cb(state);
      }, 2000);
    }
    return eventProps;
  }

  /**
   * eCommerce Add to Cart event
   * When user choose confirm their vehicle configurations
   * @param  {Object} eventProps - json data to send
   */
  static addToCart(state) {
    if (!Analytics.enabled) {
      return;
    }
    const {
      modelName,
      brand,
      category,
      trimName,
      driveTrainName,
      pkgName,
      price,
      financeType,
      paymentType,
      optionsString,
      autopilot,
      autoType,
      currencyCode,
    } = Analytics.getProductAttributes(state);

    return Analytics.fireTagEvent({
      event: 'add-to-cart',
      autoType,
      paymentOption: _capitalize(financeType),
      paymentType,
      ecommerce: {
        add: {
          products: [
            {
              id: modelName,
              name: modelName,
              brand,
              category,
              quantity: 1,
              price,
              currencyCode,
              variant: trimName,
              dimension21: autopilot,
              dimension22: driveTrainName,
              dimension23: optionsString,
              dimension27: pkgName,
              dimension31: String(price),
            },
          ],
        },
      },
    });
  }

  static inventorySwapError(state, interaction) {
    if (!Analytics.enabled) {
      return;
    }
    const {
      isInventorySwapEnabled,
      totalSwapConfig,
      interactionTypeForSwap,
    } = Analytics.getProductAttributes(state);

    if (!isInventorySwapEnabled) {
      return;
    }

    Analytics.fireTagEvent({
      event: Analytics.event,
      'cfg-type': Analytics.cfgType,
      ...(totalSwapConfig ? { 'inventorySwap-configuration': totalSwapConfig } : {}),
      interaction: `${interactionTypeForSwap}:error:${interaction}`,
    });
  }

  static postSwapTagEvent(interaction, message) {
    const event = {
      event: Analytics.event,
      'cfg-type': Analytics.cfgType,
      interaction,
    }
    if (message) {
      event.message = message
    }
    Analytics.fireTagEvent(event);
  }

  static traceOrderSource(state) {
    if (!Analytics.enabled) {
      return {};
    }
    const {
      isInventorySwapEnabled,
      isEarlyDesignSelected,
      totalSwapConfig,
      interactionTypeForSwap,
    } = Analytics.getProductAttributes(state);

    if (!isInventorySwapEnabled) {
      return;
    }

    Analytics.fireTagEvent({
      event: Analytics.event,
      'cfg-type': Analytics.cfgType,
      ...(totalSwapConfig ? { 'inventorySwap-configuration': totalSwapConfig } : {}),
      interaction: `${interactionTypeForSwap}:${
        isEarlyDesignSelected ? 'order-placed-inventory' : 'order-placed-custom'
      }`,
    });
  }

  static paymentFailure(state) {
    if (!Analytics.enabled) {
      return;
    }
    const {
      interactionTypeForSwap: type,
      region,
      postalCode,
      rn,
      previousRN,
      paymentType,
      paymentOption,
    } = Analytics.getProductAttributes(state);

    const interactionType = type ? `${type}:` : '';
    Analytics.fireTagEvent({
      event: Analytics.event,
      'cfg-type': Analytics.cfgType,
      interaction: `${interactionType}error:payment-failure`,
      paymentType,
      paymentOption,
      orderNumber: rn || previousRN,
      region,
      postalCode,
    });
  }

  /**
   * GA Translation tag (for emea markets)
   * @param  {Object} state [description]
   * @return {[type]}       [description]
   */
  static transaction(state, rn) {
    if (!Analytics.enabled) {
      return;
    }
    const {
      referralCode,
      paymentType,
      paymentOption,
      autoType,
      region,
      postalCode,
    } = Analytics.getProductAttributes(state);

    Analytics.traceOrderSource(state);

    return Analytics.fireTagEvent({
      event: Analytics.event,
      'cfg-type': Analytics.cfgType,
      referralCode: !_isEmpty(referralCode),
      interaction: 'place-order',
      orderNumber: rn,
      autoType,
      paymentOption,
      paymentType,
      region,
      postalCode,
    });
  }

  static getProductAttributes(state) {
    if (!Analytics.enabled) {
      return {};
    }
    const modelName = modelMap[getModel(state)];
    const brand = 'Tesla';
    const category = 'auto';
    const trimName = (() => {
      const trims = _get(state, 'App.analytics_variant_map');
      const options = _get(state, 'Configuration.option_codes');
      if (trims) {
        let maxCount = 0;
        return trims.reduce((r, obj) => {
          const intersect = _intersection(options, obj.options);
          if (intersect.length > maxCount) {
            maxCount = intersect.length;
            return obj.variant;
          }
          return r;
        }, null);
      }
      return _get(state, 'CustomGroups.TRIM.currentSelected[0].code');
    })();

    const driveTrainName = _get(state, 'Configuration.options_by_group.DRIVE_MODE[0]', '');
    const pkgName = 'Premium';
    let price = Number(_get(state, 'Pricing.subtotal'));

    if (isInventory(state)) {
      // eslint-disable-next-line no-restricted-globals
      price = isNaN(_get(state, 'Pricing.total'))
        ? _get(state, 'ReviewDetails.product.data.PurchasePrice')
        : Number(_get(state, 'Pricing.total'));
    }
    const financeType = getProductTypeMapping(state);
    const optionsString = _get(state, 'Configuration.option_string');
    const optionsArray = optionsString ? optionsString.split(',') : [];
    const autopilot = Analytics.getAutoPilotType(optionsArray);

    const countryCode = _get(state, 'OMS.oms_params.market');
    const referralCode = _get(state, 'ApplicationFlow.referral.referralCode');
    const totalPrice = _get(state, 'Pricing.total');
    const paymentOption = _capitalize(financeType);
    const rn = _get(state, 'Configuration.rn', '');
    const autoType = getGAAutoType(state);
    const paymentType = getPaymentType(state);
    const region = getStateProvince(state);
    const postalCode = getPostalCode(state);
    const { isInventorySwapEnabled } = state?.App;
    const { isEarlyDesignSelected, totalSwapConfig = 0, interactionTypeForSwap = '' } =
      state?.ReviewDetails?.vehicleDesign || {};
    const { CurrencyCode: currencyCode } = state?.Payment || {};
    const { previousRN = null } = state?.ReviewDetails;

    return {
      autoType,
      modelName,
      brand,
      category,
      trimName,
      driveTrainName,
      pkgName,
      financeType,
      optionsString,
      autopilot,
      referralCode,
      price,
      totalPrice,
      countryCode,
      paymentType,
      paymentOption,
      rn,
      isInventorySwapEnabled,
      isEarlyDesignSelected,
      totalSwapConfig,
      interactionTypeForSwap,
      currencyCode,
      region,
      postalCode,
      previousRN,
    };
  }

  static getAutoPilotType(options) {
    if (!Analytics.enabled) {
      return;
    }
    // detect auto-pilot type
    if (options.includes('$APPA') && options.includes('$APF0')) {
      return 'none';
    }
    if (options.includes('$APF2') && (options.includes('$APPB') || options.includes('$APBS'))) {
      return 'add-fsd';
    }
    if (options.includes('$APBS')) {
      return 'standard-autopilot';
    }
    if (options.includes('$APPB')) {
      return 'enhanced-autopilot';
    }

    return 'none';
  }

  /**
   * Event listener (if you want to know when Analytics events are fired)
   *
   * Usage:
   *    Analytics.on(ADD_TO_CART, (eventProps) => {
   *        console.log(eventProps)
   *    });
   *
   * @param  {String}   eventName
   * @param  {Function} callback
   */
  static on(eventName, callback) {
    if (!Analytics.events[eventName]) {
      Analytics.events[eventName] = [];
    }

    Analytics.events[eventName].push(callback);
  }

  /**
   * Remove listener
   * @param  {String} eventName
   */
  static removeListener(eventName, fn) {
    if (fn && Analytics.events[eventName]) {
      for (let i = 0; i < Analytics.events[eventName].length; i++) {
        if (Analytics.events[eventName][i] === fn) {
          Analytics.events[eventName].splice(i, 1);
          break;
        }
      }
    } else {
      delete Analytics.events[eventName];
    }
  }

  static fireInteractionEvent(interaction, activitySessionId = null) {
    let eventObj = {
      event: Analytics.event,
      'cfg-type': Analytics.cfgType,
      interaction,
    }
    if (activitySessionId) {
      eventObj.activitysession_id = activitySessionId;
    }
    Analytics.fireTagEvent(eventObj);
  }
}
