import { getDateTimeParts, formatDate } from '@tesla/intl-date-time';
import { i18n } from './index';
import { ESTIMATED_DELIVERY_TIMING } from 'dictionary';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isString from 'lodash/isString';
import { formatPercent } from '@tesla/coin-common-components';

export function cleanNonBreakingSpace(text) {
  return text.replace(/\xA0/g, ' ');
}

/**
 * This will remove zero-width spaces and non-breaking spaces from a string.
 * @param copy
 */
export const cleanSpaces = copy => {
  if (copy && typeof copy === 'string') {
    return copy.replace(/[\u200B-\u200D\uFEFF]/g, '').replace(/\u00A0/g, ' ');
  }

  return copy;
};

export function formatLocationsData(locations, key_prefix = 'province') {
  let allLocations = [];

  allLocations = (locations instanceof Array ? locations : Object.keys(locations)).map(
    (val, index) => {
      let value = val;
      if (locations[val] && Object.keys(locations[val]).length > 0) {
        let prefix = '';
        if (key_prefix === 'province') prefix = 'city';
        else if (key_prefix === 'city') prefix = 'district';
        value = formatLocationsData(locations[val], prefix);
      }
      return {
        key: `${key_prefix}_${index}`,
        label: val,
        value: val,
        children: value,
      };
    }
  );
  return allLocations;
}

export function getFormattedDeliveryDate(eddObj, locale) {
  if (!eddObj) {
    return '';
  }
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  const currentDate = today.getDate();
  const { inStart, inType, inEnd, atDate, deliveryWindowDisplayByLocale = null } = eddObj;
  const value = inEnd ? [inStart, inEnd].join('-') : inStart;
  let enLocale = 'en_US';
  const isAtDateValid = atDate && !Number.isNaN(new Date(atDate)?.getDate());
  if (isAtDateValid) {
    const date = getDateTimeParts(atDate, locale);
    const enDate = getDateTimeParts(atDate, enLocale);
    let enMonth = formatDate(atDate, enLocale, {
      month: 'short',
    });
    const year = date?.find(d => d.type === 'year')?.value;
    const enYear = enDate?.find(d => d.type === 'year')?.value;
    const monthName = formatDate(atDate, locale, {
      month: 'short',
    });
    let monthNumeric = formatDate(atDate, locale, {
      month: 'numeric',
    });
    return {
      delivery_date_name: `${monthName} ${year}`,
      deliveryWindowDisplay: !_isEmpty(deliveryWindowDisplayByLocale)
        ? deliveryWindowDisplayByLocale
        : `${enMonth} ${enYear}`,
      deliveryWindowStart: new Date(year, monthNumeric).toISOString(),
      deliveryWindowEnd: new Date(year, monthNumeric + 1, 0).toISOString(),
      deliveryWindowDisplayByLocale: deliveryWindowDisplayByLocale,
    };
  }
  switch (inType) {
    case 'month_year': {
      if (inStart && Number.isNaN(new Date(inStart)?.getDate())) {
        return;
      }
      const dateStart = getDateTimeParts(inStart, locale);
      const enDateStart = getDateTimeParts(inStart, enLocale);
      let enStartMonth = formatDate(inStart, enLocale, {
        month: 'short',
      });
      const dateStartYear = dateStart ? Number(dateStart.find(d => d.type === 'year')?.value) : '';
      const enDateStartYear = enDateStart
        ? Number(enDateStart.find(d => d.type === 'year')?.value)
        : '';
      let startMonth = formatDate(inStart, locale, {
        month: 'short',
      });
      let startMonthNumeric = formatDate(inStart, locale, {
        month: 'numeric',
      });
      const isInEndValid = inEnd && !Number.isNaN(new Date(inEnd)?.getDate());
      const dateEnd = isInEndValid ? getDateTimeParts(inEnd, locale) : '';
      const enDateEnd = isInEndValid ? getDateTimeParts(inEnd, enLocale) : '';
      let enEndMonth = enDateEnd
        ? formatDate(inEnd, enLocale, {
            month: 'short',
          })
        : '';
      const dateEndYear = dateEnd ? Number(dateEnd.find(d => d.type === 'year')?.value) : '';
      const enDateEndYear = enDateEnd ? Number(enDateEnd.find(d => d.type === 'year')?.value) : '';
      let endMonth = isInEndValid
        ? formatDate(inEnd, locale, {
            month: 'short',
          })
        : '';
      let endMonthNumeric = isInEndValid
        ? formatDate(inEnd, locale, {
            month: 'numeric',
          })
        : '';
      const deliveryWindowStart = new Date(
        dateStartYear,
        parseInt(startMonthNumeric) - 1
      ).toISOString();
      if (!inEnd) {
        return {
          deliveryWindowDisplay: !_isEmpty(deliveryWindowDisplayByLocale)
            ? deliveryWindowDisplayByLocale
            : `${enStartMonth} ${enDateStartYear}`,
          delivery_date_name: `${startMonth} ${dateStartYear}`,
          deliveryWindowStart,
          deliveryWindowEnd: new Date(dateStartYear, parseInt(startMonthNumeric), 0).toISOString(),
          deliveryWindowDisplayByLocale: deliveryWindowDisplayByLocale,
        };
      }
      const deliveryWindowEnd = new Date(dateEndYear, parseInt(endMonthNumeric), 0).toISOString();
      if (dateStartYear === dateEndYear && dateStartYear === new Date().getFullYear()) {
        return {
          deliveryWindowDisplay: !_isEmpty(deliveryWindowDisplayByLocale)
            ? deliveryWindowDisplayByLocale
            : `${enStartMonth} – ${enEndMonth} ${enDateEndYear}`,
          delivery_date_name: `${startMonth} – ${endMonth} ${dateEndYear}`,
          deliveryWindowStart,
          deliveryWindowEnd,
          deliveryWindowDisplayByLocale: deliveryWindowDisplayByLocale,
        };
      }
      return {
        deliveryWindowDisplay: !_isEmpty(deliveryWindowDisplayByLocale)
          ? deliveryWindowDisplayByLocale
          : `${enStartMonth} ${enDateStartYear} – ${enEndMonth} ${enDateEndYear}`,
        delivery_date_name: `${startMonth} ${dateStartYear} – ${endMonth} ${dateEndYear}`,
        deliveryWindowStart,
        deliveryWindowEnd,
        deliveryWindowDisplayByLocale: deliveryWindowDisplayByLocale,
      };
    }
    case 'months':
      return {
        deliveryWindowDisplay: !_isEmpty(deliveryWindowDisplayByLocale)
          ? deliveryWindowDisplayByLocale
          : `${value} months`,
        delivery_date_name: i18n(`common.${ESTIMATED_DELIVERY_TIMING[inType]}`, { NUM: value }),
        deliveryWindowStart: new Date(currentYear, currentMonth + parseInt(inStart)).toISOString(),
        deliveryWindowEnd: new Date(
          currentYear,
          currentMonth + 1 + parseInt(inEnd),
          0
        ).toISOString(),
        deliveryWindowDisplayByLocale: deliveryWindowDisplayByLocale,
      };
    case 'weeks':
      return {
        deliveryWindowDisplay: !_isEmpty(deliveryWindowDisplayByLocale)
          ? deliveryWindowDisplayByLocale
          : `${value} weeks`,
        delivery_date_name: i18n(`common.${ESTIMATED_DELIVERY_TIMING[inType]}`, { NUM: value }),
        deliveryWindowStart: new Date(
          currentYear,
          currentMonth,
          currentDate + parseInt(inStart) * 7
        ).toISOString(),
        deliveryWindowEnd: new Date(
          currentYear,
          currentMonth,
          currentDate + parseInt(inEnd) * 7
        ).toISOString(),
        deliveryWindowDisplayByLocale: deliveryWindowDisplayByLocale,
      };
    case 'quarter':
    case 'quarter_start': {
      if (Number.isNaN(new Date(inStart)?.getDate())) {
        return;
      }
      const dateStart = getDateTimeParts(inStart, locale, {});
      const inStartYearNum = dateStart
        ? parseInt(dateStart.find(d => d.type === 'year')?.value)
        : 0;
      const inStartMonthNum = dateStart
        ? parseInt(dateStart.find(d => d.type === 'month')?.value)
        : 0;
      let deliveryWindowStart = '';
      let deliveryWindowEnd = '';
      if (inStartYearNum > 0 && inStartMonthNum > 0) {
        //Jan - Mar: inStartMonthNum is 1 - 3, quarterNum is 1.
        //Apr - Jun: inStartMonthNum is 4 - 6, quarterNum is 2.
        //Jul - Sep: inStartMonthNum is 7 - 9, quarterNum is 3.
        //Oct - Dec: inStartMonthNum is 10 - 12, quarterNum is 4.
        const quarterNum = Math.floor((inStartMonthNum - 1) / 3) + 1;
        //Quarter 1 starts from Jan, month is 0
        //Quarter 2 starts from Apr, month is 3
        //Quarter 3 starts from Jul, month is 6
        //Quarter 4 starts from Oct, month is 9
        const monthStartNum = Math.floor((quarterNum - 1) * 3);
        deliveryWindowStart = new Date(inStartYearNum, monthStartNum, 1, 0, 0, 0).toISOString();
        //Get days in the last month of a quarter
        const daysInMonth = new Date(inStartYearNum, Math.floor(quarterNum * 3), 0).getDate();
        deliveryWindowEnd = new Date(
          inStartYearNum,
          Math.floor(quarterNum * 3) - 1,
          daysInMonth,
          0,
          0,
          0
        ).toISOString();
      }

      return {
        deliveryWindowDisplay: deliveryWindowDisplayByLocale ?? '',
        delivery_date_name: deliveryWindowDisplayByLocale ?? '',
        deliveryWindowStart: deliveryWindowStart,
        deliveryWindowEnd: deliveryWindowEnd,
        deliveryWindowDisplayByLocale: deliveryWindowDisplayByLocale,
      };
    }

    default:
      return inType ? i18n(`common.${ESTIMATED_DELIVERY_TIMING[inType]}`, { NUM: value }) : '';
  }
}

/**
 * Get matches on postal code with possible extension.
 *
 * @param  string postalCode
 *
 * @return object postal code and code extension.
 */
export function getZipWithExtension({ postalCode, countryCode }) {
  const result = {
    postalCode,
    postalCodeExtension: '',
  };
  if (countryCode !== 'US') {
    return result;
  }
  const regex = /^(\d{5})(?:[-\s]{0,1})(\d{0,4})?$/gm;
  const matches = regex.exec(postalCode);
  if (matches === null) {
    return result;
  }

  return {
    postalCode: matches[1] || '',
    postalCodeExtension: matches[2] || '',
  };
}

/**
 * Get vehicle location string, including market disclaimers
 *
 * @param vehicle vehicle
 * @param showLocationPrefix showLocationPrefix
 * @param showVehicleLocationDisclaimer showVehicleLocationDisclaimer
 * @return string location
 */
export const getVehicleLocationString = ({
  vehicle,
  showLocationPrefix,
  showVehicleLocationDisclaimer = false,
  isPickupOnlyEnabled = false,
  isEMEA,
  showInTransitStatusMetro,
  inventoryData,
  isUsedInventory,
  isCN
}) => {
  const { location, isInTransit, isUsed, city, stateProvinceLongName, isFactoryGated } = vehicle;

  const {
    InTransit,
    IsAtLocation,
    InTransitMetroName,
    InTransitSalesMetro,
    MetroName,
    SalesMetro,
    VrlLocksUnderThreshold = true,
  } = inventoryData || {};

  if (showInTransitStatusMetro) {
    let locationName = null;
    if (IsAtLocation || VrlLocksUnderThreshold) {
      if (InTransit) {
        locationName = InTransitMetroName || InTransitSalesMetro || null;
      } else {
        locationName = MetroName || SalesMetro || null;
      }
    }
    return locationName;
  }

  if (isPickupOnlyEnabled) {
    if (location) {
      if (!isInTransit && isFactoryGated) {
        return i18n('Inventory.available_at_location', { LOCATION: location });
      }
      return i18n('Inventory.coming_soon_to_location', { LOCATION: location });
    }
    return i18n('Inventory.common.newVehicleNoLocationPickUpDisclaimer');
  }
  /*
    // Not used. Confirm
    if (isPickupOnlyEnabled && !isUsed && isFactoryGated) {
      return i18n('Inventory.common.newVehiclePickUpDisclaimer', { LOCATION: location });
    }

    if (isPickupOnlyEnabled && !isUsed && !isFactoryGated) {
      return i18n('Inventory.common.newVehiclePickupDisclaimerNonGated', { LOCATION: location });
    }
    */

  if (!isFactoryGated) {
    return null;
  }

  if (showVehicleLocationDisclaimer && isUsed) {
    return i18n('Inventory.common.vehicleLocationDisclaimer', {
      CITY: city,
      STATE_PROVINCE_LONG_NAME: stateProvinceLongName,
    });
  }

  if (isCN && isUsedInventory && location) {
      return `${i18n('Inventory.common.locatedIn')} ${location}`;
  }

  if (location && isInTransit) {
    return isEMEA ? location : i18n('Inventory.common.inTransit', { LOCATION: location });
  }

  if (location && !isInTransit) {
    return `${i18n('Inventory.common.locatedIn')} ${location}`;
  }

  if (!isInTransit && showLocationPrefix) {
    return i18n(`Inventory.delivered_at_location`, { LOCATION: location });
  }

  return location;
};

/**
 * Get option family mapping code.
 *
 * @param optCode Option code
 * @return object|undefined Returns key / value pair of option family mapping.
 */
export const getOptionFamilyCode = optCode => {
  const map = {
    $MT324: {
      key: 'TRIM',
      value: 'LRAWD',
    },
    $STY7S: {
      key: 'CABIN_CONFIG',
      value: 'SEVEN',
    },
  };
  return _get(map, optCode);
};

/**
 * Get formatted copy of Model Year
 *
 * @param year Model year
 * @param showModelYear show modified model year
 * @return string|number
 */
export const getFormattedModelYear = ({ year, showFormattedModelYear = false }) => {
  return showFormattedModelYear ? `MY${year?.toString()?.slice(-2)}` : year;
};

/**
 * Get formatted address
 *
 * @param address1 string
 * @param address2 string
 * @param city string
 * @param provinceState string
 * @param postalCode string
 * @param country string
 *
 * @return string
 */
export const formatAddress = ({
  address1 = '',
  address2 = '',
  city = '',
  provinceState = '',
  postalCode = '',
  country = '',
}) => {
  // TODO: Get template and mapping for every locale
  // This format will not be correct for all markets
  // separating into Line1 and Line2 for now may return as separate keys in future?
  const streetAddress = address2 ? `${address1} ${address2}` : address1;
  let cityStatePostalCountry = provinceState;
  cityStatePostalCountry = postalCode
    ? cityStatePostalCountry
      ? `${cityStatePostalCountry} ${postalCode}`
      : postalCode
    : cityStatePostalCountry;
  cityStatePostalCountry = country
    ? cityStatePostalCountry
      ? `${cityStatePostalCountry} ${country}`
      : country
    : cityStatePostalCountry;
  cityStatePostalCountry = city
    ? cityStatePostalCountry
      ? `${city}, ${cityStatePostalCountry}`
      : city
    : cityStatePostalCountry;
  const formattedAddress = cityStatePostalCountry
    ? streetAddress
      ? `${streetAddress}, ${cityStatePostalCountry}`
      : cityStatePostalCountry
    : streetAddress;
  return formattedAddress;
};

export const formatApr = rate => {
  if (Number.isNaN(rate)) {
    return;
  }
  const precision = rate ? 2 : 0;
  return formatPercent(rate * 100, precision);
};

export const reverseFormatCurrencyBasic = (val) => {
  if (!val || !_isString(val)) {
    return 0;
  }
  const parsed = Number(val.replace(/[^0-9.-]+/g,''));
  return isNaN(parsed) ? 0 : parsed;
}
