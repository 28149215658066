import React from 'react';
import { bool, arrayOf, shape, string, func } from 'prop-types';
import cx from 'classnames';
import _get from 'lodash/get';
import { SELECTION_LABEL, CUSTOM_GROUP_TYPE } from 'dictionary';
import { FormFieldset, FormLabel, FormItem } from '@tesla/design-system-react';
import AssetTooltip from '../../components/AssetTooltip';
import CustomSelect from '../../components/CustomSelect';
import ControlledCheckbox from '../../components/CustomSelect/controlledCheckbox';

const TypeRenderer = ({
  group,
  onChange,
  baseConfigWithPrice = {},
  upgrades = {},
  config = [],
  isPOEditdesign,
}) => {
  if (!group) return null;

  const {
    totalAvailable = [],
    code: groupCode,
    name: groupName,
    exclusive = false,
    required = false,
    type: groupType,
    hidden = {},
  } = group;
  const isGroupHidden = _get(hidden, 'isHidden', false);

  if (!totalAvailable.length || isGroupHidden) return null;

  const isExclusiveNotRequired = !required && ['WINTER_WHEELS_OPTIONS'].includes(groupCode);
  // Add exceptions
  if (!exclusive && !isExclusiveNotRequired) {
    return totalAvailable.map(item => {
      const { code } = item;
      return (
        <FormFieldset key={`TypeRenderer:Checkbox:${groupCode}:${code}`}>
          <legend
            className={cx('tds-form-label', {
              'tds--is_visually_hidden': groupType !== CUSTOM_GROUP_TYPE,
            })}
            style={{
              margin: 0,
            }}
          >
            <span className="tds-text--h5" style={{ paddingBlockStart: 0 }}>
              {groupName}
            </span>
          </legend>
          <ControlledCheckbox
            group={group}
            item={item}
            config={config}
            onChange={params => onChange({ ...params, type: groupType })}
          />
        </FormFieldset>
      );
    });
  }
  const fallbackDefault =
    isExclusiveNotRequired || isPOEditdesign
      ? {
          name: `${groupCode}_selectOption`,
          type: SELECTION_LABEL,
        }
      : null;
  const currentSelected = totalAvailable.find(itm => itm.selected) || fallbackDefault;
  if (!currentSelected) {
    return null;
  }

  return (
    <FormItem>
      <FormLabel
        htmlFor={groupCode}
        tooltip={
          currentSelected?.type !== SELECTION_LABEL ? (
            <AssetTooltip classes="tds-tooltip-info" data={currentSelected} group={group} />
          ) : null
        }
      >
        {groupName}
      </FormLabel>
      <CustomSelect
        name={groupCode}
        onChange={params => {
          onChange({ ...params, analyticsPrefix: groupCode });
        }}
        group={group}
        baseConfigWithPrice={baseConfigWithPrice}
        config={config}
        upgrades={upgrades}
        current={totalAvailable}
        showSelectLabel={isExclusiveNotRequired}
        currentSelected={currentSelected}
        hasSelectLabel={!!fallbackDefault}
      />
    </FormItem>
  );
};

TypeRenderer.propTypes = {
  group: shape({
    code: string,
    current: arrayOf(shape({})),
    name: string,
    exclusive: bool,
  }),
  onChange: func.isRequired,
  baseConfigWithPrice: shape({}),
  upgrades: shape({}),
  config: arrayOf(string),
  isPOEditdesign: bool,
};

TypeRenderer.defaultProps = {
  group: {},
  baseConfigWithPrice: {},
  upgrades: {},
  config: [],
  isPOEditdesign: false,
};

export default TypeRenderer;
