import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bool, func, string } from 'prop-types';
import {
  ESIGN_STATUS_INIT,
  ESIGN_STATUS_SENT,
  ESIGN_STATUS_SIGNED,
  ESIGN_STATUS_TIMEOUT,
  ESIGN_STATUS_FAILED,
} from 'dictionary';
import {
  checkAndProcessEsignFlow,
  placePaymentSignOrderFlow,
  queryCurrentEsignStatus,
  updateESignStatus,
} from '../../../reducers';
import _get from 'lodash/get';
import { StatusMessage } from '@tesla/design-system-react';
import useInterval from '../../../hooks/useInterval';
import PartialSpinner from '../../../components/Loaders/PartialSpinner';
import moment from 'moment';
import { i18n } from 'utils';

const EsignFlowProcess = ({
  esignPending,
  updateEsignStatus,
  esignStatus,
  esignLink,
  resentEsignMessage,
  checkCurrentEsignStatus,
}) => {
  const [startUpMoment, setStartUpMoment] = useState(null);
  const [poolingDelay, setPoolingDelay] = useState(60 * 1000);
  const [poolingStart, setPoolingStart] = useState(false);
  const [countDownDelay, setCountDownDelay] = useState(1000);
  const [countDownString, setCountDownString] = useState('');
  const [countDownInSecond] = useState(30 * 60);
  const [poolingCount, setPoolingCount] = useState(0);

  const getCountDownString = cd => {
    const countDownDuration = moment.duration(cd, 'seconds');
    return moment
      .unix(0)
      .add(countDownDuration)
      .format(` mm ${i18n('esign.minute')} ss ${i18n('esign.second')} `);
  };

  const countDown = () => {
    const currentCountDownInSecond = countDownInSecond - moment().diff(startUpMoment, 'seconds');
    if (currentCountDownInSecond <= 0) {
      setCountDownDelay(null);
      setPoolingDelay(null);
      updateEsignStatus(ESIGN_STATUS_TIMEOUT);
    }
    const countDownString = getCountDownString(currentCountDownInSecond);
    setCountDownString(countDownString);
  };

  const checkIfSigned = (isPooling = false) => {
    setPoolingCount(poolingCount + 1);
    checkCurrentEsignStatus(ESIGN_STATUS_SIGNED, isPooling);
  };

  useEffect(() => {
    setStartUpMoment(moment());
  }, []);

  // interval hook enabled in pooling if esign status is SINGED in failed component
  useInterval(() => {
    if (poolingCount >= 60) {
      setPoolingDelay(null);
      return;
    }

    if (esignPending) {
      return;
    }
    if (esignStatus === ESIGN_STATUS_SIGNED) {
      setPoolingDelay(null);
      return;
    }
    if (!poolingStart) {
      setPoolingDelay(10 * 1000);
      setPoolingStart(true);
    }
    checkIfSigned(true);
  }, poolingDelay);

  useInterval(() => {
    if (esignStatus !== ESIGN_STATUS_SENT && esignStatus !== ESIGN_STATUS_FAILED) {
      return;
    }
    countDown();
  }, countDownDelay);

  const goToSignConsent = () => {
    const tab = window.open('about:blank');
    tab.location = esignLink;
    tab.focus();
  };

  const resend = () => {
    resentEsignMessage();
  };

  if (esignPending) {
    return (
      <div>
        <PartialSpinner active={true} />
      </div>
    );
  }

  if (esignStatus === ESIGN_STATUS_SENT || esignStatus === ESIGN_STATUS_TIMEOUT) {
    const statusMessageBody = (
      <>
        <p>{i18n('esign.sentSuccessMessage', null, null, { returnNullWhenEmpty: true })}</p>
        <p>{i18n('esign.sentSuccessMessageAction', null, null, { returnNullWhenEmpty: true })}</p>
      </>
    );

    return (
      <div className={'esign-status-sent'}>
        <StatusMessage body={statusMessageBody} type="success" />
        <div>
          {i18n(
            'esign.countDownMessage',
            {
              TIME: countDownString,
            },
            null,
            { returnNullWhenEmpty: true }
          )}
        </div>
        <button
          type="button"
          className="tds-btn tds-btn--primary tds-btn--width-full tds-btn--large button-sent"
          onClick={goToSignConsent}
        >
          {i18n('esign.goToSign', null, null, { returnNullWhenEmpty: true })}
        </button>
        <button
          type="button"
          className="tds-btn tds-btn--secondary tds-btn--width-full button-check"
          onClick={() => checkIfSigned(false)}
        >
          {i18n('esign.goToPay', null, null, { returnNullWhenEmpty: true })}
        </button>
      </div>
    );
  }

  return (
    <div className={'esign-status-failed'}>
      <StatusMessage
        body={i18n('esign.sendFailed', null, null, { returnNullWhenEmpty: true })}
        type="error"
      />
      <div>
        {i18n(
          'esign.countDownMessage',
          {
            TIME: countDownString,
          },
          null,
          { returnNullWhenEmpty: true }
        )}
      </div>
      <button
        type="button"
        className="tds-btn tds-btn--secondary tds-btn--width-full button-failed"
        onClick={resend}
      >
        {i18n('esign.resend', null, null, { returnNullWhenEmpty: true })}
      </button>
    </div>
  );
};

EsignFlowProcess.propTypes = {
  esignStatus: string,
  esignPending: bool,
  esignLink: string,
  updateEsignStatus: func,
  resentEsignMessage: func,
  triggerPayment: func,
  checkCurrentEsignStatus: func,
};

EsignFlowProcess.defaultProps = {
  esignStatus: ESIGN_STATUS_INIT,
  esignPending: false,
  esignLink: '',
  updateEsignStatus: () => {},
  resetEsignMessage: () => {},
  triggerPayment: () => {},
  checkCurrentEsignStatus: () => {},
};

const mapStateToProps = state => {
  const { ApplicationFlow } = state;
  const referenceNumber = _get(state, 'Configuration.rn', '');
  return {
    esignStatus: _get(ApplicationFlow, 'esignStatus', ESIGN_STATUS_INIT),
    esignPending: _get(ApplicationFlow, 'esignPending', false),
    esignLink: _get(ApplicationFlow, 'esignLink', ''),
    rn: referenceNumber,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateEsignStatus: status => dispatch(updateESignStatus(status)),
    triggerPayment: () => dispatch(placePaymentSignOrderFlow()),
    resentEsignMessage: () => dispatch(checkAndProcessEsignFlow()),
    checkCurrentEsignStatus: (status, isPooling) =>
      dispatch(queryCurrentEsignStatus(status, isPooling)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EsignFlowProcess);
