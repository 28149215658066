/* eslint no-case-declarations: 0 */
import {
  UPDATE_TRADEIN_DATA,
  SET_VEHICLE_TYPE,
  RESET_TRADEIN_DATA,
  TESLA,
  FINPLAT_INPUTS_CHANGED,
  MODEL_FULL_NAME,
  GROUP_CONNECTIVITY,
  LEASED,
  PRICE_CHANGED,
  TRADEIN_FINPLAT_INPUTS_CHANGED,
  TESLA_NOT_SHOWN,
} from 'dictionary';
import {
  getVehiclesByYear,
  getVehicleInfoByVin,
  getTradeInValue,
  memoize,
  getTrafficSource,
  getTrafficSourceHistory,
  getActivitySessionId,
} from 'utils';
import {
  getBatterySpecs,
  getFinplatInputsWithTradeIn,
  getSelectedOptions,
  getModelName,
} from 'selectors';
import { request } from 'utils/requestAgent';
import { map, filter } from 'rxjs/operators';
import Analytics from 'analytics';
import { updateAccountDetails } from 'actions';

export const setTradeInDetails = payload => ({
  type: UPDATE_TRADEIN_DATA,
  payload,
});

export const setVehicleType = value => ({
  type: SET_VEHICLE_TYPE,
  value,
});

export const resetTradeInDetails = value => (dispatch, getState) => {
  const state = getState();
  const { Pricing: { finplat } = {} } = state;
  dispatch({
    type: RESET_TRADEIN_DATA,
    value,
  });
  if (finplat) {
    dispatch({
      type: FINPLAT_INPUTS_CHANGED,
      userInput: {
        tradeInValue: 0,
      },
    });
  }
};

export const getVehicleDetailsByYear = year => async (dispatch, getState) => {
  const state = getState();
  const {
    App: { routes: { getVehicleResultsByYear: url } = {}, countryCode, language } = {},
  } = state;
  dispatch(setTradeInDetails({ isLoading: true }));
  const payload = {
    year,
    includeAllMakes: true,
    language,
    country: countryCode,
  };
  const memoizedVehicleDetailsByYear = memoize(getVehiclesByYear, payload);
  const result = (await memoizedVehicleDetailsByYear(url, payload)) || {};
  const { error } = result || {};
  const data = result?.year || {};
  if (result) {
    dispatch(setTradeInDetails({ isLoading: false, error, vehiclesByYear: data }));
  }
};

export const decodeVin = vin => async (dispatch, getState) => {
  const state = getState();
  const {
    App: { routes: { getVehicleDetailsByVin: url } = {}, countryCode, language } = {},
  } = state;
  dispatch(setTradeInDetails({ isLoading: true }));
  const payload = {
    vin,
    language,
    country: countryCode,
  };
  const memoizedVinDecode = memoize(getVehicleInfoByVin, payload);
  const result = (await memoizedVinDecode(url, payload)) || {};
  const { error, valuationResponses: data = [] } = result || {};
  let vinDetails = {};
  const trimOptions = [];
  if (data?.length) {
    data?.forEach(({ make, model, trim, year }) => {
      vinDetails = {
        make,
        model,
        year,
        trim,
      };
      trimOptions.push(trim);
    });
  }
  if (result) {
    dispatch(
      setTradeInDetails({
        isLoading: false,
        error,
        vehicleDetails: {
          ...vinDetails,
        },
        vehiclesByYear: {},
        trimOptions,
        vehicleInfoByVin: !error ? result : {},
      })
    );
  }
};

export const getVehicleValuation = () => async (dispatch, getState) => {
  const state = getState();
  const {
    App: { routes: { getVehicleValue: url } = {}, countryCode, language, locale } = {},
    TradeIn: { vehicleDetails = {}, vin, vehicleType, contactDetails = {} } = {},
    OMS: { oms_params: { model: modelCode } = {} } = {},
    Payment: { CurrencyCode } = {},
    Pricing: { finplat } = {},
  } = state;
  const {
    make,
    model,
    year,
    zipCode,
    trim = null,
    odometer,
    condition,
    accidentHistory,
    payoffAmount = 0,
    paymentStatus,
  } = vehicleDetails || {};
  const isTesla = !!(vehicleType === TESLA);
  dispatch(setTradeInDetails({ isLoading: true }));
  const { rawData: { range_units_short: unit } = {} } = getBatterySpecs(state) || {};
  const { phoneNumber } = contactDetails || {};
  let { number, code } = phoneNumber || {};
  const { optionCodes, optionCodeNames } = getSelectedOptions({
    state,
    exclude: [GROUP_CONNECTIVITY],
  });
  const phone = number && code ? `+${code}${number}` : number || phoneNumber;
  const payload = {
    accidentHistory,
    condition,
    countryCode,
    language,
    make,
    odometer,
    model,
    odometerUnits: unit === 'mi' ? 'MILES' : 'KM',
    trim,
    vin,
    year,
    zipCode,
    isTesla,
    ...contactDetails,
    formattedPhone: phone,
    modelCode,
    modelRouteName: MODEL_FULL_NAME[modelCode],
    locale,
    options: optionCodes.join(','),
    optionsNames: optionCodeNames.join(', '),
    productsInterested: getModelName(state),
    currencyCode: CurrencyCode,
    paymentStatus,
    trafficSource: getTrafficSource(),
    trafficSourceHistory: getTrafficSourceHistory(),
    activitysessionid: getActivitySessionId(state),
    formLink: window.location.href,
  };
  const result = (await getTradeInValue(url, payload)) || {};
  const { error, valuation = 0 } = result || {};
  const afterPayOff = valuation - payoffAmount;
  const tradeInAmount = payoffAmount ? afterPayOff : valuation;

  if (result) {
    const userAction = valuation ? 'success' : 'no-estimate';
    Analytics.fireInteractionEvent(`tradein-estimate-${userAction}`);
    dispatch(
      setTradeInDetails({
        isLoading: false,
        error: error,
        tradeInCredit: valuation,
        tradeInAmount,
        finplatInputs: {},
      })
    );

    if (finplat) {
      dispatch({
        type: FINPLAT_INPUTS_CHANGED,
        userInput: {
          tradeInValue: valuation,
        },
      });
    }
  }
};

export const getVehiclesByUserId = () => async (dispatch, getState) => {
  const state = getState();
  const { App: { routes: { getVehiclesByUserId: url } = {}, countryCode } = {} } = state;
  dispatch(setTradeInDetails({ isLoading: true }));
  try {
    const result = await request.post(url).send({ countryCode });
    const { error, body } = result || {};
    const { deliveredVehicles: data = [], userDetails } = body || {};
    const { Vin: vin, Year: year, Model: model, Trim: trim } = data?.find(x => x) || {};
    const vehicleDetails = {
      year,
      model,
      trim,
      make: TESLA,
    };
    if (result) {
      dispatch(
        setTradeInDetails({
          isLoading: false,
          error,
          vehiclesByUserId: data,
          vin,
          vehicleDetails,
        })
      );
      if (userDetails) {
        dispatch(updateAccountDetails(userDetails));
      }
    }
  } catch (err) {
    dispatch(
      setTradeInDetails({
        isLoading: false,
        error: err,
        vin: TESLA_NOT_SHOWN,
        step: 'estimates',
      })
    );
  }
};

export const updateFinanceProductWithTradeInCredit = (action$, state$) =>
  action$.pipe(
    filter(action => [PRICE_CHANGED].includes(action.type)),
    map(() => dispatch => {
      const state = state$.value;
      const userInput = getFinplatInputsWithTradeIn(state);
      if (userInput) {
        dispatch({
          type: TRADEIN_FINPLAT_INPUTS_CHANGED,
          userInput,
        });
      }
    })
  );
