import React, { useState, useLayoutEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { string, func, shape, bool } from 'prop-types';
import { i18n, htmlToReact } from 'utils';
import { NAVIGATION_VIEW_OVERVIEW } from 'dictionary';
import Analytics from 'analytics';
import { setBackToProfileFlag } from 'actions';
import {
  getProfileDashboardUri,
  getEnterpriseLandingUri,
  getSavedConfiguration,
  getEnterpriseSearchUri,
  getModelName,
} from '../../common/selectors';
import { Icon } from '@tesla/design-system-react';
import { iconChevronSmall270 } from '@tesla/design-system-icons';
import { ConfirmModal } from '@tesla/coin-common-components';

const ConfirmCancelModal = ({ open, onContinue, onClose }) => {
  const container = document.querySelector('#enterprise-modal-root');

  return container
    ? createPortal(
        <ConfirmModal
          open={open}
          title={i18n('Enterprise.remove_vehicle')}
          body={i18n('Enterprise.confirmRemoveVehicleMessage')}
          buttonLabel={i18n('Enterprise.yes_goback')}
          onClose={onClose}
          onContinue={onContinue}
        />,
        container
      )
    : null;
};

const OrderNavigation = ({
  modelName,
  navigationTab,
  landingRouteUri,
  profileDashboardUri,
  headerTitle,
  backButtonTitle,
  savedConfiguration,
  setBackToProfile,
  isInventory,
  enterpriseSearchUri,
  inventoryBackButtonTitle,
  inventoryHeaderTitle,
  isLayoutMobile,
  isLayoutTablet,
}) => {
  const navigationRef = useRef();
  useLayoutEffect(() => {
    let headerHeightInt = 52;
    const navigationBlock = navigationRef?.current?.getBoundingClientRect() || {};
    const { height: navigationHeightInt } = navigationBlock;
    // Note: bounding client is 0 for navigation block in regular t4b flow
    const galleryMarginTop = headerHeightInt + (navigationHeightInt || 152) + 16;
    const siteHeader = document.getElementById('tds-site-header');
    if (isLayoutMobile || isLayoutTablet) {
      const initialHeaderHeight = siteHeader?.getBoundingClientRect()?.height || 0;
      headerHeightInt = parseInt(initialHeaderHeight);
    }
    const bannerHeight =
      document.getElementsByClassName('tds-banner')[0]?.getBoundingClientRect()?.height || 0;
    if (siteHeader) {
      siteHeader.style.marginTop = bannerHeight ? `${bannerHeight}px` : 0;
    }
    const shellHeaderWithHeight = `${(isNaN(headerHeightInt) ? 0 : headerHeightInt) +
      bannerHeight}px`;
    document
      .getElementById('root')
      .style.setProperty('--tds-shell-header-height', shellHeaderWithHeight);
    // set gallery for enterprise
    Array.from(document.getElementsByClassName('gallery')).forEach(elem => {
      elem.style.top = `${galleryMarginTop + bannerHeight}px`;
      elem.style.height = `calc(100% - ${galleryMarginTop}px)`;
    });
  }, [isLayoutMobile, isLayoutTablet, navigationTab, navigationRef?.current]);

  const [promptConfirm, setPromptConfirm] = useState(false);
  const navigateToProfile = () => {
    Analytics.fireTagEvent({
      event: Analytics.event,
      'cfg-type': Analytics.cfgType,
      interaction: 'back-to-orders',
    });
    if (savedConfiguration) {
      setBackToProfile(true);
    } else {
      window.location = profileDashboardUri;
    }
  };

  const onChangeCar = e => {
    e.preventDefault();
    Analytics.fireTagEvent({
      event: Analytics.event,
      interaction: 'change-car',
      'cfg-type': Analytics.cfgType,
    });
    window.location = landingRouteUri;
  };

  const navigateToSearch = () => {
    Analytics.fireTagEvent({
      event: Analytics.event,
      interaction: 'change-car',
      'cfg-type': Analytics.cfgType,
    });
    if (navigationTab === NAVIGATION_VIEW_OVERVIEW) {
      window.location = enterpriseSearchUri;
    } else {
      setPromptConfirm(true);
    }
  };

  return (
    <div className="navigation--section" ref={navigationRef}>
      <Choose>
        <When condition={isInventory}>
          <button onClick={navigateToSearch} type="button" className="tds-flex tds-text--caption">
            <Icon inline data={iconChevronSmall270} />
            <span>{i18n(inventoryBackButtonTitle)}</span>
          </button>
          <div className="navigation--section-header">
            <h1 className="tds-text--h2 tds--no_vertical_padding">{i18n(inventoryHeaderTitle)}</h1>
          </div>
          <ConfirmCancelModal
            open={promptConfirm}
            onClose={() => setPromptConfirm(false)}
            onContinue={() => {
              Analytics.fireTagEvent({
                event: Analytics.event,
                'cfg-type': Analytics.cfgType,
                interaction: 'yes-go-back',
              });
              setPromptConfirm(false);
              window.location = enterpriseSearchUri;
            }}
          />
        </When>
        <Otherwise>
          <button onClick={navigateToProfile} type="button" className="tds-flex tds-text--caption">
            <Icon inline data={iconChevronSmall270} />
            <span>{i18n(backButtonTitle)}</span>
          </button>
          <div className="navigation--section-header">
            <h1 className="tds-text--h1-alt tds--no_vertical_padding">{i18n(headerTitle)}</h1>
            <If condition={navigationTab === NAVIGATION_VIEW_OVERVIEW}>
              <div className="tds-text--h4">
                {htmlToReact(
                  i18n('common.buildYourModel', { MODEL: modelName.replace('&nbsp;', ' ') })
                )}
              </div>
              <a href={landingRouteUri} onClick={onChangeCar} className="tds-link">
                {i18n('common.changeCar')}
              </a>
            </If>
          </div>
        </Otherwise>
      </Choose>
    </div>
  );
};

OrderNavigation.propTypes = {
  modelName: string,
  navigationTab: string,
  landingRouteUri: string.isRequired,
  profileDashboardUri: string.isRequired,
  headerTitle: string,
  backButtonTitle: string,
  setBackToProfile: func.isRequired,
  savedConfiguration: shape({}),
  isInventory: bool,
  enterpriseSearchUri: string,
  inventoryBackButtonTitle: string,
  inventoryHeaderTitle: string,
  navigationTab: string,
  isLayoutMobile: bool,
  isLayoutTablet: bool,
};

OrderNavigation.defaultProps = {
  modelName: '',
  navigationTab: '',
  headerTitle: '',
  backButtonTitle: '',
  savedConfiguration: {},
  isInventory: false,
  enterpriseSearchUri: '',
  inventoryBackButtonTitle: '',
  inventoryHeaderTitle: '',
  navigationTab: '',
  isLayoutMobile: false,
  isLayoutTablet: false,
};

const mapStateToProps = state => {
  const {
    Navigation: { section: navigationTab = '' } = {},
    OMS,
    ReviewDetails: { product } = {},
    App,
  } = state;
  const { isLayoutMobile, isLayoutTablet } = App;
  const savedConfiguration = getSavedConfiguration(state);
  const { isInventory = false } = product || {};

  return {
    modelName: getModelName(state),
    navigationTab,
    landingRouteUri: getEnterpriseLandingUri(state),
    profileDashboardUri: getProfileDashboardUri(state),
    headerTitle: savedConfiguration
      ? 'Review.edit_design_label'
      : 'Enterprise.orderSummary.reviewOrder',
    modelCode: OMS.oms_params.model,
    backButtonTitle: savedConfiguration ? 'common.back' : 'common.back_to_orders',
    savedConfiguration,
    isInventory,
    enterpriseSearchUri: getEnterpriseSearchUri(state),
    inventoryBackButtonTitle:
      navigationTab === NAVIGATION_VIEW_OVERVIEW ? 'common.back' : 'common.back_to_inventory',
    inventoryHeaderTitle:
      navigationTab === NAVIGATION_VIEW_OVERVIEW
        ? 'common.inventory'
        : 'Enterprise.orderSummary.reviewOrder',
    navigationTab,
    isLayoutMobile,
    isLayoutTablet,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setBackToProfile: flag => {
      dispatch(setBackToProfileFlag(dispatch, flag));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderNavigation);
