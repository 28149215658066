import React from 'react';
import { connect } from 'react-redux';
import { bool, string } from 'prop-types';
import { i18n } from 'utils';
import { getPricePostfix } from 'selectors';
import cx from 'classnames';

const ExVatDisclaimer = ({ showVATBreakdown, pricePostfix, isCoinReloaded }) => {
  if (!showVATBreakdown) {
    return null;
  }
  const estimatedPriceLabel = i18n('Review.purchase_price_estimated', null, null, {
    returnNullWhenEmpty: true,
  });
  return (
    <p className={cx('ex-vat-disclaimer tds-text--caption tds-text--center tds-flex tds-flex--row-nowrap tds-flex--center tds-flex--justify-center', { 'tds--vertical_padding': !isCoinReloaded })}>
      {pricePostfix}
      {estimatedPriceLabel
        ? estimatedPriceLabel
        : i18n('Review.purchase_price_ex_vat')}
    </p>
  );
};

function mapStateToProps(state) {
  return {
    showVATBreakdown: state?.ReviewDetails?.showVATBreakdown ?? false,
    pricePostfix: getPricePostfix(state),
    isCoinReloaded: state?.App?.isCoinReloaded,
  };
}

ExVatDisclaimer.propTypes = {
  showVATBreakdown: bool,
  pricePostfix: string,
  isCoinReloaded: bool,
};

ExVatDisclaimer.defaultProps = {
  showVATBreakdown: false,
  pricePostfix: '',
  isCoinReloaded: false,
};

export default connect(mapStateToProps)(ExVatDisclaimer);
