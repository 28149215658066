import React from 'react';
import { string } from 'prop-types';
import { connect } from 'react-redux';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _has from 'lodash/has';
import classnames from 'classnames';

import { i18n, htmlToReact } from 'utils';
import { EMISSIONS_DATA, GROUP } from '../../common/dictionary';

const InventoryEmissions = ({ description }) => {
  const emissionsData = _get(window.tesla, 'product.data.EmissionsData', {});

  // check if object is flat and has EmissionsData as a group in the payload
  if (
    !_has(emissionsData, GROUP) ||
    emissionsData?.group !== EMISSIONS_DATA ||
    _isEmpty(emissionsData) ||
    !description
  ) {
    return null;
  }

  return (
    <div
      className={classnames(
        'group-block specs-block tds--vertical_padding option-widget--container'
      )}
    >
      <div
        className={classnames(
          `emissions-item tds-text--caption emissions-item-container inventory-emissions`
        )}
      >
        {htmlToReact(description)}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    description: i18n(
      'Emissions.description',
      {
        LOCALE: state?.App?.locale,
      },
      null,
      {
        returnNullWhenEmpty: true,
      }
    ),
  };
};

InventoryEmissions.propTypes = {
  description: string,
};

InventoryEmissions.defaultProps = {
  description: null,
};

export default connect(mapStateToProps)(InventoryEmissions);
