import React from 'react';
import { func, shape } from 'prop-types';
import { connect } from 'react-redux';
import _reduce from 'lodash/reduce';
import _set from 'lodash/set';
import * as actions from '../reducers';

const Debugger = ({ dispatch, state }) => {
  const {
    OMS: { oms_params, pricebook },
    Configuration: { options_by_category, user_selected_options, option_codes },
    Pricing: { manufacturing_string, price_string },
  } = state;

  const availableActions = _reduce(
    actions,
    (result, action, key) =>
      Object.assign(result, {
        [key]: (...allArguments) => {
          dispatch(action.apply(this, allArguments));
        },
      }),
    {}
  );

  _set(window, 'teslaConfig.ds', {
    getManufacturingString: () =>
      manufacturing_string
        .split(',')
        .sort()
        .join(','),
    getOptionsPriceString: () =>
      price_string
        .split(';')
        .sort()
        .join(';'),
    getMarket: () => oms_params.market,
    getLocale: () => `${oms_params.market}_${oms_params.language.toLowerCase()}`,
    getPriceBook: () => pricebook,
    getPriceBookName: () => pricebook.meta_data.name,
    getPreviousSelectedOptions: () => {
      // Functionality to be implemented
    },
    getOptionsPerCategory: () => options_by_category,
    getUserSelectedOptions: () => user_selected_options,
    isOptionSet: option => option_codes.includes(option),
    getOMSParams: () => oms_params,
    getAppState: () => state,
    ...availableActions,
  });

  return <span className="Debugger" style={{ display: 'none' }} />;
};

Debugger.propTypes = {
  dispatch: func.isRequired,
  state: shape({
    OMS: shape(),
    Configuration: shape(),
    Pricing: shape(),
  }).isRequired,
};

export default connect(state => ({ state }))(Debugger);
