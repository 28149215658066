import React from 'react';
import { connect } from 'react-redux';
import { StatusMessage } from '@tesla/design-system-react';
import { string, shape, arrayOf } from 'prop-types';
import { i18n } from 'utils';

const Alerts = ({ alerts: items = [] }) =>
  items?.length ? (
  <div className="alerts-container">
    {items.map(({ key, body, header, type, bodyTag }) => {
      return (
        <div key={key}>
        <StatusMessage
          className="tds--fade-in"
          body={body}
          header={header}
          type={type || 'warning'}
          bodyTag={bodyTag || 'div'}
        />
        </div>
    );
  })}
  </div>
) : null;

function mapStateToProps(state) {
  const { Accessories = {} } = state;
  const { errorState: accessoriesError } = Accessories;
  return {
    alerts: [
      ...accessoriesError ? [{
        body: i18n('Accessories.alert.description'),
        header: i18n('Accessories.alert.title'),
        type: 'warning',
        bodyTag: 'div',
        key: 'AccessoriesAlert',
      }] : [],
    ],
  };
}

Alerts.propTypes = {
  alerts: arrayOf(shape({
    body: string,
    header: string,
    type: string,
    bodyTag: string,
  })),
};

Alerts.defaultProps = {
  alerts: [],
};

export default connect(mapStateToProps)(Alerts);