import React, { Component, createRef } from 'react';
import { shape, string } from 'prop-types';
import { OPTION_VISUALIZATION, OPTION_WIDGET } from 'dictionary';

class LocationObserver extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasIntersected: false,
    };

    const { type } = this.props;

    this.options = {
      root:
        type === 'OptionVisualization'
          ? document.querySelector(`[data-location-id=${OPTION_VISUALIZATION}]`)
          : document.querySelector(`[data-location-id=${OPTION_WIDGET}]`),
      rootMargin: '0% 0% 50% 0%',
      threshold: 0.2,
    };

    this.observer = null;
    this.targetContainerRef = createRef();
    this.handleCallback = this.handleCallback.bind(this);
  }

  componentDidMount() {
    this.observer = new IntersectionObserver(this.handleCallback, this.options);
    this.observer.observe(this.targetContainerRef.current);
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.unobserve(this.targetContainerRef.current);
    }
  }

  handleCallback(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.setState({
          hasIntersected: true,
        });
        this.observer.unobserve(this.targetContainerRef.current);
        this.observer.disconnect();
      }
    });
  }

  render() {
    const { hasIntersected } = this.state;
    const { children, classes } = this.props;

    return (
      <>
        <div
          className={`observer-placeholder${classes ? ` ${classes}` : ''}`}
          style={{
            height: '100%',
          }}
          ref={this.targetContainerRef}
        >
          {hasIntersected && children}
        </div>
      </>
    );
  }
}

LocationObserver.propTypes = {
  children: shape({}),
  type: string.isRequired,
  classes: string,
};

LocationObserver.defaultProps = {
  children: null,
  classes: '',
};

export default LocationObserver;
