import React from 'react';
import { connect } from 'react-redux';
import { string, shape } from 'prop-types';
import _get from 'lodash/get';

import AssetLoader from '../AssetLoader';

const InventoryAssetLoader = ({ asset, view }) => {
  let newAsset = asset;

  if (view) {
    newAsset = { ...asset, view };
  }

  return (
    <div className="group--main-content group--main-content--big group-main-content--full-asset">
      <AssetLoader asset={newAsset} className="group--main-content--asset" />
    </div>
  );
};

InventoryAssetLoader.defaultProps = {
  view: '',
  asset: {},
};

InventoryAssetLoader.propTypes = {
  view: string,
  asset: shape({}),
};

function mapStateToProps(state, { asset = {}, path, assetPath }) {
  return {
    view: _get(state, path, false),
    asset: { ...asset, ..._get(state, assetPath, {}) },
  };
}

export default connect(mapStateToProps)(InventoryAssetLoader);
