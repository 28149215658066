const windowTesla = window.tesla;

const InitialState = {
  merge_method: 'assign',
  object: {
    error: null,
    data: null,
    updated: false,
    isLoading: false,
    editDesignCategories: [
      'TRIM',
      'PAINT',
      'WHEELS',
      'INTERIOR',
      'AUTOPILOT_PACKAGE',
      'WINTER_WHEELS_OPTIONS',
    ],
    goBackToProfile: false,
  },
  deltas: {
    model: {
      mx: {
        editDesignCategories: [
          'TRIM',
          'PAINT',
          'WHEELS',
          'INTERIOR_PACKAGE',
          'REAR_SEATS',
          'STEERING_WHEEL',
          'AUTOPILOT_PACKAGE',
          'WINTER_WHEELS_OPTIONS',
        ],
      },
      ms: {
        editDesignCategories: [
          'TRIM',
          'PAINT',
          'WHEELS',
          'INTERIOR_PACKAGE',
          'STEERING_WHEEL',
          'AUTOPILOT_PACKAGE',
          'WINTER_WHEELS_OPTIONS',
        ],
      },
      m3: {
        editDesignCategories: [
          'TRIM',
          'PAINT',
          'WHEELS',
          'INTERIOR',
          'TOWING',
          'AUTOPILOT_PACKAGE',
          'WINTER_WHEELS_OPTIONS',
        ],
        upgradeGroups: ['SPORT_PACKAGE'],
      },
      my: {
        editDesignCategories: [
          'TRIM',
          'PAINT',
          'WHEELS',
          'INTERIOR',
          'REAR_SEATS',
          'TOWING',
          'AUTOPILOT_PACKAGE',
          'WINTER_WHEELS_OPTIONS',
        ],
      },
    },
  },
  deltasV2: [
    {
      selected_by: {
        market: ['US', 'CA', 'MX', 'PR'],
        model: 'm3',
      },
      object: {
        editDesignCategories: [
          'TRIM',
          'PAINT',
          'WHEELS',
          'INTERIOR',
          'AUTOPILOT_PACKAGE',
          'WINTER_WHEELS_OPTIONS',
        ],
      },
    },
    {
      selected_by: {
        market: ['NO', 'FI', 'IS', 'SE', 'CL'],
        model: 'my',
      },
      object: {
        editDesignCategories: [
          'TRIM',
          'PAINT',
          'WHEELS',
          'INTERIOR',
          'AUTOPILOT_PACKAGE',
          'WINTER_WHEELS_OPTIONS',
        ],
      },
    },
  ],
};
export default InitialState;
