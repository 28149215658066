import React from 'react';
import { connect } from 'react-redux';
import { string, func } from 'prop-types';
import { Select, Form } from '@tesla/informed-tds';
import { i18n } from 'utils';
import {
  EMIRATE_VALUES,
  FIELD_DELIVERY_STATE_PROVINCE,
  NAVIGATION_VIEW_PAYMENT,
  LOCAL_STORAGE_KEY_EMIRATE,
} from 'dictionary';
import _get from 'lodash/get';
import { updateDeliveryDetails } from 'actions';
import { Storage } from '@tesla/coin-common-components';

const EmirateDropdown = ({
  deliveryStateProvince,
  updateDeliveryStateProvince,
  navigationSection,
}) => {
  let cachedValue = '';
  if (navigationSection === NAVIGATION_VIEW_PAYMENT) {
    cachedValue = Storage.get(LOCAL_STORAGE_KEY_EMIRATE);
    if (cachedValue) {
      updateDeliveryStateProvince(cachedValue);
    }
  }
  return (
    <Form>
      <Select
        name="deliveryStateProvince"
        label={i18n('DeliveryEmirate.enterprise_label')}
        options={[
          { label: i18n('DeliveryEmirate.select'), value: '', disabled: true },
          ...Object.keys(EMIRATE_VALUES).map(v => {
            const label = i18n(`DeliveryEmirate.states.${v}`);
            return { label, value: EMIRATE_VALUES[v] };
          }),
        ]}
        onChange={({ value }) => {
          updateDeliveryStateProvince(value);
        }}
        initialValue={cachedValue || deliveryStateProvince}
        required
      />
    </Form>
  );
};

EmirateDropdown.propTypes = {
  deliveryStateProvince: string,
  navigationSection: string,
  updateDeliveryStateProvince: func.isRequired,
};

EmirateDropdown.defaultProps = {
  deliveryStateProvince: '',
  navigationSection: '',
};

const mapStateToProps = state => {
  return {
    deliveryStateProvince: _get(state, 'ReviewDetails.DeliveryDetails.StateProvince', ''),
    navigationSection: _get(state, 'Navigation.section', ''),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateDeliveryStateProvince: value => {
      dispatch(updateDeliveryDetails({ [FIELD_DELIVERY_STATE_PROVINCE]: value }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmirateDropdown);
