import React, { useState, useEffect, Suspense } from 'react';
import { shape, func, string, bool, arrayOf } from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { closeFeatureModal } from 'actions';

import getGroupPanels from './util/getGroupPanels';
import getGroupLabel from './util/getGroupLabel';
import { lazyWithPreload } from '../Lazy';
import Spinner from '../Loaders/Spinner';

const CarouselModal = lazyWithPreload(() =>
  import(/* webpackChunkName: "carousel-modal" */ './index')
);

const CarouselModalContainer = ({
  closeCarouselModal,
  groupName,
  isCarouselOpen,
  selectedView,
  panels,
  label,
  modalClasses,
}) => {
  const [isFadeOut, setIsFadeOut] = useState(false);
  const onCloseCarousel = () => {
    setIsFadeOut(true);
    setTimeout(closeCarouselModal, 500);
  };

  useEffect(() => {
    if (isCarouselOpen) {
      document.body.classList.add('no-scroll-cfg');
      setIsFadeOut(false);
    } else {
      document.body.classList.remove('no-scroll-cfg');
    }
  }, [isCarouselOpen]);

  if (!panels?.length) {
    return null;
  }

  return (
    <div
      className={classnames('carousel-container', {
        'is-open': isCarouselOpen,
        'is-close': isFadeOut,
      })}
    >
      <If condition={isCarouselOpen && panels.length}>
        <Suspense key="Modal.Suspense.CarouselModalContainer" fallback={<Spinner active />}>
          <CarouselModal
            onCloseCarousel={onCloseCarousel}
            id={groupName}
            panels={panels}
            label={label}
            initialView={selectedView}
            classes={modalClasses}
          />
        </Suspense>
      </If>
    </div>
  );
};

function mapStateToProps(state) {
  const {
    CustomGroups,
    FeatureListModal,
    App: { isLayoutMobile },
  } = state;
  const { isCarouselOpen, groupName, selectedView, modalClasses } = FeatureListModal;

  const panels = getGroupPanels(state);
  const label = getGroupLabel(state);

  return {
    CustomGroups,
    isCarouselOpen,
    groupName,
    isLayoutMobile,
    selectedView,
    panels,
    label,
    modalClasses,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeCarouselModal: () => dispatch(closeFeatureModal()),
  };
}

CarouselModalContainer.propTypes = {
  closeCarouselModal: func.isRequired,
  isCarouselOpen: bool.isRequired,
  // Name of the group to render
  groupName: string.isRequired,
  selectedView: string, // Initial view to display on open

  panels: arrayOf(
    shape({
      id: string,
      label: string,
      component: shape({}),
    })
  ),
  label: string,
};

export default connect(mapStateToProps, mapDispatchToProps)(CarouselModalContainer);
