import React from 'react';
import { connect } from 'react-redux';
import { bool } from 'prop-types';
import { i18n, htmlToReact } from 'utils';

const TradersLicenseDisclaimer = ({ showTraderLicense }) => {
  if (!showTraderLicense) {
    return null;
  }
  return <span>{htmlToReact(i18n('SummaryPanel.disclaimers.sellerLicence'))}</span>;
};

TradersLicenseDisclaimer.propTypes = {
  showTraderLicense: bool,
};

TradersLicenseDisclaimer.defaultProps = {
  showTraderLicense: false,
};

function mapStateToProps(state) {
  const { ReviewDetails } = state;
  const { showTraderLicense } = ReviewDetails;

  return {
    showTraderLicense,
  };
}

export default connect(mapStateToProps)(TradersLicenseDisclaimer);
