import React from 'react';
import { connect } from 'react-redux';
import { bool } from 'prop-types';
import { Loader } from '@tesla/design-system-react';

const Spinner = ({ active }) => <Loader show={active} fullscreen/>;

Spinner.propTypes = {
  active: bool.isRequired,
};

const mapStateToProps = state => ({
  active: state.Loader.active,
});

export default connect(mapStateToProps)(Spinner);
