/* eslint-disable no-param-reassign */
import { formatCurrency } from '@tesla/coin-common-components';
import cx from 'classnames';
import { bool, number } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
  i18n,
} from 'utils';


const PurchasePrice = ({
  estPurchasePrice,
  enableCyberpunk,
}) => {
  
  return (
    <div className="review-page--total-section review-page--line-items">
      <ul className="review-page--totals-list tds-list">
        <li className={cx("review-page--line-item tds-list-item" ,{
            "tds-text--medium tds-text--contrast-medium": !enableCyberpunk,
            "tds-text--bold": enableCyberpunk,
        })}>
          <span className="label left">
            {i18n('Review.est_purchase_price')}
          </span>
          <span className="value">
            {formatCurrency(estPurchasePrice)}
          </span>
        </li>
      </ul>
    </div>
  )
};

function mapStateToProps(state) {
  const {
    enableCyberpunk
  } = state?.App;

  return {
    enableCyberpunk,
  };
}

PurchasePrice.propTypes = {
  estPurchasePrice: number,
  enableCyberpunk: bool,
};

PurchasePrice.defaultProps = {
  estPurchasePrice: 0,
  enableCyberpunk: false,
};

export default connect(mapStateToProps)(PurchasePrice);