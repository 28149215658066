import _reduce from 'lodash/reduce';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import {
  GOOGLE_GEOCODE_REQUEST,
  GOOGLE_GEOCODE_SUCCESS,
  GOOGLE_GEOCODE_FAIL,
  CHANGE_DELIVERY_LOCATION,
  DELIVERY_STATE_ADDRESS,
  UPDATE_AVAILABILITY_TIMIING,
  FETCH_DELIVERY_TIMING,
} from 'dictionary';

function getResultsMatch(results) {
  const typeMap = {
    locality: 'city',
    administrative_area_level_1: 'state',
    country: 'country',
    postal_code: 'postalCode',
  };

  if (results && results.length) {
    const { address_components } = results[0];

    return _reduce(
      address_components,
      (result, component) => {
        const res = result;
        component.types.map(type => {
          if (typeMap[type]) {
            res[typeMap[type]] = {
              short_name: component.short_name,
              long_name: component.long_name,
            };
          }
          return null;
        });
        return res;
      },
      {}
    );
  }

  return results;
}

export default function Reducer(state = {}, action) {
  switch (action.type) {
    case GOOGLE_GEOCODE_REQUEST:
      return { ...state, pendingRequest: true };
    case GOOGLE_GEOCODE_SUCCESS: {
      const deliveryTimingMatch = getResultsMatch(action.results);
      let deliveryTimingLocation;

      if (_isEmpty(deliveryTimingMatch)) {
        deliveryTimingLocation = 'NO_RESULTS';
      }
      // M3 DeliveryTiming
      if (_get(deliveryTimingMatch, 'country.short_name') === 'US') {
        deliveryTimingLocation =
          _get(deliveryTimingMatch, 'state.short_name') === 'CA' ? 'CA' : 'US';
      } else {
        deliveryTimingLocation = 'OTHER';
      }

      return {
        ...state,
        deliveryTimingMatch,
        deliveryTimingLocation,
        pendingRequest: false,
        error: null,
      };
    }
    case GOOGLE_GEOCODE_FAIL:
      return { ...state, pendingRequest: false, error: action.error };

    case CHANGE_DELIVERY_LOCATION:
      return {
        ...state,
        changeDeliveryLocation: action.isOpen,
      };
    case DELIVERY_STATE_ADDRESS:
      return {
        ...state,
        deliverystateAddress: action.address,
      };
    case UPDATE_AVAILABILITY_TIMIING:
      return {
        ...state,
        isAvailableNow: action?.available || false,
      };
    case FETCH_DELIVERY_TIMING:
      return {
        ...state,
        isLoading: action?.flag || false,
      }
    default:
      return state;
  }
}
