import _get from 'lodash/get';

export default state => {
  const {
    CustomGroups,
    FeatureListModal: { groupName },
  } = state;

  return _get(CustomGroups, `${groupName}.aria_label`, '');
};
