import _merge from 'lodash/merge'
import {
    OMS_REQUEST_DEPENDENCIES,
    OMS_RECEIVED_DEPENDENCIES,
    COMPLETE_CONFIGURATION_CHANGED
 } from 'dictionary'

function OMS(state = {}, action){
    switch (action.type) {
        case OMS_REQUEST_DEPENDENCIES:
            return Object.assign({}, state, {
                fetching: true
            })

        case OMS_RECEIVED_DEPENDENCIES:
            return Object.assign({}, state, {
                fetching: false
            }, {
                pricebook: action.data.pricebook,
                oms_pricebook: action.data.pricebook,
                oms_groups: action.data.groups,
                oms_baseOptions: action.data.baseOptions,
                lexicon: action.data.lexicon
            })

        case COMPLETE_CONFIGURATION_CHANGED:
            if(!Object.keys(action.pricebookDeltas||{}).length) {
                return state
            }
            const pricebookWithMergedDeltas = _merge(JSON.parse(JSON.stringify(state.oms_pricebook)),action.pricebookDeltas)
            return Object.assign({}, state, {
                pricebook_deltas: action.pricebookDeltas,
                pricebook: pricebookWithMergedDeltas
            })

        default:
            return state
    }
}

export default OMS