import React from 'react';
import { connect } from 'react-redux';
import { bool, string } from 'prop-types';
import cx from 'classnames';
import { i18n } from 'utils';

const ConsumerInformationLink = ({ consumerDocument, showLink, classes }) => {
  if (!showLink) {
    return null;
  }

  return (
    <div className={cx({ [classes]: classes }, 'tds-text--caption tds--vertical_padding--small')}>
      <a href={consumerDocument} rel="noopener noreferrer" target="_blank" className="tds-link tds-link--primary">
        {i18n('Inventory.disclaimers.consumerInformationLink')}{' '}
      </a>
    </div>
  );
};

ConsumerInformationLink.propTypes = {
  showLink: bool,
  classes: string,
  consumerDocument: string,
};

ConsumerInformationLink.defaultProps = {
  showLink: false,
  classes: '',
  consumerDocument: '',
};

function mapStateToProps(state) {
  const { App, ReviewDetails } = state;
  const { getDMSFiles: url = '' } = App?.routes || {};
  const { showConsumerInformationNotice = false, product } = ReviewDetails || {};
  const { InspectionDocumentGuid: guid } = product?.data || {};
  const consumerDocument = guid ? url + guid : '';
  return {
    showLink: !!(showConsumerInformationNotice && consumerDocument),
    consumerDocument,
  };
}

export default connect(mapStateToProps)(ConsumerInformationLink);
