import { ALERT_ADD, ALERT_REMOVE, ALERT_CLEAR_AND_ADD } from 'dictionary';

function Alert(state = {}, action) {
  switch (action.type) {
    case ALERT_ADD:
      return Object.assign({}, state, {
        alerts: [].concat(state.alerts, {
          message: action.message,
          withTrigger: action.withTrigger,
        }),
      });
    case ALERT_REMOVE:
      return Object.assign({}, state, {
        alerts: state.alerts.filter(alert => action.message !== alert.message),
      });
    case ALERT_CLEAR_AND_ADD:
      return {
        ...state,
        alerts: [
          {
            message: action.message,
            withTrigger: action.withTrigger,
          },
        ],
      };

    default:
      return state;
  }
}

export default Alert;
