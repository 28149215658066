// iterate through focusable elements to find the next or previous element
export function findNextTabStop(el, direction) {
  const universe = document.querySelectorAll(
    '[role="button"]:not(.tds--is_hidden), [role="link"], a, [tabindex="0"], [type="button"]'
  );
  const list = Array.prototype.filter.call(universe, item => {
    return item.tabIndex >= '0';
  });
  const index = list.indexOf(el);

  if (direction === 'previous') {
    return list[index - 1] || list[list.length - 1];
  }

  return list[index + 1] || list[0];
}

export function findNextTabStop2(container, ele, direction) {
  const universe = container.querySelectorAll(
    '[role=button]:not([disabled]), [role=link], [role=tab], a, [tabindex="0"], [type="button"]'
  );
  const list = Array.prototype.filter.call(universe, item => {
    return item.tabIndex >= '0';
  });
  const index = list.indexOf(ele);

  if (direction === 'previous') {
    return list[index - 1] || list[list.length - 1];
  }

  return list[index + 1] || list[0];
}
