import {
  UPDATE_TRADEIN_DATA,
  SET_VEHICLE_TYPE,
  RESET_TRADEIN_DATA,
  TRADEIN_FINPLAT_INPUTS_CHANGED,
} from 'dictionary';
import { getFinanceProductId } from 'selectors';

export default function Reducer(state = {}, action, { app_state }) {
  const { payload = {} } = action || {};
  switch (action.type) {
    case UPDATE_TRADEIN_DATA:
      return {
        ...state,
        ...payload,
      };
    case SET_VEHICLE_TYPE: {
      return {
        ...state,
        vehicleType: action?.value || '',
      };
    }
    case RESET_TRADEIN_DATA: {
      return {
        vin: '',
        vehicleDetails: {},
        contactDetails: {},
        step: '',
        vehiclesByYear: {},
        vehicleInfoByVin: {},
        vehiclesByUserId: [],
        finplatInputs: {},
      };
    }
    case TRADEIN_FINPLAT_INPUTS_CHANGED: {
      const productId = getFinanceProductId(app_state);
      const { finplatInputs = {} } = state;
      const formState = finplatInputs?.[productId] || {};
      return {
        ...state,
        finplatInputs: {
          ...finplatInputs,
          [productId]: {
            ...formState,
            ...action.userInput,
          },
        },
      };
    }
    default:
      return state;
  }
}
