import {
  SCREEN_LAYOUT_CHANGE,
  OMS_RECEIVED_DEPENDENCIES,
  NAVIGATION_SELECT_KEY,
  COMPLETE_CONFIGURATION_CHANGED,
  NAVIGATION_VIEW_OVERVIEW,
  NAVIGATION_VIEW_PAYMENT,
  NAVIGATION_VIEW_EARLYDELIVERY,
  UPDATE_ACTIVE_GROUP,
} from 'dictionary';
import _get from 'lodash/get';
import _has from 'lodash/has';
import { parseSelectedBy, isPriceAcceptance } from 'utils';
import { getTrimCode } from 'selectors';

function getSelectedSection({ section, selectedSection, allowedSections }) {
  return allowedSections.includes(selectedSection) ? selectedSection : section;
}

function parseLayoutData(layouts, state, option_codes, availableLayouts) {
  return layouts.reduce((res, layout) => {
    const components = _get(state, layout, []);
    return {
      ...res,
      [layout]: components.filter(component => {
        if (!_has(component, 'selected_by')) {
          return true;
        }
        // apply selected_by
        return parseSelectedBy(component.selected_by, option_codes, availableLayouts);
      }),
    };
  }, {});
}

export default function Reducer(state = {}, action, { app_state }) {
  const {
    layouts = [],
    section,
    allowedSections = [],
    layoutsData,
    configDisabledView = {},
    disableConfigForTrims = [],
  } = state;
  const { App = {}, Configuration = {} } = app_state;
  const { isLayoutMobile, isLayoutTablet } = App;
  const { option_codes } = Configuration;
  let dataParsed = layoutsData || {};
  const availableLayouts = [];
  if (isLayoutTablet) {
    availableLayouts.push('tablet');
  } else if (isLayoutMobile) {
    availableLayouts.push('mobile');
  } else {
    availableLayouts.push('desktop');
  }

  switch (action.type) {
    case OMS_RECEIVED_DEPENDENCIES:
      dataParsed = parseLayoutData(layouts, state, option_codes, availableLayouts);

      return {
        ...state,
        layoutsData: dataParsed,
        section: getSelectedSection({ section, allowedSections, selectedSection: action.key }),
      };
    case NAVIGATION_SELECT_KEY:
      if (!allowedSections.includes(action.key)) {
        return state;
      }
      return {
        ...state,
        section: getSelectedSection({ section, allowedSections, selectedSection: action.key }),
        linkTo: action?.linkTo,
        clearInventorSwapPaymentError: state.clearInventorSwapPaymentError || action.key !== NAVIGATION_VIEW_EARLYDELIVERY,
      };

    case COMPLETE_CONFIGURATION_CHANGED:
    case SCREEN_LAYOUT_CHANGE: {
      const trimCode = getTrimCode(app_state);
      dataParsed = parseLayoutData(layouts, state, option_codes, availableLayouts);
      if (isPriceAcceptance()) {
        return {
          ...state,
          section: NAVIGATION_VIEW_PAYMENT,
          allowedSections: [NAVIGATION_VIEW_PAYMENT],
        };
      }
      if (disableConfigForTrims.length) {
        if (disableConfigForTrims.includes(trimCode)) {
          return {
            ...state,
            section: NAVIGATION_VIEW_OVERVIEW,
            allowedSections: allowedSections.filter(section => section !== NAVIGATION_VIEW_PAYMENT),
            layoutsData: {
              gallery: configDisabledView.gallery,
              main: configDisabledView.main,
            },
          };
        }
        const hasPaymentSection = allowedSections.includes(NAVIGATION_VIEW_PAYMENT);
        return {
          ...state,
          allowedSections: hasPaymentSection
            ? allowedSections
            : [...allowedSections, NAVIGATION_VIEW_PAYMENT],
          layoutsData: {
            gallery: dataParsed.gallery,
            main: dataParsed.main,
          },
        };
      } else {
        return {
          ...state,
          layoutsData: dataParsed,
          section: getSelectedSection({ section, allowedSections, selectedSection: action.key }),
        };
      }
    }
    case UPDATE_ACTIVE_GROUP: {
      return {
        ...state,
        activeGroupId: action?.activeId,
      }
    }

    default:
      return state;
  }
}
