import _find from 'lodash/find';
import _dropRight from 'lodash/dropRight';
import _concat from 'lodash/concat';

import {
  MODAL_OPEN,
  MODAL_CLOSE,
  MODAL_CLOSE_ALL
} from 'dictionary';

function layouts(state = { }, action) {
    function updateLayout(state, component, props) {
      const { layouts } = state;
      const buffer = _find(layouts, (layout) => {
        return layout.component === component;
      })
      if (typeof buffer !== 'undefined') {
        return _concat(state.available, [Object.assign({}, buffer, {
          props: props
        })])
      }
      else {
        return state.available;
      }
    }

    switch (action.type) {
      case MODAL_OPEN:
        const available = updateLayout(state, action.component_target, action.props)
        return Object.assign({}, state, {
          selected: available.length ? available[available.length-1] : null,
          available
        })
      case MODAL_CLOSE:
        const unset = state.available.length ? _dropRight(state.available) : []
        return {
          selected: unset.length ? unset[unset.length-1] : null,
          available: unset,
          open: !!unset.length
        }
      default:
        return state;
    }
}

function Modal(state = {}, action){
  switch (action.type) {
  	case MODAL_OPEN:
    	return Object.assign({}, state, layouts(state, action), {
        open: true
      })
    case MODAL_CLOSE:
    	return Object.assign({}, state, {
        open: false,
      }, layouts(state, action))
    case MODAL_CLOSE_ALL:
      return Object.assign({}, state, {
        open: false,
        available: [],
        selected: null
      })
    default:
      return state;
  }
}

export default Modal
