import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { string, bool, number, func } from 'prop-types';
import _get from 'lodash/get';
import { i18n, htmlToReact } from 'utils';
import { MODEL_FULL_NAME, PAYMENT_TYPE_WECHAT, PAYMENT_TYPE_ALIPAY } from 'dictionary';
import { updateQRcodePaymentTransactionStatus } from 'actions';
import { Collapse } from 'react-collapse';
import useInterval from '../../hooks/useInterval';
import cx from 'classnames';
import { request } from 'utils/requestAgent';

const PaymentQRcodePanel = ({
  qrcode,
  rn,
  modelCode,
  isPaymentInteractionRequired,
  isConfigBurst,
  enableCyberpunk,
  timeLimit,
  intervalTick,
  pollingInterval,
  paymentType,
  transactionNumber,
  qrCodePaymentTransactionStatus,
  updateTransactionStatus,
  transactionLookupUrl,
  referralCode,
  baseUrl,
  isReservedPreOrder,
  isBookedPreOrder,
  isNewOrderProfileUrl,
}) => {
  const [count, setCount] = useState(timeLimit);
  const [intervalStatus, updateIntervalStatus] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const modelUrl = isConfigBurst ? modelCode : MODEL_FULL_NAME[modelCode];
  const dashboardUrl = isNewOrderProfileUrl ? `${baseUrl}/teslaaccount/order/${rn}` : `${baseUrl}/teslaaccount/profile?rn=${rn}&redirect=no`;
  let redirectUrl = referralCode
    ? `/${modelUrl}/confirmation/${rn}?referral=${referralCode}`
    : `/${modelUrl}/confirmation/${rn}`;
  if (isReservedPreOrder || isBookedPreOrder) {
    redirectUrl = dashboardUrl;
  }

  const pollTransactionStatus = transactionNumber => {
    request
      .get(transactionLookupUrl)
      .timeout({
        response: pollingInterval * 1000,
        deadline: pollingInterval * 1000,
      })
      .query({ transactionNumber: transactionNumber })
      .end((error, response) => {
        if (!error && response && response.body) {
          const { body = [] } = response;
          if (body.decision !== qrCodePaymentTransactionStatus) {
            updateTransactionStatus(body.decision);
          }
        }
      });
  };

  const intervalTimer = () => {
    if (!startTime) {
      const start = new Date();
      setStartTime(start.getTime());
    } else {
      // CNDE-440: Safari Timer is not accurate when browser window is idle or minimized
      const now = new Date();
      const remain = timeLimit - ((now.getTime() - startTime) / 1000).toFixed(0);
      setCount(remain);
      if (transactionNumber && remain % pollingInterval === 0) {
        pollTransactionStatus(transactionNumber);
      }
      if (remain < 1) {
        updateIntervalStatus(null);
        window.location = redirectUrl;
      }
    }
  };

  useInterval(intervalTimer, intervalStatus);

  useEffect(() => {
    if (isPaymentInteractionRequired) {
      const counts = count <= 0 ? timeLimit : count;
      setCount(counts);
      updateIntervalStatus(intervalTick);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaymentInteractionRequired]);

  useEffect(() => {
    if (qrCodePaymentTransactionStatus === 'ACCEPT') {
      window.location = redirectUrl;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrCodePaymentTransactionStatus]);

  return (
    <Collapse isOpened={isPaymentInteractionRequired && qrcode}>
      <div className="payment-qrcode-panel">
        <div
          className={cx('icon-cc tds--align_center', {
            'icon-WECHATPAY': paymentType === PAYMENT_TYPE_WECHAT,
            'icon-ALIPAY': paymentType === PAYMENT_TYPE_ALIPAY,
          })}
        ></div>
        <img src={qrcode} alt="Payment QR Code" className="payment-qrcode tds--align_center" />
        {count > 0 && (
          <div className="tds-text--center">
            {htmlToReact(
              i18n('SummaryPanel.payment_qrcode_count_down_label', {
                MINUTE: Math.floor(count / 60).toString(),
                SECOND: (count % 60).toString(),
              })
            )}
          </div>
        )}
        {!isReservedPreOrder && !isBookedPreOrder && (
          <button
            type="button"
            className={cx(
              'tds-btn tds-btn--secondary tds-btn--width-full payment-qrcode-btn-paid',
              { 'tds-scrim--black': enableCyberpunk }
            )}
            onClick={() => {
              window.location = redirectUrl;
            }}
          >
            {htmlToReact(i18n('SummaryPanel.wechat_payment_complete'))}
          </button>
        )}
      </div>
    </Collapse>
  );
};

PaymentQRcodePanel.propTypes = {
  qrcode: string,
  rn: string,
  modelCode: string,
  isPaymentInteractionRequired: bool,
  isConfigBurst: bool,
  enableCyberpunk: bool,
  timeLimit: number,
  intervalTick: number,
  pollingInterval: number,
  paymentType: string,
  transactionNumber: string,
  qrCodePaymentTransactionStatus: string,
  transactionLookupUrl: string,
  updateTransactionStatus: func.isRequired,
  referralCode: string,
  baseUrl: string,
  isReservedPreOrder: bool,
  isBookedPreOrder: bool,
  isNewOrderProfileUrl: bool.isRequired,
};

PaymentQRcodePanel.defaultProps = {
  qrcode: '',
  rn: '',
  modelCode: '',
  isPaymentInteractionRequired: false,
  isConfigBurst: false,
  enableCyberpunk: false,
  intervalTick: 1000,
  pollingInterval: 5,
  timeLimit: 900,
  paymentType: '',
  transactionNumber: '',
  transactionLookupUrl: '',
  qrCodePaymentTransactionStatus: '',
  referralCode: '',
  baseUrl: '',
  isReservedPreOrder: false,
  isBookedPreOrder: false,
};

const mapStateToProps = state => {
  const { App, Configuration, Payment, ApplicationFlow } = state;
  const { isNewOrderProfileUrl = false, isConfigBurst = false, enableCyberpunk = false, base_url: baseUrl = '' } = App;
  const {
    PaymentQRCode: qrcode = '',
    isPaymentInteractionRequired = false,
    PaymentDetail = {},
    transactionNumber = '',
    qrCodePaymentTransactionStatus = ''
  } = Payment;
  const { PaymentType: paymentType } = PaymentDetail || {};
  const { rn = null, model_code: modelCode = '' } = Configuration;
  const { referral = {}, isReservedPreOrder = false, isBookedPreOrder = false } = ApplicationFlow;
  const { referralCode = '' } = referral || {};
  return {
    qrcode,
    rn,
    modelCode,
    isPaymentInteractionRequired,
    isConfigBurst,
    enableCyberpunk,
    paymentType,
    transactionNumber,
    transactionLookupUrl: _get(
      state,
      'App.routes.transactionLookup',
      '/configurator/api/v1/lookup/transaction'
    ),
    qrCodePaymentTransactionStatus,
    referralCode,
    baseUrl,
    isReservedPreOrder,
    isBookedPreOrder,
    isNewOrderProfileUrl,
  }
};

const mapDispatchToProps = dispatch => ({
  updateTransactionStatus: status => {
    dispatch(updateQRcodePaymentTransactionStatus(status));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentQRcodePanel);
