import React from 'react';
import { connect } from 'react-redux';
import { bool } from 'prop-types';
import { i18n, buttonStringExtractor } from 'utils';
import { PREPROD_MODAL, MODAL_SMALL } from 'dictionary';
import _get from 'lodash/get';
import ModalTrigger from '../../../components/ModalTrigger';

const PreprodDisclaimer = ({ showPreprodWithDisclaimer }) => {
  if (!showPreprodWithDisclaimer) {
    return null;
  }

  const PreprodDisclaimer = buttonStringExtractor(i18n('Inventory.availability.disclaimer'));

  return (
    <div className="legal-disclaimer preprod-disclaimer-disclaimer">
      <span>{PreprodDisclaimer.firstPart}</span>
      <ModalTrigger
        options={{
          props: {
            componentName: PREPROD_MODAL,
            props: {
              genericWrapper: true,
              size: MODAL_SMALL,
              classes: 'tds-text--regular preprod-disclaimer-button',
              containerCss: 'tds-display--inline tds-link tds-link--primary',
            },
          },
        }}
      >
        {PreprodDisclaimer.buttonPart}
      </ModalTrigger>
      <span>{PreprodDisclaimer.lastPart}</span>
    </div>
  );
};

PreprodDisclaimer.propTypes = {
  showPreprodWithDisclaimer: bool.isRequired,
};

function mapStateToProps(state) {
  return {
    showPreprodWithDisclaimer: _get(
      state,
      'ReviewDetails.product.data.IsPreProdWithDisclaimer',
      false
    ),
  };
}

export default connect(mapStateToProps)(PreprodDisclaimer);
