import { SET_ALD_DOWN_PAYMENT_PERCENT } from 'dictionary';

export default (state, { type, downPaymentPercent }) => {
  switch (type) {
    case SET_ALD_DOWN_PAYMENT_PERCENT:
      return {
        ...state,
        downPaymentPercent,
      };
    default:
      return state;
  }
};
